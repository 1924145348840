import { SEARCH_REQUEST, SEARCH_RESPONSE } from '../constants/actionTypes';

export const initialState = {
  loading: false,
  searchTerms: '',
  list: []
};

const search = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_REQUEST:
      return { ...state, loading: true };
    case SEARCH_RESPONSE:
      return {
        ...state,
        loading: false,
        list: payload
      };
    default:
      return state;
  }
};

export default search;
