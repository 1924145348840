import React, { Component } from 'react';

import CheckboxGroup from '../../inputs/CheckboxGroup';
import { DatePicker } from '../../inputs/DateTimePicker';
import Dropdown from '../../inputs/Dropdown';
import GoogleMap from '../../inputs/GoogleMap';
import Multiselect from '../../inputs/Multiselect';
import RadioButtons from '../../inputs/RadioButtons';
import Textbox from '../../inputs/Textbox';
import Money from '../../inputs/Money';
import Card from '../../Card';

const optionsMapper = options => {
  return options.map((option, index) => ({ value: index, label: option }));
};

const findAnswer = (propAnswers, questionId) => {
  if (propAnswers && propAnswers.hasOwnProperty(questionId)) {
    return propAnswers[questionId];
  }

  return '';
};

export default class IncidentSection extends Component {
  handleNA = (questionId, checked) =>
    this.handleAnswers(questionId, checked ? ['Not Applicable'] : '');

  handleAnswers = (questionId, answer) => {
    let fields = { ...this.props.fields };
    fields[questionId] = answer;
    this.props.handleSelectedAnswer(fields);
  };

  render() {
    const {
      name,
      reportSectionHeader,
      fields,
      employees,
      answers,
      groupList,
      activeLocation,
      isIncidentBasics,
      isEditing,
      isCreating,
      activeIncident,
      activeProject
    } = this.props;

    const disabled = !isCreating && !isEditing;

    const employeeDropdown = employees.map(employee => {
      return {
        value: employee._id,
        label: `${employee.person.firstName} ${employee.person.lastName}`
      };
    });

    const groupDropdown = groupList.map(group => {
      return {
        value: group._id,
        label: group.name
      };
    });

    const getProjectDropdown = () => {
      const selectedGroup = groupList.filter(
        group =>
          group._id === answers.locationId ||
          group._id === activeLocation._id ||
          (activeIncident && group._id === activeIncident.locationId)
      );

      let temp = [];

      if (selectedGroup.length > 0) {
        temp = selectedGroup[0].projects.map(project => {
          return {
            value: project._id,
            label: project.name
          };
        });
        return [{ value: '', label: '      ' }, ...temp];
      }

      return [];
    };

    return (
      <Card name={name} title={reportSectionHeader} showHeader>
        {isIncidentBasics && (
          <div>
            {!activeLocation && (
              <Dropdown
                searchable={true}
                options={groupDropdown}
                fieldLabel="Group or Establishment"
                currentValue={
                  activeIncident
                    ? activeIncident.locationId
                    : findAnswer(answers, 'locationId')
                }
                handleChange={values =>
                  this.handleAnswers('locationId', values.value)
                }
                isRequired
                disabled={!isCreating}
              />
            )}

            {!activeProject && (
              <Dropdown
                searchable={true}
                options={getProjectDropdown()}
                fieldLabel="Project"
                currentValue={
                  activeIncident
                    ? activeIncident.projectId
                    : findAnswer(answers, 'projectId')
                }
                handleChange={values =>
                  this.handleAnswers('projectId', values.value)
                }
                disabled={!isCreating}
              />
            )}
          </div>
        )}
        {fields.map((field, index) => {
          if (field.legacy)
            return <React.Fragment key={index}></React.Fragment>;
          let answer = findAnswer(answers, field._id);
          let isNA =
            Array.isArray(answer) &&
            answer.length === 1 &&
            answer[0] === 'Not Applicable';
          let showNA =
            field.type === 'signature' ? false : field.showNotApplicable;

          let props = {
            name: field.label,
            currentValue: answer,
            isRequired: field.required,
            fieldLabel: field.label,
            showNA,
            isNA,
            onNAChange: v => this.handleNA(field._id, v),
            disabled
          };

          return (
            <React.Fragment key={index}>
              {field.type === 'radiobuttons' ? (
                <RadioButtons
                  options={optionsMapper(field.options)}
                  handleChange={values => this.handleAnswers(field._id, values)}
                  {...props}
                />
              ) : field.type === 'textarea' ? (
                <Textbox
                  type="textarea"
                  rows={5}
                  handleChange={e =>
                    this.handleAnswers(field._id, e.target.value)
                  }
                  {...props}
                />
              ) : field.type === 'dateTimePicker' ? (
                <DatePicker
                  pickTime
                  handleChange={value => this.handleAnswers(field._id, value)}
                  {...props}
                />
              ) : field.type === 'datePicker' ? (
                <DatePicker
                  handleChange={value => this.handleAnswers(field._id, value)}
                  {...props}
                />
              ) : field.type === 'dropdown' || field.type === 'dropUp' ? (
                <Dropdown
                  options={optionsMapper(field.options)}
                  searchable
                  handleChange={values => this.handleAnswers(field._id, values)}
                  {...props}
                />
              ) : field.type === 'multiselectDown' ||
                field.type === 'multiselectUp' ||
                field.type === 'multiselect' ? (
                <Multiselect
                  options={optionsMapper(field.options)}
                  searchable
                  handleChange={values => this.handleAnswers(field._id, values)}
                  {...props}
                />
              ) : field.type === 'multiselectPersonnel' ? (
                <Multiselect
                  options={employeeDropdown}
                  searchable
                  handleChange={values => this.handleAnswers(field._id, values)}
                  {...props}
                />
              ) : field.type === 'checkbox' ? (
                <CheckboxGroup
                  options={optionsMapper(field.options)}
                  handleChange={values => this.handleAnswers(field._id, values)}
                  {...props}
                />
              ) : field.type === 'textbox' ? (
                <Textbox
                  handleChange={e =>
                    this.handleAnswers(field._id, e.target.value)
                  }
                  {...props}
                />
              ) : field.type === 'money' ? (
                <Money
                  onChange={e => this.handleAnswers(field._id, e.target.value)}
                  {...props}
                />
              ) : field.type === 'googleAddress' ? (
                <GoogleMap
                  handleChange={value => this.handleAnswers(field._id, value)}
                  {...props}
                />
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
      </Card>
    );
  }
}
