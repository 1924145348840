import React, { Component } from 'react';
import { getAttachmentSelection } from '../../utils/attachmentSelector';
import { classnames } from '../../utils/classNamesHelper';
import Button from '../inputs/Button';

import styles from './styles.module.scss';

export default class FileDownloader extends Component {
  render() {
    const {
      attachmentIndex,
      onClick,
      attachment,
      deleteAttachment,
      disabled,
      isInList,
      isInTraining
    } = this.props;

    const classes = [
      isInTraining && styles.isInTraining,
      isInList && styles.listDownload,
      styles.downloader
    ];

    const deleteClasses = isInTraining
      ? [styles.trainingDeleteAttachment]
      : [styles.deleteAttachment];

    const isImage =
      attachment.original_filename.toLowerCase().indexOf('png') > -1 ||
      attachment.original_filename.toLowerCase().indexOf('jpeg') > -1 ||
      attachment.original_filename.toLowerCase().indexOf('jpg') > -1;

    return (
      <div className={styles.fileDownloader}>
        <a key={attachmentIndex} href={attachment.source_url} target={'_blank'}>
          <img
            onClick={onClick}
            src={
              isImage
                ? attachment.source_url
                : getAttachmentSelection(attachment)
            }
            alt="images"
            className={classnames(classes)}
          />
        </a>
        {!isImage && (
          <div className={styles.fileName}>{attachment.original_filename}</div>
        )}
        {!disabled && (
          <Button
            type="button"
            color="red"
            className={deleteClasses}
            text="X"
            onClick={() => deleteAttachment(attachment)}
          />
        )}
      </div>
    );
  }
}
