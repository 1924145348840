import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import history from '../../../history';
import { getUserInfo, assignedToMapper } from '../../../utils/assignedToMapper';
import {
  defaultFilterMethod,
  sortDateMethod,
  filterExpireDate,
  filterAllDropdown,
  filterPerformedDate,
  filterUserOrEmployee
} from '../../../utils/sortFilterHelpers';
import Button from '../../inputs/Button';
import Checkbox from '../../inputs/Checkbox';

export default class SafetyTrainingList extends Component {
  handleViewEdit = template => {
    if (
      template?.trainingType?.label === 'Historical Training' ||
      template.legacy
    ) {
      this.props.setActiveTraining(template);
      history.push('/app/training/trainingContainer');
    } else {
      this.props.setActiveTemplate(template);
    }
  };

  render() {
    return (
      <ReactTable
        data={this.props.trainings}
        filterable
        defaultFilterMethod={defaultFilterMethod}
        resizable={false}
        noDataText="No results found"
        defaultPageSize={10}
        className="-striped -highlight"
        columns={[
          {
            Header: () => (
              <Button
                color="blue"
                text="Add to Folder"
                onClick={this.props.openFolder}
              />
            ),
            Cell: row => (
              <Checkbox
                options={[{ value: row.original._id, label: '' }]}
                name={row.original._id}
                onChange={() => this.props.handleAddToFolder(row.original._id)}
                currentValue={this.props.addToFolder.find(
                  v => v === row.original._id
                )}
              />
            ),
            filterable: false,
            sortable: false,
            show: this.props.isFromList
          },
          {
            Header: 'TRAINING TITLE',
            accessor: d => d.title,
            id: 'title'
          },
          {
            Header: 'STATUS',
            id: 'status',
            accessor: original => {
              const now = new moment();
              if (now.isAfter(original.expirationDate) && original.doesExpire) {
                return 'Expired';
              } else if (original.isCompleted || original.legacy) {
                return 'Completed';
              } else {
                return 'Assigned';
              }
            },
            filterMethod: filterAllDropdown,
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'All'}
              >
                <option value="all">Show All</option>
                <option value="Assigned">Assigned</option>
                <option value="Completed">Completed</option>
                <option value="Expired">Expired</option>
              </select>
            )
          },
          {
            Header: 'COMPLETION DATE',
            accessor: 'completionDate',
            Cell: ({ original }) =>
              original.completionDate
                ? `${moment(Date.parse(original.completionDate)).format(
                    'MM/DD/YYYY hh:mm a'
                  )}`
                : '',
            sortMethod: (a, b) => sortDateMethod(a, b),
            filterMethod: (filter, row) => filterPerformedDate(filter, row),
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'All'}
              >
                <option value="all">Show All</option>
                <option value="today">Completed within Last 24 Hours</option>
                <option value="last7">Completed within Last Week</option>
                <option value="last30">Completed This Month</option>
                <option value="thisQuarter">Completed This Quarter</option>
                <option value="thisYear">Completed This Year</option>
              </select>
            )
          },
          {
            Header: 'EXPIRATION DATE',
            accessor: 'expirationDate',
            Cell: ({ original }) =>
              original.dueDate && original.doesExpire
                ? `${moment(Date.parse(original.dueDate)).format(
                    'MM/DD/YYYY hh:mm a'
                  )}`
                : original.expirationDate && original.doesExpire
                ? `${moment(Date.parse(original.expirationDate)).format(
                    'MM/DD/YYYY hh:mm a'
                  )}`
                : '',
            sortMethod: (a, b) => sortDateMethod(a, b),
            filterMethod: (filter, row) => filterExpireDate(filter, row),
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'All'}
              >
                <option value="all">Show All</option>
                <option value="today">Expired</option>
                <option value="within30">Expiring within 30 Days</option>
                <option value="within60">Expiring within 60 Days</option>
                <option value="past90">Expiring after 60 Days</option>
              </select>
            )
          },
          {
            Header: 'EMPLOYEES',
            accessor: 'assignedTo',
            filterMethod: (filter, row) =>
              filterUserOrEmployee(
                filter,
                row,
                this.props.activeEmployees,
                this.props.users
              ),
            Cell: ({ original }) => {
              if (
                original.legacy ||
                original.trainingType?.label === 'Historical Training'
              ) {
                return assignedToMapper(
                  this.props.activeEmployees,
                  original.assignedTo ? original.assignedTo : []
                );
              } else {
                const userList = [];
                original.assignedTo.forEach(user => {
                  const userInfo = getUserInfo(user, this.props.users);
                  if (userInfo.firstName) {
                    userList.push(`${userInfo.firstName} ${userInfo.lastName}`);
                  }
                });

                return userList.join(', ');
              }
            },
            sortable: false
          },
          {
            Header: 'VIEW/EDIT',
            Cell: ({ original }) => (
              <Button
                color="blue"
                onClick={() => this.handleViewEdit(original)}
                text="View/Edit"
              />
            ),
            filterable: false,
            sortable: false
          }
        ]}
      />
    );
  }
}
