import {
  take,
  fork,
  call,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects';
import * as api from '../api';
import * as actionTypes from '../constants/actionTypes';
import {
  fetchCompanyIncidentAnalytics,
  fetchCompanyManagementAnalytics
} from './analytics';
import { deleteAttachment, uploadDocument } from './attachment';
import {
  createUser,
  createUserSecurityQuestions,
  forgotPasswordReset,
  getSecurityQuestions,
  loginMicrosoftUser,
  logInUser,
  newUserRegistration,
  updateDefaultPassword,
  updatePassword,
  updateUser,
  validateSecurityQuestions
} from './auth';
import {
  addCompany,
  addCompanyLocation,
  addGroupProject,
  deleteCompany,
  deleteCompanyLocation,
  deleteGroupProject,
  fetchUsersCompanies,
  updateCompany,
  updateCompanyLocation,
  updateGroupProject
} from './company';
import {
  addDailyReport,
  addDocumentFolder,
  addDocumentsToFolder,
  addQualification,
  addSafetyInstruction,
  addSds,
  addToolboxTalks,
  createContinuousCare,
  createCustomDocument,
  createCustomDocumentTemplate,
  createMyDocument,
  deleteContinuousCare,
  deleteCustomDocument,
  deleteCustomDocumentAttachment,
  deleteCustomDocumentTemplate,
  deleteDailyReport,
  deleteDailyReportAttachment,
  deleteDocumentFolder,
  deleteMyDocument,
  deleteQualification,
  deleteQualificationAttachment,
  deleteSafetyInstruction,
  deleteSafetyInstructionAttachment,
  deleteSds,
  deleteSdsAttachment,
  deleteToolboxTalkAttachment,
  deleteToolboxTalks,
  fetchAuthorizationDocuments,
  fetchCompanySafetyInstructions,
  fetchCompanySds,
  fetchCustomDocuments,
  fetchCustomDocumentTemplates,
  fetchDailyReports,
  fetchDocumentFolders,
  fetchDocumentsByFolder,
  fetchMyDocuments,
  fetchQualifications,
  fetchQualificationTypes,
  fetchToolboxTalks,
  updateContinuousCare,
  updateCustomDocument,
  updateCustomDocumentTemplate,
  updateDailyReport,
  updateDocumentFolder,
  updateMyDocument,
  updateQualification,
  updateSafetyInstruction,
  updateSds,
  updateToolboxTalks
} from './documents';
import {
  addIncidentComment,
  addIncidentNote,
  deleteIncident,
  deleteIncidentAttachment,
  emailIncidentAssignee,
  fetchIncidentBasics,
  fetchIncidentById,
  fetchIncidents,
  fetchIncidentCircles,
  recatagorizeReports,
  setActiveIncident,
  updateIncident,
  updateIncidentBasics,
  updateIncidentComment,
  updateIncidentNote
} from './incidents';
import {
  addJsaAttachment,
  addJsaNote,
  createCustomRaTemplate,
  createJsa,
  deleteCustomRaTemplate,
  deleteJsa,
  deleteJsaQuestionAttachment,
  fetchCompanyJsas,
  fetchCustomRaTemplates,
  fetchJsaById,
  fetchJsaSections,
  fetchLocationJsas,
  fetchProjectJsas,
  fetchUserJsas,
  setActiveJsa,
  updateCustomRaTemplate,
  updateJsa
} from './jsa';
import {
  createOshaForm,
  createOshaLog,
  fetchOshaCompanyLogs,
  fetchOshaLocationLogs,
  printOshaFiles,
  printSelectedOsha301,
  updateOshaEmployee,
  updateOshaForm
} from './oshaLogs';
import {
  chargeCustomer,
  createCustomer,
  fetchAccountInformation,
  updateCustomer
} from './payment';
import {
  addEmployeeDoc,
  addLocationEmployee,
  deleteEmployeeDoc,
  deleteLocationEmployee,
  downloadPersonnelQRCode,
  fetchActiveEmployees,
  fetchAllEmployeesActiveUsers,
  importEmployee,
  resetEmployeesPassword,
  setActiveEmployee,
  updateEmployeeDoc,
  updateLocationEmployee,
  resendWelcomeNotification
} from './personnel';
import {
  createCustomReportQuestions,
  deleteCustomReportQuestions,
  fetchCompanyReports,
  fetchCustomReportQuestions,
  fetchLocationReports,
  fetchProjectReports,
  setActiveReport,
  updateCustomReportQuestions
} from './reports';
import {
  addSafetyWalk,
  deleteSafetyWalk,
  fetchSafetyWalks,
  loadSafetyWalk,
  updateSafetyWalk
} from './safetyWalks';
import { searchByTerms } from './search';
import {
  addUserTask,
  deleteUserTask,
  fetchUsersTasks,
  setActiveTask,
  updateUserTask
} from './tasks';
import {
  fetchCustomTemplates,
  addCustomTemplate,
  updateCustomTemplate,
  deleteCustomTemplate,
  fetchTrainings,
  addTraining,
  updateTraining,
  deleteTraining,
  setActiveTraining
} from './training';
import { loadUser } from './user';

const takeLeadingUserId = (patternOrChannel, saga, ...args) =>
  fork(function*() {
    let loadingIds = {};
    while (true) {
      const action = yield take(patternOrChannel);
      const userId = action.payload;
      if (!(userId in loadingIds)) {
        yield fork(function*() {
          yield call(saga, ...args.concat(action));
          loadingIds[userId] = yield takeLeading(
            a => a.payload === userId && a.type === action.type,
            saga,
            ...args
          );
        });
      }
    }
  });

export default function* rootSaga() {
  yield takeLatest(
    actionTypes.CREATE_USER_SECURITY_QUESTIONS_REQUEST,
    createUserSecurityQuestions,
    api
  );

  yield takeLatest(
    actionTypes.SUBMIT_SECURITY_QUESTIONS,
    validateSecurityQuestions,
    api
  );

  yield takeLatest(
    actionTypes.GET_SECURITY_QUESTIONS,
    getSecurityQuestions,
    api
  );
  yield takeLatest(actionTypes.AUTH_SIGNIN, logInUser, api);

  yield takeLatest(actionTypes.MICROSOFT_LOGIN, loginMicrosoftUser, api);

  yield takeLatest(
    actionTypes.FORGOT_PASSWORD_REQUEST,
    forgotPasswordReset,
    api
  );

  yield takeLatest(
    actionTypes.NEW_USER_REGISTRATION_REQUEST,
    newUserRegistration,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_USER_COMPANIES_REQUEST,
    fetchUsersCompanies,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_COMPANY_INCIDENTS_REQUEST,
    fetchIncidents,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_COMPANY_INCIDENT_CIRCLES_REQUEST,
    fetchIncidentCircles,
    api
  );

  yield takeLatest(actionTypes.FETCH_USERS_TASKS_REQUEST, fetchUsersTasks, api);

  yield takeLatest(
    actionTypes.FETCH_SAFETY_INSTRUCTION_REQUEST,
    fetchCompanySafetyInstructions,
    api
  );

  yield takeLatest(actionTypes.FETCH_SDS_REQUEST, fetchCompanySds, api);

  yield takeLatest(actionTypes.ADD_COMPANY_REQUEST, addCompany, api);

  yield takeLatest(
    actionTypes.ADD_COMPANY_LOCATION_REQUEST,
    addCompanyLocation,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_COMPANY_LOCATION_REQUEST,
    updateCompanyLocation,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_COMPANY_LOCATION_REQUEST,
    deleteCompanyLocation,
    api
  );

  yield takeLatest(actionTypes.SET_ACTIVE_TASK_REQUEST, setActiveTask, api);

  yield takeLatest(actionTypes.ADD_USER_TASK_REQUEST, addUserTask, api);

  yield takeLatest(actionTypes.UPDATE_USER_TASK_REQUEST, updateUserTask, api);

  yield takeLatest(actionTypes.DELETE_USER_TASK_REQUEST, deleteUserTask, api);

  yield takeLatest(
    actionTypes.ADD_LOCATION_EMPLOYEE_REQUEST,
    addLocationEmployee,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_LOCATION_EMPLOYEE_REQUEST,
    updateLocationEmployee,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_LOCATION_EMPLOYEE_REQUEST,
    deleteLocationEmployee,
    api
  );

  yield takeLatest(actionTypes.ADD_SDS_REQUEST, addSds, api);

  yield takeLatest(actionTypes.UPDATE_SDS_REQUEST, updateSds, api);

  yield takeLatest(actionTypes.DELETE_SDS_REQUEST, deleteSds, api);

  yield takeLatest(
    actionTypes.ADD_SAFETY_INSTRUCTION_REQUEST,
    addSafetyInstruction,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_SAFETY_INSTRUCTION_REQUEST,
    updateSafetyInstruction,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_SAFETY_INSTRUCTION_REQUEST,
    deleteSafetyInstruction,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_COMPANY_REPORTS_REQUEST,
    fetchCompanyReports,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_LOCATION_REPORTS_REQUEST,
    fetchLocationReports,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_PROJECT_REPORTS_REQUEST,
    fetchProjectReports,
    api
  );

  yield takeLatest(actionTypes.UPDATE_COMPANY_REQUEST, updateCompany, api);

  yield takeLatest(actionTypes.DELETE_COMPANY_REQUEST, deleteCompany, api);

  yield takeLatest(
    actionTypes.FETCH_INCIDENT_BASICS_REQUEST,
    fetchIncidentBasics,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_INCIDENT_BASICS_REQUEST,
    updateIncidentBasics,
    api
  );

  yield takeLatest(actionTypes.UPDATE_INCIDENT_REQUEST, updateIncident, api);

  yield takeLatest(actionTypes.DELETE_INCIDENT_REQUEST, deleteIncident, api);

  yield takeLatest(actionTypes.UPDATE_USER_REQUEST, updateUser, api);

  yield takeLatest(actionTypes.UPDATE_PASSWORD_REQUEST, updatePassword, api);

  yield takeLatest(
    actionTypes.UPDATE_DEFAULT_PASSWORD_REQUEST,
    updateDefaultPassword,
    api
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_INCIDENT_REQUEST,
    setActiveIncident,
    api
  );

  yield takeLatest(actionTypes.SET_ACTIVE_REPORT_REQUEST, setActiveReport, api);

  yield takeLatest(
    actionTypes.ADD_INCIDENT_COMMENTS_REQUEST,
    addIncidentComment,
    api
  );

  yield takeLatest(
    actionTypes.ADD_INCIDENT_NOTES_REQUEST,
    addIncidentNote,
    api
  );

  yield takeLatest(actionTypes.FETCH_JSA_BY_ID, fetchJsaById, api);

  yield takeLatest(
    actionTypes.FETCH_JSA_SECTIONS_REQUEST,
    fetchJsaSections,
    api
  );

  yield takeLatest(actionTypes.CREATE_JSA_REQUEST, createJsa, api);

  yield takeLatest(actionTypes.UPDATE_JSA_REQUEST, updateJsa, api);

  yield takeLatest(actionTypes.ADD_JSA_NOTES_REQUEST, addJsaNote, api);

  yield takeLatest(
    actionTypes.ADD_JSA_ATTACHMENTS_REQUEST,
    addJsaAttachment,
    api
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_EMPLOYEE_REQUEST,
    setActiveEmployee,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_COMPANY_JSA_REQUEST,
    fetchCompanyJsas,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_LOCATION_JSA_REQUEST,
    fetchLocationJsas,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_PROJECT_JSA_REQUEST,
    fetchProjectJsas,
    api
  );

  yield takeLatest(actionTypes.FETCH_USER_JSAS_REQUEST, fetchUserJsas, api);

  yield takeLatest(actionTypes.DELETE_JSA_REQUEST, deleteJsa, api);

  yield takeLatest(actionTypes.IMPORT_EMPLOYEE_REQUEST, importEmployee, api);

  yield takeLatest(
    actionTypes.DELETE_SAFETY_INSTRUCTION_ATTACHMENT,
    deleteSafetyInstructionAttachment,
    api
  );

  yield takeEvery(
    actionTypes.ADD_UPLOADED_ATTACHMENTS_REQUEST,
    uploadDocument,
    api
  );

  yield takeLatest(actionTypes.DELETE_SDS_ATTACHMENT, deleteSdsAttachment, api);

  yield takeLatest(
    actionTypes.DELETE_INCIDENT_ATTACHMENT,
    deleteIncidentAttachment,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_JSA_QUESTION_ATTACHMENT,
    deleteJsaQuestionAttachment,
    api
  );

  yield takeLatest(actionTypes.SEARCH_REQUEST, searchByTerms, api);

  yield takeLatest(actionTypes.CREATE_CUSTOMER_REQUEST, createCustomer, api);

  yield takeLatest(actionTypes.CHARGE_CUSTOMER_REQUEST, chargeCustomer, api);

  yield takeLatest(
    actionTypes.FETCH_ACCOUNT_INFORMATION_REQUEST,
    fetchAccountInformation,
    api
  );

  yield takeLatest(actionTypes.UPDATE_CUSTOMER_REQUEST, updateCustomer, api);

  yield takeLatest(
    actionTypes.FETCH_DAILY_REPORTS_REQUEST,
    fetchDailyReports,
    api
  );

  yield takeLatest(actionTypes.ADD_DAILY_REPORTS_REQUEST, addDailyReport, api);

  yield takeLatest(
    actionTypes.UPDATE_DAILY_REPORTS_REQUEST,
    updateDailyReport,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_DAILY_REPORTS_REQUEST,
    deleteDailyReport,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_TOOLBOX_TALKS_REQUEST,
    fetchToolboxTalks,
    api
  );

  yield takeLatest(actionTypes.ADD_TOOLBOX_TALKS_REQUEST, addToolboxTalks, api);

  yield takeLatest(
    actionTypes.UPDATE_TOOLBOX_TALKS_REQUEST,
    updateToolboxTalks,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_TOOLBOX_TALKS_REQUEST,
    deleteToolboxTalks,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_DAILY_REPORT_ATTACHMENT,
    deleteDailyReportAttachment,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_TOOLBOX_TALK_ATTACHMENT,
    deleteToolboxTalkAttachment,
    api
  );

  yield takeLatest(actionTypes.CREATE_OSHA_FORM_REQUEST, createOshaForm, api);

  yield takeLatest(actionTypes.UPDATE_OSHA_FORM_REQUEST, updateOshaForm, api);

  yield takeLatest(
    actionTypes.FETCH_OSHA_LOGS_COMPANY_REQUEST,
    fetchOshaCompanyLogs,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_OSHA_LOGS_LOCATION_REQUEST,
    fetchOshaLocationLogs,
    api
  );

  yield takeLatest(actionTypes.CREATE_OSHA_LOG_REQUEST, createOshaLog, api);

  yield takeLatest(actionTypes.PRINT_OSHA_REQUEST, printOshaFiles, api);

  yield takeLatest(
    actionTypes.PRINT_SELECTED_OSHA_REQUEST,
    printSelectedOsha301,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_OSHA_EMPLOYEE_REQUEST,
    updateOshaEmployee,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_COMPANY_MANAGEMENT_ANALYTICS_REQUEST,
    fetchCompanyManagementAnalytics,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_COMPANY_INCIDENT_ANALYTICS_REQUEST,
    fetchCompanyIncidentAnalytics,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_ATTACHMENT_REQUEST,
    deleteAttachment,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_QUALIFICATION_REQUEST,
    fetchQualifications,
    api
  );

  yield takeLatest(
    actionTypes.ADD_QUALIFICATION_REQUEST,
    addQualification,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_QUALIFICATION_REQUEST,
    updateQualification,
    api
  );

  yield takeLatest(
    actionTypes.DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
    downloadPersonnelQRCode,
    api
  );

  yield takeLatest(actionTypes.ADD_PROJECT_REQUEST, addGroupProject, api);

  yield takeLatest(actionTypes.UPDATE_PROJECT_REQUEST, updateGroupProject, api);

  yield takeLatest(actionTypes.DELETE_PROJECT_REQUEST, deleteGroupProject, api);

  yield takeLatest(
    actionTypes.FETCH_QUALIFICATION_TYPES_REQUEST,
    fetchQualificationTypes,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_QUALIFICATION_REQUEST,
    deleteQualification,
    api
  );

  yield takeLatest(
    actionTypes.ADD_DOCUMENT_FOLDER_REQUEST,
    addDocumentFolder,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_DOCUMENT_FOLDERS_REQUEST,
    fetchDocumentFolders,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_DOCUMENT_FOLDER_REQUEST,
    updateDocumentFolder,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_DOCUMENT_FOLDER_REQUEST,
    deleteDocumentFolder,
    api
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
    fetchDocumentsByFolder,
    api
  );

  yield takeLatest(
    actionTypes.ADD_DOCUMENTS_TO_FOLDER_REQUEST,
    addDocumentsToFolder,
    api
  );

  yield takeLatest(
    actionTypes.ADD_EMPLOYEE_DOCUMENT_REQUEST,
    addEmployeeDoc,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_EMPLOYEE_DOCUMENT_REQUEST,
    updateEmployeeDoc,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_EMPLOYEE_DOCUMENT_REQUEST,
    deleteEmployeeDoc,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_ALL_ACTIVE_EMPLOYEES_ACTIVE_USERS,
    fetchAllEmployeesActiveUsers,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_ALL_ACTIVE_EMPLOYEES,
    fetchActiveEmployees,
    api
  );

  yield takeLatest(
    actionTypes.EMAIL_INCIDENT_ASSIGNEE_REQUEST,
    emailIncidentAssignee,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_INCIDENT_COMMENTS_REQUEST,
    updateIncidentComment,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_INCIDENT_NOTES_REQUEST,
    updateIncidentNote,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_INCIDENT_BY_ID_REQUEST,
    fetchIncidentById,
    api
  );

  yield takeLatest(actionTypes.CREATE_USER_REQUEST, createUser, api);

  yield takeLatest(
    actionTypes.RECATAGORIZE_REPORTS_REQUEST,
    recatagorizeReports,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_RA_CUSTOM_TEMPLATES_REQUEST,
    fetchCustomRaTemplates,
    api
  );

  yield takeLatest(
    actionTypes.CREATE_RA_CUSTOM_TEMPLATE_REQUEST,
    createCustomRaTemplate,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_RA_CUSTOM_TEMPLATE_REQUEST,
    updateCustomRaTemplate,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_RA_CUSTOM_TEMPLATE_REQUEST,
    deleteCustomRaTemplate,
    api
  );

  yield takeLatest(actionTypes.SET_ACTIVE_JSA_REQUEST, setActiveJsa, api);

  yield takeLatest(
    actionTypes.DELETE_QUALIFICATION_ATTACHMENT,
    deleteQualificationAttachment,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_SAFETY_WALKS_REQUEST,
    fetchSafetyWalks,
    api
  );
  yield takeLatest(actionTypes.ADD_SAFETY_WALK_REQUEST, addSafetyWalk, api);
  yield takeLatest(actionTypes.LOAD_SAFETY_WALK, loadSafetyWalk, api);

  yield takeLatest(
    actionTypes.UPDATE_SAFETY_WALK_REQUEST,
    updateSafetyWalk,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_SAFETY_WALK_REQUEST,
    deleteSafetyWalk,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_MY_DOCUMENTS_REQUEST,
    fetchMyDocuments,
    api
  );

  yield takeLatest(
    actionTypes.CREATE_MY_DOCUMENT_REQUEST,
    createMyDocument,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_MY_DOCUMENT_REQUEST,
    updateMyDocument,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_MY_DOCUMENT_REQUEST,
    deleteMyDocument,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_REQUIRE_AUTHORIZATION_REQUEST,
    fetchAuthorizationDocuments,
    api
  );

  yield takeLatest(
    actionTypes.CREATE_CONTINUOUS_CARE_REQUEST,
    createContinuousCare,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_CONTINUOUS_CARE_REQUEST,
    updateContinuousCare,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_CONTINUOUS_CARE_REQUEST,
    deleteContinuousCare,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST,
    fetchCustomReportQuestions,
    api
  );

  yield takeLatest(
    actionTypes.CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    createCustomReportQuestions,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    updateCustomReportQuestions,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    deleteCustomReportQuestions,
    api
  );

  yield takeLatest(
    actionTypes.RESET_EMPLOYEES_PASSWORD_REQUEST,
    resetEmployeesPassword,
    api
  );

  yield takeLatest(
    actionTypes.RESEND_WELCOME_NOTIFICATION_REQUEST,
    resendWelcomeNotification,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST,
    fetchCustomDocumentTemplates,
    api
  );

  yield takeLatest(
    actionTypes.CREATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
    createCustomDocumentTemplate,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
    updateCustomDocumentTemplate,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
    deleteCustomDocumentTemplate,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_DOCUMENTS_REQUEST,
    fetchCustomDocuments,
    api
  );

  yield takeLatest(
    actionTypes.CREATE_CUSTOM_DOCUMENT_REQUEST,
    createCustomDocument,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_DOCUMENT_REQUEST,
    updateCustomDocument,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_DOCUMENT_REQUEST,
    deleteCustomDocument,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_DOCUMENT_ATTACHMENT_REQUEST,
    deleteCustomDocumentAttachment,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_TRAINING_TEMPLATES_REQUEST,
    fetchCustomTemplates,
    api
  );

  yield takeLatest(
    actionTypes.ADD_CUSTOM_TRAINING_TEMPLATE_REQUEST,
    addCustomTemplate,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_TRAINING_TEMPLATE_REQUEST,
    updateCustomTemplate,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_TRAINING_TEMPLATE_REQUEST,
    deleteCustomTemplate,
    api
  );

  yield takeLatest(actionTypes.FETCH_TRAININGS_REQUEST, fetchTrainings, api);

  yield takeLatest(actionTypes.ADD_TRAINING_REQUEST, addTraining, api);

  yield takeLatest(actionTypes.UPDATE_TRAINING_REQUEST, updateTraining, api);

  yield takeLatest(actionTypes.DELETE_TRAINING_REQUEST, deleteTraining, api);

  yield takeLatest(
    actionTypes.SET_ACTIVE_TRAINING_REQUEST,
    setActiveTraining,
    api
  );

  yield takeLeadingUserId(actionTypes.LOAD_USER_REQUEST, loadUser, api);
}
