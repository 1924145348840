import React, { Component } from 'react';
import moment from 'moment';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';

import './index.css';

export default class PrivateLogCard extends Component {
  state = {
    showForSure: false
  };

  handleRemoveClick = () => {
    const showForSure = this.state.showForSure;

    if (showForSure) {
      this.setState({ showForSure: false });
    } else {
      this.setState({ showForSure: true });
    }
  };

  render() {
    const { log, setActivePrivacyLog } = this.props;
    const { showForSure } = this.state;
    const date = moment(log.createdAt);

    return (
      <div className="reportPrivateLog">
        <div className="reportPrivateLogCard">
          <div className="reportPrivateLogCard-createdDate">
            <div>{date.format('hh:mm a')}</div>
            <div>{date.format('MM/DD/YYYY')}</div>
          </div>
          <div className="reportPrivateLogCard-comment">
            <div className="reportPrivateLogCard-commentBox">{log.value}</div>
          </div>
          <UserBadge
            userId={log.createdByUserId}
            showName
            showImage
            className="reportPrivateLogCard-badge"
          />
        </div>
        <div className="reportPrivateLogCard-buttons">
          <Button
            onClick={() =>
              setActivePrivacyLog({ value: log.value, date })
            }
            type="button"
            text="View"
            className="downloadButton"
          />
          <Button
            onClick={() => this.handleRemoveClick()}
            type="button"
            text={showForSure ? 'For Sure?' : 'Remove'}
            color="red"
          />
        </div>
      </div>
    );
  }
}
