import './logrocketSetup.js';
import React from 'react';
import { render } from 'react-dom';
import Bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import LogRocket from 'logrocket';

import Root from './containers/Root';
import config from './config/config';
import history from './history';
import store from './store';

let ErrorBoundary = React.Fragment;

if (process.env.REACT_APP_STAGE !== 'development') {
  const bugsnagClient = Bugsnag({
    apiKey: '819bc47acce31fb33c49bb88d30e1090',
    releaseStage: config.name
  });
  bugsnagClient.use(bugsnagReact, React);
  ErrorBoundary = bugsnagClient.getPlugin('react');
  Bugsnag.beforeNotify = function(data) {
    data.metaData.sessionURL = LogRocket.sessionURL;
    return data;
  };
}

var inactivityTime = function() {
  var time;
  window.onload = resetTimer;
  document.onmousedown = resetTimer;
  document.onkeypress = resetTimer;

  function logout() {
    localStorage.clear();
    history.push('/login');
  }

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, 60000 * 30);
  }
};
window.onload = function() {
  process.env.REACT_APP_STAGE !== 'development' && inactivityTime();
};

render(
  <ErrorBoundary>
    <Root store={store} />
  </ErrorBoundary>,
  document.getElementById('root')
);
