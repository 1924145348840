import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import history from '../../../history';
import {
  setActiveTrainingResponse,
  setActiveTrainingAttachment,
  updateTrainingRequest
} from '../../../actions/training';
import { getActiveTrainingSelector } from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import BlockText from '../../../components/inputs/BlockText';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import MarkItemAsCompleteModal from '../../../components/Modal/markItemAsCompleteModal';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import SignatureCard from '../../../components/SignatureCard';
import { SubmitSaveForLaterFooter } from '../../../components/Footer';
import Textbox from '../../../components/inputs/Textbox';
import TrainingTable from '../../../components/training/TrainingTable';

import preformTrainingStyles from './styles.module.scss';

class PerformTraining extends Component {
  state = {
    hasUnsavedChanges: false,
    openUnsavedChanges: false,
    openMarkAsCompleteModal: false,
    employeeSignature: undefined
  };

  componentDidMount() {
    this.setState({
      employeeSignature: this.props.activeTraining.employeeSignature
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.activeTraining, this.props.activeTraining)) {
      this.setState({ hasUnsavedChanges: true });
    }
  }

  handleBack = () => {
    this.props.setActiveTraining();
    history.goBack();
  };

  handleSubmit = isComplete => {
    const { updateTraining, activeTraining } = this.props;
    let response = { isCompleted: isComplete };

    if (isComplete) {
      response.completionDate = moment();
    }

    activeTraining.employeeSignature = this.state.employeeSignature;

    updateTraining({ ...activeTraining, ...response, isPerform: true });
  };

  canSubmit = () => {
    const { activeTraining } = this.props;

    const hasAttachments = activeTraining?.attachments?.length;
    const wasOpened = activeTraining?.attachments?.every(
      attachment => attachment.timeSpent
    );
    const hasQuiz = activeTraining?.quiz;
    const quizCompleted = activeTraining?.quiz?.isCompleted;

    return (
      ((hasAttachments && wasOpened && hasQuiz && quizCompleted) ||
        (hasAttachments && wasOpened && !hasQuiz) ||
        (!hasAttachments && hasQuiz && quizCompleted)) &&
      this.state.employeeSignature
    );
  };

  getQuickNav = () => {
    const { activeTraining } = this.props;

    let leftNav = [{ label: activeTraining.title, id: '0' }];

    const wasOpened = activeTraining?.attachments?.every(
      attachment => attachment.timeSpent
    );
    const hasAttachments = activeTraining?.attachments?.length;
    const hasQuiz = activeTraining?.quiz;
    const quizCompleted = activeTraining?.quiz?.isCompleted;

    if (hasAttachments)
      leftNav = [...leftNav, { label: 'Training Material', id: '1' }];

    if (hasQuiz && wasOpened)
      leftNav = [...leftNav, { label: 'Quiz', id: '2' }];

    if (
      (hasAttachments && wasOpened && hasQuiz && quizCompleted) ||
      (hasAttachments && wasOpened && !hasQuiz) ||
      (!hasAttachments && hasQuiz && quizCompleted)
    ) {
      leftNav = [
        ...leftNav,
        {
          label: 'Employee Signature',
          id: 'employeeSignature'
        }
      ];
    }

    return leftNav;
  };

  render() {
    const { activeTraining, setActiveAttachment } = this.props;

    const {
      openUnsavedChanges,
      openMarkAsCompleteModal,
      hasUnsavedChanges
    } = this.state;

    const header = (
      <Header
        title="Safety Training"
        section={activeTraining.title}
        clickBack={() => this.handleBack()}
        hasUnsavedChanges={hasUnsavedChanges}
      />
    );

    const footer = (
      <SubmitSaveForLaterFooter
        submitButtonDisabled={!this.canSubmit()}
        submitButtonClick={() =>
          this.setState({ openMarkAsCompleteModal: true })
        }
        saveButtonClick={() => this.handleSubmit(false)}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={this.props.activeTraining.isEditing}
          Left={<LeftNav items={this.getQuickNav()} />}
        >
          <Card title={activeTraining.title} showHeader name={0}>
            <Textbox
              currentValue={activeTraining.title}
              disabled
              fieldLabel="Safety Training Title"
            />
            <Textbox
              currentValue={activeTraining.instructions}
              disabled
              fieldLabel="Instructions"
              type="textarea"
            />
            {activeTraining?.dueDate && (
              <DatePicker
                fieldLabel="Due Date"
                pickTime
                currentValue={activeTraining?.dueDate}
                disabled
              />
            )}
          </Card>
          {activeTraining?.attachments?.length ? (
            <Card title="Training Material" showHeader name={1}>
              <BlockText blockOfText="This is where you will see all training materials. Please go through each one by one. When you have completed all the training material, sign at the bottom and submit." />
              <TrainingTable
                attachments={activeTraining.attachments}
                setActiveAttachment={values => {
                  setActiveAttachment(values);
                  history.push('/app/training/TrainingAttachmentViewer');
                }}
              />
            </Card>
          ) : null}
          {this.getQuickNav().findIndex(nav => nav.label === 'Quiz') > -1 ? (
            <Card
              title="Quiz"
              showHeader
              name={2}
              rightButton={{
                text: activeTraining?.quiz.isCompleted
                  ? 'View Quiz'
                  : 'Take Quiz',
                onClick: () => history.push('/app/training/TrainingQuizViewer'),
                color: 'blue'
              }}
              className={preformTrainingStyles.quizCard}
            >
              <h3>Title: {activeTraining?.quiz.title}</h3>
              {activeTraining?.quiz.instructions && (
                <h3>Instructions: {activeTraining?.quiz.instructions}</h3>
              )}
              <h3>
                Can Retry: {activeTraining?.quiz.canBeRetaken ? 'Yes' : 'No'}
              </h3>
              {activeTraining?.quiz.canBeRetaken && (
                <h3>
                  # of Retries Allowed:{' '}
                  {activeTraining?.quiz.numberOfRetriesAllowed}
                </h3>
              )}
              <h3># of Questions: {activeTraining?.quiz.questions.length}</h3>
              <h3>
                {activeTraining?.quiz.isCompleted
                  ? 'Attempt'
                  : 'Current Attempt'}
                : {activeTraining?.quiz.attemptNumber}
              </h3>
              {activeTraining?.quiz?.isCompleted && (
                <h3>
                  Grade: {activeTraining?.quiz.passedQuiz ? 'Passed' : 'Failed'}
                </h3>
              )}
            </Card>
          ) : null}
          {this.getQuickNav().findIndex(
            nav => nav.label === 'Employee Signature'
          ) > -1 ? (
            <SignatureCard
              header="Employee Signature"
              currentValue={this.state.employeeSignature}
              name="employeeSignature"
              onChange={sig => this.setState({ employeeSignature: sig })}
              ownerType="training"
              ownerId={activeTraining._id}
              disabled={activeTraining.isCompleted}
            />
          ) : (
            ''
          )}
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChanges}
          savingWhat={activeTraining.title}
          onRequestClose={() => this.setState({ openUnsavedChanges: false })}
          submitActions={() => this.handleBack()}
        />
        <MarkItemAsCompleteModal
          isOpen={openMarkAsCompleteModal}
          completingWhat="Training"
          onRequestClose={() =>
            this.setState({ openMarkAsCompleteModal: false })
          }
          submitActions={() => this.handleSubmit(true)}
          cancelActions={() => this.handleSubmit(false)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTraining: getActiveTrainingSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveTraining: () => dispatch(setActiveTrainingResponse({})),
  setActiveAttachment: payload =>
    dispatch(setActiveTrainingAttachment(payload)),
  updateTraining: payload => dispatch(updateTrainingRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformTraining);
