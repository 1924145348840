import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, required } from '../../utils/formValidation';
import Widget from '../../components/Widget';
import Button from '../../components/inputs/Button';

import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';

const ResetPasswordForm = props => {
  const { handleSubmit } = props;
  return (
    <form className="loginForm" onSubmit={handleSubmit}>
      <Widget>
        <p className="loginForm--verbage">
          Enter your <span style={{ fontWeight: 'bold' }}>USERNAME</span> that
          you use to log in to iReport in the text box below.
        </p>
        <Field
          name="username"
          type="text"
          component={renderField}
          label="Username:"
          validate={[required]}
        />
        <p style={{ fontSize: '14px' }}>
          Your password will be reset and you will receive a notification to
          either your phone or email with a new password to use to log in.
        </p>
        <Button type="submit" color="green" text="Reset" />
      </Widget>
    </form>
  );
};

export default reduxForm({
  form: 'ResetPasswordForm'
})(ResetPasswordForm);
