import React, { Component } from 'react';
import Button from '../../inputs/Button';
import Card from '../../Card';
import Modal from '../../Modal';
import QuestionCard from '../QuestionCard';
import Textbox from '../../inputs/Textbox';

export default class SectionSummaryCard extends Component {
  state = {
    openNotesModal: false,
    noteText: ''
  };

  hasLineItemNotes = lineItem => {
    return lineItem.actionItems && lineItem.actionItems.notes.length > 0;
  };

  hasLineItemImages = lineItem => {
    return lineItem.actionItems && lineItem.actionItems.attachments.length > 0;
  };

  addAttachment = (e, raQuestionId) => {
    const files = e.target.files;

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    this.props.addRaAttachment({
      data,
      ownerId: raQuestionId,
      ownerType: 'jsaQuestion'
    });
  };

  addNote = () => {
    this.props.addRaNote({
      name: this.state.noteText,
      questionId: this.state.questionId
    });
    this.setState({
      noteText: '',
      openNotesModal: false
    });
  };

  render() {
    const {
      activeSection,
      setActiveTask,
      name,
      updateAnswer,
      updateSectionNotes,
      activeRa,
      deleteJsaQuestionAttachment
    } = this.props;

    const { openNotesModal, noteText } = this.state;
    const isLocked = activeRa.isLocked;

    return (
      <Card
        showHeader
        title={`${activeSection.sectionName}: ${activeSection.questions.length} Questions`}
        name={name}
      >
        {isLocked && activeSection.sectionNotes && (
          <Card
            smallCard
            showHeader
            title="Additional Inspection Comments and Suggestions"
          >
            <div>{activeSection.sectionNotes}</div>
          </Card>
        )}
        {activeSection.questions.map((question, index) => (
          <QuestionCard
            key={index}
            question={question}
            questionNumber={`${index + 1}`}
            updateAnswer={updateAnswer}
            isLocked={isLocked}
            sectionName={activeSection.sectionName}
            setActiveTask={setActiveTask}
            addAttachment={(target, questionId) =>
              this.addAttachment(target, questionId)
            }
            addNote={questionId =>
              this.setState({ openNotesModal: true, questionId })
            }
            locationId={activeRa.locationId}
            deleteJsaQuestionAttachment={deleteJsaQuestionAttachment}
          />
        ))}
        {!isLocked && (
          <Card
            smallCard
            showHeader
            title="Additional Inspection Comments and Suggestions"
          >
            <Textbox
              currentValue={activeSection.sectionNotes}
              handleChange={e => updateSectionNotes(e.target.value)}
              type="textarea"
              placeholder="Section Notes"
              disabled={isLocked}
            />
          </Card>
        )}
        <Modal
          title="Add Notes"
          titleClassName="blueHeader"
          isOpen={openNotesModal}
          hideButtons={true}
          onRequestClose={() => this.setState({ openNotesModal: false })}
        >
          <div className="reportSectionContainer-emailAssigeeText">
            <Textbox
              fieldLabel="Add Note"
              currentValue={noteText}
              handleChange={e => this.setState({ noteText: e.target.value })}
              type="textarea"
              placeholder="Add a note for this question"
              disabled={isLocked}
            />
            <Button
              text="Add Note"
              color="blue"
              onClick={() => this.addNote()}
            />
          </div>
        </Modal>
      </Card>
    );
  }
}
