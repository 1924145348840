import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    width="30px"
    height="25px"
  >
    <g transform="translate(0,-952.36218)">
      <path
        d="m 50.000002,975.36216 c -2.7615,0 -5,2.2386 -5,5 l 0,16.99997 -17,0 c -2.7614,0 -5,2.2385 -5,4.99997 0,2.7615 2.2386,5 5,5 l 17,0 0,17.0001 c 0,2.7614 2.2385,5 5,5 2.7615,0 5,-2.2386 5,-5 l 0,-17.0001 16.999999,0 c 2.761398,0 4.999998,-2.2385 4.999998,-5 0,-2.76147 -2.238601,-4.99997 -4.999998,-4.99997 l -16.999999,0 0,-16.99997 c 0,-2.7614 -2.2385,-5 -5,-5 z"
        fillOpacity="1"
        stroke="none"
        marker="none"
        visibility="visible"
        display="inline"
        overflow="visible"
      />
    </g>
  </svg>
);
