import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { classnames } from '../../utils/classNamesHelper';
import { loadUserRequest } from '../../actions/user';
import { getLoadedUsers } from '../../selectors/users';
import Loading from '../Loading';

import styles from './userBadge.module.scss';

export class UserBadge extends React.Component {
  componentDidMount() {
    if (this.props.userId) {
      this.props.loadUser(this.props.userId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId && this.props.userId) {
      this.props.loadUser(this.props.userId);
    }
  }

  render() {
    const {
      userId,
      showName,
      showImage,
      nameUnder,
      byline,
      hoverName,
      size,
      onClick,
      square,
      right,
      plain,
      className,
      time
    } = this.props;

    let image = require('../../assets/images/unselectedPortrait.png');
    let s = (size ?? 'small').toLowerCase();
    let user;

    if (userId) {
      user = this.props.users?.[userId];
      image = user?.profilePictureUrl || require('../../assets/images/blank-profile-picture.jpg');
    } else {
      user = { firstName: 'Unassigned', lastName: 'Owner' };
    }

    let classes = [
      styles.profileBadge,
      nameUnder && styles.column,
      s === 'medium' && styles.medium,
      s === 'large' && styles.large,
      s === 'x-large' && styles.xLarge,
      square && styles.square,
      right && styles.right,
      plain && styles.plain,
      className
    ];

    return (
      <div className={classnames(classes)} onClick={onClick}>
        {showImage && (
          user && !user?.loading ? (
            <img
              className={classnames(styles.image, showName && !nameUnder && styles.space)}
              src={image}
              alt="profile pic"
              title={hoverName && `${user.firstName} ${user.lastName}`}
            />
          ) : (
            <Loading mode="auto" className={styles.loading} />
          )
        )}
        {showName && (
          <div className="name">
            {byline && <span className={styles.byline}>{byline}</span>}
            {user && !user?.loading ? (
              <span className={styles.username}>{user.firstName} {user.lastName}</span>
            ) : (
              <span className={styles.username}>Loading User</span>
            )}
            {time && <span className={styles.time}>{moment(time).format('MM/DD/YYYY hh:mm A')}</span>}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: getLoadedUsers(state)
});

const mapDispatchToProps = dispatch => ({
  loadUser: userId => dispatch(loadUserRequest(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBadge);
