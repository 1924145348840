import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  email,
  normalizePhone,
  renderField,
  required
} from '../../utils/formValidation';
import Widget from '../../components/Widget';
import Button from '../../components/inputs/Button';

import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';

const NewUserRegistrationForm = props => {
  const { handleSubmit } = props;
  return (
    <div>
      <form className="loginForm" onSubmit={handleSubmit}>
        <Widget>
          <div className="form-fields--split">
            <div className="form-fields--leftSide">
              <Field
                name="firstName"
                type="text"
                component={renderField}
                label="First Name:"
                validate={[required]}
                placeholder={''}
              />
            </div>
            <div className="form-fields--rightSide">
              <Field
                name="lastName"
                type="text"
                component={renderField}
                label="Last Name:"
                validate={[required]}
                placeholder={''}
              />
            </div>
          </div>
          <div className="form-fields">
            <Field
              name="username"
              type="text"
              component={renderField}
              label="Username:"
              validate={[required]}
              placeholder={''}
            />
            <Field
              name="password"
              type="password"
              component={renderField}
              label="Password:"
              validate={[required]}
              placeholder={''}
            />
            <Field
              name="confirmPassword"
              type="password"
              component={renderField}
              label="Confirm Password:"
              validate={[required]}
              placeholder={''}
            />
            <Field
              name="phoneNumber"
              type="text"
              component={renderField}
              label="Phone:"
              placeholder="(000) 000-0000"
              normalize={normalizePhone}
              validate={[required]}
            />
            <Field
              name="email"
              type="text"
              component={renderField}
              label="Email:"
              placeholder="eg@email.com"
              warn={email}
              validate={[required]}
            />
            <Button type="submit" color="green" text="Register" />
          </div>
          <p className="login--verbage">
            Already have an account? <Link to="/login">Log in</Link>
          </p>
        </Widget>
      </form>
      {/* 
      Commenting out Google Login until we better support Google logins.
      Seems to be causing more issues than it is worth by confusing users.
      
      <GoogleLogin
        className={'add fullWidth google-login-button'}
        clientId="143104171078-4470tdmg30b7qa1tekch5c5kttfvav96.apps.googleusercontent.com"
        buttonText="Sign in with Google"
        onSuccess={onGoogleSuccess}
        onFailure={e => alert(e.message)}
        cookiePolicy={'single_host_origin'}
      /> */}
    </div>
  );
};

export default reduxForm({
  form: 'NewUserRegistrationForm'
})(NewUserRegistrationForm);
