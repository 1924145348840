import React, { Component } from 'react';

import ButtonSelector from '../inputs/ButtonSelector';

export default class DiagramSelection extends Component {
  render() {
    const { category, subcategory, showAircraft } = this.props;

    const categories = [{
      image: require('../../assets/images/Body Diagram Front.png'),
      url: require('../../assets/images/Body Diagram.png'),
      text: 'Body Diagram',
      value: 'bodyDiagram'
    }, {
      image: require('../../assets/images/Truck_SUV.png'),
      url: '',
      text: 'Vehicle Diagram',
      value: 'vehicleDiagram'
    }, {
      image: require('../../assets/images/4 Way Skew.png'),
      url: '',
      text: 'Road Diagram',
      value: 'roadDiagram'
    }, {
      image: require('../../assets/images/Challenger350.png'),
      url: '',
      text: 'Aircraft Diagram',
      value: 'aircraftDiagram',
      visible: showAircraft || true
    }];

    const vehicleButtons = [{
      image: require('../../assets/images/Bus.png'),
      text: 'Bus',
      value: 'Bus'
    }, {
      image: require('../../assets/images/Delivery Truck.png'),
      text: 'Delivery Truck',
      value: 'Delivery Truck'
    }, {
      image: require('../../assets/images/Sedan.png'),
      text: 'Sedan',
      value: 'Sedan'
    }, {
      image: require('../../assets/images/Semi.png'),
      text: 'Semi Truck',
      value: 'Semi Truck'
    }, {
      image: require('../../assets/images/Truck_SUV.png'),
      text: 'Truck/SUV',
      value: 'Truck/SUV'
    }, {
      image: require('../../assets/images/Van.png'),
      text: 'Van',
      value: 'Van'
    }, {
      image: require('../../assets/images/Equipment Truck.png'),
      text: 'Equipment Truck',
      value: 'Equipment Truck'
    }, {
      image: require('../../assets/images/Escape.png'),
      text: 'Escape',
      value: 'Escape'
    }, {
      image: require('../../assets/images/Global M3 CNG.png'),
      text: 'Global M3 CNG',
      value: 'Global M3 CNG'
    }, {
      image: require('../../assets/images/Pelican.png'),
      text: 'Pelican',
      value: 'Pelican'
    }, {
      image: require('../../assets/images/Pickup-4 Door.png'),
      text: 'Pickup-4 Door',
      value: 'Pickup-4 Door'
    }, {
      image: require('../../assets/images/Delivery Truck.png'),
      text: 'Delivery Truck',
      value: 'Delivery Truck'
    }];

    const aircraftButtons = [{
      image: require('../../assets/images/Bell429.png'),
      text: 'Bell',
      value: 'Bell'
    }, {
      image: require('../../assets/images/CessnaC421GoldenEagle.png'),
      text: 'Cessna C 421 Golden Eagle',
      value: 'Cessna'
    }, {
      image: require('../../assets/images/Challenger350.png'),
      text: 'Challenger 350',
      value: 'Challenger'
    }, {
      image: require('../../assets/images/CirrusSR22.png'),
      text: 'Cirrus SR',
      value: 'Cirrus'
    }, {
      image: require('../../assets/images/PilatusPC12.png'),
      text: 'Pilatus PC 12',
      value: 'Pilatus'
    }];

    const roadButtons = [{
      image: require('../../assets/images/Highway.png'),
      text: 'Highway',
      value: 'Highway'
    }, {
      image: require('../../assets/images/Seperated Straight Away.png'),
      text: 'Separated Straight Away',
      value: 'Seperated Straight Away'
    }, {
      image: require('../../assets/images/Straight Away.png'),
      text: 'Straight Away',
      value: 'Straight Away'
    }, {
      image: require('../../assets/images/Curve.png'),
      text: 'Curve',
      value: 'Curve'
    }, {
      image: require('../../assets/images/4 Way Skew.png'),
      text: '4 Way Skew',
      value: '4 Way Skew'
    }, {
      image: require('../../assets/images/Roundabout.png'),
      text: 'Roundabout',
      value: 'Roundabout'
    }, {
      image: require('../../assets/images/3 Way Right.png'),
      text: '3 Way Right',
      value: '3 Way Right'
    }, {
      image: require('../../assets/images/3 Way Left.png'),
      text: '3 Way Left',
      value: '3 Way Left'
    }, {
      image: require('../../assets/images/3 Way T.png'),
      text: '3 Way T',
      value: '3 Way T'
    }, {
      image: require('../../assets/images/4 Way intersection.png'),
      text: '4 Way Intersection',
      value: '4 Way Intersection'
    }];

    let buttons = null;
    switch (category.name) {
      case 'roadDiagram':
        buttons = roadButtons;
        break;
      case 'aircraftDiagram':
        buttons = aircraftButtons;
        break;
      case 'vehicleDiagram':
        buttons = vehicleButtons;
        break;
      default:
    }

    return (
      <>
        <h2>Category</h2>
        <ButtonSelector
          options={categories}
          value={category.name}
          onClick={v => {
            let sel = categories.find(o => o.value === v);
            this.props.handleCategorySelection({
              name: sel.value,
              url: sel.url
            });
          }}
        />
        {buttons && (
          <>
            <h2>Subcategory</h2>
            <ButtonSelector
              options={buttons}
              value={subcategory.name}
              onClick={v => {
                const sel = buttons.find(b => b.value === v);
                this.props.handleSubcategorySelection({
                  name: sel.value,
                  url: sel.image
                });
              }}
            />
          </>
        )}
      </>
    );
  }
}
