import { all, call, put, select } from 'redux-saga/effects';
import history from '../history';
import {
  authSuccess,
  newUserRegistrationResponse,
  showSecurityQuestions
} from '../actions/auth';
import {
  fetchUserCompaniesResponse,
  setActiveCompany
} from '../actions/company';
import { setActiveSafetyInstruction, setActiveSds } from '../actions/documents';
import {
  fetchIncidentBasicsResponse,
  setActiveIncidentResponse
} from '../actions/incidents';
import { setActiveJsaResponse } from '../actions/jsa';
import { addMessage, removeMessage } from '../actions/messages';
import {
  fetchCompanyUsersResponse,
  setActiveEmployeeResponse,
  updateLocationEmployeeResponse
} from '../actions/personnel';
import { clearUser } from '../actions/user';
import { createReportResponse } from '../actions/reports';
import { setActiveSafetyWalk } from '../actions/safetyWalks';
import { setActiveTaskResponse } from '../actions/tasks';
import { fetchUserProfile } from '../actions/user';
import { getActiveCompany } from '../selectors/company';
import { getActiveEmployeeSelector } from '../selectors/personnel';
import { getLoggedInUser } from '../selectors/users';
import reportTemplateMapper from '../utils/reportTemplateMapper';
import LogRocket from 'logrocket';

export function* logInUser(api, { payload }) {
  try {
    yield put(removeMessage());

    const referrer = payload.referrer;
    delete payload.referrer;

    const responseData = payload.isMicrosoft
      ? yield call(api.userMicrosoftLogin, { internal: true })
      : yield call(api.fetchUserAccount, payload);
    // localStorage.clear();
    localStorage.setItem('token', responseData.token);
    const response = responseData._doc;

    yield put(authSuccess());
    yield put(fetchUserProfile(response));
    try {
      LogRocket.identify(response.username, {
        ...response
      });
    } catch (err) {
      console.log(err);
    }
    let companies = response.companies;

    companies.map(res => {
      res.allLocations = res.groups;
      res.locations = res.groups;
      return res;
    });

    const companyNames = companies.map(company => company.name);
    localStorage.setItem('companies', companyNames.join(', '));

    yield put(fetchUserCompaniesResponse(companies));
    yield put(setActiveCompany({}));

    const users = yield call(api.fetchAllEmployeesAllUsers, {
      companyIds: companies[0]._id
    });

    yield put(fetchCompanyUsersResponse(users));

    // THINK ABOUT WHERE THIS SHOULD ACTUALLY GO, DIFFERENT METHOD MAYBE?
    const referrerUrl = referrer ? referrer.split('/') : [];
    if (referrerUrl.includes('taskContainer')) {
      const id = referrerUrl[referrerUrl.length - 1];

      if (!isValidObjectId(id)) {
        history.push('/home');
        return;
      }
      const taskResponse = yield call(api.fetchTaskById, companies[0]._id, id);

      const company =
        companies.find(c => c._id === taskResponse.companyId) || companies[0];
      yield put(setActiveCompany(company));

      yield put(
        setActiveTaskResponse({
          ...taskResponse.docs[0],
          backToDashboard: true
        })
      );
      history.push('/app/taskContainer');
    } else if (referrerUrl.includes('raSummaryContainer')) {
      const id = referrerUrl[referrerUrl.length - 1];
      if (!isValidObjectId(id)) {
        history.push('/home');
        return;
      }
      const raResponse = yield call(api.fetchJsaById, companies[0]._id, id);

      const company =
        companies.find(c => c._id === raResponse.companyId) || companies[0];
      yield put(setActiveCompany(company));

      yield put(setActiveJsaResponse({ ...raResponse, backToDashboard: true }));
      history.push('/app/raSummaryContainer');
    } else if (referrerUrl.includes('incidentContainer')) {
      const id = referrerUrl[referrerUrl.length - 1];
      if (!isValidObjectId(id)) {
        history.push('/home');
        return;
      }
      const incidentResponse = yield call(
        api.fetchIncidentById,
        companies[0]._id,
        id
      );

      const company =
        companies.find(c => c._id === incidentResponse.companyId) ||
        companies[0];
      yield put(setActiveCompany(company));

      yield put(
        setActiveIncidentResponse({
          ...incidentResponse,
          backToDashboard: true
        })
      );

      const incidentBasics = yield call(
        api.fetchIncidentBasics,
        incidentResponse.companyId
      );

      const reportBasics = yield all(
        incidentResponse.reportTemplateIds.map(fieldGroupId =>
          call(
            api.fetchReportBasics,
            incidentResponse.companyId,
            reportTemplateMapper(fieldGroupId).basic
          )
        )
      );

      const basics = reportBasics.map(report => report[0]);

      response.sections = [...incidentBasics, ...basics];

      yield put(fetchIncidentBasicsResponse(response));

      history.push('/app/incidentContainer');
    } else if (referrerUrl.includes('safetyWalkContainer')) {
      const id = referrerUrl[referrerUrl.length - 1];
      if (!isValidObjectId(id)) {
        history.push('/home');
        return;
      }
      const swResponse = yield call(api.fetchSafetyWalkById, id);

      const company =
        companies.find(c => c._id === swResponse.company) || companies[0];
      yield put(setActiveCompany(company));

      yield put(
        setActiveSafetyWalk({
          ...swResponse,
          backToDashboard: true
        })
      );
      history.push('/app/safetyWalkContainer');
    } else if (referrerUrl.includes('ViewEdit')) {
      const id = referrerUrl[referrerUrl.length - 1];
      if (!isValidObjectId(id)) {
        history.push('/home');
        return;
      }
      const docResponse = yield call(
        api.fetchDocumentById,
        companies[0]._id,
        id
      );

      const company =
        companies.find(c => c._id === docResponse.companyId) || companies[0];
      yield put(setActiveCompany(company));

      if (referrerUrl.includes('Safety Training')) {
        // yield put(
        //   setActiveSafetyTraining({
        //     ...docResponse,
        //     backToDashboard: true
        //   })
        // );
        // history.push('/app/ViewEdit/Safety Training');
      } else if (referrerUrl.includes('Safety Procedure')) {
        yield put(
          setActiveSafetyInstruction({
            ...docResponse,
            backToDashboard: true
          })
        );
        history.push('/app/ViewEdit/Safety Procedure');
      } else if (referrerUrl.includes('Qualification')) {
        history.push('/app/documents/qualifications');
      } else {
        yield put(
          setActiveSds({
            ...docResponse,
            backToDashboard: true
          })
        );
        history.push('/app/ViewEdit/SDS');
      }
    } else if (referrerUrl.includes('reportSectionContainer')) {
      const id = referrerUrl[referrerUrl.length - 1];
      if (!isValidObjectId(id)) {
        history.push('/home');
        return;
      }
      const taskResponse = yield call(api.fetchTaskById, companies[0]._id, id);

      const incidentResponse = yield call(
        api.fetchIncidentById,
        companies[0]._id,
        taskResponse.docs[0].ownerId
      );

      const company =
        companies.find(c => c._id === incidentResponse.companyId) ||
        companies[0];
      yield put(setActiveCompany(company));

      const activeReport = incidentResponse.reportComponents.filter(
        reportComponent =>
          reportComponent[0] === taskResponse.docs[0].reportComponentName
      );

      yield put(setActiveIncidentResponse(incidentResponse));

      yield put(createReportResponse(activeReport));
      history.push('/app/reportSectionContainer');
    } else {
      history.push('/home');
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* createUserSecurityQuestions(api, { payload }) {
  try {
    yield put(removeMessage());
    const responseData = yield call(api.createUserSecurityQuestions, payload);
    yield put(addMessage({ message: `${responseData.data}` }));
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e.data}` }));
  }
}

export function* getSecurityQuestions(payload) {
  try {
    console.log(payload);
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* validateSecurityQuestions(api, { payload }) {
  try {
    yield put(removeMessage());
    const responseData = yield call(api.validateSecurityQuestions, payload);
    // localStorage.clear();
    localStorage.setItem('token', responseData.token);
    const response = responseData._doc;

    yield put(fetchUserProfile(response));

    let companies = response.companies;

    companies.map(res => {
      res.allLocations = res.groups;
      res.locations = res.groups;
      return res;
    });

    const companyNames = companies.map(company => company.name);
    localStorage.setItem('companies', companyNames.join(', '));

    yield put(fetchUserCompaniesResponse(companies));

    history.push('/home');
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e.data}` }));
  }
}

export function* loginMicrosoftUser(api, { payload }) {
  try {
    yield put(removeMessage());

    const responseData = yield call(api.userMicrosoftLogin, payload);
    localStorage.setItem('token', responseData.token);
    const response = responseData._doc;

    yield put(fetchUserProfile(response));
    let companies = response.companies;
    companies.map(res => {
      res.allLocations = res.groups;
      res.locations = res.groups;
      return res;
    });

    const companyNames = companies.map(company => company.name);
    localStorage.setItem('companies', companyNames.join(', '));
    yield put(fetchUserCompaniesResponse(companies));
    history.push('/home');
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* forgotPasswordReset(api, { payload }) {
  try {
    yield put(removeMessage());
    const response = yield call(api.forgotPasswordReset, payload);
    if (Array.isArray(response)) {
      yield put(showSecurityQuestions(response));
      history.push('/login');
    } else {
      yield put(
        addMessage({
          error: false,
          message: 'Email has been successfully sent!'
        })
      );
      history.push('/login');
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* newUserRegistration(api, { payload }) {
  try {
    yield put(removeMessage());
    const response = yield call(api.createUserAccount, {
      ...payload,
      accessLevel: 900,
      freeUser: true
    });

    // localStorage.clear();
    localStorage.setItem('token', response.token);
    yield put(fetchUserProfile(response._doc));
    yield put(newUserRegistrationResponse(true));
    history.push('/home');
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* updateUser(api, { payload }) {
  try {
    yield put(removeMessage());
    const loggedInUser = yield select(getLoggedInUser);

    const companies = payload.companies;

    let response;

    if (payload.data) {
      let profilePictureUrl = yield call(api.uploadAttachment, payload.data, {
        ownerId: loggedInUser._id,
        ownerType: 'userProfile'
      });

      payload.profilePictureUrl = profilePictureUrl[0].source_url;
    }

    delete payload.companyList;
    delete payload.companies;

    if (payload.notificationType === '3') {
      payload.shouldStopNotifications = true;
      delete payload.notificationType;
    } else {
      payload.shouldStopNotifications = false;
    }

    delete payload.companyIds;
    delete payload.groupsIds;
    delete payload.projectIds;

    response = yield call(api.updateUserAccount, payload);
    response.companies = companies;

    yield put(clearUser(loggedInUser._id));

    if (payload.isFromEmployee) {
      const activeEmployee = yield select(getActiveEmployeeSelector);
      yield put(
        setActiveEmployeeResponse({ ...activeEmployee, userAccount: response })
      );
    } else {
      yield put(fetchUserProfile(response));
    }

    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
    const user = yield select(getLoggedInUser);
    yield put(fetchUserProfile(user));
  }
}

export function* createUser(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeEmployee = yield select(getActiveEmployeeSelector);
    const activeCompany = yield select(getActiveCompany);

    payload = {
      username: payload.username,
      companyIds: activeEmployee.companyIds,
      groupIds: activeEmployee.groupIds,
      projectIds: activeEmployee.projectIds,
      firstName: activeEmployee.person.firstName,
      lastName: activeEmployee.person.lastName,
      accessLevel: payload.accessLevel,
      email: payload.email,
      phoneNumber: payload.phoneNumber
    };

    const userAccount = yield call(api.createUserAccountFromEmployee, payload);

    const updateResponse = yield call(api.updateEmployee, activeCompany._id, {
      ...activeEmployee,
      userAccount
    });

    yield put(updateLocationEmployeeResponse(updateResponse));
    yield put(
      setActiveEmployeeResponse({
        ...updateResponse,
        safetyTrainings: activeEmployee.safetyTrainings,
        documents: activeEmployee.documents,
        qualifications: activeEmployee.qualifications,
        incidents: activeEmployee.incidents
      })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updatePassword(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeUser = yield select(getLoggedInUser);

    yield call(api.updatePassword, activeUser._id, payload);
    yield put(fetchUserProfile(activeUser));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateDefaultPassword(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.updateDefaultPassword, payload);
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

const isValidObjectId = id => {
  const idRegex = /\d/g;
  const validIdLength = 24;
  return id && idRegex.test(id) && id.length === validIdLength;
};
