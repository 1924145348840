import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabbordion, TabContent, TabLabel, TabPanel } from 'react-tabbordion';
import ReactLoading from 'react-loading';
import { clearUploadedAttachments } from '../../actions/attachments';
import { updateLeftNavRequest } from '../../actions/navigation';
import { setActiveIncidentResponse } from '../../actions/incidents';
import { fetchAllActiveEmployeesActiveUsers } from '../../actions/personnel';
import {
  fetchCompanyReportsRequest,
  fetchLocationReportsRequest,
  fetchProjectReportsRequest,
  fetchReportCirclesResponse,
  setActiveReportRequest
} from '../../actions/reports';
import {
  CLAIM_STAGES,
  HAZARD_STAGES,
  REACTIVE_KEYS
} from '../../constants/constants';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import {
  getReportCirclesSelector,
  getReportsLoadingSelector,
  getReportsSelector
} from '../../selectors/reports';
import { getLoggedInUser } from '../../selectors/users';
import { AnalyticCircleBox } from '../../components/AnalyticCircle';
import ReportList from '../../components/ReportList';
import Header from '../../components/Header';

import './index.css';
import '../../components/Tabs/index.css';

export class ReportStages extends Component {
  getReports = () => {
    const group = this.props.activeLocationId;
    const project = this.props.activeProject;

    if (project && project._id) {
      this.props.fetchProjectsReports({
        templateId: this.props.match.params.templateId,
        page: 1,
        limit: 10000
      });
    } else if (group && group._id) {
      this.props.fetchLocationReports({
        templateId: this.props.match.params.templateId,
        page: 1,
        limit: 10000
      });
    } else {
      this.props.fetchCompanyReports({
        templateId: this.props.match.params.templateId,
        page: 1,
        limit: 10000
      });
    }
  };

  componentDidMount = () => {
    this.props.updateLeftNav(this.props.match.params.reportType);
    this.getReports();
    this.props.fetchAllActiveEmployeesActiveUsers();
    this.props.activeIncidentResponse();
    this.props.clearAttachments();
  };

  componentDidUpdate = prevProps => {
    if (
      prevProps.match.params.reportType !== this.props.match.params.reportType
    ) {
      this.props.updateLeftNav(this.props.match.params.reportType);
      this.getReports();
    }
  };

  render() {
    const {
      circles,
      reports,
      setActiveReport,
      activeCompany,
      reportsLoading,
      match,
      companyUsers
    } = this.props;

    const blockElements = {
      content: 'traditional-tabs-content',
      panel: 'traditional-tabs-panel',
      label: 'traditional-tabs-title'
    };

    const statuses =
      REACTIVE_KEYS.indexOf(parseInt(match.params.templateId, 10)) > -1
        ? CLAIM_STAGES
        : HAZARD_STAGES;

    const reportContent = statuses.map(status =>
      reports && reports.length > 0
        ? reports.filter(report => report.reportStage === status)
        : []
    );

    return (
      <div className="reports">
        <Header title={match.params.reportType} />
        <AnalyticCircleBox circles={circles} />
        <div className="reports-content">
          <Tabbordion
            blockElements={blockElements}
            className="traditional-tabs"
            name="tabs"
          >
            {reportsLoading ? (
              <ReactLoading
                type="spinningBubbles"
                color="#58a15b"
                className="loading"
              />
            ) : (
              statuses.map((tab, index) => (
                <TabPanel key={index}>
                  <TabLabel>{`${tab} (${
                    Array.isArray(reportContent[index])
                      ? reportContent[index].length
                      : 0
                  })`}</TabLabel>
                  <TabContent>
                    <ReportList
                      content={reportContent[index]}
                      tabs={statuses}
                      setActiveReport={setActiveReport}
                      activeCompany={activeCompany}
                      activeEmployeeList={companyUsers}
                      reportType={match.params.reportType}
                    />
                  </TabContent>
                </TabPanel>
              ))
            )}
          </Tabbordion>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  circles: getReportCirclesSelector(state),
  reports: getReportsSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocationId: getActiveLocationId(state),
  reportsLoading: getReportsLoadingSelector(state),
  loggedInUser: getLoggedInUser(state),
  activeProject: getActiveProject(state),
  companyUsers: getCompanyUsersSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  fetchCompanyReports: payload => dispatch(fetchCompanyReportsRequest(payload)),
  fetchLocationReports: payload =>
    dispatch(fetchLocationReportsRequest(payload)),
  fetchProjectsReports: payload =>
    dispatch(fetchProjectReportsRequest(payload)),
  setActiveReport: report => dispatch(setActiveReportRequest(report)),
  activeIncidentResponse: () => dispatch(setActiveIncidentResponse({})),
  clearAttachments: () => dispatch(clearUploadedAttachments()),
  fetchReportCircles: () => dispatch(fetchReportCirclesResponse()),
  fetchAllActiveEmployeesActiveUsers: () =>
    dispatch(fetchAllActiveEmployeesActiveUsers())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportStages);
