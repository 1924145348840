import {
  ADD_EMPLOYEE_DOCUMENT_REQUEST,
  ADD_EMPLOYEE_DOCUMENT_RESPONSE,
  ADD_LOCATION_EMPLOYEE_REQUEST,
  ADD_LOCATION_EMPLOYEE_RESPONSE,
  DELETE_EMPLOYEE_DOCUMENT_REQUEST,
  DELETE_EMPLOYEE_DOCUMENT_RESPONSE,
  DELETE_LOCATION_EMPLOYEE_REQUEST,
  DELETE_LOCATION_EMPLOYEE_RESPONSE,
  DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
  DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE,
  FETCH_ALL_ACTIVE_EMPLOYEES,
  FETCH_ALL_ACTIVE_EMPLOYEES_ACTIVE_USERS,
  FETCH_COMPANY_EMPLOYEES_RESPONSE,
  FETCH_COMPANY_USERS_RESPONSE,
  SET_ACTIVE_EMPLOYEE_REQUEST,
  SET_ACTIVE_EMPLOYEE_RESPONSE,
  UPDATE_EMPLOYEE_DOCUMENT_REQUEST,
  UPDATE_EMPLOYEE_DOCUMENT_RESPONSE,
  UPDATE_LOCATION_EMPLOYEE_REQUEST,
  UPDATE_LOCATION_EMPLOYEE_RESPONSE,
  UPLOAD_ERROR_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  isDownloadQrLoading: false,
  users: [],
  activeEmployees: [],
  activeEmployee: {},
  activeUser: {},
  activeEmployeeDocs: [],
  uploadError: {},
  paginationInfo: {
    hasNextPage: false,
    hasPrevPage: false,
    limit: '',
    nextPage: '',
    page: '',
    prevPage: '',
    totalDocs: '',
    totalPages: ''
  },
  filtered: []
};

const company = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_LOCATION_EMPLOYEE_REQUEST:
    case UPDATE_LOCATION_EMPLOYEE_REQUEST:
    case DELETE_LOCATION_EMPLOYEE_REQUEST:
    case SET_ACTIVE_EMPLOYEE_REQUEST:
    case ADD_EMPLOYEE_DOCUMENT_REQUEST:
    case UPDATE_EMPLOYEE_DOCUMENT_REQUEST:
    case DELETE_EMPLOYEE_DOCUMENT_REQUEST:
    case FETCH_ALL_ACTIVE_EMPLOYEES:
    case FETCH_ALL_ACTIVE_EMPLOYEES_ACTIVE_USERS:
      return {
        ...state,
        loading: true
      };

    case DOWNLOAD_EMPLOYEE_QRCODE_REQUEST:
      return {
        ...state,
        isDownloadQrLoading: true
      };

    case DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE:
      return {
        ...state,
        isDownloadQrLoading: false
      };

    case FETCH_COMPANY_EMPLOYEES_RESPONSE:
      return {
        ...state,
        loading: false,
        ...payload
      };

    case FETCH_COMPANY_USERS_RESPONSE:
      return {
        ...state,
        loading: false,
        users: [...payload]
      };

    case SET_ACTIVE_EMPLOYEE_RESPONSE:
      return {
        ...state,
        loading: false,
        activeEmployee: payload,
        activeEmployeeDocs: payload.documents
      };

    case ADD_LOCATION_EMPLOYEE_RESPONSE:
      return {
        ...state,
        loading: false,
        activeEmployees: [...state.activeEmployees, { ...payload }]
      };

    case UPDATE_LOCATION_EMPLOYEE_RESPONSE: {
      const employeesList = state.activeEmployees.map(employee => {
        if (employee._id === payload._id) {
          return {
            ...payload,
            userAccount: { ...payload.userAccount }
          };
        }
        return employee;
      });

      return {
        ...state,
        loading: false,
        activeEmployees: employeesList
      };
    }

    case DELETE_LOCATION_EMPLOYEE_RESPONSE: {
      const activeEmployees = state.activeEmployees.filter(
        e => e._id !== payload._id
      );

      return {
        ...state,
        loading: false,
        activeEmployees
      };
    }

    case ADD_EMPLOYEE_DOCUMENT_RESPONSE:
      return {
        ...state,
        loading: false,
        activeEmployeeDocs: [...state.activeEmployeeDocs, payload]
      };

    case UPDATE_EMPLOYEE_DOCUMENT_RESPONSE: {
      let docsList = [...state.activeEmployeeDocs];

      docsList = docsList.map(document => {
        if (document._id === payload._id) {
          return {
            ...payload
          };
        }
        return document;
      });

      return {
        ...state,
        loading: false,
        activeEmployeeDocs: docsList
      };
    }

    case DELETE_EMPLOYEE_DOCUMENT_RESPONSE: {
      let docsList = state.activeEmployeeDocs;

      const index = docsList.findIndex(
        document => document._id === payload._id
      );

      return {
        ...state,
        loading: false,
        activeEmployeeDocs: [
          ...docsList.slice(0, index),
          ...docsList.slice(index + 1)
        ]
      };
    }

    case UPLOAD_ERROR_RESPONSE:
      return {
        ...state,
        uploadError: payload,
        loading: false
      };

    default:
      return {
        ...state,
        loading: false
      };
  }
};

export default company;
