import { call, put, select } from 'redux-saga/effects';
import {
  addUploadedAttachmentsResponse,
  deleteAttachmentResponse
} from '../actions/attachments';
import {
  addIncidentAttachmentsResponse,
  fetchIncidentAttachmentsResponse
} from '../actions/incidents';
import { addMessage, removeMessage } from '../actions/messages';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import { getIncidentAttachmentsSelector } from '../selectors/incidents';

export function* uploadDocument(api, { payload }) {
  try {
    yield put(removeMessage());
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    let response = [];

    response = yield call(api.uploadAttachment, payload.data, {
      ownerId: payload.ownerId,
      ownerType: payload.ownerType,
      isSignature: payload.isSiganture ?? false,
      isPhotoEvidence: payload.isPhotoEvidence ?? false
    });

    if (payload.ownerType === 'incident') {
      yield put(addIncidentAttachmentsResponse(response));
      yield put(addUploadedAttachmentsResponse(response));
    } else {
      yield put(
        addUploadedAttachmentsResponse([...addedAttachments, ...response])
      );
    }

    yield put(addMessage({ error: false, message: 'Attachments Uploaded' }));
  } catch (e) {
    yield put(addIncidentAttachmentsResponse());
    yield put(addUploadedAttachmentsResponse());
    yield put(
      addMessage({
        error: true,
        message:
          'Attachment cannot be uploaded at this time. Please try again later.'
      })
    );
  }
}

export function* deleteAttachment(api, { payload }) {
  try {
    yield call(api.deleteAttachment, payload._id);

    if (payload.ownerType === 'incident') {
      let attachments = yield select(getIncidentAttachmentsSelector);
      const attachmentIndex = attachments.findIndex(
        attachment => attachment._id === payload._id
      );

      attachments = [
        ...attachments.slice(0, attachmentIndex),
        ...attachments.slice(attachmentIndex + 1)
      ];
      yield put(fetchIncidentAttachmentsResponse(attachments));
    }

    yield put(deleteAttachmentResponse(payload));
  } catch (e) {
    yield put(
      addMessage({ error: true, message: 'Could not delete attachment' })
    );
  }
}
