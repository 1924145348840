import {
  ADD_INCIDENT_ATTACHMENTS_REQUEST,
  ADD_INCIDENT_ATTACHMENTS_RESPONSE,
  ADD_INCIDENT_COMMENTS_REQUEST,
  ADD_INCIDENT_NOTES_REQUEST,
  DELETE_INCIDENT_ATTACHMENT,
  DELETE_INCIDENT_REQUEST,
  EMAIL_INCIDENT_ASSIGNEE_REQUEST,
  EMAIL_INCIDENT_ASSIGNEE_RESPONSE,
  FETCH_COMPANY_CIRCLES_RESPONSE,
  FETCH_COMPANY_INCIDENT_CIRCLES_REQUEST,
  FETCH_COMPANY_INCIDENTS_REQUEST,
  FETCH_COMPANY_INCIDENTS_RESPONSE,
  FETCH_INCIDENT_ATTACHMENTS_REQUEST,
  FETCH_INCIDENT_ATTACHMENTS_RESPONSE,
  FETCH_INCIDENT_BASICS_REQUEST,
  FETCH_INCIDENT_BASICS_RESPONSE,
  FETCH_INCIDENT_BY_ID_REQUEST,
  RECATAGORIZE_REPORTS_REQUEST,
  SET_ACTIVE_INCIDENT_REQUEST,
  SET_ACTIVE_INCIDENT_RESPONSE,
  UPDATE_INCIDENT_BASICS_REQUEST,
  UPDATE_INCIDENT_BASICS_RESPONSE,
  UPDATE_INCIDENT_COMMENTS_REQUEST,
  UPDATE_INCIDENT_NOTES_REQUEST,
  UPDATE_INCIDENT_REQUEST,
  UPDATE_INCIDENT_RESPONSE
} from '../constants/actionTypes';

export const fetchCompanyIncidentCircles = payload => ({
  type: FETCH_COMPANY_INCIDENT_CIRCLES_REQUEST,
  payload
});

export const fetchCompanyIncidentsRequest = payload => ({
  type: FETCH_COMPANY_INCIDENTS_REQUEST,
  payload
});

export const fetchCompanyIncidentsResponse = payload => ({
  type: FETCH_COMPANY_INCIDENTS_RESPONSE,
  payload
});

export const fetchCompanyCirclesResponse = payload => ({
  type: FETCH_COMPANY_CIRCLES_RESPONSE,
  payload
});

export const fetchIncidentBasicsRequest = payload => ({
  type: FETCH_INCIDENT_BASICS_REQUEST,
  payload
});

export const fetchIncidentBasicsResponse = payload => ({
  type: FETCH_INCIDENT_BASICS_RESPONSE,
  payload
});

export const updateIncidentBasicsRequest = payload => ({
  type: UPDATE_INCIDENT_BASICS_REQUEST,
  payload
});

export const updateIncidentBasicsResponse = payload => ({
  type: UPDATE_INCIDENT_BASICS_RESPONSE,
  payload
});

export const updateIncidentRequest = payload => ({
  type: UPDATE_INCIDENT_REQUEST,
  payload
});

export const updateIncidentResponse = payload => ({
  type: UPDATE_INCIDENT_RESPONSE,
  payload
});

export const deleteIncidentRequest = payload => ({
  type: DELETE_INCIDENT_REQUEST,
  payload
});

export const setActiveIncidentRequest = payload => ({
  type: SET_ACTIVE_INCIDENT_REQUEST,
  payload
});

export const setActiveIncidentResponse = payload => ({
  type: SET_ACTIVE_INCIDENT_RESPONSE,
  payload
});

export const addIncidentCommentsRequest = payload => ({
  type: ADD_INCIDENT_COMMENTS_REQUEST,
  payload
});

export const updateIncidentCommentsRequest = payload => ({
  type: UPDATE_INCIDENT_COMMENTS_REQUEST,
  payload
});

export const addIncidentNotesRequest = payload => ({
  type: ADD_INCIDENT_NOTES_REQUEST,
  payload
});

export const updateIncidentNotesRequest = payload => ({
  type: UPDATE_INCIDENT_NOTES_REQUEST,
  payload
});

export const fetchIncidentAttachmentsRequest = payload => ({
  type: FETCH_INCIDENT_ATTACHMENTS_REQUEST,
  payload
});

export const fetchIncidentAttachmentsResponse = payload => ({
  type: FETCH_INCIDENT_ATTACHMENTS_RESPONSE,
  payload
});

export const addIncidentAttachmentsRequest = payload => ({
  type: ADD_INCIDENT_ATTACHMENTS_REQUEST,
  payload
});

export const addIncidentAttachmentsResponse = payload => ({
  type: ADD_INCIDENT_ATTACHMENTS_RESPONSE,
  payload
});

export const deleteIncidentAttachment = payload => ({
  type: DELETE_INCIDENT_ATTACHMENT,
  payload
});

export const emailIncidentAssigneeRequest = payload => ({
  type: EMAIL_INCIDENT_ASSIGNEE_REQUEST,
  payload
});

export const emailIncidentAssigneeResponse = payload => ({
  type: EMAIL_INCIDENT_ASSIGNEE_RESPONSE,
  payload
});

export const fetchIncidentByIdRequest = payload => ({
  type: FETCH_INCIDENT_BY_ID_REQUEST,
  payload
});

export const recatagorizeReportsRequest = payload => ({
  type: RECATAGORIZE_REPORTS_REQUEST,
  payload
});
