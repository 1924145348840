export const getLocationDropdown = locationList =>
  locationList.map(location => {
    return { value: location._id, label: location.name };
  });

export const getProjectDropdown = (groupIds, activeLocation, locationList) => {
  let group = '';
  if (!groupIds && !activeLocation) {
    return [];
  } else if (activeLocation) {
    group = [activeLocation._id];
  } else {
    group = groupIds;
  }

  const selectedGroup = locationList.filter(o =>
    group.find(o2 => {
      if (o2?.value) {
        return o._id === o2?.value;
      } else {
        return o._id === o2;
      }
    })
  );

  let projectsDropdown = [];

  if (selectedGroup.length > 0) {
    selectedGroup.forEach(group => {
      group.projects.forEach(project => {
        projectsDropdown.push({
          value: project._id,
          label: `${project.name}`
        });
      });
    });
  }

  return projectsDropdown;
};

export const getPersonnelDropdown = personnelList =>
  personnelList.map(personnel => {
    return {
      value: personnel._id,
      label: `${personnel.person.firstName} ${personnel.person.lastName}`
    };
  });

export const getUserDropdown = userList =>
  userList.map(user => {
    return {
      value: user.userAccount._id,
      label: `${user.person.firstName} ${user.person.lastName}`
    };
  });
