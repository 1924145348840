import React, { Component } from 'react';
import ReactTable from 'react-table';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import {
  sortUser,
  filterUser,
  sortByTimeSpent
} from '../../../utils/sortFilterHelpers';
import AuthorizeRejectButtons from '../../inputs/AuthorizeRejectButtons';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';

import tableStyles from './styles.module.scss';

export default class AssigneeTable extends Component {
  state = {
    data: [],
    columns: []
  };

  componentDidMount() {
    this.setState({ columns: this.getColumns(), data: this.getData() });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.assigneeApproval, this.props.assigneeApproval)) {
      this.setState({
        columns: cloneDeep(this.getColumns()),
        data: cloneDeep(this.getData())
      });
    }
  }

  getHeaders = (childAttachments, needsApproval) => {
    const headers = ['EMPLOYEE'];

    childAttachments.forEach(attachment => {
      if (!headers.includes(attachment.original_filename.toUpperCase())) {
        headers.push(attachment.original_filename.toUpperCase());
      }
    });

    if (needsApproval) {
      headers.push('AUTHORIZE');
    }

    return headers;
  };

  getData = () => {
    const {
      assignees,
      attachments,
      childAttachments,
      needsApproval,
      assigneeApproval
    } = this.props;
    const data = [];

    assignees.forEach(assignee => {
      const attachmentMap = attachments.map(attachment => {
        const childAttachment = childAttachments.filter(
          ca =>
            ca.assignedTo === assignee &&
            ca.original_filename === attachment.original_filename
        );

        return {
          name: attachment.original_filename,
          timeSpent: childAttachment[0]?.timeSpent
        };
      });

      let approveReject = '';
      if (needsApproval) {
        const index = assigneeApproval.findIndex(
          aa => aa.assignee === assignee
        );

        approveReject = index > -1 ? assigneeApproval[index].approveReject : '';
      }

      data.push({
        assignee,
        approveReject,
        attachments: attachmentMap
      });
    });

    return data;
  };

  getColumns = () => {
    const {
      childAttachments,
      users,
      needsApproval,
      isApprover,
      updateAnswer
    } = this.props;
    const headers = this.getHeaders(childAttachments, needsApproval);
    const columns = [];

    headers.forEach((header, index) => {
      let type = { Header: header, id: header };
      if (index === 0) {
        type.accessor = d => d.assignee;
        type.Cell = ({ value }) => <UserBadge userId={value} showName plain />;
        type.sortMethod = (a, b) => sortUser(a, b, users);
        type.filterMethod = (filter, row) => filterUser(filter, row, users);
      } else if (header === 'AUTHORIZE') {
        type.Cell = ({ original, index }) => {
          if (isApprover) {
            return (
              <AuthorizeRejectButtons
                answer={original.approveReject}
                updateAnswer={values =>
                  updateAnswer({
                    approveReject: values,
                    assignee: original.assignee
                  })
                }
                className={tableStyles.authorizeRejectButtons}
              />
            );
          } else if (original.approveReject) {
            return (
              <Button
                color={original.approveReject === 'reject' ? 'red' : 'blue'}
                text={
                  original.approveReject === 'reject' ? 'Reject' : 'Approve'
                }
              />
            );
          } else {
            return 'In Progress';
          }
        };
        type.filterable = false;
        type.sortable = false;
      } else {
        type.accessor = d =>
          d.attachments.filter(
            attachment => attachment.name.toUpperCase() === header
          )[0];
        type.Cell = ({ original }) => {
          const attachmentInfo = original.attachments.filter(
            attachment => attachment.name.toUpperCase() === header
          )[0];
          if (attachmentInfo?.timeSpent) {
            return `${attachmentInfo.timeSpent} mins`;
          } else {
            return 'Not Opened';
          }
        };
        type.filterable = false;
        type.sortMethod = (a, b) => sortByTimeSpent(a, b);
      }

      columns.push(type);
    });

    return columns;
  };

  render() {
    return (
      <ReactTable
        data={this.state.data}
        filterable
        resizable={true}
        noDataText="No results found"
        defaultPageSize={10}
        className="-striped -highlight"
        columns={this.state.columns}
      />
    );
  }
}
