import React, { Component } from 'react';
import moment from 'moment';
import Button from '../inputs/Button';
import Modal from '../Modal';
import ReactTooltip from 'react-tooltip';

import './index.css';

export default class Osha301List extends Component {
  state = {
    selected: 0,
    openPrintModal: false
  };

  componentDidMount = () => {
    let index = 0;
    if (this.props.selectedRow.caseNumber && this.props.incidentReports) {
      index = this.props.incidentReports.findIndex(
        log => log.caseNumber === this.props.selectedRow.caseNumber
      );
      if (index === -1) index = 0;
    }
    this.selectNewCase(index);
  };

  selectNewCase = index => {
    this.setState({ selected: index });
  };

  getRowClassNames = (index, isIncomplete) => {
    const selected = this.state.selected;

    if (selected === index) {
      return isIncomplete
        ? 'rowSelectedIncomplete isIncomplete'
        : 'rowSelectedComplete isComplete';
    } else {
      return isIncomplete ? 'isIncomplete' : 'isComplete';
    }
  };

  handleOpenModal = values => {
    this.setState(values);
  };

  handleCloseModal = values => {
    this.setState(values);
  };

  render() {
    const {
      incidentReports,
      setActiveIncident,
      printSelectedOsha,
      isPerrp
    } = this.props;

    const {
      openPrintModal,
      selected
    } = this.state;

    const incidentReport = this.props.incidentReports[selected];
    const caseNumber = incidentReport?.caseNumber;
    const claimData = incidentReport?.claimData ?? {};
    const healthCareData = incidentReport?.healthCareData ?? {};
    let workerData = incidentReport?.workerData ?? {};
    if (workerData.privacyConcern) {
      workerData = {
        ...workerData,
        name: 'Privacy Concern Case',
        streetAddress: '------------------',
        city: '----------',
        state: '---',
        zip: '------',
        dateOfBirth: '--/--/--',
        dateOfHire: '--/--/--',
        gender: '------'
      };
    }

    return (
      <div className="osha301List">
        <div className="osha301List-caseList">
          <table className="osha301List-caseList--table">
            <thead>
              <tr>
                <th className="paddingBottom">Case #</th>
                <th className="leftAlign paddingBottom">Worker Name</th>
                <th className="leftAlign paddingBottom">Job Title</th>
              </tr>
            </thead>
            <tbody>
              {incidentReports?.map((item, index) => {
                if (item.isRedLined) return <></>;
                return (
                    <tr
                      key={index}
                      className={this.getRowClassNames(
                        index,
                        item.isIncomplete
                      )}
                      onClick={() => this.selectNewCase(index)}
                    >
                      <td className="osha301List-caseList--caseWidth">
                        {item.isIncomplete && (
                          <div
                            className="inCompleteIcon"
                            onMouseDown={() =>
                              setActiveIncident(item.incidentId)
                            }
                          >
                            <ReactTooltip
                              className="oshaListErrorTheme"
                              id="inCompleteCaseError"
                            >
                              <p>Missing OSHA compliant information.</p>
                              <p>Click to Go Edit Case </p>
                            </ReactTooltip>
                            <img
                              style={{ width: '32px', height: '32px' }}
                              src={require('../../assets/images/incompleteCase.png')}
                              alt="caseIncomplete"
                              data-tip="oshaListErrorTheme"
                              data-for="inCompleteCaseError"
                            />
                          </div>
                        )}
                        {item.caseNumber}
                      </td>
                      <td className="osha301List-caseList--workerName leftAlign">
                        {item.workerData.privacyConcern
                          ? 'Privacy Concern'
                          : item.workerData.name}
                      </td>
                      <td className="osha301List-caseList--jobTitle leftAlign">
                        {item.workerData.privacyConcern
                          ? '-----------------'
                          : item.workerData.jobTitle}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="osha301List-caseInformation">
          <div className="osha301List-caseInformation--leftSide">
            <section
              className="osha301List-caseInformation--section"
            >
              <h4>Worker Information</h4>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Name:
                </span>{' '}
                <div className="underline-fullWidth">{workerData.name}</div>
              </div>
              <div className="osha301List-caseInformation--sectionFieldNoBorder">
                <span className="osha301List-caseInformation--sectionLabel">
                  Street:
                </span>{' '}
                <div className="underline-fullWidth">
                  {workerData.streetAddress}
                </div>
                <div className="osha301List-caseInformation--fieldCombo">
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      City:
                    </span>{' '}
                    <div className="underline-cityWidth">{workerData.city}</div>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      State:
                    </span>{' '}
                    <span className="underline">{workerData.state}</span>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      ZIP:
                    </span>{' '}
                    <span className="underline">{workerData.zip}</span>
                  </div>
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <div className="osha301List-caseInformation--fieldCombo">
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      Date Of Birth:
                    </span>{' '}
                    <span className="underline">
                      {workerData.dateOfBirth !== '--/--/--' &&
                      workerData.dateOfBirth !== ''
                        ? moment(workerData.dateOfBirth).format('MM/DD/YYYY')
                        : workerData.dateOfBirth}
                    </span>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      Hired:
                    </span>{' '}
                    <span className="underline">
                      {workerData.dateOfHire !== '' && workerData.dateOfHire !== '--/--/--'
                        ? moment(workerData.dateOfHire).format('MM/DD/YYYY')
                        : workerData.dateOfHire}
                    </span>
                  </div>
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Gender:{' '}
                </span>{' '}
                <span className="underline">{workerData.gender}</span>
              </div>
            </section>
            <section className="osha301List-caseInformation--section">
              <h4>Health Care Professional Information</h4>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Health Care Professional's Name:
                </span>{' '}
                <div className="underline-allWidth">
                  {healthCareData.doctorsName}
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionFieldNoBorder">
                <div style={{ marginBottom: '10px' }}>
                  <span className="osha301List-caseInformation--sectionLabel">
                    Facility:
                  </span>{' '}
                  <div className="underline-justUnderFullWidth">
                    {healthCareData.facilityName
                      ? healthCareData.facilityName
                      : 'N/A'}
                  </div>
                </div>
                <div>
                  <span className="osha301List-caseInformation--sectionLabel">
                    Street:
                  </span>{' '}
                  <div className="underline-fullWidth">
                    {healthCareData.streetAddress
                      ? healthCareData.streetAddress
                      : 'N/A'}
                  </div>
                </div>
                <div className="osha301List-caseInformation--fieldCombo">
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      City:
                    </span>{' '}
                    <div className="underline-cityWidth">
                      {healthCareData.city ? healthCareData.city : 'N/A'}
                    </div>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      State:
                    </span>{' '}
                    <span className="underline">
                      {healthCareData.state ? healthCareData.state : 'N/A'}
                    </span>
                  </div>
                  <div>
                    <span className="osha301List-caseInformation--sectionLabel">
                      ZIP:
                    </span>{' '}
                    <span className="underline">
                      {healthCareData.zip ? healthCareData.zip : 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Emergency Room:
                </span>{' '}
                <span className="underline">
                  {healthCareData.isEmergencyRoom === 1 ? 'Yes' : 'No'}
                </span>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Overnight as Inpatient:
                </span>{' '}
                <span className="underline">
                  {healthCareData.isOvernightAsInpatient === 1 ? 'Yes' : 'No'}
                </span>
              </div>
              {isPerrp && (
                <div className="osha301List-caseInformation--sectionField">
                  <span className="osha301List-caseInformation--sectionLabel">
                    Employee Received First Aid Treatment:
                  </span>{' '}
                  <span className="underline">
                    {healthCareData.employeeReceiveTreatment === 1
                      ? 'Yes'
                      : 'No'}
                  </span>
                </div>
              )}
            </section>
          </div>
          <div className="osha301List-caseInformation--rightSide">
            <section className="osha301List-caseInformation--section">
              <h4>Case Information</h4>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Case Number:
                </span>{' '}
                <div className="underline-caseNumberWidth">{caseNumber}</div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Date Of Injury or Illness:
                </span>{' '}
                <span className="underline">
                  {claimData.dateOfClaim !== ''
                    ? moment(claimData.dateOfClaim).format('MM/DD/YYYY')
                    : claimData.dateOfClaim}
                </span>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Time worker began work:
                </span>{' '}
                <span className="underline">{claimData.timeWorkerBegan}</span>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Time of Incident:
                </span>{' '}
                <span className="underline">
                  {claimData.timeNotDetermined
                    ? 'Cannot be determined'
                    : moment(claimData.timeOfIncident).format('MM/DD/YYYY hh:mm a')}
                </span>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  What was the worker doing prior to incident?
                </span>{' '}
                <div className="underline-allWidth">
                  {claimData.activityBeforeIncident}
                </div>
                <div className="underline-allWidth"> </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  What happened?
                </span>{' '}
                <div className="underline-allWidth">
                  {claimData.descriptionOfClaim}
                </div>
                <div className="underline-allWidth"> </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  What was illness or injury?
                </span>{' '}
                <div className="underline-allWidth">
                  {Array.isArray(claimData.injuryIllnessClassification)
                    ? claimData.injuryIllnessClassification.join(' ')
                    : ' '}
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  What directly harmed the worker?
                </span>{' '}
                <div className="underline-allWidth">
                  {claimData.whatHarmedWorker}
                </div>
              </div>
              <div className="osha301List-caseInformation--sectionField">
                <span className="osha301List-caseInformation--sectionLabel">
                  Date of Death:
                </span>{' '}
                <span className="underline">
                  {claimData.dateOfDeath ? claimData.dateOfDeath : 'N/A'}
                </span>
              </div>
            </section>
          </div>
        </div>
        <Button
          text="Download Report"
          className="blueButton osha301List-button"
          onClick={() =>
            incidentReport?.isIncomplete
              ? this.handleOpenModal({ openPrintModal: true })
              : printSelectedOsha({ incidentId: incidentReport?.incidentId })
          }
        />
        <Modal
          title="Download Incident Report"
          titleClassName="redHeader"
          isOpen={openPrintModal}
          submitButtonColor="blue"
          submitButtonText="Download Incomplete Report"
          onRequestClose={() =>
            this.handleCloseModal({ openPrintModal: false })
          }
          submitActions={() =>
            printSelectedOsha({ incidentId: incidentReport?.incidentId }) &&
            this.handleCloseModal({ openPrintModal: false })
          }
        >
          <p className="osha301List-incompleteModal">
            {' '}
            This report is missing information required by OSHA. Are you sure
            you want to download it?
          </p>
        </Modal>
      </div>
    );
  }
}
