import React from 'react';
import { BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

const CustomTooltip = props => {
  const { active } = props;

  if (active) {
    const { payload, label, tooltipHasPercentage } = props;

    return (
      <div className="custom-tooltip">
        <p>
          {label}: {payload[0].value}
          {tooltipHasPercentage ? '%' : ''}
        </p>
      </div>
    );
  }

  return null;
};

const CustomizedYAxisTick = props => {
  const { x, y, payload, width, fontSize, fill } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        width={width}
        height="auto"
        textAnchor="end"
        fontSize={fontSize}
        fill={fill}
      >
        <tspan x={0} y={0} dy={0}>
          {payload.value.substring(payload.value, 5)}&#8230;
        </tspan>
      </text>
    </g>
  );
};

CustomizedYAxisTick.defaultProps = {
  width: 50,
  maxChars: 10,
  fontSize: 12,
  lineHeight: 14,
  fill: '#333'
};

const CustomizedXAxisTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize={12}>
        {payload.value}%
      </text>
    </g>
  );
};

const VerticalBarChartComponent = ({
  data,
  height,
  width,
  children,
  customToolTip,
  customYAxisLabel,
  customXAxisLabel,
  tooltipHasPercentage
}) => (
  <BarChart width={width} height={height} data={data} layout="vertical">
    <CartesianGrid strokeDasharray="3 3" />
    {customXAxisLabel ? (
      <XAxis type="number" tick={<CustomizedXAxisTick />} />
    ) : (
      <XAxis type="number" />
    )}
    {customYAxisLabel ? (
      <YAxis dataKey="name" type="category" tick={<CustomizedYAxisTick />} />
    ) : (
      <YAxis dataKey="name" type="category" />
    )}
    {customToolTip ? (
      <Tooltip
        content={<CustomTooltip />}
        wrapperStyle={{
          width: 200,
          backgroundColor: '#fff',
          border: '1px solid #ccc'
        }}
        tooltipHasPercentage={tooltipHasPercentage}
      />
    ) : (
      <Tooltip />
    )}
    {children}
  </BarChart>
);

export default VerticalBarChartComponent;
