import { createSelector } from 'reselect';

const trainingSelector = state => state.training;

export const getTemplateListSelector = createSelector(
  trainingSelector,
  training => training && training.templates
);

export const getActiveTemplateSelector = createSelector(
  trainingSelector,
  training => training && training.activeTrainingTemplate
);

export const getTrainingListSelector = createSelector(
  trainingSelector,
  training => training && training.list
);

export const getActiveTrainingSelector = createSelector(
  trainingSelector,
  training => training && training.activeTraining
);

export const getActiveTrainingAttachmentSelector = createSelector(
  trainingSelector,
  training => training && training.activeTrainingAttachment
);
