const severityDescriptionMapper = severity => {
  switch (severity) {
    case 0:
    case '0':
    case '0- Immediately Corrected':
      return 'Immediately Corrected';

    case 1:
    case '1':
    case '1- Advisory':
      return 'Advisory';

    case 2:
    case '2':
    case '2- Minor':
      return 'Minor';

    case 3:
    case '3':
    case '3- Moderate':
      return 'Moderate';

    case 4:
    case '4':
    case '4- Serious':
      return 'Serious';

    case 5:
    case '5':
    case '5- Critical':
      return 'Critical';

    case 'red':
      return 'High';

    case 'yellow':
      return 'Medium';

    case 'green':
      return 'Low';

    default:
      return 'Advisory';
  }
};

export default severityDescriptionMapper;
