import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { clearUploadedAttachments } from '../actions/attachments';
import { newUserRegistrationResponse } from '../actions/auth';
import {
  addEmployeeDocumentResponse,
  addLocationEmployeeResponse,
  deleteEmployeeDocumentResponse,
  deleteLocationEmployeeResponse,
  downloadEmployeeQRcodeResponse,
  fetchCompanyEmployeesResponse,
  fetchCompanyUsersResponse,
  setActiveEmployeeResponse,
  updateEmployeeDocumentResponse,
  updateLocationEmployeeResponse,
  uploadErrorResponse
} from '../actions/personnel';
import { addMessage, removeMessage } from '../actions/messages';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getActiveEmployeeSelector } from '../selectors/personnel';

export function* fetchActiveEmployees(api, { payload }) {
  try {
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);
    const activeCompany = yield select(getActiveCompany);

    payload = payload || {};

    if (payload?.projectIds || activeProject) {
      payload.projectIds = payload?.projectIds || activeProject._id;
    } else if (payload?.groupIds || activeGroup) {
      payload.groupIds = payload?.groupIds || activeGroup._id;
    }

    let response = yield call(api.fetchActiveEmployees, {
      ...payload,
      companyIds: activeCompany._id
    });

    yield put(fetchCompanyEmployeesResponse({ activeEmployees: response }));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchAllEmployeesActiveUsers(api, { payload }) {
  try {
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);
    const activeCompany = yield select(getActiveCompany);

    payload = payload || {};

    if (payload?.projectIds || activeProject) {
      payload.projectIds = payload?.projectIds || activeProject._id;
    } else if (payload?.groupIds || activeGroup) {
      payload.groupIds = payload?.groupIds || activeGroup._id;
    }
    const response = yield call(api.fetchAllEmployeesAllUsers, {
      ...payload,
      companyIds: activeCompany._id
    });

    yield put(fetchCompanyUsersResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* addLocationEmployee(api, { payload }) {
  try {
    yield put(removeMessage());

    const activeCompany = yield select(getActiveCompany);

    const workDays = payload.workDays
      ? payload.workDays.map(day => {
          return day.value;
        })
      : [];

    const companies = payload.companyId
      ? payload.companyId.map(company => {
          return company.value;
        })
      : '';

    const groups = payload.locationId
      ? payload.locationId.map(group => {
          return group.value;
        })
      : '';

    const projects = payload.projectId
      ? payload.projectId.map(p => p.value)
      : [];

    if (payload.userAccount) {
      if (payload.userAccount.userType === 'google') {
        payload.userAccount = {
          ...payload.userAccount,
          companyIds: companies,
          groupIds: groups,
          projectIds: projects,
          firstName: payload.person.firstName,
          lastName: payload.person.lastName,
          isGoogleUser: true,
          email: payload.email,
          phoneNumber: payload.phoneNumber
        };
      } else if (payload.userAccount.userType === 'microsoft') {
        payload.userAccount = {
          ...payload.userAccount,
          companyIds: companies,
          groupIds: groups,
          projectIds: projects,
          firstName: payload.person.firstName,
          lastName: payload.person.lastName,
          isMicrosoftUser: true,
          email: payload.email,
          phoneNumber: payload.phoneNumber
        };
      } else {
        payload.userAccount = {
          ...payload.userAccount,
          companyIds: companies,
          groupIds: groups,
          projectIds: projects,
          firstName: payload.person.firstName,
          lastName: payload.person.lastName,
          email: payload.email,
          phoneNumber: payload.phoneNumber
        };
      }
      delete payload.userAccount.userType;
    }

    payload = {
      ...payload,
      companyIds: companies,
      groupIds: groups,
      projectIds: projects,
      workDays: workDays,
      dateOfHire: moment(payload.dateOfHire),
      dateOfTermination: payload.dateOfTermination
        ? moment(payload.dateOfTermination)
        : '',
      person: {
        ...payload.person,
        dateOfBirth: payload.dateOfBirth ? moment(payload.dateOfBirth) : '',
        phoneNumbers: [
          {
            fullPhoneNumber: payload.phone
          }
        ],
        addresses: [
          {
            streetAddress: payload.homeAddress,
            city: payload.city,
            stateName: payload.state,
            zip: payload.zip
          }
        ],
        emails: [
          {
            emailAddress: payload.email,
            isPrimary: true
          }
        ],
        emergencyContact: {
          ...payload.person.emergencyContact
        }
      }
    };

    delete payload.projectId;
    delete payload.locationId;
    delete payload.companyId;

    const response = yield call(api.addEmployee, activeCompany._id, payload);

    yield put(addLocationEmployeeResponse(response));
    yield put(newUserRegistrationResponse(false));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateLocationEmployee(api, { payload }) {
  const activeEmployee = yield select(getActiveEmployeeSelector);
  const activeCompany = yield select(getActiveCompany);
  try {
    yield put(removeMessage());

    const workDays = payload.workDays
      ? payload.workDays.map(day => {
          if (day.value) {
            return day.value;
          }

          return day;
        })
      : [];

    const companies = Array.isArray(payload.companyId)
      ? payload.companyId.map(day => {
          if (day.value) {
            return day.value;
          }

          return day;
        })
      : payload.companyIds;

    const groups = Array.isArray(payload.locationId)
      ? payload.locationId.map(g => g?.value ?? g)
      : payload.groupIds;

    const projects = Array.isArray(payload.projectId)
      ? payload.projectId.map(day => {
          if (day.value) {
            return day.value;
          }

          return day;
        })
      : payload.projectIds;

    payload = {
      ...payload,
      groupIds: groups,
      companyIds: companies,
      workDays: workDays,
      dateOfHire: moment(payload.dateOfHire),
      projectIds: projects,
      dateOfTermination: payload.dateOfTermination
        ? moment(payload.dateOfTermination)
        : '',
      person: {
        ...payload.person,
        dateOfBirth: payload.dateOfBirth
          ? moment(payload.dateOfBirth)
          : moment(payload.person.dateOfBirth),
        phoneNumbers: [
          {
            fullPhoneNumber: payload.phone
              ? payload.phone
              : payload.person.phoneNumbers.length > 0
              ? payload.person.phoneNumbers[0].fullPhoneNumber
              : ''
          }
        ],
        addresses: [
          {
            streetAddress: payload.homeAddress
              ? payload.homeAddress
              : payload.person.addresses.length > 0
              ? payload.person.addresses[0].streetAddress
              : '',
            city: payload.city
              ? payload.city
              : payload.person.addresses.length > 0
              ? payload.person.addresses[0].city
              : '',
            stateName: payload.state
              ? payload.state
              : payload.person.addresses.length > 0
              ? payload.person.addresses[0].stateName
              : '',
            zip: payload.zip
              ? payload.zip
              : payload.person.addresses.length > 0
              ? payload.person.addresses[0].zip
              : ''
          }
        ],
        emails: [
          {
            emailAddress: payload.email
              ? payload.email
              : payload.person.emails.length > 0
              ? payload.person.emails[0].emailAddress
              : '',
            isPrimary: true
          }
        ],
        emergencyContact: {
          ...payload.person.emergencyContact
        }
      }
    };

    delete payload.projectId;
    delete payload.locationId;
    delete payload.companyId;

    const updateResponse = yield call(
      api.updateEmployee,
      activeCompany._id,
      payload
    );

    yield put(updateLocationEmployeeResponse(updateResponse));
    yield put(
      setActiveEmployeeResponse({
        ...updateResponse,
        safetyTrainings: payload.safetyTrainings,
        documents: payload.documents,
        qualifications: payload.qualifications,
        incidents: payload.incidents,
        userAccount: payload.userAccount
      })
    );

    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
    yield put(
      setActiveEmployeeResponse({
        ...activeEmployee
      })
    );
  }
}

export function* deleteLocationEmployee(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);

    yield call(api.deleteEmployee, payload);

    let filters = {};

    if (activeProject) {
      filters.projectId = activeProject._id;
    } else if (activeGroup) {
      filters.groupId = activeProject._id;
    }

    let response = yield call(api.fetchActiveEmployees, filters);

    yield put(fetchCompanyEmployeesResponse({ activeEmployees: response }));
    yield put(deleteLocationEmployeeResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* setActiveEmployee(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);

    let employee = { ...payload };
    let empTrainings;
    let empQualifications;
    let empIncidents;

    if (activeProject) {
      const { trainings, qualifications, incidents } = yield all({
        trainings: call(
          api.fetchEmployeeSafetyTrainingByProject,
          payload,
          activeProject
        ),
        qualifications: call(
          api.fetchEmployeeQualificationsByProject,
          payload,
          activeProject
        ),
        incidents: call(
          api.fetchEmployeeIncidentsByProject,
          payload,
          activeProject
        )
      });

      empTrainings = trainings;
      empQualifications = qualifications;
      empIncidents = incidents;
    } else if (activeGroup) {
      const { trainings, qualifications, incidents } = yield all({
        trainings: call(
          api.fetchEmployeeSafetyTrainingByGroup,
          payload,
          activeGroup
        ),
        qualifications: call(
          api.fetchEmployeeQualificationsByGroup,
          payload,
          activeGroup
        ),
        incidents: call(api.fetchEmployeeIncidentsByGroup, payload, activeGroup)
      });

      empTrainings = trainings;
      empQualifications = qualifications;
      empIncidents = incidents;
    } else {
      const { trainings, qualifications, incidents } = yield all({
        trainings: call(
          api.fetchEmployeeSafetyTrainingByCompany,
          payload,
          activeCompany
        ),
        qualifications: call(
          api.fetchEmployeeQualificationsByCompany,
          payload,
          activeCompany
        ),
        incidents: call(
          api.fetchEmployeeIncidentsByCompany,
          payload,
          activeCompany
        )
      });

      empTrainings = trainings;
      empQualifications = qualifications;
      empIncidents = incidents;
    }

    let attachments = yield all(
      empQualifications.documents.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          document.companyId,
          document._id,
          'documents'
        )
      )
    );

    empQualifications.documents.map(
      (res, index) => (res.attachments = attachments[index])
    );

    employee.safetyTrainings = empTrainings;
    employee.incidents = empIncidents;
    employee.qualifications = empQualifications;

    if (employee.userAccount && employee.userAccount._id) {
      const systemInteractions = yield call(
        api.getUserInteractions,
        employee.userAccount._id
      );

      employee.userAccount = systemInteractions;
    }

    yield put(setActiveEmployeeResponse(employee));
  } catch (e) {
    console.log(e);
  }
}

export function* importEmployee(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);
    const uploadedFiles = yield select(getAddedAttachmentsSelector);

    yield call(api.importEmployees, {
      companyId: activeCompany._id,
      sourceUrl: uploadedFiles[0].source_url
    });

    let filters = {};

    if (activeProject) {
      filters.projectId = activeProject._id;
    } else if (activeGroup) {
      filters.groupId = activeGroup._id;
    }

    let response = yield call(api.fetchActiveEmployees, filters);

    yield put(fetchCompanyEmployeesResponse({ activeEmployees: response }));

    yield put(addMessage({ error: false, message: 'Imported Successfully!' }));
    yield put(clearUploadedAttachments());
  } catch (e) {
    yield put(uploadErrorResponse({ error: true, message: e }));
  }
}

export function* downloadPersonnelQRCode(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    const response = yield call(api.fetchQrCodeSheet, {
      companyId: activeCompany._id,
      ids: payload.ids
    });

    const link = document.createElement('a');
    link.href = `${response}`;
    link.target = '_blank';
    link.download = `Employee QR Codes`;
    link.click();

    yield put(downloadEmployeeQRcodeResponse());
  } catch (e) {
    console.log(e);
  }
}

export function* addEmployeeDoc(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const activeCompany = yield select(getActiveCompany);
    const activeEmployee = yield select(getActiveEmployeeSelector);

    payload = {
      label: payload.title,
      expires: moment(Date.parse(payload.dateToBeRevised)),
      documentType: 'EmployeeDocument',
      companyId: activeCompany._id,
      employees: [activeEmployee._id]
    };

    const response = yield call(api.addDocument, activeCompany._id, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    yield put(addEmployeeDocumentResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateEmployeeDoc(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const activeCompany = yield select(getActiveCompany);

    payload = {
      ...payload,
      label: payload.title,
      expires: moment(Date.parse(payload.dateToBeRevised)),
      documentType: 'EmployeeDocument',
      companyId: activeCompany._id
    };

    const response = yield call(api.updateDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = [...payload.attachments, ...newAttachments];

    yield put(updateEmployeeDocumentResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteEmployeeDoc(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteDocument, payload);

    yield put(deleteEmployeeDocumentResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* resetEmployeesPassword(api, { payload }) {
  try {
    yield put(removeMessage());
    const response = yield call(api.resetEmployeesPassword, payload);

    yield put(addMessage({ error: false, message: response.data }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* resendWelcomeNotification(api, { payload }) {
  try {
    yield put(removeMessage());
    const response = yield call(api.resendWelcomeNotification, payload);

    yield put(addMessage({ error: false, message: response.data }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}
