import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import ReactLoading from 'react-loading';
import { updateUserRequest } from '../../actions/user';
import { getUserCompaniesSelector } from '../../selectors/company';
import { getMessageSelector } from '../../selectors/messages';
import { getLoggedInUser, getUserLoading } from '../../selectors/users';
import Modal from '../../components/Modal';
import ProfileForm from '../../forms/ProfileForm';

export class ViewEditProfile extends Component {
  state = {
    redirect: false,
    openWarningModal: false,
    modalTitle: '',
    modalText: ''
  };

  componentDidMount = () => {};

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }

    if (
      this.props.match.params.editType &&
      prevProps.match.params.editType !== this.props.match.params.editType
    ) {
    }
  }

  handleState = updateStateObject => {
    this.setState(updateStateObject);
  };

  onSubmit = () => {
    if (this.props.activeUser.username !== this.state.formValues.username) {
    }

    this.props.updateUser({
      ...this.state.formValues,
      data: this.state.profileFormData
    });
    this.setState({ redirect: true, openWarningModal: false });
  };

  render() {
    const {
      redirect,
      openWarningModal,
      modalText,
      modalTitle,
      modalColor
    } = this.state;
    const { userCompanies, activeUser, loading } = this.props;

    return (
      <>
        {loading ? (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        ) : (
          <ProfileForm
            onSubmit={async values => {
              if (!values.email && !values.phone) {
                await this.setState({
                  formValues: values,
                  openWarningModal: true,
                  modalText:
                    'Without adding a email or phone, you will not get notifications. Are you sure',
                  modalTitle: 'No email or phone?',
                  modalColor: 'red'
                });
              } else if (values.username !== activeUser.username) {
                await this.setState({
                  formValues: values,
                  openWarningModal: true,
                  modalText:
                    'Remember this will be your new login after saving! Your password will remain the same',
                  modalTitle: 'Changing User Name?',
                  modalColor: 'blue'
                });
              } else {
                await this.setState({ formValues: values });
                this.onSubmit();
              }
            }}
            initialValues={{
              ...activeUser,
              notificationType: activeUser.shouldStopNotifications
                ? '3'
                : activeUser.notificationType
            }}
            activeUser={activeUser}
            userCompanies={userCompanies}
            redirect={redirect}
            onCancel={() => this.setState({ redirect: true })}
            addAttachment={updateStateObject =>
              this.handleState(updateStateObject)
            }
          />
        )}
        <Modal
          title={modalTitle}
          titleClassName={`${modalColor}Header`}
          isOpen={openWarningModal}
          submitButtonColor={modalColor}
          submitButtonText="Save Changes"
          onRequestClose={() => this.setState({ openWarningModal: false })}
          submitActions={() => this.onSubmit()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            {modalText}
          </div>
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  activeUser: getLoggedInUser(state),
  userCompanies: getUserCompaniesSelector(state),
  message: getMessageSelector(state),
  loading: getUserLoading(state)
});

export const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUserRequest(user))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(ViewEditProfile)
);
