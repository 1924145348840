export const assignedToMapper = (companyUsers, list) => {
  let assignedUsers = [];
  companyUsers.forEach(user => {
    if (list.indexOf(user._id) > -1) {
      assignedUsers.push(`${user.person.firstName} ${user.person.lastName}`);
    }
  });

  return assignedUsers.join(', ');
};

export const getUserInfo = (userId, companyUsers) => {
  if (!userId || userId === 'unassigned') return { userAccount: {} };

  const companyUser = companyUsers.filter(
    user => user.userAccount && user.userAccount._id === userId
  );

  if (companyUser.length === 0)
    return {
      firstName: 'No User',
      lastName: 'Found'
    };

  return {
    ...companyUser[0].userAccount,
    profilePictureUrl: companyUser[0].userAccount.profilePictureUrl,
    firstName: companyUser[0].person.firstName,
    lastName: companyUser[0].person.lastName
  };
};
