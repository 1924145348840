import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router';


import {
  normalizePhone,
  renderDropdownField,
  renderField,
  required
} from '../../utils/formValidation';
import {
  NOTIFICATION_TYPE_LIST,
  REPORT_COMMENT_NOTIFICATION_LIST,
  REPORT_NOTIFICATION_LIST,
  USER_ROLES
} from '../../constants/constants';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import ProfilePicture from '../../components/ProfilePicture';
import Card from '../../components/Card';

import '../../components/UserProfile/index.css';
import '../../components/ScheduledAuditWidget/index.css';
import '../form.css';

let EditProfileForm = props => {
  const {
    activeUser,
    userCompanies,
    redirect,
    onCancel,
    handleSubmit,
    addAttachment
  } = props;

  let buttons = (
    <InputRow>
      <Button text="Update" color="blue" type="submit" />
      <Button text="Cancel" color="white" onClick={onCancel} />
    </InputRow>
  );

  return (
    <form className="editProfileForm" onSubmit={handleSubmit}>
      <Card title="Profile Settings" showHeader rightButton={buttons}>
        <div className="form-fields--logo">
          <div className="form-fields">
            <img
              src={
                activeUser.profilePictureUrl ||
                require('../../assets/images/blank-profile-picture.jpg')
              }
              alt={`${activeUser.firstName}'s`}
            />
          </div>
          <div className="form-fields">
            <Field
              name="attachments"
              type="text"
              component={ProfilePicture}
              placeholder={''}
              activeUserId={activeUser._id}
              addAttachment={addAttachment}
            />
          </div>
        </div>
        <div className="form-fields--split">
          <div className="form-fields--leftSide">
            <Field
              name="firstName"
              type="text"
              component={renderField}
              label="First Name:"
              validate={[required]}
              placeholder={''}
              requiredLabel={true}
            />
            <Field
              name="lastName"
              type="text"
              component={renderField}
              label="Last Name:"
              validate={[required]}
              placeholder={''}
              requiredLabel={true}
            />
            <Field
              name="username"
              type="text"
              component={renderField}
              label="Username:"
              validate={[required]}
              placeholder="Enter a username"
            />
            <Field
              name="phoneNumber"
              type="text"
              component={renderField}
              label="Phone:"
              placeholder="(000) 000-0000"
              normalize={normalizePhone}
            />
            <Field
              name="email"
              type="text"
              component={renderField}
              label="Email:"
              placeholder="eg@email.com"
            />
            <Field
              name="notificationType"
              type="text"
              component={renderDropdownField}
              label="Notify By:"
              placeholder="Select phone or email.."
              options={NOTIFICATION_TYPE_LIST}
            />
            <Field
              name="reportNotificationSetting"
              type="text"
              component={renderDropdownField}
              label="Report Notification:"
              options={REPORT_NOTIFICATION_LIST}
            />
            <Field
              name="reportCommentNotificationSetting"
              type="text"
              component={renderDropdownField}
              label="Report Comment Notification:"
              options={REPORT_COMMENT_NOTIFICATION_LIST}
            />
          </div>
          <div className="form-fields--rightSide">
            <div className="label">Companies:</div>
            <ul className="userProfileList-list companyList-edit">
              {userCompanies.map((company, index) => (
                <li key={index}>
                  <div className="companyList-edit--name">{company.name}</div>
                  <div className="accessLevel">
                    {
                      USER_ROLES[
                        USER_ROLES.findIndex(
                          level => level.value === activeUser.accessLevel
                        )
                      ].label
                    }
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      {redirect && <Redirect to="/app/Profile" />}
      </Card>
    </form>
  );
};

export default EditProfileForm = reduxForm({
  form: 'EditProfileForm'
  // enableReinitialize: true
})(EditProfileForm);
