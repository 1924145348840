import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="30px"
    height="25px"
    viewBox="0 0 90 90"
  >
    <g>
      <polygon
        points="35.9,28.9 6.5,60.3 10.9,64.4 36.7,36.7 55.3,49.2 83.3,18.5 83.3,35 89.3,35 89.3,7.8 62,7.8 
      62,13.8 79.5,13.8 54.3,41.4 	"
      />
      <polygon
        points="2.5,92.2 97.5,92.2 97.5,86.2 89,86.2 89,46.5 83,46.5 83,86.2 77,86.2 77,39.5 71,39.5 71,86.2 
      65,86.2 65,52.5 59,52.5 59,86.2 53,86.2 53,58.5 47,58.5 47,86.2 41,86.2 41,47.5 35,47.5 35,86.2 29,86.2 29,60.5 23,60.5 
      23,86.2 17,86.2 17,71.5 11,71.5 11,86.2 2.5,86.2 	"
      />
    </g>
  </svg>
);
