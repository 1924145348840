import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isValid, submit } from 'redux-form';
import { withAlert } from 'react-alert';
import ReactLoading from 'react-loading';
import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import {
  addCompanyLocationRequest,
  addCompanyRequest,
  addProjectRequest,
  setActiveCompany,
  setActiveGroups,
  setActiveLocation,
  setActiveProject,
  setAllLocations,
  updateCompanyLocationRequest,
  updateCompanyRequest
} from '../../actions/company';
import {
  addLocationEmployeeRequest,
  fetchAllActiveEmployeesActiveUsers
} from '../../actions/personnel';
import { createUserSecurityQuestionsRequest } from '../../actions/user';
import { SECURITY_QUESTIONS_OPTIONS } from '../../constants/constants';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { isFirstTimeLogin } from '../../selectors/auth';
import {
  getActiveCompany,
  getActiveLocationId,
  getAllLocations,
  getUserCompaniesSelector
} from '../../selectors/company';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import CompanyLogo from '../../components/CompanyLogo';
import CompanyCard from '../../components/CompanyCard';
import CompanyForm from '../../forms/CompanyForm';
import Dropdown from '../../components/inputs/Dropdown';
import Modal from '../../components/Modal';
import NewLocationForm from '../../forms/NewLocationForm';
import UserBadge from '../../components/UserBadge';
import Textbox from '../../components/inputs/Textbox';
import { UserAgentApplication } from 'msal';
import config from '../../../src/config/config';

import './index.css';

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: 0,
      modalCompany: {},
      modalGroup: {},
      projectName: '',
      showLongTermProjects: false,
      showShortTermProjects: false,
      assignIncidentOwnerModal: false,
      reassignUser: undefined,
      securityQuestionsModalOpen: false,
      securityQuestion1: '',
      securityQuestion2: '',
      securityAnswer1: '',
      securityAnswer2: ''
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSecurityQuestionsOpenModal = this.handleSecurityQuestionsOpenModal.bind(
      this
    );
    this.handleSecurityQuestionsCloseModal = this.handleSecurityQuestionsCloseModal.bind(
      this
    );
  }

  handleOpenModal(company, group, modalId) {
    this.setState({
      modalOpen: modalId,
      modalCompany: company,
      modalGroup: group,
      projectName: ''
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: 0 });
  }

  handleSecurityQuestionsCloseModal() {
    this.setState({ securityQuestionsModalOpen: false });
  }

  handleSecurityQuestionsOpenModal() {
    if (!this.props.user.isMicrosoftUser) {
      this.setState({
        securityQuestionsModalOpen: true
      });
    }
  }

  handleGoToDashboard = () => {
    this.handleCloseModal();

    history.push('/app/dashboard');
  };

  handleNewCompany = async values => {
    await this.props.addCompany({ ...values, isOnboarding: true });
    this.handleOpenModal({}, {}, 1);
  };

  handleNewGroup = async values => {
    await this.props.addCompanyLocation({ ...values, isOnboarding: true });
    this.handleGoToDashboard();
  };

  handleUpdateState = stateObject => {
    this.setState(stateObject);
  };

  handleProjectSelection = project => {
    this.props.setActiveCompany(this.state.modalCompany);
    this.props.setActiveLocation(this.state.modalGroup);
    this.props.setActiveProject(project);
    history.push('/app/dashboard');
  };

  componentDidMount() {
    const msalConfig = {
      auth: config.auth
    };
    new UserAgentApplication(msalConfig);
    if (this.props.firstTimeLogin && !this.props.user.companies) {
      this.setState({ modalOpen: 4 });
    } else if (
      !this.props.user.email &&
      !this.props.user.phoneNumber &&
      !this.props.user.securityQuestions
    ) {
      this.handleSecurityQuestionsOpenModal(this.props.user);
    }
  }

  handleNewCompanySubmit = (values, user) => {
    this.props.addCompany({ ...values });
    this.handleCloseModal();
  };

  handleNewLocationSubmit = (values, user, modalCompany) => {
    this.props.setActiveCompany(modalCompany);
    this.props.addCompanyLocation(values);
    this.handleCloseModal();
  };

  handleNewProjectSubmit = (companyId, groupId, name, user) => {
    this.props.addProject({
      companyId,
      groupId,
      name
    });
    this.handleCloseModal();
  };

  openAssigneeModal = async (stateObject, company, group) => {
    const { fetchAllUsers, setActiveCompany, setActiveLocation } = this.props;

    await setActiveCompany(company);
    await setActiveLocation(group);
    await fetchAllUsers();

    this.setState(stateObject);
  };

  submitDefaultIncidentOwner = () => {
    const {
      updateCompany,
      updateGroup,
      activeLocation,
      activeCompany
    } = this.props;
    const { reassignUser } = this.state;

    activeLocation
      ? updateGroup({
          ...activeLocation,
          defaultIncidentOwner: {
            userId: reassignUser
          }
        })
      : updateCompany({
          ...activeCompany,
          defaultIncidentOwner: {
            userId: reassignUser
          }
        });

    this.setState({ assignIncidentOwnerModal: false });
  };

  render() {
    const {
      setActiveCompany,
      setActiveLocation,
      user,
      allLocations,
      addedAttachments,
      addAttachment,
      removeAttachment,
      companyValid,
      establishmentValid,
      firstTimeLogin,
      setActiveGroups,
      setActiveProject,
      companyUsers,
      saveUserSecurityQuestions
    } = this.props;

    const {
      modalOpen,
      modalCompany,
      modalGroup,
      projectName,
      assignIncidentOwnerModal,
      reassignUser,
      securityQuestionsModalOpen
    } = this.state;

    const companies = this.props.userCompanies;

    return (
      <div className="home">
        <div className="home-header">
          {user.accessLevel === '900' ? (
            <button
              className="button whiteButton home-header-newCompany"
              onClick={() => this.handleOpenModal({}, {}, 2)}
            >
              <div className="button-text">New Company Division</div>
            </button>
          ) : (
            <div />
          )}
        </div>
        {companies === 0 && (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        )}
        {companies !== 0 &&
          companies.map((company, index) => (
            <div key={index}>
              <CompanyLogo
                imageSource={company.logoUrl ? company.logoUrl : ''}
                altText={company.name}
                company={company}
                setActiveCompany={setActiveCompany}
                setActiveLocation={setActiveLocation}
                setActiveProject={setActiveProject}
                allLocations={allLocations}
                setActiveGroups={setActiveGroups}
                user={user}
                assignIncidentOwner={value =>
                  this.openAssigneeModal(
                    {
                      assignIncidentOwnerModal: true,
                      reassignUser: value
                    },
                    company
                  )
                }
              />
              <div className="home-locations">
                <h3>Long Term Establishment</h3>
                {company.groups &&
                  company.groups
                    .filter(
                      group =>
                        group.isSeparateEstablishment &&
                        group.operationalForYearOrLonger
                    )
                    .map((location, index) => (
                      <div className="location-outerDiv" key={index}>
                        <CompanyCard
                          location={location}
                          company={company}
                          locationId={location._id}
                          setActiveCompany={setActiveCompany}
                          setActiveLocation={setActiveLocation}
                          setActiveProject={setActiveProject}
                          allLocations={allLocations}
                          setActiveGroups={setActiveGroups}
                          openProjectModal={() =>
                            this.handleOpenModal(company, location, 5)
                          }
                          projects={location.projects}
                          openProjectDropdown={() =>
                            this.handleUpdateState({
                              modalCompany: company,
                              modalGroup: location
                            })
                          }
                          handleProjectSelection={project =>
                            this.handleProjectSelection(project)
                          }
                          user={user}
                          assignIncidentOwner={value =>
                            user.accessLevel !== '400' &&
                            user.accessLevel !== '100' &&
                            this.openAssigneeModal(
                              {
                                assignIncidentOwnerModal: true,
                                reassignUser: value
                              },
                              company,
                              location
                            )
                          }
                        />
                      </div>
                    ))}
                {user.accessLevel === '900' ? (
                  <div
                    onClick={() => this.handleOpenModal(company, {}, 1)}
                    className="location-button newLocation"
                  >
                    <div>Add Long Term Establishment</div>
                  </div>
                ) : (
                  <div />
                )}
                <h3>Short Term Establishment</h3>
                {company.groups &&
                  company.groups
                    .filter(
                      group =>
                        !group.isSeparateEstablishment ||
                        (!group.operationalForYearOrLonger &&
                          group.isSeparateEstablishment)
                    )
                    .map((location, index) => (
                      <div className="location-outerDiv" key={index}>
                        <CompanyCard
                          location={location}
                          company={company}
                          locationId={location._id}
                          setActiveCompany={setActiveCompany}
                          setActiveLocation={setActiveLocation}
                          allLocations={allLocations}
                          setActiveGroups={setActiveGroups}
                          setActiveProject={setActiveProject}
                          openProjectModal={() =>
                            this.handleOpenModal(company, location, 5)
                          }
                          projects={location.projects}
                          openProjectDropdown={() =>
                            this.handleUpdateState({
                              modalCompany: company,
                              modalGroup: location
                            })
                          }
                          handleProjectSelection={project =>
                            this.handleProjectSelection(project)
                          }
                          user={user}
                          assignIncidentOwner={value =>
                            this.openAssigneeModal(
                              {
                                assignIncidentOwnerModal: true,
                                reassignUser: value
                              },
                              company,
                              location
                            )
                          }
                        />
                      </div>
                    ))}
                {user.accessLevel === '900' ? (
                  <button
                    onClick={() => this.handleOpenModal(company, {}, 1)}
                    className="newLocation"
                  >
                    <div>
                      <p>Add a New Group</p>
                      <p>– or –</p>
                      <p>Short Term Establishment</p>
                    </div>
                  </button>
                ) : (
                  <div />
                )}
              </div>
            </div>
          ))}
        {modalOpen === 1 && (
          <Modal
            title="Add New Group or Establishment"
            titleClassName="greenHeader"
            isOpen={modalOpen === 1}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={this.props.submitEstablishment}
            disableSubmit={!establishmentValid}
            submitButtonText="Create"
            hideCancelButton={firstTimeLogin}
          >
            <NewLocationForm
              onSubmit={values =>
                firstTimeLogin
                  ? this.handleNewGroup(values)
                  : this.handleNewLocationSubmit(values, user, modalCompany)
              }
              isPerrp={modalCompany.reportingType === 2}
            />
          </Modal>
        )}
        {securityQuestionsModalOpen && (
          <Modal
            title="Answer Security Questions"
            titleClassName="blueHeader"
            isOpen={securityQuestionsModalOpen}
            submitButtonColor="blue"
            submitButtonText="Save Changes"
            hideCancelButton={true}
            submitActions={() => {
              const questionsAnswers = {
                questions: [
                  this.state.securityQuestion1.label,
                  this.state.securityQuestion2.label
                ],
                answers: [
                  {
                    question: this.state.securityQuestion1.label,
                    answer: this.state.securityAnswer1
                  },
                  {
                    question: this.state.securityQuestion2.label,
                    answer: this.state.securityAnswer2
                  }
                ]
              };
              user.securityQuestions = questionsAnswers.questions;
              user.securityAnswers = questionsAnswers.answers;
              this.setState({
                securityQuestionsModalOpen: false
              });
              return saveUserSecurityQuestions(user);
            }}
            disableSubmit={
              !(
                this.state.securityQuestion1 &&
                this.state.securityAnswer1 &&
                this.state.securityQuestion2 &&
                this.state.securityAnswer2
              )
            }
          >
            <p>
              These security questions are for you to be able to reset your
              password in case you forget it. The answers provided here will not
              be shared with others.
            </p>
            <Dropdown
              options={SECURITY_QUESTIONS_OPTIONS}
              fieldLabel="Security Question 1"
              handleChange={e => {
                if (this.state.securityQuestion2 === e.label) {
                  alert('Questions cannot be the same');
                  e.value = null;
                  e.label = null;
                  return;
                }
                this.setState({
                  securityQuestion1: e
                });
              }}
              currentValue={this.state.securityQuestion1}
            />
            <Textbox
              fieldLabel="Security Question Answer 1"
              handleChange={e =>
                this.handleUpdateState({ securityAnswer1: e.target.value })
              }
              isRequired={true}
              placeholder="Fill in with answer to Security Question 1"
            />
            <Dropdown
              options={SECURITY_QUESTIONS_OPTIONS}
              fieldLabel="Security Question 2"
              handleChange={e => {
                if (this.state.securityQuestion1 === e.label) {
                  alert('Questions cannot be the same');
                  e.value = null;
                  e.label = null;
                  return;
                }

                this.setState({
                  securityQuestion2: e
                });
              }}
              currentValue={this.state.securityQuestion2}
            />
            <Textbox
              fieldLabel="Security Question Answer 2"
              handleChange={e =>
                this.handleUpdateState({ securityAnswer2: e.target.value })
              }
              isRequired={true}
              placeholder="Fill in with answer to Security Question 2"
            />
          </Modal>
        )}
        {modalOpen === 2 && (
          <Modal
            title="Add New Company Division"
            titleClassName="greenHeader"
            isOpen={modalOpen === 2}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            disableSubmit={!companyValid}
            submitActions={this.props.submitCompany}
            submitButtonText="Create"
            hideCancelButton={firstTimeLogin}
          >
            <CompanyForm
              onSubmit={values =>
                firstTimeLogin
                  ? this.handleNewCompany(values)
                  : this.handleNewCompanySubmit(values, user)
              }
              redirect={false}
              addedAttachments={addedAttachments}
              addAttachment={addAttachment}
              removeAttachment={removeAttachment}
            />
          </Modal>
        )}
        <Modal
          title="Thanks for trying iReport!"
          titleClassName="blueHeader"
          isOpen={modalOpen === 4}
          submitActions={() => this.handleOpenModal({}, {}, 2)}
          submitButtonText="Next"
          submitButtonColor="blue"
          hideCancelButton={true}
        >
          <p>
            We are going to gather some basic information so that you can try
            the app with ease.
          </p>
        </Modal>
        <Modal
          titleClassName="blueHeader"
          title="Add New Project"
          isOpen={modalOpen === 5}
          className="modalSmall"
          submitActions={() =>
            this.handleNewProjectSubmit(
              modalCompany._id,
              modalGroup._id,
              projectName,
              user
            )
          }
          submitButtonText="Create"
          submitButtonColor="green"
          onRequestClose={() => this.handleCloseModal()}
          disableSubmit={!projectName || projectName === ''}
        >
          <Textbox
            currentValue={projectName}
            fieldLabel="Project Name "
            handleChange={e =>
              this.handleUpdateState({ projectName: e.target.value })
            }
            isRequired={true}
            placeholder="e.g. Mall Project"
          />
        </Modal>
        <Modal
          title={
            reassignUser ? 'Reassign Incident Owner' : 'Assign Incident Owner'
          }
          titleClassName="blueHeader"
          isOpen={assignIncidentOwnerModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ assignIncidentOwnerModal: false })
          }
          submitActions={this.submitDefaultIncidentOwner}
          disableSubmit={!reassignUser}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            From here you can assign a {reassignUser ? 'different' : ''} user to
            be the default incident owner for anything reported within this
            Division/Establishment/Group and any children without a default
            incident owner. Incident ownership can only be assigned to
            Collaborators or Admins with access to the Establishment/Group of
            this incident.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              If there is no default incident owner for a
              Division/Establishment/Group, ownership will go to the default
              incident owner of the parent location. If this reaches all the way
              to company level, the Account Owner will be assigned as the
              incident owner.
            </span>
          </div>
          <div className="incidentContainer-reassignIncident">
            <UserBadge showImage hoverName size="large" userId={reassignUser} />
          </div>
          <Dropdown
            options={
              companyUsers
                ? companyUsers
                    .filter(
                      user => parseInt(user.userAccount.accessLevel) > 400
                    )
                    .map(user => {
                      return {
                        value: user.userAccount._id,
                        label: `${user.person.firstName} ${user.person.lastName}`
                      };
                    })
                : []
            }
            fieldLabel="Default Incident Owner"
            currentValue={reassignUser}
            handleChange={value =>
              this.setState({
                reassignUser: value.value
              })
            }
            placeholder="No Default Incident Owner"
          />
        </Modal>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  userCompanies: getUserCompaniesSelector(state),
  user: getLoggedInUser(state),
  firstTimeLogin: isFirstTimeLogin(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  companyValid: isValid('CompanyForm')(state),
  establishmentValid: isValid('NewLocationForm')(state),
  loacationList: getAllLocations(state),
  activeLocationId: getActiveLocationId(state),
  companyUsers: getCompanyUsersSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state)
});

export const mapDispatchToProps = dispatch => ({
  setActiveCompany: company => dispatch(setActiveCompany(company)),
  setActiveLocation: locationId => dispatch(setActiveLocation(locationId)),
  addCompany: values => dispatch(addCompanyRequest(values)),
  addCompanyLocation: values => dispatch(addCompanyLocationRequest(values)),
  allLocations: values => dispatch(setAllLocations(values)),
  addAttachment: values => dispatch(addUploadedAttachmentsRequest(values)),
  removeAttachment: () => dispatch(clearUploadedAttachments()),
  addLocationEmployee: values =>
    dispatch(
      addLocationEmployeeRequest({ setEmployeeType: 'Employees', ...values })
    ),
  setActiveGroups: values => dispatch(setActiveGroups(values)),
  addProject: values => dispatch(addProjectRequest(values)),
  setActiveProject: project => dispatch(setActiveProject(project)),
  fetchAllUsers: () => dispatch(fetchAllActiveEmployeesActiveUsers()),
  updateCompany: payload => dispatch(updateCompanyRequest(payload)),
  updateGroup: payload => dispatch(updateCompanyLocationRequest(payload)),
  saveUserSecurityQuestions: payload =>
    dispatch(createUserSecurityQuestionsRequest(payload)),
  submitEstablishment: () => dispatch(submit('NewLocationForm')),
  submitCompany: () => dispatch(submit('CompanyForm'))
});

export default withAlert(connect(mapStateToProps, mapDispatchToProps)(Home));
