import React from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

const CustomTooltip = props => {
  const { active } = props;

  if (active) {
    return (
      <div className="custom-tooltip">
        <p>RAs Created: {props.fullJsaData.totalJsasInTimePeriod}</p>
        <p>RAs Performed: {props.fullJsaData.numberOfClosedJsas}</p>
        <p>RAs Not Performed: {props.fullJsaData.numberOfOpenJsas}</p>
      </div>
    );
  }

  return null;
};

const COLORS = ['#C55248', '#E4E5E5'];

const Speedometer = ({ height, width, data, fullJsaData }) => (
  <PieChart height={height} width={width}>
    <Tooltip
      content={<CustomTooltip fullJsaData={fullJsaData} />}
      wrapperStyle={{
        width: 200,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        zIndex: 10
      }}
      position={{ x: 50, y: -15 }}
    />
    <Pie
      cy={175}
      data={data}
      startAngle={180}
      endAngle={0}
      innerRadius={100}
      outerRadius={150}
      fill="#8884d8"
      dataKey="value"
    >
      {data.map((entry, index) => (
        <Cell fill={COLORS[index % COLORS.length]} key={index} />
      ))}
    </Pie>
  </PieChart>
);

export default Speedometer;
