import React, { Component } from 'react';
import Checkbox from '../../inputs/Checkbox';
import CheckboxGroup from '../../inputs/CheckboxGroup';
import { DatePicker } from '../../inputs/DateTimePicker';
import Dropdown from '../../inputs/Dropdown';
import GoogleMap from '../../inputs/GoogleMap';
import Multiselect from '../../inputs/Multiselect';
import RadioButtons from '../../inputs/RadioButtons';
import Signature from '../../inputs/Signature';
import Textbox from '../../inputs/Textbox';
import Money from '../../inputs/Money';
import Card from '../../Card';

const optionsMapper = options => {
  return options.map((option, index) => ({ value: index, label: option }));
};

const findAnswer = (fields, questionId) => {
  const fieldIndex = fields.findIndex(field => field._id === questionId);
  if (fieldIndex > -1) {
    return fields[fieldIndex].answer;
  }

  return '';
};

export default class ReportSection extends Component {
  handleNA = (checked, field, index) =>
    this.handleAnswer(field, checked ? ['Not Applicable'] : '');

  handleAnswer = (field, answer, index) => {
    field.answer = answer;

    const fields = this.props.fields;

    fields[index] = field;
    this.props.answerQuestion(fields);
  };

  render() {
    const {
      name,
      reportSectionHeader,
      canSectionNotApplicable,
      sectionNotApplicable,
      fields,
      employees,
      disabled,
      handleNaSection
    } = this.props;

    const employeeDropdown = employees.map(employee => {
      return {
        value: employee._id,
        label: `${employee.person.firstName} ${employee.person.lastName}`
      };
    });

    return (
      <Card
        name={name}
        title={reportSectionHeader}
        showHeader
        rightButton={
          canSectionNotApplicable && (
            <Checkbox
              fieldLabel="Not Applicable"
              onChange={value => handleNaSection(value)}
              currentValue={sectionNotApplicable}
              disabled={disabled}
              className="legacy-stop-input-spacing"
            />
          )
        }
      >
        {fields.map((field, index) => {
          if (field.legacy)
            return <React.Fragment key={index}></React.Fragment>;
          let answer = findAnswer(fields, field._id);
          let isNA = Array.isArray(answer) && answer.length === 1 && answer[0] === 'Not Applicable';

          let showNA =
            field.type === 'signature' ? false : field.showNotApplicable;

          let props = {
            name: field._id,
            currentValue: answer,
            isRequired: field.required,
            fieldLabel: field.label,
            showNA,
            isNA,
            onNAChange: v => this.handleNA(v, field, index),
            disabled
          };

          return (
            <React.Fragment key={index}>
              {field.type === 'radiobuttons' ? (
                <RadioButtons
                  options={optionsMapper(field.options)}
                  handleChange={values =>
                    this.handleAnswer(field, values, index)
                  }
                  {...props}
                />
              ) : field.type === 'textarea' ? (
                <Textbox
                  type="textarea"
                  rows={5}
                  handleChange={e =>
                    this.handleAnswer(field, e.target.value, index)
                  }
                  {...props}
                />
              ) : field.type === 'dateTimePicker' ? (
                <DatePicker
                  pickTime
                  handleChange={value => this.handleAnswer(field, value, index)}
                  {...props}
                />
              ) : field.type === 'datePicker' ? (
                <DatePicker
                  handleChange={value => this.handleAnswer(field, value, index)}
                  {...props}
                />
              ) : field.type === 'dropdown' || field.type === 'dropUp' ? (
                <Dropdown
                  options={optionsMapper(field.options)}
                  searchable
                  handleChange={values =>
                    this.handleAnswer(field, values, index)
                  }
                  {...props}
                />
              ) : field.type === 'multiselectDown' ||
                field.type === 'multiselectUp' ||
                field.type === 'multiselect' ? (
                <Multiselect
                  options={optionsMapper(field.options)}
                  searchable={true}
                  handleChange={values =>
                    this.handleAnswer(field, values, index)
                  }
                  {...props}
                />
              ) : field.type === 'multiselectPersonnel' ? (
                <Multiselect
                  options={employeeDropdown}
                  searchable={true}
                  handleChange={values =>
                    this.handleAnswer(field, values, index)
                  }
                  {...props}
                />
              ) : field.type === 'signature' ? (
                <Signature
                  onChange={sig => this.handleAnswer(field, sig, index)}
                  questionId={field._id}
                  {...props}
                />
              ) : field.type === 'checkbox' ? (
                <CheckboxGroup
                  options={optionsMapper(field.options)}
                  handleChange={values =>
                    this.handleAnswer(field, values, index)
                  }
                  {...props}
                />
              ) : field.type === 'textbox' ? (
                <Textbox
                  handleChange={e =>
                    this.handleAnswer(field, e.target.value, index)
                  }
                  {...props}
                />
              ) : field.type === 'money' ? (
                <Money
                  onChange={value => this.handleAnswer(field, value, index)}
                  {...props}
                />
              ) : field.type === 'googleAddress' ? (
                <GoogleMap
                  handleChange={value => this.handleAnswer(field, value, index)}
                  {...props}
                />
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
      </Card>
    );
  }
}
