import React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Dropdown from '../components/inputs/Dropdown';
import { DatePicker, TimePicker } from '../components/inputs/DateTimePicker';
import { parseTime, renderTime } from './dateHelpers';
import Multiselect from '../components/inputs/Multiselect';
import UserAccessButtons from '../components/inputs/UserAccessButtons';

export const required = value => (value !== '' ? undefined : 'Required!');

export const dropDownRequired = value => {
  return parseInt(value) >= 0 || value ? undefined : 'Required!';
};

export const booleanRequired = value => {
  return value === true || value === false ? undefined : 'Required!';
};

export const multiSelectRequired = value => {
  if (!value) {
    return 'Required!';
  }
  return value.length > 0 ? undefined : 'Required!';
};

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;

export const numeric = value =>
  value && /[^0-9 ]/i.test(value) ? 'Only number characters' : undefined;

export const alpha = value =>
  value && /[^a-zA-Z ]/i.test(value) ? 'Only alphabet characters' : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const phone = value =>
  value && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(value)
    ? 'Invalid phone number'
    : undefined;

export const validZip = value =>
  value && value.length !== 5 ? `Must be 5 characters` : undefined;

export const validNAICS = value =>
  value && value.length !== 6 ? `Must be 6 characters` : undefined;

export const validSIC = value =>
  value && value.length !== 4 ? `Must be 4 characters` : undefined;

export const isValidDate = value => {
  if (!value) {
    return false;
  }
  if (moment.isMoment(value)) return;

  var pattern = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/;
  var m = value.match(pattern);
  if (!m) return 'Invalid date';
};

export const isValidDateTime = dateTime => {
  if (!dateTime) {
    return false;
  }
  if (moment.isMoment(dateTime)) return;

  const matches = dateTime.match(
    /(\d{2})\/(\d{2})\/(\d{4})( {1})(((0[1-9])|(1[0-2])):([0-5][0-9])( {1})([AaPp][Mm]))/
  );

  if (matches === null) {
    return 'Date/Time is not valid';
  }
};

export const isValidTime = value => {
  if (!value) {
    return false;
  }

  const matches = value.match(
    /(((0[1-9])|(1[0-2])):([0-5][0-9])( {1})([AaPp][Mm]))/
  );

  return matches === null ? 'Invalid time' : undefined;
};

export const notFutureDate = value =>
  moment(value).isAfter(moment()) ? 'Cannot be a Future Date' : undefined;

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return (
    onlyNums.slice(0, 3) +
    '-' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  );
};

export const positiveIntsOnly = value => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, '');

  return onlyNums;
};

export const normalizeWebsite = (value, previousValue) => {
  if (!value) {
    return value;
  }

  if (!previousValue || value.length > previousValue.length) {
    if (/^http:\/\//i.test(value) || /^https:\/\//i.test(value)) {
      return value;
    } else {
      return 'http://' + value;
    }
  }
  return value;
};

export const normalizeDate = value => {
  if (!value) {
    return value;
  }
  let onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length > 2 && onlyNums.length <= 4) {
    onlyNums = onlyNums.slice(0, 2) + '/' + onlyNums.slice(2);
  } else if (onlyNums.length >= 5) {
    onlyNums =
      onlyNums.slice(0, 2) +
      '/' +
      onlyNums.slice(2, 4) +
      '/' +
      onlyNums.slice(4);
  }

  return onlyNums;
};

export const normalizeTime = value => {
  if (!value) {
    return value;
  }
  let onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length > 2) {
    onlyNums = onlyNums.slice(0, 2) + ':' + onlyNums.slice(2);
  }

  return onlyNums;
};

export const normalizeBwcPolicy = value => {
  if (!value) {
    return value;
  }

  let regexValue = value;

  if (value.length >= 9) {
    return regexValue.slice(0, 8) + '-' + regexValue.slice(9, 12);
  }

  return regexValue;
};

export const renderField = ({
  input,
  label,
  type,
  disabled,
  requiredLabel,
  placeholder,
  className,
  meta: { touched, error, warning }
}) => (
  <div className={`input ${className}`}>
    <label className="label">
      {requiredLabel ? <span className="required">*</span> : ''}
      {label}
    </label>
    <div>
      <input
        {...input}
        onChange={value => input.onChange(value)}
        type={type}
        className={'textbox'}
        disabled={disabled}
        placeholder={placeholder}
      />
      {touched &&
        ((error && <span className="form-error">{error}</span>) ||
          (warning && <span className="form-warning">{warning}</span>))}
    </div>
  </div>
);

export const renderTextareaField = ({
  input,
  label,
  type,
  disabled,
  requiredLabel,
  placeholder,
  meta: { touched, error, warning }
}) => (
  <div className="input">
    <label className="label">
      {requiredLabel ? <span className="required">*</span> : ''}
      {label}
    </label>
    <div>
      <textarea
        {...input}
        rows="5"
        cols="10"
        type={type}
        className="textbox"
        disabled={disabled}
        placeholder={placeholder}
        onChange={value => input.onChange(value)}
      />
      {touched &&
        ((error && <span className="form-error">{error}</span>) ||
          (warning && <span className="form-warning">{warning}</span>))}
    </div>
  </div>
);

export const renderDropdownField = ({
  input,
  options,
  label,
  disabled,
  requiredLabel,
  placeholder,
  className,
  inputClassName,
  children,
  displayToolTip,
  dropUp,
  searchable,
  meta: { touched, error, warning }
}) => {
  return (
    <div className={`input ${inputClassName}`}>
      {displayToolTip && (
        <ReactTooltip className="customTheme" id={input.name}>
          {children}
        </ReactTooltip>
      )}
      <label className="label" data-tip="customTheme" data-for={input.name}>
        {requiredLabel ? <span className="required">*</span> : ''}
        {label}
        {displayToolTip && (
          <img
            className="label-info"
            src={require(`../assets/images/Info Icon.png`)}
            alt="Info Icon"
          />
        )}
      </label>
      <Dropdown
        name={input.name}
        options={options}
        disabled={disabled}
        currentValue={input.value}
        handleChange={value => {
          input.onChange(value.value);
        }}
        placeholder={placeholder}
        className={className}
        inputClassName="noPadding"
        openUp={dropUp}
        searchable={searchable}
      />
      {touched &&
        ((error && <span className="form-error">{error}</span>) ||
          (warning && <span className="form-warning">{warning}</span>))}
    </div>
  );
};

export const renderMultiSelectField = ({
  input,
  label,
  requiredLabel,
  meta: { touched, error, warning },
  ...props
}) => {
  return (
    <div className="input">
      <label className="label">
        {requiredLabel ? <span className="required">*</span> : ''}
        {label}
      </label>
      <div>
        <Multiselect
          name={input.name}
          currentValue={input.value}
          handleChange={value => input.onChange(value)}
          inputClassName="noPadding"
          {...props}
        />
        {touched &&
          ((error && (
            <span className="form-error--multiselect">{error}</span>
          )) ||
            (warning && <span className="form-warning">{warning}</span>))}
      </div>
    </div>
  );
};

export const renderTimePicker = ({
  input,
  label,
  disabled,
  requiredLabel,
  meta: { touched, error, warning }
}) => {
  const [hours, minutes] = parseTime(input.value);

  return (
    <TimePicker
      {...input}
      hours={hours}
      minutes={minutes}
      handleChange={(hour, minute) =>
        input.onChange(renderTime(hour, minute, true))
      }
      disabled={disabled}
      isRequired={requiredLabel}
      fieldLabel={label}
      touched={touched}
      error={error}
      warning={warning}
    />
  );
};

export const renderDatePicker = ({
  input,
  label,
  disabled,
  requiredLabel,
  meta: { touched, error, warning }
}) => (
  <DatePicker
    {...input}
    currentValue={input.value}
    handleChange={value => input.onChange(value)}
    disabled={disabled}
    onBlur={input.onBlur}
    touched={touched}
    error={error}
    warning={warning}
    isRequired={requiredLabel}
    fieldLabel={label}
  />
);

export const renderUserButtons = ({
  input,
  label,
  disabled,
  requiredLabel,
  accountInfo,
  meta: { touched, error, warning }
}) => (
  <div className="input">
    <label className="label">
      {requiredLabel ? <span className="required">*</span> : ''}
      {label}
    </label>
    <UserAccessButtons
      {...input}
      currentValue={input.value}
      disabled={disabled}
      handleButtonClick={value => input.onChange(value)}
      accountInfo={accountInfo}
    />
    {touched &&
      ((error && <span className="form-error">{error}</span>) ||
        (warning && <span className="form-warning">{warning}</span>))}
  </div>
);

export const renderRadioButtons = ({
  input,
  options,
  name,
  label,
  disabled,
  requiredLabel,
  displayToolTip,
  children,
  meta: { touched, error, warning }
}) => (
  <div className="input">
    {displayToolTip && (
      <ReactTooltip className="customTheme" id={input.name}>
        {children}
      </ReactTooltip>
    )}
    <label className="label" data-for={input.name} data-tip="customTheme">
      {requiredLabel ? <span className="required">*</span> : ''}
      {label}
      {displayToolTip && (
        <img
          className="label-info"
          src={require(`../assets/images/Info Icon.png`)}
          alt="Info Icon"
        />
      )}
    </label>
    <div className="radioButtons-row">
      {options.map((option, index) => (
        <div className="radioButtoninput" key={index}>
          <div className="radioButtonDiv">
            <label className="radioButton-label">
              {option.label}
              <input
                type="checkbox"
                name={`${name}[${index}]`}
                value={input.value}
                checked={input.value === option.value}
                onChange={event => {
                  return input.onChange(option.value);
                }}
                disabled={disabled}
              />
              <span className="radioButton" />
            </label>
          </div>
        </div>
      ))}
    </div>
    {touched &&
      ((error && <span className="form-error">{error}</span>) ||
        (warning && <span className="form-warning">{warning}</span>))}
  </div>
);

export const renderNumberField = ({
  input,
  label,
  disabled,
  className,
  requiredLabel,
  placeholder,
  children,
  displayToolTip,
  meta: { touched, error, warning }
}) => (
  <div className={`input ${className}`}>
    {displayToolTip && (
      <ReactTooltip className="customTheme" id={input.name}>
        {children}
      </ReactTooltip>
    )}
    <label className="label" data-tip="customTheme" data-for={input.name}>
      {requiredLabel ? <span className="required">*</span> : ''}
      {label}
      {displayToolTip && (
        <img
          className="label-info"
          src={require(`../assets/images/Info Icon.png`)}
          alt="Info Icon"
        />
      )}
    </label>
    <div>
      <input
        {...input}
        onChange={value => input.onChange(value)}
        type="number"
        className="textbox"
        disabled={disabled}
        placeholder={placeholder}
      />
      {touched &&
        ((error && <span className="form-error">{error}</span>) ||
          (warning && <span className="form-warning">{warning}</span>))}
    </div>
  </div>
);
