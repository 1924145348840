import React, { Component } from 'react';
import { Bar, Cell } from 'recharts';
import {
  getCorrectActionData,
  getNearMissData,
  getOshaRecordableType,
  getTopFactorsData,
  totalIncidentOshaData
} from '../../utils/chartDataMapper';
import BarChart from '../chartTypes/BarChart';
import SpeedometerChart from '../chartTypes/SpeedometerChart';
import StackedBarChart from '../chartTypes/StackedBarChart';
import VerticalBarChart from '../chartTypes/VerticalBarChart';

import './index.css';

export default class AnalyticCharts extends Component {
  render() {
    const {
      jsaClosedOneWeek,
      nearMissByType,
      analyticType,
      reportCostStats,
      observationCostsStats,
      // taskData,
      correctiveActionsByType,
      oshaDataForIncidents,
      totalOshaInjury,
      topFactorsFROIAuto,
      divisionEstInfo
    } = this.props;

    // const GREENS = ['#009933', '#00CC00', '#00CC33', '#00CC66', '#00CC99'];
    const REDS = ['#Ff6600', '#009999', '#CC00ff', '#0099FF', '#FF0099'];

    return (
      <div>
        {analyticType === 0 ? (
          <div className="analyticsChartsSection">
            <div className="analyticsChartsSection-chartCard">
              <h4 className="analyticsChartsSection-chartCard--title">
                RAs Completed Within a Week
              </h4>
              {jsaClosedOneWeek === 'NO DATA' ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  NO DATA
                </div>
              ) : (
                <div>
                  <div className="analyticsChartsSection-jsaDetails--chart">
                    <SpeedometerChart
                      width={300}
                      height={214}
                      data={[
                        {
                          name: 'RAs Completed',
                          value: +jsaClosedOneWeek.jsaClosedInOneWeekPercent
                        },
                        {
                          name: 'RAs Not Completed',
                          value: +jsaClosedOneWeek.jsaNotClosedInOneWeekPercent
                        }
                      ]}
                      fullJsaData={jsaClosedOneWeek}
                    />
                  </div>
                  <div className="analyticsChartsSection-jsaDetails">
                    <p className="analyticsChartsSection-jsaDetails--header">
                      {!Number.isNaN(+jsaClosedOneWeek.jsaClosedInOneWeekPercent)
                        ? +jsaClosedOneWeek.jsaClosedInOneWeekPercent
                        : 0}
                      %
                    </p>{' '}
                    <p className="analyticsChartsSection-jsaDetails--details">
                      Completed
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="analyticsChartsSection-chartCard">
              <h4 className="analyticsChartsSection-chartCard--title">
                Task Closure
              </h4>
              {activeLocation !== 0 ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  Only Available to Company Divison
                </div>
              ) : taskData === 'NO DATA' ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  NO DATA
                </div>
              ) : (
                <BarChart
                  data={getTaskChartData(taskData)}
                  width={300}
                  height={214}
                >
                  <Bar dataKey="On Time" fill="#779B9E" />
                  <Bar dataKey="Past Due" fill="#C55248" />
                </BarChart>
              )}
            </div> */}
            <div className="analyticsChartsSection-chartCard">
              <h4 className="analyticsChartsSection-chartCard--title">
                Near Miss by Type
              </h4>
              {nearMissByType === 'NO DATA' ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  NO DATA
                </div>
              ) : (
                <BarChart
                  data={getNearMissData(nearMissByType)}
                  width={300}
                  height={185}
                  customXAxisLabel={true}
                  customToolTip={true}
                  isNearMiss={true}
                >
                  <Bar dataKey="" fill="#779B9E">
                    {getNearMissData(nearMissByType).map((entry, index) => {
                      return <Cell fill={REDS[0]} key={index} />;
                    })}
                  </Bar>
                </BarChart>
              )}
            </div>
            <div className="analyticsChartsSection-chartCard">
              <h4 className="analyticsChartsSection-chartCard--title">
                Top Causes for Corrective Action
              </h4>
              {correctiveActionsByType === 'NO DATA' ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  NO DATA
                </div>
              ) : (
                <VerticalBarChart
                  data={getCorrectActionData(correctiveActionsByType)}
                  width={300}
                  height={214}
                  customToolTip={true}
                  customYAxisLabel={true}
                >
                  <Bar dataKey="" fill="#C55248">
                    {getCorrectActionData(correctiveActionsByType).map(
                      (entry, index) => {
                        return <Cell fill={REDS[1]} key={index} />;
                      }
                    )}
                  </Bar>
                </VerticalBarChart>
              )}
            </div>
            <div className="analyticsChartsSection-chartCard">
              <h4 className="analyticsChartsSection-chartCard--title">
                Report Costs & Costs Avoided
              </h4>
              {reportCostStats === 'NO DATA' ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  NO DATA
                </div>
              ) : (
                <StackedBarChart
                  width={300}
                  height={214}
                  data={{ ...reportCostStats, ...observationCostsStats }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="analyticsChartsSection">
            <div className="analyticsChartsSection-chartCard">
              <h4 className="analyticsChartsSection-chartCard--title">
                Total First Reports of Injury
              </h4>
              {oshaDataForIncidents === 'NO DATA' ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  NO DATA
                </div>
              ) : (
                <BarChart
                  data={totalIncidentOshaData(
                    oshaDataForIncidents,
                    divisionEstInfo
                  )}
                  width={300}
                  height={214}
                  customToolTip={true}
                  customXAxisLabel={true}
                >
                  <Bar dataKey="" fill="#C55248">
                    {totalIncidentOshaData(
                      oshaDataForIncidents,
                      divisionEstInfo
                    ).map((entry, index) => {
                      return <Cell fill={REDS[3]} key={index} />;
                    })}
                  </Bar>
                </BarChart>
              )}
            </div>
            <div className="analyticsChartsSection-chartCard">
              <h4 className="analyticsChartsSection-chartCard--title">
                {`${
                  divisionEstInfo.reportingType === 2 ? 'PERRP' : 'OSHA'
                } Recordable by Type`}
              </h4>
              {totalOshaInjury === 'NO DATA' ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  NO DATA
                </div>
              ) : (
                <VerticalBarChart
                  data={getOshaRecordableType(totalOshaInjury)}
                  width={300}
                  height={214}
                  customToolTip={true}
                  customYAxisLabel={true}
                >
                  <Bar dataKey="" fill="#C55248">
                    {getOshaRecordableType(totalOshaInjury).map(
                      (entry, index) => {
                        return <Cell fill={REDS[4]} key={index} />;
                      }
                    )}
                  </Bar>
                </VerticalBarChart>
              )}
            </div>
            <div className="analyticsChartsSection-chartCard">
              <h4 className="analyticsChartsSection-chartCard--title">
                Incidents Contributing Factors
              </h4>
              {topFactorsFROIAuto === 'NO DATA' ? (
                <div className="analyticsChartsSection-chartCard--infoMessage">
                  NO DATA
                </div>
              ) : (
                <VerticalBarChart
                  data={getTopFactorsData(topFactorsFROIAuto)}
                  width={300}
                  height={214}
                  customToolTip={true}
                  customYAxisLabel={true}
                >
                  <Bar dataKey="" fill="#779B9E">
                    {getTopFactorsData(topFactorsFROIAuto).map(
                      (entry, index) => {
                        return <Cell fill={REDS[0]} key={index} />;
                      }
                    )}
                  </Bar>
                </VerticalBarChart>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
