import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../../history';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../../actions/attachments';
import { setActiveSafetyWalk } from '../../../actions/safetyWalks';
import { CAUSAL_FACTORS, HAZARD_CLASS } from '../../../constants/constants';
import { getAddedAttachmentsSelector } from '../../../selectors/attachments';
import { getActiveCompany } from '../../../selectors/company';
import { getActiveSafetyWalkSelector } from '../../../selectors/safetyWalks';
import { getLoggedInUser } from '../../../selectors/users';
import Button from '../../../components/inputs/Button';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import CheckboxGroup from '../../../components/inputs/CheckboxGroup';
import Modal from '../../../components/Modal';
import { DeleteItemModal } from '../../../components/Modal/deleteItemModal';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import Multiselect from '../../../components/inputs/Multiselect';
import Textbox from '../../../components/inputs/Textbox';
import { InputRow, TwoColumn } from '../../../components/inputs';

import './index.css';

class HazardCard extends Component {
  state = {
    isEditing: false,
    hasUnsavedChanges: false,
    openDiscardModal: false,
    openDeletePictureModal: false,
    openUnsavedChangesModal: false,
    selectedAttachment: 0,
    title: '',
    causalFactor: [],
    immediatelyFixed: [],
    hazardClass: [],
    description: '',
    solution: '',
    exactLocation: '',
    pictures: []
  };

  componentDidMount() {
    const hazard = this.props.activeSafetyWalk.hazards[
      `${this.props.match.params.hazardIndex}`
    ];

    this.setState({
      ...hazard,
      isEditing: !(this.props.match.params.hazardIndex && hazard._id)
    });
  }

  handlePhotoUpload = async e => {
    const files = e.target.files;

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    await this.props.addAttachment({
      data,
      ownerId: '1234',
      ownerType: 'hazard'
    });
  };

  handleDeletePicture = () => {
    this.setState({
      openDeletePictureModal: false
    });

    this.setState({ selectedAttachment: 0 });

    this.props.deleteAttachment(
      this.props.addedAttachments[this.state.selectedAttachment]
    );
  };

  handleHazardSubmit = () => {
    const activeSW = this.props.activeSafetyWalk;

    const hazard = {
      title: this.state.title,
      causalFactor: this.state.causalFactor,
      immediatelyFixed: this.state.immediatelyFixed,
      hazardClass: this.state.hazardClass,
      description: this.state.description,
      solution: this.state.solution,
      exactLocation: this.state.exactLocation,
      pictures: this.props.addedAttachments.concat(this.state.pictures)
    };

    if (this.props.match.params.hazardIndex) {
      activeSW.hazards[`${this.props.match.params.hazardIndex}`] = hazard;
    } else {
      activeSW.hazards = [...activeSW.hazards, hazard];
    }

    this.props.setSafetyWalk(activeSW);
    this.props.clearAttachments();
    this.setState({ hasUnsavedChanges: false });
    history.goBack();
  };

  canSubmit = () => {
    return (
      this.state.title &&
      this.state.hazardClass.length > 0 &&
      this.state.description &&
      this.state.hasUnsavedChanges
    );
  };

  handleDiscardHazard = () => {
    const { clearAttachments, activeSafetyWalk } = this.props;
    const {
      title,
      causalFactor,
      immediatelyFixed,
      hazardClass,
      description,
      solution,
      exactLocation,
      pictures
    } = this.state;

    const stateHazard = {
      title,
      causalFactor,
      immediatelyFixed,
      hazardClass,
      description,
      solution,
      exactLocation,
      pictures
    };

    activeSafetyWalk.hazards = activeSafetyWalk.hazards.filter(
      hazard => JSON.stringify(hazard) !== JSON.stringify(stateHazard)
    );

    this.props.setSafetyWalk(activeSafetyWalk);
    clearAttachments();
    history.goBack();
  };

  render() {
    const {
      isEditing,
      hasUnsavedChanges,
      openDiscardModal,
      openUnsavedChangesModal,
      selectedAttachment,
      title,
      causalFactor,
      immediatelyFixed,
      hazardClass,
      description,
      solution,
      exactLocation,
      pictures
    } = this.state;

    const { addedAttachments, activeCompany } = this.props;

    const header = (
      <Header
        title={activeCompany.isDash ? 'DASH Report' : 'Safety Walk'}
        section={`${isEditing ? 'Edit' : 'View'} Hazard Info`}
        needsSaved={hasUnsavedChanges}
        clickBack={() => history.goBack()}
        rightButtons={{
          visible: isEditing,
          text: 'Discard Hazard',
          color: 'red',
          onClick: () => this.setState({ openDiscardModal: true })
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={this.handleHazardSubmit}
        cancelButtonClick={() => history.goBack()}
        editing={isEditing}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header} Footer={footer} showFooter={isEditing}>
          <div className="hazardCard">
            {addedAttachments.length > 0 && (
              <div
                className={
                  addedAttachments.length === 1
                    ? 'hazardCard-pictureContainer--onePicture'
                    : 'hazardCard-pictureContainer'
                }
              >
                {addedAttachments.length > 1 && (
                  <img
                    src={require('../../../assets/images/leftChevron.png')}
                    alt="Go back hover"
                    className="arrows"
                    onClick={() =>
                      this.setState({
                        selectedAttachment:
                          selectedAttachment > 0 ? selectedAttachment - 1 : 0
                      })
                    }
                  />
                )}
                <img
                  src={addedAttachments[selectedAttachment].source_url}
                  alt={addedAttachments[selectedAttachment].original_filename}
                  className="photoImage"
                />
                {addedAttachments.length > 1 && (
                  <img
                    src={require('../../../assets/images/rightChevron.png')}
                    alt="Go back hover"
                    className="arrows"
                    onClick={() =>
                      this.setState({
                        selectedAttachment:
                          selectedAttachment === addedAttachments.length - 1
                            ? selectedAttachment
                            : selectedAttachment + 1
                      })
                    }
                  />
                )}
              </div>
            )}
            {pictures.length > 0 && (
              <div
                className={
                  pictures.length === 1
                    ? 'hazardCard-pictureContainer--onePicture'
                    : 'hazardCard-pictureContainer'
                }
              >
                {pictures.length > 1 && (
                  <img
                    src={require('../../../assets/images/leftChevron.png')}
                    alt="Go back hover"
                    className="arrows"
                    onClick={() =>
                      this.setState({
                        selectedAttachment:
                          selectedAttachment > 0 ? selectedAttachment - 1 : 0
                      })
                    }
                  />
                )}
                <img
                  src={pictures[selectedAttachment].source_url}
                  alt={pictures[selectedAttachment].original_filename}
                  className="photoImage"
                />
                {pictures.length > 1 && (
                  <img
                    src={require('../../../assets/images/rightChevron.png')}
                    alt="Go back hover"
                    className="arrows"
                    onClick={() =>
                      this.setState(state => ({
                        selectedAttachment:
                          state.selectedAttachment === pictures.length - 1
                            ? state.selectedAttachment
                            : state.selectedAttachment + 1
                      }))
                    }
                  />
                )}
              </div>
            )}
            <div
              className={
                addedAttachments.length > 0 || pictures.length > 0
                  ? 'padding noTopBorder'
                  : 'padding'
              }
            >
              {isEditing && (
                <InputRow>
                  <Button
                    text="Add Pictures"
                    color="blue"
                    onClick={() =>
                      document.getElementById('myPictureFile').click()
                    }
                  />
                  {addedAttachments.length ? (
                    <Button
                      text="Delete Picture"
                      color="red"
                      onClick={() =>
                        this.setState({ openDeletePictureModal: true })
                      }
                    />
                  ) : (
                    <></>
                  )}
                </InputRow>
              )}
              <input
                type="file"
                id="myPictureFile"
                onChange={e => this.handlePhotoUpload(e)}
                accept="image/png, image/jpeg, image/jpg"
                multiple
              />
              <TwoColumn>
                <Textbox
                  fieldLabel="Hazard Title"
                  isRequired={true}
                  placeholder="Type a clear and concise title."
                  handleChange={e =>
                    this.setState({
                      title: e.target.value,
                      hasUnsavedChanges: true
                    })
                  }
                  currentValue={title}
                  disabled={!isEditing}
                />
                <Multiselect
                  options={CAUSAL_FACTORS}
                  fieldLabel="Causal Factor"
                  searchable={false}
                  currentValue={causalFactor}
                  handleChange={values =>
                    this.setState({
                      causalFactor: values,
                      hasUnsavedChanges: true
                    })
                  }
                  disabled={!isEditing}
                />
              </TwoColumn>
              <CheckboxGroup
                options={[
                  {
                    value: 'immediatelyFixed',
                    label: 'Hazard was Immediately Fixed'
                  }
                ]}
                currentValue={immediatelyFixed}
                handleChange={values =>
                  this.setState({
                    immediatelyFixed: values,
                    hasUnsavedChanges: true
                  })
                }
                disabled={!isEditing}
              />
              <Multiselect
                options={HAZARD_CLASS}
                searchable={false}
                isRequired={true}
                fieldLabel="Hazard Class"
                currentValue={hazardClass}
                handleChange={values =>
                  this.setState({
                    hazardClass: values,
                    hasUnsavedChanges: true
                  })
                }
                disabled={!isEditing}
              />
              <Textbox
                type="textarea"
                rows={5}
                fieldLabel="Description"
                placeholder="Describe this Harzard. Be sure to include any important details."
                isRequired={true}
                handleChange={e =>
                  this.setState({
                    description: e.target.value,
                    hasUnsavedChanges: true
                  })
                }
                currentValue={description}
                disabled={!isEditing}
              />
              <TwoColumn>
                <Textbox
                  type="textarea"
                  rows={5}
                  fieldLabel="Solution"
                  placeholder="How to fix and prevent this Hazard."
                  handleChange={e =>
                    this.setState({
                      solution: e.target.value,
                      hasUnsavedChanges: true
                    })
                  }
                  currentValue={solution}
                  disabled={!isEditing}
                />
                <Textbox
                  type="textarea"
                  rows={5}
                  fieldLabel="Exact Location"
                  placeholder="Where to find this Hazard."
                  handleChange={e =>
                    this.setState({
                      exactLocation: e.target.value,
                      hasUnsavedChanges: true
                    })
                  }
                  currentValue={exactLocation}
                  disabled={!isEditing}
                />
              </TwoColumn>
            </div>
          </div>
        </HeaderAndFooter>
        <Modal
          title="Discard Hazard"
          titleClassName="redHeader"
          isOpen={openDiscardModal}
          submitButtonColor="red"
          submitButtonText="Discard Hazard"
          onRequestClose={() => this.setState({ openDiscardModal: false })}
          submitActions={() => this.handleDiscardHazard()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Discarding this Hazard will delete the information within,
              pictures taken, and the corrective action associated with it (if
              any).
            </span>
          </div>
        </Modal>
        <DeleteItemModal
          deletingWhat="picture"
          isOpen={this.state.openDeletePictureModal}
          onRequestClose={() =>
            this.setState({ openDeletePictureModal: false })
          }
          submitActions={this.handleDeletePicture}
          hideCustomTemplate
        />
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => history.goBack()}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  addedAttachments: getAddedAttachmentsSelector(state),
  activeSafetyWalk: getActiveSafetyWalkSelector(state),
  loggedInUser: getLoggedInUser(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  addAttachment: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  deleteAttachment: payload => dispatch(deleteAttachmentRequest(payload)),
  setSafetyWalk: payload => dispatch(setActiveSafetyWalk(payload)),
  clearAttachments: () => dispatch(clearUploadedAttachments())
});

export default connect(mapStateToProps, mapDispatchToProps)(HazardCard);
