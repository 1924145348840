import React, { Component } from 'react';
import ResizeDetector from 'react-resize-detector';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import history from '../../../history';
import { updateLeftNavRequest } from '../../../actions/navigation';
import { getLoggedInUser } from '../../../selectors/users';
import Widget from '../../../components/Widget';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import AnalyticsChart from '../../../components/AnalyticsChart';
import List from '../../../components/List';
import {
  exportAnalyticDetailsToCSV,
  fetchAnalyticsChart,
  fetchAnalyticsDetail
} from '../../../api';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import { inflateChartSettings } from '../../../utils/analytics';
import Button from '../../../components/inputs/Button';

import styles from './styles.module.scss';

export class MyAnalyticsDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      size: 0,
      data: null,
      currentPeriod: { ...this.props.location?.state?.period } || {},
      savedPeriod: this.props.location?.state?.period
    };
  }

  loadData = () => {
    fetchAnalyticsDetail(
      inflateChartSettings(this.state?.chart, this.state.currentPeriod, true)
    ).then(data => {
      this.setState({
        data: data.data,
        types: data.types
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.location, prevProps.location)) {
      this.setState({ data: null }, this.loadData);
    }
    if (!isEqual(this.props.period, prevProps.period)) {
      this.setState({ data: null }, this.loadData);
    }
  }

  componentDidMount() {
    this.props.updateLeftNav('My Analytics');

    fetchAnalyticsChart(this.props.match?.params?.chartId).then(data =>
      this.setState({ chart: data }, this.loadData)
    );
  }

  resize = width => {
    if (width <= 0) return;

    this.setState({ size: width });
  };

  onPeriodChange = v => {
    this.setState(
      {
        currentPeriod: v,
        data: null
      },
      this.loadData
    );
  };

  onPeriodSave = v => {
    this.setState({ savedPeriod: v });
  };

  exportDetailsViewToCSV = async () => {
    const payload = {
      data: this.state.data,
      types: this.state.types
    };
    const src = await exportAnalyticDetailsToCSV(payload);
    window.location = src;
  };

  render() {
    const chart = this.state.chart;
    return (
      <HeaderAndFooter
        Header={
          <Header
            title="My Analytics"
            section="Detailed View"
            clickBack={() => history.goBack()}
            rightButtons={{
              text: 'Edit Card',
              color: 'blue',
              onClick: () =>
                history.push({
                  pathname: `/app/myAnalytics/card/${chart._id}`,
                  state: { chart }
                })
            }}
          />
        }
        className={styles.container}
        showFooter={false}
      >
        <AnalyticsPeriodPicker
          className={styles.datePicker}
          period={this.props.location?.state?.period}
          onChange={this.onPeriodChange}
          dashboardId={this.props.location?.state?.dashboardId}
          save={this.onPeriodSave}
        />
        <Widget className={styles.widget}>
          <div className={styles.preview}>
            <div className={styles.title}>{chart?.title}</div>
            <AnalyticsChart
              width={this.state.size}
              height={445}
              className={styles.previewBody}
              settings={chart}
              period={this.state.currentPeriod}
            />
            <ResizeDetector
              handleWidth
              handleHeight
              onResize={this.resize}
              nodeType="span"
              refreshMode="debounce"
              refreshRate={250}
            />
          </div>
        </Widget>
        <Widget className={styles.widget}>
          <div className={styles.download}>
            {this.state.data?.length ? (
              <Button
                text="Download Data"
                color="green"
                onClick={this.exportDetailsViewToCSV}
                inputClassName={styles.button}
              />
            ) : (
              <></>
            )}
          </div>
          <List data={this.state.data} settings={this.state.types} />
        </Widget>
      </HeaderAndFooter>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAnalyticsDetail);
