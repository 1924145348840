import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { stateAbbr } from '../../constants/states';
import expirationMapper from '../../utils/expirationMapper';
import {
  getLocationDropdown,
  getPersonnelDropdown,
  getProjectDropdown
} from '../../utils/formHelpers';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterAttachments,
  filterExpireDate,
  sortDateMethod,
  sortPersonnel
} from '../../utils/sortFilterHelpers';
import AddToFolderModal from '../Modal/addToFolderModal';
import Button from '../inputs/Button';
import Checkbox from '../inputs/Checkbox';
import { DatePicker } from '../inputs/DateTimePicker';
import DocumentFolder from '../DocumentFolder';
import Dropdown from '../inputs/Dropdown';
import FileDownloader from '../FileDownloader';
import FileUploader from '../FileUploader';
import Modal from '../Modal';
import Multiselect from '../inputs/Multiselect';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Textbox from '../inputs/Textbox';

export default class QualificationList extends Component {
  state = {
    modalOpen: 0,
    groupIds: [],
    projectIds: [],
    scheduledDate: '',
    expires: '',
    qualificationType: [],
    evaluationTypes: '',
    employeeId: [],
    certificationEntity: '',
    certificationId: '',
    certificateState: [],
    documentFolderId: '',
    _id: '',
    addToFolder: [],
    addToFolderModal: false,
    filters: []
  };

  componentDidMount = () => {
    if (localStorage.getItem('qualFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('qualFilters'))
      });
    }

    this.props.fetchDocumentFolders({
      documentType: 'Qualification',
      companyId: this.props.activeCompanyId
    });
    this.props.updateLeftNav('Qualifications');
    if (this.props.modalOpen) {
      this.setState({
        modalOpen: Number(this.props.modalOpen)
      });
    }

    if (this.props.activeQualification._id) {
      this.setState({
        ...this.props.activeQualification.documentData,
        scheduledDate: moment(
          this.props.activeQualification.documentData.scheduledDate
        ).format('MM/DD/YYYY'),
        expires: moment(this.props.activeQualification.expires).format(
          'MM/DD/YYYY'
        ),
        grades: this.props.activeQualification.documentData.grades,
        currentGrades: {
          currentGrades: this.props.activeQualification.documentData
            .currentGrades
        },
        _id: this.props.activeQualification._id,
        companyId: this.props.activeQualification.companyId
      });
    }
  };

  resetState = () => {
    this.setState({
      modalOpen: 0,
      groupIds: [],
      projectIds: [],
      scheduledDate: '',
      expires: '',
      qualificationType: [],
      evaluationTypes: '',
      employeeId: [],
      certificationEntity: '',
      certificationId: '',
      certificateState: [],
      documentFolderId: '',
      _id: ''
    });
  };

  handleOpenModal = value => {
    this.setState({ modalOpen: value });
  };

  handleCloseModal = () => {
    this.props.clearAttachment();
    this.props.setActiveQualification({});
    this.resetState();
  };

  updateState = stateObject => {
    this.setState(stateObject);
  };

  handleBasicSubmit = () => {
    if (this.state._id) {
      this.props.updateQualification({
        ...this.state,
        isFromEmployee: this.props.isFromEmployee
      });
    } else {
      this.props.addQualification({
        ...this.state,
        isFromEmployee: this.props.isFromEmployee
      });
    }

    this.props.clearAttachment();

    this.handleCloseModal();
  };

  handleSetActiveQualification = async (row, modalOpen) => {
    await this.setState({
      ...row.documentData,
      scheduledDate: moment(row.documentData.scheduledDate).format(
        'MM/DD/YYYY'
      ),
      expires: moment(row.expires).format('MM/DD/YYYY'),
      _id: row._id,
      companyId: row.companyId,
      groupIds: row.groupIds,
      projectIds: row.projectIds
    });
    await this.props.setActiveQualification(row);
    this.handleOpenModal(modalOpen);
  };

  handleDeleteQualification = () => {
    const { deleteQualification, activeQualification } = this.props;

    deleteQualification({
      ...activeQualification,
      isFromEmployee: this.props.isFromEmployee
    });

    this.handleCloseModal();
  };

  handleAddToFolder = id => {
    this.setState(state => {
      let { addToFolder } = state;

      const qrIndex = addToFolder.findIndex(i => i === id);

      if (qrIndex > -1) {
        addToFolder = addToFolder.filter(i => i !== id);
      } else {
        addToFolder = [...addToFolder, id];
      }

      return { addToFolder };
    });
  };

  handleAddMultipleToFolders = () => {
    const { content } = this.props;
    let addDocsToFolder = [];

    const addToFolder = this.state.addToFolder;

    content.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({ addToFolderModal: false, addToFolder: [] });
  };

  handleFilterChange = filters => {
    localStorage.setItem('qualFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  canSubmit = () => {
    const {
      groupIds,
      scheduledDate,
      expires,
      qualificationType,
      employeeId
    } = this.state;

    const { activeLocation } = this.props;

    if (
      (!groupIds && !activeLocation) ||
      !scheduledDate ||
      !expires ||
      !qualificationType ||
      !employeeId.length
    ) {
      return false;
    }

    return true;
  };

  render() {
    const {
      content,
      locationList,
      activeEmployees,
      activeQualification,
      activeLocation,
      activeProject,
      addedAttachments,
      deleteAttachment,
      activeAttachments,
      activeCompany,
      deleteQualificationAttachment,
      addAttachment,
      loading,
      qualificationTypes,
      accessLevel,
      activeFolder,
      documentFolders,
      isAddingToFolder,
      isOnEmployee
    } = this.props;

    const {
      modalOpen,
      certificationEntity,
      certificationId,
      qualificationType,
      employeeId,
      groupIds,
      projectIds,
      certificateState,
      expires,
      scheduledDate,
      documentFolderId,
      addToFolder,
      addToFolderModal,
      filters
    } = this.state;

    const qualificationTypeDropdown = qualificationTypes.map(
      (qualification, index) => {
        return { value: index, label: qualification.fullName };
      }
    );

    const getEmployeeName = employee => {
      if (employee.value) {
        return employee.label;
      } else if (employee.length > 0) {
        const temp = employee.map(emp => emp.label);
        return temp.join(', ');
      } else {
        return '';
      }
    };

    const folderDropdown = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });
    return (
      <div>
        <ScrollToTopOnMount />
        <h2 className="documents-header">
          {!isOnEmployee ? 'Qualifications' : ''}
          <div className="documents-buttonBox">
            {accessLevel !== '100' && (
              <Button
                className="greenButton"
                text="Add Qualification"
                onClick={() => {
                  this.resetState();
                  this.handleOpenModal(1);
                }}
              />
            )}
          </div>
        </h2>
        <DocumentFolder
          activeFolder={activeFolder}
          accessLevel={accessLevel}
          updateDocumentFolder={this.props.updateDocumentFolder}
          deleteDocumentFolder={this.props.deleteDocumentFolder}
          username={this.props.user.username}
          content={content}
          docType="Qualification"
        />
        <div className="documents-list">
          <ReactTable
            data={content}
            filterable
            onFilteredChange={filtered => this.handleFilterChange(filtered)}
            filtered={filters}
            resizable={false}
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            columns={[
              {
                Header: () =>
                  isAddingToFolder ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#58a15b"
                      className="loading"
                    />
                  ) : (
                    <Button
                      className="blueButton"
                      text="Add to Folder"
                      onClick={() => this.setState({ addToFolderModal: true })}
                    />
                  ),
                show: accessLevel !== '100',
                Cell: row => (
                  <Checkbox
                    name={row.original._id}
                    onChange={() => this.handleAddToFolder(row.original._id)}
                    currentValue={addToFolder.indexOf(row.original._id) !== -1}
                  />
                ),
                filterable: false,
                sortable: false
              },
              {
                Header: 'TITLE',
                accessor: d => d.documentData.qualificationType,
                id: 'title',
                Cell: ({ original }) => (
                  <div>{original.documentData.qualificationType.label}</div>
                ),
                style: { whiteSpace: 'unset' }
              },
              {
                Header: 'EMPLOYEE NAME',
                id: 'employeeIds',
                accessor: d => d.documentData.employeeId,
                sortMethod: (a, b) => sortPersonnel(a, b, activeEmployees),
                Cell: ({ original }) => (
                  <div>{getEmployeeName(original.documentData.employeeId)}</div>
                ),
                style: { whiteSpace: 'unset' },
                filterable: false
              },
              {
                Header: 'CERTIFICATE',
                accessor: 'attachments',
                Cell: ({ original }) => (
                  <div>
                    {original.attachments.map((attachment, attachmentIndex) => (
                      <FileDownloader
                        attachment={attachment}
                        key={attachmentIndex}
                        attachmentIndex={attachmentIndex}
                        disabled
                        isInList
                      />
                    ))}
                  </div>
                ),
                filterMethod: (filter, row) => filterAttachments(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="hasAttachments">Has Attachments</option>
                    <option value="noAttachments">No Attachments</option>
                  </select>
                ),
                sortable: false
              },
              {
                Header: 'EXPIRES',
                accessor: 'expires',
                Cell: ({ original }) => (
                  <div className="documents-list--severity">
                    <img
                      src={expirationMapper(original.expires)}
                      alt="severity"
                    />
                    <span>{moment(original.expires).format('MM/DD/YYYY')}</span>
                  </div>
                ),
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterExpireDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Expired</option>
                    <option value="within30">Expiring within 30 Days</option>
                    <option value="within60">Expiring within 60 Days</option>
                    <option value="past90">Expiring after 60 Days</option>
                  </select>
                )
              },
              {
                Header: 'VIEW/EDIT',
                Cell: ({ original }) => (
                  <Button
                    color="blue"
                    text="View/Edit"
                    onClick={() =>
                      original.documentData.qualificationType.value === 46
                        ? this.handleSetActiveQualification(original, 3)
                        : this.handleSetActiveQualification(original, 1)
                    }
                  />
                ),
                filterable: false,
                sortable: false
              },
              {
                Header: 'DELETE',
                Cell: ({ original }) => (
                  <Button
                    text="X"
                    color="red"
                    onClick={() =>
                      this.handleSetActiveQualification(original, 5)
                    }
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
            noDataText="No results found"
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
        {modalOpen === 1 && (
          <Modal
            title={
              activeQualification._id
                ? 'Edit Qualification'
                : 'Add Qualification'
            }
            titleClassName={
              activeQualification._id ? 'blueHeader' : 'greenHeader'
            }
            isOpen={modalOpen === 1}
            submitButtonColor="green"
            submitButtonText="Submit"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={() => this.handleBasicSubmit()}
            disableSubmit={!this.canSubmit()}
          >
            <div>
              {!activeLocation ? (
                <Multiselect
                  name="groupId"
                  fieldLabel="Group/Establishment"
                  options={getLocationDropdown(locationList)}
                  handleChange={values =>
                    this.updateState({ groupIds: values })
                  }
                  isRequired={true}
                  currentValue={groupIds}
                  searchable={true}
                />
              ) : (
                ''
              )}
              {!activeProject ? (
                <Multiselect
                  name="projectId"
                  fieldLabel="Project:"
                  options={getProjectDropdown(
                    groupIds,
                    activeLocation,
                    locationList
                  )}
                  placeholder="Select a project"
                  currentValue={projectIds}
                  handleChange={value =>
                    this.updateState({ projectIds: value })
                  }
                  searchable={true}
                />
              ) : (
                ''
              )}
              <Dropdown
                name="qualification"
                fieldLabel="Qualification"
                options={qualificationTypeDropdown}
                handleChange={values =>
                  this.updateState({ qualificationType: values })
                }
                isRequired={true}
                currentValue={qualificationType}
                searchable={true}
              />
              <Dropdown
                fieldLabel="Add to Folder:"
                options={folderDropdown}
                currentValue={documentFolderId}
                handleChange={value =>
                  this.updateState({ documentFolderId: value.value })
                }
                searchable={false}
              />
              <DatePicker
                fieldLabel="Schedule Date"
                handleChange={value =>
                  this.updateState({ scheduledDate: value })
                }
                currentValue={scheduledDate}
                isRequired
                appendToBody
              />
              <DatePicker
                fieldLabel="Expiration Date"
                handleChange={value => this.updateState({ expires: value })}
                currentValue={expires}
                isRequired
                appendToBody
              />
              <Dropdown
                name="statesOfCerts"
                fieldLabel="State of Certification"
                options={stateAbbr}
                className="state"
                placeholder="State"
                currentValue={certificateState}
                handleChange={value =>
                  this.updateState({ certificateState: value })
                }
                searchable={true}
              />
              <Multiselect
                name="employees"
                fieldLabel="Personnel Name"
                options={getPersonnelDropdown(activeEmployees)}
                handleChange={values =>
                  this.updateState({ employeeId: values })
                }
                isRequired={true}
                currentValue={employeeId}
                searchable={true}
              />
              <Textbox
                currentValue={certificationEntity}
                fieldLabel="Certification Entity"
                handleChange={e =>
                  this.updateState({ certificationEntity: e.target.value })
                }
                isRequired={true}
              />
              <Textbox
                currentValue={certificationId}
                fieldLabel="Certification ID"
                handleChange={e =>
                  this.updateState({ certificationId: e.target.value })
                }
                isRequired={true}
              />
              <div>
                <h4>Attachments</h4>
                {addedAttachments &&
                  addedAttachments.map((attachment, index) => (
                    <FileDownloader
                      attachment={attachment}
                      key={index}
                      attachmentIndex={index}
                      deleteAttachment={deleteAttachment}
                    />
                  ))}
                {activeAttachments &&
                  activeAttachments.map((attachment, attachmentIndex) => (
                    <FileDownloader
                      attachment={attachment}
                      key={attachmentIndex}
                      attachmentIndex={attachmentIndex}
                      deleteAttachment={deleteQualificationAttachment}
                    />
                  ))}
                <FileUploader
                  activeCompanyId={activeCompany._id}
                  addAttachment={addAttachment}
                  loading={loading}
                  ownerId="1234"
                  ownerType="documents"
                />
              </div>
            </div>
          </Modal>
        )}
        <Modal
          title="Delete Qualification"
          titleClassName="redHeader"
          isOpen={modalOpen === 5}
          submitButtonColor="red"
          submitButtonText="Delete"
          onRequestClose={() => this.handleCloseModal()}
          submitActions={() => this.handleDeleteQualification()}
        >
          <h2>Are you sure you want to delete this qualification?</h2>
        </Modal>
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={() => this.handleAddMultipleToFolders()}
          folderOptions={folderDropdown}
          handleChange={values =>
            this.setState({ documentFolderId: values.value })
          }
        />
      </div>
    );
  }
}
