import config from '../config/config';

const featureToggles = config.featureToggles;

export const leftNavigation = {
  sections: [
    {
      title: 'General',
      section: [
        {
          text: 'Dashboard',
          image: 'Dashboard',
          active: true,
          link: '/app/dashboard',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Tasks',
          image: 'Tasks',
          active: false,
          link: '/app/tasks',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Reports',
          image: 'Incidents',
          active: false,
          link: '/app/Incidents',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Analytics',
          image: 'Analytics',
          active: false,
          link: '/app/analytics',
          accessLevel: ['500', '900'],
          appLevel: ['Division', 'Group'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'My Analytics',
          image: 'BarChart',
          active: false,
          link: '/app/myAnalytics',
          accessLevel: ['900'],
          appLevel: ['Division', 'Group'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'OSHA Forms',
          image: 'oshaLogo',
          active: false,
          link: '/app/oshaLog',
          accessLevel: ['500', '900'],
          appLevel: ['Division', 'Group'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: ['100', '400', '500', '900']
    },
    {
      title: 'Incidents',
      section: [
        {
          text: 'First Report of Injury',
          image: 'first-report-of-injury',
          active: false,
          link: '/app/reportStages/First Report of Injury/2',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Auto Incident Report',
          image: 'auto-accident',
          active: false,
          link: '/app/reportStages/Auto Incident Report/1',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'General Liability',
          image: 'general-liability',
          active: false,
          link: '/app/reportStages/General Liability/3',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Near Miss Reports',
          image: 'near-miss-report',
          active: false,
          link: '/app/reportStages/Near Miss Reports/7',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Aircraft Reports',
          image: '6',
          active: false,
          link: '/app/reportStages/Aircraft Reports/6',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Fuel Related Reports',
          image: '16',
          active: false,
          link: '/app/reportStages/Fuel Related Reports/16',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: ['400', '500', '900']
    },
    {
      title: 'Observations',
      section: [
        {
          text: 'Risk Assessment',
          image: 'jsa',
          active: false,
          link: '/app/raStages',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'See Something Say Something',
          image: '11',
          active: false,
          link: '/app/reportStages/See Something Say Something/11',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Walks',
          image: 'site-inspection',
          active: false,
          link: '/app/safetyWalkStages',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Behavior Observation',
          image: 'critical-behavior',
          active: false,
          link: '/app/reportStages/Behavior Observation/12',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: ['400', '500', '900']
    },
    {
      title: 'Custom Templates',
      section: [
        {
          text: 'Custom Risk Assessments',
          image: 'CustomRa',
          active: false,
          link: '/app/customRaList',
          accessLevel: ['900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Custom Report Questions',
          image: 'CustomQuestions',
          active: false,
          link: '/app/customReportQuestionsList',
          accessLevel: ['900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Custom Document Templates',
          image: 'CustomDocument',
          active: false,
          link: '/app/customDocumentTemplateList',
          accessLevel: ['900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: featureToggles.customDocuments
        },
        {
          text: 'Custom Safety Trainings',
          image: 'safety-training',
          active: false,
          link: '/app/training/templateList',
          accessLevel: ['900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Custom Quizzes BETA',
          image: 'quiz',
          active: false,
          link: '/app/quiz/quizList',
          accessLevel: ['900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: ['900']
    },
    {
      title: 'Documents',
      section: [
        {
          text: 'Require Acknowledgment',
          image: '',
          active: false,
          link: '/app/requireAuthList',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'My Documents',
          image: '',
          active: false,
          link: '/app/myDocumentsStages',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Procedures',
          image: 'safety-instruction',
          active: false,
          link: '/app/documents/safetyInstructions',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SafetyInstruction',
          showFeature: true
        },
        {
          text: 'SDS',
          image: 'sds',
          active: false,
          link: '/app/documents/sds',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SDS',
          showFeature: true
        },
        {
          text: 'Safety Trainings',
          image: 'safety-training',
          active: false,
          link: '/app/training/trainingList',
          accessLevel: ['400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SafetyTraining',
          showFeature: true
        },
        {
          text: 'Qualifications',
          image: 'qualifications',
          active: false,
          link: '/app/documents/qualifications',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'Qualification',
          showFeature: true
        },
        {
          text: 'Daily Reports',
          image: 'daily-reports',
          active: false,
          link: '/app/documents/dailyReports',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'DailyReports',
          showFeature: false
        },
        {
          text: 'Toolbox Talks',
          image: 'toolbox',
          active: false,
          link: '/app/documents/toolboxTalks',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'ToolboxTalks',
          showFeature: true
        },
        {
          text: 'Custom Documents',
          image: 'CustomDocument',
          active: false,
          link: '/app/customDocumentList',
          accessLevel: ['100', '400', '500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: featureToggles.customDocuments
        }
      ],
      accessLevel: ['100', '400', '500', '900']
    },
    {
      title: 'Settings',
      section: [
        {
          text: 'Employees',
          image: 'Employees',
          active: false,
          link: '/app/settings/Employees',
          accessLevel: ['500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Projects',
          image: 'Projects',
          active: false,
          link: '/app/settings/Projects',
          accessLevel: ['900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Groups / Establishments',
          image: 'Locations',
          active: false,
          link: '/app/settings/Locations',
          accessLevel: ['500', '900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Company Division',
          image: 'Dashboard',
          active: false,
          link: '/app/settings/CompanyDetails',
          accessLevel: ['900'],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: ['500', '900']
    }
  ]
};
