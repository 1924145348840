import React, { Component } from 'react';

import { RECURRING_OPTIONS } from '../../../constants/constants';
import { TimePicker } from '../../inputs/DateTimePicker';
import Dropdown from '../../inputs/Dropdown';
import { parseTime, renderTime } from '../../../utils/dateHelpers';
import Input from '..';

import styles from './dueBy.module.scss';

export default class DueBy extends Component {
  render() {
    const {
      disabled,
      dueBy,
      dueTime,
      name,
      onDueByChange,
      onDueTimeChange,
      ...props
    } = this.props;

    const [ hours, minutes ] = parseTime(dueTime);

    return (
      <Input
        disabled={disabled}
        name={name}
        {...props}
      >
        <div className={styles.container}>
          <Dropdown
            options={RECURRING_OPTIONS}
            currentValue={dueBy}
            handleChange={onDueByChange}
            disabled={disabled}
            name={name}
            className={styles.recurring}
          />
          <TimePicker
            handleChange={(hour, minute) =>
              onDueTimeChange(renderTime(hour, minute, true))
            }
            hours={hours}
            minutes={minutes}
            disabled={disabled}
            name={`${name}-time`}
            className={styles.time}
          />
        </div>
      </Input>
    );
  }
}
