import {
  ADD_JSA_ATTACHMENTS_REQUEST,
  ADD_JSA_ATTACHMENTS_RESPONSE,
  ADD_JSA_NOTES_REQUEST,
  ADD_JSA_NOTES_RESPONSE,
  CREATE_JSA_REQUEST,
  CREATE_JSA_RESPONSE,
  CREATE_RA_CUSTOM_TEMPLATE_REQUEST,
  DELETE_JSA_REQUEST,
  DELETE_JSA_RESPONSE,
  DELETE_RA_CUSTOM_TEMPLATE_REQUEST,
  FETCH_COMPANY_JSA_REQUEST,
  FETCH_COMPANY_JSA_RESPONSE,
  FETCH_JSA_ATTACHMENTS_REQUEST,
  FETCH_JSA_ATTACHMENTS_RESPONSE,
  FETCH_JSA_CIRCLES_RESPONSE,
  FETCH_JSA_NOTES_REQUEST,
  FETCH_JSA_NOTES_RESPONSE,
  FETCH_JSA_SECTIONS_REQUEST,
  FETCH_JSA_SECTIONS_RESPONSE,
  FETCH_LOCATION_JSA_REQUEST,
  FETCH_LOCATION_JSA_RESPONSE,
  FETCH_PROJECT_JSA_REQUEST,
  FETCH_PROJECT_JSA_RESPONSE,
  FETCH_RA_CUSTOM_TEMPLATES_REQUEST,
  FETCH_RA_CUSTOM_TEMPLATES_RESPONSE,
  FETCH_USER_JSAS_REQUEST,
  SET_ACTIVE_JSA_REQUEST,
  SET_ACTIVE_JSA_RESPONSE,
  SET_ACTIVE_RA_CUSTOM_TEMPLATE,
  UPDATE_JSA_REQUEST,
  UPDATE_JSA_RESPONSE,
  UPDATE_RA_CUSTOM_TEMPLATE_REQUEST
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  list: [],
  sections: [],
  circles: [],
  activeJsa: {},
  notes: [],
  attachments: [],
  customTemplates: [],
  activeCustomTemplate: {},
  paginationInfo: {
    hasNextPage: false,
    hasPrevPage: false,
    limit: '',
    nextPage: '',
    page: '',
    prevPage: '',
    totalDocs: '',
    totalPages: ''
  }
};

const jsa = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_JSA_SECTIONS_REQUEST:
    case FETCH_COMPANY_JSA_REQUEST:
    case FETCH_LOCATION_JSA_REQUEST:
    case FETCH_PROJECT_JSA_REQUEST:
    case CREATE_JSA_REQUEST:
    case UPDATE_JSA_REQUEST:
    case SET_ACTIVE_JSA_REQUEST:
    case FETCH_JSA_NOTES_REQUEST:
    case ADD_JSA_NOTES_REQUEST:
    case FETCH_JSA_ATTACHMENTS_REQUEST:
    case ADD_JSA_ATTACHMENTS_REQUEST:
    case FETCH_USER_JSAS_REQUEST:
    case DELETE_JSA_REQUEST:
    case FETCH_RA_CUSTOM_TEMPLATES_REQUEST:
    case CREATE_RA_CUSTOM_TEMPLATE_REQUEST:
    case UPDATE_RA_CUSTOM_TEMPLATE_REQUEST:
    case DELETE_RA_CUSTOM_TEMPLATE_REQUEST:
      return { ...state, loading: true };

    case FETCH_JSA_SECTIONS_RESPONSE:
      return {
        ...state,
        sections: payload,
        loading: false,
        customTemplates: []
      };

    case FETCH_COMPANY_JSA_RESPONSE:
    case FETCH_LOCATION_JSA_RESPONSE:
    case FETCH_PROJECT_JSA_RESPONSE:
      return {
        ...state,
        loading: false,
        ...payload
      };

    case FETCH_JSA_CIRCLES_RESPONSE:
      return {
        ...state,
        loading: false,
        circles: payload
      };

    case CREATE_JSA_RESPONSE:
      return {
        ...state,
        loading: false,
        activeJsa: payload,
        list: [{ ...payload }, ...state.list]
      };

    case UPDATE_JSA_RESPONSE: {
      const jsaList = state.list.map(jsa => {
        if (jsa._id === payload._id) {
          return {
            ...payload
          };
        }
        return jsa;
      });

      return {
        ...state,
        loading: false,
        list: jsaList
      };
    }

    case DELETE_JSA_RESPONSE: {
      const jsaList = state.list;

      const index = jsaList.findIndex(jsa => jsa._id === payload);

      return {
        ...state,
        loading: false,
        list: [...jsaList.slice(0, index), ...jsaList.slice(index + 1)],
        activeJsa: {}
      };
    }

    case SET_ACTIVE_JSA_RESPONSE: {
      if (!Object.keys(payload).length) {
        return {
          ...state,
          loading: false,
          activeJsa: {}
        };
      }

      const scores = getTotalScore(payload);

      return {
        ...state,
        loading: false,
        activeJsa: {
          ...payload,
          totalScore: scores.totalScore,
          safeScore: scores.safeScore,
          percentage: scores.percentage
        }
      };
    }

    case FETCH_JSA_NOTES_RESPONSE: {
      const notes = getActionItems(state.activeJsa, payload).notes;

      return {
        ...state,
        loading: false,
        notes: notes
      };
    }

    case ADD_JSA_NOTES_RESPONSE:
      return {
        ...state,
        loading: false,
        notes: payload
      };

    case FETCH_JSA_ATTACHMENTS_RESPONSE: {
      const attachments = getActionItems(state.activeJsa, payload).attachments;

      return {
        ...state,
        loading: false,
        attachments: attachments
      };
    }

    case ADD_JSA_ATTACHMENTS_RESPONSE:
      return {
        ...state,
        loading: false,
        attachments: payload
      };

    case FETCH_RA_CUSTOM_TEMPLATES_RESPONSE:
      return {
        ...state,
        loading: false,
        customTemplates: payload,
        sections: []
      };

    case SET_ACTIVE_RA_CUSTOM_TEMPLATE:
      return {
        ...state,
        activeCustomTemplate: payload
      };

    default:
      return state;
  }
};

export const getActionItems = (activeJsa, questionId) => {
  let comments = [];
  let attachments = [];
  let notes = [];

  activeJsa.sections.forEach(section => {
    section.questions.forEach(question => {
      if (question._id === questionId) {
        comments =
          question.actionItems && question.actionItems.comments
            ? question.actionItems.comments
            : [];

        attachments =
          question.actionItems && question.actionItems.attachments
            ? question.actionItems.attachments
            : [];

        notes =
          question.actionItems && question.actionItems.notes
            ? question.actionItems.notes
            : [];
      }
    });
  });

  return { comments, attachments, notes };
};

export const getTotalScore = activeJsa => {
  let totalScore = 0;
  let safeScore = 0;

  activeJsa.sections.forEach(section => {
    section.questions.forEach(question => {
      // eslint-disable-next-line
      question.answer && question.answer != '3'
        ? (totalScore += 1)
        : (totalScore += 0);
      // eslint-disable-next-line
      question.answer && question.answer == '1'
        ? (safeScore += 1)
        : (safeScore += 0);
    });
  });

  let percentage = (safeScore / totalScore) * 100;
  percentage = Number.isNaN(percentage) ? 0 : Math.floor(percentage).toFixed(2);

  return { totalScore, safeScore, percentage };
};

export default jsa;
