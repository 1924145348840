/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import history from '../../../history';
import * as api from '../../../api';
import {
  addUploadedAttachmentsRequest,
  deleteAttachmentRequest,
  clearUploadedAttachments
} from '../../../actions/attachments';
import {
  fetchAllActiveEmployees,
  fetchAllActiveEmployeesActiveUsers
} from '../../../actions/personnel';
import {
  addTrainingRequest,
  updateTrainingRequest,
  deleteTrainingRequest,
  fetchCustomTrainingTemplatesRequest,
  setActiveTrainingResponse
} from '../../../actions/training';
import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS,
  REPEATING_OPTIONS,
  TRAINING_FILE_TYPES_ACCEPTED
} from '../../../constants/constants';
import { getAddedAttachmentsSelector } from '../../../selectors/attachments';
import {
  getActiveLocationId,
  getActiveProject,
  getActiveCompany
} from '../../../selectors/company';
import { getDocumentFoldersSelector } from '../../../selectors/documents';
import {
  getCompanyUsersSelector,
  getCompanyActiveEmployeesSelector
} from '../../../selectors/personnel';
import {
  getTemplateListSelector,
  getActiveTrainingSelector
} from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import { positiveIntsOnly } from '../../../utils/formValidation';
import AddYouTubeLinksModal from '../../../components/Modal/addYouTubeLinksModal';
import Button from '../../../components/inputs/Button';
import Card from '../../../components/Card';
import { InputRow } from '../../../components/inputs';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import DateUnitPicker from '../../../components/inputs/DateUnitPicker';
import DayOfWeekButtons from '../../../components/inputs/DayOfWeekButtons';
import Dropdown from '../../../components/inputs/Dropdown';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import FileDownloader from '../../../components/FileDownloader';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import Multiselect from '../../../components/inputs/Multiselect';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import TrainingTemplate from '../../../components/training/TrainingTemplate';
import Textbox from '../../../components/inputs/Textbox';
import DueBy from '../../../components/inputs/DueBy';
import { TwoColumn } from '../../../components/inputs';

import styles from './styles.module.scss';

class TrainingContainer extends Component {
  state = {
    title: '',
    instructions: '',
    needsApproval: false,
    doesExpire: false,
    expiresAfterNumber: '',
    expiresAfterOption: { value: 'days', label: 'Days' },
    hasUnsavedChanges: false,
    isEditing: false,
    isCreating: false,
    openDeleteConfirmationModal: false,
    openUnsavedChages: false,
    attachments: [],
    schedule: {},
    trainingType: {},
    frequency: '',
    repeatingOn: [],
    repeatingOption: [],
    dueDate: '',
    assignedTo: [],
    approver: {},
    dueByTime: '',
    trainingTemplateId: '',
    assignBeforeExpirationNumber: '',
    assignBeforeExpirationOption: { value: 'days', label: 'Days' },
    groupIds: [],
    projectIds: [],
    documentFolderId: '',
    completionDate: '',
    youtubeLinks: [],
    openYoutubeLinksModal: false,
    quizzes: [],
    quizMasterId: ''
  };

  componentDidMount() {
    this.props.fetchCustomTrainingTemplates();

    const activeTemplate = this.props.activeTraining;

    if (activeTemplate?._id) {
      this.props.fetchEmployees();

      this.setState({
        ...activeTemplate,
        trainingType: activeTemplate.legacy
          ? { label: 'Historical Training', value: 3 }
          : activeTemplate.trainingType
      });
    } else {
      this.props.fetchUsers();
      this.setState({
        isCreating: true,
        trainingType: { label: 'Custom Training', value: 2 },
        expiresAfterOption: { label: 'Days', value: 'days' },
        isFromEmployee: activeTemplate?.isFromEmployee
      });
    }
    api
      .fetchQuizzes(this.props.activeCompany._id)
      .then(response => this.setState({ quizzes: response }));
  }

  handleTrainingTypeSelection = value => {
    const { templates } = this.props;

    const index = templates.findIndex(template => template._id === value.value);

    this.setState({
      ...templates[index],
      _id: 0,
      trainingTemplateId: templates[index]._id
    });
  };

  resetState = () => {
    this.setState({
      title: '',
      instructions: '',
      needsApproval: false,
      doesExpire: false,
      expiresAfterNumber: '',
      expiresAfterOption: { value: 'days', label: 'Days' },
      hasUnsavedChanges: false,
      openDeleteConfirmationModal: false,
      openUnsavedChages: false,
      attachments: [],
      schedule: {},
      trainingType: {},
      frequency: '',
      repeatingOn: [],
      repeatingOption: [],
      dueDate: '',
      assignedTo: [],
      approver: {},
      dueByTime: '',
      trainingTemplateId: '',
      assignBeforeExpirationNumber: '',
      assignBeforeExpirationOption: { value: 'days', label: 'Days' },
      completionDate: '',
      youtubeLinks: [],
      openYoutubeLinksModal: false,
      quizMasterId: ''
    });
  };

  handleTypeSelection = values => {
    this.resetState();
    values.label === 'Historical Training'
      ? this.setState({
          trainingType: values,
          schedule: { value: 1, label: 'Immediately' },
          hasUnsavedChanges: true
        })
      : this.setState({
          trainingType: values,
          hasUnsavedChanges: true
        });
  };

  handleSubmit = () => {
    const { addTraining, updateTraining } = this.props;

    if (this.state._id) {
      updateTraining(this.state);
    } else {
      addTraining(this.state);
    }
  };

  canSubmit = () => {
    const hasAttachmentOrQuiz =
      this.state.attachments.length > 0 ||
      this.props.addedAttachments.length > 0 ||
      this.state.youtubeLinks.length > 0 ||
      this.state.quizMasterId;

    if (
      this.state.title.trim().length > 2 &&
      ((this.state.schedule?.value === 1 &&
        ((this.state.dueDate && moment().isBefore(this.state.dueDate)) ||
          this.state.trainingType.value === 3) &&
        this.state.assignedTo.length > 0) ||
        (this.state.schedule?.value === 2 &&
          this.state.assignedTo.length > 0 &&
          ((this.state.frequency &&
            (this.state.repeatingOn.length > 0 ||
              this.state.repeatingOn.value)) ||
            this.state.doesExpire) &&
          this.state.repeatingOption.value &&
          this.state.dueByTime) ||
        this.state.schedule?.value === 3)
    ) {
      if (this.state.trainingType.value !== 3 && !hasAttachmentOrQuiz) {
        return false;
      }

      if (
        this.state.needsApproval &&
        !this.state.approver &&
        !this.state.approver?.value
      ) {
        return false;
      }

      if (
        this.state.doesExpire &&
        !this.state.expiresAfterNumber &&
        this.state.trainingType.label !== 'Historical Training'
      ) {
        return false;
      }

      if (
        this.state.doesExpire &&
        this.state.schedule?.value === 2 &&
        !this.state.assignBeforeExpirationNumber
      ) {
        return false;
      }

      return true;
    }

    return false;
  };

  handleDelete = () => {
    const { deleteTraining } = this.props;

    deleteTraining(this.state);
  };

  handleBack = () => {
    if (this.state.isEditing) {
      this.handleSubmit();
    }
    this.props.setActiveTemplate();
    this.props.clearUploadedAttachments();
    history.goBack();
  };

  addAttachments = async e => {
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const FileSize = files[i].size / 1024 / 1024; // in MB
      if (FileSize > 2000) {
        alert('File size exceeds 2000 MB');
        return;
      }
    }

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    await this.props.addAttachments({
      data,
      ownerId: '1234',
      ownerType: 'TrainingTemplate'
    });

    this.setState({ hasUnsavedChanges: true });
  };

  render() {
    const {
      title,
      instructions,
      needsApproval,
      doesExpire,
      expiresAfterOption,
      expiresAfterNumber,
      hasUnsavedChanges,
      isEditing,
      isCreating,
      openDeleteConfirmationModal,
      openUnsavedChages,
      attachments,
      schedule,
      trainingType,
      frequency,
      repeatingOn,
      repeatingOption,
      dueDate,
      dueByTime,
      assignedTo,
      approver,
      assignBeforeExpirationNumber,
      assignBeforeExpirationOption,
      groupIds,
      projectIds,
      documentFolderId,
      trainingTemplateId,
      legacy,
      completionDate,
      youtubeLinks,
      openYoutubeLinksModal,
      quizzes,
      quizMasterId
    } = this.state;

    const {
      addedAttachments,
      users,
      templates,
      activeGroup,
      deleteAttachment,
      activeProject,
      activeCompany,
      documentFolders,
      activeEmployees
    } = this.props;

    const quizDropdown = quizzes?.map(quiz => {
      return {
        label: quiz.title,
        value: quiz._id
      };
    });

    const employeeDropdown =
      !legacy && trainingType?.label !== 'Historical Training'
        ? users.map(user => {
            return {
              label: `${user.person.firstName} ${user.person.lastName}`,
              value: user?.userAccount?._id
            };
          })
        : activeEmployees.map(emp => {
            return {
              label: `${emp.person.firstName} ${emp.person.lastName}`,
              value: emp._id
            };
          });

    const approverDropdown =
      activeGroup?._id || groupIds.length > 0
        ? users
            .filter(user => user.userAccount.accessLevel != 100)
            .map(user => {
              return {
                label: `${user.person.firstName} ${user.person.lastName}`,
                value: user?.userAccount?._id
              };
            })
        : users
            .filter(user => user.userAccount.accessLevel == 900)
            .map(user => {
              return {
                label: `${user.person.firstName} ${user.person.lastName}`,
                value: user?.userAccount?._id
              };
            });

    const templateDropdown = templates.map(template => {
      return {
        value: template._id,
        label: template.title
      };
    });

    const header = (
      <Header
        title="Training"
        section={
          !isCreating && !isEditing
            ? 'View Training'
            : isEditing
            ? 'Edit Training'
            : 'Create Training'
        }
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChages: true })
            : this.handleBack()
        }
        rightButtons={
          isEditing
            ? {
                text: 'Delete Training',
                onClick: () =>
                  this.setState({ openDeleteConfirmationModal: true }),
                color: 'red',
                visible: !isCreating
              }
            : {
                text: 'Edit Training',
                onClick: () => this.setState({ isEditing: true }),
                color: 'blue',
                visible: !isCreating
              }
        }
      />
    );

    const footer = (
      <SaveCancelFooter
        editing={isEditing}
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={() => this.handleSubmit()}
        cancelButtonClick={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChages: true })
            : history.goBack()
        }
      />
    );

    const folderDropdown = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    const groupDropdown = activeCompany.locations.map(group => {
      return {
        label: group.name,
        value: group._id
      };
    });

    const getProjectDropdown = () => {
      let groups;
      if (groupIds?.length) {
        groups = groupIds;
      } else if (activeGroup) {
        groups = [activeGroup._id];
      } else {
        return [];
      }

      let projectsDropdown = [];
      groups.forEach(g => {
        let group = activeCompany.locations.find(l => l._id === g.value);
        if (!group) return;

        group.projects.forEach(project =>
          projectsDropdown.push({
            value: project._id,
            label: `${group.name} - ${project.name}`
          })
        );
      });

      return projectsDropdown;
    };

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isEditing || isCreating}
          className={styles.trainingContainer}
        >
          <Card showHeader title="Training Creation">
            <RadioButtons
              options={[
                { label: 'Training Template', value: 1 },
                { label: 'Custom Training', value: 2 },
                { label: 'Historical Training', value: 3 }
              ]}
              currentValue={trainingType}
              handleChange={values => this.handleTypeSelection(values)}
              fieldLabel="Training Type"
              disabled={!isCreating}
            />
            {trainingType?.value === 1 && (
              <Dropdown
                options={templateDropdown}
                fieldLabel="Training Template"
                currentValue={trainingTemplateId}
                handleChange={values =>
                  this.handleTrainingTypeSelection(values)
                }
                isRequired={true}
                disabled={!isCreating || templates.length === 0}
                placeholder={
                  templates.length > 0
                    ? 'Select A Template'
                    : 'No Templates to choose from. Create one under custom templates'
                }
              />
            )}
            <TwoColumn>
              {!activeGroup && (
                <Multiselect
                  searchable={true}
                  options={groupDropdown}
                  fieldLabel="Group or Establishment:"
                  currentValue={groupIds}
                  handleChange={values => {
                    this.setState({ groupIds: values });
                    const ids = values.map(v => v.value);
                    this.props.fetchEmployees({ groupIds: ids });
                    this.props.fetchUsers({ groupIds: ids });
                  }}
                  disabled={!isCreating && !isEditing}
                />
              )}

              {!activeProject && (
                <Multiselect
                  searchable={true}
                  options={getProjectDropdown()}
                  fieldLabel="Project:"
                  currentValue={projectIds}
                  handleChange={values => {
                    this.setState({ projectIds: values });
                    const ids = values.map(v => v.value);
                    this.props.fetchEmployees({ projectIds: ids });
                    this.props.fetchUsers({ projectIds: ids });
                  }}
                  disabled={!isCreating && !isEditing}
                />
              )}
            </TwoColumn>
            <Dropdown
              fieldLabel="Add To Folder"
              options={folderDropdown}
              currentValue={documentFolderId}
              handleChange={values =>
                this.setState({
                  documentFolderId: values.value
                })
              }
            />
            <TrainingTemplate
              updateAnswer={values => this.setState({ ...values })}
              title={title}
              instructions={instructions}
              needsApproval={needsApproval}
              doesExpire={doesExpire}
              expiresAfterNumber={expiresAfterNumber}
              expiresAfterOption={expiresAfterOption}
              isEditing={isEditing}
              isCreating={isCreating}
              disabled={trainingType?.value === 1}
              employeeDropdown={approverDropdown}
              approver={approver}
              trainingType={trainingType}
              quizDropdown={quizDropdown}
              quizMasterId={quizMasterId}
            />
            {trainingType?.label !== 'Historical Training' && (
              <RadioButtons
                fieldLabel="Schedule"
                options={REPEATING_OPTIONS}
                currentValue={schedule}
                handleChange={values =>
                  this.setState({
                    schedule: values,
                    hasUnsavedChanges: true
                  })
                }
                disabled={!isCreating && !isEditing}
              />
            )}
            {schedule.value === 2 && doesExpire && (
              <div className={styles.beforeExpires}>
                <Textbox
                  fieldLabel={`Assign how many ${assignBeforeExpirationOption?.value} before expiration`}
                  isRequired={true}
                  type="number"
                  handleChange={e => {
                    const onlyNums = positiveIntsOnly(e.target.value);
                    this.setState({
                      assignBeforeExpirationNumber: onlyNums,
                      hasUnsavedChanges: true
                    });
                  }}
                  currentValue={assignBeforeExpirationNumber}
                />
                <div className={styles.topMargin}>
                  <DateUnitPicker
                    currentValue={assignBeforeExpirationOption}
                    handleChange={values =>
                      this.setState({
                        assignBeforeExpirationOption: values,
                        hasUnsavedChanges: true
                      })
                    }
                  />
                </div>
              </div>
            )}
            {schedule.value === 2 && !doesExpire && (
              <Dropdown
                options={FREQUENCY_OPTIONS}
                fieldLabel="Frequency:"
                currentValue={frequency}
                handleChange={values =>
                  this.setState({ frequency: values.value })
                }
                isRequired={true}
                disabled={!isCreating && !isEditing}
              />
            )}
            {schedule.value === 2 && frequency === 'weekly' && !doesExpire && (
              <DayOfWeekButtons
                fieldLabel="Assign Every:"
                inputClassName="dayOfWeekInput"
                disabled={!isCreating && !isEditing}
                handleChange={values => this.setState({ repeatingOn: values })}
                currentValue={repeatingOn}
              />
            )}
            {schedule.value === 2 && frequency === 'monthly' && !doesExpire && (
              <Dropdown
                options={MONTHLY_FREQUENCY_OPTIONS}
                fieldLabel="Assign Every:"
                currentValue={repeatingOn}
                handleChange={values => this.setState({ repeatingOn: values })}
                isRequired={true}
                disabled={!isCreating && !isEditing}
              />
            )}
            {schedule.value === 2 && frequency === 'yearly' && !doesExpire && (
              <Dropdown
                options={YEARLY_FREQUENCY_OPTIONS}
                fieldLabel="Assign Every:"
                currentValue={repeatingOn}
                handleChange={values => this.setState({ repeatingOn: values })}
                isRequired={true}
                disabled={!isCreating && !isEditing}
              />
            )}
            {schedule.value === 2 ? (
              <DueBy
                fieldLabel="Due By:"
                isRequired
                disabled={!isCreating && !isEditing}
                dueBy={repeatingOption}
                dueTime={dueByTime}
                onDueByChange={v => this.setState({ repeatingOption: v })}
                onDueTimeChange={v => this.setState({ dueByTime: v })}
              />
            ) : (schedule.value === 1 &&
                trainingType.label !== 'Historical Training') ||
              (trainingType.label === 'Historical Training' && doesExpire) ? (
              <DatePicker
                fieldLabel={
                  trainingType.label === 'Historical Training'
                    ? 'Expires On:'
                    : 'Due By: '
                }
                isRequired
                handleChange={value => this.setState({ dueDate: value })}
                pickTime
                currentValue={dueDate}
                disabled={!isCreating && !isEditing}
              />
            ) : (
              ''
            )}
            {trainingType.label === 'Historical Training' && (
              <DatePicker
                fieldLabel="Completed On"
                handleChange={value => this.setState({ completionDate: value })}
                pickTime
                currentValue={completionDate}
                disabled={!isCreating && !isEditing}
              />
            )}
            {schedule.value !== 3 && (
              <Multiselect
                options={employeeDropdown}
                fieldLabel="Assignee(s):"
                currentValue={assignedTo}
                isRequired={true}
                handleChange={values => this.setState({ assignedTo: values })}
                disabled={(!isCreating && !isEditing) || legacy}
                searchable
                name="multiEmployees"
                selectButtons
              />
            )}
            <InputRow>
              <Button
                text="YouTube Links"
                color="blue"
                onClick={() => this.setState({ openYoutubeLinksModal: true })}
                disabled={!isCreating && !isEditing}
              />
              <Button
                text="Upload Training Materials"
                color="blue"
                onClick={() => document.getElementById(`myImage`).click()}
                disabled={!isCreating && !isEditing}
              />
            </InputRow>
            <input
              type="file"
              id="myImage"
              className={styles.fileUpload}
              onChange={e => this.addAttachments(e)}
              multiple
              accept={TRAINING_FILE_TYPES_ACCEPTED.join(', ')}
            />
            {(addedAttachments?.length > 0 || attachments?.length > 0) && (
              <div className={styles.attachmentContainer}>
                {addedAttachments.map((attachment, index) => (
                  <FileDownloader
                    attachment={attachment}
                    attachmentIndex={index}
                    disabled={!isEditing && !isCreating}
                    key={index}
                    isInTraining
                    deleteAttachment={deleteAttachment}
                  />
                ))}
                {attachments.map((attachment, index) => (
                  <FileDownloader
                    attachment={attachment}
                    attachmentIndex={index}
                    disabled
                    key={index}
                    isInTraining
                  />
                ))}
              </div>
            )}
          </Card>
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChages}
          savingWhat="Training"
          onRequestClose={() => this.setState({ openUnsavedChages: false })}
          submitActions={() => this.handleBack()}
        />
        <DeleteItemModal
          isOpen={openDeleteConfirmationModal}
          deletingWhat="Training"
          onRequestClose={() =>
            this.setState({ openDeleteConfirmationModal: false })
          }
          submitActions={() => this.handleDelete()}
        />
        <AddYouTubeLinksModal
          isOpen={openYoutubeLinksModal}
          onRequestClose={() => this.setState({ openYoutubeLinksModal: false })}
          submitActions={values =>
            this.setState({
              youtubeLinks: values,
              openYoutubeLinksModal: false
            })
          }
          youtubeLinks={youtubeLinks}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeTraining: getActiveTrainingSelector(state),
  users: getCompanyUsersSelector(state),
  templates: getTemplateListSelector(state),
  activeGroup: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  activeCompany: getActiveCompany(state),
  documentFolders: getDocumentFoldersSelector(state),
  activeEmployees: getCompanyActiveEmployeesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  addAttachments: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  setActiveTemplate: () => dispatch(setActiveTrainingResponse()),
  addTraining: payload => dispatch(addTrainingRequest(payload)),
  updateTraining: payload => dispatch(updateTrainingRequest(payload)),
  deleteTraining: payload => dispatch(deleteTrainingRequest(payload)),
  fetchCustomTrainingTemplates: payload =>
    dispatch(fetchCustomTrainingTemplatesRequest(payload)),
  deleteAttachment: payload => dispatch(deleteAttachmentRequest(payload)),
  clearUploadedAttachments: () => dispatch(clearUploadedAttachments()),
  fetchEmployees: payload => dispatch(fetchAllActiveEmployees(payload)),
  fetchUsers: payload =>
    dispatch(
      fetchAllActiveEmployeesActiveUsers({ ...payload, wantSummary: true })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingContainer);
