import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import './index.css';

export default class CSVUploader extends Component {
  state = {
    percent: 0,
    imageFiles: []
  };

  onDrop = files => {
    if (files[0].name.indexOf('.csv') === -1) {
      alert('File must be a .csv file.');

      return;
    }

    let data = new FormData();
    files.forEach(file => {
      data.append('attachments', file, file.name);
    });

    this.props.addAttachment({
      data,
      ownerId: this.props.activeCompanyId,
      ownerType: this.props.documentType
    });

    this.setState({
      percent: 0,
      imageFiles: [...this.state.imageFiles, ...files]
    });
  };

  render() {
    return (
      <div>
        <label className="label">{this.props.label}</label>
        <div className={this.props.className}>
          <Dropzone onDrop={this.onDrop} className="dropzone-box">
            <div>Click or drag a file here to upload!</div>
            <div>Only a *.csv file will be accepted.</div>
          </Dropzone>
          {this.state.imageFiles.length > 0 ? (
            <div>
              <h2>Uploaded {this.state.imageFiles.length} files</h2>
              <div>
                {this.state.imageFiles.map((file, index) => (
                  <img
                    src={require(`../../assets/images/csvImage.png`)}
                    key={index}
                    alt="images"
                    style={{ width: '30px', height: '30px' }}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
