import React, { Component } from 'react';
import history from '../../history';

import { USER_ROLES } from '../../constants/constants';

import Button from '../inputs/Button';
import Modal from '../Modal';
import { InputRow } from '../inputs';
import ChangePasswordForm from '../../forms/ChangePasswordForm';
import ChangeDefaultPasswordForm from '../../forms/ChangeDefaultPasswordForm';

import '../ObjectList/index.css';
import './index.css';

class UserProfile extends Component {
  state = {
    openModal: false,
    openDefaultPasswordModal: false
  };

  handleOpenDefaultPasswordModal() {
    this.setState({ openDefaultPasswordModal: true });
  }

  handleCloseDefaultPasswordModal() {
    this.setState({ openDefaultPasswordModal: false });
  }

  handleOpenModal() {
    this.setState({ openModal: true });
  }

  handleCloseModal() {
    this.setState({ openModal: false });
  }

  handleChangePassword = async values => {
    this.props.updatePassword(values);
    this.handleCloseModal();
  };

  handleChangeDefaultPassword = async values => {
    this.props.updateDefaultPassword(values);
    this.handleCloseDefaultPasswordModal();
  };

  render() {
    const { activeUser, userCompanies } = this.props;

    return (
      <div className="companyDetails">
        <h2 className="settings-header">Profile Settings</h2>
        <InputRow right>
          {activeUser.isAccountOwner && (
            <Button
              color="blue"
              text="Change Users Default Password"
              onClick={() => this.handleOpenDefaultPasswordModal()}
            />
          )}
          <Button
            color="blue"
            text="Change Password"
            onClick={() => this.handleOpenModal()}
          />
          <Button
            color="white"
            text="Edit"
            onClick={() => history.push('/app/ViewEditProfile/Profile')}
          />
        </InputRow>
        <div className="accountSettings">
          <div className="leftColumn">
            <img
              src={
                activeUser.profilePictureUrl ||
                require('../../assets/images/blank-profile-picture.jpg')
              }
              alt={`${activeUser.firstName}'s`}
            />
          </div>

          <div className="rightColumn-company profile">
            <div className="smallHeader">USER</div>
            <h1 className="companyName">{`${activeUser.firstName} ${activeUser.lastName}`}</h1>

            <div className="smallHeader-stats">USERNAME</div>
            <h4 className="companyName">
              {activeUser.username || 'Email not available'}
            </h4>

            <div className="smallHeader-stats">NOTIFICATIONS</div>
            <h4 className="companyName">
              {activeUser.shouldStopNotifications ? 'Disabled' : 'Enabled'}
            </h4>
          </div>

          <div className="rightColumn-companies">
            <div className="smallHeader-stats">COMPANIES</div>
            <ul className="userProfileList-list companyList">
              {userCompanies.map((company, index) => (
                <li key={index}>
                  <div>{company.name}</div>
                  <div className="accessLevel">
                    {
                      USER_ROLES[
                        USER_ROLES.findIndex(
                          level => level.value === activeUser.accessLevel
                        )
                      ].label
                    }
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {this.state.openModal && (
          <Modal
            title="Change Password"
            titleClassName="blueHeader"
            isOpen={this.state.openModal}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            formName="ChangePasswordForm"
          >
            <ChangePasswordForm
              onSubmit={values => this.handleChangePassword(values)}
            />
          </Modal>
        )}
        {this.state.openDefaultPasswordModal && (
          <Modal
            title="Change Users Default Password"
            titleClassName="blueHeader"
            isOpen={this.state.openDefaultPasswordModal}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseDefaultPasswordModal()}
            formName="ChangeDefaultPasswordForm"
          >
            <ChangeDefaultPasswordForm
              onSubmit={values => this.handleChangeDefaultPassword(values)}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default UserProfile;
