import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import ReactTooltip from 'react-tooltip';

import {
  email,
  normalizePhone,
  renderDatePicker,
  renderDropdownField,
  renderField,
  renderMultiSelectField,
  renderTextareaField,
  required
} from '../../utils/formValidation';
import { PRIORITY_DROPDOWN } from '../../constants/constants';
import Button from '../../components/inputs/Button';
import FileDownloader from '../../components/FileDownloader';
import FileUploader from '../../components/FileUploader';
import { InputRow } from '../../components/inputs';

import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';

let SafetyInstructionForm = props => {
  const {
    handleSubmit,
    onCancel,
    onDelete,
    disabled,
    activeCompanyId,
    addAttachment,
    activeAttachments,
    deleteSIAttachment,
    loading,
    isUpdate,
    reset,
    accessLevel,
    activeLocation,
    locationList,
    deleteAttachment,
    addedAttachments,
    groupId,
    activeProject,
    documentFolders
  } = props;

  const hasAccess = accessLevel !== '100';

  const locationDropdown = locationList.map(location => {
    return {
      value: location._id,
      label: location.name
    };
  });

  const getProjectDropdown = () => {
    let group = '';
    if (!groupId && !activeLocation) {
      return [];
    } else if (activeLocation) {
      group = [activeLocation._id];
    } else {
      group = groupId;
    }

    const selectedGroup = locationList.filter(o =>
      group.find(o2 => {
        if (o2.value) {
          return o._id === o2.value;
        } else {
          return o._id === o2;
        }
      })
    );

    let projectsDropdown = [];

    if (selectedGroup.length > 0) {
      selectedGroup.forEach(group => {
        group.projects.forEach(project => {
          projectsDropdown.push({
            value: project._id,
            label: `${group.name} - ${project.name}`
          });
        });
      });
    }

    return projectsDropdown;
  };

  const folderDropdown = documentFolders.map(folder => {
    return {
      value: folder._id,
      label: folder.name
    };
  });

  return (
    <form className="safetyInstructionForm" onSubmit={handleSubmit}>
      {disabled || !hasAccess ? (
        ''
      ) : isUpdate ? (
        <InputRow right>
          <Button text="Update" className="update" type="submit" />
          <Button text="Delete" color="red" onClick={onDelete} />
          <Button
            text="Cancel"
            type="button"
            onClick={() => {
              reset('safetyInstructionForm');
              onCancel();
            }}
          />
        </InputRow>
      ) : (
        ''
      )}
      <div className={'form-fields'}>
        {!activeLocation ? (
          <div>
            <Field
              name="locationId"
              component={renderMultiSelectField}
              label="Group or Establishment:"
              placeholder={''}
              options={locationDropdown}
              disabled={disabled}
              selectButtons
              searchable
            />
            <ReactTooltip className="customTheme" id="locationId">
              <p>
                You can share with one or more Groups / Establishments and that
                will make it available to them as well
              </p>
            </ReactTooltip>
          </div>
        ) : (
          ''
        )}
        {!activeProject && accessLevel !== '100' ? (
          <div>
            <Field
              name="projectId"
              component={renderMultiSelectField}
              label="Project:"
              placeholder={''}
              options={getProjectDropdown()}
              disabled={disabled}
              selectButtons
              searchable
            />
            <ReactTooltip className="customTheme" id="projectId">
              <p>
                You can share this document with one or more Projects and that
                will make it available to them as well
              </p>
            </ReactTooltip>
          </div>
        ) : (
          ''
        )}
        <Field
          name="label"
          type="text"
          component={renderField}
          label="Title:"
          placeholder={''}
          requiredLabel={true}
          validate={[required]}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="documentData.description"
          type="textarea"
          component={renderTextareaField}
          label="Description:"
          placeholder={''}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="documentFolderId"
          component={renderDropdownField}
          options={folderDropdown}
          label="Add to Folder:"
          placeholder={''}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="documentData.currentPriority"
          component={renderDropdownField}
          label="Priority Level:"
          placeholder={''}
          options={PRIORITY_DROPDOWN}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="expires"
          component={renderDatePicker}
          label="Expiration Date:"
          placeholder={''}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="documentData.nextSteps"
          type="text"
          component={renderField}
          label="Next Steps:"
          placeholder={''}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="documentData.notes"
          type="text"
          component={renderField}
          label="Notes:"
          placeholder={''}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="documentData.questionsPhone"
          type="text"
          component={renderField}
          label="Point of Contact Phone:"
          placeholder={''}
          normalize={normalizePhone}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="documentData.questionsEmail"
          type="text"
          component={renderField}
          label="Point of Contact Email:"
          placeholder={''}
          warn={email}
          disabled={disabled || !hasAccess}
        />
        <Field
          name="documentData.comments"
          type="text"
          component={renderField}
          label="Comments:"
          placeholder={''}
          disabled={disabled || !hasAccess}
        />
        <div>
          <h4>Attachments</h4>
          {addedAttachments &&
            addedAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={deleteAttachment}
                disabled={!hasAccess || disabled}
              />
            ))}
          {activeAttachments &&
            activeAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={deleteSIAttachment}
                disabled={!hasAccess || disabled}
              />
            ))}
        </div>
        {hasAccess && (
          <Field
            name="attachments"
            type="text"
            component={FileUploader}
            label="Certificate(s):"
            placeholder={''}
            activeCompanyId={activeCompanyId}
            addAttachment={addAttachment}
            disabled={disabled}
            loading={loading}
            ownerId="1234"
            ownerType="documents"
          />
        )}
      </div>
    </form>
  );
};

SafetyInstructionForm = reduxForm({
  form: 'SafetyInstructionForm'
})(SafetyInstructionForm);

const selector = formValueSelector('SafetyInstructionForm');

const mapStateToProps = state => ({
  groupId: selector(state, 'locationId')
});

export default connect(mapStateToProps)(SafetyInstructionForm);
