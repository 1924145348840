import React, { Component } from 'react';

import { classnames } from '../../../utils/classNamesHelper';
import HoverImage from '../../HoverImage';
import Card from '../../Card';
import { InputRow } from '../../inputs';
import Button from '../../inputs/Button';
import Textbox from '../../inputs/Textbox';

import styles from './customRaCard.module.scss';

export default class CustomRaCard extends Component {
  state = {
    dragIdx: null,
    dragTargetIdx: null
  };

  onDragStart = (e, index) => {
    this.setState({
      dragIdx: index,
      dragTargetIdx: null
    });
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/plain', index);
  };

  onDragOver = (e, index) => {
    if (e.preventDefault) e.preventDefault();
    this.setState({ dragTargetIdx: index });
  };

  onDragLeave = (e, index) => {
    this.setState({ dragTargetIdx: null });
  };

  onDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (
      this.state.dragIdx === this.state.dragTargetIdx ||
      this.state.dragTargetIdx === null
    ) {
      this.setState({
        dragIdx: null,
        dragTargetIdx: null
      });
      return;
    }

    let questions = [...this.props.questions];
    const tmp = questions[this.state.dragIdx];
    questions[this.state.dragIdx] = questions[this.state.dragTargetIdx];
    questions[this.state.dragTargetIdx] = tmp;

    this.props.onChange({ questions });
    this.setState({
      dragIdx: null,
      dragTargetIdx: null
    });
  };

  handleQuestionChange = (index, value) => {
    let questions = [...this.props.questions];

    questions[index] = value;

    this.props.onChange({ questions });
  };

  addRowsToTemplate = numberOfRows => {
    let questions = [...this.props.questions];

    for (let i = 1; i <= numberOfRows; i++) {
      questions.push('');
    }

    this.props.onChange({ questions });
  };

  removeRowFromTemplate = index => {
    let questions = [...this.props.questions];
    questions.splice(index, 1);

    this.props.onChange({ questions });
  };

  render() {
    const { title, description, onChange, disabled, questions } = this.props;

    return (
      <Card title="Risk Assessment Details" showHeader>
        <Textbox
          currentValue={title}
          fieldLabel="Title:"
          handleChange={e => onChange({ title: e.target.value })}
          placeholder="Use a unique and descriptive name."
          disabled={disabled}
        />
        <Textbox
          currentValue={description}
          fieldLabel="Description:"
          handleChange={e => onChange({ description: e.target.value })}
          type="textarea"
          placeholder="Briefly describe what this template is used for."
          disabled={disabled}
        />
        <ul className={styles.questions}>
          {questions.map((row, index) => (
            <li
              key={index}
              onDragOver={event => this.onDragOver(event, index)}
              onDragLeave={event => this.onDragLeave(event, index)}
              onDrop={event => this.onDrop(event, index)}
            >
              <div
                className={classnames(
                  styles.item,
                  disabled && styles.disabled,
                  this.state.dragTargetIdx === index &&
                    this.state.dragIdx !== index &&
                    styles.target
                )}
                draggable={!disabled}
                onDragStart={event => this.onDragStart(event, index)}
              >
                <img
                  src={require('../../../assets/images/drag.png')}
                  alt="drag to new position"
                  className={styles.dragImage}
                />
                <Textbox
                  currentValue={row}
                  handleChange={e =>
                    this.handleQuestionChange(index, e.target.value)
                  }
                  type="textarea"
                  placeholder="Type your question here. (Risk Assessments are answered with Safe, Unsafe, or N/A)"
                  disabled={disabled}
                  className={styles.question}
                />
                <HoverImage
                  src={require('../../../assets/images/removePermissions.png')}
                  srcHover={require('../../../assets/images/removePermissonsHover.png')}
                  alt="remove row"
                  className={styles.removeImage}
                  onClick={() => this.removeRowFromTemplate(index)}
                />
              </div>
            </li>
          ))}
        </ul>
        <InputRow className={styles.footer}>
          <Button
            text="Add Row"
            color="blue"
            onClick={() => this.addRowsToTemplate(1)}
            disabled={disabled}
          />
          <Button
            text="Add Five Rows"
            color="blue"
            onClick={() => this.addRowsToTemplate(5)}
            disabled={disabled}
          />
        </InputRow>
      </Card>
    );
  }
}
