import React from 'react';
import * as api from '../../../api';
import { positiveIntsOnly } from '../../../utils/formValidation';
import Checkbox from '../../inputs/Checkbox';
import DateUnitPicker from '../../inputs/DateUnitPicker';
import Dropdown from '../../inputs/Dropdown';
import Textbox from '../../inputs/Textbox';

import styles from './styles.module.scss';

const checkTitleAvailability = e => {
  api.doesTrainingHaveTitle(e.target.value).then(response => {
    const titleField = window.document.getElementById('trainingTitle');

    if (response && response.titleExists) {
      titleField.style.background = 'lightcoral';
    } else {
      titleField.style.background = 'lightgreen';
    }
  });
};

export default function TrainingTemplate({
  title,
  instructions,
  needsApproval,
  doesExpire,
  expiresAfterNumber,
  expiresAfterOption,
  isEditing,
  isCreating,
  updateAnswer,
  disabled,
  employeeDropdown,
  approver,
  trainingType,
  quizMasterId,
  quizDropdown
}) {
  return (
    <>
      <Textbox
        fieldLabel="Training Title (if textbox turns red, title is not available)"
        placeholder="Name of the template, e.g. Forklift Training"
        isRequired={true}
        currentValue={title}
        handleChange={e => {
          checkTitleAvailability(e);
          updateAnswer({
            title: e.target.value,
            hasUnsavedChanges: true
          });
        }}
        disabled={!isCreating || disabled}
        name="trainingTitle"
      />
      <Textbox
        fieldLabel="Instructions"
        type="textarea"
        placeholder="Complete steps 1 and 2"
        currentValue={instructions}
        handleChange={e =>
          updateAnswer({
            instructions: e.target.value,
            hasUnsavedChanges: true
          })
        }
        disabled={!isCreating && !isEditing}
      />
      {trainingType?.label !== 'Historical Training' && (
        <Dropdown
          options={quizDropdown}
          fieldLabel="Quiz for this training"
          currentValue={quizMasterId}
          handleChange={values => updateAnswer({ quizMasterId: values.value })}
          disabled={(!isCreating && !isEditing) || disabled}
          searchable
          name="quizDropdown"
        />
      )}
      {trainingType?.label !== 'Historical Training' && (
        <Checkbox
          fieldLabel="Approval Flow"
          name="needsApproval"
          onChange={value =>
            updateAnswer({
              needsApproval: value,
              hasUnsavedChanges: true
            })
          }
          currentValue={needsApproval}
          displayTooltip
          disabled={(!isCreating && !isEditing) || disabled}
        >
          <div>
            <p>
              When this is selected, all the trainings of this type will need to
              be approved or rejected one by one.
            </p>
            <p>
              The approver wil be able to view the amount of time each employee
              spent on their training. This will not include any tests or
              quizzes.
            </p>
          </div>
        </Checkbox>
      )}
      <Checkbox
        fieldLabel="Training Expires"
        name="expires"
        onChange={value =>
          updateAnswer({ doesExpire: value, hasUnsavedChanges: true })
        }
        currentValue={doesExpire}
        displayTooltip
        disabled={(!isCreating && !isEditing) || disabled}
      >
        <div>
          <p>
            This is how long the training will last after the employee preforms
            it.
          </p>
          <p>This will start counting after the training due date</p>
        </div>
      </Checkbox>
      {needsApproval && trainingType && (
        <Dropdown
          options={employeeDropdown}
          fieldLabel="Approver"
          currentValue={approver}
          isRequired={true}
          handleChange={values => updateAnswer({ approver: values })}
          disabled={!isCreating && !isEditing}
          searchable
          name="dropdownEmployees"
        />
      )}
      {doesExpire && trainingType?.label !== 'Historical Training' && (
        <div className={styles.sameLine}>
          <Textbox
            fieldLabel="Expires After"
            isRequired={true}
            type="number"
            handleChange={e => {
              const onlyNums = positiveIntsOnly(e.target.value);
              updateAnswer({
                expiresAfterNumber: onlyNums,
                hasUnsavedChanges: true
              });
            }}
            currentValue={expiresAfterNumber}
            disabled={(!isCreating && !isEditing) || disabled}
          />
          <div className={styles.topMargin}>
            <DateUnitPicker
              currentValue={expiresAfterOption}
              handleChange={values =>
                updateAnswer({
                  expiresAfterOption: values,
                  hasUnsavedChanges: true
                })
              }
              disabled={(!isCreating && !isEditing) || disabled}
            />
          </div>
        </div>
      )}
    </>
  );
}
