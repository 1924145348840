import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import history from '../../../history';
import { updateLeftNavRequest } from '../../../actions/navigation';
import {
  fetchDocumentFoldersRequest,
  updateDocumentFolderRequest,
  deleteDocumentFolderRequest,
  addDocumentsToFolderRequest
} from '../../../actions/documents';
import { fetchAllActiveEmployees } from '../../../actions/personnel';
import {
  fetchTrainingsRequest,
  setActiveTrainingRequest,
  setActiveTrainingResponse
} from '../../../actions/training';
import {
  MONTHLY_FREQUENCY_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS
} from '../../../constants/constants';
import { getActiveCompany } from '../../../selectors/company';
import {
  getActiveFolderSelector,
  getDocumentFoldersSelector
} from '../../../selectors/documents';
import {
  getCompanyUsersSelector,
  getCompanyActiveEmployeesSelector
} from '../../../selectors/personnel';
import { getTrainingListSelector } from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import {
  sortDateMethod,
  filterPerformedDate,
  filterRepeatingOn
} from '../../../utils/sortFilterHelpers';
import AddToFolderModal from '../../../components/Modal/addToFolderModal';
import Button from '../../../components/inputs/Button';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import DocumentFolder from '../../../components/DocumentFolder';
import SafetyTrainingList from '../../../components/training/SafetyTrainingList';
import TypeSelection from '../../../components/TypeSelection';

import styles from './styles.module.scss';

class TrainingList extends Component {
  state = {
    filters: [],
    repeatingFilters: [],
    scanFilters: [],
    trainingType: 'Active',
    addToFolderModal: false,
    documentFolderId: '',
    addToFolder: []
  };

  componentDidMount() {
    if (localStorage.getItem('trainingScanFilters')) {
      this.setState({
        scanFilters: JSON.parse(localStorage.getItem('trainingScanFilters'))
      });
    }

    if (localStorage.getItem('trainingRepeatingFilters')) {
      this.setState({
        repeatingFilters: JSON.parse(
          localStorage.getItem('trainingRepeatingFilters')
        )
      });
    }

    this.props.updateLeftNav('Safety Trainings');
    this.props.fetchTrainings();
    this.props.fetchDocumentFolders({
      documentType: 'SafetyTraining',
      companyId: this.props.activeCompany._id
    });

    this.props.fetchEmployees();
  }

  getTraining = schedule => {
    if (schedule === 'Active') return this.getImmediateTable();
    else if (schedule === 'Repeating') return this.getRepeatingTable();
    return this.getScanTable();
  };

  handleAddToFolder = id => {
    this.setState(state => {
      let { addToFolder } = state;

      const qrIndex = addToFolder.findIndex(c => c === id);

      if (qrIndex > -1) {
        addToFolder = addToFolder.filter(c => c !== id);
      } else {
        addToFolder = [...addToFolder, id];
      }
      return { addToFolder };
    });
  };

  handleAddMultipleToFolders = () => {
    const { trainings } = this.props;
    let addDocsToFolder = [];

    trainings.forEach(document => {
      if (this.state.addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentType: 'SafetyTraining',
      documentFolderId: this.state.documentFolderId
    });

    this.setState({
      addToFolderModal: false,
      addToFolder: [],
      documentFolderId: ''
    });
  };

  getImmediateTable() {
    const {
      users,
      setActiveTemplate,
      setActiveTraining,
      activeEmployees
    } = this.props;
    const { addToFolder } = this.state;

    const trainings = this.props.trainings.filter(
      training => training.schedule.label === 'Immediately'
    );
    return (
      <SafetyTrainingList
        trainings={trainings}
        users={users}
        addToFolder={addToFolder}
        setActiveTemplate={setActiveTemplate}
        setActiveTraining={setActiveTraining}
        isFromList={true}
        handleAddToFolder={value => this.handleAddToFolder(value)}
        openFolder={() => this.setState({ addToFolderModal: true })}
        activeEmployees={activeEmployees}
      />
    );
  }

  getRepeatingTable() {
    const { repeatingFilters } = this.state;

    const trainings = this.props.trainings.filter(
      training => training.schedule.label === 'Repeating'
    );
    return (
      <ReactTable
        data={trainings}
        onFilteredChange={filtered =>
          this.handleFilterChange(filtered, 'trainingRepeatingFilters')
        }
        filtered={repeatingFilters}
        filterable
        resizable={false}
        noDataText="No results found"
        defaultPageSize={10}
        className="-striped -highlight"
        columns={[
          {
            Header: 'TRAINING TITLE',
            accessor: d => d.title,
            id: 'title'
          },
          {
            Header: 'LAST UPDATED AT',
            accessor: d => d.updatedAt,
            id: 'updatedAt',
            Cell: ({ original }) =>
              original.updatedAt
                ? `${moment(Date.parse(original.updatedAt)).format(
                    'MM/DD/YYYY hh:mm a'
                  )}`
                : '',
            sortMethod: (a, b) => sortDateMethod(a, b),
            filterMethod: (filter, row) => filterPerformedDate(filter, row),
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'All'}
              >
                <option value="all">Show All</option>
                <option value="today">Updated Today</option>
                <option value="last7">Updated within Last Week</option>
                <option value="last30">Updated This Month</option>
                <option value="thisQuarter">Updated This Quarter</option>
                <option value="thisYear">Updated This Year</option>
              </select>
            )
          },
          {
            Header: 'REPEAT ON',
            id: 'repeatOn',
            Cell: ({ original }) => (
              <div>
                {original.frequency === 'monthly' ? (
                  <div>
                    {
                      MONTHLY_FREQUENCY_OPTIONS.filter(
                        month => month.value === original.repeatingOn[0]
                      )[0].label
                    }
                  </div>
                ) : original.frequency === 'yearly' ? (
                  <div>
                    {
                      YEARLY_FREQUENCY_OPTIONS.filter(
                        month => month.value === original.repeatingOn[0]
                      )[0].label
                    }
                  </div>
                ) : (
                  original.repeatingOn
                    .map(repeat => repeat.toUpperCase())
                    .join(', ')
                )}
              </div>
            ),
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">Show All</option>
                <option value="weekly">Repeating Weekly</option>
                <option value="monthly">Repeating Monthly</option>
                <option value="yearly">Repeating Yearly</option>
              </select>
            ),
            filterMethod: (filter, row) => filterRepeatingOn(filter, row),
            sortable: false
          },
          {
            Header: 'VIEW/EDIT',
            Cell: ({ original }) => (
              <Button
                color="blue"
                onClick={() => this.handleViewEdit(original)}
                text="View/Edit"
              />
            ),
            filterable: false,
            sortable: false
          }
        ]}
      />
    );
  }

  getScanTable() {
    const { scanFilters } = this.state;

    const trainings = this.props.trainings.filter(
      training => training.schedule.label === 'Scan on Demand'
    );

    return (
      <ReactTable
        data={trainings}
        onFilteredChange={filtered =>
          this.handleFilterChange(filtered, 'trainingScanFilters')
        }
        filtered={scanFilters}
        filterable
        resizable={false}
        noDataText="No results found"
        defaultPageSize={10}
        className="-striped -highlight"
        columns={[
          {
            Header: 'TRAINING TITLE',
            accessor: d => d.title,
            id: 'title'
          },
          {
            Header: 'LAST UPDATED AT',
            accessor: d => d.updatedAt,
            id: 'updatedAt',
            Cell: ({ original }) =>
              original.updatedAt
                ? `${moment(Date.parse(original.updatedAt)).format(
                    'MM/DD/YYYY hh:mm a'
                  )}`
                : '',
            sortMethod: (a, b) => sortDateMethod(a, b),
            filterMethod: (filter, row) => filterPerformedDate(filter, row),
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'All'}
              >
                <option value="all">Show All</option>
                <option value="today">Updated Today</option>
                <option value="last7">Updated within Last Week</option>
                <option value="last30">Updated This Month</option>
                <option value="thisQuarter">Updated This Quarter</option>
                <option value="thisYear">Updated This Year</option>
              </select>
            )
          },
          {
            Header: 'QR Code',
            id: 'qrCode',
            Cell: ({ original }) => (
              <Button
                onClick={() =>
                  window.open(`https://${original.qrCodeUrl}`, '_blank')
                }
                text="Download QR Code"
                color="green"
              />
            ),
            filterable: false,
            sortable: false
          },
          {
            Header: 'VIEW/EDIT',
            Cell: ({ original }) => (
              <Button
                color="blue"
                onClick={() => this.handleViewEdit(original)}
                text="View/Edit"
              />
            ),
            filterable: false,
            sortable: false
          }
        ]}
      />
    );
  }

  handleFilterChange = (filters, filterType) => {
    localStorage.setItem(`${filterType}`, JSON.stringify(filters));
    if (filterType === 'trainingRepeatingFilters') {
      this.setState({ repeatingFilters: filters });
    } else {
      this.setState({ scanFilters: filters });
    }
  };

  handleViewEdit = template => {
    this.props.setActiveTraining(template);

    history.push('/app/training/trainingContainer');
  };

  render() {
    const { trainingType, addToFolderModal, documentFolderId } = this.state;

    const {
      activeFolder,
      loggedInUser,
      updateDocumentFolder,
      deleteDocumentFolder,
      trainings,
      folderList
    } = this.props;

    const accessLevel = loggedInUser.accessLevel;

    const header = (
      <Header
        section="Safety Trainings"
        rightButtons={{
          text: 'Create Training',
          onClick: () => {
            this.props.setActiveTraining({});
            history.push('/app/training/trainingContainer');
          },
          className: 'blueButton',
          visible: true
        }}
      />
    );

    return (
      <HeaderAndFooter Header={header}>
        <TypeSelection
          selected={trainingType}
          selectedArray={['Active', 'Repeating', 'On Demand']}
          onClick={value => this.setState({ trainingType: value })}
        />
        {activeFolder.name && (
          <DocumentFolder
            activeFolder={activeFolder}
            accessLevel={accessLevel}
            updateDocumentFolder={updateDocumentFolder}
            deleteDocumentFolder={deleteDocumentFolder}
            username={loggedInUser.username}
            content={trainings}
            docType="Safety Training"
          />
        )}
        <div className={styles.table}>{this.getTraining(trainingType)}</div>
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={() => this.handleAddMultipleToFolders()}
          folderOptions={folderList.map(folder => {
            return {
              value: folder._id,
              label: folder.name
            };
          })}
          handleChange={values =>
            this.setState({ documentFolderId: values.value })
          }
        />
      </HeaderAndFooter>
    );
  }
}

const mapStateToProps = state => ({
  trainings: getTrainingListSelector(state),
  loggedInUser: getLoggedInUser(state),
  users: getCompanyUsersSelector(state),
  activeCompany: getActiveCompany(state),
  activeFolder: getActiveFolderSelector(state),
  folderList: getDocumentFoldersSelector(state),
  activeEmployees: getCompanyActiveEmployeesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: payload => dispatch(updateLeftNavRequest(payload)),
  fetchTrainings: () => dispatch(fetchTrainingsRequest()),
  setActiveTemplate: payload => dispatch(setActiveTrainingRequest(payload)),
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  updateDocumentFolder: values => dispatch(updateDocumentFolderRequest(values)),
  deleteDocumentFolder: values => dispatch(deleteDocumentFolderRequest(values)),
  addDocumentToFolder: values => dispatch(addDocumentsToFolderRequest(values)),
  fetchEmployees: () => dispatch(fetchAllActiveEmployees())
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingList);
