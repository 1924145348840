import React from 'react';
import moment from 'moment';

import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';
import Card from '../../Card';

import './index.css';

const getCorrectiveActionHeaders = hazard => {
  const hasTasks = hazard?.tasks?.length > 0;
  const immediatelyFixed = hazard?.immediatelyFixed?.length > 0;
  if (!hasTasks) {
    if (!immediatelyFixed) {
      return {
        top: 'NO CORRECTIVE ACTION',
        bottom: 'Assign Corrective Action',
        profilePicture: '',
        box: 'hazardSummaryCard-correctiveActionBox-noCA'
      };
    } else {
      return {
        top: 'Immediately Fixed',
        bottom: 'Assign Corrective Action (if needed)',
        profilePicture: '',
        box: 'hazardSummaryCard-correctiveActionBox-immediatelyFixed',
        topWords: 'hazardSummaryCard-correctiveAction-immediatelyFixed'
      };
    }
  } else {
    const task = hazard.tasks[0];

    const isPastDue = moment().isAfter(Date.parse(task.dueDate));

    if (task.currentWfStatus === 5) {
      return {
        top: 'Corrective Action Completed',
        userId: task.assignedTo[0],
        box: 'hazardSummaryCard-correctiveActionBox-completed',
        topWords: 'hazardSummaryCard-correctiveAction-immediatelyFixed'
      };
    } else if (isPastDue) {
      return {
        top: 'Corrective Action Past Due',
        userId: task.assignedTo[0],
        box: 'hazardSummaryCard-correctiveActionBox-pastDue',
        topWords: 'hazardSummaryCard-correctiveAction-pastDue'
      };
    } else {
      return {
        top: 'Corrective Action Assigned',
        userId: task.assignedTo[0],
        box: 'hazardSummaryCard-correctiveActionBox-assigned',
        topWords: 'hazardSummaryCard-correctiveAction-assigned'
      };
    }
  }
};

const HazardSummaryCard = ({
  hazard,
  history,
  setActiveTask,
  groupId,
  projectId,
  index,
  name
}) => {
  const hazardInfo = getCorrectiveActionHeaders(hazard);
  return (
    <Card title={hazard.title} showHeader>
    <div className="hazardSummaryCard-main">
      {hazard.pictures && hazard.pictures.length > 0 && (
        <div className="hazardSummaryCard-main--pictures">
          <img src={hazard.pictures[0].source_url} alt="add hazard" />
          <div className="hazardSummaryCard-main--pictureInfo">
            <div style={{ paddingTop: 5 }}>
              {hazard.pictures.length} Picture(s)
            </div>
          </div>
        </div>
      )}
      <div className="hazardSummaryCard-main--hazardInfo">
        <Button
          className="blueButton hazardSummaryCard-button"
          text="View Hazard Info"
          onClick={() =>
            history.push(`/app/safetyWalkContainer/hazardCard/${index}`)
          }
        />
        <div className="hazardSummaryCard-main--hazardDesciption">
          {hazard.description}
        </div>
        <div
          className={`hazardSummaryCard-main--correctiveAction ${
            hazardInfo.box
          }`}
          onClick={() =>
            hazard.tasks && hazard.tasks.length > 0
              ? setActiveTask({
                  ...hazard.tasks[0],
                  hazardNumber: index,
                  isFromSW: true
                })
              : setActiveTask({
                  ownerType: 'safetyWalks',
                  isFromSW: true,
                  groupId,
                  projectId,
                  label: `${hazard.title} - Corrective Action`,
                  description: hazard.description,
                  notes: `Solution - \n ${hazard.solution} \n Exact Location - \n ${hazard.exactLocation}`,
                  currentReason: 2,
                  currentCategory: 1,
                  schedule: 'immediate',
                  hazardNumber: index
                })
          }
        >
          {hazardInfo.userId && (
            <UserBadge
              userId={hazardInfo.userId}
              showImage
              size="medium"
            />
          )}
          <div>
            <div
              className={`hazardSummaryCard-correctiveAction--typeOfCA ${
                hazardInfo.topWords
              }`}
            >
              {hazardInfo.top}
            </div>
            <div className="hazardSummaryCard-correctiveAction--owner">
              {hazardInfo.userId && (
                <UserBadge
                  userId={hazardInfo.userId}
                  showName
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </Card>
  );
};

export default HazardSummaryCard;
