import React, { Component } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { stateAbbr } from '../../constants/states';
import AutoComplete from '../inputs/AutoComplete';
import CheckboxGroup from '../inputs/CheckboxGroup';
import { DatePicker, TimePicker } from '../inputs/DateTimePicker';
import Dropdown from '../inputs/Dropdown';
import RadioButtons from '../inputs/RadioButtons';
import Textbox from '../inputs/Textbox';
import { InputRow } from '../inputs';
import { parseTime, renderTime } from '../../utils/dateHelpers';

import './index.css';

export default class OshaInjuredWorker extends Component {
  state = { employeeName: '' };

  componentDidMount = () => {
    if (this.props.oshaForm) {
      this.setState({
        employeeName: this.props.oshaForm.employeeName
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.oshaForm?.employeeName !== prevProps.oshaForm?.employeeName) {
      this.setState({ employeeName: this.props.oshaForm?.employeeName });
    }
  }

  handleEmployeeChange = (event, { newValue: employee }) => {
    if (typeof employee === 'string') {
      this.setState({ employeeName: employee });
      return;
    }

    let employeeInfo = {};

    if (
      employee.person &&
      (employee.person.addresses || []).length > 0 &&
      employee.person.addresses[0].streetAddress
    ) {
      employeeInfo = {
        workerStreetAddress: employee.person.addresses[0].streetAddress,
        workerCity: employee.person.addresses[0].city,
        workerState: employee.person.addresses[0].stateName,
        workerZip: employee.person.addresses[0].zip,
        workerDateOfBirth: employee.person.dateOfBirth,
        workerGender:
          employee.person.gender === 1
            ? 'Male'
            : employee.person.gender === 2
            ? 'Female'
            : ''
      };
    } else if (employee.person) {
      employeeInfo.workerDateOfBirth = employee.person.dateOfBirth;
      employeeInfo.workerGender =
        employee.person.gender === 1
          ? 'Male'
          : employee.person.gender === 2
          ? 'Female'
          : '';
    }

    employeeInfo.workerDateOfHire = employee.dateOfHire;
    employeeInfo.employeeName = `${employee.person.firstName} ${employee.person.lastName}`;
    employeeInfo.jobTitle = employee.jobTitle;
    employeeInfo.employeeId = employee._id;

    this.props.handleInjuredWorkerChange(employeeInfo);
  };

  render() {
    const { personnel, isPerrp, disabled } = this.props;

    const {
      happenToThisWorker,
      facilityTreatedWorksite,
      ongoing,
      injuryIllness,
      jobTitle,
      workerDoing,
      howInjuryOccurred,
      incidentInjuryIllness,
      bodyParts,
      incidentFrom,
      physicianName,
      facilityName,
      facilityAddress,
      facilityCity,
      facilityState,
      facilityZip,
      treatedInEmergency,
      hospitalizedInpatient,
      daysAwayFromWork,
      daysJobRestriction,
      timeOfDayWorkStarted,
      dateOfDeath,
      privacyConcern,
      employeeFirstAid
    } = this.props.oshaForm;

    const { employeeName } = this.state;
    const isTreatedWorksite = facilityTreatedWorksite?.length === 1;

    const options = [
      { value: 0, label: 'Death' },
      {
        value: 1,
        label: 'Days away from work, restricted work, or on job transfer'
      },
      { value: 2, label: 'Loss of Consciousness' },
      { value: 3, label: 'Medical treatment beyond first aid' },
      {
        value: 4,
        label: `Other ${
          isPerrp ? 'PERRP' : 'OSHA'
        } recordable injuries or illnesses`
      }
    ];

    const injuryIllnessOptions = [
      { value: 0, label: 'Injury' },
      { value: 1, label: 'Skin Disorder' },
      { value: 2, label: 'Respiratory Condition' },
      { value: 3, label: 'Poisoning' },
      { value: 4, label: 'Hearing Loss' },
      { value: 5, label: 'Other Illness' }
    ];

    const yesNoOptions = [
      { value: 1, label: 'Yes' },
      { value: 2, label: 'No' }
    ];

    const getSuggestions = value => {
      const inputValue = value.toLowerCase();
      const inputLength = inputValue.length;

      return inputLength === 0
        ? []
        : personnel.filter(
            lang =>
              lang.person.firstName.toLowerCase().slice(0, inputLength) ===
              inputValue
          );
    };

    const getSuggestionValue = suggestion => suggestion;

    const renderSuggestion = suggestion => (
      <div>
        {`${suggestion.person.firstName} ${suggestion.person.lastName}`},{' '}
        {suggestion.jobTitle},{' '}
        {moment(suggestion.person.dateOfBirth).format('MM/DD/YYYY')}
      </div>
    );

    const shouldShowIncidents = happenToThisWorker?.length > 0;
    const shouldShowTreatment = happenToThisWorker?.length > 0;
    const shouldShowDeathDate =
      happenToThisWorker?.some(option => option.value === 0);
    const shouldShowDayAwayFromWork =
      happenToThisWorker?.some(option => option.value === 1);
    const shouldShowResults = shouldShowDayAwayFromWork || shouldShowDeathDate;
    const [hours, minutes] = parseTime(timeOfDayWorkStarted);

    return (
      <div className="oshaInjuredWorker">
        <AutoComplete
          fieldLabel="Injured or ill worker's name. "
          isRequired={true}
          placeholder="Worker must already be entered in system."
          renderSuggestion={renderSuggestion}
          getSuggestions={getSuggestions}
          getSuggestionValue={getSuggestionValue}
          handleChange={this.handleEmployeeChange}
          className="noPadding"
          currentValue={employeeName ?? ''}
          disabled={disabled}
        />
        <CheckboxGroup
          options={[{ value: 0, label: 'Privacy Concern Case' }]}
          isRequired={true}
          className="oshaQuestionaire-checkbox"
          name="privacyConcern"
          handleChange={values => this.props.handleInjuredWorkerChange({ privacyConcern: values })}
          currentValue={privacyConcern}
          displayTooltip={true}
          disabled={disabled}
          tooltips={[
            <>
              <p>- Injury to intimate body part or reproductive system</p>
              <p>- Injury resulting from sexual assault</p>
              <p>- Mental illness</p>
              <p>- HIV, hepatitis, or tuberculosis</p>
              <p>
                - Needlestick injury or cut from object contaminated with blood
                or potentially infectious material
              </p>
              <p>
                - Worker voluntarily requests their name not be entered on the
                OSHA log
              </p>
            </>
          ]}
        />
        <Textbox
          currentValue={jobTitle}
          fieldLabel="Job title "
          handleChange={e => this.props.handleInjuredWorkerChange({ jobTitle: e.target.value })}
          isRequired={true}
          type="textarea"
          placeholder="e.g. Welder"
          disabled={disabled}
        />
        <Dropdown
          fieldLabel="Classify injury or illness "
          isRequired={true}
          options={injuryIllnessOptions}
          className="oshaInjuredWorker-dropbox"
          placeholder="Select an option"
          currentValue={injuryIllness}
          handleChange={value => this.props.handleInjuredWorkerChange({ injuryIllness: value })}
          disabled={disabled}
        />
        <CheckboxGroup
          fieldLabel="Did any of these happen to this worker? (Check all that apply)"
          options={options}
          isRequired={true}
          className="oshaQuestionaire-checkbox"
          name="happenToThisWorker"
          handleChange={values =>
            this.props.handleInjuredWorkerChange({ happenToThisWorker: values })
          }
          currentValue={happenToThisWorker}
          displayTooltip={true}
          disabled={disabled}
          tooltips={[
            null,
            'Not including day of the incident',
            null,
            <p>
              First aid refers to medical attention that is usually administered
              immediately after the injury occurs and at the location where it
              occurred. It often consists of a one-time, short-term treatment
              and requires little technology or training to administer.
            </p>,
            <>
              <p>
                - Diagnosed work-related cancer, chronic irreversible diseases,
                broken bones or teeth, and punctured eardrums
              </p>
              <p>
                - Needlestick injury or cut from object contaminated with blood
                or potentially infectious material
              </p>
              <p>- Medical removal</p>
              <p>- Hearing loss</p>
              <p>- Tuberculosis</p>
            </>
          ]}
        />
        {shouldShowIncidents && (
          <section className="oshaInjuredWorker-incident">
            <div className="formHeading">
              <h2>Incident</h2>
            </div>
            <TimePicker
              fieldLabel="Time of day worker started. "
              isRequired
              handleChange={(hours, minutes) =>
                this.props.handleInjuredWorkerChange({
                  timeOfDayWorkStarted: renderTime(hours, minutes, true)
                })
              }
              hours={hours}
              minutes={minutes}
              disabled={disabled}
            />
            <Textbox
              fieldLabel="What was the worker doing and using (i.e. tools and materials) just before the incident occurred?"
              handleChange={e =>
                this.props.handleInjuredWorkerChange({ workerDoing: e.target.value })
              }
              isRequired={true}
              type="textarea"
              placeholder="e.g. Climbing a ladder while carrying roofing materials"
              currentValue={workerDoing}
              disabled={disabled}
            />
            <Textbox
              fieldLabel="How did the injury / incident occur?"
              handleChange={e =>
                this.props.handleInjuredWorkerChange({ howInjuryOccurred: e.target.value })
              }
              isRequired={true}
              type="textarea"
              placeholder="e.g. When ladder slipped on wet floor, worker fell 20 feet"
              currentValue={howInjuryOccurred}
              disabled={disabled}
            />
            <ReactTooltip className="customTheme" id="injuryIllnessBlanks">
              Leave object or substance blank if it does not apply to this
              injury.
            </ReactTooltip>
            <label
              className="label"
              data-tip="customTheme"
              data-for="injuryIllnessBlanks"
            >
              <span className="isRequired">*</span>
              Fill in the blanks to describe the injury or illness and what
              directly caused it
              <img
                className="label-info"
                src={require(`../../assets/images/Info Icon.png`)}
                alt="Info Icon"
              />
            </label>
            <div className="oshaInjuredWorker-fillInTheBlanks">
              <Textbox
                handleChange={e =>
                  this.props.handleInjuredWorkerChange({ incidentInjuryIllness: e.target.value })
                }
                isRequired={true}
                type="textarea"
                placeholder="Injury or Illness"
                currentValue={incidentInjuryIllness}
                disabled={disabled}
              />
              <span> Affecting</span>
              <Textbox
                handleChange={e =>
                  this.props.handleInjuredWorkerChange({ bodyParts: e.target.value })
                }
                isRequired={true}
                type="textarea"
                placeholder="Body Part(s)"
                currentValue={bodyParts}
                disabled={disabled}
              />
              <span>From</span>
              <Textbox
                handleChange={e =>
                  this.props.handleInjuredWorkerChange({ incidentFrom: e.target.value })
                }
                isRequired={true}
                type="textarea"
                placeholder="Object or Substance"
                currentValue={incidentFrom}
                disabled={disabled}
              />
            </div>
          </section>
        )}
        {shouldShowTreatment && (
          <section className="oshaInjuredWorker-treatment">
            <div className="formHeading">
              <h2>Treatment</h2>
            </div>
            <Textbox
              fieldLabel="Name of physician or healthcare professional."
              handleChange={e =>
                this.props.handleInjuredWorkerChange({ physicianName: e.target.value })
              }
              isRequired={true}
              placeholder="e.g. Dr. Jane Doe"
              currentValue={physicianName}
              disabled={disabled}
            />
            <label className="label">
              <span className="isRequired">*</span>
              Where was treatment given? (Must enter ALL address information for
              given facility.)
            </label>
            <div className="oshaInjuredWorker-combinationQuestion">
              <div className="oshaInjuredWorker-combinationQuestion--leftGroup">
                <Textbox
                  handleChange={e =>
                    this.props.handleInjuredWorkerChange({ facilityName: e.target.value })
                  }
                  isRequired={true}
                  placeholder="Facility Name"
                  disabled={isTreatedWorksite || disabled}
                  currentValue={isTreatedWorksite ? '' : facilityName}
                />
                <Textbox
                  handleChange={e =>
                    this.props.handleInjuredWorkerChange({ facilityAddress: e.target.value })
                  }
                  isRequired={true}
                  placeholder="Street Address"
                  currentValue={isTreatedWorksite ? '' : facilityAddress}
                  disabled={isTreatedWorksite || disabled}
                />
                <InputRow className="oshaInjuredWorker-csz">
                  <Textbox
                    handleChange={e =>
                      this.props.handleInjuredWorkerChange({ facilityCity: e.target.value })
                    }
                    isRequired={true}
                    placeholder="City"
                    className="city"
                    disabled={isTreatedWorksite || disabled}
                    currentValue={isTreatedWorksite ? '' : facilityCity}
                  />
                  <Dropdown
                    isRequired={true}
                    options={stateAbbr}
                    placeholder="State"
                    currentValue={isTreatedWorksite ? '' : facilityState}
                    handleChange={value =>
                      this.props.handleInjuredWorkerChange({ facilityState: value })
                    }
                    disabled={isTreatedWorksite || disabled}
                  />
                  <Textbox
                    handleChange={e =>
                      this.props.handleInjuredWorkerChange({ facilityZip: e.target.value })
                    }
                    isRequired={true}
                    placeholder="ZIP Code"
                    className="zip"
                    disabled={isTreatedWorksite || disabled}
                    currentValue={isTreatedWorksite ? '' : facilityZip}
                  />
                </InputRow>
              </div>
              <CheckboxGroup
                options={[{ value: 0, label: 'Treated at worksite' }]}
                className="oshaQuestionaire-checkbox"
                name="facilityTreatedWorksite"
                handleChange={values =>
                  this.props.handleInjuredWorkerChange({
                    facilityTreatedWorksite: values
                  })
                }
                currentValue={facilityTreatedWorksite}
                disabled={disabled}
              />
            </div>
            <RadioButtons
              fieldLabel="Treated in emergency room?"
              options={yesNoOptions}
              isRequired={true}
              handleChange={values =>
                this.props.handleInjuredWorkerChange({ treatedInEmergency: values })
              }
              currentValue={treatedInEmergency}
              disabled={disabled}
            />
            <RadioButtons
              fieldLabel="Hospitalized overnight as inpatient?"
              options={yesNoOptions}
              isRequired={true}
              handleChange={values =>
                this.props.handleInjuredWorkerChange({ hospitalizedInpatient: values })
              }
              currentValue={hospitalizedInpatient}
              disabled={disabled}
            />
            {isPerrp && (
              <RadioButtons
                fieldLabel="Did the employee receive treatment classified as first aid at the worksite or hospital?"
                options={yesNoOptions}
                isRequired={true}
                handleChange={values =>
                  this.props.handleInjuredWorkerChange({ employeeFirstAid: values })
                }
                currentValue={employeeFirstAid}
                disabled={disabled}
              />
            )}
          </section>
        )}
        {shouldShowResults && (
          <section className="oshaInjuredWorker-result">
            <div className="formHeading">
              <h2>Result</h2>
            </div>
            {shouldShowDayAwayFromWork && (
              <div className="oshaInjuredWorker-combinationQuestion">
                <div className="oshaInjuredWorker-combinationQuestion--leftGroup">
                  <Textbox
                    fieldLabel="Days away from work."
                    handleChange={e =>
                      this.props.handleInjuredWorkerChange({ daysAwayFromWork: e.target.value })
                    }
                    isRequired={true}
                    placeholder="0"
                    currentValue={daysAwayFromWork}
                    displayTooltip={true}
                    tooltip="Not including day of the incident."
                    name="daysAway"
                    disabled={disabled}
                  />
                  <Textbox
                    fieldLabel="Days on job restriction or transfer."
                    handleChange={e =>
                      this.props.handleInjuredWorkerChange({ daysJobRestriction: e.target.value })
                    }
                    isRequired={true}
                    placeholder="0"
                    currentValue={daysJobRestriction}
                    displayTooltip={true}
                    tooltip="Not including day of the incident."
                    name="daysJob"
                    disabled={disabled}
                  />
                </div>
                <CheckboxGroup
                  options={[{ value: 0, label: 'Ongoing' }]}
                  className="oshaQuestionaire-checkbox"
                  name="ongoing"
                  handleChange={values =>
                    this.props.handleInjuredWorkerChange({ ongoing: values })
                  }
                  currentValue={ongoing}
                  disabled={disabled}
                />
              </div>
            )}
            {shouldShowDeathDate && (
              <DatePicker
                fieldLabel="Date of Death"
                isRequired
                handleChange={value =>
                  this.props.handleInjuredWorkerChange({ dateOfDeath: value })
                }
                currentValue={dateOfDeath}
                disabled={disabled}
              />
            )}
          </section>
        )}
      </div>
    );
  }
}
