import config from '../config/config';

const featureToggles = config.featureToggles;

export const EMPLOYEE_BULK_UPLOAD_TEMPLATE =
  'https://s3.us-east-2.amazonaws.com/ireportsource-cdn/dashboard/companies/5f635c977ddac000462c47c8/cb942a4c-d767-45d4-ab0e-10d90430d843/EmployeeUploadTemplate_2020_9_18.csv';

export const EMPLOYEE_UPLOAD_DIRECTIONS =
  'https://s3.us-east-2.amazonaws.com/ireportsource-cdn/dashboard/companies/5bc7a73e29cb19000f941b32/e259beec-9cc3-4b50-acad-bda514c07135/emp_user_tempv6.csv';

export const CSV_LRR_TEMPLATE =
  'https://s3.us-east-2.amazonaws.com/ireportsource-cdn/dashboard/companies/5b21105fa64d4e00197ffba4/401404e1-b882-46b8-9ed4-6c83c586afad/loss_run_template-table 1.csv';

export const COMBINED_EMPLOYEE_STATUS = [
  { label: 'Contractor', value: 3 },
  { label: 'Intern', value: 10 },
  { label: 'Seasonal', value: 14 },
  { label: 'Temporary Worker', value: 15 },
  { label: 'Full Time', value: 1 },
  { label: 'Part Time', value: 2 },
  { label: 'Workers Comp', value: 7 },
  { label: 'Retired', value: 8 },
  { label: 'Terminated', value: 17 },
  { label: 'Resigned', value: 18 },
  { label: 'Laid Off', value: 19 }
];

export const EMPLOYEE_STATUSES = [
  { label: 'Full Time', value: 1 },
  { label: 'Part Time', value: 2 },
  { label: 'Workers Comp', value: 7 }
];

export const CONTRACTOR_STATUSES = [{ label: 'Contractor', value: 3 }];

export const OTHER_STATUSES = [
  { label: 'Retired', value: 8 },
  { label: 'Terminated', value: 17 },
  { label: 'Intern', value: 10 },
  { label: 'Seasonal', value: 14 },
  { label: 'Temporary Worker', value: 15 },
  { label: 'Resigned', value: 18 },
  { label: 'Laid Off', value: 19 }
];

export const PRIORITY_DROPDOWN = [
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' }
];

export const SEVERITY_DROPDOWN = [
  { value: 1, label: 'Advisory' },
  { value: 2, label: 'Minor' },
  { value: 3, label: 'Moderate' },
  { value: 4, label: 'Serious' },
  { value: 5, label: 'Critical' }
];

export const SDS_PHYSICAL_HAZARD_DROPDOWN = [
  { label: 'Fire', value: 1 },
  { label: 'Sudden release of pressure', value: 2 },
  { label: 'Reactivity', value: 3 },
  { label: 'Immediate/Acute', value: 4 },
  { label: 'Delayed', value: 5 },
  { label: 'Chronic', value: 6 }
];

export const SDS_STORAGE_STATE_DROPDOWN = [
  { value: 1, label: 'Gas' },
  { value: 2, label: 'Liquid' },
  { value: 3, label: 'Solid' },
  { value: 4, label: 'Mix' },
  { value: 5, label: 'Pure' }
];

export const DAY_OF_WEEK_DROPDOWN = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 }
];

export const GENDER_DROPDOWN = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 }
  // { label: 'Other', value: 3 }
];

export const TASK_CATEGORY_DROPDOWN = [
  { value: 1, label: 'Safety' },
  { value: 2, label: 'Quality' },
  { value: 3, label: 'Productivity' },
  { value: 4, label: 'Cost' },
  { value: 5, label: 'Morale' },
  { value: 6, label: 'Process' }
];

export const TASK_REASON_DROPDOWN = [
  { value: 1, label: 'Compliance with initial job-ready requirements' },
  { value: 2, label: 'Corrective Action' },
  { value: 3, label: 'Follow-up' },
  { value: 4, label: 'Compliance with material inspection and tests' },
  { value: 5, label: 'Compliance with work in progress' },
  {
    value: 6,
    label: 'Compliance with task completion inspection requirements'
  },
  { value: 7, label: 'Compliance with employee corrective action' },
  { value: 8, label: 'Correction of Near Miss' },
  { value: 9, label: 'Correction of safety suggestion' },
  { value: 10, label: 'Other' }
];

export const TASK_STATUS_DROPDOWN = [
  { value: 1, label: 'Assigned' },
  { value: 2, label: 'In Progress' },
  { value: 4, label: 'Stuck' },
  { value: 5, label: 'Completed' }
];

export const COMPANY_SIZE_LIST = [
  '10 or less',
  '11-25',
  '26-50',
  '51-100',
  '101-500',
  '501-1000',
  '1000+'
];

export const COMPANY_AGE_LIST = [
  '5 years or less',
  '6-10 years',
  '11-20 years',
  '21 years or more'
];

export const HOURS_WORKED_LIST = [
  'Fewer than 150,000 hours',
  'Between 151,000 - 400,000 hours',
  'More than 400,000 hours',
  "Don't know"
];

export const INDUSTRY_LIST = [
  'Agriculture',
  'Auto Manufacturing',
  'Auto Dealership',
  'Consulting/Professional Services',
  'Construction/Residential',
  'Construction/Commercial',
  'Electrical',
  'Exterior Painting',
  'Healthcare - Medical',
  'Home Healthcare',
  'Hospitality',
  'Laundry/Dry Cleaning',
  'Manufacturing',
  'Plumbing',
  'Restaurant',
  'Retail Store',
  'Roofing',
  'Service',
  'School/College/University',
  'Staffing Agency',
  'Transportation',
  'Tree Service/Forestry',
  'Trucking',
  'Utility',
  'Other'
];

export const OSHA_PERRP_DROPDOWN = [
  { value: 1, label: 'OSHA' },
  { value: 2, label: 'PERRP' }
];

export const REPORTER_ROLE =
  'Reporters will only have the ability to create the initial report and see reports that they have been involved with. They will also be able to see the company SDS and Safety Instructions.';

export const SUPERVISOR_ROLE =
  'Supervisors can Create Reports and edit the Incidents Basics, Supervisor Investigation and Witness Statements for Reports already created. They can perform RAs assigned to them and see Documents entailed within the locations they have permissions.';

export const COLLABORATOR_ROLE =
  'Collaborators can view all or specific Group(s) / Establishment(s) from one company. They are able to create, edit and delete Reports and Documents. As well as handle the OSHA Forms.';

export const ADMIN_ROLE = `Administrator is the most powerful user role. Administrators can Create, Edit and Delete Companies, Groups / Establishments, Projects, Users, Employees, Contractors, Documents and Reports. They are able to view the Analytics and OSHA Compliance features as well.`;

export const DEACTIVATED_ROLE =
  'A Deactivated user is someone who will not be able to log in until they are made an active role.';

export const WEATHER_CONDITIONS = [
  { value: 0, label: 'Clear' },
  { value: 1, label: 'Raining' },
  { value: 2, label: 'Fog' },
  { value: 3, label: 'Snow' },
  { value: 4, label: 'Other' }
];

export const WORK_DELAYED = [
  { value: 0, label: 'Yes, material shortage' },
  { value: 1, label: 'Yes, contractor issue' },
  { value: 2, label: 'Yes, attendence issue' },
  { value: 3, label: 'Yes, issue with permit' },
  { value: 4, label: 'Yes, issue with plans' },
  { value: 5, label: 'No Delays' },
  { value: 6, label: 'Other' }
];

export const YES_NO_DROPDOWN = [
  { value: 0, label: 'Yes' },
  { value: 1, label: 'No' },
  { value: 2, label: 'Other' }
];

export const JSA_INDUSTRY_DROPDOWN = [
  { value: 1, label: 'Agricultural Operations' },
  { value: 2, label: 'Computer Workstations' },
  { value: 3, label: 'Construction' },
  { value: 21, label: 'Custom' },
  { value: 20, label: 'DOT' },
  { value: 8, label: 'Food Manufacturing' },
  { value: 4, label: 'General Industry' },
  { value: 5, label: 'Healthcare' },
  { value: 6, label: 'Machine Guarding' },
  { value: 7, label: 'Manufacturing' },
  { value: 10, label: 'Office' },
  { value: 11, label: 'Restaurant' },
  { value: 12, label: 'Retailers' },
  { value: 13, label: 'Roofing' },
  { value: 14, label: 'Safe Material Handling' },
  { value: 15, label: 'Senior Living' },
  { value: 16, label: 'Transportation' },
  { value: 17, label: 'Trenches and Excavations' },
  { value: 18, label: 'Volunteer or Community based' },
  { value: 19, label: 'Warehouse' }
];

export const JSA_STAGE_OPTIONS = [
  { value: 0, label: 'Draft' },
  { value: 1, label: 'New Notice' },
  { value: 2, label: 'Investigation' },
  { value: 3, label: 'Minor/Serious Review' },
  { value: 4, label: 'Corrective Action' },
  { value: 5, label: 'New Policy' },
  { value: 6, label: 'Closed' }
];

export const USER_ROLES = [
  { value: '900', label: 'Admin' },
  { value: '500', label: 'Collaborator' },
  { value: '400', label: 'Supervisor' },
  { value: '100', label: 'Reporter' },
  { value: '0', label: 'Deactived' }
];

export const COMPANY_SIZE_DROPDOWN = [
  { label: '10 or Less', value: 1 },
  { label: '11-25', value: 2 },
  { label: '26-50', value: 3 },
  { label: '51-100', value: 4 },
  { label: '101-500', value: 5 },
  { label: '501-1000', value: 6 },
  { label: '1000+', value: 7 }
];

export const COMPANY_AGE_DROPDOWN = [
  { label: '5 years or less', value: 1 },
  { label: '6-10 years', value: 2 },
  { label: '11-20 years', value: 3 },
  { label: '21 years or more', value: 4 }
];

export const HOURS_WORKED_DROPDOWN = [
  { label: 'Fewer than 150,000 hours', value: 1 },
  { label: 'Between 151,000 - 400,000 hours', value: 2 },
  { label: 'More than 400,000 hours', value: 3 },
  { label: "Don't know", value: 4 }
];

export const INDUSTRY_DROPDOWN = [
  { label: 'Agriculture', value: 1 },
  { label: 'Auto Manufacturing', value: 2 },
  { label: 'Auto Dealership', value: 3 },
  { label: 'Consulting/Professional Services', value: 4 },
  { label: 'Construction/Residential', value: 5 },
  { label: 'Construction/Commercial', value: 6 },
  { label: 'Electrical', value: 7 },
  { label: 'Exterior Painting', value: 8 },
  { label: 'Healthcare - Medical', value: 9 },
  { label: 'Home Healthcare', value: 10 },
  { label: 'Hospitality', value: 11 },
  { label: 'Laundry/Dry Cleaning', value: 12 },
  { label: 'Manufacturing', value: 13 },
  { label: 'Plumbing', value: 14 },
  { label: 'Restaurant', value: 15 },
  { label: 'Retail Store', value: 16 },
  { label: 'Roofing', value: 17 },
  { label: 'Service', value: 18 },
  { label: 'School/College/University', value: 19 },
  { label: 'Staffing Agency', value: 20 },
  { label: 'Transportation', value: 21 },
  { label: 'Tree Service/Forestry', value: 22 },
  { label: 'Trucking', value: 23 },
  { label: 'Utility', value: 24 },
  { label: 'Other', value: 25 }
];

export const REACTIVE_REPORT_TYPES = [
  { label: 'Employee Was Injured', value: 2 },
  { label: 'Near Miss', value: 7 },
  { label: 'Auto Accident Occurred', value: 1 },
  { label: 'Third Party (non-employee) Was Injured', value: 5 },
  { label: 'Property Was Damaged', value: 3 },
  { label: 'Theft', value: 4 },
  { label: 'Aircraft', value: 6 },
  { label: 'Fuel Related', value: 16 }
];

export const PROACTIVE_REPORT_TYPES = [
  { label: 'See Something Say Something', value: 11 },
  { label: 'Risk Assessment', value: 8 },
  { label: 'Behavior Observation', value: 12 },
  { label: 'Safety Walk', value: 10 }
];

export const PROACTIVE_KEYS = [11, 10, 8, 9, 14, 12];
export const REACTIVE_KEYS = [2, 3, 7, 1, 5, 4, 6, 16];

export const EVALUTION_TYPES = [
  { label: 'Commercial Truck-Mounted Crane —Telescoping Boom', value: 0 },
  { label: 'Commercial Truck-Mounted Crane —Nontelescoping Boom', value: 1 },
  { label: 'Crawler Crane', value: 2 },
  { label: 'Crawler Crane — Telescoping Boom', value: 3 },
  { label: 'Locomotive Crane', value: 4 },
  { label: 'Wheel-Mounted Crane (Multiple Control Stations)', value: 5 },
  {
    label: 'Wheel-Mounted Crane — Telescoping Boom (Multiple Control Stations)',
    value: 6
  },
  { label: 'Wheel-Mounted Crane (Single Control Station)', value: 7 },
  {
    label:
      'Wheel-Mounted Crane — Telescoping Boom (Single Control Station, Rotating)',
    value: 8
  }
];

export const EVAUATION_GRADE_TYPES = [
  { label: 'Operational Test', value: 0 },
  { label: 'Oral Test', value: 1 },
  { label: 'Hand Signal/Fuction Test', value: 2 },
  { label: 'Qualification Written', value: 3 },
  { label: 'Total', value: 4 }
];

export const NOTIFICATION_TYPE_LIST = [
  { label: 'Email', value: '0' },
  { label: 'Text', value: '1' },
  { label: 'Both', value: '2' },
  { label: 'Disable', value: '3' }
];

export const REPORT_NOTIFICATION_LIST = [
  { label: 'Be notified when Reports are created', value: '0' },
  { label: 'Be notified when Reports are updated', value: '1' },
  { label: 'Be notified when Reports are created and updated', value: '2' }
];

export const REPORT_COMMENT_NOTIFICATION_LIST = [
  { label: 'Be notified when comments are made', value: '0' },
  { label: `Don't be notified when comments are made`, value: '1' }
];

export const PERRP_ENTITY_CODES = [
  { value: '110', label: 'ODNR' },
  { value: '120', label: 'ODOT' },
  { value: '130', label: 'ODRC' },
  { value: '140', label: 'Other state agency' },
  { value: '210', label: 'County Commissioner' },
  { value: '220', label: 'County Engineer' },
  { value: '230', label: 'County Home (care center)' },
  { value: '240', label: 'County MRDD' },
  { value: '250', label: 'Other county agency' },
  { value: '310', label: 'City' },
  { value: '410', label: 'Township' },
  { value: '510', label: 'Village' },
  { value: '610', label: 'Comm./Tech College' },
  { value: '620', label: 'College Branch' },
  { value: '630', label: 'Joint vocational district' },
  { value: '640', label: 'School district' },
  { value: '650', label: 'Educational service center' },
  { value: '660', label: 'University' },
  { value: '670', label: 'University Branch' },
  { value: '710', label: 'Special District' }
];

export const PUBLIC_ENTITY_CODES = [
  '110',
  '120',
  '130',
  '140',
  '210',
  '220',
  '230',
  '240',
  '250',
  '310',
  '410',
  '510',
  '710'
];

export const SCHOOL_ENTITY_CODES = [
  '610',
  '620',
  '630',
  '640',
  '650',
  '660',
  '670'
];

export const CENSORED_TAGS = [
  'Death',
  'Graphic',
  'Injury',
  'Privacy Concern',
  'Sensitive Info',
  'Violence'
];

export const DEFAULT_TAGS = [
  'Behavior',
  'Caused Harm',
  'Damaged',
  'Equipment',
  'Hazard',
  'HAZMAT',
  'Incident Location',
  'Stolen',
  'Vehicle',
  'Weapon',
  'Witness'
];

export const INCIDENT_STAGES = [
  'Investigation',
  'Action/Learning',
  'Closure',
  'Done'
];

export const REPORT_ACTION_ITEMS = [
  { value: 0, label: 'Activity Log' },
  { value: 1, label: 'Attachments' },
  { value: 2, label: 'Comments' },
  { value: 3, label: 'Open Tasks' },
  { value: 4, label: 'Private Log' }
];

export const INVESTIGATION_COMPONENTS = [
  'Initial Report',
  'Photo Evidence',
  'Diagrams',
  'Witness Statements',
  'Supervisor Summary',
  'Injured Person Statement'
];

export const ACTION_COMPONENTS = [
  'OSHA Compliance',
  'Root Cause',
  'Corrective Action',
  'Preventative Action'
];

export const CLOSURE_COMPONENTS = [
  'Follow-Up',
  'Return to Work',
  'Closure Checklist'
];

export const INVESTIGATION_COMPONENTS_PRINT = [
  { label: 'Initial Report', value: 'initialReport' },
  { label: 'Photo Evidence', value: 'photoEvidence' },
  { label: 'Diagrams', value: 'diagrams' },
  { label: 'Witness Statements', value: 'witnessStatements' },
  { label: 'Supervisor Summary', value: 'supervisorSummary' }
];

export const ACTION_COMPONENTS_PRINT = [
  { label: 'Root Cause', value: 'rootCause' },
  { label: 'Corrective Action', value: 'correctiveAction' },
  { label: 'Preventative Action', value: 'preventativeAction' }
];

export const CLOSURE_COMPONENTS_PRINT = [
  { label: 'Follow-Up', value: 'followUp' },
  { label: 'Return to Work', value: 'returnToWork' },
  { label: 'Closure Checklist', value: 'closureChecklist' }
];

export const DOWNLOAD_REPORTS_OPTIONS = [
  { label: 'Include Comments', value: 'includeComments' },
  { label: 'Include Attachments', value: 'includeAttachments' }
];

export const CLAIM_STAGES = [
  'New Report',
  'Incident Analysis',
  'Medical Treatment',
  'Return to Work',
  'Litigation',
  'Closed'
];

export const HAZARD_STAGES = [
  'New Observation',
  'Hazard Analysis',
  'Training',
  'Closed'
];

export const RECURRING_OPTIONS = [
  { value: 'Same Day', label: 'Same Day' },
  { value: 'Next Day', label: 'Next Day' },
  { value: '2 Days Later', label: '2 Days Later' },
  { value: '3 Days Later', label: '3 Days Later' },
  { value: '4 Days Later', label: '4 Days Later' },
  { value: '5 Days Later', label: '5 Days Later' },
  { value: '6 Days Later', label: '6 Days Later' },
  { value: '7 Days Later', label: '7 Days Later' }
];

export const FREQUENCY_OPTIONS = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' }
];

export const MONTHLY_FREQUENCY_OPTIONS = [
  { value: '1stofthemonth', label: '1st of the month' },
  { value: '15thofthemonth', label: '15th of the month' },
  { value: 'lastdayofthemonth', label: 'Last day of the month' }
];

export const YEARLY_FREQUENCY_OPTIONS = [
  { value: 'january', label: '1st of January' },
  { value: 'february', label: '1st of Februray' },
  { value: 'march', label: '1st of March' },
  { value: 'april', label: '1st of April' },
  { value: 'may', label: '1st of May' },
  { value: 'june', label: '1st of June' },
  { value: 'july', label: '1st of July' },
  { value: 'august', label: '1st of August' },
  { value: 'september', label: '1st of September' },
  { value: 'october', label: '1st of October' },
  { value: 'november', label: '1st of November' },
  { value: 'december', label: '1st of December' }
];

export const CAUSAL_FACTORS = [
  {
    value: 'environmental',
    label: 'Environment: (weather,housekeeping,lighting,noise,temperature,etc.)'
  },
  {
    value: 'personal',
    label:
      'Human Factor: (experience,training,physical capability,health,fatigue,stress,etc.)'
  },
  {
    value: 'task',
    label:
      'Task: (ergonomics,condition changes,work process,safe work procedures,etc.)'
  },
  {
    value: 'process',
    label:
      'Management / Process: (safety policies,enforcement,supervision,hazard correction,preventative maintenance,etc.)'
  },
  {
    value: 'equipment',
    label:
      'Material / Equipment: (equipment failure,design,guarding,hazardous substances,etc.)'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const HAZARD_CLASS = [
  {
    value: 'biological',
    label: 'Biological (biological waste,pathogens,poisonous flora/fauna)'
  },
  {
    value: 'chemical',
    label: 'Chemical (exposure to chemical preparations)'
  },
  {
    value: 'ergonomic',
    label: 'Ergonomic (working conditions that put strain on the body)'
  },
  {
    value: 'physical',
    label:
      'Physical (factors within the environment that can cause harm without touching it)'
  },
  {
    value: 'psychological',
    label:
      'Psychological (stress,workload,dealing with the public,harassment,threat of danger,violence,etc.)'
  },
  {
    value: 'safety',
    label: 'Safety (unsafe conditions that can cause injury,illness,or death)'
  }
];

export const SAFETY_WALKS_STAGES = [
  { value: 'severityReview', label: 'Severity Review' },
  { value: 'correctiveAction', label: 'Corrective Action' },
  { value: 'pastDue', label: 'Past Due' },
  { value: 'closed', label: 'Closed' }
];

export const SECURITY_QUESTIONS_OPTIONS = [
  {
    value: 1,
    label: 'What was the house number and street name you lived in as a child?'
  },
  {
    value: 2,
    label: 'What were the last four digits of your childhood telephone number?'
  },
  {
    value: 3,
    label: 'What primary school did you attend?'
  },
  {
    value: 4,
    label: 'In what town or city was your first full time job?'
  },
  {
    value: 5,
    label: 'In what town or city did you meet your spouse/partner?'
  },
  {
    value: 6,
    label: 'What is the middle name of your oldest child?'
  },
  {
    value: 7,
    label: `What are the last five digits of your driver's licence number?`
  },
  {
    value: 8,
    label: `What is your grandmother's (on your mother's side) maiden name?`
  },
  {
    value: 9,
    label: `What is your spouse or partner's mother's maiden name?`
  },
  {
    value: 10,
    label: 'In what town or city did your mother and father meet?'
  },
  {
    value: 11,
    label: 'What time of the day were you born? (hh:mm)'
  },
  {
    value: 12,
    label: 'What time of the day was your first child born? (hh:mm)'
  }
];

export const REPORT_COMPONENTS_CUSTOMIZABLE = featureToggles.customInitialReports
  ? [
      {
        label: 'Report Basics',
        value: 'reportBasic',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      },
      {
        label: 'Supervisor Summary',
        value: 'supervisorSummary',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      },
      {
        label: 'Root Cause',
        value: 'rootCause',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      },
      {
        label: 'Corrective Action',
        value: 'correctiveAction',
        reports: [1, 2, 3, 4, 5, 7]
      },
      {
        label: 'Preventative Action',
        value: 'preventativeAction',
        reports: [11, 12]
      },
      {
        label: 'Follow-Up',
        value: 'followUp',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      },
      {
        label: 'Closure Checklist',
        value: 'closureChecklist',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      }
    ]
  : [
      {
        label: 'Supervisor Summary',
        value: 'supervisorSummary',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      },
      {
        label: 'Root Cause',
        value: 'rootCause',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      },
      {
        label: 'Corrective Action',
        value: 'correctiveAction',
        reports: [1, 2, 3, 4, 5, 7]
      },
      {
        label: 'Preventative Action',
        value: 'preventativeAction',
        reports: [11, 12]
      },
      {
        label: 'Follow-Up',
        value: 'followUp',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      },
      {
        label: 'Closure Checklist',
        value: 'closureChecklist',
        reports: [1, 2, 3, 4, 5, 7, 11, 12]
      }
    ];

export const REPORT_TYPES_CUSTOMIZABLE = [
  { label: 'Employee Is Injured', value: 2 },
  { label: 'Near Miss', value: 7 },
  { label: 'Auto Incident Report', value: 1 },
  { label: 'Third Party (non-employee) Was Injured', value: 5 },
  { label: 'Property Was Damaged', value: 3 },
  { label: 'Theft', value: 4 },
  { label: 'See Something Say Something', value: 11 },
  { label: 'Behavior Observation', value: 12 }
];

export const FIELD_TYPES_CUSTOMIZABLE = [
  { label: 'Dropdown', value: 'dropdown', hasOptions: true },
  { label: 'Multiselect', value: 'multiselect', hasOptions: true },
  { label: 'Radio Button', value: 'radiobuttons', hasOptions: true },
  { label: 'Checkbox', value: 'checkbox', hasOptions: true },
  { label: 'Google Address', value: 'googleAddress' },
  { label: 'Text Field', value: 'textbox' },
  { label: 'Text Area', value: 'textarea' },
  { label: 'Date', value: 'datePicker' },
  { label: 'Date/Time', value: 'dateTimePicker' }
];

export const FIELD_TYPES_OPTIONS = [
  'dropdown',
  'multiselect',
  'radiobuttons',
  'checkbox'
];

export const DOCUMENT_TYPES_CUSTOMIZABLE = [
  { label: 'Dropdown', value: 'dropdown', hasOptions: true },
  { label: 'Multiselect', value: 'multiselect', hasOptions: true },
  { label: 'Radio Button', value: 'radiobuttons', hasOptions: true },
  { label: 'Checkbox', value: 'checkbox', hasOptions: true },
  { label: 'Text Field', value: 'textbox' },
  { label: 'Text Area', value: 'textarea' },
  { label: 'Date', value: 'datePicker' },
  { label: 'Date/Time', value: 'dateTimePicker' },
  { label: 'Block of Text', value: 'blockOfText' },
  { label: 'Employee List', value: 'multiselectPersonnel' },
  { label: 'Signature', value: 'signature' }
];

export const REPEATING_OPTIONS = [
  { value: 1, label: 'Immediately' },
  { value: 2, label: 'Repeating' },
  { value: 3, label: 'Scan on Demand' }
];

//".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const TRAINING_FILE_TYPES_ACCEPTED = [
  '.doc',
  '.docx',
  'image/png',
  'image/jpeg',
  'image/jpg',
  '.mp4',
  '.mov',
  '.pdf',
  '.xlsx',
  '.xls',
  '.ppt',
  '.pptx',
  '.txt'
];
