import React from 'react';
import './svg.css';

export default () => (
  <svg width="25px" height="30px" viewBox="0 0 66 43">
    <g>
      <path d="M4.60156 39C4.66406 36.5625 5.1875 34.3359 6.17188 32.3203C7.125 30.0547 9.375 27.6562 12.9219 25.125C16 22.9219 17.9922 21.3438 18.8984 20.3906C20.2891 18.9062 20.9844 17.2812 20.9844 15.5156C20.9844 14.0781 20.5859 12.8828 19.7891 11.9297C18.9922 10.9766 17.8516 10.5 16.3672 10.5C14.3359 10.5 12.9531 11.2578 12.2188 12.7734C11.7969 13.6484 11.5469 15.0391 11.4688 16.9453H4.97656C5.08594 14.0547 5.60938 11.7188 6.54688 9.9375C8.32812 6.54687 11.4922 4.85156 16.0391 4.85156C19.6328 4.85156 22.4922 5.85156 24.6172 7.85156C26.7422 9.83594 27.8047 12.4688 27.8047 15.75C27.8047 18.2656 27.0547 20.5 25.5547 22.4531C24.5703 23.75 22.9531 25.1953 20.7031 26.7891L18.0312 28.6875C16.3594 29.875 15.2109 30.7344 14.5859 31.2656C13.9766 31.7969 13.4609 32.4141 13.0391 33.1172H27.875V39H4.60156ZM32.8203 32.0156H39.9219V39H32.8203V32.0156ZM46.5078 15.5625V11.0156C48.6172 10.9219 50.0938 10.7812 50.9375 10.5938C52.2812 10.2969 53.375 9.70312 54.2188 8.8125C54.7969 8.20312 55.2344 7.39062 55.5312 6.375C55.7031 5.76562 55.7891 5.3125 55.7891 5.01562H61.3438V39H54.5V15.5625H46.5078Z" />
    </g>
  </svg>
);
