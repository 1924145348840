import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    width="30px"
    height="25px"
  >
    <g>
      <g>
        <path d="M56,164.7V512h328V64H156.7L56,164.7z M152,91.3V160H83.3L152,91.3z M368,496H72V176h96V80h200V496z" />
        <polygon points="392,432 392,448 456,448 456,0 228.7,0 186.3,42.3 197.7,53.7 235.3,16 440,16 440,432    " />
      </g>
    </g>
  </svg>
);
