import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import store from '../../../store';
import history from '../../../history';
import * as api from '../../../api';
import {
  sortDateMethod,
  filterPerformedDate
} from '../../../utils/sortFilterHelpers';
import Button from '../../../components/inputs/Button';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';

import quizListStyles from './styles.module.scss';

export default function QuizList() {
  const [quizzes, setQuizzes] = useState([]);

  useEffect(() => {
    let state = store.getState();
    let companyId = state.company?.activeCompany?._id;
    api.fetchQuizzes(companyId).then(response => setQuizzes(response));
  }, []);

  const header = (
    <Header
      section="Quiz Templates"
      rightButtons={{
        text: 'Create Quiz',
        onClick: () => {
          history.push('/app/quiz/quizTemplate');
        },
        color: 'blue',
        visible: true
      }}
    />
  );

  return (
    <HeaderAndFooter Header={header}>
      <div className={quizListStyles.table}>
        <ReactTable
          data={quizzes}
          filterable
          resizable
          noDataText="No results found"
          defaultPageSize={10}
          className="-striped -highlight"
          columns={[
            {
              Header: 'QUIZ TITLE',
              accessor: d => d.title,
              id: 'title'
            },
            {
              Header: 'INSTRUCTIONS',
              accessor: d => d.instructions,
              id: 'instructions'
            },
            {
              Header: 'LAST UPDATED AT',
              accessor: d => d.updatedAt,
              id: 'updatedAt',
              Cell: ({ original }) =>
                original.updatedAt
                  ? `${moment(original.updatedAt).format('MM/DD/YYYY hh:mm a')}`
                  : '',
              sortMethod: (a, b) => sortDateMethod(a, b),
              filterMethod: (filter, row) => filterPerformedDate(filter, row),
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: '100%' }}
                  value={filter ? filter.value : 'All'}
                >
                  <option value="all">Show All</option>
                  <option value="today">Updated Today</option>
                  <option value="last7">Updated within Last Week</option>
                  <option value="last30">Updated This Month</option>
                  <option value="thisQuarter">Updated This Quarter</option>
                  <option value="thisYear">Updated This Year</option>
                </select>
              )
            },
            {
              Header: 'VIEW/EDIT',
              Cell: ({ original }) => (
                <Button
                  color="blue"
                  onClick={() =>
                    history.push(`/app/quiz/quizTemplate/${original._id}`)
                  }
                  text="View/Edit"
                />
              ),
              filterable: false,
              sortable: false
            }
          ]}
        />
      </div>
    </HeaderAndFooter>
  );
}
