import React from 'react';
import './svg.css';

export default () => (
  <svg
    width="30px"
    height="25px"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
  >
    <g>
      <path d="M24.832,11.445C24.646,11.167,24.334,11,24,11h-1c-0.553,0-1,0.447-1,1v6c0,0.553,0.447,1,1,1h4   c0.553,0,1-0.447,1-1v-1.5c0-0.197-0.059-0.391-0.168-0.555L24.832,11.445z M27,18h-4v-6h1l3,4.5V18z" />
      <path d="M31.496,15.336l-4-6C26.938,8.499,26.004,8,25,8h-4V6c0-1.654-1.346-3-3-3H3C1.346,3,0,4.346,0,6v11   c0,1.654,1.346,3,3,3h0v3c0,1.654,1.346,3,3,3h1.142c0.447,1.721,2,3,3.859,3c1.857,0,3.41-1.279,3.857-3h5.282   c0.447,1.721,2,3,3.859,3c1.857,0,3.41-1.279,3.857-3H29c1.654,0,3-1.346,3-3v-6C32,16.406,31.826,15.83,31.496,15.336z M3,18   c-0.552,0-1-0.447-1-1V6c0-0.553,0.448-1,1-1h15c0.553,0,1,0.447,1,1v2v2v7c0,0.553-0.447,1-1,1H3z M11.001,27   c-1.105,0-2-0.896-2-2s0.895-2,2-2c1.104,0,2,0.896,2,2S12.104,27,11.001,27z M24,27c-1.105,0-2-0.896-2-2s0.895-2,2-2   c1.104,0,2,0.896,2,2S25.104,27,24,27z M30,23c0,0.553-0.447,1-1,1h-1.143c-0.447-1.721-2-3-3.857-3c-1.859,0-3.412,1.279-3.859,3   h-5.282c-0.447-1.721-2-3-3.857-3c-1.859,0-3.412,1.279-3.859,3H6c-0.552,0-1-0.447-1-1v-3h13c1.654,0,3-1.346,3-3v-7h4   c0.334,0,0.646,0.167,0.832,0.445l4,6C29.941,16.609,30,16.803,30,17V23z" />
    </g>
  </svg>
);
