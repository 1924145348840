import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Tabbordion, TabContent, TabLabel, TabPanel } from 'react-tabbordion';
import moment from 'moment';
import history from '../../history';

import { updateLeftNavRequest } from '../../actions/navigation';
import {
  fetchSafetyWalksRequest,
  setActiveSafetyWalk
} from '../../actions/safetyWalks';
import { SAFETY_WALKS_STAGES } from '../../constants/constants';
import { getActiveCompany } from '../../selectors/company';
import { getIncidentCircleSelector } from '../../selectors/incidents';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import { getSafetyWalksSelector } from '../../selectors/safetyWalks';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterLocation,
  filterPerformedDate,
  filterUser,
  sortDateMethod,
  sortUser
} from '../../utils/sortFilterHelpers';
import { AnalyticCircleBox } from '../../components/AnalyticCircle';
import Button from '../../components/inputs/Button';
import Header from '../../components/Header';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import UserBadge from '../../components/UserBadge';

import './index.css';
import 'react-table/react-table.css';
import '../../components/Tabs/index.css';

class SafetyWalkStages extends Component {
  state = {
    filters: []
  };

  componentDidMount() {
    if (localStorage.getItem('swFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('swFilters'))
      });
    }

    this.props.updateLeftNav('Safety Walks');
    this.props.fetchSafetyWalks();
  }

  handleFilterChange = filters => {
    localStorage.setItem('swFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  render() {
    const {
      activeCompany,
      safetyWalksList,
      activeEmployeeList,
      setSafetyWalk,
      circleData
    } = this.props;

    const { filters } = this.state;

    const blockElements = {
      content: 'traditional-tabs-content',
      panel: 'traditional-tabs-panel',
      label: 'traditional-tabs-title'
    };

    const getReportLocation = (locations, locationId) => {
      const location = locations.find(location => location._id === locationId);

      return location ? location.name : 'No Location Specified';
    };

    const content = SAFETY_WALKS_STAGES.map(status =>
      safetyWalksList && safetyWalksList.length > 0
        ? safetyWalksList.filter(
            safetyWalk => safetyWalk.stage.value === status.value
          )
        : []
    );

    return (
      <div className="safetyWalks">
        <Header title={activeCompany.isDash ? 'DASH Report' : 'Safety Walks'} />
        <AnalyticCircleBox circles={circleData} />
        <div className="safetyWalks-content">
          <Tabbordion
            blockElements={blockElements}
            className="traditional-tabs"
            name="tabs"
          >
            {SAFETY_WALKS_STAGES.map((tab, index) => (
              <TabPanel key={index}>
                <TabLabel>{`${tab.label} (${
                  Array.isArray(content[index]) ? content[index].length : 0
                })`}</TabLabel>
                <TabContent>
                  <ReactTable
                    data={content[index]}
                    filterable
                    onFilteredChange={filtered =>
                      this.handleFilterChange(filtered)
                    }
                    filtered={filters}
                    resizable={false}
                    defaultFilterMethod={(filter, row) =>
                      defaultFilterMethod(filter, row)
                    }
                    defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
                    noDataText="No results found"
                    defaultPageSize={10}
                    className="table -striped -highlight"
                    columns={[
                      {
                        Header: 'GROUP/EST.',
                        accessor: 'groupId',
                        Cell: ({ original }) => (
                          <div>
                            <ScrollToTopOnMount />
                            {getReportLocation(
                              activeCompany.allLocations,
                              original.groupId
                            )}
                          </div>
                        ),
                        filterMethod: (filter, row) =>
                          filterLocation(
                            filter,
                            row,
                            activeCompany.allLocations
                          ),
                        Filter: ({ filter, onChange }) => (
                          <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'all'}
                          >
                            <option value="all">Show All</option>
                            {activeCompany?.groups.map((location, index) => (
                              <option key={index} value={location._id}>
                                {location.name}
                              </option>
                            ))}
                          </select>
                        )
                      },
                      {
                        Header: 'REPORTER',
                        accessor: d => d.createdBy?._id || d.createdBy,
                        id: 'createdByUser',
                        sortMethod: (a, b) =>
                          sortUser(a, b, activeEmployeeList),
                        filterMethod: (filter, row) =>
                          filterUser(filter, row, activeEmployeeList),
                        Cell: ({ value }) => <UserBadge showName plain userId={value} />
                      },
                      {
                        Header: 'Hazard Count',
                        accessor: d => d.summary.hazardsFound,
                        id: 'hazardsFound',
                        filterable: false
                      },
                      {
                        Header: 'CREATED DATE',
                        accessor: 'createdAt',
                        Cell: ({ original }) =>
                          `${moment(Date.parse(original.createdAt)).format(
                            'MM/DD/YYYY hh:mm a'
                          )}`,
                        sortMethod: (a, b) => sortDateMethod(a, b),
                        filterMethod: (filter, row) =>
                          filterPerformedDate(filter, row),
                        Filter: ({ filter, onChange }) => (
                          <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'all'}
                          >
                            <option value="all">Show All</option>
                            <option value="today">Created Today</option>
                            <option value="last7">
                              Created within Last Week
                            </option>
                            <option value="last30">Created This Month</option>
                            <option value="thisQuarter">
                              Created This Quarter
                            </option>
                            <option value="thisYear">Created This Year</option>
                          </select>
                        )
                      },
                      {
                        Header: 'VIEW/EDIT',
                        Cell: ({ original }) => (
                          <Button
                            text="View/Edit"
                            color="blue"
                            onClick={() =>
                              setSafetyWalk(original) &&
                              history.push('/app/safetyWalkContainer')
                            }
                          />
                        ),
                        filterable: false,
                        sortable: false
                      }
                    ]}
                    defaultSorted={[
                      {
                        id: 'createdAt',
                        desc: true
                      }
                    ]}
                  />
                </TabContent>
              </TabPanel>
            ))}
          </Tabbordion>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  safetyWalksList: getSafetyWalksSelector(state),
  activeCompany: getActiveCompany(state),
  activeEmployeeList: getCompanyUsersSelector(state),
  circleData: getIncidentCircleSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchSafetyWalks: () => dispatch(fetchSafetyWalksRequest()),
  updateLeftNav: payload => dispatch(updateLeftNavRequest(payload)),
  setSafetyWalk: payload => dispatch(setActiveSafetyWalk(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SafetyWalkStages);
