import React from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import PaginationList from '../PaginationList';
import UserBadge from '../UserBadge';
import Widget from '../Widget';

import './index.css';

const getSections = audit => {
  let sections = audit.sections.map(section => section.sectionName);

  return sections.join(', ');
};

const ScheduledAuditList = ({
  auditList,
  setActiveJsa,
  jsaLoading,
  fetchUserJsas,
  jsaPagination
}) => (
  <Widget>
    <div className="auditList">
      <h2 className="auditList-header">Scheduled Risk Assessments </h2>
      <ul className="auditList-list">
        {!jsaLoading ? (
          auditList.map((audit, index) => (
            <li key={index}>
              <div
                onClick={() => setActiveJsa({ ...audit, goToSummary: true })}
                className={
                  moment().diff(Date.parse(audit.dueDate), 'days') > 0
                    ? 'auditList-listCard pastDue'
                    : 'auditList-listCard'
                }
              >
                <h3>
                  {moment().diff(Date.parse(audit.dueDate), 'days') > 0
                    ? 'Past Due'
                    : 'Assigned'}
                </h3>
                <div className="auditList-card">
                  <div>
                    <div className="auditList-cardTypes">Assigned To: </div>
                    <div className="auditList-cardTypes--profile">
                      {audit.assignedTo.map((userId, index) =>
                        <UserBadge
                          showImage
                          hoverName
                          userId={userId}
                          key={index}
                        />
                      )}
                    </div>
                  </div>
                  <div className="auditList-cardMargin">
                    <div className="auditList-cardTypes">Due Date: </div>
                    {moment(Date.parse(audit.dueDate)).format('MM/DD/YYYY')}
                  </div>
                  <div className="auditList-cardMargin">
                    <div className="auditList-cardTypes">Audits: </div>
                    {getSections(audit)}
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        )}
      </ul>
    </div>
    {jsaPagination.totalPages > 1 && (
      <PaginationList
        page={jsaPagination.page}
        totalPages={jsaPagination.totalPages}
        fetchAction={fetchUserJsas}
      />
    )}
  </Widget>
);

export default ScheduledAuditList;
