import React from 'react';
import moment from 'moment';
import ReactLoading from 'react-loading';
import UserBadge from '../UserBadge';

import './index.css';

const TaskCard = ({ task, onClick, taskLoading }) => (
  <div>
    {taskLoading ? (
      <div className="taskCard">
        <ReactLoading
          type="spinningBubbles"
          color="#58a15b"
          className="loading"
        />
      </div>
    ) : (
      <div className="searchCard">
        {moment().diff(task.dueDate, 'days') > 0 ? (
          <div className="taskCard-cardHeader--overdue">
            <div className="taskCard-cardHeader--overDueText">Past Due</div>
          </div>
        ) : (
          <div className="taskCard-cardHeader--due">
            <div className="taskCard-cardHeader--dueText">Assigned</div>
          </div>
        )}
        <div
          className={
            moment().diff(task.dueDate, 'days') > 0
              ? 'taskCard pastDue'
              : 'taskCard'
          }
          onClick={onClick}
        >
          <div className="taskCard-card">
            <div className="taskCard-cardLeft">
              <div>
                <div className="taskCard-cardTypes">Created By: </div>
                <div className="incidentCard-left-profile">
                  <UserBadge
                    userId={task.createdBy}
                    hoverClassName="taskCard-profileHover"
                  />
                </div>
              </div>
              <div className="taskCard-cardMargin">
                <div className="taskCard-cardTypes">Title: </div>
                {task.label}
              </div>
              <div>
                <div className="taskCard-cardTypes">Due Date: </div>
                {moment(task.dueDate).format('MM/DD/YYYY')}
              </div>
            </div>
            <div className="taskCard-cardRight">
              <div>
                <div className="taskCard-cardTypes">Assigned To: </div>
                <div className="taskCard-cardTypes--profile">
                  {task.assignedTo.map(
                    (userId, index) =>
                      <UserBadge
                        userId={userId}
                        key={index}
                        showImage
                        hoverName
                      />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default TaskCard;
