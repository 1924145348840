import React, { Component } from 'react';
import history from '../../history';
import ReactTooltip from 'react-tooltip';

import './index.css';

export default class AnalyticCard extends Component {
  constructor(props) {
    super(props);

    this.handleOshaClick = this.handleOshaClick.bind(this);
  }

  handleOshaClick(year, canViewTDL) {
    if (canViewTDL) {
      history.push(`/app/oshaLog/${year}`);
    }
  }

  render() {
    const {
      hasError,
      classBoolean,
      cardDetails,
      children,
      hoverText,
      year,
      canViewTDL
    } = this.props;

    return (
      <div className="analyticsCard">
        {hasError ? (
          <div className="analyticsCard-errorCard">
            <div className="analyticsCard-card">
              <p
                className={
                  classBoolean
                    ? 'analyticsCard-cardHeader--noData'
                    : 'analyticsCard-cardHeader--data'
                }
              >
                {children}
              </p>
              <p className="analyticsCard-cardDetails">{cardDetails}</p>
            </div>
            <div
              className="analyticsCard-card--errorIcon"
              onClick={() => this.handleOshaClick(year, canViewTDL)}
            >
              <ReactTooltip
                className="errorTheme"
                id={`analyticsCardError${cardDetails}`}
              >
                {hoverText}
              </ReactTooltip>
              <img
                src={require('../../assets/images/chartDiamond.png')}
                alt="boxWarning"
                data-tip="errorTheme"
                data-for={`analyticsCardError${cardDetails}`}
              />
            </div>
          </div>
        ) : (
          <div className="analyticsCard-card">
            <p
              className={
                classBoolean
                  ? 'analyticsCard-cardHeader--noData'
                  : 'analyticsCard-cardHeader--data'
              }
            >
              {children}
            </p>
            <p className="analyticsCard-cardDetails">{cardDetails}</p>
          </div>
        )}
      </div>
    );
  }
}
