import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <path
        d="M305.4,206.6l-73.2,72.8l-25.6-25.8c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l34.2,34.4
                      c2.4,2.4,5.5,3.6,8.7,3.6c3.1,0,6.2-1.2,8.6-3.6l81.9-81.4c4.8-4.8,4.8-12.5,0-17.3C318,201.9,310.2,201.8,305.4,206.6z"
      />
      <path
        d="M466.7,290V106.4c0-4.9-3-9.4-7.5-11.3L260.7,12.3c-3-1.3-6.4-1.3-9.4,0L52.8,95.1c-4.6,1.9-7.5,6.4-7.5,11.3
                      V290c0,116.2,94.5,210.7,210.7,210.7S466.7,406.1,466.7,290z M256,476.2c-102.7,0-186.2-83.5-186.2-186.2V114.6L256,36.9
                      l186.2,77.7V290C442.2,392.6,358.7,476.2,256,476.2z"
      />
    </g>
  </svg>
);
