import React, { Component } from 'react';

import Gear from '../../svgs/gear';

import styles from './gearStyles.module.scss';

export default class GearMenu extends Component {
  state = { open: false };

  toggle = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState((state, props) => {
      let newState = { ...state };
      newState.open = props.disabled ? false : !state.open;
      return newState;
    });
  };

  close = () => {
    this.setState({ open: false });
  };

  open = () => {
    if (!this.props.disabled) this.setState({ open: true });
  };

  render() {
    let classes = [styles.gearMenu];
    if (this.props.disable) classes.push(styles.disabled);
    if (this.state.open) classes.push(styles.open);

    return (
      <div className={this.props.className}>
        <ul
          onClick={this.toggle}
          onBlur={this.close}
          className={classes.join(' ')}
        >
          <li className={styles.menu}>
            <Gear />
            <div className={styles.right}>
              <img
                src={require('../../assets/images/downChevron.png')}
                alt="Menu"
              />
            </div>
          </li>
          <li className={styles.wrapper}>
            <ul>
              {this.props.options.map((option, index) => (
                <li key={index} onClick={option.onClick} className={option.color || 'blue'}>
                  <div>{option.label}</div>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
