import React from 'react';
import './svg.css';

export default () => (
  <svg width="100" height="100" viewBox="0 0 100 100">
    <g>
      <g transform="translate(50 50) scale(0.69 0.69) rotate(0) translate(-50 -50)">
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
          <g transform="translate(0,-952.36218)">
            <path
              d="m 71.997658,1040.4669 c 20.979017,-12.1134 28.277562,-39.0162 16.103409,-60.10438 -12.174118,-21.0882 -39.119734,-28.2184 -60.098751,-16.105 -20.9790178,12.1134 -28.2775168,39.01628 -16.103399,60.10448 12.174158,21.0882 39.119723,28.2182 60.098741,16.1049 z m -0.326501,-4.5712 -15.019006,-26.0162 13.667623,3.6471 9.133131,15.8205 c -2.532232,2.5294 -5.241524,4.8925 -7.781748,6.5486 z m -3.468662,2.0028 c -3.094339,1.5905 -6.308759,2.7331 -9.561558,3.4656 l -9.133131,-15.8205 3.675683,-13.6612 z m 13.985298,-11.8253 -8.399358,-14.5495 4.043301,-15.02708 10.335303,17.90298 c -1.330689,4.1569 -3.335941,8.1103 -5.979246,11.6736 z m -27.749289,16.0227 c -4.407318,0.5076 -8.833313,0.2677 -13.09839,-0.6582 l -10.335304,-17.903 15.034335,4.0116 z m 35.089521,-33.3501 -10.460191,-18.11928 c -0.202963,-0.3454 -0.404024,-0.6897 -0.713184,-0.9225 -7.01093,-3.061 -14.790958,-5.7795 -22.044617,-8.5818 l -21.566542,-8.3169 c -1.113831,-0.4346 -2.529263,0.3827 -2.709893,1.5647 l -3.581251,22.8374 c -0.677347,7.77078 -3.248051,15.72858 -3.602406,23.42658 0.04912,0.3359 0.185109,0.6585 0.391201,0.9281 l 10.522633,18.2274 c -8.357531,-3.1337 -15.710752,-9.0638 -20.504593,-17.3678 -11.0028918,-19.0593 -4.477727,-43.53318 14.772719,-54.64848 19.250444,-11.1153 43.706008,-4.5299 54.7089,14.5295 4.793842,8.304 6.252386,17.6377 4.787224,26.44308 z m -19.300577,0.6152 c -5.62275,-1.5003 -11.251108,-3.0021 -16.870917,-4.5017 -1.435807,-0.5463 -2.571198,0.263 -2.895687,1.7436 l -4.514275,16.7775 -16.82817,-4.4904 3.10738,-19.71518 c 4.494229,4.00348 11.319119,4.85878 16.884107,1.64558 5.564989,-3.21328 8.236798,-9.55198 7.017135,-15.44628 l 18.625952,7.1663 z m -23.11571,-12.00188 c -4.994718,2.88408 -11.209966,1.3361 -14.085607,-3.3696 l 2.594977,-16.5346 15.615495,6.0198 c 2.637074,4.8434 0.86985,11.0005 -4.124865,13.8844 z"
              fillOpacity="1"
              stroke="none"
              marker="none"
              visibility="visible"
              display="inline"
              overflow="visible"
            />
          </g>
        </svg>
      </g>
    </g>
  </svg>
);
