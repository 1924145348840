import React from 'react';
import './svg.css';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 25.5V10.95H12.125C13.0915 10.95 13.875 10.1665 13.875 9.2V4.5H23.5V11.1621L23.9068 10.7553C24.0289 10.6332 24.1629 10.5302 24.305 10.4463C24.3889 10.3042 24.4919 10.1703 24.614 10.0482L25 9.66213V4C25 3.44772 24.5523 3 24 3H13.875H13.125L12.375 3.75789L6.74219 9.45L6 10.2V10.95V26C6 26.5523 6.44771 27 7 27H24C24.5523 27 25 26.5523 25 26V20.3379L24.7602 20.5777C24.5039 20.834 24.1828 21.0159 23.8311 21.1038L23.5 21.1866V25.5H7.5ZM23.5 19.0095L25 17.5095V12.4906L23.5 13.9906V19.0095ZM20.3125 18.75H21V19.75L20 20L20.3125 18.75ZM18.251 18.75L18.0598 19.5149C17.9988 19.7589 17.9852 20.008 18.0157 20.25H9.5V18.75H18.251ZM21 13.6621L19.9121 14.75H9.5V13.25H21V13.6621ZM8.85249 9.45L12.375 5.89041V9.2C12.375 9.33807 12.2631 9.45 12.125 9.45H8.85249Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 20L23.346 19.1635L27.8305 14.679L25.321 12.1695L20.8365 16.654L20 20ZM26.0281 11.4624L28.5376 13.9719L29.4803 13.0292C30.1732 12.3362 30.1732 11.2127 29.4803 10.5197C28.7873 9.82675 27.6638 9.82675 26.9708 10.5197L26.0281 11.4624Z"
    />
  </svg>
);
