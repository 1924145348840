import { UPDATE_LEFT_NAV_REQUEST } from '../constants/actionTypes';
import { leftNavigation } from '../constants/leftNavigation';

export const initialState = {
  leftNavigation: leftNavigation,
  topLogo: '',
  loading: false,
  modalOpen: false
};

const navigation = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_LEFT_NAV_REQUEST: {
      let leftNav = state.leftNavigation;

      leftNav = {
        sections: leftNav.sections.map(nav => {
          return {
            ...nav,
            section: nav.section.map(section => {
              if (section.active) {
                section.active = false;
              }

              if (
                section.text === payload ||
                section.text.replace('/', '') === payload
              ) {
                section.active = true;
              }

              return section;
            })
          };
        })
      };

      return {
        ...state,
        leftNavigation: leftNav
      };
    }

    default:
      return {
        ...state
      };
  }
};

export default navigation;
