const priorityMapper = priority => {
  switch (priority) {
    case 'Low':
    case 1:
    case '1':
      return 1;
    case 2:
    case '2':
    case 'Medium':
      return 2;
    case 3:
    case '3':
    case 'High':
      return 3;
    default:
      return 1;
  }
};

export default priorityMapper;
