import React from 'react';

import './index.css';

const getPagination = (totalPages, page, fetchAction) => {
  let children = [];

  for (let i = 1; i <= totalPages; i++) {
    if (i === page) {
      children.push(
        <li className="paginationList-numbers--selected" key={i}>
          {i}
        </li>
      );
    } else {
      children.push(
        <li
          className="paginationList-numbers"
          key={i}
          onClick={() => fetchAction(i)}
        >
          {i}
        </li>
      );
    }
  }

  return children;
};

const PaginationList = ({
  page,
  totalPages,
  fetchAction
}) => (
  <div className="paginationList">
    <ul className="paginationList-list">
      <li
        className={
          page > 1
            ? 'paginationList-list '
            : 'paginationList-list noPrevPage'
        }
      >
        <img
          className="paginationList-leftChevron"
          src={require('../../assets/images/Vector.png')}
          alt={`left select chevron`}
          onClick={() => fetchAction(page - 1)}
        />
      </li>
      {getPagination(totalPages, page, fetchAction)}
      <li
        className={
          page < totalPages
            ? 'paginationList-list'
            : 'paginationList-list noNextPage'
        }
      >
        <img
          src={require('../../assets/images/Vector.png')}
          alt={`right select chevron`}
          onClick={() => fetchAction(page + 1)}
        />
      </li>
    </ul>
  </div>
);

export default PaginationList;
