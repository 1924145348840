import React, { Component } from 'react';
import Intercom from 'react-intercom';
import { Route, Switch } from 'react-router-dom';
import routes from '../../constants/routes';
import MobileHeader from '../MobileHeader';
import 'react-dates/initialize';

import './index.css';
import '../../styles/reactLoading.scss';
import styles from './styles.module.scss';
import history from '../../../src/history';
import LogRocket from 'logrocket';
import { UserAgentApplication } from 'msal';

// this is used to allow self signed certs from the development server
// and allows use to use https (SSL/TLS) even when running locally
if (process.env.REACT_APP_STAGE === 'development') {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

export class App extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/login');
    }
  }

  componentDidMount() {
    // This is weird but Microsoft login redirects back to the site and expects it to create the object before it closes the popup.
    // See https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/174
    if (window.location.hash.includes('id_token')) {
      new UserAgentApplication(
        '4ecf3d26-e844-4855-9158-b8f6c0121b50',
        null,
        null
      );
    }
  }

  render() {
    let user;
    const token = localStorage.getItem('token');
    if (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const decoded = JSON.parse(window.atob(base64));
      user = {
        user_id: decoded.user_id,
        email: decoded.email,
        phone: decoded.phone_number,
        name: decoded.first_name + ' ' + decoded.last_name,
        user_type: parseInt(decoded.access_level, 10),
        'Company name': localStorage.getItem('companies'),
        isAccountOwner: decoded.isAccountOwner,
        username: decoded.username
      };
    }

    try {
      // link intercom and logrocket sessions and users
      if (
        process.env.REACT_APP_STAGE === 'production' &&
        user &&
        user.username
      ) {
        Intercom('update', {
          logrocketURL: `https://app.logrocket.com/ireportsource/ireport-react-app/sessions?u=${user.username}`
        });
        LogRocket.getSessionURL(function(sessionURL) {
          Intercom('trackEvent', 'LogRocket', { sessionURL: sessionURL });
        });
      }
    } catch (e) {
      console.log(e);
    }

    return (
      <div className={styles.App}>
        {process.env.REACT_APP_STAGE === 'production' && user ? (
          <Intercom appID="gpy0bzzd" {...user} />
        ) : (
          ''
        )}
        <MobileHeader />
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            component={route.sidebar}
            exact={route.exact}
          />
        ))}
        <div className={styles.mainPage}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                component={route.main}
                exact={route.exact}
              />
            ))}
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
