import { createSelector } from 'reselect';

const searchSelector = state => state.search;

export const getSearchListSelector = createSelector(
  searchSelector,
  search => search && search.list
);

export const getSearchLoadingSelector = createSelector(
  searchSelector,
  search => search.loading
);
