import React from 'react';

import UserBadge from '../../components/UserBadge';
import { classnames } from '../../utils/classNamesHelper';

import styles from './styles.module.scss';

export const HeaderAndFooter = ({
  Header,
  Footer,
  Left,
  children,
  className,
  showFooter
}) => {
  if (showFooter === undefined) showFooter = true;
  if (!Footer) showFooter = false;
  let classes = [styles.container];
  if (!showFooter) classes.push(styles.noFooter);
  if (Left) classes.push(styles.withLeft);

  return (
    <div className={classes.join(' ')}>
      {Header}
      {Left && <div className={styles.left}>{Left}</div>}
      <div className={className}>{children}</div>
      {showFooter && Footer}
    </div>
  );
};

export const HeaderAndFooterWithLock = props => {
  let showFooter = props.showFooter;
  if (showFooter === undefined) showFooter = true;
  if (!props.Footer) showFooter = false;
  let classes = {
    [styles.container]: true,
    [styles.noFooter]: !showFooter,
    [styles.locked]:
      (props.lockedUserId && props.lockedUserId !== props.myUserId) ||
      props.dirty,
    [styles.withLeft]: !!props.Left
  };

  let lockMessage = props.dirty
    ? 'This report section has been updated. Please click cancel or the back button to get the lastest data from this report section.'
    : props.lockedUserId && props.lockedUserId !== props.myUserId
    ? 'is currently editing this section'
    : undefined;

  return (
    <div className={classnames(classes)}>
      {props.Header}
      {lockMessage ? (
        <header className={styles.lock}>
          {props.lockedUserId && props.lockedUserId !== props.myUserId ? (
            <UserBadge
              userId={props.lockedUserId}
              showImage
              showName
              className={styles.lockUser}
            />
          ) : (
            <></>
          )}
          <div className={styles.lockMessage}>{lockMessage}</div>
        </header>
      ) : (
        <></>
      )}
      {props.Left && <div className={styles.left}>{props.Left}</div>}
      <div className={props.className}>{props.children}</div>
      {showFooter && props.Footer}
    </div>
  );
};

export default HeaderAndFooter;
