import React, { Component } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';

import { classnames } from '../../../utils/classNamesHelper';
import { renderTime } from '../../../utils/dateHelpers';
import { normalizeTime } from '../../../utils/formValidation';
import Checkbox from '../Checkbox';
import Dropdown from '../Dropdown';
import Input from '../';

import styles from './dateTimePicker.module.scss';
import 'react-dates/lib/css/_datepicker.css';

export class DatePicker extends Component {
  state = {
    timeNotDetermined: false,
    isDatePickerFocused: false
  };

  componentDidMount() {
    if (this.props.timeNotDetermined) {
      this.setState({ timeNotDetermined: this.props.timeNotDetermined });
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.timeNotDetermined !== prevProps.timeNotDetermined) {
      this.setState({ timeNotDetermined: this.props.timeNotDetermined });
    }
  };

  handleTimeChange = (hours, minutes) => {
    let date = moment(this.props.currentValue || undefined);

    date.hour(hours);
    date.minute(minutes);

    this.props.handleChange &&
      this.props.handleChange(date, this.state.timeNotDetermined);
    this.props.onBlur && this.props.onBlur();
  };

  handleTimeNotExactChange = value => {
    this.setState({ timeNotDetermined: value });

    this.props.handleChange &&
      this.props.handleChange(this.props.currentValue, value);
  };

  handleDateChange = date => {
    if (!date) {
      this.props.handleChange &&
        this.props.handleChange(date, this.state.timeNotDetermined);
      return;
    }

    if (this.props.currentValue) {
      let oldDate = moment(this.props.currentValue);
      date.hour(oldDate.hour());
      date.minute(oldDate.minute());
      date.second(oldDate.second());
      date.millisecond(oldDate.millisecond());
    }

    this.props.handleChange &&
      this.props.handleChange(date, this.state.timeNotDetermined);
    this.props.onBlur && this.props.onBlur();
  };

  render() {
    let date = this.props.currentValue;

    const {
      name,
      disabled,
      pickDate,
      pickTime,
      pickNotExact,
      className,
      appendToBody,
      ...props
    } = this.props;

    const classes = [
      className,
      styles.container,
      pickTime && !this.state.timeNotDetermined && styles.pickTime
    ];

    if (date && !moment.isMoment(date)) {
      date = moment(date);
    }

    if (date?.isValid && !date.isValid()) date = null;

    return (
      <Input
        inactiveLabel
        className={classnames(classes)}
        name={name}
        {...props}
      >
        <div className={styles.picker}>
          <SingleDatePicker
            date={date}
            onDateChange={this.handleDateChange}
            focused={this.state.isDatePickerFocused}
            onFocusChange={focused => {
              this.setState({ isDatePickerFocused: focused.focused });
            }}
            id={name}
            disabled={disabled}
            placeholder="MM/DD/YYYY"
            isOutsideRange={day => false}
            appendToBody={appendToBody}
            showClearDate
          />
          {pickTime && !this.state.timeNotDetermined && (
            <TimePicker
              hours={date?.hour?.() ?? null}
              minutes={date?.minute?.() ?? null}
              handleChange={this.handleTimeChange}
              className={styles.timePicker}
              disabled={disabled}
            />
          )}
        </div>
        {pickNotExact && (
          <Checkbox
            fieldLabel="Exact Time Cannot Be Determined"
            name="timeNotDetermined"
            onChange={this.handleTimeNotExactChange}
            currentValue={this.state.timeNotDetermined}
            disabled={disabled}
          />
        )}
      </Input>
    );
  }
}

export class TimePicker extends Component {
  state = {
    hours: null,
    minutes: null,
    selectedTime: ''
  };

  componentDidMount() {
    const { hours, minutes } = this.props;
    this.setState({
      hours,
      minutes,
      selectedTime: renderTime(hours, minutes)
    });
  }

  componentDidUpdate = prevProps => {
    const { hours, minutes } = this.props;
    if (hours !== prevProps.hours || minutes !== prevProps.minutes) {
      this.setState({
        hours,
        minutes,
        selectedTime: renderTime(hours, minutes)
      });
    }
  };

  handleTimeChange = () => {
    this.setState(
      state => {
        let v = state.selectedTime;
        let hours = null;
        let minutes = null;
        if (v) {
          [hours, minutes] = v.split(':');
          hours = parseInt(hours, 10);
          minutes = parseInt(minutes, 10);
          if (hours === 12) hours = 0;
          if (hours > 23) hours = 23;
          if (minutes > 59) minutes = 59;
          if (state.hours >= 12 && hours < 12) hours += 12;
        }

        return {
          hours,
          minutes,
          selectedTime: renderTime(hours, minutes)
        };
      },
      () => {
        this.props.handleChange &&
          this.props.handleChange(this.state.hours, this.state.minutes);
        this.props.onBlur && this.props.onBlur();
      }
    );
  };

  handlePeriodChange = value => {
    this.setState(
      state => {
        let isPM = value.value === 'PM';
        let hours = state.hours % 12;

        if (isPM) hours += 12;

        return {
          hours,
          selectedTime: renderTime(hours, state.minutes)
        };
      },
      () => {
        this.props.handleChange &&
          this.props.handleChange(this.state.hours, this.state.minutes);
        this.props.onBlur && this.props.onBlur();
      }
    );
  };

  render() {
    const { name, disabled, className, ...props } = this.props;

    const amPm = [
      { value: 'AM', label: 'AM' },
      { value: 'PM', label: 'PM' }
    ];

    return (
      <Input name={name} {...props} className={[className, styles.time]}>
        <input
          value={this.state.selectedTime}
          name={name}
          type="text"
          placeholder="00:00"
          className={`${styles.textbox} textbox`}
          onChange={e =>
            this.setState({
              selectedTime: normalizeTime(e.target.value)
            })
          }
          disabled={disabled}
          maxLength={5}
          onBlur={this.handleTimeChange}
        />
        <Dropdown
          options={amPm}
          currentValue={this.state.hours >= 12 ? 'PM' : 'AM'}
          handleChange={value => this.handlePeriodChange(value)}
          className={styles.dropdown}
          inputClassName={styles.input}
          disabled={disabled}
        />
      </Input>
    );
  }
}
