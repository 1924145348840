import { all, call, put, select } from 'redux-saga/effects';
import history from '../history';
import { clearUploadedAttachments } from '../actions/attachments';
import {
  emailIncidentAssigneeResponse,
  fetchCompanyCirclesResponse,
  fetchCompanyIncidentsResponse,
  fetchIncidentAttachmentsResponse,
  fetchIncidentBasicsResponse,
  setActiveIncidentResponse,
  updateIncidentBasicsResponse,
  updateIncidentResponse
} from '../actions/incidents';
import { addMessage, removeMessage } from '../actions/messages';
import { fetchOshaFormResponse } from '../actions/oshaLogs';
import { createReportResponse, setReportTemplate } from '../actions/reports';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import {
  getActiveIncidentSelector,
  getIncidentAttachmentsSelector,
  getIncidentBasicsSelector
} from '../selectors/incidents';
import { getActiveReportTemplatesSelector } from '../selectors/reports';
import { circleDataMapper } from '../utils/circleDataMapper';
import { oshaQuestionMapper } from '../utils/oshaDbMapper';
import { dbIncidentDateTimeMapper } from '../utils/reportDateTimeMapper';
import { getLoggedInUser } from '../selectors/users';
import reportTemplateMapper from '../utils/reportTemplateMapper';

export function* fetchIncidentCircles(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeLocation = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);

    let circleResponse = [];

    // change label of circle depending on screen (per Argie on 03/23/2020)
    // it is hacky, but does what we need without making many changes
    // until we get rid of circles data or change them
    // data that gets returned will be different as well...see API updates
    // to fetchIncidents and fetchCircleData
    let label = 'Reports';
    if (window.location.href.includes('dashboard')) {
      label = 'Accidents';
    }

    if (activeProject && activeProject._id) {
      circleResponse = yield call(api.fetchProjectCircleData, {
          companyId: activeCompany._id,
          projectId: activeProject._id
      });
    } else if (activeLocation && activeLocation._id) {
      circleResponse = yield call(api.fetchLocationCircleData, {
        companyId: activeCompany._id,
        locationId: activeLocation._id
      });
    } else {
      circleResponse = yield call(api.fetchCompanyCircleData, {
        companyId: activeCompany._id
      });
    }

    const circles = circleDataMapper(circleResponse, label);

    yield put(fetchCompanyCirclesResponse(circles));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchIncidents(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeLocation = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);

    let response = [];
    let circleResponse = [];

    // change label of circle depending on screen (per Argie on 03/23/2020)
    // it is hacky, but does what we need without making many changes
    // until we get rid of circles data or change them
    // data that gets returned will be different as well...see API updates
    // to fetchIncidents and fetchCircleData
    let label = 'Reports';
    if (window.location.href.includes('dashboard')) {
      label = 'Accidents';
    }

    if (activeProject && activeProject._id) {
      const { incidentResponse, inCircleResponse } = yield all({
        incidentResponse: call(
          api.fetchIncidentsByProject,
          activeCompany._id,
          activeProject._id,
          payload.page,
          payload.limit,
          payload.removeClosed
        ),
        inCircleResponse: call(api.fetchProjectCircleData, {
          companyId: activeCompany._id,
          projectId: activeProject._id
        })
      });

      response = incidentResponse;

      circleResponse = inCircleResponse;
    } else if (activeLocation && activeLocation._id) {
      const { incidentResponse, inCircleResponse } = yield all({
        incidentResponse: call(
          api.fetchIncidentsByLocation,
          activeCompany._id,
          activeLocation._id,
          payload.page,
          payload.limit,
          payload.removeClosed
        ),
        inCircleResponse: call(api.fetchLocationCircleData, {
          companyId: activeCompany._id,
          locationId: activeLocation._id
        })
      });

      response = incidentResponse;

      circleResponse = inCircleResponse;
    } else {
      const { inCircleResponse, incidentResponse } = yield all({
        inCircleResponse: call(api.fetchCompanyCircleData, {
          companyId: activeCompany._id
        }),
        incidentResponse: call(
          api.fetchIncidentsByCompany,
          activeCompany._id,
          payload.page,
          payload.limit,
          payload.removeClosed
        )
      });

      response = incidentResponse;

      circleResponse = inCircleResponse;
    }

    const circles = circleDataMapper(circleResponse, label);

    response = {
      list: response.docs,
      paginationInfo: {
        hasNextPage: response.hasNextPage,
        hasPrevPage: response.hasPrevPage,
        limit: response.limit,
        nextPage: response.nextPage,
        page: response.page,
        prevPage: response.prevPage,
        totalDocs: response.totalDocs,
        totalPages: response.totalPages
      }
    };

    yield put(fetchCompanyIncidentsResponse(response));
    yield put(fetchCompanyCirclesResponse(circles));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchIncidentById(api, { payload }) {
  try {
    const response = yield call(
      api.fetchIncidentById,
      payload.companyId,
      payload._id
    );

    yield put(setActiveIncidentResponse(response));
    yield put(
      fetchIncidentAttachmentsResponse(response.incidentActivity.attachments)
    );
  } catch (e) {
    console.log(e);
  }
}

export function* setActiveIncident(api, { payload }) {
  const loggedInUser = yield select(getLoggedInUser);
  const company = yield select(getActiveCompany);

  if (typeof payload === 'string') {
    payload = yield call(api.fetchIncidentById, company._id, payload);
  } else {
    payload = yield call(api.fetchIncidentById, company._id, payload._id);
  }

  const froi =
    payload.reportTemplateIds.indexOf('2') > -1 ||
    payload.reportTemplateIds.indexOf('5') > -1;

  if (froi) {
    const oshaFormPayload = {
      incidentId: payload._id,
      companyId: payload.companyId
    };

    let oshaForm = yield call(api.fetchOshaForm, oshaFormPayload);

    if (oshaForm.length > 0) {
      oshaForm.map(
        form => (form.formAnswers = oshaQuestionMapper(form.questions))
      );
    }

    yield put(fetchOshaFormResponse(oshaForm));
  } else {
    yield put(fetchOshaFormResponse([]));
  }

  const response = { sections: [] };

  const incidentBasics = payload.incidentBasicsFieldGroup;

  const reportBasics = payload.reportBasicsFieldGroups;

  response.sections = [incidentBasics, ...reportBasics];

  yield put(setActiveIncidentResponse({ ...payload }));
  yield put(
    fetchIncidentAttachmentsResponse(payload.incidentActivity.attachments)
  );
  yield put(fetchIncidentBasicsResponse(response));

  parseInt(loggedInUser.accessLevel) === 100
    ? history.push('/app/initalReportContainer')
    : history.push('/app/incidentContainer');
}

export function* fetchIncidentBasics(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);

    const response = { sections: [] };

    const incidentBasics = yield call(api.fetchIncidentBasics, company._id);

    const reportBasics = yield all(
      payload.map(fieldGroupId =>
        call(
          api.fetchReportBasics,
          company._id,
          reportTemplateMapper(fieldGroupId).basic
        )
      )
    );

    const basics = reportBasics.map(report => report[0]);
    let customQuestions = yield all(
      payload.map(fieldGroupId =>
        call(api.fetchCustomReportQuestions, {
          companyId: company._id,
          reportTemplateNumber: fieldGroupId,
          reportComponentName: 'Report Basics'
        })
      )
    );

    customQuestions = customQuestions.reduce((acc, x) => acc.concat(x), []);

    if (customQuestions.length > 0) {
      let customQuestionFields = [];

      customQuestions.forEach(custom =>
        customQuestionFields.push(...custom.fields)
      );

      response.sections = [
        ...incidentBasics,
        ...basics,
        {
          label: 'Custom Questions',
          fields: customQuestionFields
        }
      ];
    } else {
      response.sections = [...incidentBasics, ...basics];
    }

    yield put(fetchIncidentBasicsResponse(response));
    yield put(setActiveIncidentResponse());
    yield put(setReportTemplate(payload));
  } catch (e) {
    console.log(e);
  } finally {
    history.push('/app/initalReportContainer');
  }
}

export function* updateIncidentBasics(api, { payload }) {
  try {
    yield put(removeMessage());

    const response = yield call(
      api.updateIncidentBasics,
      payload.id,
      payload.answers
    );

    yield put(updateIncidentBasicsResponse(response));
    yield put(addMessage({ error: false, message: 'Initial Report Updated!' }));
  } catch (e) {
    console.log(e);
  } finally {
    history.push('/app/incidentContainer');
  }
}

export function* updateIncident(api, { payload }) {
  try {
    yield put(removeMessage());
    const company = yield select(getActiveCompany);
    const locationId = yield select(getActiveLocationId);
    let project = yield select(getActiveProject);
    const activeIncident = yield select(getActiveIncidentSelector);
    const addedSignature = payload.signature;
    let addedAttachments = yield select(getIncidentAttachmentsSelector);
    const sigs = [];

    project =
      payload.answers && payload.answers.projectId
        ? payload.answers.projectId
        : project
        ? project._id
        : '';

    if (activeIncident && activeIncident._id) {
      const answers = payload.answers
        ? payload.answers
        : activeIncident.answers;

      const request = {
        ...activeIncident,
        answers: answers,
        reportComponent: payload.reportComponent,
        isCompleted: payload.isComplete
      };
      delete request._id;

      if (addedAttachments.length > 0) {
        // flatten the list in case of nested attachments
        //(side affect of uploaded multiple attachments at once)
        const flattenedAttachmentList = addedAttachments.reduce(
          (acc, x) => acc.concat(x),
          []
        );

        yield all(
          flattenedAttachmentList.map(attachment =>
            call(api.updateAttachment, {
              ...attachment,
              ownerId: activeIncident._id,
              ownerType: 'incident'
            })
          )
        );

        let hasPhotoEvidence = false;
        let hasDiagrams = false;

        flattenedAttachmentList.forEach(attachment => {
          if (attachment.isPhotoEvidence) {
            hasPhotoEvidence = true;
          } else if (attachment.original_filename.indexOf('diagram') > -1) {
            hasDiagrams = true;
          }
        });

        request.reportComponents.forEach(report => {
          if (report[0] === 'Photo Evidence') {
            report[5].isStarted = hasPhotoEvidence;
          } else if (report[0] === 'Diagrams') {
            report[5].isStarted = hasDiagrams;
          }
        });

        let component = request.reportComponents.find(
          c => c[0] === request.reportComponent
        );
        if (component && component[1] && component[1].subSections) {
          component[1].subSections.forEach(s =>
            s[1].forEach(field => {
              if (field.type === 'signature' && field.answer?._id) {
                sigs.push(field.answer);
                field.answer = field.answer.source_url;
              }
            })
          );
        }

        yield all(
          sigs.map(sig =>
            call(api.updateAttachment, {
              _id: sig._id,
              ownerType: 'incident',
              ownerId: payload._id
            })
          )
        );
      }

      const response = yield call(
        api.updateIncident,
        activeIncident._id,
        request
      );

      const froi =
        response.reportTemplateIds.indexOf('2') > -1 ||
        response.reportTemplateIds.indexOf('5') > -1;

      if (froi) {
        const oshaFormPayload = {
          incidentId: response._id,
          companyId: response.companyId
        };

        let oshaForm = yield call(api.fetchOshaForm, oshaFormPayload);

        if (oshaForm.length > 0) {
          oshaForm.map(
            form => (form.formAnswers = oshaQuestionMapper(form.questions))
          );
        }

        yield put(fetchOshaFormResponse(oshaForm));
      } else {
        yield put(fetchOshaFormResponse([]));
      }

      yield put(updateIncidentResponse(response));
      yield put(setActiveIncidentResponse(response));
      yield put(
        fetchIncidentAttachmentsResponse(response.incidentActivity.attachments)
      );
      yield put(addMessage({ error: false, message: 'Report Updated!' }));
      if (!payload.dontLeavePage)
        history.push(`/app/incidentContainer/${payload.stage}`);
    } else {
      const basics = yield select(getIncidentBasicsSelector);
      const reportTemplates = yield select(getActiveReportTemplatesSelector);

      const newPayload = dbIncidentDateTimeMapper(
        basics.sections,
        payload.answers
      );

      let incidentPayload = yield call(api.createIncident, company._id, {
        locationId: locationId ? locationId._id : payload.answers.locationId,
        companyId: company._id,
        answers: newPayload,
        projectId: project,
        reportTemplateIds: reportTemplates,
        signature: addedSignature._id
      });

      let newAttachments = [];

      if (addedAttachments.length > 0) {
        // flatten the list in case of nested attachments
        //(side affect of uploaded multiple attachments at once)
        const flattenedAttachmentList = addedAttachments.reduce(
          (acc, x) => acc.concat(x),
          []
        );

        const temp = yield all(
          flattenedAttachmentList.map(attachment =>
            call(api.updateAttachment, {
              ...attachment,
              ownerId: incidentPayload._id,
              ownerType: 'incident'
            })
          )
        );

        newAttachments = temp;
      }

      yield put(fetchIncidentAttachmentsResponse([...newAttachments]));

      const report = yield all(
        reportTemplates.map(reportTemplateId =>
          call(api.createReport, company._id, {
            locationId: incidentPayload.locationId,
            companyId: company._id,
            currentWfStage: 0,
            incidentId: incidentPayload._id,
            reportTemplateId: reportTemplateId,
            projectId: incidentPayload.projectId
          })
        )
      );

      incidentPayload = yield call(
        api.fetchIncidentById,
        company._id,
        incidentPayload._id
      );

      let hasPhotoEvidence = false;
      let hasDiagrams = false;
      newAttachments.forEach(attachment => {
        if (attachment.isPhotoEvidence) {
          hasPhotoEvidence = true;
        } else if (attachment.original_filename.indexOf('diagram') > -1) {
          hasDiagrams = true;
        }
      });

      incidentPayload.reportComponents.forEach(report => {
        if (report[0] === 'Photo Evidence') {
          report[5].isStarted = hasPhotoEvidence;
        } else if (report[0] === 'Diagrams') {
          report[5].isStarted = hasDiagrams;
        }
      });

      yield put(createReportResponse(report));
      yield put(setActiveIncidentResponse(incidentPayload));
      yield put(fetchOshaFormResponse([]));
      yield put(clearUploadedAttachments());
      history.push('/app/incidentContainer');
    }
  } catch (e) {
    console.log(e);
  }
}

export function* deleteIncident(api, { payload }) {
  try {
    yield call(api.deleteIncident, payload);

    history.push('/app/dashboard');
  } catch (e) {
    console.log(e);
  }
}

export function* addIncidentComment(api, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);
    const company = yield select(getActiveCompany);

    payload = {
      value: payload.name,
      ownerId: activeIncident._id,
      ownerType: 'incident'
    };

    yield call(api.addComment, activeIncident.companyId, payload);

    const incidentPayload = yield call(
      api.fetchIncidentById,
      company._id,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
  } catch (e) {
    console.log(e);
  }
}

export function* addIncidentNote(api, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);
    const company = yield select(getActiveCompany);

    payload = {
      value: payload.name,
      ownerId: activeIncident._id,
      ownerType: 'incident'
    };

    yield call(api.addNote, activeIncident.companyId, payload);

    const incidentPayload = yield call(
      api.fetchIncidentById,
      company._id,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
  } catch (e) {
    console.log(e);
  }
}

export function* updateIncidentComment(api, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);
    const company = yield select(getActiveCompany);

    yield call(api.updateComment, activeIncident.companyId, payload);

    const incidentPayload = yield call(
      api.fetchIncidentById,
      company._id,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
  } catch (e) {
    console.log(e);
  }
}

export function* updateIncidentNote(api, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);
    const company = yield select(getActiveCompany);

    yield call(api.updateNote, activeIncident.companyId, payload);

    const incidentPayload = yield call(
      api.fetchIncidentById,
      company._id,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteIncidentAttachment(api, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);
    let activeIncidentAttachments = yield select(
      getIncidentAttachmentsSelector
    );
    const company = yield select(getActiveCompany);

    yield call(api.deleteAttachment, payload._id);

    const incidentPayload = yield call(
      api.fetchIncidentById,
      company._id,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
    const attachmentIndex = activeIncidentAttachments.findIndex(
      attachment => attachment._id === payload._id
    );

    activeIncidentAttachments = [
      ...activeIncidentAttachments.slice(0, attachmentIndex),
      ...activeIncidentAttachments.slice(attachmentIndex + 1)
    ];
    yield put(fetchIncidentAttachmentsResponse(activeIncidentAttachments));
  } catch (e) {
    console.log(e);
  }
}

export function* emailIncidentAssignee(api, { payload }) {
  try {
    yield put(removeMessage());
    const response = yield call(api.emailIncidentAssignee, payload);

    yield put(emailIncidentAssigneeResponse(response));
    yield put(addMessage({ error: false, message: response }));
  } catch (e) {
    console.log(e);
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* recatagorizeReports(api, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);

    payload = payload.filter(
      templateId =>
        activeIncident.reportTemplateIds.indexOf(`${templateId}`) === -1
    );

    const report = yield all(
      payload.map(reportTemplateId =>
        call(api.createReport, activeIncident.companyId, {
          locationId: activeIncident.locationId,
          companyId: activeIncident.companyId,
          currentWfStage: 0,
          incidentId: activeIncident._id,
          reportTemplateId: reportTemplateId,
          projectId: activeIncident.projectId
        })
      )
    );

    activeIncident.reportTemplateIds = [
      ...activeIncident.reportTemplateIds,
      ...payload
    ];

    const response = yield call(
      api.updateIncident,
      activeIncident._id,
      activeIncident
    );

    yield put(updateIncidentResponse(response));
    yield put(setActiveIncidentResponse(response));
    yield put(
      fetchIncidentAttachmentsResponse(response.incidentActivity.attachments)
    );

    yield put(createReportResponse(report));
  } catch (e) {
    console.log(e);
  }
}
