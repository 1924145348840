import React, { Component } from 'react';
import ReactTable from 'react-table';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import Button from '../inputs/Button';
import Loading from '../Loading';
import { defaultFilterMethod } from '../../utils/sortFilterHelpers';

import styles from './listStyles.module.scss';

class PaginationButton extends Component {
  render() {
    return (
      <Button
        onClick={this.props.onClick}
        color="blue"
        text={this.props.children}
        inputClassName={styles.button}
        disabled={this.props.disabled}
      />
    );
  }
}

export class List extends Component {
  state = { columns: null };

  componentDidMount() {
    this.getColumns(this.props.settings);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.settings, prevProps.settings)) {
      this.getColumns(this.props.settings);
    }
  }

  resolveData = data =>
    data.map(row =>
      row.map((col, i) => {
        switch (this.props.settings[i].datatype) {
          case 'date':
            return moment(col);
          default:
            return col;
        }
      })
    );

  filterDate(filter, row) {
    const sinceWhen = moment();

    switch (filter.value) {
      case 'today':
        sinceWhen.startOf('day');
        break;
      case 'thisWeek':
        sinceWhen.startOf('week');
        break;
      case 'thisMonth':
        sinceWhen.startOf('month');
        break;
      case 'thisQuarter':
        sinceWhen.startOf('quarter');
        break;
      case 'thisYear':
        sinceWhen.startOf('year');
        break;
      case 'all':
        return true;
      default:
        break;
    }
    return row[filter.id] >= sinceWhen;
  }

  getColumns(settings) {
    const columns = (settings ?? []).map((t, i) => {
      let type = {
        Header: t.label,
        accessor: r => r[i],
        id: t.key
      };

      if (t.datatype === 'date') {
        type.Cell = ({ value }) => value?.format?.('MM/DD/YYYY hh:mm a') ?? '';
        type.filterMethod = this.filterDate;
        type.Filter = ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            className={styles.dateFilter}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value="today">Today</option>
            <option value="thisWeek">This Week</option>
            <option value="thisMonth">This Month</option>
            <option value="thisQuarter">This Quarter</option>
            <option value="thisYear">This Year</option>
          </select>
        );
      }

      return type;
    });

    this.setState({ columns });
  }

  getTdProps = (f, rowInfo, column) => {
    if (!rowInfo?.row || !column?.id) return {};

    return { title: rowInfo.row[column.id] };
  }

  render() {
    if (!this.props.data || !this.state.columns) return <Loading />;

    return (
      <ReactTable
        data={this.props.data}
        filterable
        defaultFilterMethod={defaultFilterMethod}
        resolveData={this.resolveData}
        columns={this.state.columns}
        PreviousComponent={PaginationButton}
        NextComponent={PaginationButton}
        getPaginationProps={() => ({ className: styles.pagination })}
        getTdProps={this.getTdProps}
      />
    );
  }
}

export default List;
