import React, { Component } from 'react';
import {
  FIELD_TYPES_CUSTOMIZABLE,
  FIELD_TYPES_OPTIONS
} from '../../../constants/constants';
import reportTemplateMapper from '../../../utils/reportTemplateMapper';
import Button from '../../inputs/Button';
import Card from '../../Card';
import Checkbox from '../../inputs/Checkbox';
import Dropdown from '../../inputs/Dropdown';
import Modal from '../../Modal';
import Textbox from '../../inputs/Textbox';
import { InputRow } from '../../inputs';
import HoverImage from '../../HoverImage';
import { classnames } from '../../../utils/classNamesHelper';

import styles from './customReportQuestion.module.scss';

export default class CustomReportQuestionsCard extends Component {
  state = {
    dragIdx: null,
    dragTargetIdx: null,
    modalOpen: false,
    activeOptions: [],
    rowIndex: 0
  };

  onDragStart = (e, index) => {
    this.setState({
      dragIdx: index,
      dragTargetIdx: null
    });
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/plain', index);
  };

  onDragOver = (e, index) => {
    if (e.preventDefault) e.preventDefault();
    this.setState({ dragTargetIdx: index });
  };

  onDragLeave = (e, index) => {
    this.setState({ dragTargetIdx: null });
  };

  onDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (
      this.state.dragIdx === this.state.dragTargetIdx ||
      this.state.dragTargetIdx === null
    ) {
      this.setState({
        dragIdx: null,
        dragTargetIdx: null
      });
      return;
    }

    let fields = [...this.props.fields];
    const tmp = fields[this.state.dragIdx];
    fields[this.state.dragIdx] = fields[this.state.dragTargetIdx];
    fields[this.state.dragTargetIdx] = tmp;

    this.props.handleChange({ fields });
    this.setState({
      dragIdx: null,
      dragTargetIdx: null
    });
  };

  handleCheckboxChange = (index, field, answer) => {
    let fields = [...this.props.fields];
    fields[index][field] = answer;

    this.props.handleChange({ fields });
  };

  handleQuestionChange = (index, questionLabel) => {
    let fields = [...this.props.fields];
    fields[index][questionLabel.field] = questionLabel.answer;

    this.setState({
      rowIndex: null,
      modalOpen: false
    });
    this.props.handleChange({ fields });
  };

  addRowsToTemplate = numberOfRows => {
    const fields = [...this.props.fields];

    for (let i = 1; i <= numberOfRows; i++) {
      fields.push({
        type: '',
        label: '',
        options: [],
        showNotApplicable: false,
        required: false,
        hide: false,
        legacy: false,
        subSection: 'Custom Questions'
      });
    }

    this.props.handleChange({ fields });
  };

  removeRowFromTemplate = index => {
    let fields = [...this.props.fields];
    fields.splice(index, 1);

    this.props.handleChange({ fields });
  };

  setChangeOptions = (options, rowIndex) => {
    options = options.map(option => {
      if (option.option) return option;
      return {
        option,
        isEditable: false
      };
    });

    this.setState({ modalOpen: true, rowIndex, activeOptions: options });
  };

  render() {
    const { modalOpen, activeOptions, rowIndex } = this.state;
    const { fields, disabled, activeTemplate } = this.props;

    const tooltip =
      'Existing questions cannot have their properties changed, due to data integrity issues.  If you need to change the properties, you must delete and re-add the question.';

    const footer = !disabled && (
      <InputRow className={styles.footer}>
        <Button
          text="Add Row"
          color="blue"
          onClick={() => this.addRowsToTemplate(1)}
        />
        <Button
          text="Add Five Rows"
          color="blue"
          onClick={() => this.addRowsToTemplate(5)}
        />
      </InputRow>
    );

    return (
      <>
        <Card
          showHeader
          title={`${
            reportTemplateMapper(activeTemplate.reportTemplateNumber).report
          } - ${activeTemplate.reportComponentName}`}
        >
          <ul className={styles.fields}>
            {fields.map((row, index) => {
              if (row.legacy) return <></>;
              const notEditable = '_id' in row;
              return (
                <li
                  key={index}
                  onDragOver={event => this.onDragOver(event, index)}
                  onDragLeave={event => this.onDragLeave(event, index)}
                  onDrop={event => this.onDrop(event, index)}
                >
                  <div
                    className={classnames(
                      styles.item,
                      disabled && styles.disabled,
                      this.state.dragTargetIdx === index &&
                        this.state.dragIdx !== index &&
                        styles.target
                    )}
                    draggable={!disabled}
                    onDragStart={event => this.onDragStart(event, index)}
                  >
                    <img
                      src={require('../../../assets/images/drag.png')}
                      alt="drag to new position"
                      className={styles.dragImage}
                    />
                    <div className={styles.grow}>
                      <Textbox
                        currentValue={row.label}
                        handleChange={e =>
                          this.handleQuestionChange(index, {
                            field: 'label',
                            answer: e.target.value
                          })
                        }
                        fieldLabel="Question"
                        type="textarea"
                        placeholder="Type your question here (e.g. Who fell?)"
                        isRequired
                        disabled={disabled}
                        name={`question-${index}`}
                      />
                      <Checkbox
                        onChange={v =>
                          this.handleCheckboxChange(index, 'required', v)
                        }
                        currentValue={row.required}
                        disabled={disabled || notEditable}
                        fieldLabel="Required"
                        displayTooltip={!disabled && notEditable}
                        name={`required-${index}`}
                        tooltip={tooltip}
                      />
                      <Dropdown
                        options={FIELD_TYPES_CUSTOMIZABLE}
                        fieldLabel="Type of Input"
                        handleChange={values =>
                          this.handleQuestionChange(index, {
                            field: 'type',
                            answer: values
                          })
                        }
                        isRequired
                        placeholder="Choose a question type (e.g. Textbox, Dropdown)"
                        currentValue={row.type}
                        disabled={disabled || notEditable}
                        displayTooltip={!disabled && notEditable}
                        name={`type-${index}`}
                        tooltip={tooltip}
                      />
                      <Checkbox
                        onChange={v =>
                          this.handleCheckboxChange(
                            index,
                            'showNotApplicable',
                            v
                          )
                        }
                        currentValue={row.showNotApplicable}
                        disabled={disabled || notEditable}
                        fieldLabel="Add Not Applicable Checkbox"
                        name={`notApplicable-${index}`}
                        displayTooltip={!disabled && notEditable}
                      >
                        {tooltip}
                      </Checkbox>
                      {(FIELD_TYPES_OPTIONS.includes(row.type.value) ||
                        FIELD_TYPES_OPTIONS.includes(row.type)) && (
                        <Button
                          text="View Options"
                          color="blue"
                          onClick={() =>
                            this.setChangeOptions(row.options, index)
                          }
                          disabled={disabled}
                        />
                      )}
                      {(FIELD_TYPES_OPTIONS.includes(row.type.value) ||
                        FIELD_TYPES_OPTIONS.includes(row.type)) &&
                        row.options.length === 0 && (
                          <div style={{ color: '#c74846', fontWeight: 'bold' }}>
                            * Must add at least one option
                          </div>
                        )}
                    </div>
                    <HoverImage
                      src={require('../../../assets/images/removePermissions.png')}
                      srcHover={require('../../../assets/images/removePermissonsHover.png')}
                      alt="remove row"
                      onClick={() => this.removeRowFromTemplate(index)}
                      className={styles.removeImage}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          {footer}
        </Card>
        <Modal
          title="Change Options"
          titleClassName="blueHeader"
          isOpen={modalOpen}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ modalOpen: false, activeOptions: [] })
          }
          submitActions={() =>
            this.handleQuestionChange(rowIndex, {
              field: 'options',
              answer: activeOptions
            })
          }
        >
          <div>
            {activeOptions.map((option, index) => (
              <div className={styles.options} key={index}>
                <Textbox
                  currentValue={option.option}
                  handleChange={e => {
                    const currentOptions = activeOptions;
                    currentOptions[index].option = e.target.value;
                    this.setState({ activeOptions: currentOptions });
                  }}
                  placeholder="Type your option here."
                  disabled={!option.isEditable}
                  className={styles.option}
                />
                {option.isEditable && (
                  <HoverImage
                    className={styles.removeImage}
                    onClick={() =>
                      this.setState({
                        activeOptions: [
                          ...activeOptions.slice(0, index),
                          ...activeOptions.slice(index + 1)
                        ]
                      })
                    }
                    src={require('../../../assets/images/removePermissions.png')}
                    srcHover={require('../../../assets/images/removePermissonsHover.png')}
                  />
                )}
              </div>
            ))}
            <Button
              text="Add Option"
              color="blue"
              onClick={() =>
                this.setState({
                  activeOptions: [
                    ...activeOptions,
                    { option: '', isEditable: true }
                  ]
                })
              }
            />
          </div>
        </Modal>
      </>
    );
  }
}
