import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import UserBadge from '../UserBadge';

import './index.css';

const handleLogoClick = (
  setActiveCompany,
  setActiveLocation,
  allLocations,
  setActiveGroups,
  setActiveProject,
  user,
  company
) => {
  setActiveCompany(company);
  setActiveLocation(0);
  setActiveGroups(company.groups);
  allLocations(company.allLocations);
  setActiveProject();
};

const CompanyLogo = ({
  imageSource,
  altText,
  company,
  setActiveCompany,
  setActiveLocation,
  allLocations,
  setActiveGroups,
  setActiveProject,
  user,
  assignIncidentOwner
}) => (
  <div>
    {user.accessLevel !== '900' ? (
      <div
        className="companyLogo companyLogo-noAccess"
        data-for={`${company._id}`}
        data-tip="customTheme"
      >
        {imageSource && (
          <img
            src={imageSource}
            alt={`${altText}'s Logo`}
            className="companyLogoImage"
          />
        )}
        <div className="companyLogo-company companyLogo-noAccess">
          <UserBadge
            size="medium"
            userId={company?.defaultIncidentOwner?.userId}
            showImage
            hoverText
            className="companyLogoHover"
          />
          <div className="companyLogo-companyDiv">
            <div className="companyLogoText">{altText}</div>
          </div>
        </div>
        <ReactTooltip className="customTheme" id={`${company._id}`}>
          *Permission Restricted to lower levels
        </ReactTooltip>
      </div>
    ) : (
      <Link
        to={{
          pathname: '/app/dashboard'
        }}
        className="companyLogo"
        onClick={() =>
          handleLogoClick(
            setActiveCompany,
            setActiveLocation,
            allLocations,
            setActiveGroups,
            setActiveProject,
            user,
            company
          )
        }
        data-test={`${company._id}`}
      >
        {imageSource && (
          <img
            src={imageSource}
            alt={`${altText}'s Logo`}
            className="companyLogoImage"
          />
        )}
        <div className="companyLogo-company">
          <UserBadge
            userId={company.defaultIncidentOwner?.userId}
            size="medium"
            showImage
            hoverText
            className="companyLogoHover"
            onClick={e => {
              e.preventDefault && e.preventDefault();
              e.stopPropagation && e.stopPropagation();
              assignIncidentOwner(
                company.defaultIncidentOwner?.userId ?? ''
              );
            }}
          />
          <div className="companyLogo-companyDiv">
            {altText}
          </div>
          <div className="companyLogo-companyDivHover">
            <div className="companyLogoText">
              {company.defaultIncidentOwner ? 'Reassign ' : 'Assign '}Incident
              Owner
            </div>
          </div>
        </div>
      </Link>
    )}
  </div>
);
export default CompanyLogo;
