import React, { Component } from 'react';

import UserBadge from '../../components/UserBadge';
import { classnames } from '../../utils/classNamesHelper';

import styles from './assignee.module.scss';

export class Assignee extends Component {
  state = { showOptions: false };

  render() {
    const { user } = this.props;
    const options = this.props.options || [];
    const text = this.props.text || 'Assignee';

    const { showOptions } = this.state;

    const classes = [
      this.props.className,
      showOptions && styles.shown,
      styles.assignee,
      options.length > 0 && styles.hasOptions
    ];

    return (
      <div className={classnames(classes)}>
        <div onClick={() => this.setState({ showOptions: !showOptions })}>
          <UserBadge
            userId={user}
            showImage
            showName
            size="medium"
            byline={text}
            className={styles.profileBadge}
          />
          {options.length ? (
            <div className={styles.dropdown}>
              <img
                src={
                  showOptions
                    ? require('../../assets/images/Chevron.png')
                    : require('../../assets/images/downChevron.png')
                }
                alt={showOptions ? 'dropdown open' : 'dropdown closed'}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        {options.length ? (
          <ul>
            {options.map((o, i) => {
              if ('visible' in o && !o.visible) return <></>;
              return (
                <li
                  onClick={() =>
                    this.setState(
                      { showOptions: false },
                      o.onClick || (() => {})
                    )
                  }
                  key={i}
                >
                  <div>{o.label}</div>
                </li>
              );
            })}
          </ul>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default Assignee;
