import moment from 'moment';

const expirationMapper = expirationDate => {
  const currentDate = moment();

  if (currentDate.diff(expirationDate, 'months') >= 0) {
    return require(`../assets/images/severityred.png`);
  } else if (
    currentDate.diff(expirationDate, 'months') < 0 &&
    currentDate.diff(expirationDate, 'months') >= -2
  ) {
    return require(`../assets/images/severityyellow.png`);
  }

  return require(`../assets/images/severitygreen.png`);
};

export default expirationMapper;
