import React, { Component } from 'react';
import Modal from '.';

import styles from './styles.module.scss';

export class ApprovalItemModal extends Component {
  render() {
    return (
      <Modal
        title={`Approve ${this.props.approvingWhat}`}
        titleClassName="greenHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="green"
        submitButtonText="Approve Training"
        onRequestClose={this.props.onRequestClose}
        cancelButtonText="Later"
        submitActions={this.props.submitActions}
      >
        <div className={styles.text}>
          Once approved, all approved employees will receive an email that the{' '}
          {this.props.approvingWhat} was approved and they can find the{' '}
          {this.props.approvingWhat} under My Documents.
          <span className={styles.warningText}>
            For all employees that were rejected, the creator of the{' '}
            {this.props.approvingWhat} will receive an email letting them know.
            If you want to re-assign a {this.props.approvingWhat} to the
            employees that failed, please create a new{' '}
            {this.props.approvingWhat}.
          </span>
        </div>
      </Modal>
    );
  }
}

export default ApprovalItemModal;
