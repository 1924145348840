import {
  FETCH_COMPANY_INCIDENT_ANALYTICS_REQUEST,
  FETCH_COMPANY_INCIDENT_ANALYTICS_RESPONSE,
  FETCH_COMPANY_MANAGEMENT_ANALYTICS_REQUEST,
  FETCH_COMPANY_MANAGEMENT_ANALYTICS_RESPONSE
} from '../constants/actionTypes';

export const fetchCompanyManagementAnalyticsRequest = payload => ({
  type: FETCH_COMPANY_MANAGEMENT_ANALYTICS_REQUEST,
  payload
});

export const fetchCompanyManagementAnalyticsResponse = payload => ({
  type: FETCH_COMPANY_MANAGEMENT_ANALYTICS_RESPONSE,
  payload
});

export const fetchCompanyIncidentAnalyticsRequest = payload => ({
  type: FETCH_COMPANY_INCIDENT_ANALYTICS_REQUEST,
  payload
});

export const fetchCompanyIncidentAnalyticsResponse = payload => ({
  type: FETCH_COMPANY_INCIDENT_ANALYTICS_RESPONSE,
  payload
});
