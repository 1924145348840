export const severityMapper = severity => {
  switch (severity) {
    case 0:
    case 1:
    case '0':
    case '1':
    case '0- Immediately Corrected':
    case '1- Advisory':
      return require(`../assets/images/severitygreen.png`);

    case 2:
    case 3:
    case '2':
    case '3':
    case '2- Minor':
    case '3- Moderate':
      return require(`../assets/images/severityyellow.png`);

    case 4:
    case 5:
    case '4':
    case '5':
    case '4- Serious':
    case '5- Critical':
      return require(`../assets/images/severityred.png`);
    default:
      return require(`../assets/images/severitygreen.png`);
  }
};

export const severityColorMapper = severity => {
  switch (severity) {
    case 0:
    case 1:
    case '0':
    case '1':
    case '0- Immediately Corrected':
    case '1- Advisory':
    case 'green':
      return 'green';

    case 2:
    case 3:
    case '2':
    case '3':
    case '2- Minor':
    case '3- Moderate':
    case 'yellow':
      return 'yellow';

    case 4:
    case 5:
    case '4':
    case '5':
    case '4- Serious':
    case '5- Critical':
    case 'red':
      return 'red';

    default:
      return 'green';
  }
};
