import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

import '../input.css';
import './index.css';

export default class AutoComplete extends Component {
  state = { suggestions: [] };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.props.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { suggestions } = this.state;
    const {
      placeholder,
      fieldLabel,
      isRequired,
      renderSuggestion,
      getSuggestionValue,
      className,
      currentValue,
      handleChange
    } = this.props;

    const inputProps = {
      placeholder: placeholder,
      value: currentValue,
      onChange: handleChange
    };

    return (
      <div className={`autocomplete input ${className}`}>
        <label className="label">
          {isRequired ? <span className="isRequired">*</span> : ''}
          {fieldLabel}
        </label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}
