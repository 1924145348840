import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import {
  addJsaAttachmentsRequest,
  addJsaNotesRequest,
  deleteJsaQuestionAttachment,
  deleteJsaRequest,
  setActiveJsaResponse,
  updateJsaRequest
} from '../../actions/jsa';
import { updateLeftNavRequest } from '../../actions/navigation';
import { setActiveTaskRequest } from '../../actions/tasks';
import { getActiveJsaSelector } from '../../selectors/jsa';
import { getLoggedInUser } from '../../selectors/users';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import RaSummaryCard from '../../components/raCards/RaSummaryCard';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import DeleteItemModal from '../../components/Modal/deleteItemModal';
import SectionSummaryCard from '../../components/raCards/SectionSummaryCard';
import LeftNav from '../../components/LeftNav';
import StageSelector from '../../components/StageSelector';

import styles from './styles.module.scss';

export class RaSummaryContainer extends Component {
  state = {
    openUnsavedChangesModal: false,
    openDeleteModal: false,
    openSaveModal: false,
    hasUnsavedChanges: false,
    sectionNotes: ''
  };

  componentDidMount() {
    this.props.updateLeftNav('Risk Assessments');
  }

  updateRaStage = async stage => {
    await this.props.updateRa({
      ...this.props.activeRa,
      currentWfStage: stage.toString()
    });
  };

  deleteRa = () => {
    this.props.deleteRa(this.props.activeRa._id);

    this.handleBack();
  };

  updateAnswer = (questionId, answer, index) => {
    const { activeRa } = this.props;

    activeRa.sections[index].questions.forEach(question => {
      if (question._id === questionId) {
        question.answer = answer;
      }
    });

    this.props.setActiveJsa({ ...activeRa });

    this.setState({ hasUnsavedChanges: true });
  };

  updateRa = isLocked => {
    this.props.updateRa({
      ...this.props.activeRa,
      isLocked,
      sectionNotes: this.state.sectionNotes
    });

    this.setState({ openSaveModal: false, hasUnsavedChanges: false });
  };

  handleTask = values => {
    this.props.setActiveJsa({ ...this.props.activeRa });
    this.props.setActiveTask(values);
  };

  handleBack = () => {
    const { activeRa, loggedInUser } = this.props;

    if (loggedInUser.accessLevel === '100') {
      history.push('/app/dashboard');
      return;
    }

    activeRa.backToDashboard
      ? history.push('/app/dashboard')
      : history.push('/app/raStages');
  };

  updateSectionNotes = (note, sectionId) => {
    const { activeRa, setActiveJsa } = this.props;

    activeRa.sections[sectionId].sectionNotes = note;

    setActiveJsa(activeRa);
  };

  render() {
    const {
      activeRa,
      addRaNote,
      addRaAttachment,
      loggedInUser,
      deleteJsaQuestionAttachment
    } = this.props;

    const {
      openUnsavedChangesModal,
      openSaveModal,
      hasUnsavedChanges
    } = this.state;

    const leftNav = activeRa.sections?.length
      ? activeRa.sections.map((section, i) => ({
          label: section.sectionName,
          id: i
        }))
      : [];

    const accessLevel = parseInt(loggedInUser?.accessLevel || 0, 10);

    const header = (
      <Header
        title="Risk Assessments"
        section={activeRa.isLocked ? 'Summary' : 'Perform Assessment'}
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleBack()
        }
        rightButtons={[
          {
            text: 'Edit Risk Assessment',
            onClick: () => history.push('/app/raContainer'),
            color: 'blue',
            visible:
              loggedInUser._id === activeRa.createdByUserId &&
              !activeRa.isLocked
          },
          {
            text: 'Delete Risk Assessment',
            onClick: () => this.setState({ openDeleteModal: true }),
            color: 'red',
            visible:
              (loggedInUser._id === activeRa.createdByUserId ||
                accessLevel === 900) &&
              activeRa.isLocked
          }
        ]}
        center={
          <StageSelector
            label="RA Stage"
            options={
              activeRa.isLocked
                ? [
                    { label: 'Severity Review', value: 2 },
                    { label: 'Closed', value: 3 }
                  ]
                : [
                    { label: 'New', value: 0 },
                    { label: 'In Progress', value: 1 },
                    { label: 'Severity Review', value: 2 },
                    { label: 'Closed', value: 3 }
                  ]
            }
            currentValue={parseInt(activeRa.currentWfStage, 10)}
            onChange={option => this.updateRaStage(option.value)}
          />
        }
      />
    );

    const footer = (
      <SaveCancelFooter
        editing
        saveButtonClick={() => this.setState({ openSaveModal: true })}
        cancelButtonClick={() => this.handleBack()}
      />
    );

    const left = <LeftNav items={leftNav} />;

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={left}
          showFooter={!activeRa.isLocked}
        >
          {activeRa.isLocked && (
            <div className={styles.lockedRa}>
              <img
                src={require('../../assets/images/reportLock.png')}
                alt="Ras are locked"
              />
            </div>
          )}
          <RaSummaryCard
            activeRa={activeRa}
            loggedInUser={loggedInUser}
          />
          {activeRa.sections &&
            activeRa.sections.map((section, index) => (
              <SectionSummaryCard
                activeSection={section}
                key={index}
                setActiveTask={values => this.handleTask(values)}
                name={index}
                addRaNote={addRaNote}
                addRaAttachment={addRaAttachment}
                updateAnswer={(questionId, value) =>
                  this.updateAnswer(questionId, value, index)
                }
                updateSectionNotes={values =>
                  this.updateSectionNotes(values, index)
                }
                activeRa={activeRa}
                deleteJsaQuestionAttachment={deleteJsaQuestionAttachment}
              />
            ))}
        </HeaderAndFooter>
        <DeleteItemModal
          isOpen={this.state.openDeleteModal}
          onRequestClose={() => this.setState({ openDeleteModal: false })}
          submitActions={() => this.deleteRa()}
          deletingWhat="Risk Assessment"
        />
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleBack()}
          savingWhat="Risk Assessment"
        />
        <Modal
          title="Submit Risk Assessment"
          titleClassName="greenHeader"
          isOpen={openSaveModal}
          submitButtonColor="green"
          submitButtonText="Submit Now"
          submitActions={() => this.updateRa(true)}
          cancelButtonText="Later"
          cancelActions={() => this.updateRa(false)}
          onRequestClose={() => this.setState({ openSaveModal: false })}
        >
          <div className="reportSectionContainer-emailAssigeeText">
            Submitting this risk assessment will lock it and prevent any further
            editing. Tasks may still be added.
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  activeRa: getActiveJsaSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: values => dispatch(updateLeftNavRequest(values)),
  updateRa: values => dispatch(updateJsaRequest(values)),
  deleteRa: values => dispatch(deleteJsaRequest(values)),
  setActiveTask: values => dispatch(setActiveTaskRequest(values)),
  addRaNote: values => dispatch(addJsaNotesRequest(values)),
  addRaAttachment: values => dispatch(addJsaAttachmentsRequest(values)),
  setActiveJsa: values => dispatch(setActiveJsaResponse(values)),
  deleteJsaQuestionAttachment: values =>
    dispatch(deleteJsaQuestionAttachment(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(RaSummaryContainer);
