import React from 'react';

import { Input, nameInput } from '../';

import './index.css';
import styles from './styles.module.scss';

const Button = ({
  text,
  color,
  inputClassName,
  type,
  badge,
  image,
  name,
  onClick,
  onMouseDown,
  disabled,
  shrink,
  ...props
}) => {
  type = type || 'button';
  let buttonClasses = `button ${inputClassName}`;
  if (color) buttonClasses = `${styles.button} ${styles[color]}`;
  if (shrink) buttonClasses += ` ${styles.shrink}`;
  return (
    <Input name={name} disabled={disabled} inline={shrink} {...props}>
      <button
        className={buttonClasses}
        type={type}
        id={name}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        {badge ? <span className="badge1" /> : ''}
        {image ? <img src={image} className={styles.image} alt="Button" /> : ''}
        <div className="button-text">{text}</div>
      </button>
    </Input>
  );
};
export default nameInput(Button);
