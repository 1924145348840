import React, { Component } from 'react';
import history from '../../history';

import UserBadge from '../UserBadge';
import { TwoColumn } from '../inputs';
import Button from '../inputs/Button';
import Modal from '../Modal';

import './index.css';

export const getClassName = (isLongTerm, user) => {
  let classes = '';
  classes = isLongTerm ? 'longTermLocation' : 'location';

  classes +=
    user.accessLevel === '400' && user.projectIds && user.projectIds.length > 0
      ? ' location--noAccess'
      : '';

  return classes;
};

export default class CompanyCard extends Component {
  state = {
    modalOpen: false,
    projects: []
  };

  componentDidMount() {
    this.setState({ projects: this.props.projects });
  }

  handleDivClick = async (
    event,
    company,
    location,
    setActiveCompany,
    setActiveLocation,
    allLocations,
    setActiveGroups,
    setActiveProject,
    user
  ) => {
    event.stopPropagation();
    if (
      user.accessLevel === '400' &&
      location.projects.filter(project => user.projectIds.includes(project._id))
        .length > 0
    ) {
      this.setState({ modalOpen: true });
    } else {
      setActiveCompany(company);
      setActiveLocation({
        ...location
      });
      setActiveProject();
      allLocations(company.allLocations);
      setActiveGroups(company.allLocations);
      history.push('/app/dashboard');
    }
  };

  handleInnerDivClick = (event, openProjectModal) => {
    event.stopPropagation();
    openProjectModal();
  };

  openModal = e => {
    e.stopPropagation();
    this.setState({ modalOpen: true });
  };

  closeModal = e => {
    e.stopPropagation();
    this.setState({ modalOpen: false });
  };

  render() {
    const {
      company,
      location,
      setActiveCompany,
      setActiveLocation,
      allLocations,
      setActiveGroups,
      openProjectModal,
      openProjectDropdown,
      setActiveProject,
      handleProjectSelection,
      user,
      assignIncidentOwner
    } = this.props;

    const { modalOpen, projects } = this.state;
    return (
      <>
        <div
          className={`location-button ${getClassName(
            location.isSeparateEstablishment &&
              location.operationalForYearOrLonger,
            user
          )}`}
          onClick={e =>
            this.handleDivClick(
              e,
              company,
              location,
              setActiveCompany,
              setActiveLocation,
              allLocations,
              setActiveGroups,
              setActiveProject,
              user
            )
          }
        >
          <UserBadge
            showImage
            hoverName
            userId={location?.defaultIncidentOwner?.userId}
            size="medium"
            className={
              location.isSeparateEstablishment &&
              location.operationalForYearOrLonger
                ? 'locationIncidentOwnerPictureWhite'
                : 'locationIncidentOwnerPictureBlue'
            }
            onClick={e => {
              e.stopPropagation();
              assignIncidentOwner(location?.defaultIncidentOwner?.userId || undefined);
            }}
          />
          <div className="assignIncidentOwner">
            Assign Incident Owner
          </div>
          <div className="locationName">{location.name}</div>
          {user.accessLevel === '400' &&
            user.projectIds &&
            location.projects.filter(project =>
              user.projectIds.includes(project._id)
            ).length > 0 && (
              <div
                className={
                  location.isSeparateEstablishment &&
                  location.operationalForYearOrLonger
                    ? 'location-noAccess--longTerm'
                    : 'location-noAccess--shortTerm'
                }
              >
                *Permission Restricted to Projects
              </div>
            )}
          {projects && projects.length > 0 ? (
            <div
              className={
                location.isSeparateEstablishment &&
                location.operationalForYearOrLonger
                  ? 'location-existingProjects white'
                  : 'location-existingProjects blue'
              }
              onClick={e => this.openModal(e)}
            >
              <div
                className={
                  location.isSeparateEstablishment &&
                  location.operationalForYearOrLonger
                    ? 'location-existingProjects--blueWords'
                    : 'location-existingProjects--whiteWords'
                }
              >
                Projects
              </div>
            </div>
          ) : (
            <div>
              {(user.accessLevel === '500' || user.accessLevel === '900') && (
                <div
                  className="location-newProject"
                  onMouseDown={e => this.handleInnerDivClick(e, openProjectModal)}
                >
                  <div
                    className={
                      location.isSeparateEstablishment &&
                      location.operationalForYearOrLonger
                        ? 'location-newProject--whiteWords'
                        : 'location-newProject--blueWords'
                    }
                  >
                    Add First Project
                  </div>
                  <img
                    src={
                      location.isSeparateEstablishment &&
                      location.operationalForYearOrLonger
                        ? require('../../assets/images/newProject-white.png')
                        : require('../../assets/images/newProject-blue.png')
                    }
                    alt="boxWarning"
                    className="location-newProject--addImage"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          title={`${location.name}'s Projects`}
          titleClassName="blueHeader"
          isOpen={modalOpen}
          submitButtonColor="red"
          submitButtonText="Delete Incident"
          onRequestClose={e => this.closeModal(e)}
          hideButtons={true}
        >
          <div className="projectModal">
            <TwoColumn className="projectButtons">
              {projects.map((project, index) => {
                if (project.isHidden) return <></>;
                return (
                  <Button
                    key={index}
                    className="projectButton"
                    color="blueOutline"
                    text={project.name}
                    onClick={async () => {
                      await openProjectDropdown();
                      handleProjectSelection(project);
                    }}
                  />
                );
              })}
              {(user.accessLevel === '500' || user.accessLevel === '900') && (
                <Button
                  color="grey"
                  onClick={openProjectModal}
                  text="Add New Project"
                />
              )}
            </TwoColumn>
          </div>
        </Modal>
      </>
    );
  }
}
