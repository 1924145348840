import React from 'react';
import ReactLoading from 'react-loading';
import { Field, reduxForm } from 'redux-form';

import { renderField, required } from '../../utils/formValidation';
import Widget from '../../components/Widget';
import Button from '../../components/inputs/Button';
import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';

const LoginForm = props => {
  const { handleSubmit, isLoading, setLoginPage, onMicrosoftSubmit } = props;
  return (
    <form className="loginForm" onSubmit={handleSubmit}>
      <Widget className="widget">
        <Field
          name="username"
          type="text"
          component={renderField}
          label="Username:"
          validate={[required]}
          placeholder="usually your email, phone number or employee id"
        />
        <Field
          name="password"
          type="password"
          component={renderField}
          label="Password:"
          validate={[required]}
          placeholder=""
        />
        <p className="loginForm--forgotPassword">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={() => setLoginPage('forgotPassword')}>
            Forgot your password?
          </a>
        </p>
        <Button
          type="submit"
          color="green"
          text={
            isLoading ? (
              <ReactLoading
                type="spinningBubbles"
                color="#FFF"
                className="loading"
                height="45px"
                width="45px"
              />
            ) : (
              'Login'
            )
          }
        />
        <Button
          color="white"
          image={require('../../assets/images/microsoft logo.png')}
          text="Sign in with Microsoft"
          className="microsoft-login-button"
          onClick={onMicrosoftSubmit}
        />
      </Widget>
    </form>
  );
};

export default reduxForm({
  form: 'LoginForm'
})(LoginForm);
