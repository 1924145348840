import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { clearUploadedAttachments } from '../../actions/attachments';
import { signOut } from '../../actions/auth';
import {
  addDocumentFolderRequest,
  fetchDailyReportsRequest,
  fetchQualificationRequest,
  fetchRequireAuthRequest,
  fetchSafetyInstructionRequest,
  fetchSdsRequest,
  fetchToolboxTalksRequest,
  setActiveDocumentFolderRequest
} from '../../actions/documents';
import {
  fetchIncidentAttachmentsResponse,
  fetchIncidentBasicsRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import {
  fetchJsaSectionsRequest,
  setActiveJsaResponse
} from '../../actions/jsa';
import { setActiveSafetyWalk } from '../../actions/safetyWalks';
import { fetchTrainingsRequest } from '../../actions/training';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getDocumentFoldersSelector,
  getRequireAuthDocsSelector
} from '../../selectors/documents';
import { getLeftNavigationSelector } from '../../selectors/navigation';
import { getLoggedInUser } from '../../selectors/users';

import Button from '../../components/inputs/Button';
import LeftNavSection from '../../components/LeftNavSection/';
import Modal from '../../components/Modal';
import UserBadge from '../../components/UserBadge';
import ReportSelector from '../../components/inputs/ReportSelector';

import './index.css';

export class LeftNav extends Component {
  state = {
    sectionClickedOn: '',
    modalOpen: false,
    reportTemplates: []
  };

  componentDidMount() {
    this.props.fetchRequiredAuth();
  }

  handleOpenModal = () => {
    this.setState({
      modalOpen: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      reportTemplates: []
    });
  };

  trackNewReportCreation = async () => {
    this.state.reportTemplates.forEach(report => {
      if (report !== 8 && report !== 10) {
      }
    });
  };

  handleSubmit = () => {
    this.props.setJsa();
    this.props.setIncident();
    this.props.setSafetyWalk();
    this.props.fetchIncidentAttachments();
    this.props.clearAttachments();
    this.handleCloseModal();

    this.trackNewReportCreation();

    if (this.state.reportTemplates.indexOf(8) > -1) {
      history.push('/app/raContainer');
    } else if (this.state.reportTemplates.indexOf(10) > -1) {
      history.push('/app/safetyWalkContainer');
    } else {
      this.props.fetchIncidentBasics(this.state.reportTemplates);
    }
  };

  handleSectionOpen = section => {
    this.setState(section);
  };

  render() {
    const {
      leftNavigation,
      loggedInUser,
      activeCompany,
      activeLocation,
      activeProject,
      documentFolders,
      addDocumentFolder,
      setActiveFolder,
      fetchSds,
      fetchDailyReports,
      fetchQualifications,
      fetchSafetyInstructions,
      fetchToolboxTalks,
      showLeftNav,
      sectionOpen,
      logOut,
      requiredAuthDocs,
      fetchTrainings
    } = this.props;

    const { sectionClickedOn, modalOpen, reportTemplates } = this.state;

    return (
      <div className={showLeftNav ? 'sidebar' : 'hideSidebar'}>
        <aside
          className={showLeftNav ? 'sidebar-sections' : 'hideSidebar-sections'}
        >
          <Button
            text="New Report"
            className="greenButton"
            onClick={() => this.handleOpenModal()}
          />
          {leftNavigation.sections.map((navSection, index) => {
            return navSection.accessLevel.indexOf(loggedInUser.accessLevel) !==
              -1 ? (
              <LeftNavSection
                title={navSection.title}
                section={navSection.section}
                key={index}
                accessLevel={loggedInUser.accessLevel}
                sectionOpen={
                  parseInt(sectionClickedOn) >= 0
                    ? sectionClickedOn === index
                    : sectionOpen === index.toString()
                }
                openNewSection={() =>
                  this.handleSectionOpen({ sectionClickedOn: index })
                }
                closeModalMenu={() => console.log}
                activeCompany={activeCompany}
                activeLocation={activeLocation}
                activeProject={activeProject}
                documentFolders={documentFolders}
                addDocumentFolder={addDocumentFolder}
                setActiveFolder={setActiveFolder}
                fetchSds={fetchSds}
                fetchDailyReports={fetchDailyReports}
                fetchQualifications={fetchQualifications}
                fetchSafetyInstructions={fetchSafetyInstructions}
                fetchTrainings={fetchTrainings}
                fetchToolboxTalks={fetchToolboxTalks}
                loggedInUser={loggedInUser}
                hasRequiredAuthdocs={requiredAuthDocs.length > 0}
              />
            ) : (
              ''
            );
          })}
        </aside>
        <div className="bottomNav">
          <UserBadge
            userId={loggedInUser._id}
            size="x-large"
            showName
            showImage
            nameUnder
          />
          <Button
            text="Profile"
            className="blueButton"
            onClick={() => history.push('/app/profile')}
          />
          <Button
            text="Logout"
            className="whiteButton"
            onClick={() => logOut()}
          />
        </div>
        {modalOpen && (
          <Modal
            title="Type of Incident (one or more)"
            titleClassName="blueHeader"
            wide
            isOpen={modalOpen}
            className="modalBig"
            submitButtonColor="green"
            submitButtonText="Create"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={() => this.handleSubmit()}
            disableSubmit={reportTemplates.length === 0}
          >
            <ReportSelector
              accessLevel={loggedInUser.accessLevel}
              currentValue={reportTemplates}
              onChange={values =>
                this.setState({ reportTemplates: values })
              }
              activeCompany={activeCompany}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  leftNavigation: getLeftNavigationSelector(state),
  loggedInUser: getLoggedInUser(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  documentFolders: getDocumentFoldersSelector(state),
  requiredAuthDocs: getRequireAuthDocsSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  addDocumentFolder: values => dispatch(addDocumentFolderRequest(values)),
  setActiveFolder: values => dispatch(setActiveDocumentFolderRequest(values)),
  fetchSds: () => dispatch(fetchSdsRequest({ page: 1, limit: 10000 })),
  fetchDailyReports: () =>
    dispatch(fetchDailyReportsRequest({ page: 1, limit: 10000 })),
  fetchQualifications: () =>
    dispatch(fetchQualificationRequest({ page: 1, limit: 10000 })),
  fetchSafetyInstructions: () =>
    dispatch(fetchSafetyInstructionRequest({ page: 1, limit: 10000 })),
  fetchToolboxTalks: () =>
    dispatch(fetchToolboxTalksRequest({ page: 1, limit: 10000 })),
  logOut: () => dispatch(signOut()),
  fetchIncidentBasics: reportTypes =>
    dispatch(fetchIncidentBasicsRequest(reportTypes)),
  fetchJsaSections: () => dispatch(fetchJsaSectionsRequest()),
  setIncident: () => dispatch(setActiveIncidentResponse({})),
  setJsa: () => dispatch(setActiveJsaResponse({})),
  fetchIncidentAttachments: () =>
    dispatch(fetchIncidentAttachmentsResponse([])),
  clearAttachments: () => dispatch(clearUploadedAttachments()),
  setSafetyWalk: () => dispatch(setActiveSafetyWalk({})),
  fetchRequiredAuth: () => dispatch(fetchRequireAuthRequest()),
  fetchTrainings: () => dispatch(fetchTrainingsRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
