import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import history from '../../history';
import {
  fetchRequireAuthRequest,
  setActiveMyDocument
} from '../../actions/documents';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  setActiveTrainingResponse,
  setActiveTrainingRequest
} from '../../actions/training';
import { getRequireAuthDocsSelector } from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterExpireDate,
  filterPerformedDate,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import Button from '../../components/inputs/Button';
import Header from '../../components/Header';

import './index.css';
import 'react-table/react-table.css';

class RequireAuthorizationList extends Component {
  state = {
    filters: [],
    openCreateModal: false,
    openDocumentTypeModal: false,
    docType: {}
  };

  componentDidMount() {
    if (localStorage.getItem('requireAuthFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('requireAuthFilters'))
      });
    }

    this.props.updateLeftNav('Require Acknowledgment');
    this.props.fetchMyDocuments();
  }

  handleFilterChange = filters => {
    localStorage.setItem('requireAuthFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  handleView = original => {
    if (original.title) {
      if (original.isPerform) {
        this.props.setActiveTraining({ ...original, isEditing: true });
        history.push('/app/training/performTraining');
      } else {
        this.props.setTrainingReq({ ...original, isEditing: true });
      }
    } else {
      this.props.setActiveMyDocument({ ...original });

      if (
        original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment'
      ) {
        history.push('/app/recurrenceContainer');
      } else {
        history.push('/app/transitionContainer');
      }
    }
  };

  render() {
    const { myDocs } = this.props;
    const { filters } = this.state;

    return (
      <div className="requireAuth">
        <Header title="Require Acknowledgment" />
        <div className="tableCard">
          <ReactTable
            data={myDocs}
            onFilteredChange={filtered => this.handleFilterChange(filtered)}
            filtered={filters}
            resizable={false}
            filterable={true}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            noDataText="No results found"
            defaultPageSize={10}
            className="table -striped -highlight"
            columns={[
              {
                Header: 'TITLE',
                accessor: d => d.label,
                id: 'label'
              },
              {
                Header: 'EMPLOYEE',
                accessor: d => d.formData.employeeName,
                id: 'employeeName'
              },
              {
                Header: 'CREATED DATE',
                accessor: d => d.createdAt,
                id: 'createdAt',
                Cell: ({ original }) =>
                  moment(Date.parse(original.createdAt)).format(
                    'MM/DD/YYYY hh:mm a'
                  ),
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterPerformedDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Created Today</option>
                    <option value="last7">Created within Last Week</option>
                    <option value="last30">Created This Month</option>
                    <option value="thisQuarter">Created This Quarter</option>
                    <option value="thisYear">Created This Year</option>
                  </select>
                )
              },
              {
                Header: 'DUE DATE',
                accessor: d => d.dueDate,
                id: 'dueDate',
                Cell: ({ original }) =>
                  original.dueDate
                    ? moment(Date.parse(original.dueDate)).format(
                        'MM/DD/YYYY hh:mm a'
                      )
                    : '',
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterExpireDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Due Today</option>
                    <option value="within7">Due within a Week</option>
                    <option value="within30">Due within 30 Days</option>
                    <option value="within60">Due within 60 Days</option>
                  </select>
                )
              },
              {
                Header: 'ACKNOWLEDGE',
                Cell: ({ original }) => (
                  <Button
                    className="blueButton"
                    onClick={() => this.handleView(original)}
                    text="Acknowledge"
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  myDocs: getRequireAuthDocsSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  fetchMyDocuments: () => dispatch(fetchRequireAuthRequest()),
  setActiveMyDocument: paylaod => dispatch(setActiveMyDocument(paylaod)),
  updateLeftNav: payload => dispatch(updateLeftNavRequest(payload)),
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  setTrainingReq: payload => dispatch(setActiveTrainingRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequireAuthorizationList);
