import React, { Component } from 'react';
import Button from '../Button';
import Textbox from '../Textbox';

import styles from './searchBar.module.scss';

class SearchBar extends Component {
  state = {
    searchTerms: ''
  };

  render() {
    const { searchTerms } = this.state;
    const { searchByTerms } = this.props;

    return (
      <div className={styles.container}>
        <Textbox
          placeholder="Enter Keywords"
          handleChange={e => this.setState({ searchTerms: e.target.value })}
          className={styles.search}
          currentValue={searchTerms}
          enter={() => searchByTerms?.({ searchTerm: searchTerms })}
        />
        <Button
          className={styles.button}
          color="blue"
          text="Search"
          onClick={() => searchByTerms?.({ searchTerm: searchTerms })}
        />
      </div>
    );
  }
}

export default SearchBar;
