import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import {
  OSHA_PERRP_DROPDOWN,
  PERRP_ENTITY_CODES
} from '../../constants/constants';
import { states } from '../../constants/states';
import {
  normalizeBwcPolicy,
  normalizePhone,
  normalizeWebsite,
  renderDropdownField,
  renderField,
  renderNumberField,
  required,
  validNAICS,
  validSIC,
  validZip
} from '../../utils/formValidation';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import FileCropper from '../../components/FileCropper';

import '../form.css';

let CompanyForm = props => {
  const {
    handleSubmit,
    onCancel,
    redirect,
    activeCompany,
    isUpdate,
    addAttachment,
    addedAttachments,
    removeAttachment,
    selectedState,
    isPerrp
  } = props;

  return (
    <div>
      <form className="companyForm" onSubmit={handleSubmit}>
        {isUpdate ? (
          <div>
            <h2>Account Settings</h2>
            <InputRow right>
              <Button text="Update" color="blue" type="submit" />
              <Button text="Cancel" color="white" onClick={onCancel} />
            </InputRow>
          </div>
        ) : (
          ''
        )}
        {isUpdate ? (
          <div className="form-fields--logo">
            <div className="form-fields">
              <img
                src={
                  (activeCompany && activeCompany.logoUrl) ||
                  require('../../assets/images/no-image-placeholder.jpg')
                }
                alt={`${activeCompany.name}'s Logo`}
              />
            </div>
            <div className="form-fields" />
          </div>
        ) : (
          ''
        )}
        <div className="form-fields">
          <div className="form-fields--split">
            <div className="form-fields--attachmentsLeftSide">
              {addedAttachments && addedAttachments.length > 0 ? (
                <div>
                  <img
                    src={addedAttachments[0].source_url}
                    alt="Uploaded Logo"
                    className="form-fields--attachmentsLeftSideImage"
                  />
                  <div className="form-fields--hoverButton">
                    <Button
                      text="Remove Logo"
                      color="red"
                      type="button"
                      onClick={() => removeAttachment()}
                    />
                  </div>
                </div>
              ) : (
                <Field
                  name="attachments"
                  type="text"
                  component={FileCropper}
                  placeholder={''}
                  activeCompany={activeCompany}
                  addAttachment={addAttachment}
                />
              )}
            </div>
            <div className="form-fields--attachmentsRightSide">
              <Field
                name="name"
                type="text"
                component={renderField}
                label="Division Name."
                validate={[required]}
                placeholder="e.g. Aperture Science Human Resources"
                requiredLabel={true}
              />
              <Field
                name="phoneNumber.fullPhoneNumber"
                type="text"
                component={renderField}
                label="Phone."
                placeholder="(000) 000-0000"
                normalize={normalizePhone}
                validate={[required]}
                requiredLabel={true}
              />
            </div>
          </div>
          <div className="form-fields--address">
            <Field
              label="Division Address."
              name="mainAddress.streetAddress"
              type="text"
              component={renderField}
              requiredLabel={true}
              placeholder="Street Address"
              className="noPadding"
              validate={[required]}
            />
            <div className="form-fields--sameLine">
              <Field
                name="mainAddress.city"
                type="text"
                component={renderField}
                placeholder="City"
                className="noPadding"
                validate={[required]}
              />
              <Field
                id="state"
                name="mainAddress.stateName"
                type="text"
                component={renderDropdownField}
                placeholder="State"
                options={states}
                inputClassName="noPadding"
                validate={[required]}
                searchable
              />
              <Field
                name="mainAddress.zip"
                type="text"
                component={renderNumberField}
                placeholder="ZIP Code"
                className="noPadding"
                validate={[required, validZip]}
              />
            </div>
          </div>
          <div className="form-fields--website">
            <Field
              name="website"
              type="text"
              component={renderField}
              label="Website."
              placeholder="e.g. www.ireportsource.com"
              normalize={normalizeWebsite}
            />
          </div>
          {selectedState === 'OH' && (
            <div className="form-fields--website">
              <Field
                name="reportingType"
                component={renderDropdownField}
                options={OSHA_PERRP_DROPDOWN}
                label="OSHA OR PERRP."
                requiredLabel={true}
              />
            </div>
          )}
          {selectedState === 'OH' && isPerrp === 2 && (
            <div className="form-fields--website">
              <Field
                name="entityCode"
                component={renderDropdownField}
                options={PERRP_ENTITY_CODES}
                label="Entity Code."
              />
            </div>
          )}
          {selectedState === 'OH' && isPerrp === 2 && (
            <div className="form-fields--website">
              <Field
                name="bwcNumber"
                component={renderField}
                type="text"
                placeholder="e.g. 12345678-000"
                label="BWC Policy Number."
                normalize={normalizeBwcPolicy}
                requiredLabel={true}
                validate={required}
              />
            </div>
          )}
          <div className="form-fields--combinationQuestion">
            <div className="form-fields--combinationQuestionLeftSide">
              <Field
                name="NAICS"
                type="text"
                component={renderNumberField}
                label="NAICS"
                placeholder="000000"
                requiredLabel={true}
                validate={[required, validNAICS]}
                displayToolTip={true}
              >
                <p>North American Industrial Classification</p>
              </Field>
            </div>
            <div className="form-fields--combinationQuestionRightSide">
              <Field
                name="SIC"
                type="text"
                component={renderNumberField}
                label="SIC"
                placeholder="0000"
                warn={validSIC}
                displayToolTip={true}
              >
                <p>Standard Industrial Classification</p>
              </Field>
            </div>
          </div>
          <div className="form-fields--year">
            <Field
              name="yearCompanyWasFounded"
              type="text"
              component={renderField}
              label="What year was the company founded?"
              placeholder="yyyy"
              className="noPadding"
            />
          </div>
        </div>
      </form>
      {redirect && <Redirect to="/app/settings/CompanyDetails" />}
    </div>
  );
};

CompanyForm = reduxForm({
  form: 'CompanyForm',
  enableReinitialize: true
})(CompanyForm);

const selector = formValueSelector('CompanyForm');

const mapStateToProps = state => ({
  selectedState: selector(state, 'mainAddress.stateName'),
  isPerrp: selector(state, 'reportingType')
});

export default connect(mapStateToProps)(CompanyForm);
