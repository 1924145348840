import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { PERRP_ENTITY_CODES } from '../../constants/constants';
import { states } from '../../constants/states';
import {
  booleanRequired,
  normalizeBwcPolicy,
  renderDropdownField,
  renderField,
  renderNumberField,
  renderRadioButtons,
  required,
  validNAICS,
  validSIC,
  validZip
} from '../../utils/formValidation';

import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';

class NewLocationForm extends Component {
  render() {
    const {
      handleSubmit,
      sepEstablishment,
      selectedState,
      isPerrp
    } = this.props;
    return (
      <form className="newLocationForm" onSubmit={handleSubmit}>
        <div className="form-fields">
          <Field
            name="name"
            type="text"
            component={renderField}
            label="Group/Establishment Name."
            validate={[required]}
            placeholder="e.g. Mark's Team/Tallahassee District Office"
            requiredLabel={true}
          />
          <Field
            name="isSeparateEstablishment"
            component={renderRadioButtons}
            label="Is this a separate establishment?"
            validate={[booleanRequired]}
            requiredLabel={true}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            displayToolTip={true}
          >
            <p>
              Single physical location where work is done, or that
              supervises/acts as a base for personnel that don’t work at a
              single location.
            </p>
            <p> &mdash; With &mdash; </p>
            <p>A separate address from the associated company division.</p>
          </Field>
          <div style={{ display: sepEstablishment ? 'block' : 'none' }}>
            <div className="form-fields--address">
              <Field
                label="Establishment Address."
                name="streetAddress"
                type="text"
                component={renderField}
                requiredLabel={true}
                placeholder="Street Address"
                className="noPadding"
                validate={sepEstablishment ? [required] : []}
              />
              <div className="form-fields--sameLine">
                <Field
                  name="city"
                  type="text"
                  component={renderField}
                  placeholder="City"
                  className="noPadding"
                  validate={sepEstablishment ? [required] : []}
                />
                <Field
                  name="state"
                  type="text"
                  component={renderDropdownField}
                  placeholder="State"
                  options={states}
                  inputClassName="noPadding"
                  searchable={true}
                />
                <Field
                  name="zip"
                  type="text"
                  component={renderField}
                  placeholder="ZIP Code"
                  className="noPadding"
                  validate={sepEstablishment ? [required, validZip] : []}
                />
              </div>
            </div>
            <Field
              name="operationalForYearOrLonger"
              component={renderRadioButtons}
              label="Has or will this establishment be operational for a year or longer?"
              validate={sepEstablishment ? [booleanRequired] : []}
              requiredLabel={true}
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
              ]}
            />
            {selectedState === 'OH' && isPerrp === 2 && (
              <div className="form-fields--website">
                <Field
                  name="entityCode"
                  component={renderDropdownField}
                  options={PERRP_ENTITY_CODES}
                  label="Entity Code."
                  requiredLabel={true}
                  validate={required}
                />
              </div>
            )}
            {selectedState === 'OH' && isPerrp === 2 && (
              <div className="form-fields--website">
                <Field
                  name="bwcNumber"
                  component={renderField}
                  type="text"
                  placeholder="e.g. 12345678-000"
                  label="BWC Policy Number."
                  requiredLabel={true}
                  validate={required}
                  normalize={normalizeBwcPolicy}
                />
              </div>
            )}
            <div className="form-fields--combinationQuestion">
              <div className="form-fields--combinationQuestionLeftSide">
                <Field
                  name="NAICS"
                  component={renderNumberField}
                  label="NAICS"
                  placeholder="000000"
                  requiredLabel={true}
                  validate={
                    sepEstablishment ? [required, validNAICS] : []
                  }
                  displayToolTip={true}
                >
                  <p>North American Industrial Classification</p>
                </Field>
              </div>
              <div className="form-fields--combinationQuestionRightSide">
                <Field
                  name="SIC"
                  component={renderNumberField}
                  label="SIC"
                  placeholder="0000"
                  warn={validSIC}
                  displayToolTip={true}
                >
                  <p>Standard Industrial Classification</p>
                </Field>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

NewLocationForm = reduxForm({
  form: 'NewLocationForm',
  enableReinitialize: true
})(NewLocationForm);

const selector = formValueSelector('NewLocationForm');

const mapStateToProps = state => ({
  sepEstablishment: selector(state, 'isSeparateEstablishment')
});

export default connect(mapStateToProps)(NewLocationForm);
