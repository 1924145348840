import React, { Component } from 'react';
import { DOCUMENT_TYPES_CUSTOMIZABLE } from '../../../constants/constants';
import Button from '../../inputs/Button';
import Checkbox from '../../inputs/Checkbox';
import Dropdown from '../../inputs/Dropdown';
import Modal from '../../Modal';
import Textbox from '../../inputs/Textbox';
import { InputRow } from '../../inputs';
import HoverImage from '../../HoverImage';
import Card from '../../Card';
import { classnames } from '../../../utils/classNamesHelper';

import styles from './customDocCard.module.scss';

export default class CustomDocumentCard extends Component {
  state = {
    modalOpen: false,
    activeOptions: [],
    rowIndex: 0,
    selectedFieldType: '',
    dragIdx: null,
    dragTargetIdx: null
  };

  onDragStart = (e, index) => {
    this.setState({
      dragIdx: index,
      dragTargetIdx: null
    });
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/plain', index);
  };

  onDragOver = (e, index) => {
    if (e.preventDefault) e.preventDefault();
    this.setState({ dragTargetIdx: index });
  };

  onDragLeave = (e, index) => {
    this.setState({ dragTargetIdx: null });
  };

  onDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (
      this.state.dragIdx === this.state.dragTargetIdx ||
      this.state.dragTargetIdx === null
    ) {
      this.setState({
        dragIdx: null,
        dragTargetIdx: null
      });
      return;
    }

    let fields = [ ...this.props.fields ];
    const tmp = fields[this.state.dragIdx];
    fields[this.state.dragIdx] = fields[this.state.dragTargetIdx];
    fields[this.state.dragTargetIdx] = tmp;

    this.props.onChange({ fields });
    this.setState({
      dragIdx: null,
      dragTargetIdx: null
    });
  };

  handleQuestionChange = (index, questionLabel) => {
    let fields = [ ...this.props.fields ];
    let answers = questionLabel.answer;

    if (questionLabel.field === 'options') {
      answers = answers.filter(answer => answer.option);
    }

    fields[index][questionLabel.field] = answers;

    this.setState({
      activeOptions: [],
      rowIndex: '',
      modalOpen: false,
      selectedFieldType: ''
    });

    this.props.onChange({ fields });
  };

  addRowsToTemplate = numberOfRows => {
    let fields = [ ...this.props.fields ];

    for (let i = 1; i <= numberOfRows; i++) {
      fields.push({
        type: '',
        label: '',
        options: [],
        required: false
      });
    }

    this.props.onChange({ fields });
  };

  removeRowFromTemplate = index => {
    let fields = [ ...this.props.fields ];
    if (this.props.isEditing) {
      fields[index].legacy = true;
    } else {
      fields.splice(index, 1);
    }
    this.props.onChange({ fields });
  };

  setChangeOptions = (options, rowIndex, fieldType) => {
    options = options.map(option => {
      if (option.option) return option;
      return {
        option,
        isEditable: false
      };
    });

    this.setState({
      modalOpen: true,
      rowIndex,
      activeOptions: options,
      selectedFieldType: fieldType.value
    });
  };

  render() {
    const {
      modalOpen,
      activeOptions,
      rowIndex,
      selectedFieldType
    } = this.state;
    const {
      fields,
      disabled,
      label,
      onChange,
      shouldHaveExpiredDate
    } = this.props;

    return (
      <Card title="Document Template" showHeader>
        <Textbox
          currentValue={label}
          handleChange={e => onChange({ label: e.target.value })}
          fieldLabel="Title"
          placeholder="Title of Document"
          disabled={disabled}
        />
        <Checkbox
          fieldLabel="Should be able to be expired"
          onChange={checked => onChange({ shouldHaveExpiredDate: checked })}
          currentValue={shouldHaveExpiredDate}
          disabled={disabled}
        />
        <ul className={styles.container}>
          {fields.map((row, index) => {
            if (row.legacy) return <></>;
            const notEditable = '_id' in row;
            return (
              <li
                key={index}
                onDragOver={event => this.onDragOver(event, index)}
                onDragLeave={event => this.onDragLeave(event, index)}
                onDrop={event => this.onDrop(event, index)}
              >
                <div
                  className={classnames(
                    styles.item,
                    disabled && styles.disabled,
                    this.state.dragTargetIdx === index && this.state.dragIdx !== index && styles.target
                  )}
                  draggable={!disabled}
                  onDragStart={event => this.onDragStart(event, index)}
                >
                  <img
                    src={require('../../../assets/images/drag.png')}
                    alt="drag to new position"
                    className={styles.dragImage}
                  />
                  <div className={styles.field}>
                    <Textbox
                      currentValue={row.label}
                      handleChange={e =>
                        this.handleQuestionChange(index, {
                          field: 'label',
                          answer: e.target.value
                        })
                      }
                      fieldLabel="Question"
                      type="textarea"
                      placeholder="Type your question here."
                      disabled={disabled}
                    />
                    <Dropdown
                      options={DOCUMENT_TYPES_CUSTOMIZABLE}
                      fieldLabel="Type of Input"
                      handleChange={values =>
                        this.handleQuestionChange(index, {
                          field: 'type',
                          answer: values
                        })
                      }
                      isRequired={true}
                      placeholder="Choose a question type."
                      currentValue={row.type}
                      disabled={disabled || notEditable}
                    />
                    {row.type.value !== 'blockOfText' && (
                      <Checkbox
                        fieldLabel="Is Required"
                        onChange={v =>
                          this.handleQuestionChange(index, {
                            field: 'required',
                            answer: v
                          })
                        }
                        currentValue={row.required}
                        disabled={disabled || notEditable}
                      />
                    )}
                    {([
                      'dropdown',
                      'multiselect',
                      'radiobuttons',
                      'checkbox'
                    ].includes(row.type.value) ||
                      [
                        'dropdown',
                        'multiselect',
                        'radiobuttons',
                        'checkbox'
                      ].includes(row.type)) && (
                      <Button
                        text="View Options"
                        color="blue"
                        onClick={() =>
                          this.setChangeOptions(row.options, index, row.type)
                        }
                        disabled={disabled}
                      />
                    )}
                    {([
                      'dropdown',
                      'multiselect',
                      'radiobuttons',
                      'checkbox'
                    ].includes(row.type.value) ||
                      [
                        'dropdown',
                        'multiselect',
                        'radiobuttons',
                        'checkbox'
                      ].includes(row.type)) &&
                      row.options.length === 0 && (
                        <div style={{ color: '#c74846', fontWeight: 'bold' }}>
                          * Must add at least one option
                        </div>
                      )}
                  </div>
                  <HoverImage
                    alt="remove row"
                    onClick={() => this.removeRowFromTemplate(index)}
                    src={require('../../../assets/images/removePermissions.png')}
                    srcHover={require('../../../assets/images/removePermissonsHover.png')}
                    className={styles.removeImage}
                  />
                </div>
              </li>
              );
            })}
        </ul>
        <InputRow className={styles.footer}>
          <Button
            text="Add Row"
            color="blue"
            onClick={() => this.addRowsToTemplate(1)}
            disabled={disabled}
          />
          <Button
            text="Add Five Rows"
            color="blue"
            onClick={() => this.addRowsToTemplate(5)}
            disabled={disabled}
          />
        </InputRow>
        <Modal
          title="Change Options"
          titleClassName="blueHeader"
          isOpen={modalOpen}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ modalOpen: false, activeOptions: [] })
          }
          submitActions={() =>
            this.handleQuestionChange(rowIndex, {
              field: 'options',
              answer: activeOptions
            })
          }
        >
          <div className={styles.modal}>
            {activeOptions.map((option, index) => (
              <div className={styles.options} key={index}>
                <Textbox
                  currentValue={option.option}
                  handleChange={e => {
                    const currentOptions = activeOptions;
                    currentOptions[index].option = e.target.value;
                    this.setState({ activeOptions: currentOptions });
                  }}
                  placeholder="Type your option here."
                  disabled={!option.isEditable}
                  className={styles.option}
                />
                {option.isEditable && (
                  <HoverImage
                    src={require('../../../assets/images/removePermissions.png')}
                    srcHover={require('../../../assets/images/removePermissonsHover.png')}
                    className={styles.removeImage}
                    alt="remove row"
                    onClick={() =>
                      this.setState({
                        activeOptions: [
                          ...activeOptions.slice(0, index),
                          ...activeOptions.slice(index + 1)
                        ]
                      })
                    }
                  />
                )}
              </div>
            ))}
            <Button
              text="Add Option"
              color="blue"
              onClick={() =>
                this.setState({
                  activeOptions: [
                    ...activeOptions,
                    { option: '', isEditable: true }
                  ]
                })
              }
              disabled={
                activeOptions.length === 2 &&
                selectedFieldType === 'radiobuttons'
              }
            />
            {activeOptions.length === 2 &&
              selectedFieldType === 'radiobuttons' && (
                <div style={{ color: '#c74846', fontWeight: 'bold' }}>
                  * Only two options are allowed for Radio Buttons
                </div>
              )}
          </div>
        </Modal>
      </Card>
    );
  }
}
