import React from 'react';
import './svg.css';

export default () => (
  <svg
    height="25px"
    width="30px"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <g>
      <path d="M93.351,24.071c-3.296-2.237-8.533-3.924-15.563-0.424C70.494,27.278,22.94,52.984,22.94,52.984L9.755,44.636L5,47.571   L13.965,64.3c2.109,3.914,6.998,5.369,10.903,3.243l29.79-16.174v26.992l7.322-3.927l5.875-30.23l25.15-13.655   C95.499,29.192,95.7,25.665,93.351,24.071z"></path>
      <path d="M56.61,31.452l-22.701-9.295l-7.3,3.968l17.967,11.786C48.565,35.767,52.687,33.553,56.61,31.452z"></path>
    </g>
  </svg>
);
