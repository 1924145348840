import { all, call, put, select } from 'redux-saga/effects';
import history from '../history';
import moment from 'moment';
import { setActiveToolboxTalks } from '../actions/documents';
import { setActiveIncidentResponse } from '../actions/incidents';
import { setActiveJsaRequest } from '../actions/jsa';
import { addMessage, removeMessage } from '../actions/messages';
import { createReportResponse } from '../actions/reports';
import { setActiveSafetyWalk } from '../actions/safetyWalks';
import {
  addUserTaskResponse,
  deleteUserTaskResponse,
  fetchTasksCirclesResponse,
  fetchUsersTasksResponse,
  setActiveTaskResponse,
  updateUserTaskResponse
} from '../actions/tasks';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getActiveToolboxTalkSelector } from '../selectors/documents';
import { getActiveJsaSelector } from '../selectors/jsa';
import { getActiveSafetyWalkSelector } from '../selectors/safetyWalks';
import { getLoggedInUser } from '../selectors/users';
import { circleDataMapper } from '../utils/circleDataMapper';

export function* fetchUsersTasks(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const user = yield select(getLoggedInUser);

    let response = [];

    if (payload.isRecurring) {
      response = yield call(api.fetchRecurringTasks, company._id);

      response = { list: response.docs };
    } else {
      response = yield call(
        api.fetchTasksByAssignedTo,
        company._id,
        user._id,
        payload.page,
        payload.limit,
        payload.removeClosed
      );

      // eslint-disable-next-line eqeqeq
      if (payload.isFromStages && user.accessLevel == 900) {
        console.log('in here');
        const group = yield select(getActiveLocationId);
        const project = yield select(getActiveProject);

        const {
          companyCall,
          groupCall,
          projectCall,
          circleResponse
        } = yield all({
          companyCall: call(api.fetchTasksByCompany, company._id, 1, 10000),
          groupCall: group && call(api.fetchTasksByGroup, group._id, 1, 10000),
          projectCall:
            project && call(api.fetchTasksByProject, project._id, 1, 10000),
          circleResponse: call(api.fetchTasksCircleData, {
            companyId: company._id,
            userId: user._id
          })
        });

        response = project ? projectCall : group ? groupCall : companyCall;

        const circles = circleDataMapper(circleResponse, 'Tasks');
        yield put(fetchTasksCirclesResponse(circles));
      } else if (payload.isFromStages) {
        const { createdBy, circleResponse } = yield all({
          createdBy: call(
            api.fetchTasksByCreatedBy,
            company._id,
            user._id,
            1,
            10000
          ),
          circleResponse: call(api.fetchTasksCircleData, {
            companyId: company._id,
            userId: user._id
          })
        });

        createdBy.docs.map(created =>
          response.docs.findIndex(res => res._id === created._id) === -1
            ? response.docs.push(created)
            : ''
        );

        const circles = circleDataMapper(circleResponse, 'Tasks');
        yield put(fetchTasksCirclesResponse(circles));
      }

      response = {
        list: response.docs,
        paginationInfo: {
          hasNextPage: response.hasNextPage,
          hasPrevPage: response.hasPrevPage,
          limit: response.limit,
          nextPage: response.nextPage,
          page: response.page,
          prevPage: response.prevPage,
          totalDocs: response.totalDocs,
          totalPages: response.totalPages
        }
      };
    }

    yield put(fetchUsersTasksResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* setActiveTask(api, { payload }) {
  try {
    yield put(setActiveTaskResponse(payload));
    history.push(`/app/taskContainer/${payload.stage}`);
  } catch (e) {
    console.log(e);
  }
}

export function* addUserTask(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const loggedInUser = yield select(getLoggedInUser);

    const assignedTo = Array.isArray(payload.assignedTo)
      ? payload.assignedTo.map(user => {
          return user.value;
        })
      : [payload.assignedTo];

    const repeatingOn = Array.isArray(payload.repeatingOn)
      ? payload.repeatingOn
      : [payload.repeatingOn.value];

    payload = {
      ...payload,
      assignedTo,
      currentWfStatus: 1,
      groupId: payload.locationId,
      dueDate: moment(Date.parse(payload.dueDate)),
      companyId: activeCompany._id,
      isMaster: payload.repeatingTask.value === 1,
      schedule: payload.repeatingTask.value === 1 ? 'repeating' : 'immediate',
      dueBy: payload.repeatingOption ? payload.repeatingOption.value : '',
      dueByTime: payload.recurringTime,
      repeatingOn
    };

    const response = payload.reportComponentName
      ? yield call(api.createReportComponentTask, activeCompany._id, payload)
      : yield call(api.createTask, activeCompany._id, payload);

    if (response.ownerType === 'ToolboxTalk') {
      const activeToolboxTalk = yield select(getActiveToolboxTalkSelector);

      activeToolboxTalk.tasks.push(response);
      yield put(setActiveToolboxTalks(activeToolboxTalk));
    }

    if (response.ownerType === 'incident') {
      const incident = yield call(
        api.fetchIncidentById,
        activeCompany._id,
        response.ownerId
      );

      const reportComponent = incident.reportComponents.filter(
        report => report[0] === response.reportComponentName
      );

      yield put(setActiveIncidentResponse(incident));
      yield put(createReportResponse(reportComponent));
    } else if (response.ownerType === 'safetyWalks') {
      const activeSafetyWalk = yield select(getActiveSafetyWalkSelector);
      const hazards = activeSafetyWalk.hazards;

      hazards[`${payload.hazardNumber}`].tasks = [response];
      activeSafetyWalk.hazards = hazards;

      if (activeSafetyWalk._id) {
        const swResponse = yield call(api.updateSafetyWalk, activeSafetyWalk);
        yield put(setActiveSafetyWalk(swResponse));
      } else {
        yield put(setActiveSafetyWalk({ ...activeSafetyWalk }));
      }

      yield put(addUserTaskResponse(response));
    } else if (response.ownerType === 'jsaQuestion') {
      const activeJsa = yield select(getActiveJsaSelector);
      yield put(setActiveJsaRequest({ ...activeJsa, goToSummary: true }));
    } else if (response.assignedTo.indexOf(loggedInUser._id) > -1) {
      yield put(addUserTaskResponse(response));
    } else {
      yield put(updateUserTaskResponse());
    }

    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
    history.goBack();
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateUserTask(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    const assignedTo = Array.isArray(payload.assignedTo)
      ? payload.assignedTo.map(user => {
          if (user.value) {
            return user.value;
          }

          return user;
        })
      : [payload.assignedTo];

    payload = {
      ...payload,
      groupId: payload.locationId,
      assignedTo: assignedTo,
      dueDate: moment(Date.parse(payload.dueDate)),
      companyId: payload.companyId ? payload.companyId : activeCompany._id
    };

    delete payload.createdBy;

    const response = yield call(
      api.updateTask,
      activeCompany._id,
      payload._id,
      payload
    );

    if (response.ownerType === 'incident') {
      const incident = yield call(
        api.fetchIncidentById,
        activeCompany._id,
        response.ownerId
      );

      const reportComponent = incident.reportComponents.filter(
        report => report[0] === response.reportComponentName
      );

      yield put(setActiveIncidentResponse(incident));
      yield put(createReportResponse(reportComponent));
    } else if (response.ownerType === 'safetyWalks') {
      let activeSafetyWalk = yield select(getActiveSafetyWalkSelector);

      if (activeSafetyWalk._id) {
        const swResponse = yield call(api.updateSafetyWalk, activeSafetyWalk);
        yield put(setActiveSafetyWalk(swResponse));
      } else {
        yield put(setActiveSafetyWalk({ ...activeSafetyWalk }));
      }

      yield put(setActiveSafetyWalk(activeSafetyWalk));
    } else if (response.ownerType === 'jsaQuestion') {
      const activeJsa = yield select(getActiveJsaSelector);
      yield put(setActiveJsaRequest({ ...activeJsa, goToSummary: true }));
    } else {
      yield put(updateUserTaskResponse(response));
    }
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteUserTask(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    yield call(api.deleteTask, activeCompany._id, payload);

    if (payload.ownerType === 'jsaQuestion') {
      const activeJsa = yield select(getActiveJsaSelector);
      yield put(setActiveJsaRequest({ ...activeJsa, goToSummary: true }));
    } else {
      yield put(deleteUserTaskResponse(payload));
      yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
      history.goBack();
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}
