import React, { Component } from 'react';

import styles from './styles.module.scss';
import '../input.css';

export default class DayOfWeekButtons extends Component {
  state = {
    selectedButtons: []
  };

  componentDidMount() {
    if (this.props.currentValue) {
      this.setState({ selectedButtons: this.props.currentValue });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentValue &&
      JSON.stringify(prevProps.currentValue) !==
        JSON.stringify(this.props.currentValue)
    ) {
      this.setState({ selectedButtons: this.props.currentValue });
    }
  }

  handleSelection = stateObject => {
    if (this.props.disabled) {
      return;
    }

    let selected = this.state.selectedButtons;

    const selectedIndex = selected.findIndex(option => option === stateObject);

    if (selectedIndex > -1) {
      selected = [
        ...this.state.selectedButtons.slice(0, selectedIndex),
        ...this.state.selectedButtons.slice(selectedIndex + 1)
      ];
    } else {
      selected.push(stateObject);
    }

    this.setState({ selectedButtons: selected });
    this.props.handleChange(selected);
  };

  getClassNames = (dayOfWeek, disabled) => {
    const isSelected =
      this.state?.selectedButtons?.findIndex(option => option === dayOfWeek) >
      -1;

    if (isSelected && disabled) {
      return styles.disabledSelected;
    } else if (isSelected) {
      return styles.selected;
    } else if (disabled) {
      return styles.disabled;
    }
  };

  render() {
    const {
      disabled,
      inputClassName,
      fieldLabel,
      name,
      isRequired
    } = this.props;

    return (
      <div className={`input ${inputClassName}`}>
        {fieldLabel && (
          <label
            className="label"
            htmlFor={name}
            data-for={name}
            data-tip="customTheme"
          >
            {isRequired ? <span className="isRequired">*</span> : ''}
            {fieldLabel}
          </label>
        )}
        <div className={styles.dayOfWeekButtons}>
          <div
            className={this.getClassNames('sunday', disabled)}
            onClick={() => this.handleSelection('sunday')}
          >
            <div className="dayOfWeekButtonText">Su</div>
          </div>
          <div
            className={this.getClassNames('monday', disabled)}
            onClick={() => this.handleSelection('monday')}
          >
            <div className="dayOfWeekButtonText">Mo</div>
          </div>
          <div
            className={this.getClassNames('tuesday', disabled)}
            onClick={() => this.handleSelection('tuesday')}
          >
            <div className="dayOfWeekButtonText">Tu</div>
          </div>
          <div
            className={this.getClassNames('wednesday', disabled)}
            onClick={() => this.handleSelection('wednesday')}
          >
            <div className="dayOfWeekButtonText">We</div>
          </div>
          <div
            className={this.getClassNames('thursday', disabled)}
            onClick={() => this.handleSelection('thursday')}
          >
            <div className="dayOfWeekButtonText">Th</div>
          </div>
          <div
            className={this.getClassNames('friday', disabled)}
            onClick={() => this.handleSelection('friday')}
          >
            <div className="dayOfWeekButtonText">Fr</div>
          </div>
          <div
            className={this.getClassNames('saturday', disabled)}
            onClick={() => this.handleSelection('saturday')}
          >
            <div className="dayOfWeekButtonText">Sa</div>
          </div>
        </div>
      </div>
    );
  }
}
