import React from 'react';

import { classnames } from '../../utils/classNamesHelper';
import Button from '../inputs/Button';

import styles from './styles.module.scss';

export default function Card({
  title,
  footer,
  showFooter,
  children,
  showHeader,
  smallCard,
  name,
  rightButton,
  className,
  wide
}) {
  const cardClasses = [
    styles.card,
    smallCard && styles.smallCard,
    className,
    wide && styles.wide
  ];

  let buttonJSX;
  if (!rightButton) {
    buttonJSX = <></>;
  } else if (React.isValidElement(rightButton)) {
    buttonJSX = rightButton;
  } else {
    buttonJSX = <Button {...rightButton} inputClassName={styles.input} />;
  }

  return (
    <div className={classnames(cardClasses)} id={name}>
      {showHeader && (
        <h2 className={styles.header}>
          {title} {buttonJSX}
        </h2>
      )}
      {children}
      {showFooter && <footer className={styles.footer}>{footer}</footer>}
    </div>
  );
}
