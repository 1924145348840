import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import {
  WEATHER_CONDITIONS,
  WORK_DELAYED,
  YES_NO_DROPDOWN
} from '../../constants/constants';
import {
  renderDropdownField,
  renderField,
  renderMultiSelectField,
  renderTextareaField,
  required
} from '../../utils/formValidation';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import FileDownloader from '../../components/FileDownloader';
import FileUploader from '../../components/FileUploader';

import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';

let DailyReportForm = props => {
  const {
    handleSubmit,
    isUpdate,
    disabled,
    onDelete,
    onCancel,
    activeCompanyId,
    addAttachment,
    activeAttachments,
    deleteDRAttachment,
    loading,
    reset,
    activeLocation,
    locationList,
    activeProject,
    addedAttachments,
    deleteAttachment,
    groupId,
    documentFolders,
    activeEmployees,
    accessLevel
  } = props;

  const hasAccess = accessLevel !== '100';

  const activeEmployeeDropdown = activeEmployees.map(employee => {
    return {
      value: employee._id,
      label: `${employee.person.firstName} ${employee.person.lastName}`
    };
  });

  const locationDropdown = locationList.map(location => {
    return {
      value: location._id,
      label: location.name
    };
  });

  const getProjectDropdown = () => {
    let group = '';
    if (!groupId && !activeLocation) {
      return [];
    } else if (activeLocation) {
      group = [activeLocation._id];
    } else {
      group = groupId;
    }

    const selectedGroup = locationList.filter(o =>
      group.find(o2 => {
        if (o2.value) {
          return o._id === o2.value;
        } else {
          return o._id === o2;
        }
      })
    );

    let projectsDropdown = [];

    if (selectedGroup.length > 0) {
      selectedGroup.forEach(group => {
        group.projects.forEach(project => {
          projectsDropdown.push({
            value: project._id,
            label: `${group.name} - ${project.name}`
          });
        });
      });
    }

    return projectsDropdown;
  };

  const folderDropdown = documentFolders.map(folder => {
    return {
      value: folder._id,
      label: folder.name
    };
  });

  return (
    <form className="dailyReportForm" onSubmit={handleSubmit}>
      {disabled ? (
        ''
      ) : isUpdate ? (
        <InputRow right>
          <Button text="Update" color="blue" type="submit" />
          <Button color="red" text="Delete" onClick={onDelete} />
          <Button
            text="Cancel"
            type="button"
            onClick={() => {
              reset('dailyReportForm');
              onCancel();
            }}
          />
        </InputRow>
      ) : (
        ''
      )}
      <div className="form-fields">
        {!activeLocation ? (
          <div>
            <Field
              name="locationId"
              component={renderMultiSelectField}
              label="Group or Establishment:"
              placeholder={''}
              options={locationDropdown}
              disabled={disabled}
              searchable={true}
              className="seventyFiveWidth"
            />
            <ReactTooltip className="customTheme" id="locationId">
              <p>
                You can share with one or more Groups / Establishments and that
                will make it available to them as well
              </p>
            </ReactTooltip>
          </div>
        ) : (
          ''
        )}
        {!activeProject ? (
          <div>
            <Field
              name="projectId"
              component={renderMultiSelectField}
              label="Project:"
              placeholder={''}
              options={getProjectDropdown()}
              disabled={disabled}
              searchable={true}
              className="seventyFiveWidth"
            />
            <ReactTooltip className="customTheme" id="projectId">
              <p>
                You can share this document with one or more Projects and that
                will make it available to them as well
              </p>
            </ReactTooltip>
          </div>
        ) : (
          ''
        )}
        <Field
          name="documentFolderId"
          component={renderDropdownField}
          options={folderDropdown}
          label="Add to Folder:"
          placeholder={''}
          disabled={disabled}
          className="seventyFiveWidth"
        />
        <div className="form-fields--sameLine">
          <Field
            name="documentData.jobName"
            component={renderField}
            label="Job Name:"
            disabled={disabled}
            validate={[required]}
            requiredLabel={true}
          />
          <Field
            name="documentData.projectNo"
            component={renderField}
            label="Project No.:"
            disabled={disabled}
            validate={[required]}
            requiredLabel={true}
          />
        </div>
        <Field
          name="documentData.weatherConditions"
          component={renderMultiSelectField}
          options={WEATHER_CONDITIONS}
          label="Weather Conditions:"
          placeholder="Only if work outside"
          disabled={disabled}
          className="seventyFiveWidth"
          searchable={true}
        />
        <h4>Temperature:</h4>
        <div className="form-fields--sameLine">
          <Field
            name="documentData.tempMin"
            type="number"
            component={renderField}
            label="Min:"
            disabled={disabled}
          />
          <Field
            name="documentData.tempMax"
            type="number"
            component={renderField}
            label="Max:"
            disabled={disabled}
          />
        </div>
        <Field
          name="documentData.employees"
          component={renderMultiSelectField}
          options={activeEmployeeDropdown}
          label="Employee(s):"
          // placeholder="Type to search...."
          disabled={disabled}
          className="seventyFiveWidth"
          searchable={true}
        />
        <Field
          name="documentData.workDelayed"
          component={renderDropdownField}
          label="Was work delayed for any reason?"
          options={WORK_DELAYED}
          disabled={disabled}
          className="seventyFiveWidth"
          searchable={true}
        />
        <Field
          name="documentData.contractorInstructions"
          component={renderDropdownField}
          label="Did you give the contractor any special instructions?"
          options={YES_NO_DROPDOWN}
          disabled={disabled}
          className="seventyFiveWidth"
        />
        <Field
          name="documentData.safetyWalkthough"
          component={renderDropdownField}
          label="Safety walk through today?"
          options={YES_NO_DROPDOWN}
          disabled={disabled}
          className="seventyFiveWidth"
        />
        <Field
          name="documentData.safetyGoals"
          component={renderTextareaField}
          label="Safety goals today?"
          disabled={disabled}
        />
        <Field
          name="documentData.safetyIssues"
          component={renderTextareaField}
          label="Safety issues today?"
          disabled={disabled}
        />
        <Field
          name="documentData.materialDeliveries"
          component={renderTextareaField}
          label="Material Deliveries?"
          disabled={disabled}
        />
        <Field
          name="documentData.vistors"
          component={renderTextareaField}
          label="Visitors?"
          disabled={disabled}
        />
        <Field
          name="documentData.description"
          component={renderTextareaField}
          label="Describe day's activities giving quantities, location of work, trades involved, etc:"
          disabled={disabled}
        />
        <div>
          <h4>Attachments</h4>
          {addedAttachments &&
            addedAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={deleteAttachment}
                disabled={!hasAccess || disabled}
              />
            ))}
          {activeAttachments &&
            activeAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={deleteDRAttachment}
                disabled={!hasAccess || disabled}
              />
            ))}
        </div>
        {!disabled && (
          <Field
            name="attachments"
            type="text"
            component={FileUploader}
            label="Attachment(s):"
            placeholder={''}
            activeCompanyId={activeCompanyId}
            addAttachment={addAttachment}
            disabled={disabled}
            loading={loading}
            ownerId="1234"
            ownerType="documents"
          />
        )}
      </div>
    </form>
  );
};

DailyReportForm = reduxForm({
  form: 'DailyReportForm'
})(DailyReportForm);

const selector = formValueSelector('DailyReportForm');

const mapStateToProps = state => ({
  groupId: selector(state, 'locationId')
});

export default connect(mapStateToProps)(DailyReportForm);
