import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import * as msal from 'msal';

import {
  forgotPasswordRequest,
  signIn,
  signInMicrosoftUser,
  submitSecurityQuestions
} from '../../actions/auth';
import { getMessageSelector } from '../../selectors/messages';
import { getSecurityQuestionsSelector } from '../../selectors/auth';
import LoginForm from '../../forms/LoginForm';
import ResetPasswordForm from '../../forms/ResetPasswordForm';
import config from '../../config/config';
import './index.css';
import { ResetUserPasswordForm } from '../../forms/ResetUserPasswordForm';

export class Login extends Component {
  state = {
    isLoading: false,
    loginPage: 'login'
  };

  componentDidMount = () => {
    // sessionStorage.clear();
    // localStorage.clear();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }

    if (prevState.isLoading === true) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      signInDispatcher,
      forgotPassword,
      signInMicrosoftUser,
      securityQuestions,
      submitQuestions
    } = this.props;

    const { isLoading, loginPage } = this.state;

    return (
      <div>
        {loginPage === 'login' ? (
          <div className="login">
            <h2>Sign Into iReportSource</h2>
            <LoginForm
              onSubmit={values =>
                signInDispatcher({
                  ...values,
                  referrer: this.props?.location?.state?.referrer
                }) && this.setState({ isLoading: true })
              }
              isLoading={isLoading}
              setLoginPage={value => this.setState({ loginPage: value })}
              onMicrosoftSubmit={async event => {
                console.log('In callback "onMicrosoftSubmit"');
                const msalConfig = {
                  auth: config.auth
                };
                const client = new msal.UserAgentApplication(msalConfig);
                const loginRequest = {
                  scopes: [
                    'openid',
                    'email',
                    'https://graph.microsoft.com/user.read'
                  ]
                };
                const response = await client.loginPopup(loginRequest);
                console.log('response: ', response);
                return (
                  signInMicrosoftUser({
                    ...response,
                    referrer: this.props?.location?.state?.referrer
                  }) && this.setState({ isLoading: true })
                );
              }}
            />
            <p className="login--verbage">
              New to iReportSource?{' '}
              <Link to="/register">Create an account!</Link>
            </p>
          </div>
        ) : (
          <div className="login">
            <h2>Recover your iReportSource password</h2>
            {securityQuestions &&
            Array.isArray(securityQuestions) &&
            securityQuestions.length > 1 ? (
              <ResetUserPasswordForm
                securityQuestions={securityQuestions}
                submitQuestions={submitQuestions}
              />
            ) : (
              <ResetPasswordForm onSubmit={values => forgotPassword(values)} />
            )}

            <p className="login--verbage">
              Remember your password {/* eslint-disable-next-line */}
              <a href="#" onClick={() => this.setState({ loginPage: 'login' })}>
                Back to Login
              </a>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  message: getMessageSelector(state),
  securityQuestions: getSecurityQuestionsSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  signInDispatcher: payload => dispatch(signIn(payload)),
  forgotPassword: email => dispatch(forgotPasswordRequest(email)),
  signInMicrosoftUser: payload => dispatch(signInMicrosoftUser(payload)),
  submitQuestions: payload => dispatch(submitSecurityQuestions(payload))
});

export default withAlert(connect(mapStateToProps, mapDispatchToProps)(Login));
