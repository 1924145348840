import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';

import Button from '../inputs/Button';
import Modal from '.';
import Textbox from '../inputs/Textbox';
import { InputRow } from '../inputs';

import styles from './styles.module.scss';

export class AddYouTubeLinksModal extends Component {
  state = {
    youtubeLinks: [{ title: '', link: '' }]
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.youtubeLinks, this.props.youtubeLinks)) {
      this.setState({ youtubeLinks: this.props.youtubeLinks });
    }
  }

  addYouTubeLink = () => {
    this.setState(state => ({
      youtubeLinks: [...state.youtubeLinks, { title: '', link: '' }]
    }));
  };

  handleSubmit = () => {
    this.props.submitActions(this.state.youtubeLinks);
  };

  render() {
    const youtubeLinks = this.state.youtubeLinks;

    return (
      <Modal
        title="Add YouTube Links"
        titleClassName="blueHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="blue"
        submitButtonText="Save Links"
        onRequestClose={this.props.onRequestClose}
        cancelButtonText="Cancel"
        submitActions={this.handleSubmit}
      >
        {youtubeLinks.map((link, index) => (
          <InputRow key={index} className={styles.grow}>
            <Textbox
              handleChange={e => {
                const currentOptions = youtubeLinks;
                currentOptions[index].title = e.target.value;
                this.setState(state => ({
                  youtubeLinks: currentOptions
                }));
              }}
              placeholder="Title of YouTube Video"
              currentValue={link.title}
              className={styles.input}
            />
            <Textbox
              handleChange={e => {
                const currentOptions = youtubeLinks;
                currentOptions[index].link = e.target.value;
                this.setState(state => ({
                  youtubeLinks: currentOptions
                }));
              }}
              placeholder="Link of YouTube Video"
              currentValue={link.link}
              className={styles.input}
            />
          </InputRow>
        ))}
        <Button
          text="Add Another Link"
          color="blue"
          style={{ maxWidth: 'fit-content' }}
          onClick={() => this.addYouTubeLink()}
        />
      </Modal>
    );
  }
}

export default AddYouTubeLinksModal;
