import React from 'react';

import AssignCorrectiveActionButton from '../../inputs/AssignCorrectiveActionButton';
import Card from '../../Card';
import Carousel from '../../Carousel';
import UserBadge from '../../UserBadge';
import SafeUnsafeButtons from '../SafeUnsafeButtons';

import styles from './styles.module.scss';

export default function QuestionCard({
  question,
  questionNumber,
  updateAnswer,
  isLocked,
  sectionName,
  setActiveTask,
  addAttachment,
  addNote,
  locationId,
  deleteJsaQuestionAttachment
}) {
  const hasLineItemNotes =
    question.actionItems && question.actionItems.notes.length > 0;

  const hasLineItemImages = question?.actionItems?.attachments?.length > 0;

  return (
    <Card smallCard>
      <div className={styles.questionCard}>
        <div className={styles.questionNumber}>{questionNumber}</div>
        <div className={styles.questionTitle}>{question.question}</div>
        <SafeUnsafeButtons
          answer={question.answer}
          updateAnswer={value => updateAnswer(question._id, value)}
          isLocked={isLocked}
        />
        {!isLocked && (
          <div className={styles.actionItemsRow}>
            <div
              onClick={() =>
                document.getElementById(`myImage${question._id}`).click()
              }
            >
              <img
                src={require(hasLineItemImages
                  ? '../../../assets/images/cameraWithAttachments.png'
                  : '../../../assets/images/camera.png')}
                alt={
                  hasLineItemImages
                    ? 'question has images'
                    : 'question does not have images'
                }
              />
              <input
                type="file"
                id={`myImage${question._id}`}
                accept="image/png, image/jpeg, image/jpg"
                onChange={e => addAttachment(e, question._id)}
                multiple
              />
            </div>
            <div onClick={() => addNote(question._id)}>
              <img
                src={require(hasLineItemNotes
                  ? '../../../assets/images/notesWithAttachments.png'
                  : '../../../assets/images/notes.png')}
                alt={
                  hasLineItemNotes
                    ? 'question has notes'
                    : 'question does not have notes'
                }
              />
            </div>
          </div>
        )}
      </div>
      {hasLineItemNotes &&
        question?.actionItems?.notes.map((note, index) => (
          <div className={styles.notesContainer} key={index}>
            <UserBadge
              userId={note.createdByUserId}
              showImage
              showName
              byline="Added By"
              time={note.createdAt}
              size="medium"
            />
            <div className={styles.note}>{note.value}</div>
          </div>
        ))}
      {hasLineItemImages && (
        <Carousel
          items={question?.actionItems?.attachments}
          title="Attachments"
          deleteAction={deleteJsaQuestionAttachment}
          canDelete={!isLocked}
        />
      )}
      <AssignCorrectiveActionButton
        task={question.task}
        description={`Please correct the following which was marked as unsafe: \n ${question.question}`}
        label={`${sectionName} - Corrective Action`}
        setActiveTask={setActiveTask}
        ownerType="jsaQuestion"
        ownerId={question._id}
        groupId={locationId}
      />
    </Card>
  );
}
