import React, { Component } from 'react';
import AnalyticCard from '../AnalyticCard';

import './index.css';

export default class AnalyticCards extends Component {
  render() {
    const format = string => {
      return string
        .toString()
        .split(/(?=(?:\d{3})+(?:\.|$))/g)
        .join(',');
    };

    const {
      analyticType,
      accountabilityDocuments,
      numberOfProactiveReports,
      numberOfSafetyTrainingsPerformed,
      averageIncidentLagTime,
      dart,
      tcir,
      ltir,
      numberOfFROIs,
      oshaCasesAverageLossTime,
      allCasesAverageLossTime,
      isTimeFrameMonth,
      totalSafetyCosts,
      canViewTDL,
      year,
      activeLocation,
      divisionEstInfo
    } = this.props;

    return (
      <div>
        {analyticType === 0 ? (
          <div className="analyticsCardSection">
            <AnalyticCard
              cardDetails="Average Lag Reporting Time"
              classBoolean={averageIncidentLagTime === 'NO DATA'}
            >
              {averageIncidentLagTime === 'NO DATA' ? (
                averageIncidentLagTime
              ) : (
                <span>
                  <span className="analyticsCard-cardHeader--small">
                    {averageIncidentLagTime}
                  </span>
                </span>
              )}
            </AnalyticCard>
            <AnalyticCard
              cardDetails="Accountability Documents Created"
              hasError={accountabilityDocuments === 0}
              classBoolean={activeLocation !== 0}
              hoverText="Creating Accountability Documents (Safety Instructions, SDS, Daily Reports, and Toolbox Talks) helps to create a better and safer workplace."
            >
              {activeLocation !== 0 ? 'In Divison' : accountabilityDocuments}
            </AnalyticCard>
            <AnalyticCard
              cardDetails="Total Safety Costs"
              classBoolean={
                totalSafetyCosts === 'NO DATA' || Number.isNaN(totalSafetyCosts)
              }
              hasError={
                totalSafetyCosts === 'NO DATA' || Number.isNaN(totalSafetyCosts)
              }
              hoverText={
                Number.isNaN(totalSafetyCosts) || totalSafetyCosts === 'NO DATA'
                  ? 'Please change all Costs to a Numerical value in the Closure section of all Incidents.'
                  : `Be sure to fill out all Costs in the Closure section of all Incidents.`
              }
            >
              {Number.isNaN(totalSafetyCosts)
                ? 'NO DATA'
                : `$` + format(totalSafetyCosts)}
            </AnalyticCard>
            <AnalyticCard
              cardDetails="Safety Trainings Performed"
              hasError={numberOfSafetyTrainingsPerformed === 0}
              classBoolean={false}
              hoverText="Making sure Safety Trainings are up to date and regularly conducted helps to keep everyone happy and healthy."
            >
              {numberOfSafetyTrainingsPerformed}
            </AnalyticCard>
            <AnalyticCard
              cardDetails="Proactive Reports Created"
              hasError={numberOfProactiveReports === 0}
              classBoolean={false}
              hoverText="Proactive Reports (Near Miss, Risk Assessments, Safety Suggestions, Site Inspections,  Behavior Observations, and Lockout/Tagout) are critical, and could even save a life."
            >
              {numberOfProactiveReports}
            </AnalyticCard>
          </div>
        ) : (
          <div className="analyticsCardSection">
            <AnalyticCard
              cardDetails="First Reports of Injury"
              classBoolean={false}
            >
              {numberOfFROIs}
            </AnalyticCard>
            <AnalyticCard
              cardDetails="Total Case Incident Rate (TCIR)"
              classBoolean={
                isTimeFrameMonth ||
                tcir === 'Total Number of Hours Missing' ||
                !canViewTDL
              }
              hasError={
                tcir === 'Total Number of Hours Missing' && !isTimeFrameMonth
              }
              year={year}
              canViewTDL={canViewTDL}
              hoverText="Missing Employment Information in the OSHA 300A section. Click to Go Edit Report"
            >
              {!canViewTDL
                ? 'In Division'
                : isTimeFrameMonth
                ? 'View by Year'
                : tcir === 'Total Number of Hours Missing'
                ? 'NO DATA'
                : tcir}
            </AnalyticCard>
            <AnalyticCard
              cardDetails="Days Away Restricted or Transferred Rate (DART)"
              classBoolean={
                isTimeFrameMonth ||
                dart === 'Total Number of Hours Missing' ||
                !canViewTDL
              }
              year={year}
              canViewTDL={canViewTDL}
              hasError={
                dart === 'Total Number of Hours Missing' && !isTimeFrameMonth
              }
              hoverText="Missing Employment Information in the OSHA 300A section. Click to Go Edit Report"
            >
              {!canViewTDL
                ? 'In Division'
                : isTimeFrameMonth
                ? 'View by Year'
                : dart === 'Total Number of Hours Missing'
                ? 'NO DATA'
                : dart}
            </AnalyticCard>
            <AnalyticCard
              cardDetails="Lost Time Incident Rate (LTIR)"
              classBoolean={
                isTimeFrameMonth ||
                ltir === 'Total Number of Hours Missing' ||
                !canViewTDL
              }
              year={year}
              canViewTDL={canViewTDL}
              hasError={
                ltir === 'Total Number of Hours Missing' && !isTimeFrameMonth
              }
              hoverText="Missing Employment Information in the OSHA 300A section. Click to Go Edit Report"
            >
              {!canViewTDL
                ? 'In Division'
                : isTimeFrameMonth
                ? 'View by Year'
                : ltir === 'Total Number of Hours Missing'
                ? 'NO DATA'
                : ltir}
            </AnalyticCard>
            <AnalyticCard
              cardDetails={`${
                divisionEstInfo.reportingType === 2 ? 'PERRP' : 'OSHA'
              } Recordable Avg. Lost Time`}
              classBoolean={oshaCasesAverageLossTime === 'NO DATA'}
            >
              <span>
                {oshaCasesAverageLossTime}{' '}
                {oshaCasesAverageLossTime !== 'NO DATA' && (
                  <span className="analyticsCard-cardHeader--small">days</span>
                )}
              </span>
            </AnalyticCard>
            <AnalyticCard
              cardDetails="All Reported Injuries Avg. Lost Time"
              classBoolean={allCasesAverageLossTime === 'NO DATA'}
            >
              <span>
                {allCasesAverageLossTime}{' '}
                {allCasesAverageLossTime !== 'NO DATA' && (
                  <span className="analyticsCard-cardHeader--small">days</span>
                )}
              </span>
            </AnalyticCard>
          </div>
        )}
      </div>
    );
  }
}
