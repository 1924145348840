import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as api from '../../../api';
import history from '../../../history';
import { setActiveTrainingResponse } from '../../../actions/training';
import { getActiveTrainingSelector } from '../../../selectors/training';
import BlockText from '../../../components/inputs/BlockText';
import Card from '../../../components/Card';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import QuizCard from '../../../components/training/QuizCard';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

class TrainingQuizViewer extends Component {
  state = {
    hasUnsavedChanges: false,
    openUnsavedChangesModal: false
  };

  handleGoBack = () => {
    if (this.props.activeTraining.isFromSummary) {
      this.props.setActiveTraining(this.props.activeTraining.masterTraining);
    }

    history.goBack();
  };

  handleSubmit = async () => {
    const response = await api.submitQuiz(this.props.activeTraining);
    this.props.setActiveTraining(response);
    history.goBack();
  };

  render() {
    const { activeTraining } = this.props;

    const header = (
      <Header
        title="Safety Training"
        section={activeTraining?.quiz?.title}
        clickBack={() =>
          this.state.hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonClick={() => this.handleSubmit()}
        cancelButtonClick={() =>
          this.state.hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
        editing={activeTraining.isEditing}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          showFooter={!activeTraining.quiz.isCompleted}
          Footer={footer}
        >
          <Card
            title={`Attempt #${activeTraining?.quiz?.attemptNumber}`}
            showHeader={!activeTraining?.quiz?.isCompleted}
          >
            {activeTraining?.quiz?.instructions && (
              <BlockText blockOfText={activeTraining?.quiz?.instructions} />
            )}
            <QuizCard
              quiz={activeTraining?.quiz}
              handleQuizAnswers={questions =>
                this.setState({
                  activeTraining: {
                    ...activeTraining,
                    quiz: { ...activeTraining.quiz, questions }
                  },
                  hasUnsavedChanges: true
                })
              }
            />
          </Card>
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={this.state.openUnsavedChangesModal}
          savingWhat="Quiz"
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => history.goBack()}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTraining: getActiveTrainingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingQuizViewer);
