import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactLoading from 'react-loading';

import './index.css';

const SdsCard = ({ sds, onClick, sdsLoading }) => (
  <div>
    {sdsLoading ? (
      <div className="incidentCard">
        <ReactLoading
          type="spinningBubbles"
          color="#58a15b"
          className="loading"
        />
      </div>
    ) : (
      <div className="searchCard">
        {moment().diff(sds.expires, 'days') > 0 ? (
          <div className="sdsCard-cardHeader--overdue">
            <div className="sdsCard-cardHeader--dueText">Expired</div>
          </div>
        ) : (
          <div className="sdsCard-cardHeader--due">
            <div className="sdsCard-cardHeader--dueText">Valid</div>
          </div>
        )}
        <Link
          to={{
            pathname: '/app/viewEdit/SDS'
          }}
          onClick={() => onClick(sds)}
        >
          <div
            className={
              moment().diff(sds.expires, 'days') > 0
                ? 'sdsCard pastDue'
                : 'sdsCard'
            }
          >
            <div className="sdsCard-card">
              <div className="sdsCard-leftSide">
                <div className="sdsCard-cardMargin">
                  <div className="sdsCard-cardTypes">Title: </div>
                  {sds.label}
                </div>
                <div className="sdsCard-cardMargin">
                  <div className="sdsCard-cardTypes">Description: </div>
                  {sds.documentData && sds.documentData.description
                    ? sds.documentData.description
                    : ''}
                </div>
              </div>
              <div className="sdsCard-rightSide">
                <div className="sdsCard-cardMargin">
                  <div className="sdsCard-cardTypes">Expires: </div>
                  {moment(sds.expires).format('MM/DD/YYYY')}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )}
  </div>
);

export default SdsCard;
