import React, { Component } from 'react';
import moment from 'moment';
import {
  getSelectedCompanies,
  getSelectedGroups,
  getSelectedProjects
} from '../../utils/employeeDGPHelper';
import svgMapper from '../../utils/svgMapper';
import UserBadge from '../UserBadge';

import './index.css';

export default class EmployeeSnapshot extends Component {
  getIsUserDeactivated = activeEmployee => {
    if (activeEmployee?.userAccount?.isDeactivated) {
      return {
        picture: require('../../assets/images/Deactivated.png'),
        level: 'Deactivated'
      };
    } else {
      return {
        picture: require('../../assets/images/blank-profile-picture.jpg'),
        level: 'No User Info'
      };
    }
  };

  getUserAccessLevel = activeEmployee => {
    const accessLevel =
      activeEmployee.userAccount && activeEmployee.userAccount.accessLevel;
    if (!accessLevel) {
      return {
        picture: require('../../assets/images/blank-profile-picture.jpg'),
        level: 'No User Info'
      };
    } else if (accessLevel === '100') {
      return {
        picture: require('../../assets/images/Reporter.png'),
        level: 'Reporter'
      };
    } else if (accessLevel === '400') {
      return {
        picture: require('../../assets/images/Supervisor.png'),
        level: 'Supervisor'
      };
    } else if (accessLevel === '500') {
      return {
        picture: require('../../assets/images/Collaborator.png'),
        level: 'Collaborator'
      };
    } else if (accessLevel === '900') {
      return {
        picture: require('../../assets/images/Admin.png'),
        level: 'Admin'
      };
    }
  };

  render() {
    const { activeEmployee, userCompanies, accessLevel } = this.props;
    const selectedCompanies = getSelectedCompanies(
      userCompanies,
      activeEmployee.companyIds
    );

    const selectedGroups = getSelectedGroups(
      selectedCompanies,
      activeEmployee.groupIds
    );
    const selectedProjects = getSelectedProjects(
      selectedGroups,
      activeEmployee.projectIds
    );

    return (
      <div className="employeeSnapshot">
        <div className="employeeSnapshot-profileInfo">
          <UserBadge
            showImage
            size="x-large"
            userId={activeEmployee?.userAccount?._id}
            className="employeeSnapshot-badge"
            square
          />
          <div className="employeeSnapshot--leftSide">
            <div className="employeeSnapshot-profileInfo--empName">
              <div className="employeeSnapshot-bold">
                {`${activeEmployee.person &&
                  activeEmployee.person.lastName}, ${activeEmployee.person &&
                  activeEmployee.person.firstName}`}
              </div>
              <div>
                {activeEmployee.person && activeEmployee.person.dateOfBirth
                  ? `${moment().diff(
                      Date.parse(activeEmployee.person.dateOfBirth),
                      'years',
                      false
                    )} yrs old ${
                      activeEmployee.person &&
                      activeEmployee.person?.gender === 1
                        ? 'Male'
                        : activeEmployee.person?.gender === 2
                        ? 'Female'
                        : ''
                    } (DOB: ${activeEmployee.person &&
                      moment(
                        Date.parse(activeEmployee.person.dateOfBirth)
                      ).format('MM/DD/YYYY')})`
                  : 'No DOB on File'}
              </div>
            </div>
            {activeEmployee.person &&
            activeEmployee.person.addresses &&
            activeEmployee.person.addresses.length > 0 &&
            activeEmployee.person.addresses[0].streetAddress ? (
              <div className="employeeSnapshot-profileInfo--empAddress">
                <div>
                  {`${activeEmployee.person.addresses[0].streetAddress}`}
                </div>
                <div>
                  {`${activeEmployee.person.addresses[0].city}, ${activeEmployee.person.addresses[0].stateName} ${activeEmployee.person.addresses[0].zip}`}
                </div>
              </div>
            ) : (
              <div>No Address On File</div>
            )}
          </div>
          <div className="employeeSnapshot--rightSide">
            <div className="employeeSnapshot-profileInfo--jobInfo">
              <div>
                <span className="employeeSnapshot-bold">Job Title: </span>
                {activeEmployee.jobTitle}
              </div>
            </div>
            <div className="employeeSnapshot-profileInfo--systemInfo">
              <div>
                <span className="employeeSnapshot-bold">
                  Company Divisions:{' '}
                </span>
                {activeEmployee.companyIds &&
                  selectedCompanies.map(company => company.name).join(', ')}
              </div>
              <div>
                <span className="employeeSnapshot-bold">
                  Group/Establishments:{' '}
                </span>
                {activeEmployee.groupIds && selectedGroups.length > 0
                  ? selectedGroups.map(group => group.name).join(', ')
                  : 'N/A'}
              </div>
              {selectedProjects && selectedProjects.length > 0 && (
                <div>
                  <span className="employeeSnapshot-bold">Projects: </span>{' '}
                  {selectedProjects.map(project => project.name).join(', ')}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="employeeSnapshot-trainings">
          {svgMapper('safety-training')}
          <div className="employeeSnapshot--leftSide">
            <h3>Expiring Trainings</h3>
            {activeEmployee.safetyTrainings &&
            activeEmployee.safetyTrainings.expiring3Months.length > 0 ? (
              activeEmployee.safetyTrainings.expiring3Months.map(
                (training, index) => <div key={index}>{training.title}</div>
              )
            ) : (
              <div>None</div>
            )}
          </div>
        </div>
        <div className="employeeSnapshot-qualifications">
          {svgMapper('qualifications')}
          <div className="employeeSnapshot--leftSide">
            <h3>Upcoming Qualifications</h3>
            {activeEmployee.qualifications &&
            activeEmployee.qualifications.upcomingQuals.length > 0 ? (
              activeEmployee.qualifications.upcomingQuals.map(
                (qualification, index) => (
                  <div key={index}>
                    {qualification.documentData.qualificationType.label}
                  </div>
                )
              )
            ) : (
              <div>None</div>
            )}
          </div>
          <div className="employeeSnapshot--rightSide">
            <h3>Expiring Qualifications</h3>
            {activeEmployee.qualifications &&
            activeEmployee.qualifications.expiring3Months.length > 0 ? (
              activeEmployee.qualifications.expiring3Months.map(
                (qualification, index) => (
                  <div key={index}>
                    {qualification.documentData.qualificationType.label}
                  </div>
                )
              )
            ) : (
              <div>None</div>
            )}
          </div>
        </div>
        <div className="employeeSnapshot-trainings">
          {svgMapper('Incidents')}
          <div className="employeeSnapshot--leftSide">
            <h3>Safety Incidents</h3>
            {activeEmployee.incidents && activeEmployee.incidents.length > 0 ? (
              activeEmployee.incidents.map((incident, index) => (
                <div key={index}>
                  {incident.reportTemplateIds.map((report, reportIndex) => (
                    <span className="employeeSnapshot--svgs" key={reportIndex}>
                      {svgMapper(report)}
                    </span>
                  ))}
                  <span className="employeeSnapshot--svgDates">
                    {`${moment(incident.createdAt).format(
                      'MM/DD/YYYY hh:mm a'
                    )}`}
                  </span>
                </div>
              ))
            ) : (
              <div>None</div>
            )}
          </div>
        </div>
        {accessLevel === '900' && (
          <div className="employeeSnapshot-userInfo">
            <img
              src={
                this.getUserAccessLevel(activeEmployee)
                  ? this.getUserAccessLevel(activeEmployee).picture
                  : require('../../assets/images/blank-profile-picture.jpg')
              }
              className="profileBadge"
              alt={`user type ${
                this.getUserAccessLevel(activeEmployee)?.level
              }`}
            />
            <div className="employeeSnapshot--leftSide">
              <h3>User Type</h3>
              <div>
                {this.getUserAccessLevel(activeEmployee)
                  ? this.getUserAccessLevel(activeEmployee).level
                  : 'No User Type Selected'}
              </div>
            </div>
          </div>
        )}
        {accessLevel === '900' && (
          <div className="employeeSnapshot-userInfo">
            <img
              src={this.getIsUserDeactivated(activeEmployee)?.picture}
              className="profileBadge"
              alt={`user type ${
                this.getIsUserDeactivated(activeEmployee)?.level
              }`}
            />
            <div className="employeeSnapshot--leftSide">
              <h3>Deactivate Employee:</h3>
              <div>{this.getIsUserDeactivated(activeEmployee)?.level}</div>
            </div>
          </div>
        )}
        <div className="employeeSnapshot-last5Docs">
          <img
            src={require('../../assets/images/User Snapshot_Document Icon.png')}
            className="profileBadge"
            alt="employee documents icon"
          />
          <div className="employeeSnapshot--leftSide">
            <h3>Employee Documents</h3>
            {activeEmployee.documents && activeEmployee.documents.length > 0 ? (
              activeEmployee.documents.map((document, index) => (
                <div key={index}>{document.label}</div>
              ))
            ) : (
              <div>None</div>
            )}
          </div>
        </div>
        {this.getUserAccessLevel(activeEmployee)?.level !== 'No User Info' && (
          <div className="employeeSnapshot-last5Interactions">
            <img
              src={require('../../assets/images/User Activity.png')}
              className="profileBadge"
              alt="employee documents icon"
            />
            <div className="employeeSnapshot-last5Interactions--displayRow">
              <div className="employeeSnapshot-last5Interactions--displayRowItems">
                <h3 className="employeeSnapshot-last5Interactions--alignText">
                  Tasks Assigned
                </h3>
                {activeEmployee.userAccount.systemInteractions.tasksData
                  .noData ? (
                  activeEmployee.userAccount.systemInteractions.tasksData
                    .noDataMessage
                ) : (
                  <div>
                    <div className="employeeSnapshot-last5Interactions--alignText">{`Performed On Time: ${activeEmployee.userAccount.systemInteractions.tasksData.performedOnTime}`}</div>
                    <div className="employeeSnapshot-last5Interactions--alignText">{`Past Due Tasks: ${activeEmployee.userAccount.systemInteractions.tasksData.pastDue}`}</div>
                  </div>
                )}
                {this.getUserAccessLevel(activeEmployee)?.level !==
                  'Reporter' &&
                  this.getUserAccessLevel(activeEmployee)?.level !==
                    'Deactivated' && (
                    <div>
                      <h3 className="employeeSnapshot-last5Interactions--alignText">
                        Tasks Created
                      </h3>
                      {activeEmployee.userAccount.systemInteractions.tasksData
                        .noData ? (
                        activeEmployee.userAccount.systemInteractions.tasksData
                          .noDataMessage
                      ) : (
                        <div>
                          <div className="employeeSnapshot-last5Interactions--alignText">{`Performed On Time: ${activeEmployee.userAccount.systemInteractions.tasksData.createdAndPerformedBeforeDueData}`}</div>
                          <div className="employeeSnapshot-last5Interactions--alignText">{`Past Due Tasks: ${activeEmployee.userAccount.systemInteractions.tasksData.createdAndNotPerformedBeforeTheDueDate}`}</div>
                        </div>
                      )}
                    </div>
                  )}
              </div>
              <div className="employeeSnapshot-last5Interactions--displayRowItems">
                <h3 className="employeeSnapshot-last5Interactions--alignText">
                  RA Assigned
                </h3>
                {activeEmployee.userAccount.systemInteractions.jsaData
                  .noData ? (
                  activeEmployee.userAccount.systemInteractions.jsaData
                    .noDataMessage
                ) : (
                  <div>
                    <div className="employeeSnapshot-last5Interactions--alignText">{`Performed On Time: ${activeEmployee.userAccount.systemInteractions.jsaData.performedOnTime}`}</div>
                    <div className="employeeSnapshot-last5Interactions--alignText">{`Past Due: ${activeEmployee.userAccount.systemInteractions.jsaData.pastDue}`}</div>
                  </div>
                )}
                {this.getUserAccessLevel(activeEmployee)?.level !==
                  'Reporter' &&
                  this.getUserAccessLevel(activeEmployee)?.level !==
                    'Deactivated' && (
                    <div>
                      <h3 className="employeeSnapshot-last5Interactions--alignText">
                        RA Created
                      </h3>
                      {activeEmployee.userAccount.systemInteractions.jsaData
                        .noData ? (
                        activeEmployee.userAccount.systemInteractions.jsaData
                          .noDataMessage
                      ) : (
                        <div>
                          <div className="employeeSnapshot-last5Interactions--alignText">{`Performed On Time: ${activeEmployee.userAccount.systemInteractions.jsaData.createdAndPerformedBeforeDueData}`}</div>
                          <div className="employeeSnapshot-last5Interactions--alignText">{`Past Due: ${activeEmployee.userAccount.systemInteractions.jsaData.createdAndNotPerformedBeforeTheDueDate}`}</div>
                        </div>
                      )}
                    </div>
                  )}
              </div>
              <div className="employeeSnapshot-last5Interactions--displayRowItems">
                <h3 className="employeeSnapshot-last5Interactions--alignText">
                  Reports Created
                </h3>
                {activeEmployee.userAccount.systemInteractions.reportData
                  .noData ? (
                  activeEmployee.userAccount.systemInteractions.reportData
                    .noDataMessage
                ) : (
                  <div>
                    <div className="employeeSnapshot-last5Interactions--alignText">{`${activeEmployee.userAccount.systemInteractions.reportData.numberOfReportsCreated}`}</div>
                  </div>
                )}
                {this.getUserAccessLevel(activeEmployee)?.level !==
                  'Reporter' &&
                  this.getUserAccessLevel(activeEmployee)?.level !==
                    'Deactivated' && (
                    <div>
                      <h3 className="employeeSnapshot-last5Interactions--alignText">
                        Reports Updated
                      </h3>
                      {activeEmployee.userAccount.systemInteractions.reportData
                        .noData ? (
                        activeEmployee.userAccount.systemInteractions.reportData
                          .noDataMessage
                      ) : (
                        <div>
                          <div className="employeeSnapshot-last5Interactions--alignText">{`${activeEmployee.userAccount.systemInteractions.reportData.numberOfReportsUpdated}`}</div>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
