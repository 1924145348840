import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderNumberField, required } from '../../utils/formValidation';

import '../form.css';

const PublicEmploymentForm = props => {
  const { handleSubmit } = props;

  return (
    <form className="oshaEmploymentForm" onSubmit={handleSubmit}>
      <div className="form-fields">
        <Field
          name="numberOfFullTime"
          component={renderNumberField}
          label="Full Time."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
        />
        <Field
          name="numberOfPartTime"
          component={renderNumberField}
          label="Part Time."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
        />
        <Field
          name="numberOfFireEmtParamedic"
          component={renderNumberField}
          label="Fire/EMT Paramedic."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
        />
        <Field
          name="numberOfPoliceFireEmt"
          component={renderNumberField}
          label="Police/Fire/EMT."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'PublicEmploymentForm'
})(PublicEmploymentForm);
