import React, { Component } from 'react';
import {
  ADMIN_ROLE,
  COLLABORATOR_ROLE,
  REPORTER_ROLE,
  SUPERVISOR_ROLE
} from '../../../constants/constants';

import ButtonSelector from '../ButtonSelector';

class UserAccessButtons extends Component {
  render() {
    const options = [
      {
        image: require('../../../assets/images/Reporter.png'),
        text: 'Reporter',
        value: '100',
        description: REPORTER_ROLE
      },
      {
        image: require('../../../assets/images/Supervisor.png'),
        text: 'Supervisor',
        value: '400',
        description: SUPERVISOR_ROLE
      },
      {
        image: require('../../../assets/images/Collaborator.png'),
        text: 'Collaborator',
        value: '500',
        description: COLLABORATOR_ROLE
      },
      {
        image: require('../../../assets/images/Admin.png'),
        text: 'Admin',
        value: '900',
        description: ADMIN_ROLE
      }
    ];

    return (
      <ButtonSelector
        onClick={this.props.handleButtonClick}
        options={options}
        value={this.props.currentValue}
      />
    );
  }
}

export default UserAccessButtons;
