import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import ReactLoading from 'react-loading';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  chargeCustomerRequest,
  createCustomerRequest,
  fetchAccountInformationRequest,
  updateCustomerRequest
} from '../../actions/payment';
import {
  getAccountInfo,
  getPaymentLoadingSelector
} from '../../selectors/payment';
import { getLoggedInUser } from '../../selectors/users';
import creditCardMapper from '../../utils/creditCardMapper';

import AddRemoveLicensesForm from '../../forms/AddRemoveLicensesForm';
import Button from '../../components/inputs/Button';
import Modal from '../../components/Modal';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';

import './index.css';

export class AccountDetails extends Component {
  state = {
    modalOpen: false,
    editCC: false,
    numberOfSupervisorLicenses: 0,
    numberOfCollaboratorAdminLicenses: 0,
    promoCode: ''
  };

  componentDidMount() {
    this.props.updateLeftNav('Account Details');
    this.props.fetchAccountInformation();
  }

  handleModalOpen() {
    this.setState({ modalOpen: true });
  }

  handleModalClose() {
    this.setState({ modalOpen: false });
  }

  handleLicenseSubmit = () => {
    this.setState({ modalOpen: false });

    this.props.chargeCard({
      accountInfo: this.props.accountInfo,
      numberOfSupervisorLicenses: this.state.numberOfSupervisorLicenses,
      numberOfCollaboratorAdminLicenses: this.state
        .numberOfCollaboratorAdminLicenses,
      promoCode: this.state.promoCode
    });
  };

  render() {
    const {
      modalOpen,
      editCC,
      numberOfCollaboratorAdminLicenses,
      numberOfSupervisorLicenses
    } = this.state;
    const { accountInfo, loading } = this.props;

    const canSubmitLicenses =
      numberOfCollaboratorAdminLicenses !== 0 ||
      numberOfSupervisorLicenses !== 0;

    return (
      <div className="accountDetails">
        <ScrollToTopOnMount />
        {loading ? (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        ) : editCC ? (
          <Elements></Elements>
        ) : accountInfo.cardDetails ? (
          <div className="accountDetails-ccInfo">
            <h3 className="accountDetails-header">Payment Information</h3>
            <p>
              <img
                src={creditCardMapper(accountInfo.cardDetails.brand)}
                alt={accountInfo.cardDetails.brand}
              />
              <span className="accountDetails-ccInfo--bold">
                Credit Card Number:
              </span>
              <span className="accountDetails-ccInfo--noBold">{` **** **** **** ${accountInfo.cardDetails.last4}`}</span>
              <span className="accountDetails-ccInfo--bold">Expiration:</span>
              <span className="accountDetails-ccInfo--noBold">{` ${accountInfo.cardDetails.exp_month}/${accountInfo.cardDetails.exp_year}`}</span>
            </p>
            <Button
              text="Edit Payment Info"
              className="update checkoutForm-button"
              type="button"
              onClick={() => this.setState({ editCC: true })}
            />
          </div>
        ) : (
          <div className="accountDetails-ccInfo">
            <h3 className="accountDetails-header">Payment Information</h3>
            <h4>There is no payment information on file.</h4>
            <Button
              text="Edit Payment Info"
              className="update checkoutForm-button"
              type="button"
              onClick={() => this.setState({ editCC: true })}
            />
          </div>
        )}

        <hr />
        <h3 className="accountDetails-header">Licenses Purchased Breakdown</h3>
        <div className="accountDetails-header">
          <p>{`# of Supervisor Licenses Available: ${
            accountInfo.availableSupervisorLicenseCount || 0
          }`}</p>
          <p>{`# of Admin/Collaborator Licenses Available: ${
            accountInfo.availableAdminCollaboratorLicenseCount || 0
          }`}</p>
          <p>{`# of Supervisor Licenses Total: ${
            accountInfo.activeSupervisorLicenseCount || 0
          }`}</p>
          <p>{`# of Admin/Collaborator Licenses Total: ${
            accountInfo.activeAdminCollaboratorLicenseCount || 0
          }`}</p>
          <p>{`# of Total Licenses Purchased: ${
            (accountInfo.activeAdminCollaboratorLicenseCount || 0) +
            (accountInfo.activeSupervisorLicenseCount || 0)
          }`}</p>
        </div>
        <Button
          type="button"
          className="update checkoutForm-button"
          text="Add Licenses"
          onClick={() => this.handleModalOpen()}
        />
        <Modal
          title="Add Licenses"
          titleClassName="greenHeader"
          isOpen={modalOpen}
          submitButtonColor="green"
          onRequestClose={() => this.handleModalClose()}
          submitActions={() => this.handleLicenseSubmit()}
          disableSubmit={!canSubmitLicenses}
        >
          <AddRemoveLicensesForm
            updateCardInfo={values => this.setState(values)}
          />
        </Modal>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  accountInfo: getAccountInfo(state),
  loading: getPaymentLoadingSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  createCustomer: info => dispatch(createCustomerRequest(info)),
  fetchAccountInformation: () => dispatch(fetchAccountInformationRequest()),
  chargeCard: chargeInfo => dispatch(chargeCustomerRequest(chargeInfo)),
  updateCustomer: info => dispatch(updateCustomerRequest(info))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
