import React, { Component } from 'react';
import Textbox from '../../components/inputs/Textbox';

class AddRemoveLicensesForm extends Component {
  state = {
    numberOfSupervisorLicenses: 0,
    numberOfCollaboratorAdminLicenses: 0,
    promoCode: '',
    touched: {
      numberOfLicenses: false
    }
  };

  updateState(stateObject) {
    this.setState(stateObject);
    this.props.updateCardInfo(stateObject);
  }

  handleBlur = field => evt => {
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });
  };

  render() {
    const {
      numberOfSupervisorLicenses,
      numberOfCollaboratorAdminLicenses,
      promoCode
    } = this.state;

    return (
      <div className="addRemoveLicensesForm">
        <h3>How many licenses would you like to add?</h3>
        <div className="form-fields--split">
          <div className="form-fields--leftSide">
            <Textbox
              fieldLabel="Number of Supervisor Licenses:"
              handleChange={e =>
                this.updateState({ numberOfSupervisorLicenses: e.target.value })
              }
              onBlur={this.handleBlur('numberOfSupervisorLicenses')}
              currentValue={numberOfSupervisorLicenses}
              type="number"
            />
            <Textbox
              fieldLabel="Number of Collaborator/Admin Licenses:"
              handleChange={e =>
                this.updateState({
                  numberOfCollaboratorAdminLicenses: e.target.value
                })
              }
              onBlur={this.handleBlur('numberOfCollaboratorAdminLicenses')}
              currentValue={numberOfCollaboratorAdminLicenses}
              type="number"
            />
            <Textbox
              fieldLabel="Promo Code:"
              handleChange={e =>
                this.updateState({ promoCode: e.target.value })
              }
              onBlur={this.handleBlur('promoCode')}
              currentValue={promoCode}
            />
          </div>
          <div className="form-fields--rightSide">
            <p>Price per Supervisor License: $24</p>
            <p>Price per Admin/Collaborator License: $99</p>
            <p>
              Number of Licenses:
              {` ${numberOfSupervisorLicenses} Supervisors + ${numberOfCollaboratorAdminLicenses} Admin/Collaborator`}
            </p>
            <p>
              Total Billed (per month): $
              {24 * numberOfSupervisorLicenses +
                99 * numberOfCollaboratorAdminLicenses}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AddRemoveLicensesForm;
