import {
  CHARGE_CUSTOMER_REQUEST,
  CHARGE_CUSTOMER_RESPONSE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_RESPONSE,
  FETCH_ACCOUNT_INFORMATION_REQUEST,
  FETCH_ACCOUNT_INFORMATION_RESPONSE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  paymentInfo: {},
  customerInfo: {}
};

const payments = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ACCOUNT_INFORMATION_REQUEST:
    case CHARGE_CUSTOMER_REQUEST:
    case CREATE_CUSTOMER_REQUEST:
    case UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FETCH_ACCOUNT_INFORMATION_RESPONSE:
    case CREATE_CUSTOMER_RESPONSE:
    case UPDATE_CUSTOMER_RESPONSE:
      return {
        ...state,
        loading: false,
        customerInfo: payload
      };

    case CHARGE_CUSTOMER_RESPONSE:
      return {
        ...state,
        loading: false,
        paymentInfo: payload
      };

    default:
      return state;
  }
};

export default payments;
