import React, { Component } from 'react';
import { withResizeDetector } from 'react-resize-detector';

import isEqual from 'lodash/isEqual';

import styles from './styles.module.scss';

import Loading from '../Loading';
import Input from '../inputs';
import Button from '../inputs/Button';

export class PelletDrop extends Component {
  constructor(props) {
    super(props);

    this.state = { hasTargetPellet: false };
    this.pellet = this.props.pellet || Pellet;
  }

  onEnter = e => {
    this.setState({ hasTargetPellet: true });
  };

  onLeave = e => {
    this.setState({ hasTargetPellet: false });
  };

  onDragOver = e => {
    e.preventDefault();
  };

  onDrop = e => {
    let pellet = JSON.parse(e.dataTransfer.getData('props'));
    if (this.props.onDrop) this.props.onDrop(pellet);
    this.setState({ hasTargetPellet: false });
  };

  deletePellet = i => {
    if (this.props.onDelete) this.props.onDelete(i);
  };

  render() {
    const target = this.state.hasTargetPellet;
    const empty = !this.props.pellets || this.props.pellets.length === 0;
    const pellets = Array.isArray(this.props.pellets)
      ? this.props.pellets
      : [this.props.pellets];
    return (
      <Input {...this.props}>
        <div
          className={`${styles.drop} ${target && styles.dropTarget}`}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
          onDragEnter={this.onEnter}
          onDragLeave={this.onLeave}
        >
          {empty && !target && (
            <span className={styles.placeholder}>
              {this.props.placeholder || 'Select an option'}
            </span>
          )}
          {!empty &&
            pellets.map((p, i) => (
              <this.pellet
                {...p}
                key={p.value}
                delete={() => this.deletePellet(i)}
                target={this.props.oneOnly && target}
                clearAll={() => this.props.clearAll(i)}
                selectAll={() => this.props.selectAll(i)}
                selectOne={j => this.props.selectValue(i, j)}
              />
            ))}
          {target && (!this.props.oneOnly || empty) && (
            <div className={styles.targetPellet}>Drop Here</div>
          )}
        </div>
      </Input>
    );
  }
}

export class Pellet extends Component {
  onDragStart = e => {
    e.dataTransfer.setData('props', JSON.stringify(this.props));
  };

  render() {
    return (
      <div
        className={this.props.target ? styles.targetPellet : styles.pellet}
        draggable
        onDragStart={this.onDragStart}
      >
        {this.props.label}
        {this.props.delete && (
          <div className={styles.delete} onClick={this.props.delete}>
            &times;
          </div>
        )}
      </div>
    );
  }
}

export class SelectValuesPellet extends Component {
  onDragStart = e => {
    e.dataTransfer.setData('props', JSON.stringify(this.props));
  };

  render() {
    const values = this.props.values || [];
    let inside;
    if (this.props.isLoading) {
      inside = <Loading />;
    } else {
      inside = (
        <>
          <div className={styles.buttons}>
            <Button
              color="blue"
              onClick={this.props.selectAll}
              inputClassName={styles.button}
              text="Select All"
            />
            <Button
              color="white"
              onClick={this.props.clearAll}
              inputClassName={styles.button}
              text="Clear"
            />
          </div>
          <div className={styles.options}>
            {values.map((v, i) => (
              <div key={v.value}>
                <input
                  type="checkbox"
                  checked={v.selected}
                  onChange={() => {
                    this.props.selectOne && this.props.selectOne(i);
                  }}
                />
                {v.label}
              </div>
            ))}
          </div>
        </>
      );
    }
    return (
      <div className={`${styles.pellet} ${styles.selectValuePellet}`}>
        <div>
          {this.props.label}
          {this.props.delete && (
            <div className={styles.delete} onClick={this.props.delete}>
              &times;
            </div>
          )}
        </div>
        {inside}
      </div>
    );
  }
}

class PelletContainer extends Component {
  #offset;
  #maxPerPage;

  constructor(props) {
    super(props);

    this.maxPerPage = 10;
    this.offset = parseInt(styles.pelletContainerSizeOffset, 10);

    this.state = {
      page: 0,
      perPage: this.maxPerPage
    };
  }

  componentDidMount() {
    this.sizeMe();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.pellets, prevProps.pellets)) {
      this.setState({ page: 0 });
    }
    if (this.props.height !== prevProps.height) this.sizeMe();
  }

  sizeMe() {
    const contentHeight = this.ref.scrollHeight;
    const height = this.props.height - this.offset;
    const biggerSize =
      contentHeight * ((this.state.perPage + 1.5) / this.state.perPage);
    const pages = Math.ceil(this.props.pellets?.length / this.state.perPage);

    if (contentHeight > height && this.state.perPage > 2) {
      this.setState((state, props) => {
        let newState = { ...state };
        newState.perPage--;
        return newState;
      });
    } else if (
      height > biggerSize &&
      this.state.perPage < this.maxPerPage &&
      this.state.page < pages
    ) {
      this.setState((state, props) => {
        let newState = { ...state };
        newState.perPage++;
        const newPages = Math.ceil(this.props.pellets?.length / newState.perPage);
        if (newState.page >= newPages) newState.page = newPages - 1;
        return newState;
      });
    }
  }

  render() {
    const { pellets } = this.props;
    const { page, perPage } = this.state;
    const pages = Math.ceil(this.props.pellets?.length / this.state.perPage);
    let pager = [];
    let classes = [styles.container];

    if (this.props.className) classes.push(this.props.className);

    for (let i = 0; pages > 1 && i < pages; i++)
      pager.push(
        <div
          key={i}
          onClick={() => {
            this.setState({ page: i });
          }}
        >
          <div className={i === this.state.page ? styles.selectedPage : ''} />
        </div>
      );

    return (
      <div className={classes.join(' ')}>
        <div className={styles.pelletContainer}>
          <div
            ref={el => {
              this.ref = el;
            }}
          >
            {pellets?.length
              ? pellets
                  .slice(page * perPage, (page + 1) * perPage)
                  .map(pellet => <Pellet {...pellet} key={pellet.value} />)
              : this.props.empty && (
                  <div className={styles.placeholder}>{this.props.empty}</div>
                )}
          </div>
        </div>
        {pager.length ? (
          <div className={styles.pagination}>{pager}</div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const PelletContainerHOC = withResizeDetector(PelletContainer);
export { PelletContainerHOC as PelletContainer };
