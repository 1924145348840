import React, { Component } from 'react';
import Konva from 'konva';
import { Image, Layer, Stage } from 'react-konva';
import useImage from 'use-image';

import { classnames } from '../../utils/classNamesHelper';
import HeaderAndFooter from '../HeaderAndFooter';
import Header from '../Header';
import { InputRow } from '../inputs';
import Button from '../inputs/Button';
import Modal from '../Modal';
import Textbox from '../inputs/Textbox';
import HoverImage from '../HoverImage';

import styles from './diagram.module.scss';

const IntersectionImage = props => {
  const [image] = useImage(props.image);
  return <Image image={image} width={props.width} height={props.height} />;
};

const brushTypes = [
  {
    src: require('../../assets/images/General Brush.png'),
    name: 'General',
    type: 'freeLine',
    color: '#58A15B'
  },
  {
    src: require('../../assets/images/Movement Brush.png'),
    name: 'Movement',
    type: 'movement',
    color: '#2CA0A3',
    category: 'roadDiagram'
  },
  {
    src: require('../../assets/images/Railroad Brush.png'),
    name: 'Railroad',
    type: 'railroad',
    color: '#C74846',
    category: 'roadDiagram'
  }
];

const textColors = [
  {
    name: 'Green',
    color: '#58A15B'
  },
  {
    name: 'Red',
    color: '#C74846'
  },
  {
    name: 'Blue',
    color: '#2CA0A3'
  },
  {
    name: 'Black',
    color: '#000000'
  }
];

class Diagram extends Component {
  state = {
    isDrawing: false,
    points: [],
    stopAt: null,
    mode: 'brush',
    brushType: 'freeLine',
    brushColor: '#58A15B',
    modalOpen: false,
    namingModalOpen: false,
    annotationModalOpen: false,
    diagramName: '',
    annotation: '',
    clearModal: false,
    deleteModal: false,
    vehiclesPage: 1
  };

  constructor(props) {
    super(props);
    this.annotationFocus = React.createRef();
  }

  componentDidMount = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 500;
    canvas.height = 500;

    var context = canvas.getContext('2d');
    context.lineJoin = 'round';
    context.lineWidth = 2;

    this.setState({ canvas });

    this.addDragListeners();
  };

  addDragListeners = () => {
    const stage = this.image.parent.parent;
    const layer = this.image.getLayer();

    var con = stage.container();
    const setState = (image, transformer) => {
      this.setState({
        deleteImage: image,
        deleteModal: true,
        transformer: transformer
      });
    };

    con.addEventListener('dragover', function(e) {
      e.preventDefault();
    });

    con.addEventListener('drop', function(e) {
      e.preventDefault();
      stage.setPointersPositions(e);

      var imageUrl = e.dataTransfer.getData('URL');

      Konva.Image.fromURL(imageUrl, function(image) {
        var tr = new Konva.Transformer({
          enabledAnchors: [
            'top-left',
            'top-center',
            'middle-left',
            'middle-right',
            'bottom-center',
            'bottom-left',
            'bottom-right'
          ],
          node: image
        });
        layer.add(image);

        image.position({
          x: stage.getPointerPosition().x,
          y: stage.getPointerPosition().y
        });

        if (
          imageUrl.indexOf('Stop') > -1 ||
          imageUrl.indexOf('Stop Sign') > 1 ||
          imageUrl.indexOf('Obsta') > -1
        ) {
          image.width(35);
          image.height(35);
        } else if (imageUrl.indexOf('data:image') > -1) {
          image.width(26);
          image.height(35);
        } else if (imageUrl.indexOf('Traffic') > -1) {
          image.width(21);
          image.height(35);
        } else if (imageUrl.indexOf('Yield') > -1) {
          image.width(35);
          image.height(32);
        } else {
          image.width(50);
          image.height(25);
        }

        image.draggable(true);
        image.dragBoundFunc(function(pos) {
          const newX = pos.x < 0 ? 0 : pos.x > 465 ? 465 : pos.x;
          const newY = pos.y > 483 ? 483 : pos.y < 0 ? 0 : pos.y;
          return {
            x: newX,
            y: newY
          };
        });

        image.on('click', function(transformer) {
          if (tr.getNode()) {
            tr.detach();
            layer.draw();
          } else {
            tr.keepRatio(true);
            tr.attachTo(image);
            layer.add(tr);
            layer.draw();
          }
        });

        image.on('blur', function(transformer) {
          tr.detach();
          layer.draw();
        });

        Konva.Image.fromURL(
          require('../../assets/images/actionClose.png'),
          function(deleteButton) {
            deleteButton.width(20);
            deleteButton.height(20);

            if (tr.width() < 60) {
              deleteButton.position({
                x: tr.width() - 10,
                y: -10
              });
            } else {
              deleteButton.position({
                x: tr.width() - 60,
                y: -10
              });
            }

            tr.add(deleteButton);

            tr.on('transform', () => {
              deleteButton.x(tr.getWidth());
            });

            deleteButton.on('click', () => {
              setState(image, tr);
            });
          }
        );

        layer.draw();
      });
    });
  };

  deleteImage = () => {
    const { deleteImage, transformer } = this.state;

    transformer.detach();
    deleteImage.destroy();
    this.image.getLayer().draw();

    this.setState({ deleteModal: false, deleteImage: null, transformer: null });
  };

  addText = () => {
    this.addPoint({
      color: this.state.brushColor,
      mode: 'text',
      text: this.state.annotation
    });
    this.setState({
      annotationModalOpen: false,
      annotation: ''
    });
  };

  handleArrow = (ctx, tox, toy, fromx, fromy, brushColor) => {
    var headlen = 15;
    var angle = Math.atan2(toy - fromy, tox - fromx);

    ctx.beginPath();
    ctx.moveTo(tox, toy);
    ctx.lineTo(
      tox - headlen * Math.cos(angle - Math.PI / 7),
      toy - headlen * Math.sin(angle - Math.PI / 7)
    );

    ctx.lineTo(
      tox - headlen * Math.cos(angle + Math.PI / 7),
      toy - headlen * Math.sin(angle + Math.PI / 7)
    );

    ctx.lineTo(tox, toy);
    ctx.lineTo(
      tox - headlen * Math.cos(angle - Math.PI / 7),
      toy - headlen * Math.sin(angle - Math.PI / 7)
    );

    ctx.strokeStyle = brushColor;
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.fillStyle = brushColor;
    ctx.fill();
  };

  drawDash = (ctx, atx, aty, slope) => {
    const lineLen = 15;
    const angle = Math.atan(slope) + Math.PI / 2;

    ctx.beginPath();
    ctx.moveTo(
      atx + lineLen * Math.cos(angle),
      aty + lineLen * Math.sin(angle)
    );
    ctx.lineTo(
      atx - lineLen * Math.cos(angle),
      aty - lineLen * Math.sin(angle)
    );

    let oldWidth = ctx.lineWidth;
    ctx.lineWidth = 3;
    ctx.stroke();
    ctx.lineWidth = oldWidth;
  };

  handleMouseDown = () => {
    if (this.state.mode !== 'brush') return;

    this.setState({ isDrawing: true });

    const { brushColor, brushType } = this.state;

    this.addPoint({
      size: 2,
      color: brushColor,
      mode: 'begin',
      tpye: brushType
    });
  };

  handleMouseOut = () => {
    if (this.state.isDrawing) {
      this.addPoint({
        size: 2,
        color: this.state.brushColor,
        mode: 'end',
        type: this.state.brushType
      });
      this.setState({ isDrawing: false });
    }
  };

  handleMouseUp = () => {
    if (this.state.mode === 'brush') {
      const { brushType, brushColor } = this.state;

      this.setState({ isDrawing: false });

      this.addPoint({
        size: 2,
        color: brushColor,
        mode: 'end',
        type: brushType
      });
    } else if (this.state.mode === 'text') {
      const stage = this.image.parent.parent;
      this.lastPointerPosition = stage.getPointerPosition();
      this.setState({ annotationModalOpen: true });
      this.annotationFocus.current && this.annotationFocus.current.focus();
    }
  };

  handleMouseMove = () => {
    const { isDrawing, brushColor, brushType } = this.state;

    if (isDrawing) {
      this.addPoint({
        mode: 'brush',
        size: 2,
        color: brushColor,
        type: brushType
      });
    }
  };

  addPoint = point => {
    let last = {};
    if (point.mode !== 'text') {
      last.x = this.lastPointerPosition?.x;
      last.y = this.lastPointerPosition?.y;
      const stage = this.image.parent.parent;
      this.lastPointerPosition = stage.getPointerPosition();
    }
    point.x = this.lastPointerPosition.x;
    point.y = this.lastPointerPosition.y;

    this.setState(
      state => {
        let points = state.points.slice(0, state.stopAt ?? state.points.length);
        return {
          points: [...points, point],
          stopAt: null
        };
      },
      () => this.drawPoint()
    );
  };

  drawPoint = (i, isBatch = false) => {
    const { canvas, points } = this.state;
    const context = canvas.getContext('2d');
    i = i ?? points.length - 1;
    const point = points[i];
    const last = points[i - 1];
    switch (point.mode) {
      case 'begin':
        if (isBatch) {
          context.strokeStyle = point.color;
          context.globalCompositeOperation = 'source-over';
          context.beginPath();
          context.moveTo(point.x, point.y);
        }
        break;
      case 'brush':
        if (isBatch) {
          context.lineTo(point.x, point.y);
        } else {
          context.strokeStyle = point.color;
          context.globalCompositeOperation = 'source-over';
          context.beginPath();
          context.moveTo(last.x, last.y);
          context.lineTo(point.x, point.y);
          context.closePath();
          context.stroke();
        }
        break;
      case 'end':
        if (isBatch) {
          context.stroke();
        }
        if (point.type === 'movement') {
          let x = 0;
          let y = 0;
          let j;
          for (j = i; j && points[j].mode !== 'begin' && j > i - 4; j--) {
            x += points[j].x;
            y += points[j].y;
          }
          x /= i - j;
          y /= i - j;
          this.handleArrow(context, point.x, point.y, x, y, point.color);
        } else if (point.type === 'railroad') {
          let deltas = [];
          let pts = [];
          const hashEvery = 3;
          for (let j = 1; i - j && points[i - j].mode !== 'begin'; j++) {
            let cp = points[i - j];
            if (j && !(j % hashEvery)) {
              let hashPoint = pts.shift();
              let sums = deltas.reduce(
                (acc, cur) => ({ x: acc.x + cur.x, y: acc.y + cur.y }),
                { x: 0, y: 0 }
              );
              let slope = sums.y / sums.x;
              this.drawDash(context, hashPoint.x, hashPoint.y, slope);
            }
            if (deltas.length >= 2 * hashEvery) deltas.shift();
            if (pts.length >= hashEvery) pts.shift();
            pts.push({ x: cp.x, y: cp.y });
            deltas.push({
              x: cp.x - points[i - j - 1].x,
              y: cp.y - points[i - j - 1].y
            });
          }
        }
        break;
      case 'text':
        context.font = '16px sans-serif';
        let oldFillStyle = context.fillStyle;
        context.fillStyle = point.color;
        context.fillText(point.text, point.x, point.y);
        context.fillStyle = oldFillStyle;
        break;
      default:
        break;
    }

    if (!isBatch) {
      this.image.getLayer().draw();
    }
  };

  redrawAll = points => {
    const { canvas } = this.state;

    const clientRect = this.image.getClientRect();
    this.image.parent
      .getContext()
      .clearRect(
        clientRect.x,
        clientRect.y,
        clientRect.width,
        clientRect.height
      );

    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.lineJoin = 'round';
    context.lineWidth = 2;

    for (var i = 0; i < points.length; i++) {
      this.drawPoint(i, true);
    }
    this.image.getLayer().draw();
  };

  // All buttons for canvas
  clearStage = () => {
    const stampLayer = this.image.getLayer();
    const images = stampLayer
      .find(node => {
        return (
          node.getClassName() === 'Image' ||
          node.getClassName() === 'Transformer'
        );
      })
      .splice(1);

    images.map(image => image.destroy());
    stampLayer.draw();

    const canvas = document.createElement('canvas');
    canvas.width = 500;
    canvas.height = 500;

    var context = canvas.getContext('2d');
    context.lineJoin = 'round';
    context.lineWidth = 2;

    this.setState({ canvas, points: [], clearModal: false, stopAt: null });
  };

  handleUndoAction = () => {
    this.setState(state => {
      let { points, stopAt } = state;
      let indexOfLastBegin = 0;

      for (
        let i = 0;
        i < points.length && (stopAt === null || i < stopAt);
        i++
      ) {
        if (points[i].mode === 'begin' || points[i].mode === 'text')
          indexOfLastBegin = i;
      }

      this.redrawAll(state.points.slice(0, indexOfLastBegin));
      return { stopAt: indexOfLastBegin };
    });
  };

  handleRedoAction = () => {
    this.setState(state => {
      let { points, stopAt } = state;

      for (stopAt++; stopAt < points.length; stopAt++) {
        if (points[stopAt].mode === 'begin' || points[stopAt].mode === 'text')
          break;
      }

      this.redrawAll(state.points.slice(0, stopAt));
      if (stopAt === points.length) stopAt = null;
      return { stopAt };
    });
  };

  handleAccidentSubmit = () => {
    const { diagramName } = this.state;
    const dataUrl = this.image.parent.parent.toDataURL();

    var binary = atob(dataUrl.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const file = new Blob([new Uint8Array(array)], { type: 'image/png' });

    let data = new FormData();
    let fileName = diagramName;

    switch (this.props.category) {
      case 'bodyDiagram':
        fileName += '_bodydiagram.png';
        break;
      case 'vehicleDiagram':
        fileName += '_vehiclediagram.png';
        break;
      case 'aircraftDiagram':
        fileName += '_aircraftdiagram.png';
        break;
      case 'roadDiagram':
        fileName += '_roaddiagram.png';
        break;
      default:
        fileName += '_diagram.png';
        break;
    }
    data.append('attachments', file, fileName);

    this.props.addAttachment({
      data,
      ownerId: '1234',
      ownerType: 'incident'
    });
    this.props.resetDiagrams();
  };

  render() {
    const {
      canvas,
      brushType,
      modalOpen,
      namingModalOpen,
      diagramName,
      clearModal,
      points,
      stopAt
    } = this.state;

    const { imageUrl, resetDiagrams } = this.props;
    const { vehiclesPage } = this.state;

    const header = (
      <Header
        clickBack={() => this.setState({ modalOpen: true })}
        needsSaved={points.length}
        center={
          <InputRow>
            <Button
              color="red"
              disabled={points.length === 0}
              text="Clear"
              onClick={() => this.setState({ clearModal: true })}
              type="button"
            />
            <Button
              color="blue"
              disabled={points.length === 0 || stopAt === 0}
              text="Undo"
              onClick={() => this.handleUndoAction()}
              type="button"
            />
            <Button
              color="blue"
              disabled={stopAt === null || stopAt >= points.length}
              text="Redo"
              onClick={this.handleRedoAction}
              type="button"
            />
            <Button
              color="green"
              text="Submit"
              onClick={() => this.setState({ namingModalOpen: true })}
              type="button"
              disabled={!(this.state.stopAt ?? this.state.points.length)}
            />
          </InputRow>
        }
      />
    );

    const classes = {
      [styles.canvas]: true,
      [styles.dragAndDrop]: this.state.mode === 'dragAndDrop'
    };

    return (
      <>
        <HeaderAndFooter Header={header} className={styles.container}>
          <div className={styles.tools}>
            <HoverImage
              src={require('../../assets/images/unselectedDraw.png')}
              srcSelected={require('../../assets/images/selectedDraw.png')}
              srcHover={require('../../assets/images/unselectedDrawHover.png')}
              srcHoverSelected={require('../../assets/images/selectedDrawHover.png')}
              alt="Selected Drawing"
              onClick={() =>
                this.setState({
                  mode: 'brush',
                  brushType: brushTypes[0].type,
                  brushColor: brushTypes[0].color
                })
              }
              selected={this.state.mode === 'brush'}
            />
            <HoverImage
              src={require('../../assets/images/unselectedText.png')}
              srcSelected={require('../../assets/images/selectedText.png')}
              srcHover={require('../../assets/images/unselectedTextHover.png')}
              srcHoverSelected={require('../../assets/images/selectedTextHover.png')}
              alt="Selected Text"
              onClick={() => this.setState({ mode: 'text' })}
              selected={this.state.mode === 'text'}
            />
            {this.props.category === 'roadDiagram' && (
              <HoverImage
                src={require('../../assets/images/unselectedDragAndDrop.png')}
                srcSelected={require('../../assets/images/selectedDragAndDrop.png')}
                srcHover={require('../../assets/images/unselectedDragAndDropHover.png')}
                srcHoverSelected={require('../../assets/images/selectedDragAndDropHover.png')}
                alt="Selected Drag and Drop"
                onClick={() => this.setState({ mode: 'dragAndDrop' })}
                selected={this.state.mode === 'dragAndDrop'}
              />
            )}
          </div>
          <div className={classnames(classes)}>
            <Stage width={500} height={500}>
              <Layer>
                <IntersectionImage width={500} height={500} image={imageUrl} />
              </Layer>
              <Layer>
                <Image
                  image={canvas}
                  ref={node => (this.image = node)}
                  onMouseDown={this.handleMouseDown}
                  onMouseUp={this.handleMouseUp}
                  onMouseMove={this.handleMouseMove}
                  onMouseOut={this.handleMouseOut}
                  onTouchStart={this.handleMouseDown}
                  onTouchMove={this.handleMouseMove}
                  onTouchEnd={this.handleMouseUp}
                />
              </Layer>
            </Stage>
          </div>
          {this.state.mode === 'text' && (
            <div>
              <div className={styles.brushes}>
                <div className={styles.title}>Text Color</div>
                {textColors.map((color, i) => (
                  <div
                    key={i}
                    className={
                      this.state.brushColor === color.color
                        ? styles.selected
                        : ''
                    }
                    onClick={() => this.setState({ brushColor: color.color })}
                  >
                    <div
                      className={styles.spot}
                      style={{ backgroundColor: color.color }}
                    />
                    <div>{color.name}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {this.state.mode === 'brush' && (
            <div>
              <div className={styles.brushes}>
                <div className={styles.title}>Brushes</div>
                {brushTypes.map((brush, i) => {
                  if (
                    (brush?.category ?? this.props.category) !==
                    this.props.category
                  )
                    return <></>;
                  return (
                    <div
                      key={i}
                      className={
                        brushType === brush.type ? styles.selected : ''
                      }
                      onClick={() =>
                        this.setState({
                          brushType: brush.type,
                          brushColor: brush.color
                        })
                      }
                    >
                      <img src={brush.src} alt={`Use ${brush.name} Brush`} />
                      <div>{brush.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {this.state.mode === 'dragAndDrop' && (
            <div style={{ position: 'relative' }}>
              <div className={styles.pagination}>
                <div
                  onClick={() =>
                    this.state.vehiclesPage !== 5 &&
                    this.setState(state => ({
                      vehiclesPage: state.vehiclesPage + 1
                    }))
                  }
                >
                  {vehiclesPage === 5 ? (
                    <img
                      src={require('../../assets/images/previousInactive.png')}
                      style={{ width: '24px', height: '24px' }}
                      alt="top of vehicle selection"
                    />
                  ) : (
                    <img
                      src={require('../../assets/images/previousActive.png')}
                      style={{ width: '24px', height: '24px' }}
                      alt="top of vehicle selection"
                    />
                  )}
                </div>
                <div
                  onClick={() =>
                    vehiclesPage !== 1 &&
                    this.setState(state => ({
                      vehiclesPage: state.vehiclesPage - 1
                    }))
                  }
                >
                  {vehiclesPage === 1 ? (
                    <img
                      src={require('../../assets/images/nextInactive.png')}
                      style={{ width: '24px', height: '24px' }}
                      alt="next page of vehicles"
                    />
                  ) : (
                    <img
                      src={require('../../assets/images/nextActive.png')}
                      style={{ width: '24px', height: '24px' }}
                      alt="next page of vehicles"
                    />
                  )}
                </div>
              </div>
              <div className={styles.dragBin}>
                <div className={styles.title}>Drag & Drop</div>
                <div className={styles.section}>
                  <img
                    src={require(`../../assets/images/${1 +
                      4 * (vehiclesPage - 1)}.png`)}
                    style={{ width: '40px', height: '20px' }}
                    draggable
                    onDragStart={() =>
                      this.setState({
                        dragImage: require(`../../assets/images/${1 +
                          4 * (vehiclesPage - 1)}.png`)
                      })
                    }
                    alt={`select car ${1 + 4 * (vehiclesPage - 1)}`}
                  />
                  <img
                    src={require(`../../assets/images/${2 +
                      4 * (vehiclesPage - 1)}.png`)}
                    style={{ width: '40px', height: '20px' }}
                    draggable
                    onDragStart={() =>
                      this.setState({
                        dragImage: require(`../../assets/images/${2 +
                          4 * (vehiclesPage - 1)}.png`)
                      })
                    }
                    alt={`select car ${2 + 4 * (vehiclesPage - 1)}`}
                  />
                  <img
                    src={require(`../../assets/images/${3 +
                      4 * (vehiclesPage - 1)}.png`)}
                    style={{ width: '40px', height: '20px' }}
                    draggable
                    onDragStart={() =>
                      this.setState({
                        dragImage: require(`../../assets/images/${3 +
                          4 * (vehiclesPage - 1)}.png`)
                      })
                    }
                    alt={`select car ${3 + 4 * (vehiclesPage - 1)}`}
                  />
                  <img
                    src={require(`../../assets/images/${4 +
                      4 * (vehiclesPage - 1)}.png`)}
                    style={{ width: '40px', height: '20px' }}
                    draggable
                    onDragStart={() =>
                      this.setState({
                        dragImage: require(`../../assets/images/${4 +
                          4 * (vehiclesPage - 1)}.png`)
                      })
                    }
                    alt={`select car ${4 + 4 * (vehiclesPage - 1)}`}
                  />
                  <div className={styles.desc}>Vehicles</div>
                </div>
                <div className={styles.section}>
                  <div className={styles.row}>
                    <img
                      src={require('../../assets/images/Stop Sign.png')}
                      style={{ width: '35px', height: '35px' }}
                      draggable
                      onDragStart={() =>
                        this.setState({
                          dragImage: require('../../assets/images/Stop Sign.png')
                        })
                      }
                      alt="select stop sign"
                    />
                    <img
                      src={require('../../assets/images/Bus Stop.png')}
                      style={{ width: '35px', height: '35px' }}
                      draggable
                      onDragStart={() =>
                        this.setState({
                          dragImage: require('../../assets/images/Bus Stop.png')
                        })
                      }
                      alt="select bus stop"
                    />
                  </div>
                  <div className={styles.row}>
                    <img
                      src={require('../../assets/images/Yield Sign.png')}
                      style={{ width: '35px', height: '32px' }}
                      draggable
                      onDragStart={() =>
                        this.setState({
                          dragImage: require('../../assets/images/Yield Sign.png')
                        })
                      }
                      alt="select yield sign"
                    />
                    <img
                      src={require('../../assets/images/Traffic Signal.png')}
                      style={{ width: '21px', height: '35px' }}
                      draggable
                      onDragStart={() =>
                        this.setState({
                          dragImage: require('../../assets/images/Traffic Signal.png')
                        })
                      }
                      alt="select traffic signal"
                    />
                  </div>
                  <div className={styles.desc}>Signs & Signal</div>
                </div>
                <div className={styles.section}>
                  <div className={styles.row}>
                    <img
                      src={require('../../assets/images/Obstacle.png')}
                      style={{ width: '35px', height: '35px' }}
                      draggable
                      onDragStart={() =>
                        this.setState({
                          dragImage: require('../../assets/images/Obstacle.png')
                        })
                      }
                      alt="select obstacle"
                    />
                    <img
                      src={require('../../assets/images/Pedestrian.png')}
                      style={{ width: '26px', height: '35px' }}
                      draggable
                      onDragStart={() =>
                        this.setState({
                          dragImage: require('../../assets/images/Pedestrian.png')
                        })
                      }
                      alt="select person"
                    />
                  </div>
                  <div className={styles.desc}>Object & Person</div>
                </div>
              </div>
            </div>
          )}
        </HeaderAndFooter>
        <Modal
          title="Clear?"
          titleClassName="redHeader"
          isOpen={clearModal}
          submitButtonColor="red"
          submitButtonText="Clear Everything"
          onRequestClose={() =>
            this.setState({
              clearModal: false
            })
          }
          submitActions={() => this.clearStage()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Do you really want to clear everything?{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              You won't be able to undo this!
            </span>
          </div>
        </Modal>
        <Modal
          title="Are you sure you want to go back?"
          titleClassName="redHeader"
          isOpen={modalOpen}
          submitButtonColor="red"
          submitButtonText="Go Back"
          onRequestClose={() =>
            this.setState({
              modalOpen: false
            })
          }
          submitActions={() => resetDiagrams()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Do you really want to go back?{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              You will lose all the progress you made!
            </span>
          </div>
        </Modal>
        <Modal
          title="Name the Diagram"
          titleClassName="greenHeader"
          isOpen={namingModalOpen}
          submitButtonColor="green"
          submitButtonText="Submit"
          onRequestClose={() =>
            this.setState({
              namingModalOpen: false,
              diagramName: ''
            })
          }
          submitActions={() => this.handleAccidentSubmit()}
          disableSubmit={!diagramName || diagramName.length < 3}
        >
          <Textbox
            fieldLabel="What would you like to name your diagram? "
            handleChange={e => this.setState({ diagramName: e.target.value })}
            currentValue={diagramName}
            placeholder="e.g. intersection"
          />
        </Modal>
        <Modal
          title="Add Annotation"
          titleClassName="greenHeader"
          isOpen={this.state.annotationModalOpen}
          submitButtonColor="green"
          submitButtonText="Submit"
          onRequestClose={() =>
            this.setState({
              annotationModalOpen: false,
              annotation: ''
            })
          }
          submitActions={this.addText}
          disableSubmit={!this.state.annotation}
        >
          <Textbox
            fieldLabel="Please type the text to add."
            handleChange={e => this.setState({ annotation: e.target.value })}
            currentValue={this.state.annotation}
            enter={this.addText}
            inputRef={this.annotationFocus}
            escape={() =>
              this.setState({
                annotationModalOpen: false,
                annotation: ''
              })
            }
          />
        </Modal>
        <Modal
          title="Delete Stamp?"
          titleClassName="redHeader"
          isOpen={this.state.deleteModal}
          submitButtonColor="red"
          submitButtonText="Delete"
          onRequestClose={() =>
            this.setState({
              deleteModal: false
            })
          }
          submitActions={() => this.deleteImage()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Do you really want to delete this stamp?
          </div>
        </Modal>
      </>
    );
  }
}

export default Diagram;
