import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import {
  createJsaRequest,
  deleteJsaRequest,
  fetchCustomTemplatesRequest,
  fetchJsaSectionsRequest,
  updateJsaRequest
} from '../../actions/jsa';
import { updateLeftNavRequest } from '../../actions/navigation';
import { JSA_INDUSTRY_DROPDOWN } from '../../constants/constants';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getActiveJsaSelector,
  getCustomRaTemplatesSelector,
  getJsaLoadingSelector,
  getJsaSectionsSelector
} from '../../selectors/jsa';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import AssignRaCard from '../../components/raCards/AssignRaCard';
import DeleteItemModal from '../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';

import styles from './styles.module.scss';

export class RaContainer extends Component {
  state = {
    locationId: '',
    projectId: '',
    dueDate: '',
    assignedTo: [],
    jobLocation: '',
    sections: [],
    industry: '',
    repeatingRa: '',
    repeatingOption: [],
    recurringTime: '',
    repeatingOn: [],
    isIndividual: false,
    frequency: '',
    isEditing: false,
    isCreating: false,
    hasUnsavedChanges: false,
    openUnsavedChangesModal: false,
    openEndRepeatingModal: false,
    openRepeatingRaModal: false,
    openEndScanModal: false,
    openDeleteRiskAssessmentModal: false
  };

  async componentDidMount() {
    this.props.updateLeftNav('Risk Assessment');

    this.setState({ locationId: this.props.activeLocation._id });

    if (this.props.activeRa._id) {
      const industry = JSA_INDUSTRY_DROPDOWN.filter(
        industry => industry.label === this.props.activeRa.industry
      );

      const repeatingRa =
        this.props.activeRa.schedule === 'immediate'
          ? { value: 0, label: 'Immediately' }
          : this.props.activeRa.schedule === 'repeating'
          ? { value: 1, label: 'Repeating' }
          : { value: 2, label: 'Scan on Demand' };

      if (this.props.activeRa.industry === 'Custom') {
        await this.props.fetchCustomTemplates();
      } else {
        await this.props.fetchJsaSections(this.props.activeRa.industry);
      }

      this.setState({
        ...this.props.activeRa,
        recurringTime: this.props.activeRa.dueByTime,
        repeatingOption: this.props.activeRa.dueBy,
        industry: industry[0],
        repeatingRa,
        isIndividual: this.props.activeRa.isIndividual,
        isEditing: true
      });
    } else {
      this.setState({ isCreating: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      ((this.props.jsaSections &&
        this.props.jsaSections !== prevProps.jsaSections) ||
        (this.props.templates &&
          this.props.templates !== prevProps.templates)) &&
      !this.state.isCreating
    ) {
      const sectionDropdown =
        this.props.jsaSections.length > 0
          ? this.props.jsaSections.map((section, index) => {
              return {
                value: index,
                label: section
              };
            })
          : this.props.templates.map((section, index) => {
              return {
                value: index,
                label: section.title
              };
            });

      const raSections = this.props.activeRa.sections.map(
        section => section.sectionName
      );

      const riskAssessments = sectionDropdown.filter(
        section => raSections.indexOf(section.label) > -1
      );

      this.setState({ sections: riskAssessments });
    }
  }

  handleAnswer = stateObject => {
    this.setState({ ...stateObject, hasUnsavedChanges: true });

    if (stateObject.hasOwnProperty('industry')) {
      this.setState({ sections: [] });
      if (stateObject['industry'].label === 'Custom') {
        this.props.fetchCustomTemplates();
      } else {
        this.props.fetchJsaSections(stateObject['industry'].label);
      }
    }

    if (stateObject.hasOwnProperty('frequency')) {
      this.setState(state => ({ repeatingOn: [] }));
    }
  };

  handleRaSubmit = () => {
    if (this.props.activeRa._id) {
      this.props.updateJsa({
        ...this.state,
        sections: this.props.activeRa.sections
      });

      this.setState({ isEditing: false });
    } else {
      this.props.createJsa(this.state);

      if (this.state.repeatingRa.value === 1) {
        this.setState({
          isCreating: false,
          hasUnsavedChanges: false,
          openRepeatingRaModal: true
        });
      } else {
        this.setState({ isCreating: false, hasUnsavedChanges: false });
      }
    }
  };

  handleRaDeleteSelection = () => {
    if (this.state.isMaster && this.state.repeatingRa.value === 1) {
      this.setState({ openEndRepeatingModal: true });
    } else if (this.state.isMaster && this.state.repeatingRa.value === 2) {
      this.setState({ openEndScanModal: true });
    } else {
      this.setState({ openDeleteRiskAssessmentModal: true });
    }
  };

  handleRaDelete = () => {
    this.props.deleteJsa(this.state._id);
  };

  canSubmit = () => {
    if (
      this.state.locationId &&
      this.state.industry &&
      ((this.state.repeatingRa.value === 0 &&
        this.state.dueDate &&
        this.state.assignedTo.length > 0 &&
        this.state.sections.length > 0) ||
        (this.state.repeatingRa.value === 1 &&
          this.state.frequency &&
          this.state.repeatingOption &&
          this.state.recurringTime &&
          (this.state.repeatingOn.length > 0 || this.state.repeatingOn.value) &&
          this.state.assignedTo.length > 0 &&
          this.state.sections.length > 0) ||
        (this.state.repeatingRa.value === 2 &&
          (this.state.sections.label || this.state.sections.length > 0)))
    ) {
      return true;
    }

    return false;
  };

  handleGoBack = () => {
    if (this.state.hasUnsavedChanges) {
      this.setState({ openUnsavedChangesModal: true });
    } else {
      history.goBack();
    }
  };

  render() {
    const {
      employeeList,
      activeCompany,
      jsaSections,
      activeLocation,
      activeProject,
      templates
    } = this.props;

    const {
      locationId,
      sections,
      industry,
      dueDate,
      assignedTo,
      jobLocation,
      projectId,
      repeatingRa,
      repeatingOption,
      recurringTime,
      repeatingOn,
      isIndividual,
      isEditing,
      isCreating,
      hasUnsavedChanges,
      openUnsavedChangesModal,
      openEndRepeatingModal,
      openRepeatingRaModal,
      frequency,
      openEndScanModal,
      openDeleteRiskAssessmentModal
    } = this.state;

    const sectionDropdown =
      jsaSections.length > 0
        ? jsaSections.map((section, index) => {
            return {
              value: index,
              label: section
            };
          })
        : templates.map((section, index) => {
            return {
              value: index,
              label: section.title
            };
          });

    const header = (
      <Header
        title="Risk Assessments"
        section={isCreating ? 'Create Assessment(s)' : 'Edit Assessment(s)'}
        needsSaved={hasUnsavedChanges}
        clickBack={() => this.handleGoBack()}
        rightButtons={{
          text: 'Delete Risk Assessment',
          onClick: () => this.handleRaDeleteSelection(),
          color: 'red',
          visible: isEditing
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        editing={isEditing}
        saveButtonClick={() => this.handleRaSubmit()}
        cancelButtonClick={() => this.handleGoBack()}
        saveButtonDisabled={!this.canSubmit()}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          className={styles.raContainer}
        >
          <AssignRaCard
            employees={employeeList}
            activeLocation={activeLocation}
            activeProject={activeProject}
            groupList={activeCompany.locations}
            sectionDropdown={sectionDropdown}
            categoryDropdown={JSA_INDUSTRY_DROPDOWN}
            handleAnswer={answerObject => this.handleAnswer(answerObject)}
            locationId={locationId}
            sections={sections}
            industry={industry}
            dueDate={dueDate}
            assignedTo={assignedTo}
            jobLocation={jobLocation}
            disabled={!isEditing && !isCreating}
            projectId={projectId}
            repeatingRa={repeatingRa}
            repeatingOption={repeatingOption}
            repeatingOn={repeatingOn}
            recurringTime={recurringTime}
            isIndividual={isIndividual}
            isEditing={isEditing}
            frequency={frequency}
          />
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => history.goBack()}
          savingWhat="a template"
        />
        <DeleteItemModal
          isOpen={openDeleteRiskAssessmentModal}
          onRequestClose={() =>
            this.setState({ openDeleteRiskAssessmentModal: false })
          }
          submitActions={() => this.handleRaDelete()}
          deletingWhat="Risk Assessment"
        />
        <Modal
          title="End Scan on Demand Assessment"
          titleClassName="redHeader"
          isOpen={openEndScanModal}
          submitButtonColor="red"
          submitButtonText="End Scan on Demand"
          onRequestClose={() => this.setState({ openEndScanModal: false })}
          submitActions={() => this.handleRaDelete()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Ending a Scan on Demand Risk Assessment will remove it from the
              Scan on Demand section of the Risk Assessments page.{' '}
            </span>
            Risk Assessments that are already Active will not be affected, but
            no more will be created by this Scan on Demand Risk Assessment.
          </div>
        </Modal>
        <Modal
          title="End Repeating Risk Assessment"
          titleClassName="redHeader"
          isOpen={openEndRepeatingModal}
          submitButtonColor="red"
          submitButtonText="End Repeating"
          onRequestClose={() => this.setState({ openEndRepeatingModal: false })}
          submitActions={() => this.handleRaDelete()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Ending a Repeating Risk Assessment will remove it from the
              Repeating section of the Risk Assessments page.{' '}
            </span>
            Risk Assessments that are already Active will not be affected, but
            no more will be created by this Repeating Risk Assessment.
          </div>
        </Modal>
        <Modal
          title="Repeating Risk Assessment"
          titleClassName="blueHeader"
          isOpen={openRepeatingRaModal}
          hideButtons={true}
          onRequestClose={() => history.goBack()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            A Repeating Risk Assessment has been created{' '}
            <span
              style={{
                fontWeight: 'bold',
                fontStyle: 'italic'
              }}
            >
              It can be found in the Repeating section of the Risk Assessments
              page. Editing or ending the a Repeating Risk Assessment will only
              affect future Risk Assessments, and will not alter any Risk
              Assessments that are already Active
            </span>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  employeeList: getCompanyUsersSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  jsaSections: getJsaSectionsSelector(state),
  jsaLoading: getJsaLoadingSelector(state),
  activeRa: getActiveJsaSelector(state),
  loggedInUser: getLoggedInUser(state),
  templates: getCustomRaTemplatesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  createJsa: values => dispatch(createJsaRequest(values)),
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation)),
  fetchJsaSections: industry => dispatch(fetchJsaSectionsRequest(industry)),
  updateJsa: values => dispatch(updateJsaRequest(values)),
  deleteJsa: values => dispatch(deleteJsaRequest(values)),
  fetchCustomTemplates: () => dispatch(fetchCustomTemplatesRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(RaContainer);
