import React, { Component } from 'react';

import { Input, nameInput } from '..';
import { classnames } from '../../../utils/classNamesHelper';

import styles from './checkbox.module.scss';

class Checkbox extends Component {
  render() {
    const {
      name,
      disabled,
      onChange,
      className,
      children,
      currentValue,
      tooltip,
      ...props
    } = this.props;

    let classes = [className, styles.input, disabled && styles.disabled];

    return (
      <Input
        {...props}
        tooltip={tooltip || children}
        name={name}
        className={classnames(classes)}
      >
        <div className={styles.container}>
          <input
            type="checkbox"
            id={name}
            checked={currentValue}
            onChange={e => onChange && onChange(e.target.checked)}
            disabled={disabled}
          />
          <div className={styles.checkbox}>
            {currentValue && <img src={require('../../../assets/images/Check.png')} alt="checked" />}
          </div>
        </div>
      </Input>
    );
  }
}

export default nameInput(Checkbox);
