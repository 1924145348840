import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import ReactTooltip from 'react-tooltip';

import {
  isValidDate,
  renderDatePicker,
  renderDropdownField,
  renderField,
  renderMultiSelectField,
  renderTextareaField,
  required
} from '../../utils/formValidation';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import FileDownloader from '../../components/FileDownloader';
import FileUploader from '../../components/FileUploader';

import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';

let ToolboxTalkForm = props => {
  const {
    handleSubmit,
    activeEmployees,
    isUpdate,
    disabled,
    onDelete,
    onCancel,
    activeCompanyId,
    addAttachment,
    activeAttachments,
    deleteTTAttachment,
    loading,
    locations,
    reset,
    addedAttachments,
    deleteAttachment,
    activeLocation,
    activeProject,
    groupId,
    documentFolders,
    hasAccess
  } = props;

  const activeEmployeesDropdown = activeEmployees.map(personnel => {
    return {
      value: personnel._id,
      label: `${personnel.person.firstName} ${personnel.person.lastName}`
    };
  });

  const locationDropdown = locations.map(location => {
    return {
      value: location._id,
      label: location.name
    };
  });

  const getProjectDropdown = () => {
    let group = '';
    if (!groupId && !activeLocation) {
      return [];
    } else if (activeLocation) {
      group = [activeLocation._id];
    } else {
      group = groupId;
    }

    const selectedGroup = locations.filter(o =>
      group.find(o2 => {
        if (o2.value) {
          return o._id === o2.value;
        } else {
          return o._id === o2;
        }
      })
    );

    let projectsDropdown = [];

    if (selectedGroup.length > 0) {
      selectedGroup.forEach(group => {
        group.projects.forEach(project => {
          projectsDropdown.push({
            value: project._id,
            label: `${group.name} - ${project.name}`
          });
        });
      });
    }

    return projectsDropdown;
  };

  const folderDropdown = documentFolders.map(folder => {
    return {
      value: folder._id,
      label: folder.name
    };
  });

  return (
    <form className="toolboxTalkForm" onSubmit={handleSubmit}>
      {disabled ? (
        ''
      ) : isUpdate ? (
        <InputRow right>
          <Button text="Update" color="blue" type="submit" />
          <Button color="red" text="Delete" onClick={onDelete} />
          <Button
            text="Cancel"
            type="button"
            onClick={() => {
              reset('toolboxTalkForm');
              onCancel(true);
            }}
          />
        </InputRow>
      ) : (
        ''
      )}
      <div className="form-fields">
        <Field
          name="expires"
          component={renderDatePicker}
          requiredLabel={true}
          label="Date:"
          disabled={disabled}
          validate={[required, isValidDate]}
        />
        {!activeLocation ? (
          <div>
            <Field
              name="locationId"
              component={renderMultiSelectField}
              label="Group or Establishment:"
              placeholder={''}
              options={locationDropdown}
              disabled={disabled}
              searchable
            />
            <ReactTooltip className="customTheme" id="locationId">
              <p>
                You can share with one or more Groups / Establishments and that
                will make it available to them as well
              </p>
            </ReactTooltip>
          </div>
        ) : (
          ''
        )}
        {!activeProject ? (
          <div>
            <Field
              name="projectId"
              component={renderMultiSelectField}
              label="Project:"
              placeholder={''}
              options={getProjectDropdown()}
              disabled={disabled}
              searchable
            />
            <ReactTooltip className="customTheme" id="projectId">
              <p>
                You can share this document with one or more Projects and that
                will make it available to them as well
              </p>
            </ReactTooltip>
          </div>
        ) : (
          ''
        )}
        <Field
          name="documentFolderId"
          component={renderDropdownField}
          options={folderDropdown}
          label="Add to Folder:"
          placeholder={''}
          disabled={disabled}
        />
        <Field
          name="documentData.title"
          component={renderField}
          label="Title:"
          disabled={disabled}
        />
        <Field
          name="documentData.employees"
          component={renderMultiSelectField}
          options={activeEmployeesDropdown}
          label="Employees who attended this Safety Meeting:"
          placeholder="Type to search..."
          disabled={disabled}
          searchable
          selectButtons
        />
        <Field
          name="documentData.employeeComments"
          component={renderTextareaField}
          label="Employee comments or concerns:"
          disabled={disabled}
        />
        <Field
          name="documentData.safetyIssues"
          component={renderTextareaField}
          label="Other safety issues addressed on this specific job site:"
          disabled={disabled}
        />
        <Field
          name="documentData.researchItems"
          component={renderTextareaField}
          label="Items requiring research or follow-up:"
          disabled={disabled}
        />
      </div>
      <div>
        <h4>Attachments</h4>
        {addedAttachments &&
          addedAttachments.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              deleteAttachment={deleteAttachment}
              disabled={!hasAccess || disabled}
            />
          ))}
        {activeAttachments &&
          activeAttachments.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              deleteAttachment={deleteTTAttachment}
              disabled={!hasAccess || disabled}
            />
          ))}
      </div>
      {!disabled && (
        <Field
          name="attachments"
          type="text"
          component={FileUploader}
          label="Attachment(s):"
          placeholder={''}
          activeCompanyId={activeCompanyId}
          addAttachment={addAttachment}
          disabled={disabled}
          loading={loading}
          ownerId="1234"
          ownerType="documents"
        />
      )}
    </form>
  );
};

ToolboxTalkForm = reduxForm({
  form: 'ToolboxTalkForm'
})(ToolboxTalkForm);

const selector = formValueSelector('ToolboxTalkForm');

const mapStateToProps = state => ({
  groupId: selector(state, 'locationId')
});

export default connect(mapStateToProps)(ToolboxTalkForm);
