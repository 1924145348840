import { all, call, put, select } from 'redux-saga/effects';
import {
  fetchCompanyIncidentAnalyticsResponse,
  fetchCompanyManagementAnalyticsResponse
} from '../actions/analytics';
import { getActiveCompany, getActiveLocationId } from '../selectors/company';

export function* fetchCompanyManagementAnalytics(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);

    const timeFilter =
      payload.toString().indexOf('last') > -1
        ? { filter: 'timeFrame', time: payload }
        : { filter: 'year', time: payload };

    const groupFilter = activeGroup !== 0 ? { groupId: activeGroup._id } : {};

    const {
      nearMissResponse,
      jsaClosedOneWeekResponse,
      taskData,
      accountabilityDocuments,
      numberOfProactiveReports,
      numberOfSafetyTrainingsPerformed,
      averageIncidentLagTime,
      reportCostsStats,
      observationCostsStats,
      correctiveActionsByType
    } = yield all({
      nearMissResponse: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getNearMissByType',
        ...timeFilter,
        ...groupFilter
      }),
      jsaClosedOneWeekResponse: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getPercentOfJSAsClosedWithinOneWeek',
        ...timeFilter,
        ...groupFilter
      }),
      taskData: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getTasksClosedOnTime',
        ...timeFilter,
        ...groupFilter
      }),
      accountabilityDocuments: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getNumberOfAccountabilityDocuments',
        ...timeFilter,
        ...groupFilter
      }),
      numberOfProactiveReports: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getNumberOfProactiveReports',
        ...timeFilter,
        ...groupFilter
      }),
      numberOfSafetyTrainingsPerformed: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getNumberOfSafetyTrainingsPerformed',
        ...timeFilter,
        ...groupFilter
      }),
      averageIncidentLagTime: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getAverageIncidentLagTime',
        ...timeFilter,
        ...groupFilter
      }),
      reportCostsStats: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getReportCostsStats',
        ...timeFilter,
        ...groupFilter
      }),
      observationCostsStats: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getObservationCostsAvoidedStats',
        ...timeFilter,
        ...groupFilter
      }),
      correctiveActionsByType: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getCorrectiveActionsByType',
        ...timeFilter,
        ...groupFilter
      })
    });

    yield put(
      fetchCompanyManagementAnalyticsResponse({
        nearMissByType: nearMissResponse,
        jsaClosedOneWeek: jsaClosedOneWeekResponse,
        accountabilityDocuments: accountabilityDocuments,
        numberOfProactiveReports: numberOfProactiveReports,
        numberOfSafetyTrainingsPerformed: numberOfSafetyTrainingsPerformed,
        reportCostsStats: reportCostsStats,
        correctiveActionsByType: correctiveActionsByType,
        averageIncidentLagTime: averageIncidentLagTime,
        totalSafetyCosts: reportCostsStats.totalSafetyCosts,
        observationCostsStats: observationCostsStats,
        taskData: taskData
      })
    );
  } catch (e) {}
}

export function* fetchCompanyIncidentAnalytics(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);

    const timeFilter =
      payload.indexOf('last') > -1
        ? { filter: 'timeFrame', time: payload }
        : { filter: 'year', time: payload };

    const groupFilter = activeGroup !== 0 ? { groupId: activeGroup._id } : {};

    const {
      dartResponse,
      tcirResponse,
      ltirResponse,
      oshaCasesAverageLossTime,
      allCasesAverageLossTime,
      numberOfFROIs,
      oshaDataForIncidents,
      totalOshaInjury,
      topFactorsFROIAuto
    } = yield all({
      dartResponse: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'DART',
        ...timeFilter,
        ...groupFilter
      }),
      tcirResponse: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'TCIR',
        ...timeFilter,
        ...groupFilter
      }),
      ltirResponse: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getLTIR',
        ...timeFilter,
        ...groupFilter
      }),
      oshaCasesAverageLossTime: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'OSHACasesAverageLossTime',
        ...timeFilter,
        ...groupFilter
      }),
      allCasesAverageLossTime: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'allCasesAverageLossTime',
        ...timeFilter,
        ...groupFilter
      }),
      numberOfFROIs: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'numberOfFROIs',
        ...timeFilter,
        ...groupFilter
      }),
      oshaDataForIncidents: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getOshaDataForIncidentsStats',
        ...timeFilter,
        ...groupFilter
      }),
      totalOshaInjury: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getTotalsForOshaInjuryTypes',
        ...timeFilter,
        ...groupFilter
      }),
      topFactorsFROIAuto: call(api.fetchAnalyticType, {
        companyId: activeCompany._id,
        query: 'getTopContributingFactorsForFROIAndAutoAccident',
        ...timeFilter,
        ...groupFilter
      })
    });

    yield put(
      fetchCompanyIncidentAnalyticsResponse({
        dart: dartResponse,
        tcir: tcirResponse,
        ltir: ltirResponse,
        numberOfFROIs: numberOfFROIs,
        oshaCasesAverageLossTime: oshaCasesAverageLossTime,
        allCasesAverageLossTime: allCasesAverageLossTime,
        oshaDataForIncidents: oshaDataForIncidents,
        totalOshaInjury: totalOshaInjury,
        topFactorsFROIAuto: topFactorsFROIAuto
      })
    );
  } catch (e) {}
}
