import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g transform="translate(1 1)">
      <g>
        <path
          d="M400.1,442.7h-1.6L379,358.6c0.1-0.9,0.1-1.9-0.3-2.9l-19.6-85.3c-0.2-1.2-0.7-2.3-1.3-3.2l-18.2-78.6
                        c0.3-1.1,0.3-2.4-0.1-3.6l-19.6-85.3c-0.3-1.5-0.9-2.7-1.7-3.7l-17.9-77.3C297.7,7.5,287.4-1,275.5-1h-41
                        c-11.9,0-22.2,8.5-24.7,19.6l-98.3,424.1h-1.6c-18.8,0-34.1,15.4-34.1,34.1c0,18.8,15.4,34.1,34.1,34.1h290.1
                        c18.8,0,34.1-15.4,34.1-34.1C434.2,458.1,418.8,442.7,400.1,442.7z M359.1,348.9h-208l6.6-28.8l9.4-39.5h176.6l0.7,3.1
                        L359.1,348.9z M206.4,109.9h97.8l10.8,46.5l4.9,21.7H190.3l5-21.7L206.4,109.9z M186.4,195.3H324l15.8,68.3H170.7L186.4,195.3z
                         M226,22.9c0.9-4.3,4.3-6.8,8.5-6.8h41c4.3,0,7.7,2.6,8.5,6.8l16.2,70h-90.3L226,22.9z M147.2,365.9h216.3l17.8,76.8H129.6
                        L147.2,365.9z M400.1,493.9H109.9c-9.4,0-17.1-7.7-17.1-17.1s7.7-17.1,17.1-17.1h8.5h273.1h8.5c9.4,0,17.1,7.7,17.1,17.1
                        S409.5,493.9,400.1,493.9z"
        />
      </g>
    </g>
  </svg>
);
