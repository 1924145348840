const isObject = val => {
  return typeof val === 'object' && val !== null;
};

function processClasses(classes, arg) {
  if (arg === null || arg === undefined) {
  } else if (Array.isArray(arg)) {
    arg.forEach(a => processClasses(classes, a));
  } else if (typeof arg === 'string') {
    classes.push(arg);
  } else if (isObject(arg)) {
    Object.keys(arg).forEach(key => {
      if (arg[key]) {
        classes.push(key);
      }
    });
  }
}

export function classnames(...args) {
  let classes = [];
  args.forEach(arg => processClasses(classes, arg));
  return classes.join(' ');
}
