import {
  AUTH_SIGNIN,
  AUTH_SIGNOUT,
  AUTH_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  GET_SECURITY_QUESTIONS,
  MICROSOFT_LOGIN,
  NEW_USER_REGISTRATION_REQUEST,
  NEW_USER_REGISTRATION_RESPONSE,
  SUBMIT_SECURITY_QUESTIONS
} from '../constants/actionTypes';

export const authSuccess = () => {
  return { type: AUTH_SUCCESS };
};

export const signIn = payload => {
  return { type: AUTH_SIGNIN, payload };
};

export const signOut = () => {
  localStorage.clear();
  return { type: AUTH_SIGNOUT };
};

export const forgotPasswordRequest = payload => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload
});

export const newUserRegistrationRequest = payload => ({
  type: NEW_USER_REGISTRATION_REQUEST,
  payload
});

export const newUserRegistrationResponse = payload => ({
  type: NEW_USER_REGISTRATION_RESPONSE,
  payload
});

export const signInMicrosoftUser = payload => {
  return { type: MICROSOFT_LOGIN, payload };
};

export const submitSecurityQuestions = payload => {
  return { type: SUBMIT_SECURITY_QUESTIONS, payload };
};

export const showSecurityQuestions = questions => ({
  type: GET_SECURITY_QUESTIONS,
  payload: questions
});
