import React, { Component } from 'react';
import Dropdown from '../inputs/Dropdown';
import Modal from '.';

import styles from './styles.module.scss';

export class AddToFolderModal extends Component {
  render() {
    return (
      <Modal
        title="Add To Folder"
        titleClassName="blueHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={this.props.onRequestClose}
        submitActions={this.props.submitActions}
        disableSubmit={!this.props.folderId}
      >
        <div className={styles.text}>
          <Dropdown
            options={this.props.folderOptions}
            fieldLabel="Add to which folder"
            handleChange={this.props.handleChange}
            currentValue={this.props.folderId}
          />
        </div>
      </Modal>
    );
  }
}

export default AddToFolderModal;
