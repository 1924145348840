import {
  ADD_COMPANY_LOCATION_REQUEST,
  ADD_COMPANY_LOCATION_RESPONSE,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_RESPONSE,
  ADD_PROJECT_REQUEST,
  DELETE_COMPANY_LOCATION_REQUEST,
  DELETE_COMPANY_LOCATION_RESPONSE,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_RESPONSE,
  DELETE_PROJECT_REQUEST,
  FETCH_USER_COMPANIES_REQUEST,
  FETCH_USER_COMPANIES_RESPONSE,
  SET_ACTIVE_COMPANY,
  SET_ACTIVE_GROUPS,
  SET_ACTIVE_LOCATION,
  SET_ACTIVE_PROJECT,
  SET_ALL_LOCATIONS,
  UPDATE_COMPANY_LOCATION_REQUEST,
  UPDATE_COMPANY_LOCATION_RESPONSE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_RESPONSE,
  UPDATE_PROJECT_REQUEST
} from '../constants/actionTypes';

export const fetchUserCompaniesRequest = payload => ({
  type: FETCH_USER_COMPANIES_REQUEST,
  payload
});

export const fetchUserCompaniesResponse = payload => ({
  type: FETCH_USER_COMPANIES_RESPONSE,
  payload
});

export const setActiveCompany = payload => ({
  type: SET_ACTIVE_COMPANY,
  payload
});

export const setActiveLocation = payload => ({
  type: SET_ACTIVE_LOCATION,
  payload
});

export const addCompanyRequest = payload => ({
  type: ADD_COMPANY_REQUEST,
  payload
});

export const addCompanyResponse = payload => ({
  type: ADD_COMPANY_RESPONSE,
  payload
});

export const addCompanyLocationRequest = payload => ({
  type: ADD_COMPANY_LOCATION_REQUEST,
  payload
});

export const addCompanyLocationResponse = payload => ({
  type: ADD_COMPANY_LOCATION_RESPONSE,
  payload
});

export const updateCompanyLocationRequest = payload => ({
  type: UPDATE_COMPANY_LOCATION_REQUEST,
  payload
});

export const updateCompanyLocationResponse = payload => ({
  type: UPDATE_COMPANY_LOCATION_RESPONSE,
  payload
});

export const deleteCompanyLocationRequest = payload => ({
  type: DELETE_COMPANY_LOCATION_REQUEST,
  payload
});

export const deleteCompanyLocationResponse = payload => ({
  type: DELETE_COMPANY_LOCATION_RESPONSE,
  payload
});

export const updateCompanyRequest = payload => ({
  type: UPDATE_COMPANY_REQUEST,
  payload
});

export const updateCompanyResponse = payload => ({
  type: UPDATE_COMPANY_RESPONSE,
  payload
});

export const deleteCompanyRequest = payload => ({
  type: DELETE_COMPANY_REQUEST,
  payload
});

export const deleteCompanyResponse = payload => ({
  type: DELETE_COMPANY_RESPONSE,
  payload
});

export const setAllLocations = payload => ({
  type: SET_ALL_LOCATIONS,
  payload
});

export const setActiveGroups = payload => ({
  type: SET_ACTIVE_GROUPS,
  payload
});

export const addProjectRequest = payload => ({
  type: ADD_PROJECT_REQUEST,
  payload
});

export const updateProjectRequest = payload => ({
  type: UPDATE_PROJECT_REQUEST,
  payload
});

export const deleteProjectRequest = payload => ({
  type: DELETE_PROJECT_REQUEST,
  payload
});

export const setActiveProject = payload => ({
  type: SET_ACTIVE_PROJECT,
  payload
});
