import {
  CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  CREATE_OSHA_FORM_REQUEST,
  CREATE_OSHA_FORM_RESPONSE,
  CREATE_REPORT_RESPONSE,
  DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  FETCH_COMPANY_REPORTS_REQUEST,
  FETCH_COMPANY_REPORTS_RESPONSE,
  FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST,
  FETCH_INCIDENT_REPORT_RESPONSE,
  FETCH_LOCATION_REPORTS_REQUEST,
  FETCH_LOCATION_REPORTS_RESPONSE,
  FETCH_PROJECT_REPORTS_REQUEST,
  FETCH_PROJECT_REPORTS_RESPONSE,
  FETCH_REPORT_CIRCLES_RESPONSE,
  SET_ACTIVE_CUSTOM_REPORT_QUESTIONS,
  SET_ACTIVE_REPORT_REQUEST,
  SET_REPORT_TEMPLATES,
  UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  UPDATE_OSHA_FORM_REQUEST,
  UPDATE_OSHA_FORM_RESPONSE,
  UPDATE_REPORT_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  circles: [],
  reports: [],
  oshaLog: {},
  reportTemplates: [],
  activeCustomQuestions: {}
};

const reports = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMPANY_REPORTS_REQUEST:
    case FETCH_LOCATION_REPORTS_REQUEST:
    case FETCH_PROJECT_REPORTS_REQUEST:
    case SET_ACTIVE_REPORT_REQUEST:
    case CREATE_OSHA_FORM_REQUEST:
    case UPDATE_OSHA_FORM_REQUEST:
    case FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case CREATE_REPORT_RESPONSE:
      return {
        ...state,
        loading: false,
        reports: payload
      };

    case UPDATE_REPORT_RESPONSE:
      if (Array.isArray(state.reports)) {
        const reportsUpdated = [...state.reports].filter(
          r => r._id !== payload._id
        );
        reportsUpdated.push(payload);
        return {
          ...state,
          loading: false,
          reports: reportsUpdated
        };
      }
      return {
        ...state,
        loading: false,
        reports: [payload]
      };

    case FETCH_COMPANY_REPORTS_RESPONSE:
    case FETCH_LOCATION_REPORTS_RESPONSE:
    case FETCH_INCIDENT_REPORT_RESPONSE:
    case FETCH_PROJECT_REPORTS_RESPONSE:
      return {
        ...state,
        reports: payload
      };

    case FETCH_REPORT_CIRCLES_RESPONSE:
      return {
        ...state,
        circles: payload,
        loading: false
      };

    case CREATE_OSHA_FORM_RESPONSE:
    case UPDATE_OSHA_FORM_RESPONSE:
      return {
        ...state,
        oshaLog: payload
      };

    case SET_REPORT_TEMPLATES:
      return {
        ...state,
        reportTemplates: payload
      };

    case SET_ACTIVE_CUSTOM_REPORT_QUESTIONS:
      return {
        ...state,
        loading: false,
        activeCustomQuestions: payload
      };

    default:
      return {
        ...state
      };
  }
};

export default reports;
