import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="30px"
    height="25px"
    viewBox="0 0 90 90"
  >
    <path d="M69.2,77.5c-4-3.6-2.1-11-1.6-14.2c0.7-4.5,2.9-10.9,3.8-16.4c0.6-3.6,1.1-6.5,1.1-12.4c0-4.2-0.7-14.5-4.5-21.8  C60.9-0.4,45.4,0,45.4,0S29.9-0.4,22.9,12.7c-3.9,7.3-4.5,17.6-4.5,21.8c0,5.9,0.5,8.9,1.1,12.4c0.8,5.5,3,11.8,3.8,16.4  c0.5,3.2,2.4,10.6-1.6,14.2c0,0-13.3,12.5,23.7,12.5C82.5,90,69.2,77.5,69.2,77.5z M25,35.6c-0.3-3.7,2.6-5.5,9.4-5.5  c6,0,9.4,1.7,9.4,4.2c0,5.2-6.3,7.6-11.4,7.6C28.4,41.9,25.4,40.1,25,35.6z M45.4,61.1c-3.7,0-6.7-1-6.7-4.6c0-3.6,2-7.8,6.7-7.8  s6.7,4.3,6.7,7.8C52.1,60.1,49.1,61.1,45.4,61.1z M65.8,35.6c-0.4,4.5-3.4,6.3-7.3,6.3c-5.2,0-11.4-2.5-11.4-7.6  c0-2.5,3.4-4.2,9.4-4.2C63.2,30,66.2,31.8,65.8,35.6z" />
  </svg>
);
