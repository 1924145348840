import React, { Component } from 'react';
import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS
} from '../../../constants/constants';
import Card from '../../Card';
import Checkbox from '../../inputs/Checkbox';
import { DatePicker } from '../../inputs/DateTimePicker';
import DayOfWeekButtons from '../../inputs/DayOfWeekButtons';
import Dropdown from '../../inputs/Dropdown';
import Multiselect from '../../inputs/Multiselect';
import RadioButtons from '../../inputs/RadioButtons';
import Textbox from '../../inputs/Textbox';
import DueBy from '../../inputs/DueBy';
import { TwoColumn } from '../../inputs';

export default class AssignRaCard extends Component {
  render() {
    const {
      employees,
      groupList,
      activeLocation,
      activeProject,
      sectionDropdown,
      categoryDropdown,
      locationId,
      sections,
      industry,
      dueDate,
      assignedTo,
      jobLocation,
      disabled,
      projectId,
      handleAnswer,
      repeatingRa,
      repeatingOption,
      recurringTime,
      repeatingOn,
      isIndividual,
      isEditing,
      frequency
    } = this.props;

    const locationDropdown = groupList.map(location => {
      return {
        value: location._id,
        label: location.name
      };
    });

    const employeeDropdown = employees.map(employee => {
      return {
        value: employee.userAccount._id,
        label: `${employee.person.firstName} ${employee.person.lastName}`
      };
    });

    const getProjectDropdown = () => {
      const selectedGroup = groupList.filter(
        group => group._id === activeLocation._id || group._id === locationId
      );

      let temp = [];

      if (selectedGroup.length > 0) {
        temp = selectedGroup[0].projects.map(project => {
          return {
            value: project._id,
            label: project.name
          };
        });
        return [{ value: '', label: '      ' }, ...temp];
      }

      return [];
    };

    return (
      <Card>
        <TwoColumn>
          {!activeLocation && (
            <Dropdown
              searchable={true}
              options={locationDropdown}
              fieldLabel="Group or Establishment:"
              currentValue={locationId}
              handleChange={values =>
                handleAnswer({ locationId: values.value })
              }
              isRequired={true}
              disabled={disabled || isEditing}
            />
          )}
          {!activeProject && (
            <Dropdown
              searchable={true}
              options={getProjectDropdown()}
              fieldLabel="Project:"
              currentValue={projectId}
              handleChange={values => handleAnswer({ projectId: values.value })}
              disabled={
                disabled ||
                !locationId ||
                getProjectDropdown().length === 0 ||
                isEditing
              }
              placeholder={
                !locationId || getProjectDropdown().length === 0
                  ? 'No Projects with Group or Establishment'
                  : ''
              }
            />
          )}
        </TwoColumn>
        <TwoColumn>
          <Dropdown
            options={categoryDropdown}
            fieldLabel="Category:"
            currentValue={industry}
            isRequired={true}
            handleChange={values => handleAnswer({ industry: values })}
            disabled={disabled || isEditing}
          />
        </TwoColumn>
        {!isEditing && (
          <RadioButtons
            fieldLabel="Schedule:"
            options={[
              { value: 0, label: 'Immediately' },
              { value: 1, label: 'Repeating' },
              { value: 2, label: 'Scan on Demand' }
            ]}
            isRequired={true}
            handleChange={values =>
              handleAnswer({ repeatingRa: values, frequency: '' })
            }
            currentValue={repeatingRa}
            disabled={disabled}
          />
        )}
        {repeatingRa.value !== 2 ? (
          <Multiselect
            options={sectionDropdown}
            fieldLabel="Risk Assessment(s):"
            currentValue={sections}
            isRequired={true}
            handleChange={values => handleAnswer({ sections: values })}
            disabled={disabled || !industry || isEditing}
            placeholder={!industry ? 'Please choose a category first' : ''}
          />
        ) : (
          <Dropdown
            options={sectionDropdown}
            fieldLabel="Risk Assessment(s):"
            currentValue={sections[0]}
            isRequired={true}
            handleChange={values => handleAnswer({ sections: values })}
            disabled={disabled || !industry || isEditing}
            placeholder={!industry ? 'Please choose a category first' : ''}
          />
        )}
        <Textbox
          currentValue={jobLocation}
          fieldLabel="Specific Location:"
          handleChange={e => handleAnswer({ jobLocation: e.target.value })}
          type="textarea"
          placeholder="Briefly describe where this Risk Assessment should be done."
          disabled={disabled}
        />
        {repeatingRa.value === 1 && (
          <Dropdown
            options={FREQUENCY_OPTIONS}
            fieldLabel="Frequency:"
            currentValue={frequency}
            handleChange={values => handleAnswer({ frequency: values.value })}
            isRequired={true}
            disabled={disabled}
          />
        )}
        {frequency === 'weekly' && (
          <DayOfWeekButtons
            fieldLabel="Assign Every:"
            disabled={repeatingRa.value !== 1 || disabled}
            handleChange={values => handleAnswer({ repeatingOn: values })}
            currentValue={repeatingOn}
          />
        )}
        {frequency === 'monthly' && (
          <Dropdown
            options={MONTHLY_FREQUENCY_OPTIONS}
            fieldLabel="Assign Every:"
            currentValue={repeatingOn}
            handleChange={values => handleAnswer({ repeatingOn: values })}
            isRequired={true}
            disabled={disabled}
          />
        )}
        {frequency === 'yearly' && (
          <Dropdown
            options={YEARLY_FREQUENCY_OPTIONS}
            fieldLabel="Assign Every:"
            currentValue={repeatingOn}
            handleChange={values => handleAnswer({ repeatingOn: values })}
            isRequired={true}
            disabled={disabled}
          />
        )}
        {repeatingRa.value === 1 ? (
          <DueBy
            fieldLabel="Due By:"
            isRequired
            dueBy={repeatingOption}
            dueTime={recurringTime}
            onDueByChange={v => handleAnswer({ repeatingOption: v })}
            onDueTimeChange={v => handleAnswer({ recurringTime: v })}
            disabled={disabled}
          />
        ) : repeatingRa.value === 0 ? (
          <DatePicker
            fieldLabel="Due By"
            isRequired
            handleChange={value => handleAnswer({ dueDate: value })}
            pickTime
            currentValue={dueDate}
            disabled={disabled}
          />
        ) : (
          ''
        )}
        {repeatingRa.value !== 2 && (
          <Multiselect
            options={employeeDropdown}
            fieldLabel="Assignee(s):"
            currentValue={assignedTo}
            isRequired={true}
            handleChange={values => handleAnswer({ assignedTo: values })}
            disabled={disabled}
            searchable={true}
            name="multiEmployees"
          />
        )}
        {repeatingRa.value !== 2 && (
          <Checkbox
            fieldLabel="Assign Individually"
            name="isIndividual"
            onChange={value => handleAnswer({ isIndividual: value })}
            currentValue={isIndividual}
            disabled={disabled}
          />
        )}
      </Card>
    );
  }
}
