import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import {
  COMBINED_EMPLOYEE_STATUS,
  DAY_OF_WEEK_DROPDOWN,
  GENDER_DROPDOWN
} from '../../constants/constants';
import { states } from '../../constants/states';
import {
  getSelectedCompanies,
  getSelectedGroups
} from '../../utils/employeeDGPHelper';
import {
  email,
  isValidDate,
  multiSelectRequired,
  normalizePhone,
  notFutureDate,
  renderDatePicker,
  renderDropdownField,
  renderField,
  renderMultiSelectField,
  renderRadioButtons,
  renderUserButtons,
  required,
  booleanRequired
} from '../../utils/formValidation';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';

import '../form.css';
import { doesUsernameExist } from '../../api';

let EmployeeForm = props => {
  const {
    handleSubmit,
    onDelete,
    disabled,
    isUpdate,
    groupId,
    companyIds,
    userCompanies,
    accessLevel,
    accountInfo,
    isAccountOwner
  } = props;

  const getGroupDropdown = () => {
    const selectedCompanies = getSelectedCompanies(userCompanies, companyIds);

    let groupDropdown = [];

    if (selectedCompanies.length > 0) {
      selectedCompanies.forEach(company =>
        company.groups.forEach(group =>
          groupDropdown.push({
            value: group._id,
            label: `${company.name} - ${group.name}`
          })
        )
      );
    }

    return groupDropdown;
  };

  const checkUsernameAvailability = async e => {
    const response = await doesUsernameExist({
      candidateUsername: e.target.value
    });
    const usernameField = window.document.getElementsByName(e.target.name)[0];

    if (response && response.userExists) {
      usernameField.style.background = 'lightcoral';
    } else {
      usernameField.style.background = 'lightgreen';
    }
  };

  const getProjectDropdown = () => {
    if (!groupId) {
      return [];
    }
    const selectedCompanies = getSelectedCompanies(userCompanies, companyIds);
    const selectedGroup = getSelectedGroups(selectedCompanies, groupId);

    let projectsDropdown = [];
    selectedGroup.forEach(group => {
      group.projects.forEach(project => {
        projectsDropdown.push({
          value: project._id,
          label: `${group.name} - ${project.name}`
        });
      });
    });

    return projectsDropdown;
  };

  const companiesDropdown = userCompanies
    ? userCompanies.map(company => {
        return {
          value: company._id,
          label: company.name
        };
      })
    : [];

  return (
    <form className="editEmployeeForm" onSubmit={handleSubmit}>
      {isUpdate ? (
        <InputRow right>
          <Button text="Update" color="blue" type="submit" />
          {!isAccountOwner && (
            <Button text="Delete" color="red" onClick={onDelete} />
          )}
        </InputRow>
      ) : (
        <></>
      )}
      <div className="form-fields--split">
        <div className="form-fields--leftSide">
          <Field
            name="companyId"
            component={renderMultiSelectField}
            label="Company Division:"
            options={companiesDropdown}
            disabled={disabled}
            validate={[multiSelectRequired]}
            requiredLabel={true}
            searchable
            selectButtons
          />
          <Field
            name="locationId"
            component={renderMultiSelectField}
            label="Group or Establishment:"
            validate={[multiSelectRequired]}
            requiredLabel={true}
            options={getGroupDropdown()}
            disabled={disabled}
            searchable
            selectButtons
          />
          <Field
            name="projectId"
            component={renderMultiSelectField}
            label="Project:"
            options={getProjectDropdown()}
            disabled={disabled}
            searchable
            selectButtons
          />
          <Field
            name="employeeNumber"
            type="text"
            component={renderField}
            label="Employee Id:"
            placeholder={''}
            disabled={disabled}
            validate={[required]}
            requiredLabel
          />
          <Field
            name="person.firstName"
            type="text"
            component={renderField}
            label="First Name:"
            validate={[required]}
            placeholder={''}
            requiredLabel={true}
            disabled={disabled}
          />
          <Field
            name="person.lastName"
            type="text"
            component={renderField}
            label="Last Name:"
            validate={[required]}
            placeholder={''}
            requiredLabel={true}
            disabled={disabled}
          />
          <Field
            name="person.gender"
            type="text"
            component={renderDropdownField}
            label="Sex:"
            placeholder={''}
            options={GENDER_DROPDOWN}
            disabled={disabled}
          />
          <Field
            name="dateOfBirth"
            type="text"
            component={renderDatePicker}
            label="Date of Birth:"
            placeholder={''}
            disabled={disabled}
            warn={[notFutureDate, isValidDate]}
          />
          <Field
            name="person.lastFourSSN"
            type="text"
            component={renderField}
            label="Last four of SSN:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="dateOfHire"
            type="text"
            component={renderDatePicker}
            label="Date of Hire:"
            placeholder={''}
            disabled={disabled}
            validate={[required, isValidDate]}
            requiredLabel={true}
          />
        </div>
        <div className="form-fields--rightSide">
          <Field
            name="jobTitle"
            type="text"
            component={renderField}
            label="Job Title:"
            validate={[required]}
            placeholder={''}
            requiredLabel={true}
            disabled={disabled}
          />
          <Field
            name="status"
            component={renderDropdownField}
            label="Employee Status:"
            placeholder={''}
            options={COMBINED_EMPLOYEE_STATUS}
            format={v => parseInt(v, 10)}
            disabled={disabled}
            validate={[required]}
            requiredLabel={true}
            searchable={true}
          />
          <Field
            name="trainingRequired"
            type="text"
            component={renderDropdownField}
            label="Training Required:"
            placeholder={''}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            disabled={disabled}
          />
          <Field
            name="location"
            type="text"
            component={renderField}
            label="Location:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="department"
            type="text"
            component={renderField}
            label="Department:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="supervisor"
            type="text"
            component={renderField}
            label="Supervisor:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="workDays"
            component={renderMultiSelectField}
            label="Work Days:"
            placeholder={''}
            options={DAY_OF_WEEK_DROPDOWN}
            disabled={disabled}
          />
          <Field
            name="hoursPerWeek"
            type="text"
            component={renderField}
            label="Hours Per Week:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="dateOfTermination"
            type="text"
            component={renderDatePicker}
            label="Last Date of Employment:"
            placeholder={''}
            disabled={disabled}
          />
        </div>
      </div>
      <h2>Contact Information</h2>
      <div className="form-fields--split">
        <div className="form-fields--leftSide">
          <Field
            name="homeAddress"
            type="text"
            component={renderField}
            label="Home Address:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="city"
            type="text"
            component={renderField}
            label="City:"
            placeholder={''}
            disabled={disabled}
          />
        </div>
        <div className="form-fields--rightSide">
          <Field
            name="state"
            type="text"
            component={renderDropdownField}
            label="State:"
            placeholder={''}
            options={states}
            disabled={disabled}
            searchable={true}
          />
          <Field
            name="zip"
            type="text"
            component={renderField}
            label="Zip:"
            placeholder={''}
            disabled={disabled}
          />
        </div>
      </div>
      <h2>Emergency Contact Information</h2>
      <div className="form-fields--split">
        <div className="form-fields--leftSide">
          <Field
            name="person.emergencyContact.emergencyContact"
            type="text"
            component={renderField}
            label="Contact:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="person.emergencyContact.phone"
            type="text"
            component={renderField}
            label="Phone:"
            placeholder={''}
            normalize={normalizePhone}
            disabled={disabled}
          />
        </div>
        <div className="form-fields--rightSide">
          <Field
            name="person.emergencyContact.relationship"
            type="text"
            component={renderField}
            label="Relationship:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="person.emergencyContact.email"
            type="text"
            component={renderField}
            label="Email:"
            placeholder={''}
            warn={[email]}
            disabled={disabled}
          />
        </div>
      </div>
      {!isUpdate && accessLevel === '900' ? (
        <div>
          <h2>User Information</h2>
          <Field
            name="userAccount.username"
            type="text"
            component={renderField}
            label="Username: (if textbox turns red, username is not available)"
            validate={[required]}
            placeholder="Enter a username"
            required={true}
            requiredLabel={true}
            onChange={e => {
              return checkUsernameAvailability(e);
            }}
          />
          <h3>
            In order to receive notifications about new Reports, Tasks, and Risk
            Assessments you need to provide a valid phone number, email, or
            both.
          </h3>
          <div className="form-fields--split">
            <div className="form-fields--leftSide">
              <Field
                name="phone"
                type="text"
                component={renderField}
                label="Phone:"
                placeholder={''}
                normalize={normalizePhone}
                disabled={disabled}
              />
            </div>
            <div className="form-fields--rightSide">
              <Field
                name="email"
                type="text"
                component={renderField}
                label="Email:"
                placeholder={''}
                warn={[email]}
                disabled={disabled}
              />
            </div>
          </div>
          <Field
            name="userAccount.userType"
            options={[
              { value: 'microsoft', label: 'Microsoft Login' },
              { value: 'none', label: 'N/A' }
            ]}
            component={renderRadioButtons}
            label="Using Microsoft login? NOTE: Must have first set up your company's Azure Active Directory with iReportSource. If unsure, chose N/A."
            validate={[required]}
            required={true}
            requiredLabel={true}
          />
          <Field
            name="userAccount.isDeactivated"
            options={[
              { value: false, label: 'Activated User' },
              { value: true, label: 'Deactivated User (cannot login)' }
            ]}
            component={renderRadioButtons}
            label="Should this user be activated? (activated users will receive a welcome email once they are created, giving them instructions on how to log in to iReport. Deactivated users can be activated later on.)"
            validate={[booleanRequired]}
            required={true}
            requiredLabel={true}
          />
          <Field
            name="userAccount.accessLevel"
            component={renderUserButtons}
            label="User Type:"
            placeholder={''}
            disabled={disabled}
            accountInfo={accountInfo}
            required={true}
            requiredLabel={true}
          />
        </div>
      ) : (
        ''
      )}
    </form>
  );
};

EmployeeForm = reduxForm({
  form: 'EmployeeForm',
  enableReinitialize: true
})(EmployeeForm);

const selector = formValueSelector('EmployeeForm');

const mapStateToProps = state => ({
  groupId: selector(state, 'locationId'),
  companyIds: selector(state, 'companyId')
});

export default connect(mapStateToProps)(EmployeeForm);
