import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderNumberField, required } from '../../utils/formValidation';

import '../form.css';

const SchoolEmploymentForm = props => {
  const { handleSubmit } = props;

  return (
    <form className="oshaEmploymentForm" onSubmit={handleSubmit}>
      <div className="form-fields">
        <Field
          name="numberOfTeachersInstructors"
          component={renderNumberField}
          label="Teachers/Instructors."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
        />
        <Field
          name="numberOfAllOtherSupportStaff"
          component={renderNumberField}
          label="All others/support staff."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'SchoolEmploymentForm'
})(SchoolEmploymentForm);
