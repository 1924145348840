import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateLeftNavRequest } from '../../actions/navigation';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  createOshaLogRequest,
  fetchOshaLogLocationRequest,
  fetchOshaLogsCompanyRequest,
  printOshaRequest,
  printSelectedOshaRequest,
  updateOshaEmployeeRequest
} from '../../actions/oshaLogs';
import { fetchAllActiveEmployees } from '../../actions/personnel';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getAllLocations,
  getUserCompaniesSelector
} from '../../selectors/company';
import {
  getOsha300ALogSelector,
  getOsha300LogSelector,
  getOsha301LogSelector
} from '../../selectors/oshaLogs';
import { getLoggedInUser } from '../../selectors/users';
import yearToDateDropdown from '../../utils/yeartoDateDropdown';
import Button from '../../components/inputs/Button';
import Dropdown from '../../components/inputs/Dropdown';
import Modal from '../../components/Modal';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import Osha300List from '../../components/Osha300List';
import Osha300AForm from '../../components/Osha300AForm';
import Osha301List from '../../components/Osha301List';
import OshaLogDownload from '../../components/OshaLogDownload';
import { InputRow } from '../../components/inputs';

import './index.css';

export class OshaLog extends Component {
  state = {
    selected: 0,
    year: new Date().getFullYear(),
    selectedRow: {},
    divisonEstInfo: {},
    openPrintModal: false,
    printValues: [],
    canPrint300ACSV: true,
    missingNoInformation: true
  };

  componentDidMount() {
    this.props.updateLeftNav('OSHA Forms');
    this.props.fetchAllActiveEmployees();

    if (this.props.activeLocationId === 0) {
      this.props.fetchCompanyOsha({ year: new Date().getFullYear() });
      this.setState({
        divisonEstInfo: {
          ...this.props.activeCompany,
          goToLocation: false,
          isSeparateEstablishment: true
        }
      });
    } else {
      this.props.fetchLocationOsha({ year: new Date().getFullYear() });
      const locationIndex = this.props.activeCompany.groups.findIndex(
        location =>
          location._id === this.props.activeLocationId.fromLocationId ||
          location._id === this.props.activeLocationId._id
      );

      if (locationIndex > -1) {
        const location = this.props.activeCompany.groups[locationIndex];

        const longerThanYear = location.operationalForYearOrLonger;
        const sepEst = location.isSeparateEstablishment;

        const locationObject =
          longerThanYear && sepEst
            ? {
                name: location.name,
                mainAddress: {
                  streetAddress: location.streetAddress,
                  city: location.city,
                  stateName: location.state,
                  zip: location.zip
                },
                NAICS: location.NAICS,
                SIC: location.SIC,
                entityCode: location.entityCode,
                bwcNumber: location.bwcNumber,
                reportingType: location.reportingType,
                hasPublicEntity: location.hasPublicEntity,
                hasSchoolEntity: location.hasSchoolEntity,
                numberOfAllOtherSupportStaff:
                  location.numberOfAllOtherSupportStaff,
                numberOfFireEmtParamedic: location.numberOfFireEmtParamedic,
                numberOfFullTime: location.numberOfFullTime,
                numberOfPartTime: location.numberOfPartTime,
                numberOfPoliceFireEmt: location.numberOfPoliceFireEmt,
                numberOfTeachersInstructors:
                  location.numberOfTeachersInstructors,
                isSeparateEstablishment: true
              }
            : {
                ...this.props.activeCompany,
                name: location.name,
                isSeparateEstablishment: false
              };

        this.setState({
          divisonEstInfo: {
            ...locationObject,
            goToLocation: longerThanYear
          }
        });
      }
    }

    if (this.props.match.params.year && this.props.match.params.year !== '') {
      this.setState({ year: this.props.match.params.year, selected: 1 });
    }
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.osha300Logs.lineItems &&
      prevProps.osha300Logs.lineItems !== this.props.osha300Logs.lineItems
    ) {
      let incompleteCases = false;

      this.props.osha300Logs.lineItems &&
        this.props.osha300Logs.lineItems.forEach(item => {
          if (item.isIncomplete) {
            incompleteCases = true;
          }
        });

      if (incompleteCases) {
        this.setState({ canPrint300ACSV: false, missingNoInformation: false });
      } else {
        this.setState({ canPrint300ACSV: true, missingNoInformation: true });
      }
    }
  };

  updateState = stateObject => {
    this.setState(stateObject);
  };

  handleYearSelection = year => {
    if (this.props.activeLocationId === 0) {
      this.props.fetchCompanyOsha({ year: year.value });
    } else {
      this.props.fetchLocationOsha({ year: year.value });
    }
    this.updateState({ year: year.value });
  };

  handleOpenModal = () => {
    this.setState({ openPrintModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openPrintModal: false });
  };

  render() {
    const {
      selected,
      year,
      selectedRow,
      divisonEstInfo,
      openPrintModal,
      printValues,
      canPrint300ACSV,
      missingNoInformation
    } = this.state;

    const {
      osha300Logs,
      osha300ALogs,
      osha301Logs,
      createOshaLog,
      setActiveIncident,
      printOsha,
      updateOshaEmployee,
      printSelectedOsha,
      activeProject,
      activeLocationId,
      userCompanies
    } = this.props;

    const canPrint301 =
      !osha301Logs.lineItems || osha301Logs.lineItems.length === 0
        ? false
        : true;

    const header = (
      <Header
        title={
          divisonEstInfo.reportingType === 2 ? 'PERRP Forms' : 'OSHA Forms'
        }
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header} className="oshaLog">
          <div className="oshaLog-header">
            <div className="oshaLog-tabs">
              <div
                className={
                  selected === 0
                    ? 'oshaLog-tab oshaLog-tab1 selected'
                    : 'oshaLog-tab oshaLog-tab1 oshaLog-tab--notSelected'
                }
                onClick={() => this.updateState({ selected: 0 })}
              >
                <div className="oshaLog-tabs--headerTopLine">
                  {divisonEstInfo.reportingType === 2 ? '300P' : 'OSHA 300'}
                </div>
                <div className="oshaLog-tabs--headerBottomLine">
                  Injury & Illness Log
                </div>
                {selected === 0 && <div className="oshaLog-tab--selected" />}
              </div>
              <div
                className={
                  selected === 1
                    ? 'oshaLog-tab oshaLog-tab2 selected'
                    : 'oshaLog-tab oshaLog-tab2 oshaLog-tab--notSelected'
                }
                onClick={() => this.updateState({ selected: 1 })}
              >
                <div className="oshaLog-tabs--headerTopLine">
                  {divisonEstInfo.reportingType === 2 ? '300AP' : 'OSHA 300A'}
                </div>
                <div className="oshaLog-tabs--headerBottomLine"> Summary</div>
                {selected === 1 && (
                  <div className="oshaLog-tab2 oshaLog-tab--selected" />
                )}
              </div>
              <div
                className={
                  selected === 2
                    ? 'oshaLog-tab oshaLog-tab3 selected'
                    : 'oshaLog-tab oshaLog-tab3 oshaLog-tab--notSelected'
                }
                onClick={() => this.updateState({ selected: 2 })}
              >
                <div className="oshaLog-tabs--headerTopLine">
                  {divisonEstInfo.reportingType === 2 ? '301P' : 'OSHA 301'}
                </div>
                <div className="oshaLog-tabs--headerBottomLine">
                  Incident Reports
                </div>
                {selected === 2 && (
                  <div className="oshaLog-tab3 oshaLog-tab--selected" />
                )}
              </div>
            </div>
            <InputRow className="oshaLog-selection">
              <Dropdown
                options={yearToDateDropdown()}
                currentValue={year}
                handleChange={value => this.handleYearSelection(value)}
                inputClassName="oshaLog-selection--dropdown"
              />
              <Button
                text="Download Files"
                color="green"
                onClick={() => this.handleOpenModal()}
              />
            </InputRow>
          </div>
          <div className="oshaLog-tabContent">
            {selected === 0 &&
            osha300Logs.lineItems &&
            osha300Logs.lineItems.length === 0 ? (
              <div className="oshaLogTab1-noContent">
                <div style={{ fontWeight: 600 }}>No Cases Found </div>
                <div>Check whether you have correct year</div>
              </div>
            ) : selected === 0 ? (
              <Osha300List
                cases={osha300Logs.lineItems}
                goTo301={values => this.updateState(values)}
                setActiveIncident={setActiveIncident}
              />
            ) : (
              ''
            )}
            {selected === 1 ? (
              <Osha300AForm
                aggrates={osha300ALogs}
                divisonEstInfo={divisonEstInfo}
                createOshaLog={createOshaLog}
                year={year}
                isPerrp={divisonEstInfo.reportingType === 2}
              />
            ) : (
              ''
            )}
            {selected === 2 &&
            osha301Logs.lineItems &&
            osha301Logs.lineItems.length === 0 ? (
              <div className="oshaLogTab1-noContent">
                <div style={{ fontWeight: 600 }}>No Cases Found </div>
                <div>Check whether you have correct year</div>
              </div>
            ) : selected === 2 ? (
              <Osha301List
                incidentReports={osha301Logs.lineItems}
                selectedRow={selectedRow}
                setActiveIncident={setActiveIncident}
                updateOshaEmployee={updateOshaEmployee}
                printSelectedOsha={printSelectedOsha}
                activeLocationId={activeLocationId}
                activeProject={activeProject}
                isPerrp={divisonEstInfo.reportingType === 2}
                userCompanies={userCompanies}
              />
            ) : (
              ''
            )}
          </div>
        </HeaderAndFooter>
        {JSON.stringify(divisonEstInfo) !== '{}' && (
          <Modal
            title="Download Official Files"
            titleClassName="blueHeader"
            submitButtonText="Download Files"
            isOpen={openPrintModal}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={() =>
              printOsha({
                year: year,
                print: printValues
              }) && this.handleCloseModal()
            }
            disableSubmit={printValues.length === 0}
          >
            <OshaLogDownload
              handleValueChange={values =>
                this.updateState({ printValues: values })
              }
              canPrint300ACSV={canPrint300ACSV}
              canPrint301={canPrint301}
              missingNoInformation={missingNoInformation}
              selected={selected}
              isShortTermEstablishment={!divisonEstInfo.isSeparateEstablishment}
              aggrates={osha300ALogs}
              divisonEstInfo={divisonEstInfo}
            />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  osha300Logs: getOsha300LogSelector(state),
  osha300ALogs: getOsha300ALogSelector(state),
  osha301Logs: getOsha301LogSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocationId: getActiveLocationId(state),
  allLocations: getAllLocations(state),
  loggedInUser: getLoggedInUser(state),
  activeProject: getActiveProject(state),
  userCompanies: getUserCompaniesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  fetchCompanyOsha: year => dispatch(fetchOshaLogsCompanyRequest(year)),
  fetchLocationOsha: year => dispatch(fetchOshaLogLocationRequest(year)),
  createOshaLog: values => dispatch(createOshaLogRequest(values)),
  setActiveIncident: incidentId =>
    dispatch(setActiveIncidentRequest(incidentId)),
  printOsha: values => dispatch(printOshaRequest(values)),
  updateOshaEmployee: values => dispatch(updateOshaEmployeeRequest(values)),
  fetchAllActiveEmployees: () => dispatch(fetchAllActiveEmployees()),
  printSelectedOsha: values => dispatch(printSelectedOshaRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(OshaLog);
