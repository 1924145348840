import React from 'react';

import styles from './styles.module.scss';

export const AnalyticCircle = ({ timeFrame, count }) => (
  <div className={styles.circle}>
    <div>
      <div>
        <div className={styles.circleTime}>{timeFrame}</div>
        <div>{count}</div>
      </div>
    </div>
  </div>
);

export const AnalyticCircleBox = ({ circles }) => (
  <div className={styles.box}>
    {circles &&
      circles.map((circle, index) => (
        <AnalyticCircle {...circle} key={index} />
      ))}
  </div>
);

export default AnalyticCircle;
