import React, { Component } from 'react';

import Checkbox from '../Checkbox';
import Input from '../';

import styles from './checkboxGroup.module.scss';

export default class CheckboxGroup extends Component {
  handleChange = newValue => {
    const { handleChange, options } = this.props;

    const newSelected = options.filter(
      option => newValue.indexOf(option.value) > -1
    );

    handleChange(newSelected);
  };

  render() {
    const {
      name,
      fieldLabel,
      options,
      isRequired,
      disabled,
      className,
      displayTooltip,
      columns,
      currentValue,
      tooltips
    } = this.props;

    const classes = [className, columns && styles.checkboxColumns];

    let values = currentValue;
    if (!values) {
      values = [];
    } else if (!Array.isArray(values)) {
      values = [values];
    }
    values = values.map(v => v?.value ?? v?.label ?? v);

    return (
      <Input
        className={classes}
        fieldLabel={fieldLabel}
        isRequired={isRequired}
      >
        {options.map((option, index) => (
          <Checkbox
            key={index}
            fieldLabel={option.label}
            currentValue={
              values.indexOf(option.value) !== -1 ||
              values.indexOf(option.label) !== -1
            }
            className={styles.checkboxInput}
            name={`${name}[${index}]`}
            onChange={checked => {
              if (checked) {
                values.push(option.value);
              } else {
                values = values.filter(v => v !== option.value);
              }

              this.handleChange(values);
            }}
            displayTooltip={displayTooltip && tooltips?.[index]}
            disabled={disabled || option.disabled}
            tooltip={tooltips?.[index]}
          />
        ))}
      </Input>
    );
  }
}
