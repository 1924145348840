import React, { Component } from 'react';
import Modal from '.';

export class DeleteItemModal extends Component {
  render() {
    return (
      <Modal
        title={`Delete ${this.props.folderName}`}
        titleClassName="redHeader"
        submitButtonColor="blue"
        submitButtonText="Delete Folder and Save Contents "
        isOpen={this.props.isOpen}
        submitActions={this.props.submitActions}
        onRequestClose={this.props.onRequestClose}
        cancelActions={this.props.cancelActions}
        cancelButtonText="Delete All Contents"
        cancelButtonColor="red"
      >
        Do you want to delete the folder and all the documents within the
        folder, or delete the folder and remove contents from the folder?
      </Modal>
    );
  }
}

export default DeleteItemModal;
