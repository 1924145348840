import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import {
  addSafetyWalkRequest,
  deleteSafetyWalkRequest,
  setActiveSafetyWalk,
  updateSafetyWalkRequest
} from '../../actions/safetyWalks';
import { setActiveTaskRequest } from '../../actions/tasks';
import { SAFETY_WALKS_STAGES } from '../../constants/constants';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import { getActiveSafetyWalkSelector } from '../../selectors/safetyWalks';
import { getLoggedInUser } from '../../selectors/users';
import Card from '../../components/Card';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { SaveCancelFooter } from '../../components/Footer';
import { TwoColumn } from '../../components/inputs';
import Dropdown from '../../components/inputs/Dropdown';
import HazardSummaryCard from '../../components/safetyWalkCards/HazardSummaryCard';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import SignatureCard from '../../components/SignatureCard';
import SummaryCard from '../../components/safetyWalkCards/SummaryCard';
import Textbox from '../../components/inputs/Textbox';
import LeftNav from '../../components/LeftNav';
import StageSelector from '../../components/StageSelector';

class SafetyWalkContainer extends Component {
  state = {
    hasUnsavedChanges: false,
    isCreating: true,
    openDiscardModal: false,
    openUnsavedChangesModal: false,
    noHazardsModal: false,
    groupId: '',
    projectId: '',
    inspectionLocation: '',
    signature: undefined
  };

  componentDidMount() {
    if (this.props.activeSafetyWalk.groupId) {
      this.setState({
        ...this.props.activeSafetyWalk,
        isCreating: !this.props.activeSafetyWalk._id
      });
    } else if (this.props.activeProject) {
      this.setState({
        companyId: this.props.activeCompany._id,
        groupId: this.props.activeLocation._id,
        projectId: this.props.activeProject._id,
        isCreating: true
      });
    } else if (this.props.activeLocation) {
      this.setState({
        companyId: this.props.activeCompany._id,
        groupId: this.props.activeLocation._id,
        isCreating: true
      });
    } else {
      this.setState({
        companyId: this.props.activeCompany._id,
        isCreating: true,
        hasUnsavedChanges: true
      });
    }
  }

  canSubmit = () => {
    return (
      this.state.groupId &&
      this.state.inspectionLocation &&
      this.state.signature
    );
  };

  handleAddHazard = () => {
    this.props.setSafetyWalk({
      companyId: this.state.companyId,
      groupId: this.state.groupId,
      projectId: this.state.projectId,
      inspectionLocation: this.state.inspectionLocation,
      hazards:
        this.props.activeSafetyWalk?.hazards?.length > 0
          ? this.props.activeSafetyWalk.hazards
          : []
    });

    history.push('/app/safetyWalkContainer/hazardCard');
  };

  handleSWSubmit = () => {
    this.props.addSafetyWalk({
      companyId: this.state.companyId,
      groupId: this.state.groupId,
      projectId: this.state.projectId,
      inspectionLocation: this.state.inspectionLocation,
      hazards: this.props.activeSafetyWalk.hazards,
      signature: this.state.signature
    });
    this.setState({
      isCreating: false,
      noHazardsModal: false,
      hasUnsavedChanges: false
    });
  };

  updateStage = value => {
    this.props.updateSafetyWalk({
      ...this.props.activeSafetyWalk,
      ...value
    });
  };

  render() {
    const {
      hasUnsavedChanges,
      isCreating,
      openUnsavedChangesModal,
      openDiscardModal,
      noHazardsModal,
      groupId,
      projectId,
      inspectionLocation,
      backToDashboard
    } = this.state;

    const {
      activeCompany,
      activeLocation,
      activeProject,
      setActiveTask,
      activeSafetyWalk,
      loggedInUser,
      setSafetyWalk
    } = this.props;

    const hazards = activeSafetyWalk?.hazards || [];
    const groupDropdown = activeCompany.groups.map(group => {
      return {
        value: group._id,
        label: group.name
      };
    });

    const getProjectDropdown = () => {
      const selectedGroup = activeCompany.groups.filter(
        group => group._id === activeLocation._id || group._id === groupId
      );

      let temp = [];

      if (selectedGroup.length > 0) {
        temp = selectedGroup[0].projects.map(project => {
          return {
            value: project._id,
            label: project.name
          };
        });
        return [{ value: '', label: '      ' }, ...temp];
      }

      return [];
    };

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={() =>
          activeSafetyWalk?.hazards?.length === 0
            ? this.setState({ noHazardsModal: true })
            : this.handleSWSubmit()
        }
        cancelButtonClick={() =>
          backToDashboard ? history.push('/app/dashboard') : history.goBack()
        }
      />
    );

    const myName = activeCompany.isDash ? 'DASH Report' : 'Safety Walk';
    const deleteText = `Delete ${myName}`;
    const accessLevel = parseInt(loggedInUser?.accessLevel || 0, 10);

    const header = (
      <Header
        title={`${isCreating ? 'Create' : 'View'} ${myName}`}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : backToDashboard
            ? history.push('/app/dashboard')
            : history.goBack()
        }
        center={
          !isCreating && (
            <StageSelector
              label={`${myName} Stage`}
              options={SAFETY_WALKS_STAGES}
              currentValue={activeSafetyWalk.stage}
              onChange={value => this.updateStage({ stage: value })}
              disabled={accessLevel === 100}
            />
          )
        }
        rightButtons={{
          text: deleteText,
          color: 'red',
          visible:
            !isCreating &&
            accessLevel !== 100 &&
            (accessLevel === 900 ||
              activeSafetyWalk.createdBy._id === loggedInUser._id),
          onClick: () => this.setState({ openDiscardModal: true })
        }}
      />
    );

    let leftNav = hazards.map((h, i) => ({
      label: `#${i + 1} ${h.title}`,
      id: `h-${i}`
    }));
    leftNav.push({ label: 'Signature', id: 'signature' });
    leftNav.unshift({ label: `${myName} Info`, id: 'info' });
    if (!isCreating) leftNav.unshift({ label: 'Summary', id: 'summary' });

    const left = (
      <LeftNav
        items={leftNav}
        onAddHazard={isCreating && this.handleAddHazard}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={left}
          showFooter={isCreating}
        >
          {!isCreating && (
            <SummaryCard
              safetyWalk={activeSafetyWalk}
              loggedInUser={loggedInUser}
              name="summary"
              isDash={activeCompany.isDash}
            />
          )}
          <Card showHeader title={`${myName} Info`} name="info">
            <TwoColumn>
              {!activeLocation && (
                <Dropdown
                  searchable={true}
                  options={groupDropdown}
                  fieldLabel="Group or Establishment:"
                  currentValue={groupId}
                  handleChange={values =>
                    this.setState({
                      groupId: values.value,
                      hasUnsavedChanges: true
                    })
                  }
                  isRequired={true}
                  disabled={!isCreating}
                />
              )}
              {!activeProject && (
                <Dropdown
                  searchable={true}
                  options={getProjectDropdown()}
                  fieldLabel="Project:"
                  currentValue={projectId}
                  handleChange={values =>
                    this.setState({
                      projectId: values.value,
                      hasUnsavedChanges: true
                    })
                  }
                  disabled={
                    !isCreating || !groupId || getProjectDropdown().length === 1
                  }
                  placeholder={
                    !isCreating || getProjectDropdown().length === 1
                      ? 'No Projects with Group or Establishment'
                      : ''
                  }
                />
              )}
            </TwoColumn>
            <Textbox
              fieldLabel="Inspection Location"
              isRequired={true}
              placeholder="Where is the inspection taking place?"
              handleChange={e =>
                this.setState({ inspectionLocation: e.target.value })
              }
              currentValue={inspectionLocation}
              disabled={!isCreating}
            />
          </Card>
          {hazards.map((hazard, index) => (
            <HazardSummaryCard
              hazard={hazard}
              key={index}
              history={history}
              setActiveTask={setActiveTask}
              index={index}
              groupId={groupId}
              projectId={projectId}
              name={`h-${index}`}
            />
          ))}
          <SignatureCard
            currentValue={this.state.signature}
            name="signature"
            disabled={!isCreating}
            header="Signature"
            onChange={signature => this.setState({ signature })}
          />
        </HeaderAndFooter>
        <Modal
          title={deleteText}
          titleClassName="redHeader"
          isOpen={openDiscardModal}
          submitButtonColor="red"
          submitButtonText={deleteText}
          onRequestClose={() => this.setState({ openDiscardModal: false })}
          submitActions={() => {
            if (!this.state.isCreating) {
              this.props.deleteSafetyWalk(this.props.activeSafetyWalk._id);
            }
            setSafetyWalk({ hazards: [] });
            backToDashboard ? history.push('/app/dashboard') : history.goBack();
          }}
        >
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Discarding the {myName} will delete all information that has been
            gathered, pictures taken, and corrective actions assigned as tasks.
          </span>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() =>
            setSafetyWalk({ hazards: [] }) && backToDashboard
              ? history.push('/app/dashboard')
              : history.goBack()
          }
        />
        <Modal
          title="No Hazards"
          titleClassName="blueHeader"
          isOpen={noHazardsModal}
          submitButtonColor="blue"
          submitButtonText="Continue"
          onRequestClose={() => this.setState({ noHazardsModal: false })}
          submitActions={() => this.handleSWSubmit()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            No Hazards have been added to this {myName}. If this is correct,
            please continue. Otherwise, cancel and add the Hazards before
            Submitting.
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  activeSafetyWalk: getActiveSafetyWalkSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  addSafetyWalk: payload => dispatch(addSafetyWalkRequest(payload)),
  setSafetyWalk: payload => dispatch(setActiveSafetyWalk(payload)),
  setActiveTask: payload => dispatch(setActiveTaskRequest(payload)),
  updateSafetyWalk: payload => dispatch(updateSafetyWalkRequest(payload)),
  deleteSafetyWalk: payload => dispatch(deleteSafetyWalkRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SafetyWalkContainer);
