import React from 'react';
import ReactTable from 'react-table';
import Button from '../../inputs/Button';

export default function TrainingTable({ attachments, setActiveAttachment }) {
  return (
    <ReactTable
      data={attachments}
      filterable
      resizable={true}
      noDataText="No results found"
      defaultPageSize={10}
      className="-striped -highlight"
      columns={[
        {
          Header: 'TITLE',
          accessor: d => d.original_filename,
          id: 'title'
        },
        {
          Header: 'TIME SPENT(MINUTES)',
          accessor: d => d.timeSpent,
          id: 'timeSpent',
          filterable: false
        },
        {
          Header: 'VIEW',
          Cell: ({ original }) => (
            <Button
              className="blueButton"
              onClick={() => setActiveAttachment(original)}
              text="View"
            />
          ),
          filterable: false,
          sortable: false
        }
      ]}
    />
  );
}
