import React from 'react';
import moment from 'moment';
import {
  MONTHLY_FREQUENCY_OPTIONS,
  PRIORITY_DROPDOWN,
  YEARLY_FREQUENCY_OPTIONS
} from '../../constants/constants';
import Button from '../inputs/Button';
import DayOfWeekButtons from '../inputs/DayOfWeekButtons';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const TaskList = ({
  taskHeader,
  content,
  onClick,
  activeGroups,
  hasBorder
}) => {
  const getGroupName = groupId => {
    if (!groupId) return 'No Group Selected';
    const group = activeGroups.filter(group => group._id === groupId);

    if (group.length > 0) {
      return group[0].name;
    }

    return 'No Group Selected';
  };

  return (
    <table className={hasBorder ? 'table bordered' : 'table'}>
      <ScrollToTopOnMount />
      <thead>
        <tr>
          {taskHeader.map((header, index) => (
            <th scope="col" key={index}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {content.map((row, index) => (
          <tr key={index}>
            <td>{getGroupName(row.groupId)}</td>
            <td>{row.label}</td>
            <td>
              {row.currentPriority &&
                PRIORITY_DROPDOWN[row.currentPriority - 1].label}
            </td>
            {row.isMaster ? (
              <td>
                {row.frequency === 'monthly' ? (
                  <div>
                    {
                      MONTHLY_FREQUENCY_OPTIONS.filter(
                        month => month.value === row.repeatingOn[0]
                      )[0].label
                    }
                  </div>
                ) : row.frequency === 'yearly' ? (
                  <div>
                    {
                      YEARLY_FREQUENCY_OPTIONS.filter(
                        month => month.value === row.repeatingOn[0]
                      )[0].label
                    }
                  </div>
                ) : (
                  <DayOfWeekButtons
                    currentValue={row.repeatingOn}
                    disabled={true}
                  />
                )}
              </td>
            ) : (
              <td>{moment(row.dueDate).format('MM/DD/YYYY hh:mm a')}</td>
            )}
            <td>
              <Button
                className="blueButton"
                onClick={() => onClick(row)}
                text="View/Edit"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default TaskList;
