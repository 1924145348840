import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import history from '../../history';
import svgMapper from '../../utils/svgMapper';
import Modal from '../../components/Modal';
import Textbox from '../../components/inputs/Textbox';
import Button from '../../components/inputs/Button';

class LeftNavSection extends Component {
  state = {
    documentType: '',
    folderName: '',
    openModal: false,
    openFolder: 'All Folders'
  };

  handleAddFolder = async () => {
    await this.props.addDocumentFolder({
      documentType: this.state.documentType,
      name: this.state.folderName
    });
    this.handleCloseModal();
  };

  openFolder = (folder, link) => {
    this.props.setActiveFolder({ ...folder, page: 1, limit: 10000 });
    history.push(link);
    this.setState({ openFolder: folder.name });
  };

  handleOpenModal = value => {
    this.setState({ openModal: true, documentType: value });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, folderName: '' });
  };

  fetchAllDocuments = (documentType, link) => {
    this.props.setActiveFolder({});

    if (documentType === 'SDS') {
      this.props.fetchSds();
    } else if (documentType === 'SafetyInstruction') {
      this.props.fetchSafetyInstructions();
    } else if (documentType === 'SafetyTraining') {
      this.props.fetchTrainings();
    } else if (documentType === 'Qualification') {
      this.props.fetchQualifications();
    } else if (documentType === 'DailyReports') {
      this.props.fetchDailyReports();
    } else if (documentType === 'ToolboxTalks') {
      this.props.fetchToolboxTalks();
    }

    history.push(link);
    this.setState({ openFolder: 'All Folders' });
  };

  handleCloseModalMenu = () => {
    this.props.setActiveFolder({});
    this.setState({ openFolder: '' });
    this.props.closeModalMenu();
  };

  render() {
    const shouldDisplay = (
      navItem,
      project,
      location,
      company,
      accessLevel,
      hasRequiredAuthdocs
    ) => {
      const navItemAccess = navItem.accessLevel.indexOf(accessLevel) !== -1;
      const navItemAppProject =
        navItem.appLevel.indexOf('Project') !== -1 && project;
      const navItemAppLocation =
        navItem.appLevel.indexOf('Group') !== -1 && location;
      const navItemAppCompany =
        navItem.appLevel.indexOf('Division') !== -1 && company;

      if (!hasRequiredAuthdocs && navItem.text === 'Require Acknowledgment')
        return false;

      if (!navItem.showFeature) {
        return false;
      }

      if (
        (navItem.text === 'Aircraft Reports' ||
          navItem.text === 'Fuel Related Reports') &&
        !activeCompany.showAircraft
      )
        return false;

      if (navItem.text === 'My Analytics' && activeCompany.hideAnalytics)
        return false;

      if (project) {
        return navItemAccess && navItemAppProject;
      } else if (location) {
        return navItemAccess && navItemAppLocation;
      } else {
        return navItemAccess && navItemAppCompany;
      }
    };

    const {
      title,
      section,
      accessLevel,
      sectionOpen,
      openNewSection,
      activeCompany,
      activeLocation,
      activeProject,
      documentFolders,
      hasRequiredAuthdocs
    } = this.props;

    const { folderName, openModal, openFolder } = this.state;

    return (
      <div className="sidebar-section" onClick={() => openNewSection()}>
        <p
          className={
            sectionOpen ? 'sidebar-label activeSection' : 'sidebar-label'
          }
        >
          {title}
        </p>
        <ul
          className={
            sectionOpen ? 'sidebar-menu activeSection' : 'sidebar-menu'
          }
        >
          {section.map((navItem, index) => {
            return shouldDisplay(
              navItem,
              activeProject,
              activeLocation,
              activeCompany,
              accessLevel,
              hasRequiredAuthdocs
            ) ? (
              <li key={index}>
                <Link
                  className={navItem.active ? 'active' : ''}
                  to={{ pathname: navItem.link }}
                  onClick={() => this.handleCloseModalMenu()}
                >
                  {navItem.text === 'Require Acknowledgment' ? (
                    <img
                      src={require('../../assets/images/Require Authorization.png')}
                      alt="Require Authorzation option"
                      style={{
                        width: '20px',
                        height: '20px',
                        paddingTop: '5px'
                      }}
                    />
                  ) : (
                    svgMapper(navItem.image)
                  )}
                  <span>
                    {activeCompany.reportingType === 2 &&
                    navItem.text === 'OSHA Forms'
                      ? 'PERRP Forms'
                      : activeCompany.isDash && navItem.text === 'Safety Walks'
                      ? 'DASH Report'
                      : navItem.text}
                  </span>
                </Link>
                {navItem.hasFolders && navItem.active && (
                  <div className="folderContainer">
                    <div
                      className={
                        openFolder === 'All Folders'
                          ? 'navItem-allFolder folder-selected'
                          : 'navItem-allFolder'
                      }
                      onClick={() =>
                        this.fetchAllDocuments(navItem.folderType, navItem.link)
                      }
                    >
                      All Folders
                    </div>
                    <ul className="navItem-folderList">
                      {documentFolders.map((folder, index) => (
                        <li
                          key={index}
                          className={
                            openFolder === folder.name ? 'folder-selected' : ''
                          }
                          onClick={() => this.openFolder(folder, navItem.link)}
                        >
                          {openFolder === folder.name ? (
                            <img
                              src={require('../../assets/images/Folder Open Black.png')}
                              className="navItem-folderList--addButton"
                              alt="folder closed"
                            />
                          ) : (
                            <img
                              src={require('../../assets/images/Folder Closed White.png')}
                              className="navItem-folderList--addButton"
                              alt="folder closed"
                            />
                          )}{' '}
                          {folder.name}
                        </li>
                      ))}
                      {accessLevel !== '100' && (
                        <li>
                          <Button
                            color="blue"
                            text="Add Folder"
                            onClick={() =>
                              this.handleOpenModal(navItem.folderType)
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            ) : (
              ''
            );
          })}
        </ul>
        <Modal
          title="Add Folder"
          titleClassName="greenHeader"
          isOpen={openModal}
          onRequestClose={() => this.handleCloseModal()}
          submitActions={() => this.handleAddFolder()}
          submitButtonColor="green"
          disableSubmit={folderName.length < 3}
        >
          <Textbox
            fieldLabel="Folder Name"
            handleChange={e => this.setState({ folderName: e.target.value })}
            isRequired={true}
            currentValue={folderName}
          />
        </Modal>
      </div>
    );
  }
}

export default LeftNavSection;
