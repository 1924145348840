import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import ReactLoading from 'react-loading';
import moment from 'moment';

import { getLoggedInUser } from '../../selectors/users';
import {
  getCompanyActiveEmployeesSelector,
  getIsDownloadQrLoadingSelector,
  getPersonnelLoadingSelector,
  getPersonnelUploadErrorSelector
} from '../../selectors/personnel';
import { isValid } from 'redux-form';
import { getAccountInfo } from '../../selectors/payment';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  getActiveCompany,
  getActiveProject,
  getCompanyLoadingSelector,
  getUserCompaniesSelector
} from '../../selectors/company';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  addLocationEmployeeRequest,
  downloadEmployeeQRcodeRequest,
  importEmployeeRequest,
  setActiveEmployeeRequest,
  uploadErrorResponse
} from '../../actions/personnel';
import history from '../../history';
import {
  COMBINED_EMPLOYEE_STATUS,
  EMPLOYEE_BULK_UPLOAD_TEMPLATE
} from '../../constants/constants';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterAllDropdown,
  filterEmployeeType,
  filterHireDate,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import Button from '../inputs/Button';
import Checkbox from '../inputs/Checkbox';
import CSVUploader from '../../components/CSVUploader';
import EmployeeForm from '../../forms/EmployeeForm';
import Modal from '../Modal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import { exportEmployeesToCSV } from '../../api';
import Card from '../Card';
import HeaderAndFooter from '../HeaderAndFooter';
import Header from '../Header';
import { InputRow } from '../inputs';

import 'react-table/react-table.css';

export class EmployeeList extends Component {
  state = {
    modalOpen: 0,
    downloadQRCodes: [],
    filters: []
  };

  componentDidMount() {
    if (localStorage.getItem('empFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('empFilters'))
      });
    }
    this.props.updateLeftNav('Employees');
  }

  handleOpenModal(modalId) {
    this.setState({
      modalOpen: modalId
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: 0 });
  }

  exportEmployeesToCSV = async () => {
    const src = await exportEmployeesToCSV();
    window.location = src;
  };

  handleDownloadChange = id => {
    this.setState(state => {
      const { downloadQRCodes } = state;
      let removed = false;

      const newCodes = downloadQRCodes.filter(code => {
        const match = code === id;
        if (match) removed = true;
        return !match;
      });

      if (!removed) newCodes.push(id);
      return { downloadQRCodes: newCodes };
    });
  };

  submitDownload = () => {
    this.props.downloadQRCodes({
      ids: this.state.downloadQRCodes
    });

    this.setState({ downloadQRCodes: [] });
  };

  trackImportEmployee = async user => {};

  handleImport = () => {
    const { loggedInUser, importEmployees } = this.props;

    if (
      (process.env.REACT_APP_STAGE === 'production' &&
        (loggedInUser.username.indexOf('@ireportsource.com') > -1 ||
          loggedInUser.username.indexOf('@demo.com') > -1)) ||
      process.env.REACT_APP_STAGE !== 'production'
    ) {
      importEmployees();
      this.handleCloseModal();
    } else {
      importEmployees();
      this.trackImportEmployee(loggedInUser);
      this.handleCloseModal();
    }
  };

  handleAddEmployeeSubmit = values => {
    this.props.addLocationEmployee(values);
    this.handleCloseModal();
  };

  handleFilterChange = filters => {
    localStorage.setItem('empFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  render() {
    const {
      employees,
      setActiveEmployee,
      activeCompany,
      addAttachment,
      isDownloadQrLoading,
      userCompanies,
      loggedInUser,
      employeeValid,
      accountInfo,
      employeeError,
      uploadError,
      attachmentLoading
    } = this.props;

    const { modalOpen, downloadQRCodes, filters } = this.state;
    let messages = employeeError.message;
    if (!messages) {
      messages = [];
    } else if (!Array.isArray(messages)) {
      messages = [messages];
    }

    const getStatus = status => {
      if (!status) return '';
      status = parseInt(status, 10);

      if (status > 19 || status <= 0) {
        status = 1;
      }

      return (
        COMBINED_EMPLOYEE_STATUS.find(s => s.value === status)?.label ?? ''
      );
    };

    const getUserAccess = accessLevel =>
      accessLevel === '900'
        ? 'Admin'
        : accessLevel === '500'
        ? 'Collaborator'
        : accessLevel === '400'
        ? 'Supervisor'
        : accessLevel === '100'
        ? 'Reporter'
        : '';

    const header = <Header title="Employees" />;

    return (
      <>
        <HeaderAndFooter Header={header}>
          <ScrollToTopOnMount />
          <Card wide>
            <InputRow right>
              <Button
                color="green"
                text="Add Employee"
                onClick={() => this.handleOpenModal(1)}
              />
              <Button
                color="green"
                text="Upload Employee Templates"
                onClick={() => this.handleOpenModal(2)}
              />
              <Button
                color="green"
                text="Export Employees To CSV"
                onClick={this.exportEmployeesToCSV}
              />
            </InputRow>
            <ReactTable
              data={employees}
              onFilteredChange={filtered => this.handleFilterChange(filtered)}
              filtered={filters}
              filterable
              resizable={false}
              defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
              defaultFilterMethod={(filter, row) =>
                defaultFilterMethod(filter, row)
              }
              columns={[
                {
                  Header: () =>
                    isDownloadQrLoading ? (
                      <ReactLoading
                        type="spinningBubbles"
                        color="#58a15b"
                        className="loading"
                      />
                    ) : (
                      <Button
                        className="blueButton"
                        text="Download QR"
                        onClick={() => this.submitDownload()}
                      />
                    ),
                  Cell: row => (
                    <Checkbox
                      name={row.original._id}
                      onChange={() =>
                        this.handleDownloadChange(row.original._id)
                      }
                      currentValue={
                        downloadQRCodes.find(d => d === row.original._id) !==
                        undefined
                      }
                    />
                  ),
                  filterable: false,
                  sortable: false
                },
                {
                  Header: 'FIRST NAME',
                  accessor: d => d.person.firstName,
                  id: 'firstName'
                },
                {
                  Header: 'LAST NAME',
                  accessor: d => d.person.lastName,
                  id: 'lastName'
                },
                {
                  Header: 'HIRE DATE',
                  accessor: 'dateOfHire',
                  Cell: row =>
                    `${moment(row.original.dateOfHire).format('MM/DD/YYYY')}`,
                  sortMethod: (a, b) => sortDateMethod(a, b),
                  filterMethod: (filter, row) => filterHireDate(filter, row),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'All Folders'}
                    >
                      <option value="all">Show All</option>
                      <option value="past7">Past 7 Days</option>
                      <option value="past30">Past 30 Days</option>
                      <option value="past60">Past 60 Days</option>
                      <option value="past90">Past 90 Days</option>
                      <option value="past120">More Than 90 Days</option>
                    </select>
                  )
                },
                {
                  Header: 'USER TYPE',
                  accessor: d =>
                    d.userAccount ? d.userAccount.accessLevel : '',
                  id: 'userType',
                  Cell: ({ original }) => (
                    <div>
                      {getUserAccess(
                        original.userAccount
                          ? original.userAccount.accessLevel
                          : ''
                      )}
                    </div>
                  ),
                  filterMethod: filterAllDropdown,
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'All Folders'}
                    >
                      <option value="all">Show All</option>
                      <option value="100">Reporter</option>
                      <option value="400">Supervisor</option>
                      <option value="500">Collaborator</option>
                      <option value="900">Admin</option>
                    </select>
                  ),
                  sortable: false
                },
                // {
                //   Header: 'DEACTIVATED',
                //   id: 'isDeactivated',
                //   accessor: d =>
                //     d.userAccount && d.userAccount.isDeactivated
                //       ? 'Deactivated'
                //       : 'Activated',
                //   Cell: ({ original }) => (
                //     <div>
                //       {original.userAccount &&
                //       original.userAccount.isDeactivated
                //         ? 'Deactivated'
                //         : 'Activated'}
                //     </div>
                //   ),
                //   filterMethod: (filter, row) =>
                //     filterEmployeeType(filter, row),
                //   Filter: ({ filter, onChange }) => (
                //     <select
                //       onChange={event => onChange(event.target.value)}
                //       style={{ width: '100%' }}
                //       value={filter ? filter.value : 'All Folders'}
                //     >
                //       <option value="all">Show All</option>
                //       <option value="Activated">Activated</option>
                //       <option value="Deactivated">Deactivated</option>
                //     </select>
                //   ),
                //   sortable: false
                // },
                {
                  Header: 'EMPLOYEE TYPE',
                  accessor: 'status',
                  Cell: row => `${getStatus(row.original.status)}`,
                  filterMethod: (filter, row) =>
                    filterEmployeeType(filter, row),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'All Folders'}
                    >
                      <option value="all">Show All</option>
                      <option value="contractor">Contractor</option>
                      <option value="employee">Employee</option>
                      <option value="other">Other</option>
                    </select>
                  ),
                  sortable: false
                },
                {
                  Header: 'VIEW/EDIT',
                  Cell: row => (
                    <Button
                      color="blue"
                      text="View/Edit"
                      onClick={() => {
                        setActiveEmployee({ ...row.original });
                        history.push('/app/viewEditPersonnel/Employee');
                      }}
                    />
                  ),
                  filterable: false,
                  sortable: false
                }
              ]}
              noDataText="No results found"
              defaultPageSize={10}
              className="-striped -highlight"
            />
          </Card>
        </HeaderAndFooter>
        {modalOpen === 1 && (
          <Modal
            title="Add New Employee"
            titleClassName="greenHeader"
            wide
            isOpen={modalOpen === 1}
            onRequestClose={() => this.handleCloseModal()}
            formName="EmployeeForm"
            submitButtonColor="green"
            disableSubmit={!employeeValid}
          >
            <EmployeeForm
              onRequestClose={() => this.handleCloseModal()}
              onSubmit={values => this.handleAddEmployeeSubmit({ ...values })}
              userCompanies={userCompanies}
              accessLevel={loggedInUser.accessLevel}
              accountInfo={accountInfo}
            />
          </Modal>
        )}
        <Modal
          title="Import Employee"
          titleClassName="blueHeader"
          isOpen={modalOpen === 2}
          className="modalSmall"
          onRequestClose={() => this.handleCloseModal()}
          submitButtonColor="blue"
          submitActions={() => this.handleImport()}
          disableSubmit={attachmentLoading}
        >
          <Button
            color="blue"
            onClick={() => {
              var win = window.open(EMPLOYEE_BULK_UPLOAD_TEMPLATE, '_blank');
              win.focus();
            }}
            text="Download CSV Template"
          />
          <CSVUploader
            addAttachment={addAttachment}
            activeCompanyId={activeCompany._id}
            documentType="EmployeesUpload"
            className="dropzone"
          />
        </Modal>
        <Modal
          title="Issues with Employee Upload"
          titleClassName="redHeader"
          isOpen={employeeError && employeeError.error}
          className="modalMedium"
          onRequestClose={() => uploadError()}
          hideCancelButton={true}
          showXButton={true}
        >
          <ul>
            {messages.map((issue, index) => (
              <li key={index}>{issue}</li>
            ))}
          </ul>
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  employees: getCompanyActiveEmployeesSelector(state),
  activeCompany: getActiveCompany(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  companyLoading: getCompanyLoadingSelector(state),
  accountInfo: getAccountInfo(state),
  loggedInUser: getLoggedInUser(state),
  activeProject: getActiveProject(state),
  userCompanies: getUserCompaniesSelector(state),
  isDownloadQrLoading: getIsDownloadQrLoadingSelector(state),
  employeeValid: isValid('EmployeeForm')(state),
  isPersonnelLoading: getPersonnelLoadingSelector(state),
  employeeError: getPersonnelUploadErrorSelector(state),
  attachmentLoading: getAttachmentLoadingSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  addLocationEmployee: values => dispatch(addLocationEmployeeRequest(values)),
  setActiveEmployee: employee => dispatch(setActiveEmployeeRequest(employee)),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  importEmployees: values => dispatch(importEmployeeRequest(values)),
  downloadQRCodes: values => dispatch(downloadEmployeeQRcodeRequest(values)),
  uploadError: () =>
    dispatch(uploadErrorResponse({ error: false, message: '' }))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
