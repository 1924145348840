import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { InputRow } from '../inputs';
import Button from '../inputs/Button';

import './index.css';

export class Modal extends Component {
  render() {
    const {
      title,
      titleClassName,
      isOpen,
      children,
      onRequestClose,
      submitActions,
      disableSubmit,
      submitButtonColor,
      submitButtonText,
      hideCancelButton,
      cancelButtonText,
      cancelButtonColor,
      hideButtons,
      cancelActions,
      dispatch,
      formName,
      wide
    } = this.props;

    const handleSubmit =
      submitActions ?? (formName ? () => dispatch(submit(formName)) : () => {});

    return (
      <div>
        <div className={isOpen ? 'modal newModalShow' : 'modal modalHide'}>
          <section className={wide ? 'modalBody modalWide' : 'modalBody'}>
            <h2 className={`modalBody-header ${titleClassName}`}>
              <span className="modalBody-header--text">{title}</span>
              <div onClick={onRequestClose} className="modalBody-header--X">
                <img
                  src={require('../../assets/images/modalX.png')}
                  alt="close the modal"
                />
              </div>
            </h2>
            <section
              className={
                hideButtons
                  ? 'modalBody-content--hideButtons'
                  : 'modalBody-content'
              }
            >
              {children}
            </section>
            {!hideButtons && (
              <footer className="modalBody-footer">
                <InputRow>
                  <Button
                    text={submitButtonText || 'Submit'}
                    color={submitButtonColor}
                    onClick={handleSubmit}
                    disabled={disableSubmit}
                  />
                  {!hideCancelButton && (
                    <Button
                      onClick={cancelActions ? cancelActions : onRequestClose}
                      text={cancelButtonText || 'Cancel'}
                      color={cancelButtonColor || 'white'}
                    />
                  )}
                </InputRow>
              </footer>
            )}
          </section>
        </div>
      </div>
    );
  }
}

export default connect()(Modal);
