import React from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

const format = string => {
  if (string) {
    return string
      .toString()
      .split(/(?=(?:\d{3})+(?:\.|$))/g)
      .join(',');
  }
  return 0;
};

const barData = data => [
  {
    name: 'FROI',
    'Medical Costs': format(data.FROICosts.medicalCosts),
    'Indemnity Costs': format(data.FROICosts.indemnityCosts),
    'Reserve Costs': format(data.FROICosts.reserveCosts),
    'Indirect Costs': format(data.FROICosts.indirectCosts),
    Total: format(data.FROICosts.total)
  },
  {
    name: 'Auto Accident',
    'Direct Costs': format(data.autoAccidentCosts.directCosts),
    'Indirect Costs': format(data.autoAccidentCosts.indirectCosts),
    'Costs Not Covered': format(data.autoAccidentCosts.costsNotCovered),
    'Total Loss': format(data.autoAccidentCosts.totalLoss),
    Total: format(data.autoAccidentCosts.total)
  },
  {
    name: 'Property Damage',
    'Medical Costs': format(data.propertyDamageCosts.medicalCosts),
    'Indemnity Costs': format(data.propertyDamageCosts.indemnityCosts),
    'Reserve Costs': format(data.propertyDamageCosts.reserveCosts),
    'Indirect Costs': format(data.propertyDamageCosts.indirectCosts),
    Total: format(data.propertyDamageCosts.total)
  },
  {
    name: 'Theft',
    'Direct Costs': format(data.theftCosts.directCosts),
    'Indirect Costs': format(data.theftCosts.indirectCosts),
    'Costs Not Covered': format(data.theftCosts.costsNotCovered),
    'Total Loss': format(data.theftCosts.totalLoss),
    Total: format(data.theftCosts.total)
  },
  {
    name: 'Third Party',
    'Medical Costs': format(data.thirdPartyCosts.medicalCosts),
    'Indemnity Costs': format(data.thirdPartyCosts.indemnityCosts),
    'Reserve Costs': format(data.thirdPartyCosts.reserveCosts),
    'Indirect Costs': format(data.thirdPartyCosts.indirectCosts),
    'Settlement Costs': format(data.thirdPartyCosts.settlementCosts),
    Total: format(data.thirdPartyCosts.total)
  },
  {
    name: 'DOT',
    'Direct Costs': format(data.DOTCosts.directCosts),
    'Indirect Costs': format(data.DOTCosts.indirectCosts),
    'Costs Not Covered': format(data.DOTCosts.costsNotCovered),
    'Total Loss': format(data.DOTCosts.totalLoss),
    Total: format(data.DOTCosts.total)
  },
  {
    name: 'Costs Avoided',
    'Behavior Observation Costs Avoided': format(
      data.behaviorObservationCostsAvoided
    ),
    'Near Miss Costs Avoided': format(data.nearMissCostsAvoided),
    'See Something Say Something Costs Avoided': format(
      data.SeeSomethingSaySomethingCostsAvoided
    ),
    'Site Inspection Costs Avoided': format(data.siteInspectionCostsAvoided),
    'Total Costs Avoided': format(data.totalCostsAvoided)
  }
];

const CustomizedAxisTick = ({ x, y }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text fill="#666" fontSize={12} />
    </g>
  );
};

const StackedBarChart = ({ height, width, data }) => (
  <BarChart
    width={width}
    height={height}
    data={data.FROICosts ? barData(data) : []}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" tick={<CustomizedAxisTick />} interval={0} />
    <YAxis tick={<CustomizedAxisTick />} interval={0} />
    <Tooltip />
    <Bar dataKey="Medical Costs" stackId="a" fill="#EC5049" />
    <Bar dataKey="Indemnity Costs" stackId="a" fill="#BC403A" />
    <Bar dataKey="Reserve Costs" stackId="a" fill="#A43833" />
    <Bar dataKey="Direct Costs" stackId="a" fill="#A43833" />
    <Bar dataKey="Indirect Costs" stackId="a" fill="#8D2F2B" />
    <Bar dataKey="Costs Not Covered" stackId="a" fill="#752724" />
    <Bar dataKey="Total Loss" stackId="a" fill="#5E1F1C" />
    <Bar dataKey="Settlement Costs" stackId="a" fill="#461715" />
    <Bar
      dataKey="Behavior Observation Costs Avoided"
      stackId="b"
      fill="#ADC991"
    />
    <Bar dataKey="Near Miss Costs Avoided" stackId="b" fill="#98B07F" />
    <Bar
      dataKey="See Something Say Something Costs Avoided"
      stackId="b"
      fill="#83976D"
    />
    <Bar dataKey="Site Inspection Costs Avoided" stackId="b" fill="#6D7E5B" />
  </BarChart>
);

export default StackedBarChart;
