import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateLeftNavRequest } from '../../actions/navigation';
import { getLoggedInUser } from '../../selectors/users';
import {
  createAnalyticsDashboard,
  getAnalyticsDashboard,
  updateAnalyticsDashboard,
  deleteAnalyticsDashboard
} from '../../api';
import history from '../../history';

import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import ViewEditList from '../../components/ViewEditList';

export class MyAnalytics extends Component {
  state = {
    dashboards: [],
    addingDashboard: false
  };

  componentDidMount = () => {
    this.props.updateLeftNav('My Analytics');
    getAnalyticsDashboard().then(data => {
      this.setState({ dashboards: data });
    });
  };

  addDashboard = name => {
    const dashboard = {
      name,
      period: {
        mode: 'year',
        frequency: 'monthly'
      }
    };
    createAnalyticsDashboard(dashboard).then(data => {
      this.setState((state, props) => {
        let newState = { ...state };
        newState.dashboards.push(data);
        newState.addingDashboard = false;
        return newState;
      });
    });
  };

  editDashboard = (dashboardId, name, next) => {
    updateAnalyticsDashboard({ dashboardId, payload: { name } }).then(data => {
      this.setState((state, props) => {
        let newState = { ...state };
        let dashboard = newState.dashboards.find(d => d._id === dashboardId);
        if (dashboard) dashboard.name = name;
        return newState;
      });
      next();
    });
  };

  deleteDashboard = id => {
    deleteAnalyticsDashboard(id).then(data => {
      this.setState((state, props) => {
        let newState = { ...state };
        newState.dashboards = newState.dashboards.filter(d => d._id !== id);
        return newState;
      });
    });
  };

  render() {
    const header = (
      <Header
        title="My Analytics"
        rightButtons={[
          {
            visible: !this.state.addingDashboard,
            text: 'Create Dashboard',
            type: 'button',
            color: 'blue',
            onClick: () => this.setState({ addingDashboard: true })
          },
          {
            visible: this.state.addingDashboard,
            text: 'Cancel',
            type: 'button',
            color: 'red',
            onClick: () => this.setState({ addingDashboard: false })
          }
        ]}
      />
    );

    return (
      <HeaderAndFooter Header={header}>
        <ViewEditList
          data={this.state.dashboards}
          onView={id => history.push(`/app/myAnalytics/dashboard/${id}`)}
          onDelete={this.deleteDashboard}
          inlineEdit
          submitEdit={this.editDashboard}
          submitAdd={this.addDashboard}
          showAdd={this.state.addingDashboard}
          cancelAdd={() => this.setState({ addingDashboard: false })}
          minLength={3}
        />
      </HeaderAndFooter>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAnalytics);
