import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import history from '../../history';
import ReactTable from 'react-table';
import {
  fetchCustomDocumentsRequest,
  setActiveCustomDocument,
  setActiveCustomDocumentTemplate
} from '../../actions/documents';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  getCustomDocumentsSelector,
  getCustomDocumentTemplatesSelector
} from '../../selectors/documents';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterHireDate,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import Button from '../../components/inputs/Button';
import Dropdown from '../../components/inputs/Dropdown';
import Modal from '../../components/Modal';
import Header from '../../components/Header';

import './index.css';

export class CustomDocumentList extends Component {
  state = {
    openCreateDocumentModal: false,
    selectedTemplate: ''
  };

  componentDidMount() {
    this.props.updateLeftNav('Custom Documents');
    this.props.fetchCustomTemplates();
  }

  handleCreateDocument = () => {
    const {
      templates,
      setActiveCustomDocument,
      setActiveCustomDocumentTemplate
    } = this.props;
    const { selectedTemplate } = this.state;

    const selected = templates.filter(
      template => template.label === selectedTemplate.label
    )[0];

    setActiveCustomDocument({});
    setActiveCustomDocumentTemplate(selected);
    history.push('/app/customDocumentContainer');
  };

  handleViewDocument = document => {
    const {
      templates,
      setActiveCustomDocument,
      setActiveCustomDocumentTemplate
    } = this.props;

    const selected = templates.filter(
      template => template.label === document.label
    )[0];

    setActiveCustomDocument(document);
    setActiveCustomDocumentTemplate(selected);
    history.push('/app/customDocumentContainer');
  };

  render() {
    const { templates, list } = this.props;
    const { openCreateDocumentModal, selectedTemplate } = this.state;

    const templateDropdown = templates.map(template => {
      return { value: template.label, label: template.label };
    });

    return (
      <div className="customDocumentList">
        <Header
          title="Custom Documents"
          rightButtons={{
            text: 'Create Documents',
            className: 'blueButton',
            onClick: () => this.setState({ openCreateDocumentModal: true })
          }}
        />
        <div className="customDocumentList-table">
          <ReactTable
            data={list}
            filterable
            resizable={false}
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            columns={[
              {
                Header: 'TITLE',
                accessor: d => d.label,
                id: 'label'
              },
              {
                Header: 'UPDATED DATE',
                accessor: 'updatedDate',
                Cell: row => `${moment(row.updatedAt).format('MM/DD/YYYY')}`,
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterHireDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="past7">Past 7 Days</option>
                    <option value="past30">Past 30 Days</option>
                    <option value="past60">Past 60 Days</option>
                    <option value="past90">Past 90 Days</option>
                    <option value="past120">More Than 90 Days</option>
                  </select>
                )
              },
              {
                Header: 'VIEW/EDIT',
                Cell: row => (
                  <Button
                    text="View/Edit"
                    onClick={() => this.handleViewDocument(row.original)}
                    className="blueButton customDocumentList-buttonWidth"
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
            noDataText="No results found"
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
        <Modal
          title="Create Custom Document"
          titleClassName="blueHeader"
          isOpen={openCreateDocumentModal}
          submitButtonColor="blue"
          submitButtonText="Create Document"
          onRequestClose={() =>
            this.setState({ openCreateDocumentModal: false })
          }
          submitActions={() => this.handleCreateDocument()}
          disableSubmit={!selectedTemplate}
        >
          <div className="customDocumentList-createModal">
            <Dropdown
              fieldLabel="Select Custom Document to add"
              options={templateDropdown}
              searchable={true}
              name="templateDD"
              handleChange={values =>
                this.setState({ selectedTemplate: values })
              }
              isRequired={true}
              currentValue={selectedTemplate}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  templates: getCustomDocumentTemplatesSelector(state),
  list: getCustomDocumentsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation)),
  fetchCustomTemplates: () => dispatch(fetchCustomDocumentsRequest()),
  setActiveCustomDocument: payload =>
    dispatch(setActiveCustomDocument(payload)),
  setActiveCustomDocumentTemplate: payload =>
    dispatch(setActiveCustomDocumentTemplate(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDocumentList);
