import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import moment from 'moment';
import history from '../../history';
import * as api from '../../api';
import { addUploadedAttachmentsRequest } from '../../actions/attachments';
import {
  addIncidentCommentsRequest,
  addIncidentNotesRequest,
  deleteIncidentRequest,
  emailIncidentAssigneeRequest,
  fetchIncidentAttachmentsResponse,
  recatagorizeReportsRequest,
  setActiveIncidentResponse,
  updateIncidentCommentsRequest,
  updateIncidentNotesRequest,
  updateIncidentRequest
} from '../../actions/incidents';
import { createReportResponse } from '../../actions/reports';
import { setActiveTaskRequest } from '../../actions/tasks';
import {
  ACTION_COMPONENTS,
  ACTION_COMPONENTS_PRINT,
  CLAIM_STAGES,
  CLOSURE_COMPONENTS,
  CLOSURE_COMPONENTS_PRINT,
  DOWNLOAD_REPORTS_OPTIONS,
  HAZARD_STAGES,
  INVESTIGATION_COMPONENTS,
  INVESTIGATION_COMPONENTS_PRINT,
  REACTIVE_KEYS
} from '../../constants/constants';
import { getActiveCompany } from '../../selectors/company';
import {
  getActiveIncidentSelector,
  getDiagramSelector,
  getIncidentAttachmentsSelector,
  getIncidentPhotoEvidenceSelector,
  getNonPhotoEvidenceDiagramSelector
} from '../../selectors/incidents';
import { getMessageSelector } from '../../selectors/messages';
import { getActiveOshaLogSelector } from '../../selectors/oshaLogs';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import { getUserInfo } from '../../utils/assignedToMapper';
import {
  getSelectedProjects,
  getUsersByEstablishmentCollabLevel
} from '../../utils/employeeDGPHelper';
import {
  getAvailableUsersForPermissions,
  getPercentages,
  isSectionComplete
} from '../../utils/incidentHelper';
import svgMapper from '../../utils/svgMapper';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import CheckboxGroup from '../../components/inputs/CheckboxGroup';
import Dropdown from '../../components/inputs/Dropdown';
import IncidentStages from '../../components/incidentComponents/IncidentStages';
import Modal from '../../components/Modal';
import Multiselect from '../../components/inputs/Multiselect';
import PermissionsDropdown from '../../components/inputs/Dropdown/permissionsDropdown';
import UserBadge from '../../components/UserBadge';
import ReportActions from '../../components/ReportActions';
import ReportComponentCard from '../../components/incidentComponents/ReportComponentCard';
import ReportSelector from '../../components/inputs/ReportSelector';
import Textbox from '../../components/inputs/Textbox';
import Assignee from '../../components/Assignee';
import Header from '../../components/Header';
import ProgressBar from '../../components/ProgressBar';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import StageSelector from '../../components/StageSelector';

import './index.css';

export class IncidentContainer extends Component {
  state = {
    downloadReportModalOpen: false,
    stage: '',
    openEmailAssigneeModal: false,
    emailMessage: '',
    openDeleteIncidentModal: false,
    openReassignIncidentOwnerModal: false,
    reassignUserId: undefined,
    openCreateTaskModal: false,
    reportStage: 0,
    openChangeGroupModal: false,
    locationId: '',
    projectId: '',
    openEditPermissionsModal: false,
    permissions: [],
    downloadReport: false,
    openRecategorizeModal: false,
    openReportTypeModal: false,
    downloadInvestigationReports: [],
    downloadActionReports: [],
    downloadClosureReports: [],
    reportOptions: []
  };

  componentDidMount() {
    const { match } = this.props;

    if (match.params.stage === 'action') {
      this.setState({ stage: 1 });
    } else if (match.params.stage === 'closure') {
      this.setState({ stage: 2 });
    } else {
      this.setState({ stage: '' });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  sendEmail = () => {
    this.props.emailIncidentAssignee({
      messageContent: this.state.emailMessage,
      incidentId: this.props.activeIncident._id,
      userId: this.props.loggedInUser._id,
      sentMessageToId: this.props.activeIncident.incidentOwner
    });

    this.setState({ openEmailAssigneeModal: false });
  };

  handleReportComponentClick = async (reportComponent, stage) => {
    await this.props.setActiveReportComponent({
      report: reportComponent,
      stage
    });

    if (reportComponent[0][0] === 'Photo Evidence') {
      history.push('/app/photoEvidenceContainer/true');
    } else if (reportComponent[0][0] === 'Diagrams') {
      history.push('/app/photoEvidenceContainer');
    } else {
      history.push(`/app/reportSectionContainer/${stage}`);
    }
  };

  flattenedAttachmentList = addedAttachments =>
    addedAttachments.reduce((acc, x) => acc.concat(x), []);

  handleReassignUser = () => {
    const { setActiveIncident, updateIncident, activeIncident } = this.props;
    const { reassignUserId } = this.state;

    setActiveIncident({ ...activeIncident, incidentOwner: reassignUserId });
    updateIncident({ dontLeavePage: true });

    this.setState({
      reassignUserId: undefined,
      openReassignIncidentOwnerModal: false
    });
  };

  submitIncidentName = async newName => {
    const { setActiveIncident, activeIncident } = this.props;
    await setActiveIncident({
      ...activeIncident,
      incidentNumber: newName,
      incidentNumberLegacy: activeIncident.incidentNumber
    });

    api.updateIncident(activeIncident._id, {
      ...activeIncident,
      incidentNumber: newName,
      incidentNumberLegacy: activeIncident.incidentNumber
    });
  };

  removeActionItem = async actionItem => {
    const {
      addedAttachments,
      addIncidentAttachments,
      loggedInUser,
      activeIncident,
      updateIncident,
      updateComment
    } = this.props;

    actionItem = {
      ...actionItem,
      isRemoved: true,
      isRemovedByOwner: loggedInUser._id === activeIncident.incidentOwner
    };

    if (actionItem.type === 'attachment') {
      let flatList = this.flattenedAttachmentList(addedAttachments);

      flatList = flatList.map(currentAttachment => {
        if (currentAttachment._id === actionItem._id) {
          return { ...actionItem };
        }

        return { ...currentAttachment };
      });

      await addIncidentAttachments(flatList);
      await updateIncident({ dontLeavePage: true });
    } else if (actionItem.type === 'comment') {
      await updateComment(actionItem);
    }
  };

  updateReportStage = stageObject => {
    const { activeIncident, setActiveIncident } = this.props;

    api.updateIncident(activeIncident._id, {
      ...activeIncident,
      reportStage: stageObject.reportStage
    });

    setActiveIncident({
      ...activeIncident,
      reportStage: stageObject.reportStage
    });

    this.setState(stageObject);
  };

  handleReportSelectionFromTask = async taskObject => {
    const reportComponents = this.props.activeIncident.reportComponents;

    const reportComponent = reportComponents.filter(
      report => report[0] === taskObject.reportComponentName
    );

    await this.props.setActiveReportComponent({ report: reportComponent });

    if (reportComponent[0][0] === 'Photo Evidence') {
      history.push('/app/photoEvidenceContainer/true');
    } else if (reportComponent[0][0] === 'Diagrams') {
      history.push('/app/photoEvidenceContainer');
    } else {
      history.push('/app/reportSectionContainer');
    }
  };

  addUsersToIncident = async () => {
    const { permissions } = this.state;
    const { setActiveIncident, activeIncident } = this.props;

    const perms = permissions.map(permission => {
      return {
        userId: permission.value,
        action: 'View',
        closure: 'View',
        investigation: 'View'
      };
    });

    await setActiveIncident({
      ...activeIncident,
      permissions: [...activeIncident.permissions, ...perms]
    });

    await this.setState({ permissions: [] });
  };

  removeUsersFromIncident = async user => {
    const { setActiveIncident, activeIncident } = this.props;

    const perms = activeIncident.permissions.filter(
      permission => permission.userId !== user.userId
    );

    await setActiveIncident({
      ...activeIncident,
      permissions: [...perms]
    });
  };

  handleUserPermissionUpdates = user => {
    const { setActiveIncident, activeIncident } = this.props;

    const userIndex = activeIncident.permissions.findIndex(
      perm => perm.userId === user.userId
    );

    let incident = { ...activeIncident };

    incident.permissions[userIndex] = {
      ...activeIncident.permissions[userIndex],
      ...user
    };

    setActiveIncident(incident);
  };

  handlePermissions = () => {
    this.props.updateIncident({ dontLeavePage: true });
    this.setState({ openEditPermissionsModal: false });
  };

  downloadReport = async () => {
    this.setState({ downloadReport: true, downloadReportModalOpen: false });

    const {
      downloadInvestigationReports,
      downloadActionReports,
      downloadClosureReports,
      reportOptions
    } = this.state;

    let reportsToDownload = [
      ...downloadInvestigationReports,
      ...downloadActionReports,
      ...downloadClosureReports
    ];
    let options = {};
    reportsToDownload.forEach(report => (options[report.value] = true));
    reportOptions.forEach(option => (options[option.value] = true));

    const url = await api.getIncidentSummary(
      this.props.activeIncident,
      options
    );

    url.map(report => window.open(report, '_blank'));
    this.closeDownloadModal();
  };

  closeDownloadModal = () => {
    this.setState({
      downloadReport: false,
      downloadReportModalOpen: false,
      downloadInvestigationReports: [],
      downloadActionReports: [],
      downloadClosureReports: [],
      reportOptions: []
    });
  };

  getCurrentNumber = (activeComponent, photoEvidence, diagrams, oshaForms) => {
    if (activeComponent[0][0] === 'Photo Evidence') {
      return photoEvidence.filter(photo => !photo.isRemoved).length;
    } else if (activeComponent[0][0] === 'Diagrams') {
      return diagrams.length;
    } else if (activeComponent[0][0] === 'Witness Statements') {
      const subSections = activeComponent[0][1].subSections;
      subSections.filter(section => isSectionComplete(section));
      return subSections.length;
    } else if (activeComponent[0][0] === 'OSHA Compliance') {
      return oshaForms.length;
    }

    return '';
  };

  render() {
    const {
      loggedInUser,
      activeIncident,
      deleteIncident,
      companyUsers,
      addComment,
      addNote,
      nonPhotoEvidenceDiagrams,
      addAttachment,
      activeCompany,
      photoEvidence,
      diagrams,
      oshaForms,
      addReports,
      setActiveTask
    } = this.props;

    if (!activeIncident?._id) return <div />;

    const {
      downloadReportModalOpen,
      stage,
      openEmailAssigneeModal,
      emailMessage,
      openDeleteIncidentModal,
      openReassignIncidentOwnerModal,
      reassignUserId,
      reportStage,
      openChangeGroupModal,
      locationId,
      projectId,
      openEditPermissionsModal,
      permissions,
      downloadReport,
      openRecategorizeModal,
      openReportTypeModal,
      downloadInvestigationReports,
      downloadActionReports,
      downloadClosureReports,
      reportOptions
    } = this.state;

    const permUsers = getAvailableUsersForPermissions(
      companyUsers,
      activeIncident
    );

    const percentages =
      activeIncident &&
      getPercentages(
        activeIncident.reportComponents,
        Number(stage),
        activeIncident.reportTemplateIds
      );

    const getReportStages =
      activeIncident && activeIncident.reportTemplateIds
        ? REACTIVE_KEYS.indexOf(
            parseInt(activeIncident.reportTemplateIds[0], 10)
          ) > -1
          ? CLAIM_STAGES.map((stage, index) => {
              return {
                value: index,
                label: stage
              };
            })
          : HAZARD_STAGES.map((stage, index) => {
              return {
                value: index,
                label: stage
              };
            })
        : [];

    const accessLevel = loggedInUser
      ? parseInt(loggedInUser.accessLevel, 10)
      : 0;

    const rStage = reportStage || activeIncident.reportStage;
    const center = (
      <StageSelector
        options={getReportStages}
        currentValue={rStage?.value ?? rStage}
        onChange={value => this.updateReportStage({ reportStage: value })}
        label={
          REACTIVE_KEYS.indexOf(
            parseInt(activeIncident.reportTemplateIds[0], 10)
          ) > -1
            ? 'Claim Stage'
            : 'Hazard Stage'
        }
      />
    );

    const right = (
      <Assignee
        text="Incident Owner"
        user={activeIncident?.incidentOwner}
        options={[
          {
            label: 'Email Incident Owner',
            onClick: () =>
              this.setState({
                openEmailAssigneeModal: true
              })
          },
          {
            label: 'Reassign Incident',
            visible:
              accessLevel === 900 ||
              activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () =>
              this.setState({
                openReassignIncidentOwnerModal: true
              })
          },
          {
            label: 'Assign Permissions',
            visible:
              accessLevel === 900 ||
              activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () =>
              this.setState({
                openEditPermissionsModal: true
              })
          },
          {
            label: 'Delete Incident',
            visible:
              accessLevel === 900 ||
              activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () => this.setState({ openDeleteIncidentModal: true })
          }
        ]}
      />
    );

    const header = (
      <Header
        clickBack={() => history.push('/app/dashboard')}
        title="Incident ID"
        section={activeIncident?.incidentNumber}
        editName={this.submitIncidentName}
        center={center}
        right={right}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header} className="incidentContainer-mainPage">
          <div className="incidentContainer-mainPage--left">
            <div className="incidentContainer-mainPage--categorization">
              <div className="incidentContainer-reportCategories">
                <div className="incidentContainer-reportCategories--reported">
                  <span>
                    Reported at{' '}
                    {moment(Date.parse(activeIncident.createdAt)).format(
                      'hh:mm A'
                    )}
                  </span>
                  <span style={{ fontWeight: 'bold' }}>
                    {activeIncident.createdAt &&
                      moment(Date.parse(activeIncident.createdAt)).format('LL')}
                  </span>
                </div>
                {activeIncident.reportTemplateIds.map((templateId, index) => (
                  <div key={index}>{svgMapper(templateId)}</div>
                ))}
              </div>
              {downloadReport ? (
                <ReactLoading
                  type="spinningBubbles"
                  color="#008284"
                  className="loading"
                />
              ) : (
                <Button
                  text="Download Reports"
                  color="blue"
                  onClick={() =>
                    this.setState({ downloadReportModalOpen: true })
                  }
                />
              )}
            </div>
            <div className="incidentContainer-incidentCard">
              <IncidentStages
                changeStage={stage => this.setState({ stage })}
                selectedStage={stage}
                activeIncident={activeIncident}
                loggedInUser={loggedInUser}
              />
              <div className="incidentContainer-incidentCard--content">
                <ProgressBar
                  scale={percentages.total}
                  complete={percentages.completed / percentages.total === 1}
                  bars={[
                    {
                      width: percentages.completed,
                      color: 'green'
                    },
                    {
                      width: percentages.started,
                      color: 'blue'
                    },
                    {
                      width: percentages.pastDue,
                      color: 'red'
                    }
                  ]}
                />
                {stage === '' ? (
                  <div className="incidentContainer-cardContainer">
                    <ReportComponentCard
                      reportComponent={['Initial Report', []]}
                      setActiveReportComponent={() =>
                        history.push('/app/initalReportContainer')
                      }
                      activeIncident={activeIncident}
                      setActiveTask={setActiveTask}
                      activeCompany={activeCompany}
                    />
                    {INVESTIGATION_COMPONENTS.map((component, index) => {
                      const activeComponent = activeIncident.reportComponents.filter(
                        report => report[0] === component
                      );
                      if (!activeComponent?.length) return <></>;
                      return (
                        <ReportComponentCard
                          key={index}
                          reportComponent={activeComponent[0]}
                          setActiveReportComponent={() =>
                            this.handleReportComponentClick(
                              activeComponent,
                              'investigation'
                            )
                          }
                          activeIncident={activeIncident}
                          loggedInUser={loggedInUser}
                          currentNumber={this.getCurrentNumber(
                            activeComponent,
                            photoEvidence,
                            diagrams,
                            oshaForms
                          )}
                          showNumbers={true}
                          stage="investigation"
                          setActiveTask={setActiveTask}
                          activeCompany={activeCompany}
                        />
                      );
                    })}
                  </div>
                ) : stage === 1 ? (
                  <div className="incidentContainer-cardContainer">
                    {ACTION_COMPONENTS.map((component, index) => {
                      const activeComponent = activeIncident.reportComponents.filter(
                        report => report[0] === component
                      );
                      if (!activeComponent?.length) return <></>;
                      return (
                        <ReportComponentCard
                          key={index}
                          reportComponent={activeComponent[0]}
                          setActiveReportComponent={() =>
                            this.handleReportComponentClick(
                              activeComponent,
                              'action'
                            )
                          }
                          currentNumber={this.getCurrentNumber(
                            activeComponent,
                            photoEvidence,
                            diagrams,
                            oshaForms
                          )}
                          activeIncident={activeIncident}
                          loggedInUser={loggedInUser}
                          showNumbers={true}
                          stage="action"
                          setActiveTask={setActiveTask}
                          activeCompany={activeCompany}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="incidentContainer-cardContainer">
                    {CLOSURE_COMPONENTS.map((component, index) => {
                      const activeComponent = activeIncident.reportComponents.filter(
                        report => report[0] === component
                      );
                      if (!activeComponent?.length) return <></>;
                      return (
                        <ReportComponentCard
                          key={index}
                          reportComponent={activeComponent[0]}
                          setActiveReportComponent={() =>
                            this.handleReportComponentClick(
                              activeComponent,
                              'closure'
                            )
                          }
                          activeIncident={activeIncident}
                          loggedInUser={loggedInUser}
                          showNumbers={true}
                          stage="closure"
                          setActiveTask={setActiveTask}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {/* <SummaryCard
              reportSectionHeader={
                Number(stage) === 0
                  ? 'Investigation Summary'
                  : Number(stage) === 1
                  ? 'Action Summary'
                  : 'Closure Summary'
              }
              activeIncident={activeIncident}
              stage={stage}
            /> */}
          </div>
          <div className="incidentContainer-mainPage--right">
            <ReportActions
              comments={activeIncident.incidentActivity.comments}
              notes={activeIncident.incidentActivity.notes}
              activityItems={activeIncident.incidentActivity.activityItems}
              tasks={activeIncident.incidentActivity.openTasks}
              attachments={nonPhotoEvidenceDiagrams}
              loggedInUser={loggedInUser}
              activeIncident={activeIncident}
              addNote={addNote}
              addComment={addComment}
              removeActionItem={actionItem => this.removeActionItem(actionItem)}
              addAttachment={addAttachment}
              handleReportSelection={task =>
                this.handleReportSelectionFromTask(task)
              }
              setActiveTask={setActiveTask}
              stage={stage}
            />
          </div>
        </HeaderAndFooter>
        <Modal
          title="Download Reports"
          titleClassName="blueHeader"
          isOpen={downloadReportModalOpen}
          submitButtonColor="blue"
          submitButtonText="Download"
          onRequestClose={this.closeDownloadModal}
          submitActions={() => this.downloadReport()}
        >
          <div className="incidentContainer-downloadReports">
            <div className="incidentContainer-downloadReports--components">
              <div className="incidentContainer-downloadReports--stages">
                <div>Investigation</div>
                <div>Action</div>
                <div>Closure</div>
              </div>
              <div className="incidentContainer-downloadReports--checkboxContainer">
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={INVESTIGATION_COMPONENTS_PRINT}
                    name="investigation"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadInvestigationReports}
                    handleChange={value =>
                      this.setState({ downloadInvestigationReports: value })
                    }
                  />
                </div>
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={ACTION_COMPONENTS_PRINT}
                    name="action"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadActionReports}
                    handleChange={value =>
                      this.setState({ downloadActionReports: value })
                    }
                  />
                </div>
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={CLOSURE_COMPONENTS_PRINT}
                    name="closure"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadClosureReports}
                    handleChange={value =>
                      this.setState({ downloadClosureReports: value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="incidentContainer-downloadReports--selectAll">
              <Button
                text="Select All"
                color="blue"
                inputClassName="incidentContainer-downloadReports--input"
                onClick={() =>
                  this.setState({
                    downloadInvestigationReports: INVESTIGATION_COMPONENTS_PRINT,
                    downloadActionReports: ACTION_COMPONENTS_PRINT,
                    downloadClosureReports: CLOSURE_COMPONENTS_PRINT
                  })
                }
              />
            </div>
            <div className="incidentContainer-downloadReports--options">
              <CheckboxGroup
                options={DOWNLOAD_REPORTS_OPTIONS}
                name="options"
                currentValue={reportOptions}
                className="incidentContainer-downloadReports--input"
                columns={true}
                handleChange={value => this.setState({ reportOptions: value })}
              />
            </div>
          </div>
        </Modal>
        <Modal
          title="Email Incident Owner"
          titleClassName="blueHeader"
          isOpen={openEmailAssigneeModal}
          submitButtonColor="blue"
          submitButtonText="Send Email"
          onRequestClose={() =>
            this.setState({ openEmailAssigneeModal: false })
          }
          submitActions={() => this.sendEmail()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            This email will go through the iReportSource system for record
            keeping purposes.
          </div>
          <Textbox
            fieldLabel="Email Content"
            rows={5}
            type="textarea"
            placeholder="Type email content here"
            currentValue={emailMessage}
            handleChange={e => this.setState({ emailMessage: e.target.value })}
          />
        </Modal>
        <Modal
          title="Change Incident Group or Est."
          titleClassName="blueHeader"
          isOpen={openChangeGroupModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() => this.setState({ openChangeGroupModal: false })}
          // submitActions={() => this.sendEmail()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            From here you can change what Group or Establsihment (and Project)
            an incident is filed under.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              When this is changed, the Incident Owner will also be changed to
              the default for that Group or Establishment. Which users have
              permissions on the incident will also change to the default for
              that Group or Establishment. Any Tasks assigned to users who no
              longer have access will be deleted.
            </span>
          </div>
          <Dropdown
            searchable={true}
            options={activeCompany.groups.map(group => {
              return {
                value: group._id,
                label: group.name
              };
            })}
            currentValue={locationId}
            handleChange={value =>
              this.setState({
                locationId: value.value
              })
            }
            fieldLabel="Group or Establishment"
          />
          <Dropdown
            searchable={true}
            options={
              locationId ? getSelectedProjects([locationId], [projectId]) : []
            }
            currentValue={projectId}
            handleChange={value =>
              this.setState({
                projectId: value.value
              })
            }
            fieldLabel="Project"
            disabled={!locationId}
          />
        </Modal>
        <Modal
          title={`Delete ${activeIncident.incidentNumber}`}
          titleClassName="redHeader"
          isOpen={openDeleteIncidentModal}
          submitButtonColor="red"
          submitButtonText="Delete Incident"
          onRequestClose={() =>
            this.setState({ openDeleteIncidentModal: false })
          }
          submitActions={async () => await deleteIncident(activeIncident)}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting this incident will delete all relavent data with the
              incident forever.{' '}
            </span>
            Are you sure?
          </div>
        </Modal>
        <Modal
          title="Reassign Incident Owner"
          titleClassName="blueHeader"
          isOpen={openReassignIncidentOwnerModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ openReassignIncidentOwnerModal: false })
          }
          submitActions={() => this.handleReassignUser()}
          disableSubmit={!reassignUserId}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            From here you can assign a different user as the owner of this
            incident.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              The previous incident owner will be removed, lose special
              privileges, and be replaced by the new incident owner.
            </span>{' '}
            Incident ownership can only by assigned to Collaborators or Admins
            with assess to the Establishment/Group of this incident.
          </div>
          <div className="incidentContainer-reassignIncident">
            <UserBadge
              size="large"
              userId={activeIncident?.incidentOwner}
              showImage
              hoverText
            />
            <img
              src={require('../../assets/images/reassignArrow.png')}
              className="reassignArrow"
              alt="assign arrow"
            />
            <UserBadge
              userId={reassignUserId}
              showImage
              hoverText
              size="large"
            />
          </div>
          <Dropdown
            options={getUsersByEstablishmentCollabLevel(
              companyUsers,
              activeIncident.locationId
            )}
            currentValue={reassignUserId}
            handleChange={value =>
              this.setState({ reassignUserId: value.value })
            }
          />
        </Modal>
        <Modal
          title="Edit Permissions"
          titleClassName="blueHeader"
          isOpen={openEditPermissionsModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ openEditPermissionsModal: false })
          }
          submitActions={() => this.handlePermissions()}
        >
          <div className="incidentContainer-editPermissions">
            <div className="incidentContainer-editPermissions--stages">
              <div>Users</div>
              <div>Investigation</div>
              <div>Action</div>
              <div>Closure</div>
            </div>
            <ul className="incidentContainer-editPermissions--userList">
              {activeIncident.permissions
                .filter(
                  user => getUserInfo(user.userId, companyUsers).firstName
                )
                .map((user, index) => (
                  <li key={index}>
                    <div className="userTable">
                      {parseInt(
                        getUserInfo(user.userId, companyUsers).accessLevel
                      ) !== 900 && (
                        <img
                          src={require('../../assets/images/removePermissions.png')}
                          className="removePermissions"
                          alt="Remove permissions from Incident"
                          onClick={() => this.removeUsersFromIncident(user)}
                        />
                      )}
                      <UserBadge
                        userId={user.userId}
                        showImage
                        showName
                        className="incidentContainer-editPermissions--user"
                      />
                    </div>
                    <div className="userTable">
                      <PermissionsDropdown
                        currentValue={user.investigation}
                        onChange={value =>
                          this.handleUserPermissionUpdates({
                            investigation: value,
                            userId: user.userId
                          })
                        }
                        accessLevel={
                          getUserInfo(user.userId, companyUsers).accessLevel
                        }
                        stage={0}
                      />
                    </div>
                    <div className="userTable">
                      <PermissionsDropdown
                        currentValue={user.action}
                        onChange={value =>
                          this.handleUserPermissionUpdates({
                            action: value,
                            userId: user.userId
                          })
                        }
                        accessLevel={
                          getUserInfo(user.userId, companyUsers).accessLevel
                        }
                        disabled={
                          parseInt(
                            getUserInfo(user.userId, companyUsers).accessLevel
                          ) === 400
                        }
                        stage={1}
                      />
                    </div>
                    <div className="userTable">
                      <PermissionsDropdown
                        currentValue={user.closure}
                        onChange={value =>
                          this.handleUserPermissionUpdates({
                            closure: value,
                            userId: user.userId
                          })
                        }
                        accessLevel={
                          getUserInfo(user.userId, companyUsers).accessLevel
                        }
                        stage={2}
                        disabled={
                          parseInt(
                            getUserInfo(user.userId, companyUsers).accessLevel
                          ) === 400
                        }
                      />
                    </div>
                  </li>
                ))}
            </ul>
            <InputRow>
              <Multiselect
                options={permUsers}
                currentValue={permissions}
                handleChange={values => this.setState({ permissions: values })}
                disabled={permUsers.length === 0}
                placeholder={
                  permUsers.length === 0
                    ? 'No Users Available'
                    : 'Select Users to add'
                }
                className="incidentContainer-editPermissions--grow"
              />
              <Button
                text="Add"
                color="blue"
                onClick={() => this.addUsersToIncident()}
              />
            </InputRow>
            <div className="reportSectionContainer-emailAssigneeText">
              Only Collaborators and Admins may be given full permissions.{' '}
              <span style={{ color: '#c74846', fontWeight: 'bold' }}>
                Admins can not be removed from any stage or the incident as a
                whole.
              </span>{' '}
              Supervisors can only be added to Investigation, and Reporters can
              not be added.{' '}
              <span style={{ color: '#c74846', fontWeight: 'bold' }}>
                Removing a user’s permission from all stages will completely
                remove them after saving changes.
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          title="Are you sure you want to add a report?"
          titleClassName="blueHeader"
          isOpen={openRecategorizeModal}
          submitButtonColor="blue"
          submitButtonText="Add Report"
          onRequestClose={() => this.setState({ openRecategorizeModal: false })}
          submitActions={async () =>
            (await addReports(this.state.reportTemplates)) &&
            this.setState({ openRecategorizeModal: false })
          }
        >
          <div className="reportSectionContainer-emailAssigneeText">
            You have added new report categories associated with this incident.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Saving these changes will change what questions appear on other
              reports within this incident and might mark completed report
              components as non-complete.{' '}
            </span>
            Are you sure?
          </div>
        </Modal>
        <Modal
          title="Type of Incident (one or more)"
          titleClassName="blueHeader"
          wide
          isOpen={openReportTypeModal}
          className="modalBig"
          submitButtonColor="blue"
          onRequestClose={() => this.setState({ openReportTypeModal: false })}
          submitActions={() =>
            this.setState({
              openReportTypeModal: false,
              openRecategorizeModal: true
            })
          }
        >
          <ReportSelector
            accessLevel={loggedInUser.accessLevel}
            handleReportSelection={values =>
              this.setState({ reportTemplates: values })
            }
            currentReports={activeIncident.reportTemplateIds}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  activeIncident: getActiveIncidentSelector(state),
  addedAttachments: getIncidentAttachmentsSelector(state),
  companyUsers: getCompanyUsersSelector(state),
  nonPhotoEvidenceDiagrams: getNonPhotoEvidenceDiagramSelector(state),
  activeCompany: getActiveCompany(state),
  photoEvidence: getIncidentPhotoEvidenceSelector(state),
  diagrams: getDiagramSelector(state),
  message: getMessageSelector(state),
  oshaForms: getActiveOshaLogSelector(state)
});

const mapDispatchToProps = dispatch => ({
  emailIncidentAssignee: payload =>
    dispatch(emailIncidentAssigneeRequest(payload)),
  setActiveReportComponent: payload => dispatch(createReportResponse(payload)),
  deleteIncident: payload => dispatch(deleteIncidentRequest(payload)),
  addComment: payload => dispatch(addIncidentCommentsRequest(payload)),
  addNote: payload => dispatch(addIncidentNotesRequest(payload)),
  updateIncident: payload => dispatch(updateIncidentRequest(payload)),
  setActiveIncident: payload => dispatch(setActiveIncidentResponse(payload)),
  addIncidentAttachments: payload =>
    dispatch(fetchIncidentAttachmentsResponse(payload)),
  updateComment: payload => dispatch(updateIncidentCommentsRequest(payload)),
  updateNote: payload => dispatch(updateIncidentNotesRequest(payload)),
  addAttachment: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  setActiveTask: payload => dispatch(setActiveTaskRequest(payload)),
  addReports: payload => dispatch(recatagorizeReportsRequest(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(IncidentContainer)
);
