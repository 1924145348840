import React, { Component } from 'react';

import { classnames } from '../../utils/classNamesHelper';

import styles from './hoverImage.module.scss';

export default class HoverImage extends Component {
  render() {
    let {
      className,
      alt,
      src,
      srcHover,
      selected,
      srcSelected,
      srcHoverSelected,
      onClick,
      ...props
    } = this.props;
    src = src || srcSelected;
    srcHover = srcHover || srcHoverSelected;
    srcSelected = srcSelected || src;
    srcHoverSelected = srcHoverSelected || srcHover;

    return (
      <div
        className={classnames(className, styles.container, onClick && styles.onClick)}
        onClick={onClick}
        {...props}
      >
        <img className={styles.hoverOff} src={selected ? srcSelected : src} alt={alt} />
        <img className={styles.hoverOn} src={selected ? srcHoverSelected : srcHover} alt={alt} />
      </div>
    );
  }
}
