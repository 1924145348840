const creditCardMapper = type => {
  switch (type) {
    case 'Visa':
      return require(`../assets/images/visa.png`);
    case 'MasterCard':
      return require(`../assets/images/mastercard.png`);
    case 'American Express':
      return require(`../assets/images/american.png`);
    case 'Discover':
      return require(`../assets/images/curved.png`);
    default:
      return require(`../assets/images/default.png`);
  }
};

export default creditCardMapper;
