import React, { useState } from 'react';
import { classnames } from '../../../utils/classNamesHelper';
import Button from '../../inputs/Button';
import Checkbox from '../../inputs/Checkbox';
import HoverImage from '../../HoverImage';
import { InputRow } from '../../inputs';
import Modal from '../../Modal';
import Textbox from '../../inputs/Textbox';

import customQuizCardStyle from './styles.module.scss';

export default function CustomQuizQuestionCard(props) {
  const [dragIdx, setDragIdx] = useState(null);
  const [dragTargetIdx, setDragTargetIdx] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);

  const addAnswerToRow = index => {
    const newQuestions = [...props.questions];
    newQuestions[index].answerOptions = [
      ...newQuestions[index].answerOptions,
      { answer: '', isCorrectAnswer: false }
    ];

    props.updateQuestions(newQuestions);
  };

  const updateAnswerRow = ({
    answer,
    isCorrectAnswer,
    questionIndex,
    answerIndex
  }) => {
    const newQuestions = [...props.questions];
    newQuestions[questionIndex].answerOptions[answerIndex] = {
      answer,
      isCorrectAnswer
    };

    props.updateQuestions(newQuestions);
  };

  const removeAnswerRow = (index, answerIndex) => {
    const newQuestions = [...props.questions];
    newQuestions[index].answerOptions.splice(answerIndex, 1);

    props.updateQuestions(newQuestions);
  };

  const addRowsToQuestions = number => {
    const newQuestions = [...props.questions];
    for (let i = 1; i <= number; i++) {
      newQuestions.push({
        label: '',
        answerOptions: []
      });
    }
    props.updateQuestions(newQuestions);
  };

  const removeRowsFromQuestions = index => {
    const newQuestions = [...props.questions];
    newQuestions.splice(index, 1);

    props.updateQuestions(newQuestions);
  };

  const onDragStart = (e, index) => {
    setDragIdx(index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/plain', index);
  };

  const onDragOver = (e, index) => {
    if (e.preventDefault) e.preventDefault();
    setDragTargetIdx(index);
  };

  const onDragLeave = (e, index) => {
    setDragTargetIdx(null);
  };

  const onDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (dragIdx === dragTargetIdx || dragTargetIdx === null) {
      setDragIdx(null);
      setDragTargetIdx(null);
      return;
    }

    let tempQuestions = [...props.questions];
    const tmp = tempQuestions[dragIdx];
    tempQuestions[this.state.dragIdx] = tempQuestions[dragTargetIdx];
    tempQuestions[dragTargetIdx] = tmp;

    props.updateQuestions(tempQuestions);
    setDragIdx(null);
    setDragTargetIdx(null);
  };

  const footer = !props.disabled && (
    <InputRow className={customQuizCardStyle.footer}>
      <Button
        text="Add Row"
        color="blue"
        onClick={() => addRowsToQuestions(1)}
      />
      <Button
        text="Add Five Rows"
        color="blue"
        onClick={() => addRowsToQuestions(5)}
      />
    </InputRow>
  );

  return (
    <>
      <ul className={customQuizCardStyle.fields}>
        {props?.questions.map((row, index) => (
          <li
            key={index}
            onDragOver={event => onDragOver(event, index)}
            onDragLeave={event => onDragLeave(event, index)}
            onDrop={event => onDrop(event, index)}
          >
            <div
              className={classnames(
                customQuizCardStyle.item,
                props.disabled && customQuizCardStyle.disabled,
                dragTargetIdx === index &&
                  dragIdx !== index &&
                  customQuizCardStyle.target
              )}
              draggable={!props.disabled}
              onDragStart={event => onDragStart(event, index)}
            >
              <img
                src={require('../../../assets/images/drag.png')}
                alt="drag to new position"
                className={customQuizCardStyle.dragImage}
              />
              <div className={customQuizCardStyle.grow}>
                <Textbox
                  currentValue={row.label}
                  handleChange={e => {
                    const newQuestions = [...props.questions];
                    newQuestions[index].label = e.target.value;
                    props.updateQuestions(newQuestions);
                  }}
                  fieldLabel="Question"
                  placeholder="Type your question here (e.g. What is 2+2?)"
                  isRequired
                  disabled={props.disabled}
                  name={`question-${index}`}
                />
                <InputRow>
                  <Button
                    text={
                      row.answerOptions.length ? 'View Answers' : 'Add Answers'
                    }
                    color="blue"
                    onClick={() => {
                      setModalOpen(true);
                      setRowIndex(index);
                    }}
                  />
                </InputRow>
              </div>
              <HoverImage
                src={require('../../../assets/images/removePermissions.png')}
                srcHover={require('../../../assets/images/removePermissonsHover.png')}
                alt="remove row"
                onClick={() => removeRowsFromQuestions(index)}
                className={customQuizCardStyle.removeImage}
              />
            </div>
          </li>
        ))}
      </ul>
      {footer}
      <Modal
        title="Change Answers"
        titleClassName="blueHeader"
        isOpen={modalOpen}
        submitButtonColor="blue"
        submitButtonText="Save Changes"
        onRequestClose={() => {
          setModalOpen(false);
        }}
        submitActions={() => {
          setRowIndex(0);
          setModalOpen(false);
        }}
      >
        <div>
          <div className={customQuizCardStyle.options}>
            <div>Possible Answer(s)</div>
            <div>Mark Correct Answer(s)</div>
            <div></div>
          </div>
          {props?.questions[rowIndex]?.answerOptions.map((answer, index) => (
            <div className={customQuizCardStyle.options} key={index}>
              <Textbox
                currentValue={answer.answer}
                handleChange={e =>
                  updateAnswerRow({
                    answer: e.target.value,
                    isCorrectAnswer: answer.isCorrectAnswer,
                    questionIndex: rowIndex,
                    answerIndex: index
                  })
                }
                placeholder="Type your option here."
                className={customQuizCardStyle.noMargin}
                disabled={props.disabled}
              />
              <Checkbox
                name={index}
                currentValue={answer.isCorrectAnswer}
                onChange={value =>
                  updateAnswerRow({
                    answer: answer.answer,
                    isCorrectAnswer: value,
                    questionIndex: rowIndex,
                    answerIndex: index
                  })
                }
                className={customQuizCardStyle.noMargin}
                disabled={props.disabled}
              />
              <HoverImage
                className={customQuizCardStyle.removeImage}
                onClick={() => removeAnswerRow(rowIndex, index)}
                src={require('../../../assets/images/removePermissions.png')}
                srcHover={require('../../../assets/images/removePermissonsHover.png')}
              />
            </div>
          ))}
          <Button
            text="Add Answer"
            color="blue"
            onClick={() => addAnswerToRow(rowIndex)}
            disabled={props.disabled}
          />
        </div>
      </Modal>
    </>
  );
}
