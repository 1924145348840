import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <title />
    <path
      d="M40.8,64.2h56.4v-4.7H40.8V64.2z M40.8,21.9v4.7h56.4v-4.7H40.8z M40.8,45.4h56.4v-4.7H40.8V45.4z M40.8,83
                    h56.4v-4.7H40.8V83z M11.7,28.2L7,23.5l-3.8,4.1l8.5,8.4l15-14.9l-3.9-3.9L11.7,28.2z M11.7,65.8L7,61.1l-3.8,4.1l8.5,8.4l15-14.9
                    l-3.9-3.9L11.7,65.8z"
    />
  </svg>
);
