const dev = {
  apiUrl: 'http://localhost:3006',
  gaTrackingID: 'UA-130877160-1',
  featureToggles: {
    customDocuments: true,
    customInitialReports: true
  },
  showAircraftForAccount: [
    '5e5fd4a51ac597004ce26bcd',
    '5f2d641b551a93003b1aa865'
  ],
  name: 'development',
  auth: {
    clientId: 'ba25d550-b067-4ab1-8fed-d185a8a578d8',
    redirectUri: 'https://localhost:3000/useraccounts/microsoftlogin',
    authority:
      'https://login.microsoftonline.com/564e0f68-6a64-44aa-8727-af9510d7aa16',
    prompt: 'login'
  },
  logrocketKey: 'aorusa/ireport-react-app-local'
};

const test = {
  s3: {
    BUCKET: 'test.ireportsourcetest.com'
  },
  apiGateway: {
    REGION: 'YOUR_DEV_API_GATEWAY_REGION',
    URL: 'YOUR_DEV_API_GATEWAY_URL'
  },
  cognito: {
    REGION: 'YOUR_DEV_COGNITO_REGION',
    USER_POOL_ID: 'YOUR_DEV_COGNITO_USER_POOL_ID',
    APP_CLIENT_ID: 'YOUR_DEV_COGNITO_APP_CLIENT_ID',
    IDENTITY_POOL_ID: 'YOUR_DEV_IDENTITY_POOL_ID'
  },
  apiUrl:
    'http://ireportsource-server-testing-v4.us-east-2.elasticbeanstalk.com',
  gaTrackingID: 'UA-130877160-1',
  featureToggles: {
    customDocuments: true,
    customInitialReports: true
  },
  showAircraftForAccount: [
    '5e5fd4a51ac597004ce26bcd',
    '5f2d641b551a93003b1aa865'
  ],
  name: 'test',
  auth: {
    clientId: 'ba25d550-b067-4ab1-8fed-d185a8a578d8',
    redirectUri: 'https://stage.ireportapp.com/useraccounts/microsoftlogin',
    authority:
      'https://login.microsoftonline.com/564e0f68-6a64-44aa-8727-af9510d7aa16',
    prompt: 'login'
  },
  logrocketKey: 'aorusa/ireport-react-app-test'
};

const stage = {
  s3: {
    BUCKET: 'staging.ireportsourcetest.com'
  },
  apiGateway: {
    REGION: 'YOUR_DEV_API_GATEWAY_REGION',
    URL: 'YOUR_DEV_API_GATEWAY_URL'
  },
  cognito: {
    REGION: 'YOUR_DEV_COGNITO_REGION',
    USER_POOL_ID: 'YOUR_DEV_COGNITO_USER_POOL_ID',
    APP_CLIENT_ID: 'YOUR_DEV_COGNITO_APP_CLIENT_ID',
    IDENTITY_POOL_ID: 'YOUR_DEV_IDENTITY_POOL_ID'
  },
  apiUrl: 'https://ireportstageapi.com',
  gaTrackingID: 'UA-130877160-1',
  featureToggles: {
    customDocuments: true,
    customInitialReports: true
  },
  showAircraftForAccount: [
    '5e5fd4a51ac597004ce26bcd',
    '5f2d641b551a93003b1aa865'
  ],
  name: 'stage',
  auth: {
    clientId: 'ba25d550-b067-4ab1-8fed-d185a8a578d8',
    redirectUri: 'https://stage.ireportapp.com/useraccounts/microsoftlogin',
    authority:
      'https://login.microsoftonline.com/564e0f68-6a64-44aa-8727-af9510d7aa16',
    prompt: 'login'
  },
  logrocketKey: 'aorusa/ireport-react-app-stage'
};

const uat = {
  s3: {
    BUCKET: 'uat.ireportsourcetest.com'
  },
  apiGateway: {
    REGION: 'YOUR_DEV_API_GATEWAY_REGION',
    URL: 'YOUR_DEV_API_GATEWAY_URL'
  },
  cognito: {
    REGION: 'YOUR_DEV_COGNITO_REGION',
    USER_POOL_ID: 'YOUR_DEV_COGNITO_USER_POOL_ID',
    APP_CLIENT_ID: 'YOUR_DEV_COGNITO_APP_CLIENT_ID',
    IDENTITY_POOL_ID: 'YOUR_DEV_IDENTITY_POOL_ID'
  },
  apiUrl: 'https://uat-api.ireportapp.com',
  gaTrackingID: 'UA-130877160-1',
  featureToggles: {
    customDocuments: true,
    customInitialReports: true
  },
  showAircraftForAccount: [
    '5e5fd4a51ac597004ce26bcd',
    '5f2d641b551a93003b1aa865'
  ],
  name: 'uat',
  auth: {
    clientId: 'ireport-web-stage',
    redirectUri: 'https://stage.ireportapp.com/useraccounts/microsoftlogin',
    authority:
      'https://login.microsoftonline.com/272e36c7-955b-4fad-963f-95772cccab93',
    prompt: 'login'
  },
  logrocketKey: 'aorusa/ireport-react-app-uat'
};

const prod = {
  s3: {
    BUCKET: 'production.ireportsource.com'
  },
  apiGateway: {
    REGION: 'YOUR_PROD_API_GATEWAY_REGION',
    URL: 'YOUR_PROD_API_GATEWAY_URL'
  },
  cognito: {
    REGION: 'YOUR_PROD_COGNITO_REGION',
    USER_POOL_ID: 'YOUR_PROD_COGNITO_USER_POOL_ID',
    APP_CLIENT_ID: 'YOUR_PROD_COGNITO_APP_CLIENT_ID',
    IDENTITY_POOL_ID: 'YOUR_PROD_IDENTITY_POOL_ID'
  },
  apiUrl: 'https://ireportprodapi.com',
  gaTrackingID: 'UA-124808580-1',
  featureToggles: {
    customDocuments: true,
    customInitialReports: true
  },
  showAircraftForAccount: [
    '5e5fd4a51ac597004ce26bcd',
    '5f2d641b551a93003b1aa865'
  ],
  name: 'production',
  auth: {
    clientId: 'ireport-web-prod',
    redirectUri: 'https://www.ireportapp.com/useraccounts/microsoftlogin',
    authority:
      'https://login.microsoftonline.com/272e36c7-955b-4fad-963f-95772cccab93',
    prompt: 'login'
  },
  logrocketKey: 'aorusa/ireport-react-app'
};
// http://ireportapp.com
const config =
  process.env.REACT_APP_STAGE === 'production'
    ? prod
    : process.env.REACT_APP_STAGE === 'stage'
    ? stage
    : process.env.REACT_APP_STAGE === 'uat'
    ? uat
    : process.env.REACT_APP_STAGE === 'test'
    ? test
    : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
