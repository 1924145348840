import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import history from '../../history';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterMyDocStatus,
  filterPerformedDate,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import Button from '../inputs/Button';
import Modal from '../../components/Modal';

export default class EmployeeDocumentRepo extends Component {
  state = {
    openCreateModal: false,
    openDocumentTypeModal: false,
    docType: {}
  };

  componentDidMount() {
    if (localStorage.getItem('empFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('empFilters'))
      });
    }
  }

  handleFilterChange = filters => {
    localStorage.setItem('myDocsFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  handleView = original => {
    if (original.title) {
      this.props.setActiveTraining({ ...original, isEditing: false });
      history.push('/app/training/performApproveTraining');
    } else {
      this.props.setActiveMyDocument({ ...original });

      if (
        original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment'
      ) {
        history.push('/app/recurrenceContainer');
      } else if (original.label === 'Transitional Return to Work Program') {
        history.push('/app/transitionContainer');
      } else {
        history.push('/app/myDocumentContainer');
      }
    }
  };

  render() {
    const { content, setActiveMyDocument } = this.props;
    const { openCreateModal } = this.state;

    return (
      <div>
        <h2 className="documents-header">
          <div className="documents-buttonBox">
            <Button
              className="blueButton"
              text="Add Employee Document"
              onClick={() => {
                this.setState({ openCreateModal: true });
              }}
            />
          </div>
        </h2>
        <div className="documents-list">
          <ReactTable
            data={content}
            filterable
            resizable={false}
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            columns={[
              {
                Header: 'Title',
                accessor: d => d.label,
                id: 'label'
              },
              {
                Header: 'CREATED DATE',
                accessor: d => d.createdAt,
                id: 'createdAt',
                Cell: ({ original }) =>
                  moment(Date.parse(original.createdAt)).format(
                    'MM/DD/YYYY hh:mm a'
                  ),
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterPerformedDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Created Today</option>
                    <option value="last7">Created within Last Week</option>
                    <option value="last30">Created This Month</option>
                    <option value="thisQuarter">Created This Quarter</option>
                    <option value="thisYear">Created This Year</option>
                  </select>
                )
              },
              {
                Header: 'STATUS',
                id: 'status',
                Cell: ({ original }) => {
                  if (
                    original.label ===
                    'Recurrence, Relapse During Recovery & Continuing Treatment'
                  ) {
                    if (original.isApprovedBySupervisor) {
                      return 'Approved';
                    } else {
                      return 'Supervisor Review';
                    }
                  } else if (
                    original.label === 'Transitional Return to Work Program'
                  ) {
                    if (
                      original.isApprovedBySupervisor &&
                      original.isApprovedByHr
                    ) {
                      return 'Approved';
                    }
                    if (
                      original.isApprovedBySupervisor &&
                      !original.isApprovedByHr
                    ) {
                      return 'HR Review';
                    } else {
                      return 'Supervisor Review';
                    }
                  } else {
                    return '--';
                  }
                },
                filterMethod: (filter, row) => filterMyDocStatus(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="supReview">Supervisor Review</option>
                    <option value="hrReview">HR Review</option>
                    <option value="approved">Approved</option>
                  </select>
                ),
                sortable: false
              },
              {
                Header: 'VIEW/EDIT',
                Cell: ({ original }) => (
                  <Button
                    className="blueButton"
                    onClick={() => this.handleView(original)}
                    text=" View/Edit"
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
            noDataText="No results found"
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
        <Modal
          title="Choose Document Type"
          titleClassName="blueHeader"
          isOpen={openCreateModal}
          onRequestClose={() => this.setState({ openCreateModal: false })}
          hideButtons={true}
        >
          <div className="myDocumentList-reportSelection">
            <div
              className="myDocumentList-button"
              onClick={() =>
                setActiveMyDocument({ attachments: [] }) &&
                history.push('/app/myDocumentContainer')
              }
            >
              <img
                src={require('../../assets/images/Upload Image.png')}
                className="myDocumentList-uploadForms"
                alt="Select For Employee Document Upload"
              />
              <span>Upload Images</span>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
