import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import ReactLoading from 'react-loading';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  addDailyReportsRequest,
  addDocumentsToFolderRequest,
  addQualificationRequest,
  addSdsRequest,
  addToolboxTalksRequest,
  deleteDocumentFolderRequest,
  deleteQualificationAttachment,
  deleteQualificationRequest,
  fetchDailyReportsRequest,
  fetchDocumentFoldersRequest,
  fetchQualificationRequest,
  fetchQualificationTypeRequest,
  fetchSdsRequest,
  fetchToolboxTalksRequest,
  setActiveDailyReport,
  setActiveQualification,
  setActiveSds,
  setActiveToolboxTalks,
  updateDocumentFolderRequest,
  updateQualificationRequest
} from '../../actions/documents';
import { setActiveIncidentResponse } from '../../actions/incidents';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  fetchAllActiveEmployees,
  fetchAllActiveEmployeesActiveUsers
} from '../../actions/personnel';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getActiveFolderSelector,
  getActiveQualificationSelector,
  getCompanyDailyReportSelector,
  getCompanyQualificationsSelector,
  getCompanySdsSelector,
  getCompanyToolboxTalksSelector,
  getDocumentFoldersSelector,
  getDocumentLoadingSelector,
  getQualificationTypesSelector
} from '../../selectors/documents';
import { getMessageSelector } from '../../selectors/messages';
import { getCompanyActiveEmployeesSelector } from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import DailyReportList from '../../components/DailyReportList';
import QualificationList from '../../components/QualificationList';
import SafetyInstructionList from '../../components/SafetyInstructionList';
import SdsList from '../../components/SdsList';
import ToolboxTalksList from '../../components/ToolboxTalksList';

import './index.css';
import '../../components/ObjectList/index.css';

export class Documents extends Component {
  componentDidMount = () => {
    this.props.fetchQualificationTypes();
    this.props.fetchSds({ page: 1, limit: 10000 });
    this.props.fetchDailyReports({ page: 1, limit: 10000 });
    this.props.fetchToolboxTalks({ page: 1, limit: 10000 });
    this.props.fetchQualifications();
    this.props.activeIncidentResponse();
    this.props.clearAttachment();
    this.props.fetchAllActiveEmployees();
    this.props.fetchAllActiveEmployeesActiveUsers();
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }

    if (
      this.props.match.params.documentType &&
      prevProps.match.params.documentType !==
        this.props.match.params.documentType
    ) {
    }
  };

  render() {
    const {
      sds,
      match,
      updateLeftNav,
      addSds,
      setSds,
      activeCompany,
      addAttachment,
      clearAttachment,
      attachmentLoading,
      documentLoading,
      addDailyReport,
      activeEmployees,
      dailyReports,
      setDailyReport,
      toolboxTalks,
      addToolboxTalk,
      setToolboxTalk,
      loggedInUser,
      addedAttachments,
      deleteAttachment,
      qualifications,
      addQualification,
      updateQualification,
      activeQualification,
      setActiveQualifications,
      activeLocation,
      activeProject,
      qualificationTypes,
      deleteQualification,
      fetchDocumentFolders,
      activeFolder,
      documentFolders,
      updateDocumentFolder,
      deleteDocumentFolder,
      addDocumentToFolder,
      deleteQualificationAttachment
    } = this.props;
    
    if (match.params.documentType === 'safetyInstructions') return <SafetyInstructionList />;
    return (
      <div className="documents">
        {documentLoading ? (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        ) : (
          ''
        )}
        {match.params.documentType === 'dailyReports' ? (
          <DailyReportList
            content={dailyReports}
            updateLeftNav={updateLeftNav}
            activeEmployees={activeEmployees}
            addDailyReport={addDailyReport}
            setDailyReport={setDailyReport}
            activeCompanyId={activeCompany._id}
            addAttachment={addAttachment}
            clearAttachment={clearAttachment}
            loading={attachmentLoading}
            accessLevel={loggedInUser.accessLevel}
            locationList={activeCompany.groups}
            deleteAttachment={deleteAttachment}
            addedAttachments={addedAttachments}
            activeLocation={activeLocation}
            activeProject={activeProject}
            user={loggedInUser}
            fetchDocumentFolders={fetchDocumentFolders}
            activeFolder={activeFolder}
            documentFolders={documentFolders}
            updateDocumentFolder={updateDocumentFolder}
            deleteDocumentFolder={deleteDocumentFolder}
            addDocumentToFolder={addDocumentToFolder}
          />
        ) : match.params.documentType === 'toolboxTalks' ? (
          <ToolboxTalksList
            content={toolboxTalks}
            updateLeftNav={updateLeftNav}
            activeEmployees={activeEmployees}
            addToolboxTalk={addToolboxTalk}
            setToolboxTalk={setToolboxTalk}
            activeCompanyId={activeCompany._id}
            addAttachment={addAttachment}
            clearAttachment={clearAttachment}
            loading={attachmentLoading}
            locations={activeCompany.groups}
            accessLevel={loggedInUser.accessLevel}
            deleteAttachment={deleteAttachment}
            addedAttachments={addedAttachments}
            activeLocation={activeLocation}
            activeProject={activeProject}
            user={loggedInUser}
            activeFolder={activeFolder}
            documentFolders={documentFolders}
            updateDocumentFolder={updateDocumentFolder}
            deleteDocumentFolder={deleteDocumentFolder}
            fetchDocumentFolders={fetchDocumentFolders}
            addDocumentToFolder={addDocumentToFolder}
          />
        ) : match.params.documentType === 'sds' ? (
          <SdsList
            content={sds}
            updateLeftNav={updateLeftNav}
            addSds={addSds}
            setSds={setSds}
            activeCompanyId={activeCompany._id}
            addAttachment={addAttachment}
            clearAttachment={clearAttachment}
            loading={attachmentLoading}
            accessLevel={loggedInUser.accessLevel}
            locationList={activeCompany.groups}
            deleteAttachment={deleteAttachment}
            addedAttachments={addedAttachments}
            activeLocation={activeLocation}
            activeProject={activeProject}
            user={loggedInUser}
            fetchDocumentFolders={fetchDocumentFolders}
            activeFolder={activeFolder}
            documentFolders={documentFolders}
            updateDocumentFolder={updateDocumentFolder}
            deleteDocumentFolder={deleteDocumentFolder}
            addDocumentToFolder={addDocumentToFolder}
          />
        ) : (
          <QualificationList
            locationList={activeCompany.groups}
            content={qualifications}
            activeEmployees={activeEmployees}
            addQualification={addQualification}
            updateQualification={updateQualification}
            activeQualification={activeQualification}
            setActiveQualification={setActiveQualifications}
            updateLeftNav={updateLeftNav}
            activeLocation={activeLocation}
            activeProject={activeProject}
            qualificationTypes={qualificationTypes}
            activeCompany={activeCompany}
            deleteAttachment={deleteAttachment}
            deleteQualificationAttachment={deleteQualificationAttachment}
            addedAttachments={addedAttachments}
            addAttachment={addAttachment}
            activeAttachments={activeQualification.attachments}
            clearAttachment={clearAttachment}
            loading={attachmentLoading}
            deleteQualification={deleteQualification}
            modalOpen={this.props.match.params.qualificationType}
            accessLevel={loggedInUser.accessLevel}
            user={loggedInUser}
            fetchDocumentFolders={fetchDocumentFolders}
            activeFolder={activeFolder}
            documentFolders={documentFolders}
            updateDocumentFolder={updateDocumentFolder}
            deleteDocumentFolder={deleteDocumentFolder}
            addDocumentToFolder={addDocumentToFolder}
          />
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  sds: getCompanySdsSelector(state),
  activeCompany: getActiveCompany(state),
  message: getMessageSelector(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  documentLoading: getDocumentLoadingSelector(state),
  activeEmployees: getCompanyActiveEmployeesSelector(state),
  dailyReports: getCompanyDailyReportSelector(state),
  toolboxTalks: getCompanyToolboxTalksSelector(state),
  loggedInUser: getLoggedInUser(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  qualifications: getCompanyQualificationsSelector(state),
  activeQualification: getActiveQualificationSelector(state),
  activeProject: getActiveProject(state),
  activeLocation: getActiveLocationId(state),
  qualificationTypes: getQualificationTypesSelector(state),
  activeFolder: getActiveFolderSelector(state),
  documentFolders: getDocumentFoldersSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  fetchSds: paginationInfo => dispatch(fetchSdsRequest(paginationInfo)),
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  addSds: sds => dispatch(addSdsRequest(sds)),
  setSds: sds => dispatch(setActiveSds(sds)),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  activeIncidentResponse: () => dispatch(setActiveIncidentResponse({})),
  addDailyReport: report => dispatch(addDailyReportsRequest(report)),
  fetchDailyReports: paginationInfo =>
    dispatch(fetchDailyReportsRequest(paginationInfo)),
  setDailyReport: dailyReport => dispatch(setActiveDailyReport(dailyReport)),
  fetchToolboxTalks: paginationInfo =>
    dispatch(fetchToolboxTalksRequest(paginationInfo)),
  addToolboxTalk: toolboxTalk => dispatch(addToolboxTalksRequest(toolboxTalk)),
  setToolboxTalk: toolboxTalk => dispatch(setActiveToolboxTalks(toolboxTalk)),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  fetchQualifications: () =>
    dispatch(fetchQualificationRequest({ page: 1, limit: 10000 })),
  addQualification: values => dispatch(addQualificationRequest(values)),
  updateQualification: values => dispatch(updateQualificationRequest(values)),
  setActiveQualifications: values => dispatch(setActiveQualification(values)),
  fetchQualificationTypes: () => dispatch(fetchQualificationTypeRequest()),
  deleteQualification: values => dispatch(deleteQualificationRequest(values)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  updateDocumentFolder: values => dispatch(updateDocumentFolderRequest(values)),
  deleteDocumentFolder: values => dispatch(deleteDocumentFolderRequest(values)),
  addDocumentToFolder: values => dispatch(addDocumentsToFolderRequest(values)),
  fetchAllActiveEmployeesActiveUsers: () =>
    dispatch(fetchAllActiveEmployeesActiveUsers()),
  fetchAllActiveEmployees: () => dispatch(fetchAllActiveEmployees()),
  deleteQualificationAttachment: values =>
    dispatch(deleteQualificationAttachment(values))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(Documents)
);
