import React, { Component } from 'react';
import styles from './styles.module.scss';

class Widget extends Component {
  render() {
    const classes = [styles.widget].concat(this.props.className || []);
    return <div className={classes.join(' ')}>{this.props.children}</div>;
  }
}

export default Widget;
