import axios from 'axios';
import config from '../config/config';
import history from '../history';
import store from '../store';
import { START_LOADING, STOP_LOADING } from '../constants/actionTypes';

function setTokenHeader() {
  return localStorage.getItem('token');
}

function getQueryString(options) {
  return Object.keys(options)
    .reduce((acc, cur) => {
      const name = encodeURIComponent(cur);
      let val = options[cur];
      if (val !== undefined) {
        if (Array.isArray(val)) {
          val.forEach(v => acc.push(`${name}[]=${encodeURIComponent(v)}`));
        } else {
          acc.push(`${name}=${encodeURIComponent(val)}`);
        }
      }
      return acc;
    }, [])
    .join('&');
}

axios.default.withCredentials = true;

const v2Api = axios.create({
  baseURL: `${config.apiUrl}/v2`
});

const companyApi = axios.create({
  baseURL: `${config.apiUrl}/v2/companies`
});

const companyApiNoLoading = axios.create({
  baseURL: `${config.apiUrl}/v2/companies`
});

const v3Api = axios.create({
  baseURL: `${config.apiUrl}/v3`
});

const companyV3Api = axios.create({
  baseURL: `${config.apiUrl}/v3/companies`
});

const requestConfigNoLoading = config => {
  if (!config.headers.Authorization) {
    const token = setTokenHeader();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
};

const requestConfig = config => {
  store.dispatch({ type: START_LOADING });

  return requestConfigNoLoading(config);
};

const responseStop = response => {
  store.dispatch({ type: STOP_LOADING });

  return response.data;
};

const errorHandlerNoLoading = error => {
  if (!error.response) throw error;

  if (error.response.status === 500 || error.response.status === 418) {
    if (process.env.NODE_ENV === 'development') throw error.response.data;
    else history.push('/error');
  }

  if (error.response.status === 403) {
    // localStorage.clear();
    history.push('/login');
  }

  throw error.response.data;
};

const errorHandler = error => {
  store.dispatch({ type: STOP_LOADING });

  return errorHandlerNoLoading(error);
};

v2Api.interceptors.request.use(requestConfig, error => Promise.reject(error));
v2Api.interceptors.response.use(responseStop, errorHandler);

v3Api.interceptors.request.use(requestConfig, error => Promise.reject(error));
v3Api.interceptors.response.use(responseStop, errorHandler);

const addCompanyId = config => {
  let state = store.getState();
  let companyId = state.company?.activeCompany?._id || state.company?.list?.[0]?._id;

  config.url = `/${companyId || '1234'}${config.url}`;
  return requestConfig(config);
};

companyApi.interceptors.request.use(addCompanyId, error =>
  Promise.reject(error)
);
companyV3Api.interceptors.request.use(addCompanyId, error =>
  Promise.reject(error)
);
companyApi.interceptors.response.use(responseStop, errorHandler);
companyV3Api.interceptors.response.use(responseStop, errorHandler);

companyApiNoLoading.interceptors.request.use(
  config => {
    let state = store.getState();
    let companyId = state.company?.activeCompany?._id || state.company?.list?.[0]?._id;

    config.url = `/${companyId}${config.url}`;
    return requestConfigNoLoading(config);
  },
  error => Promise.reject(error)
);
companyApiNoLoading.interceptors.response.use(
  response => response.data,
  errorHandlerNoLoading
);

const v2ApiNoLoading = axios.create({
  baseURL: `${config.apiUrl}/v2`
});

const v3ApiNoLoading = axios.create({
  baseURL: `${config.apiUrl}/v3`
});

v2ApiNoLoading.interceptors.request.use(requestConfigNoLoading, error =>
  Promise.reject(error)
);
v2ApiNoLoading.interceptors.response.use(
  response => response.data,
  errorHandlerNoLoading
);

v3ApiNoLoading.interceptors.request.use(requestConfigNoLoading, error =>
  Promise.reject(error)
);
v3ApiNoLoading.interceptors.response.use(
  response => response.data,
  errorHandlerNoLoading
);

const companyApiGet = url =>
  companyApi
    .get(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiPost = (url, data) =>
  companyApi
    .post(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiPut = (url, data) =>
  companyApi
    .put(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiDelete = url =>
  companyApi
    .delete(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiNLGet = url =>
  companyApiNoLoading
    .get(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiNLPost = (url, data) =>
  companyApiNoLoading
    .post(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiNLPut = (url, data) =>
  companyApiNoLoading
    .put(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyV3ApiGet = url =>
  companyV3Api
    .get(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchUserAccount = payload =>
  v2Api
    .post(`/userAccounts/login`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const userMicrosoftLogin = payload =>
  v2Api
    .post(`/userAccounts/microsoftLogin`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const forgotPasswordReset = payload =>
  v2Api
    .post(`/userAccounts/auth/resetPassword`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const doesUsernameExist = payload =>
  v2ApiNoLoading
    .get(`/userAccounts/doesUsernameExist/${payload.candidateUsername}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const googleSignIn = googleUser => {
  const id_token = googleUser.getAuthResponse().id_token;
  return v2Api
    .post(`/userAccounts/googlesignin`, 'idtoken=' + id_token, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .then(response => response)
    .catch(err => {
      throw err;
    });
};

export const createNewGoogleUser = googleUser => {
  const id_token = googleUser.getAuthResponse().id_token;
  return v2Api
    .post(`/userAccounts/googlesignin`, 'idtoken=' + id_token, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .then(response => response)
    .catch(err => {
      throw err;
    });
};

export const createUserAccount = userAccount =>
  v2Api
    .post(`/userAccounts/`, userAccount)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createUserAccountFromEmployee = userAccount =>
  v2Api
    .post(`/userAccounts/createUserForEmployee`, userAccount)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateUserAccount = userAccount =>
  v2Api
    .put(`/userAccounts/${userAccount._id}`, userAccount)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updatePassword = (userId, values) =>
  v2Api
    .put(`/userAccounts/${userId}/updatePassword`, values)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateDefaultPassword = values =>
  v2Api
    .put(`/userAccounts/default/password`, values)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteUserAccount = userAccount =>
  v2Api
    .delete(`/userAccounts/${userAccount._id}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const validateSecurityQuestions = payload =>
  v2Api
    .post(`/userAccounts/auth/resetpassword/securityQuestions`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchCompanies = userAccountId =>
  v2Api
    .post(`/companies/?userAccountId=${userAccountId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addCompanyUser = (companyId, users) =>
  v2Api
    .post(`/userAccounts/addUserInCompany/${companyId || '1234'}`, users)
    .then(response => response)
    .catch(err => {
      throw err;
    });

//add userAccountId to body as array
export const addCompany = company =>
  v2Api
    .post(`/companies/`, company)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateCompany = (companyId, company) =>
  v2Api
    .put(`/companies/${companyId || '1234'}`, company)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteCompany = companyId =>
  v2Api
    .post(`/companies/${companyId || '1234'}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchIncidentsByCompany = (companyId, page, limit, removeClosed) =>
  v2Api
    .get(
      `/companies/${companyId || '1234'}/incidents/?companyId=${companyId ||
        '1234'}&page=${page}&limit=${limit}&removeClosed=${removeClosed}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchIncidentsByLocation = (
  companyId,
  locationId,
  page,
  limit,
  removeClosed
) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/incidents/?locationId=${locationId}&page=${page}&limit=${limit}&removeClosed=${removeClosed}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchIncidentsByProject = (
  companyId,
  projectId,
  page,
  limit,
  removeClosed
) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/incidents/?projectId=${projectId}&page=${page}&limit=${limit}&removeClosed=${removeClosed}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createIncident = (companyId, incident) => {
  return v2Api
    .post(`/companies/${companyId || '1234'}/incidents`, incident)
    .then(response => response)
    .catch(err => {
      throw err;
    });
};

export const fetchIncidentById = (companyId, incidentId) =>
  v2Api
    .get(`/companies/${companyId || '1234'}/incidents/${incidentId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchIncidentBasics = companyId =>
  v2Api
    .get(`/companies/${companyId || '1234'}/fieldgroups/?label=Incident Basics`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReportBasics = (companyId, reportTemplateLabel) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/fieldgroups/?label=${reportTemplateLabel}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateIncidentBasics = (incidentId, answers) =>
  companyApiPut(`/incidents/${incidentId}/basics`, { answers });

export const updateIncident = (incidentId, incident) =>
  companyApiPut(`/incidents/${incidentId}`, incident);

export const deleteIncident = incident =>
  v2Api
    .delete(
      `/companies/${incident.companyId || '1234'}/incidents/${incident._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReportTemplate = (companyId, reportType) =>
  v2Api
    .get(`/companies/${companyId || '1234'}/templates/?title=${reportType}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReportsByIncidentId = (companyId, incidentId) =>
  v2Api
    .get(`/companies/${companyId || '1234'}/reports/?incidentId=${incidentId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReportsByTemplateIdAndProjectId = (
  companyId,
  templateId,
  projectId,
  page,
  limit
) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/reports/?reportTemplateId=${templateId}&projectId=${projectId}&page=${page}&limit=${limit}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReportsByTemplateIdAndLocationId = (
  companyId,
  templateId,
  locationId,
  page,
  limit
) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/reports/?reportTemplateId=${templateId}&locationId=${locationId}&page=${page}&limit=${limit}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReportsByTemplateIdAndCompanyId = (
  companyId,
  templateId,
  page,
  limit
) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/reports/?reportTemplateId=${templateId}&companyId=${companyId ||
        '1234'}&page=${page}&limit=${limit}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReportsByTemplateId = (companyId, templateId) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/reports/?reportTemplateId=${templateId}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createReport = (companyId, report) => {
  return v2Api
    .post(`/companies/${companyId || '1234'}/reports/`, report)
    .then(response => response)
    .catch(err => {
      throw err;
    });
};

export const updateReport = (companyId, reportId, report) => {
  return v2Api
    .put(`/companies/${companyId || '1234'}/reports/${reportId}`, report)
    .then(response => response)
    .catch(err => {
      throw err;
    });
};

export const fetchTaskById = (companyId, taskId) =>
  v2Api
    .get(`/companies/${companyId || '1234'}/tasks/?_id=${taskId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchTasksByOwnerId = (ownerId, page) =>
  companyApiGet(`/tasks/?ownerId=${ownerId}&page=${page}&isMaster=false`);

export const fetchTasksByAssignedTo = (
  companyId,
  userId,
  page,
  limit,
  removeClosed
) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/tasks/?assignedTo=${userId}&page=${page}&limit=${limit}&removeClosed=${removeClosed}&isMaster=false`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchTasksByCreatedBy = (companyId, userId, page, limit) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/tasks/?createdBy=${userId}&page=${page}&limit=${limit}&isMaster=false`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchTasksByCompany = (companyId, page, limit) =>
  companyApi.get(
    `/tasks/?companyId=${companyId}&page=${page}&limit=${limit}&isMaster=false`
  );

export const fetchTasksByGroup = (groupId, page, limit) =>
  companyApi.get(
    `/tasks/?groupId=${groupId}&page=${page}&limit=${limit}&isMaster=false`
  );

export const fetchTasksByProject = (projectId, page, limit) =>
  companyApi.get(
    `/tasks/?projectId=${projectId}&page=${page}&limit=${limit}&isMaster=false`
  );

export const fetchRecurringTasks = companyId =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/tasks/?isMaster=true&companyId=${companyId || '1234'}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createTask = (companyId, task) =>
  v2Api
    .post(`/companies/${companyId || '1234'}/tasks`, task)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createReportComponentTask = (companyId, task) =>
  v2Api
    .post(`/companies/${companyId || '1234'}/tasks/reportComponent`, task)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateTask = (companyId, taskId, task) =>
  v2Api
    .put(`/companies/${companyId || '1234'}/tasks/${taskId}`, task)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteTask = (companyId, task) =>
  v2Api
    .delete(`/companies/${companyId || '1234'}/tasks/${task._id}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchAllEmployeesAllUsers = payload =>
  companyV3ApiGet(
    `/employees/getAllActiveEmployeesWithActiveUser/?${getQueryString(payload)}`
  );

export const fetchActiveEmployees = payload =>
  companyV3ApiGet(
    `/employees/getAllActiveEmployees/?${getQueryString(payload)}`
  );

export const addEmployee = (companyId, employee) =>
  v3Api
    .post(`/companies/${companyId || '1234'}/employees/`, employee)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateEmployee = (companyId, employee) =>
  v3Api
    .put(
      `/companies/${companyId || '1234'}/employees/${employee._id}`,
      employee
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteEmployee = employee =>
  v3Api
    .delete(
      `/companies/${employee.companyId || '1234'}/employees/${employee._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const resetEmployeesPassword = employee =>
  v3Api
    .put(
      `/companies/${employee.companyId || '1234'}/employees/${
        employee._id
      }/resetPassword`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const resendWelcomeNotification = employee =>
  v3Api
    .put(
      `/companies/${employee.companyId || '1234'}/employees/${
        employee._id
      }/resendWelcomeNotification`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchComments = (companyId, ownerId, ownerType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/comments/?ownerId=${ownerId}&ownerType=${ownerType}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addComment = (companyId, comment) =>
  v2Api
    .post(`/companies/${companyId || '1234'}/comments/`, comment)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateComment = (companyId, comment) =>
  v2Api
    .put(`/companies/${companyId || '1234'}/comments/${comment._id}`, comment)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteComment = (companyId, commentId) =>
  v2Api
    .delete(`/companies/${companyId || '1234'}/comments/${commentId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchNotes = (companyId, ownerId, ownerType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/notes/?ownerId=${ownerId}&ownerType=${ownerType}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addNote = (companyId, note) =>
  v2Api
    .post(`/companies/${companyId || '1234'}/notes/`, note)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateNote = (companyId, note) =>
  v2Api
    .put(`/companies/${companyId || '1234'}/notes/${note._id}`, note)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteNote = (companyId, noteId) =>
  v2Api
    .delete(`/companies/${companyId || '1234'}/notes/${noteId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const uploadAttachment = (attachment, options) =>
  companyApiPost(`/attachments/?${getQueryString(options)}`, attachment);

export const updateAttachment = (attachment, background = false) =>
  (background ? companyApiNLPut : companyApiPut)(
    `/attachments/${attachment._id}`,
    attachment
  );

export const fetchAttachmentByOwnerId = (companyId, ownerId, ownerType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/attachments/?ownerId=${ownerId}&ownerType=${ownerType}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchDocumentById = (companyId, docId) =>
  v2Api
    .get(`/companies/${companyId || '1234'}/documents/${docId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchDocumentsByType = (
  companyId,
  documentType,
  page,
  limit,
  folderId,
  employeeId
) =>
  v2Api
    .get(
      folderId
        ? `/companies/${companyId ||
            '1234'}/documents/?documentType=${documentType}&companyId=${companyId ||
            '1234'}&page=${page}&limit=${limit}&documentFolderId=${folderId}`
        : employeeId
        ? `/companies/${companyId ||
            '1234'}/documents/?documentType=${documentType}&companyId=${companyId ||
            '1234'}&page=${page}&limit=${limit}&employees=${employeeId}`
        : `/companies/${companyId ||
            '1234'}/documents/?documentType=${documentType}&companyId=${companyId ||
            '1234'}&page=${page}&limit=${limit}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchDocumentsByTypeAndGroup = (
  companyId,
  documentType,
  page,
  limit,
  locationId,
  folderId
) =>
  v2Api
    .get(
      folderId
        ? `/companies/${companyId ||
            '1234'}/documents/?documentType=${documentType}&groupIds=${locationId}&page=${page}&limit=${limit}&documentFolderId=${folderId}`
        : `/companies/${companyId ||
            '1234'}/documents/?documentType=${documentType}&groupIds=${locationId}&page=${page}&limit=${limit}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchDocumentsByTypeAndProject = (
  companyId,
  documentType,
  page,
  limit,
  projectId,
  folderId
) =>
  v2Api
    .get(
      folderId
        ? `/companies/${companyId ||
            '1234'}/documents/?documentType=${documentType}&projectIds=${projectId}&page=${page}&limit=${limit}&documentFolderId=${folderId}`
        : `/companies/${companyId ||
            '1234'}/documents/?documentType=${documentType}&projectIds=${projectId}&page=${page}&limit=${limit}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addDocument = (companyId, document) =>
  v2Api
    .post(`/companies/${companyId || '1234'}/documents/`, document)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateDocument = document =>
  v2Api
    .put(
      `/companies/${document.companyId || '1234'}/documents/${document._id}`,
      document
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteDocument = document =>
  v2Api
    .delete(
      `/companies/${document.companyId || '1234'}/documents/${document._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchDocumentFoldersByType = (companyId, documentType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/documentFolders/?documentType=${documentType}&companyId=${companyId ||
        '1234'}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addDocumentFolder = (companyId, documentFolder) =>
  v2Api
    .post(`/companies/${companyId || '1234'}/documentFolders/`, documentFolder)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateDocumentFolder = documentFolder =>
  v2Api
    .put(
      `/companies/${documentFolder.companyId || '1234'}/documentFolders/${
        documentFolder._id
      }`,
      documentFolder
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteDocumentFolder = documentFolder =>
  v2Api
    .delete(
      `/companies/${documentFolder.companyId || '1234'}/documentFolders/${
        documentFolder._id
      }`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchEmployeeSafetyTrainingByCompany = (employee, company) =>
  v3Api
    .get(
      `/companies/${company._id}/employees/${employee._id}/trainings/?companyId=${company._id}&isMaster=true`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });
export const fetchEmployeeSafetyTrainingByGroup = (employee, group) =>
  v3Api
    .get(
      `/companies/${group.companyId || '1234'}/employees/${
        employee._id
      }/trainings/?groupIds=${group._id}&isMaster=true`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchEmployeeSafetyTrainingByProject = (employee, project) =>
  v3Api
    .get(
      `/companies/${project.companyId || '1234'}/employees/${
        employee._id
      }/trainings/?projectIds=${project._id}&isMaster=true`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchEmployeeQualificationsByCompany = (employee, company) =>
  v3Api
    .get(
      `/companies/${company._id}/employees/${employee._id}/qualifications/?companyIds=${company._id}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchEmployeeQualificationsByGroup = (employee, group) =>
  v3Api
    .get(
      `/companies/${group.companyId || '1234'}/employees/${
        employee._id
      }/qualifications/?groupIds=${group._id}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchEmployeeQualificationsByProject = (employee, project) =>
  v3Api
    .get(
      `/companies/${project.companyId || '1234'}/employees/${
        employee._id
      }/qualifications/?projectIds=${project._id}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchEmployeeIncidentsByCompany = (employee, company) =>
  v3Api
    .get(
      `/companies/${company._id}/employees/${employee._id}/incidents/?companyId=${company._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchEmployeeIncidentsByGroup = (employee, group) =>
  v3Api
    .get(
      `/companies/${group.companyId || '1234'}/employees/${
        employee._id
      }/incidents/?locationId=${group._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchEmployeeIncidentsByProject = (employee, project) =>
  v3Api
    .get(
      `/companies/${project.companyId || '1234'}/employees/${
        employee._id
      }/incidents/?projectId=${project._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchAllUserJsas = (companyId, userId, page, limit) =>
  v2ApiNoLoading
    .get(
      `/companies/${companyId ||
        '1234'}/jsas/getAll/?assignedTo=${userId}&page=${page}&limit=${limit}&createdBy=${userId}&isMaster=false`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchJsaById = (companyId, jsaId) =>
  v2Api
    .get(`/companies/${companyId || '1234'}/jsas/${jsaId}`, {
      headers: { 'Cache-Control': 'no-cache' }
    })
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchJsasByCompanyId = (companyId, page, limit, removeClosed) =>
  v2Api
    .get(
      `/companies/${companyId || '1234'}/jsas/?companyId=${companyId ||
        '1234'}&page=${page}&limit=${limit}&removeClosed=${removeClosed}&isMaster=false`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchJsasByLocationId = (locationId, page, limit, removeClosed) =>
  v2Api
    .get(
      `/companies/${locationId}/jsas/?locationId=${locationId}&page=${page}&limit=${limit}&removeClosed=${removeClosed}&isMaster=false`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchJsasByProjectId = (projectId, page, limit, removeClosed) =>
  v2Api
    .get(
      `/companies/${projectId}/jsas/?projectId=${projectId}&page=${page}&limit=${limit}&removeClosed=${removeClosed}&isMaster=false`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchJsasByCompanyIdLean = companyId =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/jsas/meta/lean/?companyId=${companyId || '1234'}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchJsasByLocationIdLean = locationId =>
  v2Api
    .get(`/companies/${locationId}/jsas/meta/lean?locationId=${locationId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchJsasByProjectIdLean = projectId =>
  v2Api
    .get(`/companies/${projectId}/jsas/meta/lean?projectId=${projectId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchRecurringJsasByCompanyId = (
  companyId,
  page,
  limit,
  removeClosed
) =>
  v2Api
    .get(
      `/companies/${companyId || '1234'}/jsas/?companyId=${companyId ||
        '1234'}&page=${page}&limit=${limit}&removeClosed=${removeClosed}&isMaster=true`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchRecurringJsasByLocationId = (
  locationId,
  page,
  limit,
  removeClosed
) =>
  v2Api
    .get(
      `/companies/${locationId}/jsas/?locationId=${locationId}&page=${page}&limit=${limit}&removeClosed=${removeClosed}&isMaster=true`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchRecurringJsasByProjectId = (
  projectId,
  page,
  limit,
  removeClosed
) =>
  v2Api
    .get(
      `/companies/${projectId}/jsas/?projectId=${projectId}&page=${page}&limit=${limit}&removeClosed=${removeClosed}&isMaster=true`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const getJsaQuestions = (companyId, industryType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/jsaQuestionTemplate/industry/${industryType}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const getJsaQuestionsBySections = (companyId, section, industryType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/jsaQuestionTemplate/?sections=${section}&industry=${industryType}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createJsa = jsa =>
  v2Api
    .post(`/companies/${jsa.companyId || '1234'}/jsas`, jsa)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateJsa = jsa =>
  v2Api
    .put(`/companies/${jsa.companyId || '1234'}/jsas/${jsa._id}`, jsa)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteJsa = id => companyApiDelete(`/jsas/${id}`);

export const fetchRaCustomTemplates = companyId =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/jsaCustomTemplate/?companyId=${companyId || '1234'}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createRaCustomTemplate = template =>
  v2Api
    .post(
      `/companies/${template.companyId || '1234'}/jsaCustomTemplate`,
      template
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateRaCustomTemplate = template =>
  v2Api
    .put(
      `/companies/${template.companyId || '1234'}/jsaCustomTemplate/${
        template._id
      }`,
      template
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteRaCustomTemplate = template =>
  v2Api
    .delete(
      `/companies/${template.companyId || '1234'}/jsaCustomTemplate/${
        template._id
      }`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const getPermissions = (user, permissionType) =>
  v2Api
    .get(
      `/companies/${user._id}/permissions/?permissionType=${permissionType}&userId=${user._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addPermissions = permissions =>
  v2Api
    .post(`/companies/${permissions.userId}/permissions/`, permissions)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updatePermissions = (permissions, permissionId) =>
  v2Api
    .put(
      `/companies/${permissions.userId}/permissions/${permissionId}`,
      permissions
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const importLrr = upload =>
  v2Api
    .post(
      `/companies/${upload.companyId || '1234'}/lossRunReports/upload`,
      upload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteLrr = document =>
  v2Api
    .delete(`/companies/${document.companyId || '1234'}/lossRunReports`, {
      data: { fileUrl: document.fileUrl }
    })
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const importEmployees = upload =>
  v3Api
    .post(
      `/companies/${upload.companyId || '1234'}/employees/employeeUpload`,
      upload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteAttachment = id => companyApiDelete(`/attachments/${id}`);

export const searchItems = (companyId, searchTerms, searchType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/search/?searchTerm=${searchTerms}&searchType=${searchType}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchAccountInformation = companyId =>
  v2Api
    .get(`/companies/${companyId || '1234'}/payments/getAccountInformation`, {
      headers: { 'Cache-Control': 'no-cache' }
    })
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const chargeCustomer = (companyId, paymentCard) =>
  v2Api
    .post(`/companies/${companyId || '1234'}/payments/chargeCard`, paymentCard)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createCustomer = (companyId, customer) =>
  v2Api
    .post(
      `/companies/${companyId || '1234'}/payments/createNewPaymentInformation`,
      customer
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateCustomer = (companyId, paymentCard) =>
  v2Api
    .put(
      `/companies/${companyId || '1234'}/payments/updatePaymentInformation`,
      paymentCard
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchOshaForm = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId || '1234'}/oshaQuestions/?incidentId=${
        payload.incidentId
      }`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createOshaLog = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/oshaQuestions/`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateOshaLog = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/oshaQuestions/${payload._id}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchOshaLogsByCompany = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/?companyId=${payload.companyId || '1234'}&year=${
        payload.year
      }`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchOshaLogsByLocation = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/?companyId=${payload.companyId || '1234'}&locationId=${
        payload.locationId
      }&year=${payload.year}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createOshaCompanyForm = payload =>
  v2Api
    .post(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/?companyId=${payload.companyId || '1234'}&year=${
        payload.year
      }`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createOshaLocationForm = payload =>
  v2Api
    .post(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/?companyId=${payload.companyId || '1234'}&locationId=${
        payload.locationId
      }&year=${payload.year}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateOshaForm = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/oshaLogs/${payload.oshaId}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printCompanyOshaCsv = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/printCSV/?companyId=${payload.companyId ||
        '1234'}&year=${payload.year}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printLocationOshaCsv = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/printCSV/?companyId=${payload.companyId ||
        '1234'}&locationId=${payload.locationId}&year=${
        payload.year
      }&isLongTermEstablishment=${payload.isLongTermEstablishment}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printCompanyOsha300Excel = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/printExcel300/?companyId=${payload.companyId ||
        '1234'}&year=${payload.year}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printLocationOsha300Excel = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/printExcel300/?companyId=${payload.companyId ||
        '1234'}&locationId=${payload.locationId}&year=${
        payload.year
      }&isLongTermEstablishment=${payload.isLongTermEstablishment}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printCompanyOsha300AExcel = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/printExcel300A/?companyId=${payload.companyId ||
        '1234'}&year=${payload.year}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printLocationOsha300AExcel = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/printExcel300A/?companyId=${payload.companyId ||
        '1234'}&locationId=${payload.locationId}&year=${
        payload.year
      }&isLongTermEstablishment=${payload.isLongTermEstablishment}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printCompanyOsha301Excel = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/printExcel301/?companyId=${payload.companyId ||
        '1234'}&year=${payload.year}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printLocationOsha301Excel = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/printExcel301/?companyId=${payload.companyId ||
        '1234'}&locationId=${payload.locationId}&year=${
        payload.year
      }&isLongTermEstablishment=${payload.isLongTermEstablishment}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printSelectedOsha301Excel = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaLogs/singlePrintExcel301/?incidentId=${payload.incidentId}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchOshaFormByIncident = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaQuestions/?companyId=${payload.companyId ||
        '1234'}&incidentId=${payload.incidentId}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchCompanyCircleData = payload =>
  v2Api
    .get(
      payload.reportType
        ? `/companies/${payload.companyId ||
            '1234'}/analytics/circles/?companyId=${payload.companyId ||
            '1234'}&reportType=${payload.reportType}`
        : `/companies/${payload.companyId ||
            '1234'}/analytics/circles/?companyId=${payload.companyId ||
            '1234'}&path=${window.location.href}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchLocationCircleData = payload =>
  v2Api
    .get(
      payload.reportType
        ? `/companies/${payload.companyId ||
            '1234'}/analytics/circles/?companyId=${payload.companyId ||
            '1234'}&locationId=${payload.locationId}&reportType=${
            payload.reportType
          }`
        : `/companies/${payload.companyId ||
            '1234'}/analytics/circles/?companyId=${payload.companyId ||
            '1234'}&locationId=${payload.locationId}&path=${
            window.location.href
          }`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchProjectCircleData = payload =>
  v2Api
    .get(
      payload.reportType
        ? `/companies/${payload.companyId ||
            '1234'}/analytics/circles/?companyId=${payload.companyId ||
            '1234'}&projectId=${payload.projectId}&reportType=${
            payload.reportType
          }`
        : `/companies/${payload.companyId ||
            '1234'}/analytics/circles/?companyId=${payload.companyId ||
            '1234'}&projectId=${payload.projectId}&path=${window.location.href}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchTasksCircleData = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/analytics/getTasksCircles/?userId=${payload.userId}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createGroup = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/groups/`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateGroup = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/groups/${payload._id}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteGroup = payload =>
  v2Api
    .delete(`/companies/${payload.companyId || '1234'}/groups/${payload.id}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchAnalyticType = payload =>
  v2Api
    .get(
      payload.groupId
        ? `/companies/${payload.companyId || '1234'}/analytics/${
            payload.query
          }/?companyId=${payload.companyId || '1234'}&${payload.filter}=${
            payload.time
          }&groupId=${payload.groupId}`
        : `/companies/${payload.companyId || '1234'}/analytics/${
            payload.query
          }/?companyId=${payload.companyId || '1234'}&${payload.filter}=${
            payload.time
          }`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response.data)
    .catch(err => {
      throw err;
    });

export const fetchQrCodeSheet = payload =>
  v3Api
    .post(
      `/companies/${payload.companyId || '1234'}/employees/createQRCodeSheet/`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addGroupProject = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/projects/`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateGroupProject = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/projects/${payload._id}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteGroupProject = payload =>
  v2Api
    .delete(`/companies/${payload.companyId || '1234'}/projects/${payload._id}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchQualificationType = payload =>
  v2Api
    .get(
      `/companies/${payload}/documents/qualifications/getQualificationCategories`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const getIncidentSummary = (incident, options = {}) =>
  v2Api
    .get(
      `/companies/${incident.companyId || '1234'}/incidents/${
        incident._id
      }/printReport/?${getQueryString(options)}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });
export const addWebErrorLog = payload =>
  v2Api.post(`/companies/1234/error/webError`, payload);

export const emailIncidentAssignee = payload =>
  v2Api.post(`/userAccounts/${payload.sentMessageToId}/message`, payload);

export const getUserInteractions = payload =>
  v2Api.get(`/useraccounts/getSystemInteractions/${payload}`);

export const getInvestigationSummary = payload =>
  v2ApiNoLoading
    .get(
      `/companies/${payload.companyId || '1234'}/incidents/${
        payload._id
      }/printInvestigationReport`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const getActionSummary = payload =>
  v2ApiNoLoading
    .get(
      `/companies/${payload.companyId || '1234'}/incidents/${
        payload._id
      }/printActionReport`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const getClosureSummary = payload =>
  v2ApiNoLoading
    .get(
      `/companies/${payload.companyId || '1234'}/incidents/${
        payload._id
      }/printClosureReport`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printRA = payload =>
  v2Api
    .get(`/companies/${payload.companyId || '1234'}/jsas/${payload._id}/print`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createUserSecurityQuestions = payload =>
  v2Api
    .post(`/userAccounts/auth/create/securityQuestions`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchSafetyWalksByCompany = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/safetyWalks/?companyId=${payload.companyId || '1234'}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchSafetyWalksByGroup = groupId =>
  companyApiGet(`/safetyWalks/?groupId=${groupId}`);

export const fetchSafetyWalksByProject = projectId =>
  companyApiGet(`/safetyWalks/?projectId=${projectId}`);

export const fetchSafetyWalkById = id => companyApiGet(`/safetyWalks/${id}`);

export const createSafetyWalk = payload =>
  companyApiPost(`/safetyWalks`, payload);

export const updateSafetyWalk = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/safetyWalks/${payload._id}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteSafetyWalk = id => companyApiDelete(`/safetyWalks/${id}`);

export const printSafetyWalk = payload =>
  v2Api
    .get(`/companies/${payload.companyId}/safetyWalks/${payload._id}/print`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchContinuousCareByEmployeeId = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId || '1234'}/continuousCares/?employeeId=${
        payload.employeeId
      }`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchContinuousCareBySupervisorId = payload =>
  v2ApiNoLoading
    .get(
      `/companies/${payload.companyId ||
        '1234'}/continuousCares/?supervisorId=${
        payload.supervisorId
      }&isApprovedBySupervisor=${false}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchContinuousCareByHrRepId = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId || '1234'}/continuousCares/?hrRepId=${
        payload.hrRepId
      }&isApprovedByHr=${false}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createContinuousCare = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/continuousCares`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateContinuousCare = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/continuousCares/${
        payload._id
      }`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteContinuousCare = payload =>
  v2Api
    .delete(
      `/companies/${payload.companyId || '1234'}/continuousCares/${payload._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printContinuousCare = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId || '1234'}/continuousCares/${
        payload._id
      }/print`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchCustomReportQuestions = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/customReportQuestions/?reportTemplateNumber=${
        payload.reportTemplateNumber
      }&reportComponentName=${payload.reportComponentName}&companyId=${
        payload.companyId
      }`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createCustomReportQuestions = payload =>
  companyApiPost(`/customReportQuestions`, payload);
export const updateCustomReportQuestions = payload =>
  companyApiPut(`/customReportQuestions/${payload._id}`, payload);
export const deleteCustomReportQuestions = payload =>
  companyApiDelete(`/customReportQuestions/${payload._id}`);

export const fetchCustomDocumentTemplates = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId || '1234'}/customDocuments/?companyId=${
        payload.companyId
      }`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createCustomDocumentTemplates = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/customDocuments`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateCustomDocumentTemplates = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/customDocuments/${
        payload._id
      }`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteCustomDocumentTemplates = payload =>
  v2Api
    .delete(
      `/companies/${payload.companyId || '1234'}/customDocuments/${payload._id}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const printCustomDoc = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId || '1234'}/documents/${
        payload._id
      }/printCustomDoc`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchCustomTrainingTemplates = payload =>
  companyApiGet(`/trainingTemplates/?companyId=${payload.companyId}`);

export const addCustomTrainingTemplate = payload =>
  companyApiPost(`/trainingTemplates`, payload);

export const updateCustomTrainingTemplate = payload =>
  companyApiPut(`/trainingTemplates/${payload._id}`, payload);

export const deleteCustomTrainingTemplate = payload =>
  companyApiDelete(`/trainingTemplates/${payload._id}`);

export const fetchTrainings = payload =>
  companyApiGet(
    payload.documentFolderId
      ? `/trainings/?companyId=${payload.companyId}&documentFolderId=${payload.documentFolderId}&isMaster=true`
      : `/trainings/?companyId=${payload.companyId}&isMaster=true`
  );

export const fetchTrainingsByGroup = payload =>
  companyApiGet(
    payload.documentFolderId
      ? `/trainings/?groupIds=${payload.groupId}&documentFolderId=${payload.documentFolderId}&isMaster=true`
      : `/trainings/?groupIds=${payload.groupId}&isMaster=true`
  );

export const fetchTrainingsByProject = payload =>
  companyApiGet(
    payload.documentFolderId
      ? `/trainings/?projectIds=${payload.projectId}&documentFolderId=${payload.documentFolderId}&isMaster=true`
      : `/trainings/?projectIds=${payload.projectId}&isMaster=true`
  );

export const fetchTrainingsByMasterId = payload =>
  companyApiGet(
    `/trainings/?companyId=${payload.companyId}&isMaster=false&masterTemplateId=${payload._id}`
  );

export const fetchTrainingsByUser = payload =>
  companyApiGet(
    payload.isApproved
      ? `/trainings/approvedTrainingForEmployees/?companyId=${payload.companyId}&isMaster=false&assignedTo=${payload.userId}&approveReject=approve`
      : `/trainings/?companyId=${payload.companyId}&isMaster=false&assignedTo=${payload.userId}&isCompleted=${payload.isCompleted}`
  );

export const fetchTrainingForApprover = payload =>
  companyApiGet(
    `/trainings/trainingForApproval/?companyId=${payload.companyId}&approver=${payload.approverId}&isMaster=true&isCompleted=false`
  );

// export const exportAnalyticDetailsToCSV = payload =>
//   v2Api.post(
//     `/companies/${payload.companyId}/querybuilder/export/exportDetailedViewToCSV`
//   );

export const exportEmployeesToCSV = payload =>
  v3Api.post(`/companies/1234/employees/export/exportEmployeesToCSV`);

export const addTraining = payload => companyApiPost('/trainings', payload);

export const updateTraining = payload =>
  companyApiPut(`/trainings/${payload._id}`, payload);

export const deleteTraining = payload =>
  companyApiDelete(`/trainings/${payload._id}`);

export const printTrainingSummary = payload =>
  companyApiGet(`/trainings/${payload._id}/print`);

export const fetchAnalyticsDataSources = () =>
  companyApiGet(`/queryBuilder/dataSources`);

export const fetchAnalyticsDataPoints = dataSource =>
  companyApiNLGet(`/queryBuilder/${dataSource}/dataPoints`);

export const fetchAnalyticsDataPointValues = ({ dataSource, dataPoint }) =>
  companyApiNLGet(`/queryBuilder/${dataSource}/dataPoints/${dataPoint}`);

export const fetchAnalyticsPreview = payload =>
  companyApiNLPost(`/queryBuilder/preview`, payload);

export const fetchAnalyticsDetail = payload =>
  companyApiNLPost(`/queryBuilder/detailed`, payload);

export const getAnalyticsDashboard = (dashboardId = '') =>
  companyApiGet(`/queryBuilder/dashboards/${dashboardId}`);

export const createAnalyticsDashboard = payload =>
  companyApiPost(`/queryBuilder/dashboards`, payload);

export const updateAnalyticsDashboard = ({ dashboardId = '', payload }) =>
  companyApiPut(`/queryBuilder/dashboards/${dashboardId}`, payload);

export const deleteAnalyticsDashboard = dashboardId =>
  companyApiDelete(`/queryBuilder/dashboards/${dashboardId}`);

export const createAnalyticsChart = ({ dashboardId, payload }) =>
  companyApiPost(`/queryBuilder/charts/${dashboardId}`, payload);

export const exportAnalyticDetailsToCSV = payload =>
  companyApiPost(`/queryBuilder/export/exportAnalyticDetailsToCSV`, payload);

export const fetchAnalyticsChart = chartId =>
  companyApiGet(`/queryBuilder/charts/${chartId}`);

export const updateAnalyticsChart = ({ chartId, payload }) =>
  companyApiPut(`/queryBuilder/charts/${chartId}`, payload);

export const deleteAnalyticsChart = chartId =>
  companyApiDelete(`/queryBuilder/charts/${chartId}`);

export const loadDashboard = (options = {}) =>
  companyApiGet(`/user/dashboard/?${getQueryString(options)}`);

export const loadUser = userId =>
  companyApiNLGet(`/user/${userId}/badge`);

export const fetchQuizzes = payload =>
  companyApiGet(`/quizTemplates/?companyId=${payload}`);

export const fetchQuizById = payload =>
  companyApiGet(`/quizTemplates/${payload}`);

export const createQuiz = payload => companyApiPost(`/quizTemplates`, payload);

export const updateQuiz = payload =>
  companyApiPut(`/quizTemplates/${payload._id}`, payload);

export const deleteQuiz = payload =>
  companyApiDelete(`/quizTemplates/${payload._id}`);

export const submitQuiz = payload =>
  companyApiPut(`/trainings/submitQuiz/${payload._id}`, payload);

export const doesQuizHaveTitle = payload =>
  companyApiNLGet(`/quizTemplates/doesTitleExist/${payload}`);

export const doesTrainingHaveTitle = payload =>
  companyApiNLGet(`/trainings/doesTitleExist/${payload}`);
