import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import moment from 'moment';
import ReactLoading from 'react-loading';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterAttachments,
  filterPerformedDate,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import Button from '../inputs/Button';
import Checkbox from '../inputs/Checkbox';
import DailyReportForm from '../../forms/DailyReportForm';
import FileDownloader from '../FileDownloader';
import Dropdown from '../inputs/Dropdown';
import Modal from '../Modal';
import DeleteFolderModal from '../Modal/deleteFolderModal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Textbox from '../inputs/Textbox';

class DailyReportList extends Component {
  state = {
    modalOpen: false,
    editFolderName: false,
    folderName: '',
    openDeleteModal: false,
    addToFolder: [],
    addToFolderModal: false,
    documentFolderId: '',
    filters: []
  };

  componentDidMount() {
    if (localStorage.getItem('drFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('drFilters'))
      });
    }

    this.props.fetchDocumentFolders({
      documentType: 'DailyReports',
      companyId: this.props.activeCompanyId
    });
    this.props.updateLeftNav('Daily Reports');
  }

  handleOpenModal() {
    this.setState({
      modalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }

  handleSubmit = values => {
    this.props.addDailyReport(values);
    this.props.clearAttachment();
    this.handleCloseModal();
  };

  handleFolderUpdate = () => {
    this.props.updateDocumentFolder({
      ...this.props.activeFolder,
      name: this.state.folderName
    });

    this.setState({ editFolderName: '', folderName: '' });
  };

  handleFolderDelete = deleteAll => {
    this.props.deleteDocumentFolder({
      ...this.props.activeFolder,
      deleteAll,
      documents: this.props.content
    });

    this.setState({ openDeleteModal: false });
  };

  handleAddToFolder = id => {
    this.setState(state => {
      let { addToFolder } = state;

      const qrIndex = addToFolder.findIndex(i => i === id);

      if (qrIndex > -1) {
        addToFolder = addToFolder.filter(i => i !== id);
      } else {
        addToFolder = [...addToFolder, id];
      }

      return { addToFolder };
    });
  };

  handleAddMultipleToFolders = () => {
    const { content } = this.props;
    let addDocsToFolder = [];

    const addToFolder = this.state.addToFolder;

    content.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({ addToFolderModal: false, addToFolder: [] });
  };

  handleFilterChange = filters => {
    localStorage.setItem('drFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  render() {
    const {
      content,
      activeEmployees,
      setDailyReport,
      activeCompanyId,
      addAttachment,
      clearAttachment,
      loading,
      accessLevel,
      locationList,
      deleteAttachment,
      addedAttachments,
      activeLocation,
      activeProject,
      activeFolder,
      documentFolders,
      isAddingToFolder
    } = this.props;

    const {
      modalOpen,
      editFolderName,
      folderName,
      openDeleteModal,
      addToFolder,
      addToFolderModal,
      documentFolderId,
      filters
    } = this.state;

    const folderList = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    return (
      <div>
        <ScrollToTopOnMount />
        <h2 className="documents-header">
          Daily Reports
          <div className="documents-buttonBox">
            {accessLevel !== '100' && (
              <Button
                className="add"
                text="Add Daily Report"
                onClick={() => this.handleOpenModal()}
              />
            )}
          </div>
        </h2>
        {activeFolder.name && (
          <div>
            {editFolderName ? (
              <div className="documents-folderEditWrapper">
                <span>Old Folder Name: {activeFolder.name}</span>{' '}
                <div className="documents-folderEdit">
                  <Textbox
                    handleChange={e =>
                      this.setState({ folderName: e.target.value })
                    }
                    fieldLabel="New Folder Name"
                    currentValue={folderName}
                    className="documents-editFolderName"
                  />
                  <Button
                    className={
                      folderName.length < 3
                        ? 'disabledButton documents-editFolderButton'
                        : 'update documents-editFolderButton'
                    }
                    text="Submit Folder Name"
                    onClick={() => this.handleFolderUpdate()}
                    disabled={folderName.length < 3}
                  />
                  <Button
                    className="whiteButton documents-editFolderButton"
                    text="Cancel"
                    onClick={() => this.setState({ editFolderName: false })}
                  />
                </div>
              </div>
            ) : (
              <div
                className={
                  accessLevel !== '100'
                    ? 'documents-folderHeader'
                    : 'documents-folderHeader--reporter'
                }
              >
                <span>{activeFolder.name}</span>{' '}
                {accessLevel !== '100' && (
                  <img
                    src={require('../../assets/images/Pen.png')}
                    alt="edit folder name"
                    onClick={() => this.setState({ editFolderName: true })}
                    className="editIcon"
                  />
                )}
                {accessLevel !== '100' && (
                  <img
                    src={require('../../assets/images/actionClose.png')}
                    alt="delete folder name"
                    onClick={() => this.setState({ openDeleteModal: true })}
                    className="deleteIcon"
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className="documents-list">
          <ReactTable
            data={content}
            filterable
            onFilteredChange={filtered => this.handleFilterChange(filtered)}
            filtered={filters}
            resizable={false}
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            columns={[
              {
                Header: () =>
                  isAddingToFolder ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#58a15b"
                      className="loading"
                    />
                  ) : (
                    <Button
                      className="blueButton"
                      text="Add to Folder"
                      onClick={() => this.setState({ addToFolderModal: true })}
                    />
                  ),
                show: accessLevel !== '100',
                Cell: row => (
                  <Checkbox
                    name={row.original._id}
                    onChange={() => this.handleAddToFolder(row.original._id)}
                    currentValue={addToFolder.indexOf(row.original._id) !== -1}
                  />
                ),
                filterable: false,
                sortable: false
              },
              {
                Header: 'JOB NAME',
                accessor: d => d.documentData.jobName,
                id: 'title',
                Cell: ({ original }) => (
                  <div>{original.documentData.jobName}</div>
                )
              },
              {
                Header: 'PROJECT NO.',
                accessor: d => d.documentData.projectNo,
                id: 'projectNo',
                Cell: ({ original }) => (
                  <div>{original.documentData.projectNo}</div>
                )
              },
              {
                Header: 'DATE',
                accessor: 'expires',
                Cell: ({ original }) => (
                  <div>{moment(original.expires).format('MM/DD/YYYY')}</div>
                ),
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterPerformedDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Created Today</option>
                    <option value="last7">Created within last week</option>
                    <option value="last30">Created within last month</option>
                    <option value="moreThan30">
                      Created before last month
                    </option>
                  </select>
                )
              },
              {
                Header: 'ATTACHMENT(S)',
                accessor: 'attachments',
                Cell: ({ original }) => (
                  <div>
                    {original.attachments.map((attachment, attachmentIndex) => (
                      <FileDownloader
                        attachment={attachment}
                        key={attachmentIndex}
                        attachmentIndex={attachmentIndex}
                        disabled
                        isInList
                      />
                    ))}
                  </div>
                ),
                filterMethod: (filter, row) => filterAttachments(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="hasAttachments">Has Attachments</option>
                    <option value="noAttachments">No Attachments</option>
                  </select>
                ),
                sortable: false
              },
              {
                Header: 'VIEW/EDIT',
                Cell: row => (
                  <Link
                    to={{
                      pathname: '/app/viewEdit/Daily Report'
                    }}
                    className={`button update settings--links`}
                    onClick={() => setDailyReport(row.original)}
                  >
                    View/Edit
                  </Link>
                ),
                filterable: false,
                sortable: false
              }
            ]}
            noDataText="No results found"
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
        {modalOpen && (
          <Modal
            title="Add New Daily Report"
            titleClassName="greenHeader"
            isOpen={modalOpen}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            formName="DailyReportForm"
          >
            <DailyReportForm
              onSubmit={values => this.handleSubmit(values)}
              activeEmployees={activeEmployees}
              activeCompanyId={activeCompanyId}
              addAttachment={addAttachment}
              clearAttachment={clearAttachment}
              loading={loading}
              locationList={locationList}
              deleteAttachment={deleteAttachment}
              addedAttachments={addedAttachments}
              activeProject={activeProject}
              activeLocation={activeLocation}
              documentFolders={documentFolders}
            />
          </Modal>
        )}
        <DeleteFolderModal
          folderName={activeFolder.name}
          submitActions={() => this.handleFolderDelete(false)}
          onRequestClose={() => this.setState({ openDeleteModal: false })}
          cancelActions={() => this.handleFolderDelete(true)}
          isOpen={openDeleteModal}
        />
        <Modal
          title="Add to Folder"
          titleClassName="blueHeader"
          submitButtonColor="blue"
          isOpen={addToFolderModal}
          submitActions={() => this.handleAddMultipleToFolders()}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
        >
          <Dropdown
            options={folderList}
            handleChange={value =>
              this.setState({ documentFolderId: value.value })
            }
            currentValue={documentFolderId}
          />
        </Modal>
      </div>
    );
  }
}

export default DailyReportList;
