import { call, put, select } from 'redux-saga/effects';
import saveAs from 'file-saver';
import { addMessage, removeMessage } from '../actions/messages';
import {
  createOshaFormResponse,
  createOshaLogResponse,
  fetchOsha300AResponse,
  fetchOsha300LogsResponse,
  fetchOsha301Response,
  updateOshaFormResponse
} from '../actions/oshaLogs';
import { updateLocationEmployeeResponse } from '../actions/personnel';
import { getActiveCompany, getActiveLocationId } from '../selectors/company';
import {
  getOsha300ALogSelector,
  getOshaLogInfoSelector
} from '../selectors/oshaLogs';
import { getCompanyActiveEmployeesSelector } from '../selectors/personnel';
import { oshaQuestionMapper } from '../utils/oshaDbMapper';

export function* fetchOshaCompanyLogs(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    payload = {
      ...payload,
      companyId: activeCompany._id
    };

    const response = yield call(api.fetchOshaLogsByCompany, payload);

    yield put(createOshaLogResponse(response));
    yield put(fetchOsha300LogsResponse(response.osha300Data));
    yield put(fetchOsha300AResponse(response.osha300AData));
    yield put(fetchOsha301Response(response.osha301Data));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchOshaLocationLogs(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const activeLocationId = yield select(getActiveLocationId);

    payload = {
      ...payload,
      companyId: activeCompany._id,
      locationId: activeLocationId._id
    };

    const response = yield call(api.fetchOshaLogsByLocation, payload);

    yield put(createOshaLogResponse(response));
    yield put(fetchOsha300LogsResponse(response.osha300Data));
    yield put(fetchOsha300AResponse(response.osha300AData));
    yield put(fetchOsha301Response(response.osha301Data));
  } catch (e) {
    console.log(e);
  }
}

export function* createOshaForm(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    const response = yield call(api.createOshaLog, payload);

    yield put(createOshaFormResponse(response));
    yield put(
      addMessage({
        error: false,
        message:
          activeCompany.reportingType === 2
            ? 'PERRP Compliance Saved Successfully'
            : 'OSHA Compliance Saved Successfully'
      })
    );
  } catch (e) {
    yield put(
      addMessage({
        error: true,
        message: e
      })
    );
  }
}

export function* updateOshaForm(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    delete payload.formAnswers;

    let response = yield call(api.updateOshaLog, payload);

    response.formAnswers = oshaQuestionMapper(response.questions);

    yield put(updateOshaFormResponse([response]));
    yield put(
      addMessage({
        error: false,
        message:
          activeCompany.reportingType === 2
            ? 'PERRP Compliance Updated Successfully'
            : 'OSHA Compliance Updated Successfully'
      })
    );
  } catch (e) {
    console.log(e);
    yield put(
      addMessage({
        error: true,
        message: `${e}`
      })
    );
  }
}

export function* createOshaLog(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const oshaLogInfo = yield select(getOshaLogInfoSelector);
    const osha300A = yield select(getOsha300ALogSelector);

    let response = {};

    if (!group) {
      payload = {
        ...payload,
        companyId: activeCompany._id
      };
      response = oshaLogInfo.oshaLogId
        ? yield call(api.updateOshaForm, {
            ...payload,
            oshaId: oshaLogInfo.oshaLogId,
            auditLog: []
          })
        : yield call(api.createOshaCompanyForm, payload);
    } else {
      payload = {
        ...payload,
        companyId: activeCompany._id,
        locationId: group._id
      };

      response = oshaLogInfo.oshaLogId
        ? yield call(api.updateOshaForm, {
            ...payload,
            oshaId: oshaLogInfo.oshaLogId,
            auditLog: []
          })
        : yield call(api.createOshaLocationForm, payload);
    }

    osha300A.annualAverageNumberOfWorkers =
      response.annualAverageNumberOfWorkers;
    osha300A.totalHoursWorkedByAllEmployees =
      response.totalHoursWorkedByAllEmployees;
    osha300A.maximumNumberOfWorkersEmployed =
      response.maximumNumberOfWorkersEmployed;
    osha300A.numberOfFullTime = response.numberOfFullTime;
    osha300A.numberOfPartTime = response.numberOfPartTime;
    osha300A.numberOfFireEmtParamedic = response.numberOfFireEmtParamedic;
    osha300A.numberOfPoliceFireEmt = response.numberOfPoliceFireEmt;
    osha300A.numberOfTeachersInstructors = response.numberOfTeachersInstructors;
    osha300A.numberOfAllOtherSupportStaff =
      response.numberOfAllOtherSupportStaff;

    yield put(fetchOsha300AResponse(osha300A));
    yield put(createOshaLogResponse(response));
    yield put(
      addMessage({
        error: false,
        message: 'Employment Information Saved Successfully'
      })
    );
  } catch (e) {
    addMessage({
      error: true,
      message: `${e}`
    });
  }
}

export function* printOshaFiles(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const activeLocationId = yield select(getActiveLocationId);

    payload = {
      ...payload,
      companyId: activeCompany._id
    };

    if (activeLocationId !== 0) {
      payload.locationId = activeLocationId._id;

      const location = activeCompany.locations.filter(
        location => location._id === activeLocationId._id
      );

      payload.isLongTermEstablishment = location[0]
        ? location[0].isSeparateEstablishment
        : false;
    }

    const printValues = payload.print.map(print => print.value);

    delete payload.print;
    let data;

    if (printValues.indexOf(3) > -1) {
      let response =
        activeLocationId !== 0
          ? yield call(api.printLocationOshaCsv, payload)
          : yield call(api.printCompanyOshaCsv, payload);

      response = 'data:text/csv;charset=utf-8,' + response;
      data = encodeURI(response);

      const link = document.createElement('a');
      link.download = `OSHA300A_${activeCompany.name}`;
      link.href = data;
      link.click();
    }

    if (printValues.indexOf(0) > -1) {
      const response =
        activeLocationId !== 0
          ? yield call(api.printLocationOsha300Excel, payload)
          : yield call(api.printCompanyOsha300Excel, payload);
      response.forEach(file => {
        let iframe = document.createElement('iframe');
        iframe.style.visibility = 'collapse';
        document.body.append(iframe);

        iframe.contentDocument.write(
          `<form action="${file.source_url.replace(
            /"/g,
            '"'
          )}" method="GET"></form>`
        );
        iframe.contentDocument.forms[0].submit();

        setTimeout(() => iframe.remove(), 2000);
      });

      const link = document.createElement('a');
      link.download = `OSHA300_${activeCompany.name}`;
      link.href = response.source_url;
      link.click();
    }

    if (printValues.indexOf(1) > -1) {
      const response =
        activeLocationId !== 0
          ? yield call(api.printLocationOsha300AExcel, payload)
          : yield call(api.printCompanyOsha300AExcel, payload);

      const link = document.createElement('a');
      link.download = `OSHA300A_${activeCompany.name}`;
      link.href = response.source_url;
      link.click();
    }

    if (printValues.indexOf(2) > -1) {
      const response =
        activeLocationId !== 0
          ? yield call(api.printLocationOsha301Excel, payload)
          : yield call(api.printCompanyOsha301Excel, payload);

      let zip = require('jszip')();

      response.forEach((file, index) => {
        let blob = fetch(file.source_url)
          .then(r => r.blob())
          .catch(e => console.log(e));
        zip.file(`301_${index}.xlsx`, blob, {
          binary: true
        });
      });

      zip.generateAsync({ type: 'blob' }).then(function(blob) {
        saveAs(blob, `301_${payload.year}_Reports.zip`);
      });
    }

    yield put(printOshaFiles({}));
    addMessage({
      error: false,
      message: 'Documents Downloaded Successfully.'
    });
  } catch (e) {
    addMessage({
      error: true,
      message: `${e}`
    });
  }
}

export function* printSelectedOsha301(api, { payload }) {
  try {
    const response = yield call(api.printSelectedOsha301Excel, payload);

    let iframe = document.createElement('iframe');
    iframe.style.visibility = 'collapse';
    document.body.append(iframe);

    iframe.contentDocument.write(
      `<form action="${response.source_url.replace(
        /"/g,
        '"'
      )}" method="GET"></form>`
    );
    iframe.contentDocument.forms[0].submit();

    setTimeout(() => iframe.remove(), 2000);

    yield put(printOshaFiles({}));
    addMessage({
      error: false,
      message: 'Selected Osha 301 Downloaded Successfully.'
    });
  } catch (e) {
    addMessage({
      error: true,
      message: `${e}`
    });
  }
}

export function* updateOshaEmployee(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const activeLocationId = yield select(getActiveLocationId);
    const personnel = yield select(getCompanyActiveEmployeesSelector);
    const oshaLog = yield call(api.fetchOshaFormByIncident, {
      companyId: activeCompany._id,
      incidentId: payload.incidentId
    });

    let personnelToUpdate = personnel.filter(
      person =>
        person.person.firstName === payload.person.firstName &&
        person.person.lastName === payload.person.lastName &&
        person.jobTitle === payload.jobTitle
    );

    personnelToUpdate = {
      ...personnelToUpdate[0],
      person: {
        ...personnelToUpdate[0].person,
        addresses: [
          {
            streetAddress: payload.homeAddress,
            city: payload.city,
            stateName: payload.state,
            zip: payload.zip
          }
        ],
        dateOfBirth: payload.dateOfBirth
      },
      dateOfHire: payload.dateOfHire
    };

    const oshaWorkerUpdate = [
      {
        key: 'workerStreetAddress',
        value: payload.homeAddress
      },
      {
        key: 'workerCity',
        value: payload.city
      },
      {
        key: 'workerState',
        value: payload.state
      },
      {
        key: 'workerZip',
        value: payload.zip
      },
      {
        key: 'workerDateOfBirth',
        value: payload.dateOfBirth
      },
      {
        key: 'workerDateOfHire',
        value: payload.dateOfHire
      }
    ];

    oshaLog[0].questions = [...oshaLog[0].questions, ...oshaWorkerUpdate];

    const employeeResponse = yield call(
      api.updateEmployee,
      activeCompany._id,
      personnelToUpdate
    );
    yield call(api.updateOshaLog, { ...oshaLog[0] });

    yield put(
      updateLocationEmployeeResponse({
        setEmployeeType: 'Employees',
        ...employeeResponse
      })
    );

    let response = [];
    if (activeLocationId === 0) {
      response = yield call(api.fetchOshaLogsByCompany, {
        companyId: oshaLog[0].companyId,
        year: oshaLog[0].year
      });
    } else {
      response = yield call(api.fetchOshaLogsByLocation, {
        companyId: oshaLog[0].companyId,
        locationId: oshaLog[0].locationId,
        year: oshaLog[0].year
      });
    }

    yield put(createOshaLogResponse(response));
    yield put(fetchOsha300LogsResponse(response.osha300Data));
    yield put(fetchOsha300AResponse(response.osha300AData));
    yield put(fetchOsha301Response(response.osha301Data));

    addMessage({
      error: false,
      message: 'Employee Info Updated.'
    });
  } catch (e) {
    addMessage({
      error: true,
      message: `${e}`
    });
  }
}
