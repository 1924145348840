import moment from 'moment';
import {
  ACTION_COMPONENTS,
  CLOSURE_COMPONENTS,
  INVESTIGATION_COMPONENTS
} from '../constants/constants';
import { getUserInfo } from './assignedToMapper';
import { getUsersByEstablishmentSuperLevel } from './employeeDGPHelper';

const isPastDue = dueDate => {
  return moment().diff(dueDate, 'days') > 0;
};

export const getPercentages = (
  reportComponents,
  incidentStage,
  reportTemplateIds
) => {
  let completed = 0;
  let started = 0;
  let pastDue = 0;
  let total = 0;

  if (!reportComponents) return {};

  if (incidentStage === 0) {
    completed = 1;

    INVESTIGATION_COMPONENTS.forEach(component => {
      const report = reportComponents.filter(report => report[0] === component);
      if (report.length > 0 && report[0][4].isComplete) {
        completed++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        isPastDue(Date.parse(report[0][3].dueDate))
      ) {
        pastDue++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        report[0][5].isStarted
      ) {
        started++;
      }

      if (
        reportTemplateIds.indexOf('11') > -1 ||
        reportTemplateIds.indexOf('12') > -1
      ) {
        total = INVESTIGATION_COMPONENTS.length - 2;
      } else {
        total = INVESTIGATION_COMPONENTS.length - 1;
      }
    });
  } else if (incidentStage === 1) {
    ACTION_COMPONENTS.forEach(component => {
      const report = reportComponents.filter(report => report[0] === component);
      if (report.length > 0 && report[0][4].isComplete) {
        completed++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        isPastDue(Date.parse(report[0][3].dueDate))
      ) {
        pastDue++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        report[0][5].isStarted
      ) {
        started++;
      }
    });

    if (reportTemplateIds.indexOf('2') > -1) {
      total = ACTION_COMPONENTS.length - 1;
    } else {
      total = ACTION_COMPONENTS.length - 2;
    }
  } else {
    CLOSURE_COMPONENTS.forEach(component => {
      const report = reportComponents.filter(report => report[0] === component);
      if (report.length > 0 && report[0][4].isComplete) {
        completed++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        isPastDue(Date.parse(report[0][3].dueDate))
      ) {
        pastDue++;
      } else if (
        report.length > 0 &&
        !report[0][4].isComplete &&
        report[0][5].isStarted
      ) {
        started++;
      }
    });

    if (reportTemplateIds.indexOf('2') > -1) {
      total = CLOSURE_COMPONENTS.length;
    } else {
      total = CLOSURE_COMPONENTS.length - 1;
    }
  }

  return { completed, started, pastDue, total };
};

export const isSectionComplete = section => {
  const fields = section[1];

  if (section[3] && section[3].notApplicable) {
    return true;
  }

  const complete = fields.map(field => {
    if (
      field.required &&
      typeof field.answer !== 'number' &&
      JSON.stringify(field.answer) !== '{}' &&
      !field.answer &&
      !field.legacy
    ) {
      return 'not answered';
    }

    return '';
  });

  return complete.findIndex(answer => answer === 'not answered') === -1;
};

export const isInitialReportSectionComplete = (section, answers) => {
  const fields = section.fields;

  const complete = fields.map(field => {
    if (
      field.required &&
      typeof field.answer !== 'number' &&
      JSON.stringify(field.answer) !== '{}' &&
      (!answers[field._id] ||
        (Array.isArray(answers[field._id]) && !answers[field._id].length)) &&
      !field.legacy
    ) {
      return 'not answered';
    }

    return '';
  });

  return complete.findIndex(answer => answer === 'not answered') === -1;
};

export const canMarkAsComplete = subSections => {
  let totalSections = subSections.length;
  let sectionComplete = 0;

  subSections.map(section => isSectionComplete(section) && sectionComplete++);
  return totalSections === sectionComplete;
};

export const canEditTask = (activeIncident, loggedInUser, task) => {
  if (loggedInUser._id === activeIncident.incidentOwner) {
    return true;
  } else if (task.reportComponentName) {
    const reportComponents = activeIncident.reportComponents;

    const assignedTo = reportComponents.filter(
      report => report[0] === task.reportComponentName
    )[0][2].assignedTo;

    return loggedInUser._id === assignedTo;
  } else {
    const assignedTo = task.assignedTo[0];

    return loggedInUser._id === assignedTo;
  }
};

export const getIncidentStagesList = incidents => {
  const incidentContent = incidents.map(incident => {
    const investigationPercentages = getPercentages(
      incident.reportComponents,
      0,
      incident.reportTemplateIds
    );
    const actionPercentages = getPercentages(
      incident.reportComponents,
      1,
      incident.reportTemplateIds
    );

    if (incident.reportStage && incident.reportStage.label === 'Closed') {
      return {
        ...incident,
        stage: 'Done'
      };
    } else if (
      investigationPercentages.completed !== investigationPercentages.total
    ) {
      return {
        ...incident,
        stage: 'Investigation'
      };
    } else if (actionPercentages.completed !== actionPercentages.total) {
      return {
        ...incident,
        stage: 'Action/Learning'
      };
    } else {
      return {
        ...incident,
        stage: 'Closure'
      };
    }
  });

  const investigationIncidents = incidentContent.filter(
    incident => incident.stage === 'Investigation'
  );

  const actionIncidents = incidentContent.filter(
    incident => incident.stage === 'Action/Learning'
  );

  const closureIncidents = incidentContent.filter(
    incident => incident.stage === 'Closure'
  );

  const doneIncidents = incidentContent.filter(
    incident => incident.stage === 'Done'
  );

  return [
    investigationIncidents,
    actionIncidents,
    closureIncidents,
    doneIncidents
  ];
};

export const getPercentComplete = reportComponent => {
  if (
    !reportComponent[1].subSections ||
    reportComponent[0] === 'Witness Statements'
  )
    return 'no percentage';

  const reportQuestions = reportComponent[1].subSections;
  let totalRequired = 0;
  let totalRequiredAnswered = 0;
  reportQuestions.forEach(section =>
    section[1].forEach(field => {
      if (field.required && !field.legacy) {
        totalRequired++;
        if (
          field.answer &&
          !(Array.isArray(field.answer) && field.answer.length === 0)
        ) {
          totalRequiredAnswered++;
        }
      }
    })
  );

  return totalRequired === 0
    ? 0
    : parseInt((totalRequiredAnswered / totalRequired) * 100, 10);
};

export const getAvailableUsersForPermissions = (
  companyUsers,
  activeIncident
) => {
  const availableUsers = getUsersByEstablishmentSuperLevel(
    companyUsers,
    activeIncident.locationId
  );

  const usersWithPermissions = activeIncident.permissions.map(
    user => user.userId
  );

  return availableUsers.filter(
    user => usersWithPermissions.indexOf(user.value) === -1
  );
};

export const hasAccess = (loggedInUser, stage, activeIncident) => {
  if (activeIncident && activeIncident.incidentOwner === loggedInUser._id)
    return true;

  const userPerms = activeIncident?.permissions?.filter(
    perm => perm.userId === loggedInUser._id
  );

  if (!userPerms || userPerms.length === 0) return false;

  return userPerms[0][stage] === 'Edit';
};

export const hasAccessStage = (
  loggedInUser,
  permissions,
  stage,
  activeIncident
) => {
  if (activeIncident && activeIncident.incidentOwner === loggedInUser._id)
    return true;

  const userPerms =
    permissions && permissions.filter(perm => perm.userId === loggedInUser._id);

  if (!userPerms || userPerms.length === 0) return false;

  return userPerms[0][stage];
};

export const getPermissionUserList = (activeIncident, companyUsers, stage) => {
  if (stage) {
    return activeIncident.permissions
      .map(user => {
        const userInfo = getUserInfo(user.userId, companyUsers);
        return {
          value: userInfo._id,
          label: `${userInfo.firstName} ${userInfo.lastName}`,
          ...user
        };
      })
      .filter(user => user.value && user[stage] === 'Edit');
  } else {
    return activeIncident.permissions.map(user => {
      const userInfo = getUserInfo(user.userId, companyUsers);
      return {
        value: userInfo._id,
        label: `${userInfo.firstName} ${userInfo.lastName}`,
        ...user
      };
    });
  }
};
