import { createSelector } from 'reselect';

const userSelector = state => state.user;

export const getLoggedInUser = createSelector(
  userSelector,
  user => user && user.activeUser
);

export const getUserLoading = createSelector(
  userSelector,
  user => user && user.loading
);

export const getLoadedUsers = createSelector(
  userSelector,
  user => user.users
);
