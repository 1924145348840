import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import InfoBar from '../InfoBar';
import Textbox from '../inputs/Textbox';
import Button from '../inputs/Button';
import FrequencyPicker from '../inputs/FrequencyPicker';
import DateRangePicker from '../inputs/DateRangePicker';
import DateUnitPicker from '../inputs/DateUnitPicker';

import { updateAnalyticsDashboard } from '../../api';

import styles from './picker.module.scss';

export default class AnalyticsPeriodPicker extends Component {
  constructor(props) {
    super(props);
    let period = this.props.period || {};

    this.state = {
      currentPeriod: { ...period },
      savedPeriod: period,
      periodNumber: period.number
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.period, this.props.period)) {
      const period = this.props.period || {};
      this.setState(
        {
          currentPeriod: { ...period },
          savedPeriod: period,
          periodNumber: period.number
        },
        () =>
          this.props.onChange &&
          this.props.onChange({ ...this.state.currentPeriod })
      );
    }
  }

  save = () => {
    let period = {
      mode: this.state.currentPeriod.mode,
      frequency: this.state.currentPeriod.frequency
    };

    if (period.mode === 'absolute') {
      period.startDate = this.state.currentPeriod.startDate;
      period.endDate = this.state.currentPeriod.endDate;
    } else if (period.mode === 'relative') {
      period.unit = this.state.currentPeriod.unit;
      period.number = this.state.currentPeriod.number;
    }
    updateAnalyticsDashboard({
      dashboardId: this.props.dashboardId,
      payload: { period }
    }).then(
      () => this.props.onSave && this.props.onSave(this.state.currentPeriod)
    );
  };

  itemChange = (i, v) => {
    this.setState(
      (state, props) => {
        let newState = { ...state };
        if ('number' in newState.currentPeriod) {
          newState.currentPeriod.number = Number(newState.currentPeriod.number);
        }
        if (
          newState.currentPeriod.mode === 'relative' &&
          !newState.currentPeriod.unit
        ) {
          newState.currentPeriod.unit = 'days';
        }
        newState.currentPeriod[i] = v;
        return newState;
      },
      () =>
        this.props.onChange &&
        this.props.onChange({ ...this.state.currentPeriod })
    );
  };

  periodNumberChange = () => this.itemChange('number', this.state.periodNumber);
  periodUnitChange = v => this.itemChange('unit', v.value);
  startDateChange = d => this.itemChange('startDate', d?.toString());
  endDateChange = d => this.itemChange('endDate', d?.toString());
  frequencyChange = v => this.itemChange('frequency', v.value);
  modeChange = v => this.itemChange('mode', v.value);

  render() {
    const value = this.state.currentPeriod || {};
    let classes = [styles.picker];
    if (this.props.className) classes.push(this.props.className);

    let startDate = value.startDate;
    if (typeof startDate === 'string') startDate = moment(startDate);
    let endDate = value.endDate;
    if (typeof endDate === 'string') endDate = moment(endDate);

    return (
      <InfoBar color="white" className={classes.join(' ')}>
        <DateRangePicker
          className={styles.input}
          handleChange={this.modeChange}
          currentValue={value.mode}
          fieldLabel="Date Range:"
          name="range"
          customRange
          customPeriod
        />
        {value.mode === 'absolute' && (
          <div className={styles.period}>
            <SingleDatePicker
              date={startDate}
              onDateChange={this.startDateChange}
              focused={this.state.startDateFocused}
              onFocusChange={f =>
                this.setState({ startDateFocused: f.focused })
              }
              placeholder="Start"
              isOutsideRange={d => false}
            />
            <SingleDatePicker
              date={endDate}
              onDateChange={this.endDateChange}
              focused={this.state.endDateFocused}
              onFocusChange={f => this.setState({ endDateFocused: f.focused })}
              placeholder="End"
              isOutsideRange={d => false}
            />
          </div>
        )}
        {value.mode === 'relative' && (
          <div className={styles.period}>
            <span>Past</span>
            <Textbox
              className={`${styles.input} ${styles.time}`}
              currentValue={this.state.periodNumber || ''}
              handleChange={e => {
                let v = e.target.value;
                v = v.replace(/[^0-9]/g, '');
                this.setState({ periodNumber: Number(v) });
              }}
              enter={this.periodNumberChange}
              onBlur={this.periodNumberChange}
              type="text"
            />
            <DateUnitPicker
              className={styles.input}
              handleChange={this.periodUnitChange}
              currentValue={value.unit}
              name="unit"
            />
          </div>
        )}
        <FrequencyPicker
          className={styles.input}
          handleChange={this.frequencyChange}
          currentValue={value.frequency}
          fieldLabel="Period:"
          displayTooltip={true}
          name="frequency"
          tooltip="This option only applies to line graphs, and controls the time period each dot represents."
        />
        {!isEqual(this.state.currentPeriod, this.state.savedPeriod) && (
          <Button
            color="green"
            onClick={this.save}
            text="Set as Default"
            className={styles.save}
          />
        )}
      </InfoBar>
    );
  }
}
