import {
  FETCH_COMPANY_INCIDENT_ANALYTICS_REQUEST,
  FETCH_COMPANY_INCIDENT_ANALYTICS_RESPONSE,
  FETCH_COMPANY_MANAGEMENT_ANALYTICS_REQUEST,
  FETCH_COMPANY_MANAGEMENT_ANALYTICS_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  nearMissByType: [],
  jsaClosedOneWeek: '',
  accountabilityDocuments: '',
  numberOfProactiveReports: '',
  numberOfSafetyTrainingsPerformed: '',
  averageIncidentLagTime: '',
  reportCostsStats: [],
  correctiveActionsByType: [],
  dart: '',
  tcir: '',
  ltir: '',
  numberOfFROIs: '',
  oshaCasesAverageLossTime: '',
  allCasesAverageLossTime: '',
  totalSafetyCosts: '',
  observationCostsStats: '',
  taskData: [],
  oshaDataForIncidents: [],
  totalOshaInjury: [],
  topFactorsFROIAuto: []
};

const analytics = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMPANY_MANAGEMENT_ANALYTICS_REQUEST:
    case FETCH_COMPANY_INCIDENT_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FETCH_COMPANY_MANAGEMENT_ANALYTICS_RESPONSE:
    case FETCH_COMPANY_INCIDENT_ANALYTICS_RESPONSE:
      return {
        ...state,
        loading: false,
        ...payload
      };

    default:
      return state;
  }
};

export default analytics;
