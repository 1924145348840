import {
  CLEAR_USER,
  CREATE_USER_REQUEST,
  CREATE_USER_SECURITY_QUESTIONS_REQUEST,
  FETCH_USER_PROFILE,
  LOAD_USER_REQUEST,
  LOAD_USER_RESPONSE,
  UPDATE_DEFAULT_PASSWORD_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESPONSE
} from '../constants/actionTypes';

export const fetchUserProfile = payload => ({
  type: FETCH_USER_PROFILE,
  payload
});

export const updateUserRequest = payload => ({
  type: UPDATE_USER_REQUEST,
  payload
});

export const updateUserResponse = payload => ({
  type: UPDATE_USER_RESPONSE,
  payload
});

export const updatePasswordRequest = payload => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload
});

export const updateDefaultPasswordRequest = payload => ({
  type: UPDATE_DEFAULT_PASSWORD_REQUEST,
  payload
});

export const createUserRequest = payload => ({
  type: CREATE_USER_REQUEST,
  payload
});

export const createUserSecurityQuestionsRequest = payload => ({
  type: CREATE_USER_SECURITY_QUESTIONS_REQUEST,
  payload
});

export const loadUserRequest = payload => ({
  type: LOAD_USER_REQUEST,
  payload
});

export const loadUserResponse = payload => ({
  type: LOAD_USER_RESPONSE,
  payload
});

export const clearUser = payload => ({
  type: CLEAR_USER,
  payload
});
