import { all, call, put, select } from 'redux-saga/effects';

import history from '../history';
import { fetchCompanyCirclesResponse } from '../actions/incidents';
import {
  fetchSafetyWalksResponse,
  setActiveSafetyWalk
} from '../actions/safetyWalks';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getUserTasksSelector } from '../selectors/tasks';
import { circleDataMapper } from '../utils/circleDataMapper';

export function* loadSafetyWalk(api, { payload }) {
  try {
    const response = yield call(api.fetchSafetyWalkById, payload);

    yield put(setActiveSafetyWalk(response));
    history.push('/app/safetyWalkContainer');
  } catch (e) {
    console.log(e);
  }
}

export function* fetchSafetyWalks(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeLocation = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);

    let response = [];
    let circleResponse = [];

    if (activeProject && activeProject._id) {
      const { swResponse, inCircleResponse } = yield all({
        swResponse: call(api.fetchSafetyWalksByProject, activeProject._id),
        inCircleResponse: call(api.fetchProjectCircleData, {
          companyId: activeCompany._id,
          projectId: activeProject._id,
          reportType: 'safetyWalk'
        })
      });

      response = swResponse;
      circleResponse = inCircleResponse;
    } else if (activeLocation && activeLocation._id) {
      const { swResponse, inCircleResponse } = yield all({
        swResponse: call(api.fetchSafetyWalksByGroup, activeLocation._id),
        inCircleResponse: call(api.fetchLocationCircleData, {
          companyId: activeCompany._id,
          groupId: activeLocation._id,
          reportType: 'safetyWalk'
        })
      });

      response = swResponse;
      circleResponse = inCircleResponse;
    } else {
      const { swResponse, inCircleResponse } = yield all({
        swResponse: call(api.fetchSafetyWalksByCompany, {
          companyId: activeCompany._id
        }),
        inCircleResponse: call(api.fetchCompanyCircleData, {
          companyId: activeCompany._id,
          reportType: 'safetyWalk'
        })
      });

      response = swResponse;
      circleResponse = inCircleResponse;
    }

    yield put(fetchSafetyWalksResponse(response));
    yield put(
      fetchCompanyCirclesResponse(
        circleDataMapper(
          circleResponse,
          activeCompany.isDash ? 'DASH Report' : 'Safety Walk'
        )
      )
    );
  } catch (e) {
    console.log(e);
  }
}

export function* addSafetyWalk(api, { payload }) {
  try {
    const tasks = yield select(getUserTasksSelector);
    const sig = payload.signature;
    payload.signature = sig?.source_url || sig;

    let response = yield call(api.createSafetyWalk, payload);

    if (sig?._id) {
      yield call(api.updateAttachment, {
        _id: sig._id,
        ownerId: response._id,
        ownerType: 'safetyWalk'
      });
    }

    yield all(
      tasks.map(task =>
        call(api.updateTask, task.companyId, task._id, {
          ...task,
          ownerId: response._id
        })
      )
    );

    response = yield call(api.fetchSafetyWalkById, response._id);

    yield put(setActiveSafetyWalk(response));
  } catch (e) {
    console.log(e);
  }
}

export function* updateSafetyWalk(api, { payload }) {
  try {
    const response = yield call(api.updateSafetyWalk, payload);

    yield put(setActiveSafetyWalk(response));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteSafetyWalk(api, { payload }) {
  try {
    yield call(api.deleteSafetyWalk, payload);

    yield put(setActiveSafetyWalk(undefined));
  } catch (e) {
    console.log(e);
  }
}
