import {
  ADD_UPLOADED_ATTACHMENTS_REQUEST,
  ADD_UPLOADED_ATTACHMENTS_RESPONSE,
  CLEAR_UPLOADED_ATTACHMENTS,
  DELETE_ATTACHMENT_REQUEST,
  DELETE_ATTACHMENT_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  list: []
};

const attachments = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_UPLOADED_ATTACHMENTS_REQUEST:
    case DELETE_ATTACHMENT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_UPLOADED_ATTACHMENTS_RESPONSE: {
      if (Array.isArray(payload)) {
        return {
          ...state,
          loading: false,
          list: [...payload]
        };
      }

      return {
        ...state,
        loading: false,
        list: [...state.list, payload]
      };
    }

    case CLEAR_UPLOADED_ATTACHMENTS:
      return {
        ...state,
        list: []
      };

    case DELETE_ATTACHMENT_RESPONSE: {
      const attachmentsList = state.list;
      let index = -1;

      index = attachmentsList.findIndex(
        attachment => attachment._id === payload._id
      );

      return {
        ...state,
        loading: false,
        list: [
          ...attachmentsList.slice(0, index),
          ...attachmentsList.slice(index + 1)
        ]
      };
    }

    default:
      return state;
  }
};

export default attachments;
