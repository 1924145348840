import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import socketIOClient from 'socket.io-client';
import history from '../../../history';
import {
  setActiveTrainingAttachment,
  setActiveTrainingResponse
} from '../../../actions/training';
import config from '../../../config/config';
import {
  getActiveTrainingAttachmentSelector,
  getActiveTrainingSelector
} from '../../../selectors/training';
import {
  isMovie,
  isMicrosoft,
  isYoutube
} from '../../../utils/attachmentSelector';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';

import styles from './styles.module.scss';

class TrainingAttachmentViewer extends Component {
  state = {
    startTime: '',
    socket: null,
    endpoint: config.apiUrl,
    startingAttachmentTime: 0
  };

  componentDidMount() {
    this.setState({ startTime: moment() });
    const { endpoint } = this.state;
    const socket = socketIOClient(endpoint, { transports: ['websocket'] });
    this.setState({ socket });
    this.setState({
      startingAttachmentTime: this.props.activeAttachment.timeSpent
    });

    this.interval = setInterval(() => this.updateAttachment(), 5000);
  }

  componentWillUnmount() {
    this.state.socket.close();
    clearInterval(this.interval);
  }

  updateAttachment(isGoBack) {
    const { activeAttachment, activeTraining, setActiveTraining } = this.props;
    const { startTime, startingAttachmentTime } = this.state;

    if (activeTraining.isCompleted) return;
    let secondsAsMinutes = moment().diff(startTime, 'seconds') / 60;

    if (isGoBack) {
      secondsAsMinutes += parseFloat(startingAttachmentTime);
      activeAttachment.timeSpent = secondsAsMinutes.toFixed(2);
    } else {
      activeAttachment.timeSpent = secondsAsMinutes.toFixed(2);
    }

    const attachmentIndex = activeTraining.attachments.findIndex(
      attachment => attachment._id === activeAttachment._id
    );

    activeTraining.attachments[attachmentIndex] = activeAttachment;

    this.state.socket.emit('updateTrainingTime', activeTraining);
    setActiveTraining(activeTraining);
  }

  handleBack = () => {
    this.updateAttachment(true);
    history.goBack();
  };

  render() {
    const { activeAttachment } = this.props;

    const header = (
      <Header
        title="Safety Training"
        section={activeAttachment.original_filename}
        clickBack={() => this.handleBack()}
      />
    );

    return (
      <HeaderAndFooter
        Header={header}
        className={styles.trainingAttachmentViewer}
      >
        <div className={styles.main}>
          {isYoutube(activeAttachment) ? (
            <iframe
              title="pdfSummary"
              src={activeAttachment.source_url}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={styles.docViewer}
            />
          ) : isMovie(activeAttachment) ? (
            <video className={styles.vidViewer} controls>
              <source src={activeAttachment.source_url} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          ) : isMicrosoft(activeAttachment) ? (
            <iframe
              title="pdfSummary"
              src={`https://docs.google.com/gview?url=${activeAttachment.source_url}&embedded=true`}
              frameBorder="0"
              className={styles.docViewer}
            />
          ) : (
            <iframe
              title="pdfSummary"
              src={activeAttachment.source_url}
              frameBorder="0"
              className={styles.docViewer}
            />
          )}
        </div>
      </HeaderAndFooter>
    );
  }
}

const mapStateToProps = state => ({
  activeAttachment: getActiveTrainingAttachmentSelector(state),
  activeTraining: getActiveTrainingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveAttachment: () => dispatch(setActiveTrainingAttachment({})),
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingAttachmentViewer);
