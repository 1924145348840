import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import {
  createContinuousRequest,
  deleteContinuousRequest,
  setActiveMyDocument,
  updateContinuousRequest
} from '../../actions/documents';
import * as api from '../../api/index';
import { getActiveMyDocumentSelector } from '../../selectors/documents';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import { getUserInfo } from '../../utils/assignedToMapper';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { SaveCancelFooter } from '../../components/Footer';
import Dropdown from '../../components/inputs/Dropdown';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import UserBadge from '../../components/UserBadge';
import RecurrenceInfoCard from '../../components/recurrenceCards/RecurrenceInfoCard';
import SignatureCard from '../../components/SignatureCard';
import LeftNav from '../../components/LeftNav';

class RecurrenceContainer extends Component {
  state = {
    isCreating: false,
    hasUnsavedChanges: false,
    isEditing: false,
    openDeleteDocumentModal: false,
    openDiscardDocumentModal: false,
    openUnsavedChangesModal: false,
    label: 'Recurrence, Relapse During Recovery & Continuing Treatment',
    employeeName: '',
    dateOfRecurrence: '',
    dateReported: '',
    datePhysicianAppt: '',
    physicianInfo: '',
    incidentDesc: '',
    reinjuryOccurred: '',
    injuryHistory: '',
    supervisorId: '',
    isSupervisor: false,
    downloading: false,
    openReassignModal: false,
    reassignUserId: undefined,
    openAuthorizationModal: false,
    employeeSignature: undefined,
    supervisorSignature: undefined
  };

  componentDidMount() {
    if (!this.props.activeMyDoc._id) {
      this.setState({ isCreating: true });
    } else {
      this.setState({
        employeeName: this.props.activeMyDoc.formData.employeeName,
        dateOfRecurrence: this.props.activeMyDoc.formData.dateOfRecurrence,
        dateReported: this.props.activeMyDoc.formData.dateReported,
        datePhysicianAppt: this.props.activeMyDoc.formData.datePhysicianAppt,
        physicianInfo: this.props.activeMyDoc.formData.physicianInfo,
        incidentDesc: this.props.activeMyDoc.formData.incidentDesc,
        reinjuryOccurred: this.props.activeMyDoc.formData.reinjuryOccurred,
        injuryHistory: this.props.activeMyDoc.formData.injuryHistory,
        supervisorId: this.props.activeMyDoc.supervisorId,
        isSupervisor:
          this.props.loggedInUser._id === this.props.activeMyDoc.supervisorId,
        isApprovedBySupervisor: this.props.activeMyDoc.isApprovedBySupervisor,
        employeeSignature: this.props.activeMyDoc.formData.employeeSignature,
        supervisorSignature: this.props.activeMyDoc.formData.supervisorSignature
      });
    }
  }

  handleChange = values => {
    this.setState({ ...values, hasUnsavedChanges: true });
  };

  canSubmit = () => {
    const {
      employeeName,
      dateOfRecurrence,
      dateReported,
      datePhysicianAppt,
      incidentDesc,
      reinjuryOccurred,
      injuryHistory,
      supervisorId,
      employeeSignature,
      supervisorSignature,
      isSupervisor
    } = this.state;

    if (
      employeeName &&
      dateOfRecurrence &&
      dateReported &&
      datePhysicianAppt &&
      incidentDesc &&
      reinjuryOccurred &&
      injuryHistory &&
      employeeSignature &&
      supervisorId.value &&
      !isSupervisor
    ) {
      return true;
    } else if (isSupervisor && supervisorSignature) {
      return true;
    }

    return false;
  };

  handleSubmit = () => {
    if (this.props.activeMyDoc._id) {
      let update = {
        ...this.state,
        _id: this.props.activeMyDoc._id
      };
      if (this.state.isSupervisor) {
        update.supervisorSignature = this.state.supervisorSignature;
      }
      this.props.updateContinuous(update);
    } else {
      this.props.createContinuous(this.state);
    }
  };

  printForm = async () => {
    this.setState({ downloading: true });
    const url = await api.printContinuousCare({ ...this.props.activeMyDoc });
    window.open(url, '_blank');
    this.setState({ downloading: false });
  };

  handleDeleteDocument() {
    this.props.deleteContinuous(this.props.activeMyDoc);
  }

  handleReassignUser = () => {
    const { reassignUserId } = this.state;

    this.props.updateContinuous({
      ...this.state,
      _id: this.props.activeMyDoc._id,
      supervisorId: reassignUserId
    });
  };

  render() {
    const {
      isCreating,
      hasUnsavedChanges,
      isEditing,
      openDeleteDocumentModal,
      openDiscardDocumentModal,
      openUnsavedChangesModal,
      employeeName,
      dateOfRecurrence,
      dateReported,
      datePhysicianAppt,
      physicianInfo,
      incidentDesc,
      reinjuryOccurred,
      injuryHistory,
      supervisorId,
      isSupervisor,
      isApprovedBySupervisor,
      downloading,
      openReassignModal,
      reassignUserId,
      openAuthorizationModal
    } = this.state;

    const { users, activeMyDoc } = this.props;

    const userDropdown = users
      .filter(user => user.userAccount.accessLevel !== '100')
      .map(user => {
        return {
          value: user.userAccount._id,
          label: `${user.person.firstName} ${user.person.lastName}`
        };
      });

    const leftNav = [
      { label: 'Recurrence, Relapse During Recovery & Continuing Treatment' },
      { label: 'Employee Signature' },
      { label: 'Supervisor Approval' }
    ];

    const header = (
      <Header
        title="My Documents"
        section={
          !isEditing && !isCreating
            ? 'View Document'
            : isCreating
            ? 'Create Document'
            : 'Edit Document'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
        rightButtons={[
          {
            visible: !isCreating && !isSupervisor && !isApprovedBySupervisor,
            text: isEditing ? 'Delete Document' : 'Edit Document',
            color: isEditing ? 'red' : 'blue',
            onClick: () =>
              isEditing
                ? this.setState({ openDeleteDocumentModal: true })
                : this.setState({ isEditing: true })
          },
          {
            visible: isSupervisor && !isApprovedBySupervisor,
            text: 'Change Supervisor',
            color: 'red',
            onClick: () => this.setState({ openReassignModal: true })
          },
          {
            visible: isApprovedBySupervisor,
            loading: downloading,
            text: 'Print Form',
            color: 'blue',
            onClick: this.printForm
          }
        ]}
      />
    );

    const footer = (
      <SaveCancelFooter
        editing={!isCreating}
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={() =>
          isSupervisor
            ? this.setState({ openAuthorizationModal: true })
            : this.handleSubmit()
        }
        cancelButtonClick={() =>
          isCreating
            ? this.setState({ openDiscardDocumentModal: true })
            : isEditing && hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
      />
    );

    const left = <LeftNav items={leftNav} />;

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={left}
          showFooter={isEditing || isCreating || isSupervisor}
        >
          <RecurrenceInfoCard
            employeeName={employeeName}
            dateOfRecurrence={dateOfRecurrence}
            dateReported={dateReported}
            datePhysicianAppt={datePhysicianAppt}
            physicianInfo={physicianInfo}
            incidentDesc={incidentDesc}
            reinjuryOccurred={reinjuryOccurred}
            injuryHistory={injuryHistory}
            disabled={!isCreating && !isEditing}
            handleChange={values => this.handleChange(values)}
            name={0}
          />
          <SignatureCard
            header="Employee Signature"
            currentValue={this.state.employeeSignature}
            onChange={sig => this.handleChange({ employeeSignature: sig })}
            disabled={!isCreating}
            ownerId={activeMyDoc?._id}
            ownerType={activeMyDoc ? 'continuousCare' : undefined}
            name={1}
          />
          <SignatureCard
            header="Supervisor Approval"
            dropdownName="Select your Supervisor"
            users={userDropdown}
            onUserChange={values => this.handleChange({ supervisorId: values })}
            userId={supervisorId}
            userDisabled={isApprovedBySupervisor}
            disabled={
              isCreating || isEditing || !isSupervisor || isApprovedBySupervisor
            }
            currentValue={this.state.supervisorSignature}
            onChange={supervisorSignature =>
              this.handleChange({ supervisorSignature })
            }
            ownerId={activeMyDoc?._id}
            ownerType={activeMyDoc ? 'continuousCare' : undefined}
            name={2}
          />
        </HeaderAndFooter>
        <Modal
          title="Delete Document"
          titleClassName="redHeader"
          isOpen={openDeleteDocumentModal}
          submitButtonColor="red"
          submitButtonText="Delete Document"
          onRequestClose={() =>
            this.setState({ openDeleteDocumentModal: false })
          }
          submitActions={() => this.handleDeleteDocument()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting the Document will delete it for you as well as anyone who
              may be associated with it.
            </span>
          </div>
        </Modal>
        <Modal
          title="Discard Document"
          titleClassName="redHeader"
          isOpen={openDiscardDocumentModal}
          submitButtonColor="red"
          submitButtonText="Discard Document"
          onRequestClose={() =>
            this.setState({ openDiscardDocumentModal: false })
          }
          submitActions={() => history.goBack()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Discarding the Document will delete all information that has been
              gathered and pictures taken.
            </span>
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => history.goBack()}
        />
        <Modal
          title="Change Supervisor"
          titleClassName="redHeader"
          isOpen={openReassignModal}
          submitButtonColor="red"
          submitButtonText="Save Changes"
          onRequestClose={() => this.setState({ openReassignModal: false, reassignUserId: undefined })}
          submitActions={() => this.handleReassignUser()}
          disableSubmit={!reassignUserId}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            From here you can assign a different user as the Supervisor for this
            Document. .{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This is to be used only if you are not the person meant to approve
              this document (as a Supervisor). You will be removed from this
              document.
            </span>
          </div>
          <div className="incidentContainer-reassignIncident">
            <UserBadge
              size="large"
              showImage
              hoverName
              userId={supervisorId}
            />
            <img
              src={require('../../assets/images/reassignArrow.png')}
              className="reassignArrow"
              alt="assign arrow"
            />
            <UserBadge
              size="large"
              showImage
              hoverName
              userId={reassignUserId}
            />
          </div>
          <Dropdown
            options={userDropdown}
            currentValue={reassignUserId}
            handleChange={value =>
              this.setState({ reassignUserId: value.value })
            }
            placeholder={
              isSupervisor
                ? `${getUserInfo(supervisorId, users).firstName} ${
                    getUserInfo(supervisorId, users).lastName
                  }`
                : 'Select one..'
            }
          />
        </Modal>
        <Modal
          title="Authorization Notice"
          titleClassName="blueHeader"
          isOpen={openAuthorizationModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ openAuthorizationModal: false })
          }
          submitActions={() => this.handleSubmit()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Upon saving, this form will be removed from your Requires
              Authorization page.
            </span>
            If you need to see it later, it can be found in the Doc Repo section
            of the Employee Profile.
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeMyDoc: getActiveMyDocumentSelector(state),
  users: getCompanyUsersSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveMyDocument: payload => dispatch(setActiveMyDocument(payload)),
  createContinuous: payload => dispatch(createContinuousRequest(payload)),
  updateContinuous: payload => dispatch(updateContinuousRequest(payload)),
  deleteContinuous: payload => dispatch(deleteContinuousRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceContainer);
