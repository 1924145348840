import { ADD_MESSAGE, REMOVE_MESSAGE } from '../constants/actionTypes';

export const initialState = {
  message: {}
};

const messages = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_MESSAGE:
      return { ...state, message: { ...payload } };
    case REMOVE_MESSAGE:
      return { ...state, message: {} };
    default:
      return state;
  }
};

export default messages;
