import React, { Component } from 'react';
import { normalizePhone } from '../../utils/formValidation';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import Textbox from '../../components/inputs/Textbox';
import Checkbox from '../../components/inputs/Checkbox';
import UserAccessButtons from '../../components/inputs/UserAccessButtons';

class UserForm extends Component {
  state = {
    username: '',
    accessLevel: '',
    disableUserName: false,
    isDeactivated: false,
    _id: 0
  };

  resetState = () => {
    this.setState({
      username: '',
      accessLevel: '',
      disableUserName: false,
      email: '',
      phoneNumber: '',
      isDeactivated: false,
      _id: 0
    });
  };

  componentDidMount = () => {
    const { user } = this.props;

    if (user && user._id) {
      this.resetState();
      this.setState({
        username: user.username,
        accessLevel: user.accessLevel,
        isAccountOwner: user.isAccountOwner,
        phoneNumber: user.phoneNumber,
        email: user.email,
        disableUserName: true,
        _id: user._id,
        isDeactivated: user.isDeactivated
      });
    }
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.user &&
      JSON.stringify(this.props.user) !== JSON.stringify(prevProps.user)
    ) {
      this.setState({
        username: this.props.user.username,
        accessLevel: this.props.user.accessLevel,
        isAccountOwner: this.props.user.isAccountOwner,
        phoneNumber: this.props.user.phoneNumber,
        email: this.props.user.email,
        disableUserName: true,
        isDeactivated: this.props.user.isDeactivated
      });
    }
  };

  updateState = stateObject => {
    this.setState(stateObject);
  };

  handleSubmit = () => {
    this.props.updateActiveUser({
      ...this.state
    });
  };

  render() {
    const {
      accessLevel,
      username,
      oldAccessLevel,
      isAccountOwner,
      email,
      phoneNumber,
      disableUserName,
      _id,
      isDeactivated
    } = this.state;

    const {
      accountInfo,
      resetEmployeesPassword,
      resendWelcomeNotification
    } = this.props;

    return (
      <div className="addUserForm">
        <InputRow right>
          <Button
            text="Update"
            className={!username ? 'disabledBlueButton' : 'blueButton'}
            type="button"
            onClick={() => this.handleSubmit()}
            disabled={!username}
          />
          <Button
            text="Reset Password"
            className={!_id ? 'disabledBlueButton' : 'blueButton'}
            type="button"
            onClick={resetEmployeesPassword}
            disabled={!_id}
          />
          <Button
            text="Resend 'Welcome Notification' to User"
            className={!_id ? 'disabledBlueButton' : 'blueButton'}
            type="button"
            onClick={resendWelcomeNotification}
            disabled={!_id}
          />
        </InputRow>
        <div className="addUserForm-userInfo">
          <Textbox
            fieldLabel="User Name:"
            handleChange={e => this.updateState({ username: e.target.value })}
            currentValue={username}
            disabled={disableUserName}
          />
          <Textbox
            fieldLabel="Email:"
            handleChange={e => this.updateState({ email: e.target.value })}
            currentValue={email}
            placeholder="eg@email.com"
          />
          <Textbox
            fieldLabel="Phone:"
            handleChange={e =>
              this.updateState({ phoneNumber: normalizePhone(e.target.value) })
            }
            currentValue={phoneNumber}
            placeholder="(000) 000-0000"
          />
          <Checkbox
            fieldLabel="Deactivate User (user will not be able to log in)"
            onChange={v => {
              this.updateState({ isDeactivated: v });
            }}
            currentValue={isDeactivated}
          />
          {!isAccountOwner && (
            <UserAccessButtons
              handleButtonClick={value =>
                this.updateState({ accessLevel: value })
              }
              currentValue={accessLevel !== '' ? accessLevel : oldAccessLevel}
              accountInfo={accountInfo}
            />
          )}
        </div>
      </div>
    );
  }
}

export default UserForm;
