import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import history from '../../../history';
import { updateLeftNavRequest } from '../../../actions/navigation';
import {
  fetchCustomTrainingTemplatesRequest,
  setActiveCustomTrainingTemplate
} from '../../../actions/training';
import { getTemplateListSelector } from '../../../selectors/training';
import {
  filterApprovalExpires,
  sortDateMethod,
  filterPerformedDate
} from '../../../utils/sortFilterHelpers';
import Button from '../../../components/inputs/Button';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';

import styles from './styles.module.scss';

class TemplateList extends Component {
  state = {
    filters: []
  };

  componentDidMount() {
    this.props.updateLeftNav('Custom Safety Trainings');
    this.props.fetchCustomTemplates();
  }

  handleFilterChange = filters => {
    this.setState({ filters });
  };

  handleViewEdit = template => {
    this.props.setActiveTemplate(template);
    history.push('/app/training/customTrainingTemplate');
  };

  render() {
    const { templates } = this.props;
    const { filters } = this.state;

    const header = (
      <Header
        section="Training Templates"
        rightButtons={{
          text: 'Add Template',
          onClick: () => {
            this.props.setActiveTemplate({});
            history.push('/app/training/customTrainingTemplate');
          },
          className: 'blueButton',
          visible: true
        }}
      />
    );

    return (
      <HeaderAndFooter Header={header} className={styles.trainingTemplateList}>
        <div>
          <ReactTable
            data={templates}
            onFilteredChange={filtered => this.handleFilterChange(filtered)}
            filtered={filters}
            filterable
            resizable={false}
            noDataText="No results found"
            defaultPageSize={10}
            className="-striped -highlight"
            columns={[
              {
                Header: 'TITLE',
                accessor: d => d.title,
                id: 'title'
              },
              {
                Header: 'EXPIRES',
                id: 'doesExpire',
                Cell: ({ original }) => (original.doesExpire ? 'Yes' : 'No'),
                accessor: d => d.doesExpire,
                filterMethod: (filter, row) =>
                  filterApprovalExpires(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All'}
                  >
                    <option value="all">Show All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                )
              },
              {
                Header: 'NEEDS APPROVAL',
                id: 'needsApproval',
                Cell: ({ original }) => (original.needsApproval ? 'Yes' : 'No'),
                accessor: d => d.needsApproval,
                filterMethod: (filter, row) =>
                  filterApprovalExpires(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All'}
                  >
                    <option value="all">Show All</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                )
              },
              {
                Header: 'UPDATED DATE',
                accessor: 'updatedAt',
                Cell: ({ original }) =>
                  `${moment(Date.parse(original.updatedAt)).format(
                    'MM/DD/YYYY hh:mm a'
                  )}`,
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterPerformedDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Updated Today</option>
                    <option value="last7">Updated within Last Week</option>
                    <option value="last30">Updated This Month</option>
                    <option value="thisQuarter">Updated This Quarter</option>
                    <option value="thisYear">Updated This Year</option>
                  </select>
                )
              },
              {
                Header: 'VIEW/EDIT',
                Cell: ({ original }) => (
                  <Button
                    color="blue"
                    onClick={() => this.handleViewEdit(original)}
                    text=" View/Edit"
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
          />
        </div>
      </HeaderAndFooter>
    );
  }
}

const mapStateToProps = state => ({
  templates: getTemplateListSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: payload => dispatch(updateLeftNavRequest(payload)),
  fetchCustomTemplates: () => dispatch(fetchCustomTrainingTemplatesRequest()),
  setActiveTemplate: payload =>
    dispatch(setActiveCustomTrainingTemplate(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);
