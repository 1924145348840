import { createSelector } from 'reselect';

const incidentsSelector = state => state.incidents;

export const getIncidentsSelector = createSelector(
  incidentsSelector,
  incidents => incidents && incidents.list
);

export const getIncidentCircleSelector = createSelector(
  incidentsSelector,
  incidents => incidents && incidents.circles
);

export const getIncidentBasicsSelector = createSelector(
  incidentsSelector,
  basics => basics && basics.incidentBasics
);

export const getActiveIncidentSelector = createSelector(
  incidentsSelector,
  activeIncident => activeIncident && activeIncident.activeIncident
);

export const getIncidentAttachmentsSelector = createSelector(
  incidentsSelector,
  incident => incident && incident.attachments
);

export const getIncidentsLoadingSelector = createSelector(
  incidentsSelector,
  incidents => incidents && incidents.loading
);

export const getIncidentPhotoEvidenceSelector = createSelector(
  getIncidentAttachmentsSelector,
  attachment =>
    attachment && attachment.filter(attachment => attachment.isPhotoEvidence)
);

export const getNonPhotoEvidenceDiagramSelector = createSelector(
  getIncidentAttachmentsSelector,
  attachment =>
    attachment &&
    attachment.filter(
      attachment =>
        !attachment.isPhotoEvidence &&
        attachment.original_filename.indexOf('diagram') === -1
    )
);

export const getDiagramSelector = createSelector(
  getIncidentAttachmentsSelector,
  attachment =>
    attachment &&
    attachment.filter(
      attachment =>
        !attachment.isPhotoEvidence &&
        attachment.original_filename.indexOf('diagram') > -1
    )
);
