import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTable from 'react-table';

import history from '../../history';
import {
  fetchCustomDocumentTemplatesRequest,
  setActiveCustomDocumentTemplate
} from '../../actions/documents';
import { updateLeftNavRequest } from '../../actions/navigation';
import { getLoggedInUser } from '../../selectors/users';
import { getCustomDocumentTemplatesSelector } from '../../selectors/documents';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterHireDate,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import Header from '../../components/Header';
import Button from '../../components/inputs/Button';

import './index.css';

export class CustomDocumentList extends Component {
  componentDidMount() {
    this.props.updateLeftNav('Custom Document Templates');
    this.props.fetchCustomTemplates();
  }

  render() {
    const { templates, setActiveCustomTemplate } = this.props;

    return (
      <div className="customDocumentTemplateList">
        <Header
          title="Custom Document Templates"
          rightButtons={{
            text: 'Create Template',
            className: 'blueButton',
            onClick: () =>
              setActiveCustomTemplate({}) &&
              history.push('/app/customDocumentTemplateContainer')
          }}
        />
        <div className="customDocumentTemplateList-table">
          <ReactTable
            data={templates}
            filterable
            resizable={false}
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            columns={[
              {
                Header: 'TITLE',
                accessor: d => d.label,
                id: 'label'
              },
              {
                Header: 'CREATED DATE',
                accessor: 'createdDate',
                Cell: row => `${moment(row.createdAt).format('MM/DD/YYYY')}`,
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterHireDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="past7">Past 7 Days</option>
                    <option value="past30">Past 30 Days</option>
                    <option value="past60">Past 60 Days</option>
                    <option value="past90">Past 90 Days</option>
                    <option value="past120">More Than 90 Days</option>
                  </select>
                )
              },
              {
                Header: 'UPDATED DATE',
                accessor: 'updatedDate',
                Cell: row => `${moment(row.updatedAt).format('MM/DD/YYYY')}`,
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterHireDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="past7">Past 7 Days</option>
                    <option value="past30">Past 30 Days</option>
                    <option value="past60">Past 60 Days</option>
                    <option value="past90">Past 90 Days</option>
                    <option value="past120">More Than 90 Days</option>
                  </select>
                )
              },
              {
                Header: 'VIEW/EDIT',
                Cell: row => (
                  <Button
                    color="blue"
                    text="View/Edit"
                    onClick={() => {
                      setActiveCustomTemplate(row.original);
                      history.push('/app/customDocumentTemplateContainer');
                    }}
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
            noDataText="No results found"
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  templates: getCustomDocumentTemplatesSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation)),
  fetchCustomTemplates: () => dispatch(fetchCustomDocumentTemplatesRequest()),
  setActiveCustomTemplate: payload =>
    dispatch(setActiveCustomDocumentTemplate(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDocumentList);
