import { createSelector } from 'reselect';

const analyticsSelector = state => state.analytics;

export const getAnalyticsLoadingSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.loading
);

export const getNearMissByTypeSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.nearMissByType
);

export const getJsaClosedOneWeekSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.jsaClosedOneWeek
);

export const getTaskDataSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.taskData
);

export const getAccountabilityDocumentsSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.accountabilityDocuments
);

export const getNumberOfProactiveReportsSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.numberOfProactiveReports
);

export const getNumberOfSafetyTrainingsPerformedSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.numberOfSafetyTrainingsPerformed
);

export const getAverageIncidentLagTimeSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.averageIncidentLagTime
);

export const getReportCostsStatsSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.reportCostsStats
);

export const getObservationCostsStatsSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.observationCostsStats
);

export const getCorrectiveActionsByTypeSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.correctiveActionsByType
);

export const getDartSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.dart
);

export const getTcirSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.tcir
);

export const getLtirSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.ltir
);

export const getNumberOfFROIsSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.numberOfFROIs
);

export const getOshaCasesAverageLossTimeSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.oshaCasesAverageLossTime
);

export const getAllCasesAverageLossTimeSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.allCasesAverageLossTime
);

export const getTotalSafetyCostsSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.totalSafetyCosts
);

export const getOshaDataForIncidentsSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.oshaDataForIncidents
);

export const getTotalOshaInjurySelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.totalOshaInjury
);

export const getTopFactorsFROIAutoSelector = createSelector(
  analyticsSelector,
  analytics => analytics && analytics.topFactorsFROIAuto
);
