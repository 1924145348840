import {
  CREATE_OSHA_FORM_REQUEST,
  CREATE_OSHA_FORM_RESPONSE,
  CREATE_OSHA_LOG_REQUEST,
  CREATE_OSHA_LOG_RESPONSE,
  FETCH_OSHA_300A_RESPONSE,
  FETCH_OSHA_300LOGS_RESPONSE,
  FETCH_OSHA_301_RESPONSE,
  FETCH_OSHA_FORM_RESPONSE,
  FETCH_OSHA_LOGS_COMPANY_REQUEST,
  FETCH_OSHA_LOGS_LOCATION_REQUEST,
  PRINT_OSHA_REQUEST,
  PRINT_OSHA_RESPONSE,
  PRINT_SELECTED_OSHA_REQUEST,
  UPDATE_OSHA_EMPLOYEE_REQUEST,
  UPDATE_OSHA_FORM_REQUEST,
  UPDATE_OSHA_FORM_RESPONSE
} from '../constants/actionTypes';

export const createOshaFormRequest = payload => ({
  type: CREATE_OSHA_FORM_REQUEST,
  payload
});

export const createOshaFormResponse = payload => ({
  type: CREATE_OSHA_FORM_RESPONSE,
  payload
});

export const updateOshaFormRequest = payload => ({
  type: UPDATE_OSHA_FORM_REQUEST,
  payload
});

export const updateOshaFormResponse = payload => ({
  type: UPDATE_OSHA_FORM_RESPONSE,
  payload
});

export const fetchOshaLogsCompanyRequest = payload => ({
  type: FETCH_OSHA_LOGS_COMPANY_REQUEST,
  payload
});

export const fetchOshaLogLocationRequest = payload => ({
  type: FETCH_OSHA_LOGS_LOCATION_REQUEST,
  payload
});

export const fetchOsha300LogsResponse = payload => ({
  type: FETCH_OSHA_300LOGS_RESPONSE,
  payload
});

export const fetchOsha300AResponse = payload => ({
  type: FETCH_OSHA_300A_RESPONSE,
  payload
});

export const fetchOsha301Response = payload => ({
  type: FETCH_OSHA_301_RESPONSE,
  payload
});

export const fetchOshaFormResponse = payload => ({
  type: FETCH_OSHA_FORM_RESPONSE,
  payload
});

export const createOshaLogRequest = payload => ({
  type: CREATE_OSHA_LOG_REQUEST,
  payload
});

export const createOshaLogResponse = payload => ({
  type: CREATE_OSHA_LOG_RESPONSE,
  payload
});

export const printOshaRequest = payload => ({
  type: PRINT_OSHA_REQUEST,
  payload
});

export const printOshaResponse = payload => ({
  type: PRINT_OSHA_RESPONSE,
  payload
});

export const updateOshaEmployeeRequest = payload => ({
  type: UPDATE_OSHA_EMPLOYEE_REQUEST,
  payload
});

export const printSelectedOshaRequest = payload => ({
  type: PRINT_SELECTED_OSHA_REQUEST,
  payload
});
