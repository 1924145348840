import React from 'react';
import moment from 'moment';
import UserBadge from '../../UserBadge';

import styles from './styles.module.scss';

const getCorrectiveActionHeaders = task => {
  if (!task)
    return {
      top: 'NO CORRECTIVE ACTION',
      style: 'blueOutline'
    };

  const isPastDue = moment().isAfter(Date.parse(task.dueDate));

  if (task.currentWfStatus === 5) {
    return {
      top: 'Corrective Action Completed',
      style: 'greenOutline'
    };
  } else if (isPastDue) {
    return {
      top: 'Corrective Action Past Due',
      style: 'redOutline'
    };
  } else {
    return {
      top: 'Corrective Action Assigned',
      style: 'blueOutline'
    };
  }
};

export default function AssignCorrectiveActionButton({
  task,
  setActiveTask,
  ownerId,
  ownerType,
  label,
  description,
  groupId
}) {
  const header = getCorrectiveActionHeaders(task);
  return (
    <>
      <div
        className={`${styles.assignCorrectiveActionButton} ${
          styles[header.style]
        }`}
        onClick={() =>
          task
            ? setActiveTask({
                ...task,
                isFromRa: true
              })
            : setActiveTask({
                label,
                description,
                isFromRa: true,
                ownerId,
                ownerType,
                currentReason: 2,
                currentCategory: 1,
                groupId
              })
        }
      >
        {task?.assignedTo?.[0] && (
          <UserBadge
            size="medium"
            userId={task.assignedTo[0]}
            showImage
          />
        )}
        <div>
          <div
            className={`${styles.assignCorrectiveActionButtonText}`}
          >
            {header.top}
          </div>
          <div
            className={`${styles.assignCorrectiveActionButtonText} ${styles.notBold}`}
          >
            {task?.assignedTo?.[0] ? (
              <UserBadge
                userId={task.assignedTo[0]}
                showName
              />
            ) : (
              'Assign Corrective Action'
            )}
          </div>
          <div
            className={`${styles.assignCorrectiveActionButtonText} ${styles.notBold}`}
          >
            {task?.dueDate ? (
              moment(task.dueDate).format('MM/DD/YYYY hh:mm A')
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
