export const getTaskChartData = data => [
  {
    name: 'Closed',
    'On Time': data.tasksClosedOnTime,
    'Past Due': data.tasksClosedAfterDueDate
  },
  { name: 'Open', 'On Time': data.onGoingTasks, 'Past Due': data.tasksDue }
];

export const getCorrectActionData = data => {
  let caData = [];

  if (data.topFiveReportTypes) {
    data.topFiveReportTypes.map(reportType =>
      caData.push({
        name: reportType.reportType,
        '': reportType.numberOfOriginatingReports
      })
    );
  }

  return caData;
};

export const getOshaRecordableType = data => {
  let orData = [];

  orData.push({
    name: 'Injury',
    '': data.totalInjuries
  });

  orData.push({
    name: 'Skin Disorder',
    '': data.totalSkinDisorders
  });

  orData.push({
    name: 'Respitory Conditions',
    '': data.totalRespiratoryConditions
  });

  orData.push({
    name: 'Poison',
    '': data.totalPoisonings
  });

  orData.push({
    name: 'Hearing Loss',
    '': data.totalHearingLoss
  });

  orData.push({
    name: 'Other',
    '': data.totalOtherIllnesses
  });

  return orData;
};

export const getNearMissData = nearMissByType => [
  {
    name: 'Conditons',
    '': nearMissByType.unsafeConditions
  },
  {
    name: 'Personal Acts',
    '': nearMissByType.unsafePersonalActs
  },
  {
    name: 'Equipment',
    '': nearMissByType.unsafeEquipment
  },
  {
    name: 'Use of Equip.',
    '': nearMissByType.unsafeUseOfEquipment
  },
  {
    name: 'Not Specified',
    '': nearMissByType.notSpecified
  }
];

export const totalIncidentOshaData = (oshaIncidentData, divisionEstInfo) => [
  {
    name: 'Total',
    '': oshaIncidentData.totalFROIs
  },
  {
    name: divisionEstInfo.reportingType === 2 ? 'PERRP' : 'OSHA',
    '': oshaIncidentData.totalOshaRecordable
  },
  {
    name: 'Lost Time',
    '': oshaIncidentData.totalOshaWithLostTime
  },
  {
    name: 'Job Rest.',
    '': oshaIncidentData.totalOshaWithJobRestriction
  }
];

export const getTopFactorsData = data => {
  let caData = [];

  data.map(reportType =>
    caData.push({
      name: reportType.factorName,
      '': reportType.numberOfFactors
    })
  );

  return caData;
};

export const inspectionsForGroupData = inspectionData => {
  let caData = [];

  inspectionData.map(reportType =>
    caData.push({
      name: reportType.groupName,
      '': parseFloat(reportType.numberOfObservations, 10) * 100
    })
  );

  return caData;
};
