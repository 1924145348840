import { call, put, select } from 'redux-saga/effects';
import { addMessage, removeMessage } from '../actions/messages';
import {
  chargeCustomerResponse,
  createCustomerResponse,
  fetchAccountInformationResponse,
  updateCustomerResponse
} from '../actions/payment';
import { getActiveCompany } from '../selectors/company';

export function* fetchAccountInformation(api) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    const response = yield call(api.fetchAccountInformation, activeCompany._id);

    yield put(fetchAccountInformationResponse(response));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* createCustomer(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    const response = yield call(api.createCustomer, activeCompany._id, payload);

    yield put(createCustomerResponse(response));
    yield put(addMessage({ error: false, message: 'Card Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e.message }));
  }
}

export function* updateCustomer(api, { payload }) {
  try {
    yield put(removeMessage());

    const activeCompany = yield select(getActiveCompany);

    const response = yield call(api.updateCustomer, activeCompany._id, payload);
    const accountResponse = yield call(
      api.fetchAccountInformation,
      activeCompany._id
    );

    yield put(updateCustomerResponse(response));
    yield put(fetchAccountInformationResponse(accountResponse));
    yield put(addMessage({ error: false, message: 'Card Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* chargeCustomer(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    const paymentResponse = yield call(api.chargeCustomer, activeCompany._id, {
      numberOfSupervisorLicenses: payload.numberOfSupervisorLicenses,
      numberOfCollaboratorAdminLicenses:
        payload.numberOfCollaboratorAdminLicenses,
      promoCode: payload.promoCode
    });

    const response = yield call(api.fetchAccountInformation, activeCompany._id);

    yield put(chargeCustomerResponse(paymentResponse));
    yield put(fetchAccountInformationResponse(response));
    yield put(
      addMessage({ error: false, message: 'Card Charged Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e.message }));
  }
}
