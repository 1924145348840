export const getAttachmentSelection = attachment => {
  const attachmentName = attachment.original_filename.split('.');
  switch (attachmentName[attachmentName.length - 1]) {
    case 'doc':
      return require(`../assets/images/attachmentTypes/DOC.png`);

    case 'docx':
      return require('../assets/images/attachmentTypes/DOCX.png');

    case 'mp4':
    case 'mov':
      return require('../assets/images/attachmentTypes/MP4.png');

    case 'mpeg':
      return require('../assets/images/attachmentTypes/MPEG.png');

    case 'mpg':
      return require('../assets/images/attachmentTypes/MPG.png');

    case 'pdf':
      return require('../assets/images/attachmentTypes/PDF.png');

    case 'ppt':
      return require('../assets/images/attachmentTypes/PPT.png');

    case 'pptx':
      return require('../assets/images/attachmentTypes/PPTX.png');

    case 'rtf':
      return require('../assets/images/attachmentTypes/RTF.png');

    case 'xls':
      return require('../assets/images/attachmentTypes/XLS.png');

    case 'xlsx':
      return require('../assets/images/attachmentTypes/XLSX.png');

    default:
      return require('../assets/images/attachmentTypes/TXT.png');
  }
};

export const isMovie = attachment => {
  const attachmentName = attachment.original_filename.split('.');
  return ['mp4', 'mpeg', 'mpg', 'mov'].includes(
    attachmentName[attachmentName.length - 1]
  );
};

export const isMicrosoft = attachment => {
  const attachmentName = attachment.original_filename.split('.');
  return ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt'].includes(
    attachmentName[attachmentName.length - 1]
  );
};

export const isYoutube = attachment =>
  attachment?.source_url?.includes('youtube');
