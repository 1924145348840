import { call, put, select } from 'redux-saga/effects';
import history from '../history';
import { searchResponse } from '../actions/search';
import { getActiveCompany } from '../selectors/company';

export function* searchByTerms(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    let response = yield call(
      api.searchItems,
      activeCompany._id,
      payload.searchTerm,
      'All'
    );

    let incidents = response.find(res => res.searchType === 'Incidents');

    if (incidents) {
      const incidentBasics = yield call(api.fetchIncidentBasics, payload);

      incidents = incidents.searchItems.map(incident => {
        return {
          ...incident,
          severityRating: incident.answers
            ? incident.answers[incidentBasics[0].fields[8]._id]
            : 1,
          projectId: incident.answers
            ? incident.answers[incidentBasics[0].fields[4]._id]
            : '',
          projectName: incident.answers
            ? incident.answers[incidentBasics[0].fields[5]._id]
            : '',
          jobLocation: incident.answers
            ? incident.answers[incidentBasics[0].fields[6]._id]
            : '',
          description: incident.answers
            ? incident.answers[incidentBasics[0].fields[0]._id]
            : ''
        };
      });

      response[0].searchItems = incidents;
    }

    response.maxLength =
      payload.searchType === 'All'
        ? 4
        : response.length > 0
        ? response[0].searchItems.length
        : 4;

    response.searchType = payload.searchType;
    response.searchTerm = payload.searchTerm;

    yield put(searchResponse(response));
    history.push('/app/searchPage');
  } catch (e) {
    console.log(e);
  }
}
