import moment from 'moment';

export function inflateChartSettings(settings, period, isDetail = false) {
  const { datasource, category, filterArgs, yAxis } = settings;
  let ret = { datasource, category, filterArgs, yAxis };
  if (!isDetail && settings.type === 'bar') ret.series = settings.series;

  switch (period?.mode) {
    case 'day':
      ret.startTime = moment().startOf('day');
      break;
    case 'week':
      ret.startTime = moment().startOf('week');
      break;
    case 'month':
      ret.startTime = moment().startOf('month');
      break;
    case 'quarter':
      ret.startTime = moment().startOf('quarter');
      break;
    case 'year':
      ret.startTime = moment().startOf('year');
      break;
    case 'absolute':
      ret.startTime = period.startDate;
      ret.endTime = moment(period.endDate).startOf('day').subtract(1, 'days');
      break;
    case 'relative':
      ret.startTime = moment().subtract(period.number, period.unit);
      break;
    default:
      break;
  }

  if (settings.type === 'line' && !isDetail) ret.frequency = period?.frequency || 'monthly';
  return ret;
}
