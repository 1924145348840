import React from 'react';
import './svg.css';

export default () => (
  <svg width="30px" height="25px" viewBox="0 0 67 67">
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5008 4V29.4424L11.2365 14.0897C16.236 8.33729 23.4232 4.54108 31.5008 4ZM35.5008 4.00016V33.4668V34.164L35.0674 34.7101L16.7552 57.7846C21.512 61.0737 27.2811 63 33.4996 63C49.7918 63 62.9992 49.7775 62.9992 33.4666C62.9992 17.8291 50.8597 5.0302 35.5008 4.00016ZM13.6262 55.2926L30.6626 33.8257L8.82213 17.2788C5.77339 21.9277 4 27.4898 4 33.4666C4 42.113 7.71136 49.8915 13.6262 55.2926Z" />
    </g>
  </svg>
);
