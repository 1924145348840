import React from 'react';
import Button from '../Button';

export default function AuthorizeRejectButtons({
  answer,
  updateAnswer,
  className
}) {
  return (
    <div className={className}>
      {answer === 'approve' ? (
        <Button
          text="Approve"
          color="blue"
          image={require('../../../assets/images/checkmark.png')}
        />
      ) : (
        <Button
          text="Approve"
          onClick={() => updateAnswer('approve')}
          color={answer ? 'blueOutline' : 'blue'}
        />
      )}
      {answer === 'reject' ? (
        <Button
          text="Reject"
          color="red"
          image={require('../../../assets/images/checkmark.png')}
        />
      ) : (
        <Button
          text="Reject"
          onClick={() => updateAnswer('reject')}
          color={answer ? 'redOutline' : 'red'}
        />
      )}
    </div>
  );
}
