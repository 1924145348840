import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { setActiveCompany } from '../../actions/company';
import { searchRequest } from '../../actions/search';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import logoImage from './logo.png';
import Button from '../../components/inputs/Button';
import SearchBar from '../../components/inputs/SearchBar';

import styles from './styles.module.scss';

export class TopHeader extends Component {
  getButtonText = () => {
    const { activeCompany, activeGroup, activeProject } = this.props;
    let buttonText = '';

    if (activeProject) {
      buttonText = `${activeCompany.name}: ${activeGroup.name}: ${activeProject.name}`;
    } else if (activeGroup) {
      buttonText = `${activeCompany.name}: ${activeGroup.name}`;
    } else if (activeCompany) {
      buttonText = `${activeCompany.name}`;
    }

    return buttonText;
  };

  render() {
    const { activeCompany, setActiveCompany, searchByTerms } = this.props;

    return (
      <header className={styles.topNav}>
        <div className={styles.leftSide}>
          <div
            className={styles.logoContainer}
            onClick={() => setActiveCompany() && history.push('/home')}
          >
            <img src={logoImage} alt="iReportSource logo" />
          </div>
          {activeCompany._id && (
            <div className={styles.locationContainer}>
              <div className={styles.locationContainerText}> Location:</div>
              <Button
                text={this.getButtonText()}
                className="locationButton"
                onClick={() => setActiveCompany() && history.push('/home')}
              />
            </div>
          )}
        </div>
        {activeCompany._id && (
          <div className={styles.rightSide}>
            <SearchBar searchByTerms={searchByTerms} />
          </div>
        )}
      </header>
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  activeGroup: getActiveLocationId(state),
  activeProject: getActiveProject(state)
});

export const matchDispatchToProps = dispatch => ({
  setActiveCompany: () => dispatch(setActiveCompany({})),
  searchByTerms: values => dispatch(searchRequest(values))
});

export default connect(mapStateToProps, matchDispatchToProps)(TopHeader);
