import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  addUserTaskRequest,
  deleteUserTaskRequest,
  updateUserTaskRequst
} from '../../actions/tasks';
import { TASK_STATUS_DROPDOWN } from '../../constants/constants';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import { getActiveIncidentSelector } from '../../selectors/incidents';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import { getActiveTaskSelector } from '../../selectors/tasks';
import { getLoggedInUser } from '../../selectors/users';
import AssignTaskCard from '../../components/taskCards/AssignTaskCard';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import StageSelector from '../../components/StageSelector';

export class TaskContainer extends Component {
  state = {
    locationId: '',
    projectId: '',
    label: '',
    description: '',
    notes: '',
    currentPriority: '',
    currentCategory: '',
    currentReason: '',
    dueDate: '',
    assignedTo: '',
    repeatingTask: '',
    repeatingOption: [],
    recurringTime: '',
    repeatingOn: [],
    isEditing: false,
    currentWfStatus: '',
    hasUnsavedChanges: false,
    isCreating: false,
    openUnsavedChangesModal: false,
    openDeleteTaskModal: false,
    openEndRepeatingTask: false,
    openCreateRepeatingTask: false,
    isFromRa: false,
    isFromReport: false,
    isForIncident: false,
    isIndividual: false,
    frequency: ''
  };

  componentDidMount() {
    this.props.updateLeftNav('Tasks');

    if (this.props.activeTask) {
      const repeatingTask =
        this.props.activeTask.schedule === 'repeating'
          ? { value: 1, label: 'Repeating' }
          : { value: 0, label: 'Immediately' };

      this.setState({
        ...this.props.activeTask,
        locationId: this.props.activeTask.groupId,
        isIndividual: this.props.activeTask.isIndividual,
        repeatingTask,
        repeatingOption: this.props.activeTask.dueBy,
        recurringTime: this.props.activeTask.dueByTime
      });

      if (this.props.activeTask.isFromRa && !this.props.activeTask._id) {
        this.setState({ isCreating: true, isFromRa: true });
      } else if (this.props.activeTask.isFromRa && this.props.activeTask._id) {
        this.setState({ isEditing: true, isFromRa: true });
      } else if (this.props.activeTask.isFromSW && !this.props.activeTask._id) {
        this.setState({
          isCreating: true,
          isFromRa: true,
          locationId: this.props.activeTask.groupId
        });
      } else if (this.props.activeTask.isFromSW && this.props.activeTask._id) {
        this.setState({ isEditing: true, isFromRa: true, isCreating: false });
      } else if (
        this.props.activeTask.isFromReport &&
        !this.props.activeTask._id
      ) {
        this.setState({
          isCreating: true,
          locationId: this.props.activeIncident.locationId,
          isFromReport: true
        });
      } else if (
        this.props.activeTask.isFromReport &&
        this.props.activeTask._id
      ) {
        this.setState({
          isEditing: true,
          locationId: this.props.activeTask.groupId,
          isFromReport: true,
          isCreating: false
        });
      } else if (
        this.props.activeTask.isForIncident &&
        !this.props.activeTask._id
      ) {
        this.setState({
          isCreating: true,
          locationId: this.props.activeTask.groupId,
          isForIncident: true
        });
      } else if (
        this.props.activeTask.isForIncident &&
        this.props.activeTask._id
      ) {
        this.setState({
          isEditing: true,
          locationId: this.props.activeTask.groupId,
          isForIncident: true
        });
      } else if (this.props.activeTask._id) {
        this.setState({
          locationId: this.props.activeTask.groupId
        });
      } else {
        this.setState({
          ...this.props.activeTask,
          isCreating: true,
          locationId: this.props.activeLocation
            ? this.props.activeLocation._id
            : '',
          projectId: this.props.activeProject
            ? this.props.activeProject._id
            : ''
        });
      }
    } else {
      this.setState({
        isCreating: true,
        locationId: this.props.activeLocation
          ? this.props.activeLocation._id
          : '',
        projectId: this.props.activeProject ? this.props.activeProject._id : ''
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeTask &&
      JSON.stringify(this.props.activeTask) !==
        JSON.stringify(prevProps.activeTask)
    ) {
      this.setState({ ...this.props.activeTask });
    }
  }

  handleAnswer = stateObject => {
    this.setState({
      ...stateObject,
      hasUnsavedChanges: true
    });
  };

  canSubmit = () => {
    const {
      locationId,
      label,
      description,
      dueDate,
      assignedTo,
      currentCategory,
      currentPriority,
      currentReason,
      repeatingTask,
      repeatingOption,
      recurringTime,
      repeatingOn,
      frequency
    } = this.state;

    if (
      (locationId || this.props.activeLocation._id) &&
      label &&
      description &&
      assignedTo.length > 0 &&
      currentCategory &&
      currentPriority &&
      currentReason &&
      ((repeatingTask.value === 0 && dueDate) ||
        (repeatingTask.value === 1 &&
          frequency &&
          repeatingOption &&
          recurringTime &&
          (repeatingOn.length > 0 || repeatingOn.value)))
    ) {
      return true;
    }

    return false;
  };

  handleTaskSubmit = async () => {
    if (this.state.isCreating) {
      await this.props.addUserTask(this.state);

      if (this.state.repeatingTask.value === 1) {
        this.setState({
          isCreating: false,
          hasUnsavedChanges: false,
          openCreateRepeatingTask: true
        });
      } else {
        this.setState({ isCreating: false, hasUnsavedChanges: false });
      }
    }

    if (this.state.isEditing) {
      await this.props.updateUserTask(this.state);
      this.setState({ isEditing: false, hasUnsavedChanges: false });
    }
  };

  handleDeleteTask = async () => {
    await this.props.deleteUserTask(this.state);
  };

  updateTaskStage = value => {
    this.props.updateUserTask({
      ...this.state,
      currentWfStatus: value.value
    });
  };

  render() {
    const {
      employeeList,
      activeCompany,
      activeLocation,
      activeProject,
      activeTask,
      loggedInUser,
      activeIncident,
      match
    } = this.props;

    const {
      locationId,
      label,
      description,
      dueDate,
      assignedTo,
      currentCategory,
      currentPriority,
      currentReason,
      projectId,
      notes,
      currentWfStatus,
      repeatingTask,
      repeatingOption,
      recurringTime,
      repeatingOn,
      isEditing,
      hasUnsavedChanges,
      isCreating,
      openDeleteTaskModal,
      openUnsavedChangesModal,
      openEndRepeatingTask,
      openCreateRepeatingTask,
      isFromRa,
      isFromReport,
      isForIncident,
      isIndividual,
      frequency,
      backToDashboard
    } = this.state;

    const cantEditAll =
      ['500', '900'].indexOf(loggedInUser.accessLevel) === -1 &&
      activeTask.createdBy !== loggedInUser._id;

    const header = (
      <Header
        title="Tasks"
        section={
          !isEditing && !isCreating
            ? 'View Active'
            : isCreating
            ? 'Create Task'
            : 'Edit Active'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          backToDashboard ? history.push('/app/dashboard') : history.goBack()
        }
        center={
          (!isCreating || (isEditing && !cantEditAll)) && (
            <StageSelector
              label="Task Stage"
              options={TASK_STATUS_DROPDOWN}
              currentValue={currentWfStatus}
              onChange={this.updateTaskStage}
            />
          )
        }
        rightButtons={{
          visible: !isCreating,
          text:
            !isEditing && !cantEditAll
              ? 'Edit Task'
              : !isEditing
              ? 'Edit Notes'
              : 'Delete Task',
          color: isEditing ? 'red' : 'blue',
          onClick: () =>
            isEditing
              ? this.setState({ openDeleteTaskModal: true })
              : this.setState({ isEditing: true })
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonClick={this.handleTaskSubmit}
        saveButtonDisabled={isCreating ? !this.canSubmit() : !hasUnsavedChanges}
        cancelButtonClick={() =>
          isEditing && hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : isEditing
            ? this.setState({ isEditing: false })
            : backToDashboard
            ? history.push('/app/dashboard')
            : history.goBack()
        }
        editing={isEditing}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={(isCreating || isEditing) && footer}
        >
          <AssignTaskCard
            employees={employeeList}
            activeLocation={activeLocation}
            activeProject={activeProject}
            groupList={activeCompany.locations}
            handleAnswer={this.handleAnswer}
            locationId={locationId}
            label={label}
            description={description}
            dueDate={dueDate}
            assignedTo={assignedTo}
            currentCategory={currentCategory}
            currentPriority={currentPriority}
            currentReason={currentReason}
            projectId={projectId}
            notes={notes}
            repeatingTask={repeatingTask}
            repeatingOption={repeatingOption}
            recurringTime={recurringTime}
            task={activeTask}
            disabled={!isEditing && !isCreating}
            isFromRa={isFromRa}
            isFromReport={isFromReport}
            isForIncident={isForIncident}
            activeIncident={activeIncident}
            isIndividual={isIndividual}
            isEditing={isEditing}
            repeatingOn={repeatingOn}
            isCreatedBy={
              activeTask && activeTask.createdBy === loggedInUser._id
            }
            frequency={frequency}
            match={match}
          />
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() =>
            backToDashboard ? history.push('/app/dashboard') : history.goBack()
          }
          savingWhat="a page"
        />
        <Modal
          title="Delete Task"
          titleClassName="redHeader"
          isOpen={openDeleteTaskModal}
          submitButtonColor="red"
          submitButtonText="Delete Task"
          onRequestClose={() => this.setState({ openDeleteTaskModal: false })}
          submitActions={() => this.handleDeleteTask()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting a Task is permanent. No one will be able to view or edit
              this specific Task again.{' '}
            </span>
            A new Task that is similar or identical can still be created.
          </div>
        </Modal>
        <Modal
          title="End Repeating Task"
          titleClassName="redHeader"
          isOpen={openEndRepeatingTask}
          submitButtonColor="red"
          submitButtonText="Delete Task"
          onRequestClose={() => this.setState({ openEndRepeatingTask: false })}
          submitActions={() => this.handleDeleteTask()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Ending a Repeating Task will remove it from the Repeating section
              of the Task page.{' '}
            </span>
            Tasks that are already Active will not be affected, but no more will
            be created by this Repeating Task.
          </div>
        </Modal>
        <Modal
          title="Repeating Task"
          titleClassName="blueHeader"
          isOpen={openCreateRepeatingTask}
          onRequestClose={() =>
            backToDashboard ? history.push('/app/dashboard') : history.goBack()
          }
          hideButtons={true}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            A Repeating Task has been created.{' '}
            <span
              style={{
                fontWeight: 'bold',
                fontStyle: 'italic'
              }}
            >
              It can be found in the Repeating section of the Tasks page.
              Editing or ending the a Repeating Task will only affect future
              Tasks, and will not alter any Tasks that are already Active
            </span>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  employeeList: getCompanyUsersSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  activeTask: getActiveTaskSelector(state),
  activeIncident: getActiveIncidentSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation)),
  addUserTask: task => dispatch(addUserTaskRequest(task)),
  updateUserTask: task => dispatch(updateUserTaskRequst(task)),
  deleteUserTask: task => dispatch(deleteUserTaskRequest(task))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskContainer);
