import React, { Component } from 'react';

import { classnames } from '../../../utils/classNamesHelper';
import { Input, InputRow, nameInput } from '../';
import Textbox from '../Textbox';
import Button from '../Button';

import styles from './dropdown.module.scss';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.searchRef = React.createRef();
    this.state = {
      showOptions: false,
      searchText: ''
    };
  }

  handleSelectionChange(e, selection) {
    let sValue = selection.value;
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();

    if (!this.props.multi) {
      this.setState({ showOptions: false });
      this.props.handleChange(this.props.bareValues ? sValue : selection);
      return;
    }

    let values = [...this.props.currentValue];
    let found = false;
    values = values.reduce((acc, cur) => {
      let cValue = cur?.value ?? cur;
      if (cValue === sValue) {
        found = true;
      } else {
        acc.push(cValue);
      }
      return acc;
    }, []);

    if (!found) {
      values.push(sValue);
    }

    if (!this.props.bareValues) {
      values = values.reduce((acc, cur) => {
        const v = this.props.options.find(o => o.value === cur);
        if (v) acc.push(v)
        return acc;
      }, []);
    }

    this.props.handleChange(values);
  }

  clearAll = () => {
    this.props.handleChange([]);
  };

  selectAll = () => {
    this.props.handleChange([...this.props.options]);
  };

  getSelectionNames = () => {
    let { currentValue, options, multi } = this.props;
    if (currentValue === null || currentValue === undefined || !options)
      return '';

    if (!Array.isArray(currentValue)) {
      currentValue = [currentValue];
    }
    if (!multi) {
      currentValue = currentValue.slice(0, 1);
    }

    const labels = options
      .filter(
        option =>
          currentValue.findIndex(v => (v?.value ?? v) === option.value) > -1
      )
      .map(option => option.label);

    return labels.join(', ');
  };

  stopIt = e => {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
  };

  handleSearchInput = e => {
    this.stopIt(e);
    if (this.searchRef?.current?.focus) this.searchRef.current.focus();
    this.setState({ searchText: '' });
  };

  render() {
    let {
      name,
      options,
      currentValue,
      placeholder,
      disabled,
      searchable,
      selectButtons,
      multi,
      ...props
    } = this.props;

    if (!multi) selectButtons = false;

    const { showOptions, searchText } = this.state;
    if (!Array.isArray(options)) {
      options = options ? [options] : [];
    }
    if (!Array.isArray(currentValue)) {
      currentValue =
        currentValue !== null && currentValue !== undefined
          ? [currentValue]
          : [];
    }
    currentValue = currentValue.map(o => o?.value ?? o);
    if (searchText) {
      options = options.filter(
        o => o.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      );
    }

    if (!multi) currentValue = currentValue.slice(0, 1);

    let ulStyles = [
      disabled && styles.disabled,
      showOptions && styles.showOptions,
      styles.multiSelect,
      this.props.className
    ];

    return (
      <Input name={name} disabled={disabled} {...props}>
        <ul
          className={classnames(ulStyles)}
          onBlur={() => this.setState({ showOptions: false })}
          onFocus={() => !disabled && this.setState({ showOptions: true })}
          tabIndex={0}
        >
          <li
            className={styles.select}
            onMouseDown={() =>
              this.setState(state => ({
                showOptions: disabled ? false : !state.showOptions
              }))
            }
          >
            <span
              className={currentValue.length === 0 ? styles.placeHolder : ''}
            >
              {currentValue.length > 0
                ? this.getSelectionNames()
                : placeholder || 'Select an option'}
            </span>
            <div className={styles.right}>
              <img
                src={require('../../../assets/images/downChevron.png')}
                alt={showOptions ? 'multiselect open' : 'multiselect closed'}
              />
            </div>
          </li>
          <li className={styles.wrapper}>
            <ul>
              {searchable && (
                <li>
                  <InputRow className={styles.row}>
                    <Textbox
                      currentValue={searchText}
                      handleChange={e =>
                        this.setState({ searchText: e.target.value })
                      }
                      placeholder="Type to Search..."
                      onMouseDown={this.handleSearchInput}
                      name={name}
                      className={styles.grow}
                      inputRef={this.searchRef}
                    />
                    {selectButtons && (
                      <>
                        <Button
                          text="Select All"
                          color="blue"
                          onMouseDown={this.stopIt}
                          onClick={this.selectAll}
                          className={styles.button}
                        />
                        <Button
                          text="Clear"
                          color="white"
                          onMouseDown={this.stopIt}
                          onClick={this.clearAll}
                          className={styles.button}
                        />
                      </>
                    )}
                  </InputRow>
                </li>
              )}
              {options.map((option, index) => (
                <li
                  key={index}
                  onMouseDown={e => this.handleSelectionChange(e, option)}
                  className={`${styles.options} ${
                    currentValue.indexOf(option.value) > -1
                      ? styles.optionSelected
                      : ''
                  }`}
                >
                  <div className={styles.text}>{option.label}</div>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </Input>
    );
  }
}

export default nameInput(Dropdown);
