import { select, put, call, all } from 'redux-saga/effects';
import history from '../history';
import { addUploadedAttachmentsResponse } from '../actions/attachments';
import { addMessage, removeMessage } from '../actions/messages';
import {
  fetchCustomTrainingTemplatesResponse,
  fetchTrainingsResponse,
  setActiveTrainingResponse
} from '../actions/training';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';

export function* fetchCustomTemplates(api) {
  try {
    const company = yield select(getActiveCompany);

    const response = yield call(api.fetchCustomTrainingTemplates, {
      companyId: company._id
    });

    const attachments = yield all(
      response.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          company._id,
          document._id,
          'TrainingTemplate'
        )
      )
    );

    response.map((res, index) => (res.attachments = attachments[index]));

    yield put(fetchCustomTrainingTemplatesResponse(response));
  } catch (error) {
    console.log(error);
  }
}

export function* addCustomTemplate(api, { payload }) {
  try {
    yield put(removeMessage());
    const company = yield select(getActiveCompany);
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    payload = {
      ...payload,
      companyId: company._id,
      expiresAfterOption: payload.expires ? payload.expiresAfterOption : {},
      approver: payload?.approver?.value
    };

    const response = yield call(api.addCustomTrainingTemplate, payload);

    yield all(
      addedAttachments.map(attachment =>
        call(api.updateAttachment, {
          ...attachment,
          ownerId: response._id
        })
      )
    );

    yield put(addUploadedAttachmentsResponse([]));
    history.push('/app/training/templateList');
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* updateCustomTemplate(api, { payload }) {
  try {
    yield put(removeMessage());
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    payload = {
      ...payload,
      approver: payload?.approver?.value
    };

    const response = yield call(api.updateCustomTrainingTemplate, payload);

    yield all(
      addedAttachments.map(attachment =>
        call(api.updateAttachment, {
          ...attachment,
          ownerId: response._id
        })
      )
    );

    yield put(addUploadedAttachmentsResponse([]));
    history.push('/app/training/templateList');
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* deleteCustomTemplate(api, { payload }) {
  try {
    yield call(api.deleteCustomTrainingTemplate, payload);

    history.push('/app/training/templateList');
  } catch (error) {
    console.log(error);
  }
}

export function* fetchTrainings(api) {
  try {
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let response = project
      ? yield call(api.fetchTrainingsByProject, {
          companyId: company._id,
          projectId: project._id
        })
      : group
      ? yield call(api.fetchTrainingsByGroup, {
          companyId: company._id,
          groupId: group._id
        })
      : yield call(api.fetchTrainings, {
          companyId: company._id
        });

    const training = response.filter(training => !training.legacy);
    const legacyTrainings = response.filter(training => training.legacy);

    const attachments = yield all(
      legacyTrainings.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          company._id,
          document.masterTemplateId,
          'documents'
        )
      )
    );

    legacyTrainings.map((res, index) => (res.attachments = attachments[index]));

    yield put(fetchTrainingsResponse([...training, ...legacyTrainings]));
  } catch (error) {
    console.log(error);
  }
}

export function* addTraining(api, { payload }) {
  try {
    const isFromEmployee = payload.isFromEmployee;
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const groupIds = payload.groupIds.map(group => group.value);
    const projectIds = payload.projectIds.map(project => project.value);

    let addedAttachments = yield select(getAddedAttachmentsSelector);

    const repeatingOn = Array.isArray(payload.repeatingOn)
      ? payload.repeatingOn
      : [payload.repeatingOn.value];

    if (payload.youtubeLinks?.length) {
      const youtubeAttachments = payload.youtubeLinks
        .filter(link => link.title)
        .map(link => {
          let url;
          try {
            url = new URL(link.link);
          } catch (e) {
            throw new Error(`Invalid YouTube Link ${link.link}`);
          }
          let videoId = url.searchParams.get('v');
          if (videoId) {
            /* Already have it */
          } else if (url.host === 'youtu.be') {
            videoId = url.pathname.substring(1);
          } else if (url.pathname.startsWith('/embed/')) {
            videoId = url.pathname.replace('/embed/', '');
          } else {
            throw new Error(`Invalid YouTube Link ${link.link}`);
          }
          return {
            source_url: `https://www.youtube.com/embed/${videoId}`,
            original_filename: link.title
          };
        });

      addedAttachments = [...addedAttachments, ...youtubeAttachments];
    }

    payload = {
      ...payload,
      companyId: company._id,
      assignedTo: payload.assignedTo.map(user => user.value),
      approver: payload?.approver?.value,
      repeatingOn,
      attachments: [...addedAttachments, ...payload.attachments],
      dueBy: payload.repeatingOption ? payload.repeatingOption.value : '',
      groupIds: group ? [group._id] : groupIds,
      projectIds: project ? [project._id] : projectIds
    };

    delete payload._id;
    delete payload.isFromEmployee;

    const response = yield call(api.addTraining, payload);

    yield all(
      addedAttachments
        .filter(attachment => !attachment.source_url.includes('youtube'))
        .map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
    );

    yield put(addUploadedAttachmentsResponse([]));
    if (isFromEmployee) {
      history.goBack();
    } else {
      history.push('/app/training/trainingList');
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateTraining(api, { payload }) {
  try {
    const groupId = payload.groupIds.map(group => {
      if (group.value) {
        return group.value;
      }

      return group;
    });

    const projectId = payload.projectIds.map(project => {
      if (project.value) {
        return project.value;
      }

      return project;
    });

    const assignedTo = payload.assignedTo.map(user => user.value || user);

    const empSig = payload.employeeSignature;
    payload.employeeSignature = empSig?.source_url || empSig;
    const appSig = payload.approverSignature;
    payload.approverSignature = appSig?.source_url || appSig;

    payload.dueBy = payload.repeatingOption
      ? payload.repeatingOption.value
      : '';
    payload.groupIds = groupId;
    payload.projectIds = projectId;
    payload.approver = payload.approver.value
      ? payload.approver.value
      : payload.approver;
    payload.assignedTo = assignedTo;

    if (payload.isPerform && !payload.needsApproval && payload.isCompleted) {
      if (payload?.quiz) {
        payload.approveReject = payload?.quiz?.passedQuiz
          ? 'approve'
          : 'reject';
      } else {
        payload.approveReject = 'approve';
      }
    }

    if (payload.isApprove) {
      const childTrainings = yield call(api.fetchTrainingsByMasterId, payload);

      yield all(
        childTrainings.map(training => {
          const index = payload.assigneeApproval.findIndex(
            aa => aa.assignee === training.assignedTo[0]
          );
          if (index > -1) {
            return call(api.updateTraining, {
              ...training,
              approveReject: payload.assigneeApproval[index].approveReject,
              approverSignature: payload.approverSignature,
              approver: payload.approver
            });
          }
          return '';
        })
      );
    }

    yield call(api.updateTraining, payload);
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* deleteTraining(api, { payload }) {
  try {
    yield call(api.deleteTraining, payload);

    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* setActiveTraining(api, { payload }) {
  try {
    const childTrainings = yield call(api.fetchTrainingsByMasterId, payload);

    const childTrainingAttachments = [];
    const childQuizzes = [];

    childTrainings.forEach(child => {
      child.attachments.forEach(attachment => {
        childTrainingAttachments.push({
          ...attachment,
          assignedTo: child.assignedTo[0],
          approveReject: child.approveReject
        });
      });
      childQuizzes.push({
        ...child,
        assignee: child.assignedTo[0]
      });
    });

    yield put(
      setActiveTrainingResponse({
        ...payload,
        childAttachments: childTrainingAttachments,
        childQuizzes
      })
    );

    if (payload.isApprove) {
      history.push('/app/training/approveTraining');
    } else {
      history.push('/app/training/trainingSummary');
    }
  } catch (error) {
    console.error(error);
  }
}
