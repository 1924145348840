import { connect } from 'react-redux';
import React, { Component } from 'react';

import history from '../../history';

import {
  addProjectRequest,
  deleteProjectRequest,
  setActiveLocation,
  setActiveProject,
  updateProjectRequest
} from '../../actions/company';
import { getActiveCompany } from '../../selectors/company';
import { updateLeftNavRequest } from '../../actions/navigation';
import { getLoggedInUser } from '../../selectors/users';

import Dropdown from '../inputs/Dropdown';
import Modal from '../Modal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Textbox from '../inputs/Textbox';
import ViewEditList from '../ViewEditList';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';

export class ProjectList extends Component {
  state = {
    modalOpen: 0,
    projectName: '',
    assignedToGroup: '',
    row: {}
  };

  handleOpenModal(modalId, row) {
    this.setState({
      modalOpen: modalId,
      projectName: row?.name,
      assignedToGroup: row?.groupId,
      row: row
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: 0 });
  }

  componentDidMount() {
    this.props.updateLeftNav('Projects');
  }

  getGroups = () => {
    return (
      this.props.activeCompany.groups &&
      this.props.activeCompany.groups.map(group => {
        return {
          value: group._id,
          label: group.name
        };
      })
    );
  };

  handleAddProject = () => {
    const { projectName, assignedToGroup } = this.state;
    const { addProject, activeCompany } = this.props;

    addProject({
      companyId: activeCompany._id,
      groupId: assignedToGroup.value,
      name: projectName
    });

    this.handleCloseModal();
  };

  handleUpdateProject = () => {
    const { projectName, assignedToGroup, row } = this.state;
    const { updateProject } = this.props;

    updateProject({
      ...row,
      groupId: assignedToGroup,
      name: projectName
    });

    if (projectName !== row.name) {
    }

    if (row.groupId !== assignedToGroup.value) {
    }

    this.handleCloseModal();
  };

  handleHideProject = () => {
    const { row } = this.state;
    const { updateProject } = this.props;

    updateProject({
      ...row,
      isHidden: !row.isHidden
    });

    this.handleCloseModal();
  };

  deleteProject = id => {
    const { project } = this.findGroupAndProject(id);
    const { deleteProject } = this.props;

    deleteProject({
      ...project
    });
  };

  handleChange = stateObject => {
    this.setState(stateObject);
  };

  findGroupAndProject(projectId) {
    let project, group;
    this.props.activeCompany.groups.forEach(g => {
      let p = g.projects.find(p => p._id === projectId);
      if (p) {
        project = p;
        group = g;
      }
    });

    return { project, group };
  }

  render() {
    const { activeCompany, setActiveLocation } = this.props;
    const { modalOpen, projectName, assignedToGroup, row } = this.state;

    const header = (
      <Header
        title="Projects"
        rightButtons={{
          text: 'New Project',
          color: 'green',
          onClick: () => this.handleOpenModal(1)
        }}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header} showFooter={false}>
          <ScrollToTopOnMount />
          <ViewEditList
            data={activeCompany?.groups}
            subCat="projects"
            onView={id => {
              let { group, project } = this.findGroupAndProject(id);
              setActiveLocation(group);
              setActiveProject(project);
              history.push('/app/dashboard');
            }}
            onHide={id => {
              let { project } = this.findGroupAndProject(id);
              this.handleOpenModal(3, project);
            }}
            onEdit={id => {
              let { project } = this.findGroupAndProject(id);
              this.handleOpenModal(2, project);
            }}
            onDelete={this.deleteProject}
          />
        </HeaderAndFooter>
        {modalOpen === 1 && (
          <Modal
            title="Add New Project"
            titleClassName="greenHeader"
            isOpen={modalOpen === 1}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={() => this.handleAddProject()}
            disableSubmit={
              !projectName || projectName === '' || !assignedToGroup
            }
          >
            <Dropdown
              fieldLabel="Group to Add Project "
              isRequired={true}
              options={this.getGroups()}
              placeholder="Select a group"
              currentValue={assignedToGroup}
              handleChange={value =>
                this.handleChange({ assignedToGroup: value })
              }
            />
            <Textbox
              currentValue={projectName}
              fieldLabel="Project Name "
              handleChange={e =>
                this.handleChange({ projectName: e.target.value })
              }
              isRequired={true}
              placeholder="e.g Hotel Projects"
            />
          </Modal>
        )}
        <Modal
          title="Edit Project"
          titleClassName="blueHeader"
          isOpen={modalOpen === 2}
          submitButtonColor="blue"
          onRequestClose={() => this.handleCloseModal()}
          submitActions={() => this.handleUpdateProject()}
        >
          <Dropdown
            fieldLabel="Group to Add Project "
            isRequired={true}
            options={this.getGroups()}
            placeholder="Select a group"
            currentValue={assignedToGroup}
            handleChange={value =>
              this.handleChange({ assignedToGroup: value })
            }
          />
          <Textbox
            currentValue={projectName}
            fieldLabel="Project Name "
            handleChange={e =>
              this.handleChange({ projectName: e.target.value })
            }
            isRequired={true}
            placeholder="e.g Hotel Projects"
          />
        </Modal>
        <Modal
          title={row?.isHidden ? 'Show Project' : 'Hide Project'}
          titleClassName="blueHeader"
          isOpen={modalOpen === 3}
          submitButtonColor="blue"
          onRequestClose={() => this.handleCloseModal()}
          submitActions={() => this.handleHideProject()}
        >
          <h2>
            Are you sure you want to {row?.isHidden ? 'show ' : 'hide '}
            {projectName}?
          </h2>
          {row?.isHidden ? (
            <h3>
              Showing a project means that it will show up on the home page and
              will still show on this page.
            </h3>
          ) : (
            <h3>
              Hiding a project means that it won't show up on the home page but
              will still show on this page.
            </h3>
          )}
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  loggedInUser: getLoggedInUser(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  setActiveLocation: locationId => dispatch(setActiveLocation(locationId)),
  setActiveProject: values => dispatch(setActiveProject(values)),
  addProject: values => dispatch(addProjectRequest(values)),
  updateProject: values => dispatch(updateProjectRequest(values)),
  deleteProject: values => dispatch(deleteProjectRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
