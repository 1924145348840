import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResizeDetector from 'react-resize-detector';
import { updateLeftNavRequest } from '../../../actions/navigation';
import { getLoggedInUser } from '../../../selectors/users';
import {
  createAnalyticsChart,
  deleteAnalyticsChart,
  getAnalyticsDashboard,
  updateAnalyticsDashboard
} from '../../../api';
import history from '../../../history';

import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import GearMenu from '../../../components/GearMenu';
import { AddFooter } from '../../../components/Footer';
import AnalyticsChart from '../../../components/AnalyticsChart';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';

import styles from './dashboard.module.scss';

export class MyAnalyticsDashboard extends Component {
  #sizes;

  constructor(props) {
    super(props);

    this.sizes = {};
    Object.keys(styles)
      .filter(n => n.substr(0, 3) === 'sz_')
      .forEach(k => {
        const n = k.substr(3);
        this.sizes[n] = parseInt(styles[k], 10);
      });

    this.state = {
      currentPeriod: {},
      dashboard: null,
      chartWidth: 0,
      chartHeight: 0,
      dragIdx: null,
      dragTargetIdx: null,
      confirmDeleteId: null
    };
  }

  componentDidMount = () => {
    this.props.updateLeftNav('My Analytics');

    getAnalyticsDashboard(this.props.match.params.dashboardId).then(
      dashboard => {
        const currentPeriod = { ...dashboard.period };
        this.setState({ dashboard, currentPeriod });
      }
    );
  };

  onPeriodChange = v => {
    this.setState({ currentPeriod: v });
  };

  onPeriodSave = v => {
    this.setState((state, props) => {
      let newState = { ...state };
      newState.dashboard.period = v;
      return newState;
    });
  };

  resize = width => {
    if (width <= 0) return;

    /* Ugly math */
    const chartWidth =
      Math.floor((width - this.sizes.gridGap) / this.sizes.chartColumns) -
      2 * this.sizes.chartBorder -
      2 * this.sizes.chartPadding;

    const chartHeight = Math.floor(chartWidth * 0.5625);
    this.setState({ chartWidth, chartHeight });
  };

  renameDashboard = newName => {
    this.setState(
      (state, props) => {
        let newState = { ...state };
        newState.dashboard.name = newName;
        return newState;
      },
      () => {
        if (this.props.match.params.dashboardId)
          updateAnalyticsDashboard({
            dashboardId: this.props.match.params.dashboardId,
            payload: this.state.dashboard
          });
      }
    );
  };

  confirmDeleteChart = () => {
    deleteAnalyticsChart(this.state.confirmDeleteId).then(() =>
      this.setState((state, props) => {
        let newState = { ...state };
        let idx = newState.dashboard.charts.findIndex(
          d => d._id === newState.confirmDeleteId
        );
        newState.confirmDeleteId = null;
        newState.dashboard.charts.splice(idx, 1);
        return newState;
      })
    );
  };

  newCard = () => {
    history.push({
      pathname: '/app/myAnalytics/card',
      state: {
        dashboardId: this.props.match.params.dashboardId
      }
    });
  };

  onDragStart = (e, i) => {
    e.dataTransfer.dropEffect = 'move';
    this.setState({
      dragIdx: i,
      dragTargetIdx: null
    });
  };

  onDragOver = (e, i) => {
    if (e.preventDefault) e.preventDefault();
    this.setState((state, props) => {
      let newState = { ...state };
      newState.dragTargetIdx = newState.dragIdx === i ? null : i;
      return newState;
    });
  };

  onDragLeave = e => {
    this.setState({ dragTargetIdx: null });
  };

  onDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (
      this.state.dragIdx === this.state.dragTargetIdx ||
      this.state.dragTargetIdx === null
    )
      return;
    let charts = this.state.dashboard.charts.map(c => c._id);
    const tmp = charts[this.state.dragIdx];
    charts[this.state.dragIdx] = charts[this.state.dragTargetIdx];
    charts[this.state.dragTargetIdx] = tmp;
    updateAnalyticsDashboard({
      dashboardId: this.props.match.params.dashboardId,
      payload: { charts }
    }).then(() =>
      this.setState((state, props) => {
        let newState = { ...state };
        const t = newState.dashboard.charts[state.dragIdx];
        newState.dashboard.charts[state.dragIdx] =
          newState.dashboard.charts[state.dragTargetIdx];
        newState.dashboard.charts[state.dragTargetIdx] = t;
        newState.dragIdx = null;
        newState.dragTargetIdx = null;
        return newState;
      })
    );
  };

  renderChart = (chart, i) => (
    <div draggable onDragStart={e => this.onDragStart(e, i)}>
      <div className={styles.chartTitle}>
        {chart.title}
        <GearMenu
          options={[
            {
              label: 'Detailed View',
              onClick: () =>
                history.push({
                  pathname: `/app/myAnalytics/detail/${chart._id}`,
                  state: {
                    chart,
                    dashboardId: this.props.match.params.dashboardId,
                    period: this.state.currentPeriod
                  }
                })
            },
            {
              label: 'Edit',
              onClick: () =>
                history.push({
                  pathname: `/app/myAnalytics/card/${chart._id}`,
                  state: { chart }
                })
            },
            {
              label: 'Clone',
              onClick: () => {
                let chart = { ...this.state.dashboard.charts[i] };
                delete chart._id;
                chart.title += ' - Clone';
                createAnalyticsChart({
                  dashboardId: this.props.match.params.dashboardId,
                  payload: chart
                }).then(data => {
                  chart._id = data.chartId;
                  this.setState((state, props) => {
                    let newState = { ...state };
                    newState.dashboard.charts.push(chart);
                    return newState;
                  });
                });
              }
            },
            {
              label: 'Delete',
              color: 'red',
              onClick: () => this.setState({ confirmDeleteId: chart._id })
            }
          ]}
        />
      </div>
      <AnalyticsChart
        width={this.state.chartWidth}
        height={this.state.chartHeight}
        settings={chart}
        period={this.state.currentPeriod}
        className={styles.chart}
        noDataClassName={styles.noData}
        onClick={() =>
          history.push({
            pathname: `/app/myAnalytics/detail/${chart._id}`,
            state: {
              dashboardId: this.props.match.params.dashboardId,
              period: this.state.currentPeriod
            }
          })
        }
      />
    </div>
  );

  renderCharts = () => {
    if (!this.state?.dashboard?.charts || !this.state.dashboard.charts.length)
      return <></>;

    const charts = this.state.dashboard.charts;
    let ret = [];
    let nextChartIdx = 0;

    for (let i = 0; i < charts.length; i++) {
      let chart = null;
      if (this.state.dragIdx === null || this.state.dragTargetIdx !== i) {
        if (
          nextChartIdx === this.state.dragIdx &&
          this.state.dragTargetIdx !== null &&
          this.state.dragIdx !== this.state.dragTargetIdx
        )
          nextChartIdx++;
        chart = this.renderChart(charts[nextChartIdx], nextChartIdx);
        nextChartIdx++;
      }
      ret.push(
        <div
          key={i}
          onDragOver={e => this.onDragOver(e, i)}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          className={chart === null ? styles.dropTarget : ''}
        >
          {chart || <></>}
        </div>
      );
    }
    return ret;
  };

  render() {
    const name = this.state.dashboard
      ? this.state.dashboard.name
      : 'New Dashboard';
    const header = (
      <Header
        title={undefined}
        section={name}
        clickBack={() => {
          history.goBack();
        }}
        editName={this.renameDashboard}
        rightButtons={{
          text: 'Create Card',
          type: 'button',
          color: 'blue',
          onClick: this.newCard
        }}
      />
    );

    const footer = <AddFooter onClick={this.newCard} />;

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          className={styles.container}
        >
          <AnalyticsPeriodPicker
            className={styles.datePicker}
            period={this.state.dashboard?.period}
            onChange={this.onPeriodChange}
            onSave={this.onPeriodSave}
            dashboardId={this.props.match.params.dashboardId}
          />
          <div className={styles.charts}>
            {this.renderCharts()}
            <ResizeDetector
              handleWidth
              handleHeight
              onResize={this.resize}
              nodeType="span"
              refreshMode="debounce"
              refreshRate={250}
            />
          </div>
        </HeaderAndFooter>
        <DeleteItemModal
          isOpen={this.state.confirmDeleteId !== null}
          onRequestClose={() => this.setState({ confirmDeleteId: null })}
          submitActions={this.confirmDeleteChart}
          deletingWhat="Chart"
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAnalyticsDashboard);
