import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

import { getAllLocations } from '../../selectors/company';
import { classnames } from '../../utils/classNamesHelper';
import { severityColorMapper } from '../../utils/severityMapper';
import severityDescriptionMapper from '../../utils/severityDescriptionMapper';
import svgMapper from '../../utils/svgMapper';
import UserBadge from '../UserBadge';

import styles from './incidentCard.module.scss';

const getCircleImage = s => {
  switch (s) {
    case 'started':
      return (
        <img
          src={require('../../assets/images/stageDots.png')}
          alt="circle dots"
        />
      );
    case 'complete':
      return (
        <img
          src={require('../../assets/images/stageCheckmark.png')}
          alt="stage complete"
        />
      );
    default:
      return <></>;
  }
};

class __DashboardCard extends React.Component {
  render() {
    const {
      isLoading,
      icons,
      groupId,
      projectId,
      locations,
      subtitle,
      onClick,
      userId,
      description,
      actionVerb,
      children,
      created,
      updated,
      severity,
      hasAttachments,
    } = this.props;

    const location = Array.isArray(locations)
      && locations.find(location => location._id === groupId);
    let where = location?.name ?? '';
    if (location && projectId) {
      const project = location.projects.find(project => project._id === projectId);
      if (project?.name) {
        where += ` - ${project.name}`;
      }
    }

    return isLoading ? (
      <div className={styles.incidentCard}>
        <ReactLoading
          type="spinningBubbles"
          color="#58a15b"
          className="loading"
        />
      </div>
    ) : (
      <div className={styles.incidentCard} onClick={onClick}>
        <div className={styles.updated}>
          <div className={styles.header}>Last Updated</div>
          <div className={styles.dateTime}>
            {updated.format('MM/DD/YYYY')}
            <span>{updated.format('hh:mm A')}</span>
          </div>
        </div>
        <div className={styles.content}>
          {severity && (
            <div
              className={classnames(
                styles.severity,
                styles[severityColorMapper(severity)]
              )}
            >
              {severityDescriptionMapper(severity)}
            </div>
          )}
          <div className={styles.iconRow}>
            <div>{icons}</div>
          </div>
          <div className={styles.group}>{where}</div>
          <div className={styles.project}>{subtitle}</div>
          {children}
          <div className={styles.description}>{description}</div>
          <div className={styles.firstReported}>
            <div className={styles.header}>Time {actionVerb}</div>
            <div className={styles.dateTime}>
              {created.format('MM/DD/YYYY')}
              <span>{created.format('hh:mm A')}</span>
            </div>
          </div>
          <UserBadge
            showImage
            userId={userId}
            className={styles.profileBadge}
            showName
            size="medium"
            byline="Uploaded by"
          />
          {hasAttachments && (
            <div className={styles.attachment}>
              <img
                src={require('../../assets/images/incidentAttachment.png')}
                alt="Incident has attachment"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const IncidentCard = ({
  incident,
  onClick,
  incidentLoading,
  showAttachments
}) => {
  const icons = incident.reportTemplateIds.map((report, index) =>
    <div key={index}>{svgMapper(report)}</div>
  );

  return (
    <DashboardCard
      isLoading={incidentLoading}
      onClick={onClick}
      updated={incident.updatedAt}
      userId={incident.createdByUserId}
      icons={icons}
      projectId={incident.projectId}
      groupId={incident.locationId}
      subtitle={incident.incidentNumber}
      description={incident.description?.toUpperCase?.() ?? ''}
      actionVerb="Occurred"
      created={moment(incident.dateIncidentOccured)}
      hasAttachments={showAttachments && incident.hasAttachments}
      severity={incident.riskValue}
    >
      <div className={styles.stages}>
        <div className={styles.circles}>
          <div className={styles[incident.status.investigation]}>
            {getCircleImage(incident.status.investigation)}
          </div>
          <div className={styles.stageSelected}>Investigation</div>
        </div>
        <div className={styles.circles}>
          <div className={styles[incident.status.action]}>
            {getCircleImage(incident.status.action)}
          </div>
          <div className={styles.stageSelected}>Action</div>
        </div>
        <div className={styles.circles}>
          <div className={styles[incident.status.closure]}>
            {getCircleImage(incident.status.closure)}
          </div>
          <div className={styles.stageSelected}>Closure</div>
        </div>
      </div>
    </DashboardCard>
  );
};

export const SafetyWalkCard = ({
  isLoading,
  safetyWalk,
  onClick
}) => (
  <DashboardCard
    onClick={onClick}
    updated={safetyWalk.updatedAt}
    icons={svgMapper(10)}
    groupId={safetyWalk.groupId}
    projectId={safetyWalk.projectId}
    subtitle={safetyWalk.inspectionLocation}
    description={`${safetyWalk.hazards} HAZARD${
      safetyWalk.hazards === 1 ? '' : 'S'} FOUND`}
    actionVerb="Performed"
    created={safetyWalk.createdAt}
    userId={safetyWalk.createdBy}
    isLoading={isLoading}
  />
);

const mapStateToProps = state => ({
  locations: getAllLocations(state)
});

export const DashboardCard = connect(mapStateToProps)(__DashboardCard);
