import React from 'react';
import './svg.css';

export default () => (
  <svg width="31" height="31" viewBox="0 0 31 31">
    <path d="M23 21L21 23C21 23 26.8515 33.1485 30 30C33.1485 26.8515 23 21 23 21Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7165 15.2497C21.9016 14.5307 22 13.7768 22 13C22 12.2232 21.9016 11.4693 21.7165 10.7503C19.7051 9.04744 16.5488 7 13 7C9.45117 7 6.29485 9.04744 4.28347 10.7503C4.09842 11.4693 4 12.2232 4 13C4 13.7768 4.09842 14.5307 4.28347 15.2497C6.29485 16.9526 9.45117 19 13 19C16.5488 19 19.7051 16.9526 21.7165 15.2497ZM17 13C17 15.2091 15.2091 17 13 17C10.7909 17 9 15.2091 9 13C9 10.7909 10.7909 9 13 9C15.2091 9 17 10.7909 17 13Z"
    />
  </svg>
);
