import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import Button from '../../inputs/Button';
import CheckboxGroup from '../../inputs/CheckboxGroup';
import { DatePicker } from '../../inputs/DateTimePicker';
import Dropdown from '../../inputs/Dropdown';
import FileDownloader from '../../FileDownloader';
import FileUploader from '../../FileUploader';
import Multiselect from '../../inputs/Multiselect';
import RadioButtons from '../../inputs/RadioButtons';
import Signature from '../../inputs/Signature';
import Textbox from '../../inputs/Textbox';

import './index.css';

const optionsMapper = options => {
  return options.map((option, index) => ({ value: index, label: option }));
};

const findAnswer = (fields, questionId) => {
  const fieldIndex = fields.findIndex(field => field._id === questionId);
  if (fieldIndex > -1) {
    return fields[fieldIndex].answer;
  }

  return '';
};

export default class CustomDocumentGenerator extends Component {
  state = {
    fields: []
  };

  handleAnswer = (field, answer, index) => {
    field.answer = answer;

    const fields = this.props.fields;

    fields[index] = field;
    this.props.answerQuestion(fields);
  };

  render() {
    const {
      fields,
      employees,
      disabled,
      activeCustomDoc,
      handleExpires,
      expires,
      printDocument,
      downloadCustomDoc,
      addedAttachments,
      addAttachment,
      activeAttachments,
      deleteAttachment,
      activeCompanyId,
      loading,
      deleteCDAttachment,
      hasAccess
    } = this.props;

    const employeeDropdown = employees.map(employee => {
      return {
        value: employee._id,
        label: `${employee.person.firstName} ${employee.person.lastName}`
      };
    });

    return (
      <div className="customDocumentGenerator">
        <h2 className="customDocumentGenerator-header">
          {activeCustomDoc && activeCustomDoc.label}
          {!downloadCustomDoc ? (
            <div>
              {disabled && (
                <Button
                  text="Print Document"
                  className="blueButton"
                  onClick={() => printDocument()}
                />
              )}
            </div>
          ) : (
            <ReactLoading
              type="spinningBubbles"
              color="#2f8738"
              className="loading"
            />
          )}
        </h2>
        {activeCustomDoc && activeCustomDoc.shouldHaveExpiredDate && (
          <DatePicker
            fieldLabel="Expires"
            handleChange={value => handleExpires(value)}
            currentValue={expires}
            disabled={disabled}
          />
        )}
        {fields &&
          fields.map((field, index) =>
            field.type === 'radiobuttons' ? (
              <RadioButtons
                options={optionsMapper(field.options)}
                fieldLabel={field.label}
                handleChange={values => this.handleAnswer(field, values, index)}
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                disabled={disabled}
                key={index}
              />
            ) : field.type === 'textarea' ? (
              <Textbox
                type="textarea"
                fieldLabel={field.label}
                rows={5}
                key={index}
                handleChange={e =>
                  this.handleAnswer(field, e.target.value, index)
                }
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                disabled={disabled}
              />
            ) : field.type === 'dateTimePicker' ? (
              <DatePicker
                pickTime
                fieldLabel={field.label}
                handleChange={value => this.handleAnswer(field, value, index)}
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                key={index}
                disabled={disabled}
              />
            ) : field.type === 'datePicker' ? (
              <DatePicker
                fieldLabel={field.label}
                handleChange={value => this.handleAnswer(field, value, index)}
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                disabled={disabled}
                key={index}
              />
            ) : field.type === 'dropdown' ? (
              <Dropdown
                options={optionsMapper(field.options)}
                searchable={true}
                fieldLabel={field.label}
                name={field.label}
                handleChange={values => this.handleAnswer(field, values, index)}
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                disabled={disabled}
                key={index}
              />
            ) : field.type === 'multiselect' ? (
              <Multiselect
                options={optionsMapper(field.options)}
                searchable={true}
                fieldLabel={field.label}
                name={field.label}
                handleChange={values => this.handleAnswer(field, values, index)}
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                disabled={disabled}
                key={index}
              />
            ) : field.type === 'multiselectPersonnel' ? (
              <Multiselect
                options={employeeDropdown}
                searchable={true}
                fieldLabel={field.label}
                name={field.label}
                handleChange={values => this.handleAnswer(field, values, index)}
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                disabled={disabled}
                key={index}
              />
            ) : field.type === 'signature' ? (
              <Signature
                fieldLabel={field.label}
                key={index}
                disabled={disabled}
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                onChange={values => this.handleAnswer(field, values, index)}
                questionId={field._id}
                ownerType="document"
              />
            ) : field.type === 'checkbox' ? (
              <CheckboxGroup
                options={optionsMapper(field.options)}
                fieldLabel={field.label}
                name={field.label}
                handleChange={values => this.handleAnswer(field, values, index)}
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                disabled={disabled}
                key={index}
              />
            ) : field.type === 'textbox' ? (
              <Textbox
                fieldLabel={field.label}
                key={index}
                handleChange={e =>
                  this.handleAnswer(field, e.target.value, index)
                }
                isRequired={field.required}
                currentValue={findAnswer(fields, field._id)}
                disabled={disabled}
              />
            ) : field.type === 'blockOfText' ? (
              <div className="customDocumentGenerator-blockText" key={index}>
                {field.label}
              </div>
            ) : (
              ''
            )
          )}
        <div>
          <h4>Attachments</h4>
          {addedAttachments &&
            addedAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={deleteAttachment}
                disabled={!hasAccess || disabled}
              />
            ))}
          {activeAttachments &&
            activeAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={deleteCDAttachment}
                disabled={!hasAccess || disabled}
              />
            ))}
        </div>
        {!disabled && (
          <FileUploader
            activeCompanyId={activeCompanyId}
            addAttachment={addAttachment}
            loading={loading}
            ownerId="1234"
            ownerType="documents"
          />
        )}
      </div>
    );
  }
}
