import React from 'react';
import './svg.css';

export default () => (
  <svg
    width="30px"
    height="25px"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <g>
      <path d="M76.7,37.7c0.1-0.3,0.1-0.5,0.1-0.8c0-14.8-12-26.8-26.8-26.8c-14.8,0-26.8,12-26.8,26.8c0,0.3,0,0.5,0.1,0.8   c-3.5,2.1-5.9,6-5.9,10.3v29.8c0,6.7,5.4,12.1,12.1,12.1h41c6.7,0,12.1-5.4,12.1-12.1V48C82.6,43.7,80.2,39.8,76.7,37.7z M50,17   c10.6,0,19.3,8.4,19.8,18.9H30.2C30.7,25.4,39.4,17,50,17z M75.7,77.8c0,2.9-2.3,5.2-5.2,5.2h-41c-2.9,0-5.2-2.3-5.2-5.2V48   c0-2.9,2.3-5.2,5.2-5.2h41c2.9,0,5.2,2.3,5.2,5.2V77.8z" />
    </g>
    <g>
      <path d="M56.5,59.7c0-3.6-2.9-6.5-6.5-6.5s-6.5,2.9-6.5,6.5c0,2.1,1,3.9,2.5,5.1L44.8,76c0,0.2,0.1,0.5,0.2,0.7   c0.2,0.2,0.4,0.3,0.6,0.3h8.6c0,0,0,0,0,0c0.5,0,0.9-0.4,0.9-0.9c0-0.1,0-0.2-0.1-0.3l-1.2-11C55.5,63.6,56.5,61.7,56.5,59.7z" />
    </g>
  </svg>
);
