import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <path d="M50.892,97.053C25.543,97.053,5,76.505,5,51.158  c0-12.363,4.913-23.562,12.861-31.811c0.618-1.033,1.735-1.73,3.024-1.73c1.946,0,3.53,1.581,3.53,3.531  c0,1.103-0.517,2.076-1.311,2.723l0.09,0.091c-6.881,7.007-11.134,16.6-11.134,27.196c0,21.445,17.383,38.828,38.831,38.828  c21.444,0,38.834-17.383,38.834-38.828c0-21.448-17.39-38.834-38.834-38.834v-0.002c-1.951-0.002-3.531-1.582-3.531-3.531 c0-1.95,1.58-3.532,3.531-3.532c0.015,0,0.028,0.005,0.043,0.005c25.326,0.022,45.853,20.559,45.853,45.893  C96.787,76.505,76.24,97.053,50.892,97.053z M50.895,40.565c0,0,0,0-0.003,0c-1.951,0-3.531-1.581-3.531-3.53  c0-1.951,1.58-3.531,3.531-3.531c0.003,0,0.011,0.002,0.015,0.002c9.743,0.007,17.641,7.904,17.641,17.652  c0,9.732-7.887,17.63-17.619,17.648c-0.011,0-0.021,0.004-0.036,0.004c-1.951,0-3.531-1.58-3.531-3.53  c0-1.951,1.58-3.531,3.531-3.531v-0.001c5.851,0,10.591-4.742,10.591-10.59C61.482,45.308,56.745,40.567,50.895,40.565z   M75.608,51.158c0-13.649-11.066-24.712-24.717-24.712v-0.002c-1.951,0-3.531-1.581-3.531-3.531c0-1.95,1.58-3.53,3.531-3.53  c0.011,0,0.018,0.001,0.025,0.001c17.54,0.015,31.747,14.233,31.747,31.773c0,17.546-14.221,31.77-31.772,31.77  c-17.537,0-31.756-14.207-31.77-31.74c0-0.009-0.003-0.021-0.003-0.029c0-1.95,1.58-3.533,3.53-3.533  c1.951,0,3.531,1.583,3.531,3.533l0,0c0,13.646,11.065,24.711,24.713,24.711C64.542,75.869,75.608,64.804,75.608,51.158z" />
  </svg>
);
