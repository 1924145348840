import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import history from '../../../history';
import {
  setActiveTrainingResponse,
  updateTrainingRequest
} from '../../../actions/training';
import { getCompanyUsersSelector } from '../../../selectors/personnel';
import { getActiveTrainingSelector } from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import ApprovalItemModal from '../../../components/Modal/approvalItemModal';
import AssigneeTable from '../../../components/training/AssigneeTable';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import Dropdown from '../../../components/inputs/Dropdown';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import Modal from '../../../components/Modal';
import UserBadge from '../../../components/UserBadge';
import QuizTable from '../../../components/training/QuizTable';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import SignatureCard from '../../../components/SignatureCard';
import { SubmitSaveForLaterFooter } from '../../../components/Footer';
import Textbox from '../../../components/inputs/Textbox';

class ApproveTraining extends Component {
  state = {
    hasUnsavedChanges: false,
    openUnsavedChanges: false,
    openApprovalComplateModal: false,
    assigneeApproval: [],
    openChangeApproverModal: false,
    changeApproverId: undefined,
    employeeSignature: undefined,
    approverSignature: undefined
  };

  componentDidMount() {
    let assigneeApproval = [];
    let attachments = this.props.activeTraining?.childAttachments;

    if (attachments?.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];
        if (
          assigneeApproval.findIndex(
            assignee => assignee.assignee === attachment.assignedTo
          ) === -1
        ) {
          assigneeApproval.push({
            approveReject: attachment.approveReject,
            assignee: attachment.assignedTo
          });
        }
      }
    }

    this.setState({
      employeeSignature: this.props.activeTraining.employeeSignature,
      approverSignature: this.props.activeTraining.approverSignature,
      assigneeApproval
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.activeTraining, this.props.activeTraining)) {
      this.setState({ hasUnsavedChanges: true });
    }
  }

  handleBack = () => {
    this.props.setActiveTraining({});
    history.goBack();
  };

  handleSubmit = isComplete => {
    const { updateTraining, activeTraining } = this.props;
    const { assigneeApproval } = this.state;
    let response = { isCompleted: isComplete };

    if (isComplete) {
      response.completionDate = moment();
    }

    activeTraining.approverSignature = this.state.approverSignature;

    updateTraining({ ...activeTraining, ...response, assigneeApproval });
  };

  canSubmit = () => {
    const { activeTraining } = this.props;
    const { assigneeApproval } = this.state;

    return (
      assigneeApproval?.length === activeTraining?.assignedTo?.length &&
      this.state.approverSignature
    );
  };

  updateAnswer = values => {
    let assignees = cloneDeep(this.state.assigneeApproval);
    const index = assignees.findIndex(aa => aa.assignee === values.assignee);

    if (index > -1) {
      assignees[index] = values;
    } else {
      assignees = [
        ...assignees,
        {
          assignee: values.assignee,
          approveReject: values.approveReject
        }
      ];
    }

    this.setState({ assigneeApproval: assignees });
  };

  getQuickNav = () => {
    const { activeTraining } = this.props;

    let leftNav = [{ label: activeTraining.title, id: '0' }];

    if (activeTraining.childAttachments?.length) {
      leftNav = [...leftNav, { label: 'Material List', id: '1' }];
    }

    if (activeTraining.childQuizzes?.length) {
      leftNav = [...leftNav, { label: 'Quiz List', id: '2' }];
    }

    leftNav = [...leftNav, { label: 'Approver Signature', id: '4' }];

    return leftNav;
  };

  handleChangeApprover = () => {
    this.props.updateTraining({
      ...this.props.activeTraining,
      approver: this.state.changeApproverId,
      isApprove: false
    });
    history.goBack();
  };

  render() {
    const { activeTraining, users, loggedInUser } = this.props;

    const {
      openUnsavedChanges,
      assigneeApproval,
      openChangeApproverModal,
      changeApproverId,
      hasUnsavedChanges,
      openApprovalComplateModal
    } = this.state;

    const userDD = users
      .filter(user => user.userAccount.accessLevel !== '100')
      .map(user => {
        return {
          value: user.userAccount._id,
          label: `${user.person.firstName} ${user.person.lastName}`
        };
      });

    const header = (
      <Header
        title={
          activeTraining.isPerform
            ? 'Safety Training'
            : 'Require Acknowledgment'
        }
        section={activeTraining.title}
        clickBack={() => this.handleBack()}
        hasUnsavedChanges={hasUnsavedChanges}
        rightButtons={{
          text: 'Change Approver',
          onClick: () => this.setState({ openChangeApproverModal: true }),
          color: 'red',
          visible: activeTraining.isApprove
        }}
      />
    );

    const footer = (
      <SubmitSaveForLaterFooter
        submitButtonDisabled={!this.canSubmit()}
        submitButtonClick={() =>
          activeTraining.isPerform
            ? this.setState({ openMarkAsCompleteModal: true })
            : this.setState({ openApprovalComplateModal: true })
        }
        saveButtonClick={() => this.handleSubmit(false)}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={activeTraining.isEditing}
          Left={<LeftNav items={this.getQuickNav()} />}
        >
          <Card title={activeTraining.title} showHeader name="0">
            <Textbox
              currentValue={activeTraining.title}
              disabled
              fieldLabel="Safety Training Title"
            />
            <Textbox
              currentValue={activeTraining.instructions}
              disabled
              fieldLabel="Instructions"
              type="textarea"
            />
            {activeTraining.dueDate && (
              <DatePicker
                fieldLabel="Due Date"
                pickTime
                currentValue={activeTraining.dueDate}
                disabled
              />
            )}
          </Card>
          {activeTraining?.attachments?.length ? (
            <Card title="Assignee(s) Material List" showHeader name="1">
              <AssigneeTable
                assignees={activeTraining.assignedTo}
                attachments={activeTraining.attachments}
                childAttachments={activeTraining.childAttachments}
                users={users}
                needsApproval={
                  activeTraining.needsApproval && !activeTraining?.quiz
                }
                isApprover={activeTraining.approver === loggedInUser._id}
                updateAnswer={values => this.updateAnswer(values)}
                assigneeApproval={assigneeApproval}
              />
            </Card>
          ) : null}
          {activeTraining?.quiz && (
            <Card title="Assignee(s) Quiz List" showHeader name="2">
              <QuizTable
                childQuizzes={activeTraining?.childQuizzes}
                setActiveTraining={values =>
                  this.props.setActiveTraining({
                    ...values,
                    isEditing: false,
                    isFromSummary: true,
                    masterTraining: activeTraining
                  })
                }
                users={users}
                assigneeApproval={assigneeApproval}
                needsApproval={activeTraining.needsApproval}
                isApprover={activeTraining.approver === loggedInUser._id}
                updateAnswer={values => this.updateAnswer(values)}
              />
            </Card>
          )}
          <SignatureCard
            header="Sign for Approval"
            currentValue={this.state.approverSignature}
            userDisabled={true}
            dropdownName="Approver Name"
            users={userDD}
            userId={activeTraining.approver}
            name="4"
            onChange={sig => this.setState({ approverSignature: sig })}
            ownerType="training"
            ownerId={activeTraining._id}
          />
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChanges}
          savingWhat={activeTraining.title}
          onRequestClose={() => this.setState({ openUnsavedChanges: false })}
          submitActions={() => this.handleBack()}
        />
        <ApprovalItemModal
          isOpen={openApprovalComplateModal}
          approvingWhat="training"
          onRequestClose={() =>
            this.setState({ openApprovalComplateModal: false })
          }
          submitActions={() => this.handleSubmit(true)}
          cancelActions={() => this.handleSubmit(false)}
        />
        <Modal
          title="Change Approver"
          titleClassName="redHeader"
          isOpen={openChangeApproverModal}
          submitButtonColor="red"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ openChangeApproverModal: false })
          }
          submitActions={() => this.handleChangeApprover()}
          disableSubmit={!changeApproverId}
        >
          <div className="reportSectionContainer-emailAssigeeText">
            From here you can assign a different user as the Approver for this
            Safety Training.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This is to be used only if you are not the person meant to approve
              this safety training (as a Approver). You will be removed from
              this safety training.
            </span>
          </div>
          <div className="incidentContainer-reassignIncident">
            <UserBadge
              showImage
              hoverName
              userId={activeTraining.approver}
              size="large"
            />
            <img
              src={require('../../../assets/images/reassignArrow.png')}
              className="reassignArrow"
              alt="assign arrow"
            />
            <UserBadge
              showImage
              hoverName
              userId={changeApproverId}
              size="large"
            />
          </div>
          <Dropdown
            options={userDD}
            currentValue={changeApproverId}
            handleChange={value =>
              this.setState({ changeApproverId: value.value })
            }
            placeholder="Select one.."
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTraining: getActiveTrainingSelector(state),
  users: getCompanyUsersSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  updateTraining: payload => dispatch(updateTrainingRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveTraining);
