import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import history from '../../history';
import {
  createCustomDocumentTemplateRequest,
  deleteCustomDocumentTemplateRequest,
  setActiveCustomDocumentTemplate,
  updateCustomDocumentTemplateRequest
} from '../../actions/documents';
import { updateLeftNavRequest } from '../../actions/navigation';
import { getActiveCustomDocTemplateSelector } from '../../selectors/documents';
import { getMessageSelector } from '../../selectors/messages';
import { getLoggedInUser } from '../../selectors/users';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import CustomDocumentCard from '../../components/documentCards/CustomDocumentCard';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';

export class CustomDocumentContainer extends Component {
  state = {
    openUnsavedTemplateModal: false,
    openUnsavedChangesModal: false,
    openDeleteTemplateModal: false,
    shouldHaveExpiredDate: false,
    label: '',
    fields: [],
    isCreating: false,
    isEditing: false,
    hasUnsavedChanges: false
  };

  componentDidMount() {
    this.props.updateLeftNav('Custom Documents');

    if (this.props.activeTemplate._id) {
      this.setState({ ...this.props.activeTemplate });
    } else {
      this.setState({
        isCreating: true,
        fields: [{
          type: '',
          label: '',
          options: [],
          required: false
        }]
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  handleCustomSubmit = () => {
    if (this.state._id) {
      this.props.updateCustomTemplate(this.state);
    } else {
      this.props.createCustomTemplate(this.state);
    }
  };

  handleCustomDelete = () => {
    this.props.deleteCustomTemplate(this.state);
  };

  canSubmit = () => {
    const questionsHaveAllParts = this.state.fields.filter(
      question =>
        !question.type ||
        !question.label ||
        (question.type &&
          ['dropdown', 'multiselect', 'radiobuttons', 'checkbox'].includes(
            question.type.value
          ) &&
          question.options.length === 0)
    );

    if (
      this.state.fields.length > 0 &&
      questionsHaveAllParts.length === 0 &&
      this.state.label?.length
    ) {
      return true;
    }

    return false;
  };

  render() {
    const {
      openUnsavedTemplateModal,
      openUnsavedChangesModal,
      openDeleteTemplateModal,
      shouldHaveExpiredDate,
      label,
      fields,
      isCreating,
      isEditing,
      hasUnsavedChanges
    } = this.state;

    const { setActiveCustomDocument } = this.props;

    const header = (
      <Header
        title="Custom Document"
        section={
          !isEditing && !isCreating
            ? 'View Template'
            : isCreating
            ? 'Create Template'
            : 'Edit Template'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges && isEditing
            ? this.setState({ openUnsavedChangesModal: true })
            : hasUnsavedChanges && isCreating
            ? this.setState({ openUnsavedTemplateModal: true })
            : setActiveCustomDocument({}) && history.goBack()
        }
        rightButtons={{
          visible: !isCreating,
          text: isEditing ? 'Delete Template' : 'Edit Template',
          color: isEditing ? 'red' : 'blue',
          onClick: () =>
            isEditing
              ? this.setState({ openDeleteTemplateModal: true })
              : this.setState({ isEditing: true })
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={this.handleCustomSubmit}
        cancelButtonClick={() =>
          isEditing && hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : isEditing && !hasUnsavedChanges
            ? this.setState({ isEditing: false })
            : this.setState({ openUnsavedChangesModal: true })
        }
        editing={isEditing}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isCreating || isEditing}
        >
          <CustomDocumentCard
            onChange={values =>
              this.setState({ ...values, hasUnsavedChanges: true })
            }
            label={label}
            shouldHaveExpiredDate={shouldHaveExpiredDate}
            disabled={!isEditing && !isCreating}
            fields={fields}
            isEditing={isEditing}
          />
        </HeaderAndFooter>
        <Modal
          title="Unsaved Template"
          titleClassName="redHeader"
          isOpen={openUnsavedTemplateModal}
          submitButtonColor="red"
          submitButtonText="Discard Template"
          onRequestClose={() =>
            this.setState({ openUnsavedTemplateModal: false })
          }
          submitActions={() => setActiveCustomDocument({}) && history.goBack()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            You are navigating away from a document template that you were
            creating.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Leaving without saving will result in this template not being
              created.{' '}
            </span>
            If you wish to keep your template, click “Cancel”, and then hit
            “Save Changes” at the bottom of the create template screen.
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => setActiveCustomDocument({}) && history.goBack()}
          savingWhat="a document template"
        />
        <Modal
          title="Delete Document"
          titleClassName="redHeader"
          isOpen={openDeleteTemplateModal}
          submitButtonColor="red"
          submitButtonText="Delete Document"
          onRequestClose={() =>
            this.setState({ openDeleteTemplateModal: false })
          }
          submitActions={() => this.handleCustomDelete()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting a document template will permanently remove it for all
              users. Be sure that no one else needs to use this template before
              deleting.{' '}
            </span>
            Any document template that have already been assigned will not be
            affected, and can be completed normally.{' '}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTemplate: getActiveCustomDocTemplateSelector(state),
  loggedInUser: getLoggedInUser(state),
  message: getMessageSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation)),
  createCustomTemplate: values =>
    dispatch(createCustomDocumentTemplateRequest(values)),
  updateCustomTemplate: values =>
    dispatch(updateCustomDocumentTemplateRequest(values)),
  deleteCustomTemplate: values =>
    dispatch(deleteCustomDocumentTemplateRequest(values)),
  setActiveCustomDocument: values =>
    dispatch(setActiveCustomDocumentTemplate(values))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(CustomDocumentContainer)
);
