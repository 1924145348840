export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';

export const NEW_USER_REGISTRATION_REQUEST = 'NEW_USER_REGISTRATION_REQUEST';
export const NEW_USER_REGISTRATION_RESPONSE = 'NEW_USER_REGISTRATION_RESPONSE';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const FETCH_LEFT_NAV_REQUEST = 'FETCH_LEFT_NAV_REQUEST';
export const UPDATE_LEFT_NAV_REQUEST = 'UPDATE_LEFT_NAV_REQUEST';

export const FETCH_USER_COMPANIES_REQUEST = 'FETCH_USER_COMPANIES_REQUEST';
export const FETCH_USER_COMPANIES_RESPONSE = 'FETCH_USER_COMPANIES_RESPONSE';

export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';
export const SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION';

export const SET_ACTIVE_TASK_REQUEST = 'SET_ACTIVE_TASK_REQUEST';
export const SET_ACTIVE_TASK_RESPONSE = 'SET_ACTIVE_TASK_RESPONSE';

export const FETCH_COMPANY_INCIDENT_CIRCLES_REQUEST =
  'FETCH_COMPANY_INCIDENT_CIRCLES_REQUEST';
export const FETCH_COMPANY_INCIDENTS_REQUEST =
  'FETCH_COMPANY_INCIDENTS_REQUEST';
export const FETCH_COMPANY_INCIDENTS_RESPONSE =
  'FETCH_COMPANY_INCIDENTS_RESPONSE';

export const FETCH_COMPANY_CIRCLES_RESPONSE = 'FETCH_COMPANY_CIRCLES_RESPONSE';

export const FETCH_USERS_TASKS_REQUEST = 'FETCH_USERS_TASKS_REQUEST';
export const FETCH_USERS_TASKS_RESPONSE = 'FETCH_USERS_TASKS_RESPONSE';

export const ADD_USER_TASK_REQUEST = 'ADD_USER_TASK_REQUEST';
export const ADD_USER_TASK_RESPONSE = 'ADD_USER_TASK_RESPONSE';

export const UPDATE_USER_TASK_REQUEST = 'UPDATE_USER_TASK_REQUEST';
export const UPDATE_USER_TASK_RESPONSE = 'UPDATE_USER_TASK_RESPONSE';

export const DELETE_USER_TASK_REQUEST = 'DELETE_USER_TASK_REQUEST';
export const DELETE_USER_TASK_RESPONSE = 'DELETE_USER_TASK_RESPONSE';

export const FETCH_COMPANY_AUDITS_REQUEST = 'FETCH_COMPANY_AUDITS_REQUEST';
export const FETCH_COMPANY_AUDITS_RESPONSE = 'FETCH_COMPANY_AUDITS_RESPONSE';

export const FETCH_COMPANY_EMPLOYEES_RESPONSE =
  'FETCH_COMPANY_EMPLOYEES_RESPONSE';

export const FETCH_SAFETY_INSTRUCTION_REQUEST =
  'FETCH_SAFETY_INSTRUCTIONS_REQUEST';
export const FETCH_SAFETY_INSTRUCTION_RESPONSE =
  'FETCH_SAFETY_INSTUCTIONS_RESPONSE';

export const FETCH_SDS_REQUEST = 'FETCH_SDS_REQUEST';
export const FETCH_SDS_RESPONSE = 'FETCH_SDS_RESPONSE';

export const FETCH_COMPANY_REPORTS_REQUEST = 'FETCH_COMPANY_REPORTS_REQUEST';
export const FETCH_COMPANY_REPORTS_RESPONSE = 'FETCH_COMPANY_REPORTS_RESPONSE';

export const FETCH_LOCATION_REPORTS_REQUEST = 'FETCH_LOCATION_REPORTS_REQUEST';
export const FETCH_LOCATION_REPORTS_RESPONSE =
  'FETCH_LOCATION_REPORTS_RESPONSE';

export const FETCH_PROJECT_REPORTS_REQUEST = 'FETCH_PROJECT_REPORTS_REQUEST';
export const FETCH_PROJECT_REPORTS_RESPONSE = 'FETCH_PROJECT_REPORTS_RESPONSE';

export const FETCH_REPORT_CIRCLES_RESPONSE = 'FETCH_REPORT_CIRCLES_RESPONSE';

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
export const ADD_COMPANY_RESPONSE = 'ADD_COMPANY_RESPONSE';

export const ADD_COMPANY_LOCATION_REQUEST = 'ADD_COMPANY_LOCATION_REQUEST';
export const ADD_COMPANY_LOCATION_RESPONSE = 'ADD_COMPANY_LOCATION_RESPONSE';

export const UPDATE_COMPANY_LOCATION_REQUEST =
  'UPDATE_COMPANY_LOCATION_REQUEST';
export const UPDATE_COMPANY_LOCATION_RESPONSE =
  'UPDATE_COMPANY_LOCATION_RESPONSE';

export const DELETE_COMPANY_LOCATION_REQUEST =
  'DELETE_COMPANY_LOCATION_REQUEST';
export const DELETE_COMPANY_LOCATION_RESPONSE =
  'DELETE_COMPANY_LOCATION_RESPONSE';

export const FETCH_TASKS_CIRCLES_RESPONSE = 'FETCH_TASKS_CIRCLES_RESPONSE';

export const ADD_LOCATION_EMPLOYEE_REQUEST = 'ADD_LOCATION_EMPLOYEE_REQUEST';
export const ADD_LOCATION_EMPLOYEE_RESPONSE = 'ADD_LOCATION_EMPLOYEE_RESPONSE';

export const UPDATE_LOCATION_EMPLOYEE_REQUEST =
  'UPDATE_LOCATION_EMPLOYEE_REQUEST';
export const UPDATE_LOCATION_EMPLOYEE_RESPONSE =
  'UPDATE_LOCATION_EMPLOYEE_RESPONSE';

export const DELETE_LOCATION_EMPLOYEE_REQUEST =
  'DELETE_LOCATION_EMPLOYEE_REQUEST';
export const DELETE_LOCATION_EMPLOYEE_RESPONSE =
  'DELETE_LOCATION_EMPLOYEE_RESPONSE';

export const SET_ACTIVE_GROUPS = 'SET_ACTIVE_GROUPS';

export const IMPORT_EMPLOYEE_REQUEST = 'IMPORT_EMPLOYEE_REQUEST';

export const SET_ACTIVE_EMPLOYEE_REQUEST = 'SET_ACTIVE_EMPLOYEE_REQUEST';

export const ADD_SDS_REQUEST = 'ADD_SDS_REQUEST';
export const ADD_SDS_RESPONSE = 'ADD_SDS_RESPONSE';

export const UPDATE_SDS_REQUEST = 'UPDATE_SDS_REQUEST';
export const UPDATE_SDS_RESPONSE = 'UPDATE_SDS_RESPONSE';

export const DELETE_SDS_REQUEST = 'DELETE_SDS_REQUEST';
export const DELETE_SDS_RESPONSE = 'DELETE_SDS_RESPONSE';

export const SET_ACTIVE_SDS = 'SET_ACTIVE_SDS';

export const ADD_SAFETY_INSTRUCTION_REQUEST = 'ADD_SAFETY_INSTRUCTION_REQUEST';
export const ADD_SAFETY_INSTRUCTION_RESPONSE =
  'ADD_SAFETY_INSTRUCTION_RESPONSE';

export const UPDATE_SAFETY_INSTRUCTION_REQUEST =
  'UPDATE_SAFETY_INSTRUCTION_REQUEST';
export const UPDATE_SAFETY_INSTRUCTION_RESPONSE =
  'UPDATE_SAFETY_INSTRUCTION_RESPONSE';

export const DELETE_SAFETY_INSTRUCTION_REQUEST =
  'DELETE_SAFETY_INSTRUCTION_REQUEST';
export const DELETE_SAFETY_INSTRUCTION_RESPONSE =
  'DELETE_SAFETY_INSTRUCTION_RESPONSE';

export const SET_ACTIVE_SAFETY_INSTRUCTION = 'SET_ACTIVE_SAFETY_INSTRUCTION';

export const ADD_UPLOADED_ATTACHMENTS_REQUEST =
  'ADD_UPLOADED_ATTACHMENTS_REQUEST';
export const ADD_UPLOADED_ATTACHMENTS_RESPONSE =
  'ADD_UPLOADED_ATTACHMENTS_RESPONSE';
export const CLEAR_UPLOADED_ATTACHMENTS = 'CLEAR_UPLOADED_ATTACHMENTS';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_RESPONSE = 'UPDATE_COMPANY_RESPONSE';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_RESPONSE = 'DELETE_COMPANY_RESPONSE';

export const FETCH_INCIDENT_BASICS_REQUEST = 'FETCH_INCIDENT_BASICS_REQUEST';
export const FETCH_INCIDENT_BASICS_RESPONSE = 'FETCH_INCIDENT_BASICS_RESPONSE';

export const UPDATE_INCIDENT_BASICS_REQUEST = 'UPDATE_INCIDENT_BASICS_REQUEST';
export const UPDATE_INCIDENT_BASICS_RESPONSE =
  'UPDATE_INCIDENT_BASICS_RESPONSE';

export const UPDATE_INCIDENT_REQUEST = 'UPDATE_INCIDENT_REQUEST';
export const UPDATE_INCIDENT_RESPONSE = 'UPDATE_INCIDENT_RESPONSE';

export const DELETE_INCIDENT_REQUEST = 'DELETE_INCIDENT_REQUEST';

export const SET_ACTIVE_INCIDENT_REQUEST = 'SET_ACTIVE_INCIDENT_REQUEST';
export const SET_ACTIVE_INCIDENT_RESPONSE = 'SET_ACTIVE_INCIDENT_RESPONSE';

export const SET_ACTIVE_REPORT_REQUEST = 'SET_ACTIVE_REPORT_REQUEST';

export const CREATE_REPORT_RESPONSE = 'CREATE_REPORT_RESPONSE';

export const UPDATE_REPORT_RESPONSE = 'UPDATE_REPORT_RESPONSE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE';
export const UPDATE_USER_PROFILE_PICTURE_URL =
  'UPDATE_USER_PROFILE_PICTURE_URL';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_DEFAULT_PASSWORD_REQUEST =
  'UPDATE_DEFAULT_PASSWORD_REQUEST';

export const ADD_INCIDENT_COMMENTS_REQUEST = 'ADD_INCIDENT_COMMENTS_REQUEST';
export const UPDATE_INCIDENT_COMMENTS_REQUEST =
  'UPDATE_INCIDENT_COMMENTS_REQUEST';

export const ADD_INCIDENT_NOTES_REQUEST = 'ADD_INCIDENT_NOTES_REQUEST';
export const UPDATE_INCIDENT_NOTES_REQUEST = 'UPDATE_INCIDENT_NOTES_REQUEST';

export const FETCH_INCIDENT_ATTACHMENTS_REQUEST =
  'FETCH_INCIDENT_ATTACHMENTS_REQUEST';
export const FETCH_INCIDENT_ATTACHMENTS_RESPONSE =
  'FETCH_INCIDENT_ATTACHMENTS_RESPONSE';

export const ADD_INCIDENT_ATTACHMENTS_REQUEST =
  'ADD_INCIDENT_ATTACHMENTS_REQUEST';
export const ADD_INCIDENT_ATTACHMENTS_RESPONSE =
  'ADD_INCIDENT_ATTACHMENTS_RESPONSE';

export const SET_REPORT_TEMPLATES = 'SET_REPORT_TEMPLATES';

export const FETCH_JSA_SECTIONS_REQUEST = 'FETCH_JSA_SECTIONS_REQUEST';
export const FETCH_JSA_SECTIONS_RESPONSE = 'FETCH_JSA_SECTIONS_RESPONSE';

export const FETCH_COMPANY_JSA_REQUEST = 'FETCH_COMPANY_JSA_REQUEST';
export const FETCH_COMPANY_JSA_RESPONSE = 'FETCH_COMPANY_JSA_RESPONSE';
export const FETCH_LOCATION_JSA_REQUEST = 'FETCH_LOCATION_JSA_REQUEST';
export const FETCH_LOCATION_JSA_RESPONSE = 'FETCH_LOCATION_JSA_RESPONSE';
export const FETCH_PROJECT_JSA_REQUEST = 'FETCH_PROJECT_JSA_REQUEST';
export const FETCH_PROJECT_JSA_RESPONSE = 'FETCH_PROJECT_JSA_RESPONSE';

export const FETCH_JSA_CIRCLES_RESPONSE = 'FETCH_JSA_CIRCLES_RESPONSE';

export const FETCH_JSA_NOTES_REQUEST = 'FETCH_JSA_NOTES_REQUEST';
export const FETCH_JSA_NOTES_RESPONSE = 'FETCH_JSA_NOTES_RESPONSE';

export const ADD_JSA_NOTES_REQUEST = 'ADD_JSA_NOTES_REQUEST';
export const ADD_JSA_NOTES_RESPONSE = 'ADD_JSA_NOTES_RESPONSE';

export const UPDATE_JSA_NOTES_REQUEST = 'UPDATE_JSA_NOTES_REQUEST';
export const UPDATE_JSA_NOTES_RESPONSE = 'UPDATE_JSA_NOTES_RESPONSE';

export const DELETE_JSA_NOTES_REQUEST = 'DELETE_JSA_NOTES_REQUEST';
export const DELETE_JSA_NOTES_RESPONSE = 'DELETE_JSA_NOTES_RESPONSE';

export const SET_ACTIVE_JSA_REQUEST = 'SET_ACTIVE_JSA_REQUEST';
export const SET_ACTIVE_JSA_RESPONSE = 'SET_ACTIVE_JSA_RESPONSE';

export const CREATE_JSA_REQUEST = 'CREATE_JSA_REQUEST';
export const CREATE_JSA_RESPONSE = 'CREATE_JSA_RESPONSE';

export const UPDATE_JSA_REQUEST = 'UPDATE_JSA_REQUEST';
export const UPDATE_JSA_RESPONSE = 'UPDATE_JSA_RESPONSE';

export const DELETE_JSA_REQUEST = 'DELETE_JSA_REQUEST';
export const DELETE_JSA_RESPONSE = 'DELETE_JSA_RESPONSE';

export const FETCH_RA_CUSTOM_TEMPLATES_REQUEST =
  'FETCH_RA_CUSTOM_TEMPLATES_REQUEST';
export const FETCH_RA_CUSTOM_TEMPLATES_RESPONSE =
  'FETCH_RA_CUSTOM_TEMPLATES_RESPONSE';

export const CREATE_RA_CUSTOM_TEMPLATE_REQUEST =
  'CREATE_RA_CUSTOM_TEMPLATE_REQUEST';
export const CREATE_RA_CUSTOM_TEMPLATE_RESPONSE =
  'CREATE_RA_CUSTOM_TEMPLATE_RESPONSE';

export const UPDATE_RA_CUSTOM_TEMPLATE_REQUEST =
  'UPDATE_RA_CUSTOM_TEMPLATE_REQUEST';
export const UPDATE_RA_CUSTOM_TEMPLATE_RESPONSE =
  'UPDATE_RA_CUSTOM_TEMPLATE_RESPONSE';

export const DELETE_RA_CUSTOM_TEMPLATE_REQUEST =
  'DELETE_RA_CUSTOM_TEMPLATE_REQUEST';
export const DELETE_RA_CUSTOM_TEMPLATE_RESPONSE =
  'DELETE_RA_CUSTOM_TEMPLATE_RESPONSE';

export const SET_ACTIVE_RA_CUSTOM_TEMPLATE = 'SET_ACTIVE_RA_CUSTOM_TEMPLATE';

export const SET_ACTIVE_EMPLOYEE_RESPONSE = 'SET_ACTIVE_EMPLOYEE_RESPONSE';

export const FETCH_INCIDENT_REPORT_RESPONSE = 'FETCH_INCIDENT_REPORT_RESPONSE';

export const FETCH_JSA_BY_ID = 'FETCH_JSA_BY_ID';

export const FETCH_JSA_ATTACHMENTS_REQUEST = 'FETCH_JSA_ATTACHMENTS_REQUEST';
export const FETCH_JSA_ATTACHMENTS_RESPONSE = 'FETCH_JSA_ATTACHMENTS_RESPONSE';

export const ADD_JSA_ATTACHMENTS_REQUEST = 'ADD_JSA_ATTACHMENTS_REQUEST';
export const ADD_JSA_ATTACHMENTS_RESPONSE = 'ADD_JSA_ATTACHMENTS_RESPONSE';

export const FETCH_USER_JSAS_REQUEST = 'FETCH_USER_JSAS_REQUEST';

export const DELETE_SAFETY_INSTRUCTION_ATTACHMENT =
  'DELETE_SAFETY_INSTRUCTION_ATTACHMENT';

export const DELETE_SDS_ATTACHMENT = 'DELETE_SDS_ATTACHMENT';

export const DELETE_INCIDENT_ATTACHMENT = 'DELETE_INCIDENT_ATTACHMENT';

export const DELETE_JSA_QUESTION_ATTACHMENT = 'DELETE_JSA_QUESTION_ATTACHMENT';

export const DELETE_DAILY_REPORT_ATTACHMENT = 'DELETE_DAILY_REPORT_ATTACHMENT';

export const DELETE_TOOLBOX_TALK_ATTACHMENT = 'DELETE_TOOLBOX_TALK_ATTACHMENT';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_RESPONSE = 'SEARCH_RESPONSE';

export const CHARGE_CUSTOMER_REQUEST = 'CHARGE_CUSTOMER_REQUEST';
export const CHARGE_CUSTOMER_RESPONSE = 'CHARGE_CUSTOMER_RESPONSE';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_RESPONSE = 'CREATE_CUSTOMER_RESPONSE';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_RESPONSE = 'UPDATE_CUSTOMER_RESPONSE';

export const FETCH_ACCOUNT_INFORMATION_REQUEST =
  'FETCH_ACCOUNT_INFORMATION_REQUEST';
export const FETCH_ACCOUNT_INFORMATION_RESPONSE =
  'FETCH_ACCOUNT_INFORMATION_RESPONSE';

export const FETCH_DAILY_REPORTS_REQUEST = 'FETCH_DAILY_REPORTS_REQUEST';
export const FETCH_DAILY_REPORTS_RESPONSE = 'FETCH_DAILY_REPORTS_RESPONSE';

export const ADD_DAILY_REPORTS_REQUEST = 'ADD_DAILY_REPORTS_REQUEST';
export const ADD_DAILY_REPORTS_RESPONSE = 'ADD_DAILY_REPORTS_RESPONSE';

export const UPDATE_DAILY_REPORTS_REQUEST = 'UPDATE_DAILY_REPORTS_REQUEST';
export const UPDATE_DAILY_REPORTS_RESPONSE = 'UPDATE_DAILY_REPORTS_RESPONSE';

export const DELETE_DAILY_REPORTS_REQUEST = 'DELETE_DAILY_REPORTS_REQUEST';
export const DELETE_DAILY_REPORTS_RESPONSE = 'DELETE_DAILY_REPORTS_RESPONSE';

export const SET_ACTIVE_DAILY_REPORT = 'SET_ACTIVE_DAILY_REPORT';

export const FETCH_TOOLBOX_TALKS_REQUEST = 'FETCH_TOOLBOX_TALKS_REQUEST';
export const FETCH_TOOLBOX_TALKS_RESPONSE = 'FETCH_TOOLBOX_TALKS_RESPONSE';

export const ADD_TOOLBOX_TALKS_REQUEST = 'ADD_TOOLBOX_TALKS_REQUEST';
export const ADD_TOOLBOX_TALKS_RESPONSE = 'ADD_TOOLBOX_TALKS_RESPONSE';

export const UPDATE_TOOLBOX_TALKS_REQUEST = 'UPDATE_TOOLBOX_TALKS_REQUEST';
export const UPDATE_TOOLBOX_TALKS_RESPONSE = 'UPDATE_TOOLBOX_TALKS_RESPONSE';

export const DELETE_TOOLBOX_TALKS_REQUEST = 'DELETE_TOOLBOX_TALKS_REQUEST';
export const DELETE_TOOLBOX_TALKS_RESPONSE = 'DELETE_TOOLBOX_TALKS_RESPONSE';

export const SET_ACTIVE_TOOLBOX_TALK = 'SET_ACTIVE_TOOLBOX_TALK';

export const SET_ALL_LOCATIONS = 'SET_ALL_LOCATIONS';

export const CREATE_OSHA_FORM_REQUEST = 'CREATE_OSHA_FORM_REQUEST';
export const CREATE_OSHA_FORM_RESPONSE = 'CREATE_OSHA_FORM_RESPONSE';

export const UPDATE_OSHA_FORM_REQUEST = 'UPDATE_OSHA_FORM_REQUEST';
export const UPDATE_OSHA_FORM_RESPONSE = 'UPDATE_OSHA_FORM_RESPONSE';

export const FETCH_OSHA_LOGS_COMPANY_REQUEST =
  'FETCH_OSHA_LOGS_COMPANY_REQUEST';
export const FETCH_OSHA_LOGS_LOCATION_REQUEST =
  'FETCH_OSHA_LOGS_LOCATION_REQUEST';

export const FETCH_OSHA_FORM_RESPONSE = 'FETCH_OSHA_FORM_RESPONSE';

export const FETCH_OSHA_300LOGS_RESPONSE = 'FETCH_OSHA_300LOGS_RESPONSE';

export const FETCH_OSHA_300A_RESPONSE = 'FETCH_OSHA_300A_RESPONSE';

export const FETCH_OSHA_301_RESPONSE = 'FETCH_OSHA_301_RESPONSE';

export const CREATE_OSHA_LOG_REQUEST = 'CREATE_OSHA_LOG_REQUEST';
export const CREATE_OSHA_LOG_RESPONSE = 'CREATE_OSHA_LOG_RESPONSE';

export const PRINT_OSHA_REQUEST = 'PRINT_OSHA_REQUEST';
export const PRINT_OSHA_RESPONSE = 'PRINT_OSHA_RESPONSE';

export const PRINT_SELECTED_OSHA_REQUEST = 'PRINT_SELECTED_OSHA_REQUEST';

export const UPDATE_OSHA_EMPLOYEE_REQUEST = 'UPDATE_OSHA_EMPLOYEE_REQUEST';

export const FETCH_COMPANY_MANAGEMENT_ANALYTICS_REQUEST =
  'FETCH_COMPANY_MANAGEMENT_ANALYTICS_REQUEST';
export const FETCH_COMPANY_MANAGEMENT_ANALYTICS_RESPONSE =
  'FETCH_COMPANY_MANAGEMENT_ANALYTICS_RESPONSE';

export const FETCH_COMPANY_INCIDENT_ANALYTICS_REQUEST =
  'FETCH_COMPANY_INCIDENT_ANALYTICS_REQUEST';
export const FETCH_COMPANY_INCIDENT_ANALYTICS_RESPONSE =
  'FETCH_COMPANY_INCIDENT_ANALYTICS_RESPONSE';

export const DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST';
export const DELETE_ATTACHMENT_RESPONSE = 'DELETE_ATTACHMENT_RESPONSE';

export const FETCH_QUALIFICATION_REQUEST = 'FETCH_QUALIFICATION_REQUEST';
export const FETCH_QUALIFICATION_RESPONSE = 'FETCH_QUALIFICATION_RESPONSE';

export const ADD_QUALIFICATION_REQUEST = 'ADD_QUALIFICATION_REQUEST';
export const ADD_QUALIFICATION_RESPONSE = 'ADD_QUALIFICATION_RESPONSE';

export const UPDATE_QUALIFICATION_REQUEST = 'UPDATE_QUALIFICATION_REQUEST';
export const UPDATE_QUALIFICATION_RESPONSE = 'UPDATE_QUALIFICATION_RESPONSE';

export const DELETE_QUALIFICATION_REQUEST = 'DELETE_QUALIFICATION_REQUEST';
export const DELETE_QUALIFICATION_RESPONSE = 'DELETE_QUALIFICATION_RESPONSE';

export const SET_ACTIVE_QUALIFICATION = 'SET_ACTIVE_QUALIFICATION';

export const DELETE_QUALIFICATION_ATTACHMENT =
  'DELETE_QUALIFICATION_ATTACHMENT';

export const DOWNLOAD_EMPLOYEE_QRCODE_REQUEST =
  'DOWNLOAD_EMPLOYEE_QRCODE_REQUEST';
export const DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE =
  'DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE';

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';

export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';

export const FETCH_QUALIFICATION_TYPES_REQUEST =
  'FETCH_QUALIFICATION_TYPES_REQUEST';
export const FETCH_QUALIFICATION_TYPES_RESPONSE =
  'FETCH_QUALIFICATION_TYPES_RESPONSE';

export const SET_ACTIVE_COMPANY_USER = 'SET_ACTIVE_COMPANY_USER';

export const FETCH_DOCUMENT_FOLDERS_REQUEST = 'FETCH_DOCUMENT_FOLDERS_REQUEST';
export const FETCH_DOCUMENT_FOLDERS_RESPONSE =
  'FETCH_DOCUMENT_FOLDERS_RESPONSE';

export const ADD_DOCUMENT_FOLDER_REQUEST = 'ADD_DOCUMENT_FOLDER_REQUEST';
export const ADD_DOCUMENT_FOLDER_RESPONSE = 'ADD_DOCUMENT_FOLDER_RESPONSE';

export const UPDATE_DOCUMENT_FOLDER_REQUEST = 'UPDATE_DOCUMENT_FOLDER_REQUEST';
export const UPDATE_DOCUMENT_FOLDER_RESPONSE =
  'UPDATE_DOCUMENT_FOLDER_RESPONSE';

export const DELETE_DOCUMENT_FOLDER_REQUEST = 'DELETE_DOCUMENT_FOLDER_REQUEST';
export const DELETE_DOCUMENT_FOLDER_RESPONSE =
  'DELETE_DOCUMENT_FOLDER_RESPONSE';

export const SET_ACTIVE_DOCUMENT_FOLDER_REQUEST =
  'SET_ACTIVE_DOCUMENT_FOLDER_REQUEST';
export const SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE =
  'SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE';

export const ADD_DOCUMENTS_TO_FOLDER_REQUEST =
  'ADD_DOCUMENTS_TO_FOLDER_REQUEST';
export const ADD_DOCUMENTS_TO_FOLDER_RESPONSE =
  'ADD_DOCUMENTS_TO_FOLDER_RESPONSE';

export const ADD_EMPLOYEE_DOCUMENT_REQUEST = 'ADD_EMPLOYEE_DOCUMENT_REQUEST';
export const ADD_EMPLOYEE_DOCUMENT_RESPONSE = 'ADD_EMPLOYEE_DOCUMENT_RESPONSE';

export const UPDATE_EMPLOYEE_DOCUMENT_REQUEST =
  'UPDATE_EMPLOYEE_DOCUMENT_REQUEST';
export const UPDATE_EMPLOYEE_DOCUMENT_RESPONSE =
  'UPDATE_EMPLOYEE_DOCUMENT_RESPONSE';

export const DELETE_EMPLOYEE_DOCUMENT_REQUEST =
  'DELETE_EMPLOYEE_DOCUMENT_REQUEST';
export const DELETE_EMPLOYEE_DOCUMENT_RESPONSE =
  'DELETE_EMPLOYEE_DOCUMENT_RESPONSE';

export const FETCH_ALL_ACTIVE_EMPLOYEES = 'FETCH_ALL_ACTIVE_EMPLOYEES';

export const FETCH_ALL_ACTIVE_EMPLOYEES_ACTIVE_USERS =
  'FETCH_ALL_ACTIVE_EMPLOYEES_ACTIVE_USERS';

export const FETCH_COMPANY_USERS_RESPONSE = 'FETCH_COMPANY_USERS_RESPONSE';

export const EMAIL_INCIDENT_ASSIGNEE_REQUEST =
  'EMAIL_INCIDENT_ASSIGNEE_REQUEST';
export const EMAIL_INCIDENT_ASSIGNEE_RESPONSE =
  'EMAIL_INCIDENT_ASSIGNEE_RESPONSE';

export const FETCH_INCIDENT_BY_ID_REQUEST = 'FETCH_INCIDENT_BY_ID_REQUEST';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';

export const RECATAGORIZE_REPORTS_REQUEST = 'RECATAGORIZE_REPORTS_REQUEST';

export const UPLOAD_ERROR_RESPONSE = 'UPLOAD_ERROR_RESPONSE';

export const MICROSOFT_LOGIN = 'MICROSOFT_LOGIN';

export const CREATE_USER_SECURITY_QUESTIONS_REQUEST =
  'CREATE_USER_SECURITY_QUESTIONS_REQUEST';
export const GET_SECURITY_QUESTIONS = 'GET_SECURITY_QUESTIONS';
export const SUBMIT_SECURITY_QUESTIONS = 'SUBMIT_SECURITY_QUESTIONS';

export const FETCH_SAFETY_WALKS_REQUEST = 'FETCH_SAFETY_WALKS_REQUEST';
export const FETCH_SAFETY_WALKS_RESPONSE = 'FETCH_SAFETY_WALKS_RESPONSE';

export const ADD_SAFETY_WALK_REQUEST = 'ADD_SAFETY_WALK_REQUEST';

export const UPDATE_SAFETY_WALK_REQUEST = 'UPDATE_SAFETY_WALK_REQUEST';

export const DELETE_SAFETY_WALK_REQUEST = 'DELETE_SAFETY_WALK_REQUEST';

export const SET_ACTIVE_SAFETY_WALK = 'SET_ACTIVE_SAFETY_WALK';
export const LOAD_SAFETY_WALK = 'LOAD_ACTIVE_SAFETY_WALK';

export const FETCH_MY_DOCUMENTS_REQUEST = 'FETCH_MY_DOCUMENTS_REQUEST';
export const FETCH_MY_DOCUMENTS_RESPONSE = 'FETCH_MY_DOCUMENTS_RESPONSE';

export const SET_ACTIVE_MY_DOCUMENT = 'SET_ACTIVE_MY_DOCUMENT';

export const CREATE_MY_DOCUMENT_REQUEST = 'CREATE_MY_DOCUMENT_REQUEST';
export const UPDATE_MY_DOCUMENT_REQUEST = 'UPDATE_MY_DOCUMENT_REQUEST';
export const DELETE_MY_DOCUMENT_REQUEST = 'DELETE_MY_DOCUMENT_REQUEST';

export const FETCH_REQUIRE_AUTHORIZATION_REQUEST =
  'FETCH_REQUIRE_AUTHORIZATION_REQUEST';
export const FETCH_REQUIRE_AUTHORIZATION_RESPONSE =
  'FETCH_REQUIRE_AUTHORIZATION_RESPONSE';

export const CREATE_CONTINUOUS_CARE_REQUEST = 'CREATE_CONTINUOUS_CARE_REQUEST';
export const UPDATE_CONTINUOUS_CARE_REQUEST = 'UPDATE_CONTINUOUS_CARE_REQUEST';
export const DELETE_CONTINUOUS_CARE_REQUEST = 'DELETE_CONTINUOUS_CARE_REQUEST';

export const FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST =
  'FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST';
export const FETCH_CUSTOM_DOCUMENTS_TEMPLATE_RESPONSE =
  'FETCH_CUSTOM_DOCUMENTS_TEMPLATE_RESPONSE';

export const SET_ACTIVE_CUSTOM_DOCUMENT_TEMPLATE =
  'SET_ACTIVE_CUSTOM_DOCUMENT_TEMPLATE';

export const CREATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST =
  'CREATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST';
export const UPDATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST =
  'UPDATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST';
export const DELETE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST =
  'DELETE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST';

export const FETCH_CUSTOM_DOCUMENTS_REQUEST = 'FETCH_CUSTOM_DOCUMENTS_REQUEST';
export const FETCH_CUSTOM_DOCUMENTS_RESPONSE =
  'FETCH_CUSTOM_DOCUMENTS_RESPONSE';

export const SET_ACTIVE_CUSTOM_DOCUMENT = 'SET_ACTIVE_CUSTOM_DOCUMENT';

export const CREATE_CUSTOM_DOCUMENT_REQUEST = 'CREATE_CUSTOM_DOCUMENT_REQUEST';
export const UPDATE_CUSTOM_DOCUMENT_REQUEST = 'UPDATE_CUSTOM_DOCUMENT_REQUEST';
export const DELETE_CUSTOM_DOCUMENT_REQUEST = 'DELETE_CUSTOM_DOCUMENT_REQUEST';
export const DELETE_CUSTOM_DOCUMENT_ATTACHMENT_REQUEST =
  'DELETE_CUSTOM_DOCUMENT_ATTACHMENT_REQUEST';

export const FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST';

export const SET_ACTIVE_CUSTOM_REPORT_QUESTIONS =
  'SET_ACTIVE_CUSTOM_REPORT_QUESTIONS';
export const CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST';
export const UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST';
export const DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST';

export const RESEND_WELCOME_NOTIFICATION_REQUEST =
  'RESEND_WELCOME_NOTIFICATION_REQUEST';
export const RESET_EMPLOYEES_PASSWORD_REQUEST =
  'RESET_EMPLOYEES_PASSWORD_REQUEST';

export const FETCH_TRAININGS_REQUEST = 'FETCH_TRAININGS_REQUEST';
export const FETCH_TRAININGS_RESPONSE = 'FETCH_TRAININGS_RESPONSE';

export const ADD_TRAINING_REQUEST = 'ADD_TRAINING_REQUEST';

export const UPDATE_TRAINING_REQUEST = 'UPDATE_TRAINING_REQUEST';

export const DELETE_TRAINING_REQUEST = 'DELETE_TRAINING_REQUEST';

export const SET_ACTIVE_TRAINING_REQUEST = 'SET_ACTIVE_TRAINING_REQUEST';
export const SET_ACTIVE_TRAINING_RESPONSE = 'SET_ACTIVE_TRAINING_RESPONSE';

export const DELETE_SAFETY_TRAINING_ATTACHMENT =
  'DELETE_SAFETY_TRAINING_ATTACHMENT';

export const FETCH_CUSTOM_TRAINING_TEMPLATES_REQUEST =
  'FETCH_CUSTOM_TRAINING_TEMPLATES_REQUEST';
export const FETCH_CUSTOM_TRAINING_TEMPLATES_RESPONSE =
  'FETCH_CUSTOM_TRAINING_TEMPLATES_RESPONSE';

export const ADD_CUSTOM_TRAINING_TEMPLATE_REQUEST =
  'ADD_CUSTOM_TRAINING_TEMPLATE_REQUEST';

export const UPDATE_CUSTOM_TRAINING_TEMPLATE_REQUEST =
  'UPDATE_CUSTOM_TRAINING_TEMPLATE_REQUEST';

export const DELETE_CUSTOM_TRAINING_TEMPLATE_REQUEST =
  'DELTE_CUSTOM_TRAINING_TEMPLATE_REQUEST';

export const SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE =
  'SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE';

export const SET_ACTIVE_TRAINING_ATTACHMENT = 'SET_ACTIVE_TRAINING_ATTACHMENT';

export const CLEAR_USER = 'CLEAR_USER';
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_RESPONSE = 'LOAD_USER_RESPONSE';
