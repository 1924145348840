import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="30px"
    height="25px"
    viewBox="0 0 1000 1000"
  >
    <title>Asset 1dashboard</title>
    <g id="Layer_2">
      <g id="Layer_2-2">
        <path
          id="dashboard"
          d="M997.641,713.63c0,0.251,0,0.44,0,0.691c0,0.252,0,0.252,0,0.378c0.013,0.125,0.013,0.252,0,0.378
                    c0,13.153-10.664,23.816-23.817,23.816l0,0l-212.057-0.755c-13.153-0.004-23.813-10.671-23.81-23.824
                    c0.005-13.148,10.662-23.806,23.81-23.81h189.341c0.44-7.96,1.29-15.731,1.29-23.817
                    c2.082-249.844-198.769-454.069-448.612-456.151S49.716,409.306,47.634,659.149c-0.021,2.513-0.021,5.025,0,7.538
                    c0,8.055,0.818,15.731,1.259,23.817h189.215c13.153,0.004,23.813,10.671,23.809,23.824c-0.004,13.147-10.662,23.806-23.809,23.81
                    l-212.057,0.724c-13.154,0-23.817-10.663-23.817-23.817l0,0c-0.013-0.125-0.013-0.252,0-0.377c0,0,0-0.252,0-0.378s0-0.44,0-0.66
                    C0.944,698.15,0,682.671,0,666.688c-0.042-276.142,223.781-500.034,499.924-500.076S999.958,390.393,1000,666.535
                    c0,0.051,0,0.102,0,0.152C1000,682.671,999.088,698.15,997.641,713.63z M345.803,431.632c9.182-4.908,20.437-3.657,28.316,3.146
                    l-1.195-1.982c208.91,152.845,251.698,223.163,252.611,224.484c34.104,56.129,13.843,127.895-45.212,160.27
                    s-134.596,13.151-168.701-42.946c-0.817-1.259-43.418-71.766-78.372-320.035l1.227,1.95
                    C332.222,446.62,336.857,436.434,345.803,431.632z M454.442,751.195c13.85,22.106,38.16,35.464,64.246,35.301
                    c12.92,0.009,25.634-3.237,36.969-9.438c16.848-8.966,29.336-24.384,34.608-42.726c5.143-17.941,2.529-37.216-7.205-53.141
                    c-0.409-0.598-37.44-56.82-191.92-174.962C421.596,692.109,454.159,750.724,454.442,751.195z"
        />
      </g>
    </g>
  </svg>
);
