import React from 'react';
import moment from 'moment';
import * as api from '../../../api';
import Button from '../../inputs/Button';
import Card from '../../Card';
import UserBadge from '../../UserBadge';

import styles from './styles.module.scss';

const summaryInfo = summary => {
  const safeItems = {
    text: `${summary.safeItems} Safe Items`,
    color: 'white'
  };
  const unsafeItems = {
    text: `${summary.unsafeItems} Unsafe Items`,
    color: 'redOutline'
  };
  const correctiveActionsCreated = {
    text: `${summary.correctiveActionsCreated} Corrective Actions Assigned`,
    color: 'blueOutline'
  };
  const correctiveActionsCompleted = {
    text: `${summary.correctiveActionsCompleted} Corrective Actions Completed`,
    color: 'greenOutline'
  };
  const correctiveActionsPastDue = {
    text: `${summary.correctiveActionsPastDue} Corrective Actions Past Due`,
    color: summary.correctiveActionsPastDue ? 'redOutline' : 'black'
  };

  return [
    safeItems,
    unsafeItems,
    correctiveActionsCreated,
    correctiveActionsCompleted,
    correctiveActionsPastDue
  ];
};

const printRiskAssessment = async (activeRa, loggedInUser) => {
  const url = await api.printRA(activeRa);

  window.open(url, '_blank');
};

const titleText = (title, activeRa, index) => {
  let titleText = '';

  if (index === 0) {
    titleText += 'Title: ';
  }

  if (activeRa.isLocked) {
    titleText += `${title} ${
      activeRa.sections.filter(section => section.sectionName === title)[0]
        .sectionScore
    }%`;
  } else {
    titleText += title;
  }

  return titleText;
};

export default function RaSummaryCard({ activeRa, loggedInUser }) {
  const raTitles = activeRa?.sections?.map(section => section.sectionName);

  const footer = (
    <Button
      color="blue"
      text="Download Risk Assessment"
      onClick={() => printRiskAssessment(activeRa, loggedInUser)}
    />
  );

  return (
    <>
      <Card showFooter={activeRa.isLocked} footer={footer}>
        <div className={styles.raSummaryCard}>
          <div className={styles.usersInfo}>
            <div className={styles.rows}>
              <UserBadge
                userId={activeRa.createdByUserId}
                showImage
                showName
                byline="Created By"
                right
              />
              <div className={styles.title}>
                {raTitles?.map((title, index) => (
                  <div key={index}>{titleText(title, activeRa, index)}</div>
                ))}
              </div>
            </div>
            <div className={styles.rows}>
              <div className={styles.assignedTo}>
                {activeRa?.assignedTo?.map((assignee, index) => (
                  <UserBadge
                    userId={assignee}
                    showName
                    showImage
                    byline={activeRa.isLocked ? 'Performed By' : 'Assigned To'}
                    key={index}
                    right
                  />
                ))}
              </div>
              {activeRa.isLocked ? (
                <div>{`Completed On: ${moment(activeRa.updatedAt).format(
                  'MM/DD/YYYY hh:mma'
                )}`}</div>
              ) : (
                <div>{`Due By: ${moment(activeRa.dueDate).format(
                  'MM/DD/YYYY hh:mma'
                )}`}</div>
              )}
            </div>
          </div>
          {activeRa.isLocked && (
            <ul className={styles.summaryInfo}>
              {summaryInfo(activeRa.summary).map((info, index) => (
                <li key={index}>
                  <div className={styles[info.color]}>{info.text}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Card>
    </>
  );
}
