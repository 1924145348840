import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { isValid, submit } from 'redux-form';

import history from '../../history';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterAttachments,
  filterLocation,
  filterPerformedDate,
  filterPersonnel,
  sortDateMethod,
  sortPersonnel
} from '../../utils/sortFilterHelpers';
import AddToFolderModal from '../Modal/addToFolderModal';
import Button from '../inputs/Button';
import Checkbox from '../inputs/Checkbox';
import DocumentFolder from '../DocumentFolder';
import FileDownloader from '../FileDownloader';
import Modal from '../Modal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import ToolboxTalkForm from '../../forms/ToolboxTalkForm';

export class ToolboxTalksList extends Component {
  state = {
    modalOpen: false,
    addToFolder: [],
    addToFolderModal: false,
    documentFolderId: '',
    filters: []
  };

  componentDidMount() {
    if (localStorage.getItem('ttFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('ttFilters'))
      });
    }

    this.props.fetchDocumentFolders({
      documentType: 'ToolboxTalks',
      companyId: this.props.activeCompanyId
    });
    this.props.updateLeftNav('Toolbox Talks');
  }

  handleOpenModal() {
    this.setState({
      modalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }

  handleSubmit = values => {
    this.props.addToolboxTalk(values);
    this.props.clearAttachment();
    this.handleCloseModal();
  };

  handleAddToFolder = id => {
    this.setState(state => {
      let { addToFolder } = state;

      const qrIndex = addToFolder.findIndex(i => i === id);

      if (qrIndex > -1) {
        addToFolder = addToFolder.filter(i => i !== id);
      } else {
        addToFolder = [...addToFolder, id];
      }

      return { addToFolder };
    });
  };

  handleAddMultipleToFolders = () => {
    const { content } = this.props;
    let addDocsToFolder = [];

    const addToFolder = this.state.addToFolder;

    content.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({ addToFolderModal: false, addToFolder: [] });
  };

  handleFilterChange = filters => {
    localStorage.setItem('ttFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  render() {
    const {
      content,
      setToolboxTalk,
      activeEmployees,
      activeCompanyId,
      addAttachment,
      clearAttachment,
      loading,
      locations,
      accessLevel,
      deleteAttachment,
      addedAttachments,
      activeProject,
      activeLocation,
      activeFolder,
      documentFolders,
      isAddingToFolder
    } = this.props;

    const {
      modalOpen,
      addToFolder,
      addToFolderModal,
      documentFolderId,
      filters
    } = this.state;

    const getSupervisorName = supervisorId => {
      const personnel = activeEmployees.find(
        personnel => personnel?.userAccount?._id === supervisorId
      );

      return personnel
        ? `${personnel.person.firstName} ${personnel.person.lastName}`
        : '';
    };

    const getLocationName = locationId => {
      if (!locations || !locationId) return '';

      const location = locations.filter(o =>
        locationId.find(o2 => o._id === o2)
      );
      if (!location) return '';

      const locationMap = location.map(locate => locate.name);
      return locationMap.join(', ');
    };

    const folderList = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    return (
      <div>
        <ScrollToTopOnMount />
        <h2 className="documents-header">
          Toolbox Talks
          <div className="documents-buttonBox">
            {accessLevel !== '100' && (
              <Button
                className="add"
                text="Add Toolbox Talks"
                onClick={() => this.handleOpenModal()}
              />
            )}
          </div>
        </h2>
        {activeFolder.name && (
          <DocumentFolder
            activeFolder={activeFolder}
            accessLevel={accessLevel}
            updateDocumentFolder={this.props.updateDocumentFolder}
            deleteDocumentFolder={this.props.deleteDocumentFolder}
            username={this.props.user.username}
            content={content}
            docType="Toolbox Talk"
          />
        )}
        <div className="documents-list">
          <ReactTable
            data={content}
            filterable
            onFilteredChange={filtered => this.handleFilterChange(filtered)}
            filtered={filters}
            resizable={false}
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            columns={[
              {
                Header: () =>
                  isAddingToFolder ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#58a15b"
                      className="loading"
                    />
                  ) : (
                    <Button
                      className="blueButton"
                      text="Add to Folder"
                      onClick={() => this.setState({ addToFolderModal: true })}
                    />
                  ),
                show: accessLevel !== '100',
                Cell: row => (
                  <Checkbox
                    name={row.original._id}
                    onChange={() => this.handleAddToFolder(row.original._id)}
                    currentValue={addToFolder.indexOf(row.original._id) !== -1}
                  />
                ),
                filterable: false,
                sortable: false
              },
              {
                Header: 'LOCATION',
                accessor: 'groupIds',
                Cell: ({ original }) => (
                  <div>{getLocationName(original.groupIds)}</div>
                ),
                sortable: false,
                filterMethod: (filter, row) =>
                  filterLocation(filter, row, locations),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    {locations.map((location, index) => (
                      <option key={index} value={location._id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                )
              },
              {
                Header: 'DATE',
                accessor: 'expires',
                Cell: ({ original }) => (
                  <div>{moment(original.expires).format('MM/DD/YYYY')}</div>
                ),
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterPerformedDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Performed Today</option>
                    <option value="last7">Performed within last week</option>
                    <option value="last30">Performed within last month</option>
                    <option value="moreThan30">
                      Performed before last month
                    </option>
                  </select>
                )
              },
              {
                Header: 'SUPERVISOR',
                id: 'supervisor',
                accessor: d => d?.createdByUserId,
                sortMethod: (a, b) => sortPersonnel(a, b, activeEmployees),
                filterMethod: (filter, row) =>
                  filterPersonnel(filter, row, activeEmployees),
                Cell: ({ original }) => (
                  <div>{getSupervisorName(original?.createdByUserId)}</div>
                )
              },
              {
                Header: 'ATTACHMENT(S)',
                accessor: 'attachments',
                Cell: ({ original }) => (
                  <div>
                    {original.attachments.map((attachment, attachmentIndex) => (
                      <FileDownloader
                        attachment={attachment}
                        key={attachmentIndex}
                        attachmentIndex={attachmentIndex}
                        disabled
                        isInList
                      />
                    ))}
                  </div>
                ),
                filterMethod: (filter, row) => filterAttachments(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="hasAttachments">Has Attachments</option>
                    <option value="noAttachments">No Attachments</option>
                  </select>
                ),
                sortable: false
              },
              {
                Header: 'VIEW/EDIT',
                Cell: row => (
                  <Button
                    color="blue"
                    text="View/Edit"
                    onClick={() => {
                      setToolboxTalk(row.original);
                      history.push('/app/viewEdit/Toolbox Talk');
                    }}
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
            noDataText="No results found"
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
        {modalOpen && (
          <Modal
            title="Add New Toolbox Talk"
            titleClassName="greenHeader"
            isOpen={modalOpen}
            wide
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={this.props.submitForm}
            disableSubmit={!this.props.formValid}
          >
            <ToolboxTalkForm
              onSubmit={values => this.handleSubmit(values)}
              activeEmployees={activeEmployees}
              activeCompanyId={activeCompanyId}
              addAttachment={addAttachment}
              clearAttachment={clearAttachment}
              loading={loading}
              locations={locations}
              deleteAttachment={deleteAttachment}
              addedAttachments={addedAttachments}
              activeProject={activeProject}
              activeLocation={activeLocation}
              documentFolders={documentFolders}
              hasAccess={accessLevel !== '100'}
            />
          </Modal>
        )}
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={() => this.handleAddMultipleToFolders()}
          folderOptions={folderList}
          handleChange={values =>
            this.setState({ documentFolderId: values.value })
          }
        />
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  formValid: isValid('ToolboxTalkForm')(state),
});

export const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('ToolboxTalkForm'))
});

export default connect(mapStateToProps, mapDispatchToProps)(ToolboxTalksList);
