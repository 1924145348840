import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterPerformedDate,
  filterRiskValue,
  filterUser,
  sortDateMethod,
  sortRiskValue,
  sortUser
} from '../../utils/sortFilterHelpers';
import severityDescriptionMapper from '../../utils/severityDescriptionMapper';
import Button from '../inputs/Button';
import UserBadge from '../UserBadge';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

import 'react-table/react-table.css';

const IncidentsList = ({ content, setActiveIncident, activeEmployeeList }) => {
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    if (localStorage.getItem('incidentFilters')) {
      setFilters(JSON.parse(localStorage.getItem('incidentFilters')));
    }
  }, []);

  return (
    <div>
      <ScrollToTopOnMount />
      <ReactTable
        data={content}
        filterable
        filtered={filters}
        onFilteredChange={filtered => {
          localStorage.setItem('incidentFilters', JSON.stringify(filtered));
          setFilters(filtered);
        }}
        resizable={false}
        defaultFilterMethod={(filter, row) => defaultFilterMethod(filter, row)}
        defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
        noDataText="No results found"
        defaultPageSize={10}
        className="-striped -highlight"
        columns={[
          {
            Header: 'INCIDENT NUMBER',
            accessor: d => d.incidentNumber,
            id: 'incidentNumber'
          },
          {
            Header: 'SEVERITY',
            accessor: 'riskValue',
            sortMethod: (a, b) => sortRiskValue(a, b),
            filterMethod: (filter, row) => filterRiskValue(filter, row),
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'All Folders'}
              >
                <option value="all">Show All</option>
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            ),
            Cell: ({ original }) =>
              original.answers
                ? severityDescriptionMapper(original.riskValue)
                : ''
          },
          {
            Header: 'REPORTER',
            accessor: 'createdByUserId',
            sortMethod: (a, b) => sortUser(a, b, activeEmployeeList),
            filterMethod: (filter, row) =>
              filterUser(filter, row, activeEmployeeList),
            Cell: ({ value }) => <UserBadge showName plain userId={value} />
          },
          {
            Header: 'DATE OCCURRED',
            accessor: 'dateIncidentOccured',
            Cell: ({ original }) =>
              `${moment(Date.parse(original.dateIncidentOccured)).format(
                'MM/DD/YYYY hh:mm a'
              )}`,
            sortMethod: (a, b) => sortDateMethod(a, b),
            filterMethod: (filter, row) => filterPerformedDate(filter, row),
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'All Folders'}
              >
                <option value="all">Show All</option>
                <option value="today">Occurred Today</option>
                <option value="last7">Occurred within Last Week</option>
                <option value="last30">Occurred This Month</option>
                <option value="thisQuarter">Occurred This Quarter</option>
                <option value="thisYear">Occurred This Year</option>
              </select>
            )
          },
          {
            Header: 'UPDATED DATE',
            accessor: 'updatedAt',
            Cell: ({ original }) =>
              `${moment(Date.parse(original.updatedAt)).format(
                'MM/DD/YYYY hh:mm a'
              )}`,
            sortMethod: (a, b) => sortDateMethod(a, b),
            filterMethod: (filter, row) => filterPerformedDate(filter, row),
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'All Folders'}
              >
                <option value="all">Show All</option>
                <option value="today">Updated Today</option>
                <option value="last7">Updated within Last Week</option>
                <option value="last30">Updated This Month</option>
                <option value="thisQuarter">Updated This Quarter</option>
                <option value="thisYear">Updated This Year</option>
              </select>
            )
          },
          {
            Header: 'VIEW/EDIT',
            Cell: ({ original }) => (
              <Button
                text="View/Edit"
                color="blue"
                onClick={() => setActiveIncident(original)}
              />
            ),
            filterable: false,
            sortable: false
          }
        ]}
      />
    </div>
  );
};

export default IncidentsList;
