import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { isValid } from 'redux-form';

import history from '../../history';
import expirationMapper from '../../utils/expirationMapper';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  addDocumentsToFolderRequest,
  addSafetyInstructionRequest,
  deleteDocumentFolderRequest,
  fetchDocumentFoldersRequest,
  fetchSafetyInstructionRequest,
  setActiveSafetyInstruction,
  updateDocumentFolderRequest
} from '../../actions/documents';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getActiveFolderSelector,
  getCompanySafetyInstructionsSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterAttachments,
  filterExpireDate,
  filterPriority,
  sortDateMethod,
  sortPriority
} from '../../utils/sortFilterHelpers';
import priorityMapper from '../../utils/priorityMapper';
import AddToFolderModal from '../Modal/addToFolderModal';
import Button from '../inputs/Button';
import Checkbox from '../inputs/Checkbox';
import DocumentFolder from '../DocumentFolder';
import FileDownloader from '../FileDownloader';
import Modal from '../Modal';
import SafetyInstructionForm from '../../forms/SafetyInstructionForm';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import HeaderAndFooter from '../HeaderAndFooter';
import Header from '../Header';
import Card from '../Card';

export class SafetyInstructionList extends Component {
  state = {
    openModal: false,
    addToFolder: [],
    addToFolderModal: false,
    documentFolderId: '',
    filters: []
  };

  componentDidMount() {
    this.props.fetchSafetyInstructions({ page: 1, limit: 10000 });
    if (localStorage.getItem('safetyProcFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('safetyProcFilters'))
      });
    }

    this.props.fetchDocumentFolders({
      documentType: 'SafetyInstruction',
      companyId: this.props.activeCompany._id
    });
    this.props.updateLeftNav('Safety Procedures');
  }

  handleOpenModal = () => this.setState({ openModal: true });
  handleCloseModal = () => this.setState({ openModal: false });

  handleSubmit = values => {
    this.props.addSafetyInstruction(values);
    this.props.clearAttachment();
    this.handleCloseModal();
  };

  handleAddToFolder = id => {
    this.setState(state => {
      let { addToFolder } = state;

      const qrIndex = addToFolder.findIndex(i => i === id);

      if (qrIndex > -1) {
        addToFolder = addToFolder.filter(i => i !== id);
      } else {
        addToFolder = [...addToFolder, id];
      }

      return { addToFolder };
    });
  };

  handleAddMultipleToFolders = () => {
    const { content } = this.props;
    let addDocsToFolder = [];

    const addToFolder = this.state.addToFolder;

    content.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({ addToFolderModal: false, addToFolder: [] });
  };

  handleFilterChange = filters => {
    localStorage.setItem('safetyProcFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  render() {
    const {
      content,
      setSafetyInstruction,
      addAttachment,
      clearAttachment,
      activeCompany,
      loading,
      loggedInUser,
      deleteAttachment,
      addedAttachments,
      activeLocation,
      activeProject,
      activeFolder,
      documentFolders,
      isAddingToFolder,
      formValid
    } = this.props;

    const {
      openModal,
      addToFolder,
      addToFolderModal,
      documentFolderId,
      filters
    } = this.state;

    const accessLevel = loggedInUser
      ? parseInt(loggedInUser.accessLevel, 10)
      : 0;
    const priorityList = ['Low', 'Medium', 'High'];
    const folderList = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    const header = (
      <Header
        title="Safety Procedures"
        rightButtons={[
          {
            color: 'green',
            text: 'Add Safety Procedure',
            visible: accessLevel !== 100,
            onClick: this.handleOpenModal
          }
        ]}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header}>
          <Card wide>
            <ScrollToTopOnMount />
            {activeFolder.name && (
              <DocumentFolder
                activeFolder={activeFolder}
                accessLevel={accessLevel.toString()}
                updateDocumentFolder={this.props.updateDocumentFolder}
                deleteDocumentFolder={this.props.deleteDocumentFolder}
                username={loggedInUser.username}
                content={content}
                docType="Safety Training"
              />
            )}
            <div className="documents-list">
              <ReactTable
                data={content}
                onFilteredChange={filtered => this.handleFilterChange(filtered)}
                filtered={filters}
                filterable
                resizable={false}
                defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
                defaultFilterMethod={(filter, row) =>
                  defaultFilterMethod(filter, row)
                }
                columns={[
                  {
                    Header: () =>
                      isAddingToFolder ? (
                        <ReactLoading
                          type="spinningBubbles"
                          color="#58a15b"
                          className="loading"
                        />
                      ) : (
                        <Button
                          className="blueButton"
                          text="Add to Folder"
                          onClick={() =>
                            this.setState({ addToFolderModal: true })
                          }
                        />
                      ),
                    show: accessLevel !== 100,
                    Cell: row => (
                      <Checkbox
                        name={row.original._id}
                        onChange={() =>
                          this.handleAddToFolder(row.original._id)
                        }
                        currentValue={
                          addToFolder.indexOf(row.original._id) !== -1
                        }
                      />
                    ),
                    filterable: false,
                    sortable: false
                  },
                  {
                    Header: 'TITLE',
                    accessor: 'label'
                  },
                  {
                    Header: 'ATTACHMENT(S)',
                    accessor: 'attachments',
                    Cell: ({ original }) => (
                      <div>
                        {original.attachments.map(
                          (attachment, attachmentIndex) => (
                            <FileDownloader
                              attachment={attachment}
                              key={attachmentIndex}
                              attachmentIndex={attachmentIndex}
                              disabled
                              isInList
                            />
                          )
                        )}
                      </div>
                    ),
                    filterMethod: (filter, row) =>
                      filterAttachments(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'All Folders'}
                      >
                        <option value="all">Show All</option>
                        <option value="hasAttachments">Has Attachments</option>
                        <option value="noAttachments">No Attachments</option>
                      </select>
                    ),
                    sortable: false
                  },
                  {
                    Header: 'PRIORITY LEVEL',
                    id: 'priority',
                    accessor: d =>
                      d.documentData && d.documentData.currentPriority,
                    Cell: ({ original }) => (
                      <div>
                        {original.documentData &&
                        original.documentData.currentPriority
                          ? priorityList[
                              priorityMapper(
                                original.documentData.currentPriority
                              ) - 1
                            ]
                          : null}
                      </div>
                    ),
                    sortMethod: (a, b) => sortPriority(a, b),
                    filterMethod: (filter, row) => filterPriority(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'All Folders'}
                      >
                        <option value="all">Show All</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </select>
                    )
                  },
                  {
                    Header: 'EXPIRATION DATE',
                    accessor: 'expires',
                    Cell: ({ original }) =>
                      original.expires && (
                        <div className="documents-list--severity">
                          <img
                            src={expirationMapper(original.expires)}
                            alt="severity"
                          />
                          <span>
                            {moment(original.expires).format('MM/DD/YYYY')}
                          </span>
                        </div>
                      ),
                    sortMethod: (a, b) => sortDateMethod(a, b),
                    filterMethod: (filter, row) =>
                      filterExpireDate(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'All Folders'}
                      >
                        <option value="all">Show All</option>
                        <option value="today">Expired</option>
                        <option value="within30">
                          Expiring within 30 Days
                        </option>
                        <option value="within60">
                          Expiring within 60 Days
                        </option>
                        <option value="past90">Expiring after 60 Days</option>
                      </select>
                    )
                  },
                  {
                    Header: 'VIEW/EDIT',
                    Cell: row => (
                      <Button
                        color="blue"
                        text="View/Edit"
                        onClick={() => {
                          setSafetyInstruction(row.original);
                          history.push('/app/viewEdit/Safety Procedure');
                        }}
                      />
                    ),
                    filterable: false,
                    sortable: false
                  }
                ]}
                noDataText="No results found"
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </Card>
        </HeaderAndFooter>
        {openModal && (
          <Modal
            title="Add Safety Procedure"
            titleClassName="greenHeader"
            wide
            submitButtonColor="green"
            isOpen={openModal}
            onRequestClose={() => clearAttachment() && this.handleCloseModal()}
            formName="SafetyInstructionForm"
            disableSubmit={!formValid}
          >
            <SafetyInstructionForm
              onSubmit={values => this.handleSubmit(values)}
              addAttachment={addAttachment}
              activeCompanyId={activeCompany._id}
              loading={loading}
              locationList={activeCompany.groups}
              deleteAttachment={deleteAttachment}
              addedAttachments={addedAttachments}
              activeLocation={activeLocation}
              activeProject={activeProject}
              documentFolders={documentFolders}
            />
          </Modal>
        )}
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={() => this.handleAddMultipleToFolders()}
          folderOptions={folderList}
          handleChange={values =>
            this.setState({ documentFolderId: values.value })
          }
        />
      </>
    );
  }
}

export const mapStateToProps = state => ({
  content: getCompanySafetyInstructionsSelector(state),
  activeCompany: getActiveCompany(state),
  loading: getAttachmentLoadingSelector(state),
  loggedInUser: getLoggedInUser(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeProject: getActiveProject(state),
  activeLocation: getActiveLocationId(state),
  activeFolder: getActiveFolderSelector(state),
  documentFolders: getDocumentFoldersSelector(state),
  formValid: isValid('SafetyInstructionForm')(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  addSafetyInstruction: safetyInstruction =>
    dispatch(addSafetyInstructionRequest(safetyInstruction)),
  setSafetyInstruction: safetyInstruction =>
    dispatch(setActiveSafetyInstruction(safetyInstruction)),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  updateDocumentFolder: values => dispatch(updateDocumentFolderRequest(values)),
  deleteDocumentFolder: values => dispatch(deleteDocumentFolderRequest(values)),
  addDocumentToFolder: values => dispatch(addDocumentsToFolderRequest(values)),
  fetchSafetyInstructions: paginationInfo =>
    dispatch(fetchSafetyInstructionRequest(paginationInfo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SafetyInstructionList);
