import { createSelector } from 'reselect';

const companySelector = state => state.company;
const personnelSelector = state => state.personnel;

export const getActiveLocationId = createSelector(
  companySelector,
  activeLocationId => activeLocationId && activeLocationId.activeLocationId
);

export const getActiveEmployeeSelector = createSelector(
  personnelSelector,
  activeEmployee => activeEmployee && activeEmployee.activeEmployee
);

export const getCompanyUsersSelector = createSelector(
  personnelSelector,
  users => users && users.users
);

export const getCompanyActiveEmployeesSelector = createSelector(
  personnelSelector,
  employees => employees && employees.activeEmployees
);

export const getIsDownloadQrLoadingSelector = createSelector(
  personnelSelector,
  personnel => personnel && personnel.isDownloadQrLoading
);

export const getActiveEmployeeDocsSelector = createSelector(
  personnelSelector,
  personnel => personnel && personnel.activeEmployeeDocs
);

export const getPersonnelLoadingSelector = createSelector(
  personnelSelector,
  personnel => personnel && personnel.loading
);

export const getPersonnelUploadErrorSelector = createSelector(
  personnelSelector,
  personnel => personnel && personnel.uploadError
);
