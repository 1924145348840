import React, { Component } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import isEqual from 'lodash/isEqual';
import MD5 from 'md5.js';

import { classnames } from '../../utils/classNamesHelper';
import { fetchAnalyticsPreview } from '../../api';
import { inflateChartSettings } from '../../utils/analytics';
import Loading from '../Loading';

import styles from './analyticsChart.module.scss';

class AnalyticsChart extends Component {
  state = { data: null };

  loadData() {
    if (!this.props.settings) return;

    fetchAnalyticsPreview(
      inflateChartSettings(this.props.settings, this.props.period)
    ).then(data => {
      this.setState(data);
    });
  }

  getColorOffset(l) {
    const { datasource, category, filterArgs, series } = this.props.settings;
    let settings = { datasource, category, filterArgs, series };
    let hash = new MD5().update(JSON.stringify(settings)).digest();
    return hash.reduce((a, v) => (a + v) % l);
  }

  darkenColor(col, amt) {
    col = col.slice(1);

    var num = parseInt(col, 16);
    var r = (num >> 16) - amt;
    var b = ((num >> 8) & 0xff) - amt;
    var g = (num & 0xff) - amt;

    if (r < 0) r = 0;
    if (b < 0) b = 0;
    if (g < 0) g = 0;

    return '#' + (g | (b << 8) | (r << 16)).toString(16);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.settings, prevProps.settings) ||
      !isEqual(this.props.period, prevProps.period)
    ) {
      this.setState({ data: null }, this.loadData);
    }
  }

  render() {
    if (!this.state.data || !this.props.settings) {
      return (
        <Loading
          width={this.props.width}
          height={this.props.height}
          mode="fixedSize"
        />
      );
    }
    if (this.state.data.length === 0) {
      return (
        <div
          className={classnames(styles.noData, this.props.noDataClassName)}
          style={{ width: this.props.width, height: this.props.height }}
        >
          No Data
        </div>
      );
    }
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        {this.renderChart()}
      </div>
    );
  }

  renderChart() {
    let keys = {};
    let total = 0;
    let data = this.state.data.map(datum => {
      let key = {
        label: datum.label,
        sum: datum.sum
      };
      total += datum.sum;

      if (datum.series) {
        datum.series.forEach(s => {
          keys[s.label] = 1;
          key[s.label] = s.sum;
        });
      }

      return key;
    });
    let usedKeys = Object.keys(keys);
    let colors = [
      '#800000',
      '#805500',
      '#558000',
      '#008000',
      '#008055',
      '#005580',
      '#000080',
      '#550080',
      '#800055'
    ];
    let offset = this.getColorOffset(colors.length);
    let prepend = this.state.yAxisOptions?.prependUnit || '';
    let label =
      this.state.yAxisOptions?.graphLabel ||
      this.state.yAxisOptions?.label ||
      undefined;
    switch (this.props.settings.type) {
      case 'pie':
        return (
          <PieChart
            width={this.props.width}
            height={this.props.height}
            key={this.props.width}
          >
            {/* <Legend /> */}
            <Tooltip
              formatter={v =>
                `${prepend}${v} (${((v * 100) / total).toFixed()}%)`
              }
            />
            <Pie data={data} dataKey="sum" nameKey="label" label>
              {data.map((k, i) => (
                <Cell key={k} fill={colors[(i + offset) % colors.length]} />
              ))}
              <LabelList position="outside" />
            </Pie>
          </PieChart>
        );
      case 'line':
        return (
          <LineChart
            data={data}
            width={this.props.width}
            height={this.props.height}
            key={this.props.width}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip formatter={v => `${prepend}${v}`} />
            <XAxis dataKey="label" xAxisId={0} />
            <YAxis tickFormatter={v => `${prepend}${v}`}>
              {label && (
                <Label angle={90} position="insideLeft" value={label} />
              )}
            </YAxis>
            {usedKeys.length ? (
              usedKeys.map((k, i) => (
                <Line
                  type="monotone"
                  dataKey={o => o[k] || 0}
                  name={k}
                  key={k}
                  stroke={colors[(i + offset) % colors.length]}
                  xAxisId={0}
                  connectNulls
                />
              ))
            ) : (
              <Line
                type="monotone"
                dataKey="sum"
                stroke={colors[offset % colors.length]}
                xAxisId={0}
                connectNulls
              />
            )}
          </LineChart>
        );
      case 'bar':
      default:
        return (
          <BarChart
            data={data}
            width={this.props.width}
            height={this.props.height}
            key={this.props.width}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip formatter={v => `${prepend}${v}`} />
            <XAxis dataKey="label" />
            <YAxis tickFormatter={v => `${prepend}${v}`}>
              {label && (
                <Label angle={90} position="insideLeft" value={label} />
              )}
            </YAxis>
            {/* <Legend /> */}
            {usedKeys.length ? (
              usedKeys.map((k, i) => (
                <Bar
                  dataKey={k}
                  key={k}
                  fill={colors[(i + offset) % colors.length]}
                  stroke={this.darkenColor(
                    colors[(i + offset) % colors.length],
                    48
                  )}
                  stackId="0"
                />
              ))
            ) : (
              <Bar
                dataKey="sum"
                fill={colors[offset % colors.length]}
                stroke={this.darkenColor(colors[offset % colors.length], 48)}
              />
            )}
          </BarChart>
        );
    }
  }
}

export default AnalyticsChart;
