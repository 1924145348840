import {
  FETCH_LEFT_NAV_REQUEST,
  UPDATE_LEFT_NAV_REQUEST
} from '../constants/actionTypes';

export const fetchLeftNavRequest = () => {
  return {
    type: FETCH_LEFT_NAV_REQUEST
  };
};

export const updateLeftNavRequest = payload => {
  return {
    type: UPDATE_LEFT_NAV_REQUEST,
    payload
  };
};
