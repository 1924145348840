import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import {
  createCustomTemplateRequest,
  deleteCustomTemplateRequest,
  setActiveRaCustomTemplate,
  updateCustomTemplateRequest
} from '../../actions/jsa';
import { updateLeftNavRequest } from '../../actions/navigation';
import { getActiveCustomRaTemplateSelector } from '../../selectors/jsa';
import { getLoggedInUser } from '../../selectors/users';
import CustomRaCard from '../../components/raCards/CustomRaCard';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';

export class CustomRaContainer extends Component {
  state = {
    openUnsavedTemplateModal: false,
    openUnsavedChangesModal: false,
    openDeleteTemplateModal: false,
    title: '',
    description: '',
    questions: [],
    isCreating: false,
    isEditing: false,
    hasUnsavedChanges: false,
    _id: undefined
  };

  componentDidMount() {
    let { activeTemplate } = this.props;
    this.props.updateLeftNav('Custom Risk Assessments');

    if (this.props.activeTemplate._id) {
      this.setState({
        title: activeTemplate.title,
        description: activeTemplate.description,
        questions: activeTemplate.questions.map(q => q.question),
        _id: activeTemplate._id
      });
    } else {
      this.setState({ isCreating: true });
    }
  }

  handleCustomSubmit = () => {
    if (this.state._id) {
      this.props.updateCustomTemplate(this.state);
    } else {
      this.props.createCustomTemplate(this.state);
    }
  };

  handleCustomDelete = () => {
    this.props.deleteCustomTemplate(this.state);
  };

  canSubmit = () => {
    const questionsHaveWords = this.state.questions.filter(
      question => !question
    );

    return (
      this.state.title &&
      this.state.description &&
      this.state.questions.length > 0 &&
      questionsHaveWords.length === 0
    );
  };

  render() {
    const {
      openUnsavedTemplateModal,
      openUnsavedChangesModal,
      openDeleteTemplateModal,
      title,
      description,
      questions,
      isCreating,
      isEditing,
      hasUnsavedChanges
    } = this.state;

    const { setActiveRaCustomTemplate } = this.props;
    const header = (
      <Header
        title="Custom Risk Assessments"
        section={
          !isEditing && !isCreating
            ? 'View Template'
            : isCreating
            ? 'Create New Template'
            : 'Edit Template'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges && isEditing
            ? this.setState({ openUnsavedChangesModal: true })
            : hasUnsavedChanges && isCreating
            ? this.setState({ openUnsavedTemplateModal: true })
            : setActiveRaCustomTemplate({}) && history.goBack()
        }
        rightButtons={{
          text: isEditing ? 'Delete Template' : 'Edit Template',
          color: isEditing ? 'red' : 'blue',
          visible: !isCreating,
          onClick: () =>
            isEditing
              ? this.setState({ openDeleteTemplateModal: true })
              : this.setState({ isEditing: true })
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={this.handleCustomSubmit}
        cancelButtonClick={() =>
          isEditing && hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : isEditing && !hasUnsavedChanges
            ? this.setState({ isEditing: false })
            : this.setState({ openUnsavedChangesModal: true })
        }
        editing={isEditing}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isEditing || isCreating}
        >
          <CustomRaCard
            onChange={values =>
              this.setState({ ...values, hasUnsavedChanges: true })
            }
            title={title}
            description={description}
            questions={questions}
            disabled={!isEditing && !isCreating}
          />
        </HeaderAndFooter>
        <Modal
          title="Unsaved Template"
          titleClassName="redHeader"
          isOpen={openUnsavedTemplateModal}
          submitButtonColor="red"
          submitButtonText="Discard Template"
          onRequestClose={() =>
            this.setState({ openUnsavedTemplateModal: false })
          }
          submitActions={() =>
            setActiveRaCustomTemplate({}) && history.goBack()
          }
        >
          <div className="reportSectionContainer-emailAssigneeText">
            You are navigating away from a template that you were creating.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Leaving without saving will result in this template not being
              created.{' '}
            </span>
            If you wish to keep your template, click “Cancel”, and then hit
            “Save Template” at the bottom of the create template screen.
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() =>
            setActiveRaCustomTemplate({}) && history.goBack()
          }
          savingWhat="a template"
        />
        <Modal
          title="Delete Template"
          titleClassName="redHeader"
          isOpen={openDeleteTemplateModal}
          submitButtonColor="red"
          submitButtonText="Delete Template"
          onRequestClose={() =>
            this.setState({ openDeleteTemplateModal: false })
          }
          submitActions={() => this.handleCustomDelete()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting a template will permanently remove it for all users. Be
              sure that no one else needs to use this template before deleting.{' '}
            </span>
            Any templates that have already been assigned will not be affected,
            and can be completed normally.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              However any templates that have been set to repeating will no
              longer do so.{' '}
            </span>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTemplate: getActiveCustomRaTemplateSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation)),
  createCustomTemplate: values => dispatch(createCustomTemplateRequest(values)),
  updateCustomTemplate: values => dispatch(updateCustomTemplateRequest(values)),
  deleteCustomTemplate: values => dispatch(deleteCustomTemplateRequest(values)),
  setActiveRaCustomTemplate: values =>
    dispatch(setActiveRaCustomTemplate(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomRaContainer);
