import React, { Component } from 'react';

import Dropdown from '../../components/inputs/Dropdown';
import { classnames } from '../../utils/classNamesHelper';

import styles from './stageSelector.module.scss';

export class StageSelector extends Component {
  render() {
    let {
      onChange,
      className,
      label,
      ...props
    } = this.props;
    let classes = [styles.container, className];
    return (
      <div className={classnames(classes)}>
        <div className={styles.stageLabel}>{label}:</div>
        <Dropdown
          handleChange={onChange}
          inputClassName={styles.input}
          {...props}
        />
      </div>
    );
  }
}

export default StageSelector;
