import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { REHYDRATE } from 'redux-persist';

import analytics from './analytics';
import attachments from './attachments';
import auth from './auth';
import company from './company';
import documents from './documents';
import incidents from './incidents';
import jsa from './jsa';
import messages from './messages';
import navigation from './navigation';
import oshaLogs from './oshaLogs';
import payments from './payments';
import personnel from './personnel';
import reports from './reports';
import safetyWalks from './safetyWalks';
import search from './search';
import tasks from './tasks';
import training from './training';
import user from './user';
import loading from './loading';
import { AUTH_SIGNOUT } from '../constants/actionTypes';

const appReducer = combineReducers({
  analytics,
  attachments,
  auth,
  company,
  documents,
  form: formReducer,
  incidents,
  jsa,
  messages,
  navigation,
  oshaLogs,
  payments,
  personnel,
  reports,
  safetyWalks,
  search,
  tasks,
  training,
  user,
  loading
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_SIGNOUT) {
    state = undefined;
  }
  if (action.type === REHYDRATE) {
    if (action?.payload?.loading) {
      action.payload.loading = 0;
    }
    if (action?.payload?.user?.users) {
      action.payload.user.users = {};
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
