import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import ReactLoading from 'react-loading';
import moment from 'moment';

import { loadDashboard } from '../../api';
import { clearUploadedAttachments } from '../../actions/attachments';
import {
  fetchCompanyIncidentCircles,
  setActiveIncidentRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import { loadSafetyWalk } from '../../actions/safetyWalks';
import { fetchUsersJsaRequest, setActiveJsaRequest } from '../../actions/jsa';
import { updateLeftNavRequest } from '../../actions/navigation';
import { fetchAccountInformationRequest } from '../../actions/payment';
import { fetchAllActiveEmployeesActiveUsers } from '../../actions/personnel';
import {
  fetchUsersTasksRequest,
  setActiveTaskRequest
} from '../../actions/tasks';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import { getIncidentCircleSelector } from '../../selectors/incidents';
import {
  getJsaLoadingSelector,
  getJsaPaginationSelector,
  getJsasSelector
} from '../../selectors/jsa';
import { getMessageSelector } from '../../selectors/messages';
import {
  getTaskLoadingSelector,
  getTaskPaginationInfoSelector,
  getUserTasksSelector
} from '../../selectors/tasks';
import { getLoggedInUser } from '../../selectors/users';
import { AnalyticCircleBox } from '../../components/AnalyticCircle';
import { IncidentCard, SafetyWalkCard } from '../../components/DashboardCard';
import PaginationList from '../../components/PaginationList';
import ScheduledAuditWidget from '../../components/ScheduledAuditWidget';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import TaskWidget from '../../components/TaskWidget';
import Header from '../../components/Header';

import './index.css';

export class Dashboard extends Component {
  state = {
    isLoading: 0,
    incidents: null,
    safetyWalks: null,
    page: 1
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount = async () => {
    this.props.activeIncidentResponse();
    this.props.fetchAllActiveEmployeesActiveUsers();
    this.props.fetchUsersTasks(1);
    this.props.fetchUserJsas(1);
    this.props.updateLeftNav('Dashboard');
    this.props.clearAttachments();
    this.props.fetchAccountInformation();
    this.props.fetchCircles();
    loadDashboard({
      groupId: this.props.activeLocationId._id,
      projectId: this.props.activeProject?._id
    }).then(data => {
      this.setState({
        ...data,
        page: 1
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }

    if (prevProps.activeLocationId._id !== this.props.activeLocationId._id) {
      loadDashboard({
        groupId: this.props.activeLocationId._id,
        projectId: this.props.activeProject?._id
      }).then(data => {
        this.setState({
          ...data,
          page: 1
        });
      });
    }
  }

  renderIncident = incident => (
    <IncidentCard
      incident={incident}
      key={incident._id}
      onClick={() =>
        this.props.setActiveIncident(incident) &&
        this.setState({ isLoading: incident._id })
      }
      incidentLoading={this.state.isLoading === incident._id}
      showAttachments
    />
  );

  renderSafetyWalk = safetyWalk => (
    <SafetyWalkCard
      safetyWalk={safetyWalk}
      key={safetyWalk._id}
      isLoading={this.state.isLoading === safetyWalk._id}
      onClick={() => {
        this.props.loadSafetyWalk(safetyWalk._id);
        this.setState({ isLoading: safetyWalk._id });
      }}
    />
  );

  render() {
    const {
      tasks,
      audits,
      circles,
      setActiveTask,
      setActiveJsa,
      taskLoading,
      jsaLoading,
      loggedInUser,
      taskPagination,
      fetchUsersTasks,
      jsaPagination,
      fetchUserJsas
    } = this.props;

    const { safetyWalks, incidents, page } = this.state;

    let cards;
    let total;
    let totalPages = 0;
    if (safetyWalks !== null && incidents !== null) {
      cards = [
        ...safetyWalks.map(s => ({
          ...s,
          type: 'safetyWalk',
          updatedAt: moment(s.updatedAt),
          createdAt: moment(s.createdAt)
        })),
        ...incidents.map(i => ({
          ...i,
          type: 'incident',
          updatedAt: moment(i.updatedAt)
        }))
      ].sort((a, b) => b.updatedAt.diff(a.updatedAt));
      total = cards.length;
      cards = cards.slice((page - 1) * 10, page * 10);
      totalPages = Math.ceil(total / 10);
    }

    return (
      <div className="dashboard">
        <ScrollToTopOnMount />
        <Header title="Dashboard" />
        <AnalyticCircleBox circles={circles} />
        <div className="dashboard-content">
          <div className="dashboard-incidents">
            {cards === undefined ? (
              <ReactLoading
                type="spinningBubbles"
                color="#2f8738"
                className="loading"
              />
            ) : cards.length !== 0 ? (
              cards.map((card, index) => {
                switch (card.type) {
                  case 'incident':
                    return this.renderIncident(card);
                  case 'safetyWalk':
                    return this.renderSafetyWalk(card);
                  default:
                    return <></>;
                }
              })
            ) : (
              <div className="dashboard-noIncidents">
                <h2>No Incidents to Display</h2>
              </div>
            )}
            {totalPages > 1 && (
              <PaginationList
                page={page}
                totalPages={totalPages}
                fetchAction={page => this.setState({ page })}
              />
            )}
          </div>
          <div className="dashboard-right-nav">
            <TaskWidget
              taskList={tasks}
              handleOpenModal={() => this.handleModalOpen()}
              setActiveTask={setActiveTask}
              taskLoading={taskLoading}
              accessLevel={loggedInUser.accessLevel}
              taskPagination={taskPagination}
              fetchUsersTasks={fetchUsersTasks}
            />
            <ScheduledAuditWidget
              auditList={audits || []}
              setActiveJsa={setActiveJsa}
              jsaLoading={jsaLoading}
              fetchUserJsas={fetchUserJsas}
              jsaPagination={jsaPagination}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  tasks: getUserTasksSelector(state),
  audits: getJsasSelector(state),
  circles: getIncidentCircleSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocationId: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  loggedInUser: getLoggedInUser(state),
  message: getMessageSelector(state),
  taskLoading: getTaskLoadingSelector(state),
  jsaLoading: getJsaLoadingSelector(state),
  taskPagination: getTaskPaginationInfoSelector(state),
  jsaPagination: getJsaPaginationSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  fetchUsersTasks: page =>
    dispatch(
      fetchUsersTasksRequest({
        isFromStages: false,
        page: page,
        limit: 2,
        removeClosed: true
      })
    ),
  fetchUserJsas: page =>
    dispatch(fetchUsersJsaRequest({ page: page, limit: 2 })),
  setActiveTask: task => dispatch(setActiveTaskRequest(task)),
  setActiveIncident: incident => dispatch(setActiveIncidentRequest(incident)),
  activeIncidentResponse: () => dispatch(setActiveIncidentResponse({})),
  setActiveJsa: jsa => dispatch(setActiveJsaRequest(jsa)),
  clearAttachments: () => dispatch(clearUploadedAttachments()),
  fetchAccountInformation: () => dispatch(fetchAccountInformationRequest()),
  fetchCircles: () => dispatch(fetchCompanyIncidentCircles()),
  fetchAllActiveEmployeesActiveUsers: () =>
    dispatch(fetchAllActiveEmployeesActiveUsers()),
  loadSafetyWalk: id => dispatch(loadSafetyWalk(id))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
