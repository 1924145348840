import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactLoading from 'react-loading';
import UserBadge from '../UserBadge';
import { severityMapper } from '../../utils/severityMapper';
import severityDescriptionMapper from '../../utils/severityDescriptionMapper';

import './index.css';

const JsaCard = ({
  jsa,
  onClick,
  jsaLoading,
  companyLocations
}) => (
  <div>
    {jsaLoading ? (
      <div className="incidentCard">
        <ReactLoading
          type="spinningBubbles"
          color="#58a15b"
          className="loading"
        />
      </div>
    ) : (
      <div className="searchCard">
        <Link
          to={{
            pathname: '/app/jsaSummaryPage'
          }}
          onClick={() => onClick(jsa)}
        >
          {moment().diff(jsa.schedule, 'days') > 0 ? (
            <div className="auditCard-cardHeader--overdue">
              <div className="auditCard-cardHeader--overDueText">Past Due</div>
            </div>
          ) : (
            <div className="auditCard-cardHeader--due">
              <div className="auditCard-cardHeader--dueText">Scheduled</div>
            </div>
          )}
          <div className="auditCard">
            <div className="auditCard-card">
              <div className="auditCard-leftSide">
                <div>
                  <div className="auditCard-cardTypes">Assigned To: </div>
                  <div className="auditCard-cardTypes--profile">
                    {jsa.assignedTo.map(
                      (userId, index) =>
                        <UserBadge
                          showImage
                          hoverName
                          key={index}
                          userId={userId}
                        />
                    )}
                  </div>
                </div>
                <div className="auditCard-cardMargin">
                  <div className="auditCard-cardTypes">Due Date: </div>
                  {moment(jsa.schedule).format('MM/DD/YYYY')}
                </div>
                <div className="auditCard-cardMargin">
                  <div className="auditCard-cardTypes">Audits: </div>
                  {jsa.sections.map((section, index) => {
                    if (index === jsa.sections.length - 1) {
                      return section.sectionName;
                    }

                    return section.sectionName + ', ';
                  })}
                </div>
              </div>
              <div className="auditCard-rightSide">
                <div className="auditCard-cardMargin">
                  <div className="auditCard-cardTypes">Location: </div>
                  {companyLocations.find(
                    location => location._id === jsa.locationId
                  )
                    ? companyLocations.find(
                        location => location._id === jsa.locationId
                      ).name
                    : ''}
                </div>
                <div className="incidentCard-right-incidentSeverity">
                  <p>
                    <img
                      src={severityMapper(jsa.severityRating)}
                      alt="severity"
                    />{' '}
                    <span>
                      {' '}
                      - {severityDescriptionMapper(jsa.severityRating)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )}
  </div>
);

export default JsaCard;
