import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import ReactLoading from 'react-loading';
import socketIOClient from 'socket.io-client';
import history from '../../history';
import {
  emailIncidentAssigneeRequest,
  fetchIncidentByIdRequest,
  updateIncidentRequest
} from '../../actions/incidents';
import {
  createOshaFormRequest,
  updateOshaFormRequest
} from '../../actions/oshaLogs';
import { fetchAllActiveEmployees } from '../../actions/personnel';
import { setActiveTaskRequest } from '../../actions/tasks';
import config from '../../config/config';
import { getAttachmentLoadingSelector } from '../../selectors/attachments';
import { getActiveCompany } from '../../selectors/company';
import {
  getActiveIncidentSelector,
  getIncidentsLoadingSelector
} from '../../selectors/incidents';
import { getMessageSelector } from '../../selectors/messages';
import { getActiveOshaLogSelector } from '../../selectors/oshaLogs';
import { getCompanyActiveEmployeesSelector } from '../../selectors/personnel';
import { getActiveReportsSelector } from '../../selectors/reports';
import { getLoggedInUser } from '../../selectors/users';
import {
  canMarkAsComplete,
  hasAccess,
  isSectionComplete
} from '../../utils/incidentHelper';
import { isComplete } from '../../utils/reportHelper';
import { canSubmit, oshaDbMapper } from '../../utils/oshaDbMapper';
import Header from '../../components/Header';
import { HeaderAndFooterWithLock } from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import MarkAsCompleteModal from '../../components/Modal/markAsCompleteModal';
import OshaComplianceCard from '../../components/reportCards/OshaComplianceCard';
import ReportSection from '../../components/reportCards/ReportSection';
import Textbox from '../../components/inputs/Textbox';
import LeftNav from '../../components/LeftNav';
import ViewOnly from '../../components/ViewOnly';
import Assignee from '../../components/Assignee';

import './index.css';

export class ReportSectionContainer extends Component {
  state = {
    openEmailAssigneeModal: false,
    openEmailStatementModal: false,
    openUnsavedChangesModal: false,
    openMarkAsCompleteModal: false,
    openDeleteIrrelevantReportModal: false,
    openUnlockAndReopenReportModal: false,
    emailMessage: '',
    emailAddress: '',
    activeReport: [],
    hasUnsavedChanges: false,
    oshaQuestionnaire: [],
    canSubmitOshaLog: false,
    socket: null,
    dirty: false,
    lockedUser: null,
    endpoint: config.apiUrl
  };

  componentWillUnmount() {
    this.state.socket?.close && this.state.socket.close();
  }

  componentDidMount() {
    let reportName = '';
    let minSections = '';
    if (this.props.activeReport.report) {
      this.setState({
        activeReport: [...this.props.activeReport.report]
      });
      reportName = this.props.activeReport.report[0][0];
      minSections = this.props.activeReport.report[0][6].minSections;
    } else {
      this.setState({
        activeReport: [...this.props.activeReport]
      });
      reportName = this.props.activeReport[0][0];
      minSections = this.props.activeReport[0][6].minSections;
    }

    if (this.props.oshaForm.length > 0) {
      const oshaQuestionnaire = [];

      this.props.oshaForm.forEach(form =>
        oshaQuestionnaire.push({
          ...form.formAnswers,
          incidentId: form._id,
          _id: form._id
        })
      );

      this.setState({ oshaQuestionnaire });
    } else if (reportName === 'OSHA Compliance') {
      this.setState({
        oshaQuestionnaire: Array(minSections).fill({})
      });
    }

    this.props.fetchAllActEmployees();
    const { endpoint } = this.state;
    const socket = socketIOClient(endpoint, { transports: ['websocket'] });

    socket.on('FromAPI', locks => {
      let lock = locks.find(
        lock =>
          this.props.activeReport?.report?.[0]?.[0] === lock.lockedComponent &&
          this.props.activeIncident._id === lock.incidentId &&
          this.props.loggedInUser._id !== lock.userId
      );

      this.setState((state, props) => {
        let newState = { ...state };

        if (lock && lock.dirty) {
          newState.dirty = true;
        }

        if (!newState.dirty) {
          newState.lockedUser = lock?.userId || null;
        }

        return newState;
      });
    });
    socket.emit('getIncident', this.props.activeIncident._id);
    this.setState({ socket });
  }

  componentDidUpdate(prevProps) {
    const { message, alert } = this.props;
    if (message.message && prevProps.message.message !== message.message) {
      message.error
        ? alert.error(message.message)
        : alert.success(message.message);
    }
  }

  sendEmail = () => {
    const { emailIncidentAssignee, activeIncident } = this.props;
    const { activeReport } = this.state;

    const reportTask =
      activeIncident &&
      activeIncident.incidentActivity &&
      activeIncident.incidentActivity.openTasks.filter(
        task =>
          activeReport.length > 0 &&
          task.reportComponentName === this.getRCName(activeReport)
      );
    emailIncidentAssignee({
      messageContent: this.state.emailMessage,
      incidentId: this.props.activeIncident?._id,
      userId: this.props.loggedInUser?._id,
      sentMessageToId: reportTask?.[0]?.assignedTo?.[0]
    });

    this.setState({ openEmailAssigneeModal: false });
  };

  answerQuestion = async (fields, subSectionIndex) => {
    const { activeReport } = this.state;

    activeReport[0][1].subSections[subSectionIndex][1] = fields;
    activeReport[0][5].isStarted = true;
    this.lockReport();

    const { loggedInUser } = this.props;
    this.setState({
      activeReport,
      hasUnsavedChanges: true,
      lockedUser: loggedInUser._id
    });
  };

  cancelAction = async () => {
    const { activeReport, oshaQuestionnaire, lockedUser } = this.state;
    const showComplete =
      activeReport[0][0] !== 'OSHA Compliance'
        ? canMarkAsComplete(activeReport[0][1].subSections)
        : false;
    const canSubmitOshaLog =
      activeReport[0][0] === 'OSHA Compliance'
        ? oshaQuestionnaire.map(form => canSubmit(form))
        : [false];
    if (
      (showComplete || canSubmitOshaLog.indexOf(false) === -1) &&
      !activeReport[0][4].isComplete
    ) {
      this.setState({ openMarkAsCompleteModal: true });
    } else {
      if (this.props.loggedInUser._id === lockedUser) {
        this.unLockReport();
      }
      this.props.fetchIncidentById(this.props.activeIncident);
      history.push(`/app/incidentContainer/${this.props.match.params.stage}`);
    }
  };

  reopenReportSection = async () => {
    const { activeReport } = this.state;
    activeReport[0][4].isComplete = false;
    await this.props.updateIncident({
      reportComponent: activeReport[0][0],
      isComplete: false,
      stage: this.props.match.params.stage
    });
  };

  answerOsha = (values, index) =>
    this.setState((state, props) => {
      const { oshaQuestionnaire } = state;
      const oshaForm = { ...oshaQuestionnaire[index], ...values };
      let osha = [...oshaQuestionnaire];
      osha[index] = oshaForm;
      return {
        oshaQuestionnaire: osha,
        hasUnsavedChanges: true,
        lockedUser: props.loggedInUser._id
      };
    });

  handleSectionSubmit = markAsComplete => {
    this.unLockReport(true);
    const { activeReport, oshaQuestionnaire } = this.state;
    const { activeIncident, updateIncident } = this.props;
    this.setState({
      hasUnsavedChanges: false,
      lockedUser: null,
      intervalId: null
    });

    const canSubmitOshaLog = oshaQuestionnaire.map(form => canSubmit(form));

    const showComplete =
      activeReport[0][0] !== 'OSHA Compliance'
        ? canMarkAsComplete(activeReport[0][1].subSections)
        : canSubmitOshaLog.indexOf(false) === -1;
    if (showComplete && markAsComplete === undefined) {
      this.setState({ openMarkAsCompleteModal: true });
      return;
    }

    this.setState({ openMarkAsCompleteModal: false });

    if (markAsComplete) {
      activeReport[0][4].isComplete = true;
    }

    if (activeReport[0][0] === 'OSHA Compliance') {
      oshaQuestionnaire.map(async (form, index) => {
        const oshaAnswers = oshaDbMapper(form);
        const payload = {
          incidentId: activeIncident._id,
          companyId: activeIncident.companyId,
          locationId: activeIncident.locationId,
          questions: oshaAnswers
        };
        if (form._id) {
          await this.props.updateOsha({
            ...oshaQuestionnaire[index],
            ...payload
          });
        } else {
          await this.props.createOsha(payload);
        }
      });
      activeReport[0][5].isStarted = true;
    }

    updateIncident({
      reportComponent: activeReport[0][0],
      isComplete: markAsComplete,
      stage: this.props.match.params.stage
    });
  };

  handleNaSection = async (value, subSectionIndex) => {
    const { activeReport } = this.state;

    if (activeReport[0][0] === 'OSHA Compliance') {
      activeReport[0][6].subSections[subSectionIndex][3] = {
        notApplicable: value
      };
    } else {
      this.lockReport(activeReport);

      activeReport[0][1].subSections[subSectionIndex][3] = {
        notApplicable: value
      };
    }

    const { loggedInUser } = this.props;
    this.setState({ hasUnsavedChanges: true, lockedUser: loggedInUser._id });
  };

  handleDiscard = async () => {
    if (this.props.loggedInUser._id === this.state.lockedUser) {
      this.unLockReport();
    }
    this.props.fetchIncidentById(this.props.activeIncident);
    history.push(`/app/incidentContainer/${this.props.match.params.stage}`);
  };

  getLeftNavLabel = (activeReport, activeCompany) =>
    this.getRCName(activeReport) !== 'OSHA Compliance'
      ? this.getFirstSubsectionOf(activeReport)
      : this.isOshaType(activeReport, activeCompany)
      ? [{ label: 'OSHA Compliance' }]
      : this.isPERRPType(activeReport, activeCompany)
      ? [{ label: 'PERRP' }]
      : [];

  isPERRPType = (activeReport, activeCompany) =>
    this.getRC(activeReport)?.[0] === 'OSHA Compliance' &&
    activeCompany.reportingType === 2;

  isOshaType = (activeReport, activeCompany) =>
    this.getRC(activeReport)?.[0] === 'OSHA Compliance' &&
    activeCompany.reportingType === 1;

  getFirstSubsectionOf = activeReport =>
    activeReport?.[0]?.[1]?.subSections?.map(subsection => ({
      label: subsection[0],
      complete: isSectionComplete(subsection)
    }));

  getRCName = activeReport => this.getRC(activeReport) && activeReport[0][0];

  getRC = activeReport => this.hasRC(activeReport) && activeReport[0];

  hasRC = activeReport => activeReport && activeReport.length > 0;

  unLockReport(didSave = false) {
    this.state.socket.emit('unlockReport', {
      incidentId: this.props.activeIncident._id,
      reportComponentName: this.state.activeReport[0][0],
      userId: this.props.loggedInUser._id,
      didSave
    });
  }

  lockReport() {
    if (this.state.lockedUser !== this.props.loggedInUser._id) {
      this.state.socket.emit('lockReport', {
        incidentId: this.props.activeIncident._id,
        reportComponentName: this.state.activeReport[0][0],
        userId: this.props.loggedInUser._id
      });
    }
  }

  render() {
    const {
      loggedInUser,
      activeIncident,
      employees,
      setActiveTask,
      activeCompany,
      incidentLoading,
      attachmentLoading
    } = this.props;

    const {
      openEmailAssigneeModal,
      openEmailStatementModal,
      openUnsavedChangesModal,
      openMarkAsCompleteModal,
      openDeleteIrrelevantReportModal,
      openUnlockAndReopenReportModal,
      emailMessage,
      emailAddress,
      activeReport,
      hasUnsavedChanges,
      oshaQuestionnaire
    } = this.state;

    const leftNav = this.getLeftNavLabel(activeReport, activeCompany);
    const disabled =
      this.state.dirty ||
      (this.state.lockedUser &&
        this.state.lockedUser !== this.props.loggedInUser._id);

    const reportTask =
      activeIncident &&
      activeIncident.incidentActivity &&
      activeIncident.incidentActivity.openTasks.filter(
        task =>
          activeReport.length > 0 &&
          task.reportComponentName === activeReport[0][0]
      );
    const assignedUser =
      activeReport &&
      activeReport[0] &&
      activeReport[0][2] &&
      activeReport[0][2].assignedTo;

    const footer = (
      <SaveCancelFooter
        saveButtonClick={() => this.handleSectionSubmit(undefined)}
        saveButtonDisabled={!hasUnsavedChanges || attachmentLoading}
        cancelButtonClick={this.cancelAction}
        editing={true}
      />
    );

    const headerCenter = (
      <ViewOnly
        report={activeReport}
        incident={activeIncident}
        onClick={() => this.setState({ openUnlockAndReopenReportModal: true })}
      />
    );

    const right =
      this.getRC(activeReport)?.[2]?.assignedTo !== 'unassigned' ? (
        <Assignee
          user={assignedUser}
          options={[
            {
              label: 'Email Assignee',
              onClick: () =>
                this.setState({
                  openEmailAssigneeModal: true
                })
            },
            {
              label: 'Edit Task',
              visible: activeIncident?.incidentOwner === loggedInUser._id,
              onClick: () =>
                setActiveTask({
                  ...reportTask[0],
                  isFromReport: true,
                  isForIncident: true,
                  stage: this.props.match.params.stage
                })
            }
          ]}
        />
      ) : (
        <></>
      );

    const header = (
      <Header
        title={activeIncident && activeIncident.incidentNumber}
        section={
          this.getRC(activeReport)?.[0] === 'OSHA Compliance' &&
          activeCompany.reportingType === 2
            ? 'PERRP'
            : activeReport && activeReport.length > 0 && activeReport[0][0]
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleDiscard()
        }
        center={headerCenter}
        right={right}
        rightButtons={{
          visible:
            activeIncident &&
            activeIncident.incidentOwner === loggedInUser._id &&
            this.getRC(activeReport)?.[2]?.assignedTo === 'unassigned',
          text: 'Assign Report as Task',
          color: 'blue',
          onClick: () =>
            setActiveTask({
              ownerId: activeIncident._id,
              ownerType: 'incident',
              reportComponentName: activeReport[0][0],
              label: `${activeIncident.incidentNumber}, ${activeReport[0][0]}`,
              currentCategory: 6,
              currentReason: 10,
              isFromReport: true,
              isForIncident: true,
              stage: this.props.match.params.stage
            })
        }}
      />
    );

    const left = <LeftNav items={leftNav} />;

    return (
      <>
        {incidentLoading && (
          <ReactLoading
            type="spinningBubbles"
            color="#2f8738"
            className="loading"
          />
        )}
        <HeaderAndFooterWithLock
          Header={header}
          Footer={footer}
          Left={left}
          dirty={this.state.dirty}
          lockedUserId={this.state.lockedUser}
          myUserId={loggedInUser._id}
          showFooter={!isComplete(activeReport)}
        >
          {this.getRC(activeReport)?.[0] !== 'OSHA Compliance' ? (
            activeReport[0]?.[1]?.subSections?.map((subsection, index) => (
              <ReportSection
                name={index}
                reportSectionHeader={subsection[0]}
                fields={subsection[1]}
                key={index}
                answerQuestion={fields => this.answerQuestion(fields, index)}
                employees={employees}
                disabled={
                  this.getRC(activeReport)?.[4]?.isComplete ||
                  !hasAccess(
                    loggedInUser,
                    this.props.activeReport.stage,
                    activeIncident
                  ) ||
                  disabled
                }
                canSectionNotApplicable={
                  subsection[2] && subsection[2].showNotApplicable
                }
                sectionNotApplicable={
                  subsection[3] && subsection[3].notApplicable
                }
                handleNaSection={value => this.handleNaSection(value, index)}
              />
            ))
          ) : (
            <div>
              {activeReport &&
                activeReport.length > 0 &&
                Array.apply(
                  null,
                  Array(activeReport[0][6].minSections)
                ).map((x, i) => (
                  <OshaComplianceCard
                    employees={employees}
                    answerOsha={values => this.answerOsha(values, i)}
                    oshaForm={oshaQuestionnaire[i]}
                    key={i}
                    name={i}
                    disabled={
                      (activeReport.length > 0 &&
                        activeReport[0][4].isComplete) ||
                      !hasAccess(
                        loggedInUser,
                        this.props.activeReport.stage,
                        activeIncident
                      )
                    }
                    isPerrp={activeCompany.reportingType === 2}
                  />
                ))}
            </div>
          )}
        </HeaderAndFooterWithLock>
        <Modal
          title="Email Assignee"
          titleClassName="blueHeader"
          isOpen={openEmailAssigneeModal}
          submitButtonColor="blue"
          submitButtonText="Send Email"
          onRequestClose={() =>
            this.setState({ openEmailAssigneeModal: false })
          }
          submitActions={() => this.sendEmail()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            This email will go through the iReportSource system for record
            keeping purposes.
          </div>
          <Textbox
            fieldLabel="Email Content"
            rows={5}
            type="textarea"
            placeholder="Type email content here"
            currentValue={emailMessage}
            handleChange={e => this.setState({ emailMessage: e.target.value })}
          />
        </Modal>
        <Modal
          title="Email Statement"
          titleClassName="blueHeader"
          isOpen={openEmailStatementModal}
          submitButtonColor="blue"
          submitButtonText="Send Email"
          onRequestClose={() =>
            this.setState({ openEmailStatementModal: false })
          }
          submitActions={() => this.sendEmail()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Sending out this statement as an email will allow the recipient to
            respond with their answers, and populate the fields within the
            report.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Resending this statement will invalidate the email in which this
              statement was previously sent.
            </span>{' '}
            Sending this email will not invalidate different statements that
            have been sent.
          </div>
          <Textbox
            fieldLabel="Recipient's Email Address"
            placeholder="Enter only one email address."
            currentValue={emailAddress}
            handleChange={e => this.setState({ emailAddress: e.target.value })}
          />
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleDiscard()}
          savingWhat="a report"
        />
        <MarkAsCompleteModal
          isOpen={openMarkAsCompleteModal}
          onRequestClose={() =>
            this.setState({ openMarkAsCompleteModal: false })
          }
          submitActions={() => this.handleSectionSubmit(true)}
          cancelActions={() => this.handleSectionSubmit(false)}
        />
        <Modal
          title="Delete Irrelevant Report"
          titleClassName="redHeader"
          isOpen={openDeleteIrrelevantReportModal}
          submitButtonColor="red"
          submitButtonText="Delete Report"
          onRequestClose={() =>
            this.setState({ openDeleteIrrelevantReportModal: false })
          }
          submitActions={() => this.sendEmail()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            This report is no longer relevant due to the incident being
            recategorized, and as such it may not be unlocked and edited
            further.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This report wasn’t deleted during recategorization due to it
              containing data. Make sure you have reviewed (and migrated if
              necessary) all the information in this report, as deleting this
              report will get rid of it forever.{' '}
            </span>
          </div>
        </Modal>
        <Modal
          title="Unlock and Re-Open Report"
          titleClassName="blueHeader"
          isOpen={openUnlockAndReopenReportModal}
          submitButtonColor="blue"
          submitButtonText="Re-open Report"
          onRequestClose={() =>
            this.setState({ openUnlockAndReopenReportModal: false })
          }
          submitActions={() => this.reopenReportSection()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Unlocking this report will allow you –and users with edit
            permission– to make changes and additions to the report.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This also means that the report will no longer be considered
              completed until it is filled out, saved, and marked as complete
              once more. Re-Opening this report will also remove the currently
              assigned user.
            </span>{' '}
            You can reassign this report to the same or a different user as a
            new task.
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  message: getMessageSelector(state),
  activeReport: getActiveReportsSelector(state),
  activeIncident: getActiveIncidentSelector(state),
  employees: getCompanyActiveEmployeesSelector(state),
  oshaForm: getActiveOshaLogSelector(state),
  activeCompany: getActiveCompany(state),
  incidentLoading: getIncidentsLoadingSelector(state),
  attachmentLoading: getAttachmentLoadingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  emailIncidentAssignee: payload =>
    dispatch(emailIncidentAssigneeRequest(payload)),
  updateIncident: payload => dispatch(updateIncidentRequest(payload)),
  fetchAllActEmployees: () => dispatch(fetchAllActiveEmployees()),
  createOsha: payload => dispatch(createOshaFormRequest(payload)),
  updateOsha: payload => dispatch(updateOshaFormRequest(payload)),
  fetchIncidentById: payload => dispatch(fetchIncidentByIdRequest(payload)),
  setActiveTask: payload => dispatch(setActiveTaskRequest(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(ReportSectionContainer)
);
