import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterLocation,
  filterPerformedDate,
  filterUser,
  sortDateMethod,
  sortUser
} from '../../utils/sortFilterHelpers';
import UserBadge from '../UserBadge';
import Button from '../inputs/Button';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

import 'react-table/react-table.css';

const getReportLocation = (locations, locationId) => {
  const location = locations.find(location => location._id === locationId);

  return location ? location.name : 'No Location Specified';
};

const ReportList = props => {
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    if (localStorage.getItem(`${props.reportType}Filters`)) {
      setFilters(
        JSON.parse(localStorage.getItem(`${props.reportType}Filters`))
      );
    }
  }, [props.reportType]);

  const {
    content,
    setActiveReport,
    activeCompany,
    activeEmployeeList,
    reportType
  } = props;

  return (
    <ReactTable
      data={content}
      filterable
      filtered={filters}
      onFilteredChange={filtered => {
        localStorage.setItem(`${reportType}Filters`, JSON.stringify(filtered));
        setFilters(filtered);
      }}
      resizable={false}
      defaultFilterMethod={(filter, row) => defaultFilterMethod(filter, row)}
      defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
      noDataText="No results found"
      defaultPageSize={10}
      className="-striped -highlight"
      columns={[
        {
          Header: 'GROUP/EST.',
          accessor: 'locationId',
          Cell: ({ original }) => (
            <div>
              <ScrollToTopOnMount />
              {getReportLocation(
                activeCompany.allLocations,
                original.locationId
              )}
            </div>
          ),
          filterMethod: (filter, row) =>
            filterLocation(filter, row, activeCompany.allLocations),
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'All Folders'}
            >
              <option value="all">Show All</option>
              {activeCompany &&
                activeCompany.allLocations &&
                activeCompany.allLocations.map((location, index) => (
                  <option key={index} value={location._id}>
                    {location.name}
                  </option>
                ))}
            </select>
          )
        },
        {
          Header: 'REPORTER',
          accessor: 'createdByUserId',
          sortMethod: (a, b) => sortUser(a, b, activeEmployeeList),
          filterMethod: (filter, row) =>
            filterUser(filter, row, activeEmployeeList),
          Cell: ({ value }) => <UserBadge showName userId={value} plain />
        },
        {
          Header: 'DATE OCCURRED',
          accessor: 'dateIncidentOccurred',
          Cell: ({ original }) =>
            `${moment(Date.parse(original.dateIncidentOccurred)).format(
              'MM/DD/YYYY hh:mm a'
            )}`,
          sortMethod: (a, b) => sortDateMethod(a, b),
          filterMethod: (filter, row) => filterPerformedDate(filter, row),
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'All Folders'}
            >
              <option value="all">Show All</option>
              <option value="today">Created Today</option>
              <option value="last7">Created within Last Week</option>
              <option value="last30">Created This Month</option>
              <option value="thisQuarter">Created This Quarter</option>
              <option value="thisYear">Created This Year</option>
            </select>
          )
        },
        {
          Header: 'UPDATED DATE',
          accessor: 'updatedAt',
          Cell: ({ original }) =>
            `${moment(Date.parse(original.updatedAt)).format(
              'MM/DD/YYYY hh:mm a'
            )}`,
          sortMethod: (a, b) => sortDateMethod(a, b),
          filterMethod: (filter, row) => filterPerformedDate(filter, row),
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'All Folders'}
            >
              <option value="all">Show All</option>
              <option value="today">Updated Today</option>
              <option value="last7">Updated within Last Week</option>
              <option value="last30">Updated This Month</option>
              <option value="thisQuarter">Updated This Quarter</option>
              <option value="thisYear">Updated This Year</option>
            </select>
          )
        },
        {
          Header: 'VIEW/EDIT',
          Cell: ({ original }) => (
            <Button
              text="View/Edit"
              color="blue"
              onClick={() => setActiveReport(original)}
            />
          ),
          filterable: false,
          sortable: false
        }
      ]}
    />
  );
};

export default ReportList;
