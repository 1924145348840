const reportTemplateMapper = reportTemplateId => {
  switch (reportTemplateId) {
    case 2:
    case '2':
      return {
        basic: 'First Report of Injury Basics',
        report: 'First Report of Injury',
        circleText: 'FROI'
      };

    case 1:
    case '1':
      return {
        basic: 'Auto Accident Basics',
        report: 'Auto Accident',
        circleText: 'Auto Incident Reports'
      };

    case 3:
    case '3':
      return {
        basic: 'First Report of Property Damage Basics',
        report: 'First Report of Property Damage',
        circleText: 'General Liability Reports'
      };

    case 4:
    case '4':
      return {
        basic: 'First Report of Theft Basics',
        report: 'First Report of Theft',
        circleText: 'General Liability Reports'
      };

    case '5':
    case 5:
      return {
        basic: 'First Report of Third Party Injury Basics',
        report: 'First Report of Third Party Injury',
        circleText: 'General Liability Reports'
      };

    case '6':
    case 6:
      return {
        basic: 'Aircraft Basics',
        report: 'Aircraft Report',
        circleText: 'Aircraft Reports'
      };

    case '16':
    case 16:
      return {
        basic: 'Fuel Related Basics',
        report: 'Fuel Related Report',
        circleText: 'Fuel Related Reports'
      };

    case '7':
    case 7:
      return {
        basic: 'Near Miss Basics',
        report: 'Near Miss Report',
        circleText: 'Near Miss Reports'
      };

    case '9':
    case 9:
      return {
        basic: 'Safety Suggestion Basics',
        report: 'Safety Suggestion Report',
        circleText: 'Safety Suggestions'
      };

    case '12':
    case 12:
      return {
        basic: 'Behavior Observation Basics',
        report: 'Behavior Observation Report',
        circleText: 'Behavior Observation Reports'
      };

    case '14':
    case 14:
      return {
        basic: 'Lockout/Tagout Basics',
        report: 'Lockout/Tagout',
        circleText: 'Lockout/Tagout Reports'
      };

    case '15':
    case 15:
      return {
        basic: 'DOT Basics',
        report: 'DOT',
        circleText: 'DOT Reports'
      };

    case '11':
    case 11:
      return {
        basic: 'See Something Say Something Basics',
        report: 'See Something Say Something',
        circleText: 'S4 Reports'
      };

    default:
      return {
        basic: '',
        report: '',
        circleText: ''
      };
  }
};

export default reportTemplateMapper;
