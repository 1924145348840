import {
  ADD_JSA_ATTACHMENTS_REQUEST,
  ADD_JSA_ATTACHMENTS_RESPONSE,
  ADD_JSA_NOTES_REQUEST,
  ADD_JSA_NOTES_RESPONSE,
  CREATE_JSA_REQUEST,
  CREATE_JSA_RESPONSE,
  CREATE_RA_CUSTOM_TEMPLATE_REQUEST,
  CREATE_RA_CUSTOM_TEMPLATE_RESPONSE,
  DELETE_JSA_QUESTION_ATTACHMENT,
  DELETE_JSA_REQUEST,
  DELETE_JSA_RESPONSE,
  DELETE_RA_CUSTOM_TEMPLATE_REQUEST,
  DELETE_RA_CUSTOM_TEMPLATE_RESPONSE,
  FETCH_COMPANY_JSA_REQUEST,
  FETCH_COMPANY_JSA_RESPONSE,
  FETCH_JSA_ATTACHMENTS_REQUEST,
  FETCH_JSA_ATTACHMENTS_RESPONSE,
  FETCH_JSA_CIRCLES_RESPONSE,
  FETCH_JSA_NOTES_REQUEST,
  FETCH_JSA_NOTES_RESPONSE,
  FETCH_JSA_SECTIONS_REQUEST,
  FETCH_JSA_SECTIONS_RESPONSE,
  FETCH_LOCATION_JSA_REQUEST,
  FETCH_LOCATION_JSA_RESPONSE,
  FETCH_PROJECT_JSA_REQUEST,
  FETCH_PROJECT_JSA_RESPONSE,
  FETCH_RA_CUSTOM_TEMPLATES_REQUEST,
  FETCH_RA_CUSTOM_TEMPLATES_RESPONSE,
  FETCH_JSA_BY_ID,
  FETCH_USER_JSAS_REQUEST,
  SET_ACTIVE_JSA_REQUEST,
  SET_ACTIVE_JSA_RESPONSE,
  SET_ACTIVE_RA_CUSTOM_TEMPLATE,
  UPDATE_JSA_REQUEST,
  UPDATE_JSA_RESPONSE,
  UPDATE_RA_CUSTOM_TEMPLATE_REQUEST,
  UPDATE_RA_CUSTOM_TEMPLATE_RESPONSE
} from '../constants/actionTypes';

export const fetchJsaSectionsRequest = payload => ({
  type: FETCH_JSA_SECTIONS_REQUEST,
  payload
});

export const fetchJsaSectionsResponse = payload => ({
  type: FETCH_JSA_SECTIONS_RESPONSE,
  payload
});

export const fetchCompanyJsaRequest = payload => ({
  type: FETCH_COMPANY_JSA_REQUEST,
  payload
});

export const fetchCompanyJsaResponse = payload => ({
  type: FETCH_COMPANY_JSA_RESPONSE,
  payload
});

export const fetchLocationJsaRequest = payload => ({
  type: FETCH_LOCATION_JSA_REQUEST,
  payload
});

export const fetchLocationJsaResponse = payload => ({
  type: FETCH_LOCATION_JSA_RESPONSE,
  payload
});

export const fetchProjectJsaRequest = payload => ({
  type: FETCH_PROJECT_JSA_REQUEST,
  payload
});

export const fetchProjectJsaResponse = payload => ({
  type: FETCH_PROJECT_JSA_RESPONSE,
  payload
});

export const fetchJsaCirclesResponse = payload => ({
  type: FETCH_JSA_CIRCLES_RESPONSE,
  payload
});

export const createJsaRequest = payload => ({
  type: CREATE_JSA_REQUEST,
  payload
});

export const createJsaResponse = payload => ({
  type: CREATE_JSA_RESPONSE,
  payload
});

export const updateJsaRequest = payload => ({
  type: UPDATE_JSA_REQUEST,
  payload
});

export const updateJsaResponse = payload => ({
  type: UPDATE_JSA_RESPONSE,
  payload
});

export const deleteJsaRequest = payload => ({
  type: DELETE_JSA_REQUEST,
  payload
});

export const deleteJsaResponse = payload => ({
  type: DELETE_JSA_RESPONSE,
  payload
});

export const setActiveJsaRequest = payload => ({
  type: SET_ACTIVE_JSA_REQUEST,
  payload
});

export const setActiveJsaResponse = payload => ({
  type: SET_ACTIVE_JSA_RESPONSE,
  payload
});

export const fetchJsaNotesRequest = payload => ({
  type: FETCH_JSA_NOTES_REQUEST,
  payload
});

export const fetchJsaNotesResponse = payload => ({
  type: FETCH_JSA_NOTES_RESPONSE,
  payload
});

export const addJsaNotesRequest = payload => ({
  type: ADD_JSA_NOTES_REQUEST,
  payload
});

export const addJsaNotesResponse = payload => ({
  type: ADD_JSA_NOTES_RESPONSE,
  payload
});

export const fetchJsaAttachmentsRequest = payload => ({
  type: FETCH_JSA_ATTACHMENTS_REQUEST,
  payload
});

export const fetchJsaAttachmentsResponse = payload => ({
  type: FETCH_JSA_ATTACHMENTS_RESPONSE,
  payload
});

export const addJsaAttachmentsRequest = payload => ({
  type: ADD_JSA_ATTACHMENTS_REQUEST,
  payload
});

export const addJsaAttachmentsResponse = payload => ({
  type: ADD_JSA_ATTACHMENTS_RESPONSE,
  payload
});

export const fetchUsersJsaRequest = payload => ({
  type: FETCH_USER_JSAS_REQUEST,
  payload
});

export const deleteJsaQuestionAttachment = payload => ({
  type: DELETE_JSA_QUESTION_ATTACHMENT,
  payload
});

export const fetchCustomTemplatesRequest = payload => ({
  type: FETCH_RA_CUSTOM_TEMPLATES_REQUEST,
  payload
});

export const fetchCustomTemplatesResponse = payload => ({
  type: FETCH_RA_CUSTOM_TEMPLATES_RESPONSE,
  payload
});

export const createCustomTemplateRequest = payload => ({
  type: CREATE_RA_CUSTOM_TEMPLATE_REQUEST,
  payload
});

export const createCustomTemplateResponse = payload => ({
  type: CREATE_RA_CUSTOM_TEMPLATE_RESPONSE,
  payload
});

export const updateCustomTemplateRequest = payload => ({
  type: UPDATE_RA_CUSTOM_TEMPLATE_REQUEST,
  payload
});

export const updateCustomTemplateResponse = payload => ({
  type: UPDATE_RA_CUSTOM_TEMPLATE_RESPONSE,
  payload
});

export const deleteCustomTemplateRequest = payload => ({
  type: DELETE_RA_CUSTOM_TEMPLATE_REQUEST,
  payload
});

export const deleteCustomTemplateResponse = payload => ({
  type: DELETE_RA_CUSTOM_TEMPLATE_RESPONSE,
  payload
});

export const setActiveRaCustomTemplate = payload => ({
  type: SET_ACTIVE_RA_CUSTOM_TEMPLATE,
  payload
});

export const fetchJsaById = payload => ({
  type: FETCH_JSA_BY_ID,
  payload
});
