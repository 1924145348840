import React, { Component } from 'react';

import Card from '../../Card';
import { oshaHasAnswers } from '../../../utils/oshaDbMapper';
import CheckboxGroup from '../../inputs/CheckboxGroup';
import { DatePicker } from '../../inputs/DateTimePicker';
import OshaInjuredWorker from '../../OshaInjuredWorker';
import Textbox from '../../inputs/Textbox';

import styles from './oshaCompliance.module.scss';

export default class OshaComplianceCard extends Component {
  render() {
    const {
      name,
      employees,
      answerOsha,
      oshaForm,
      disabled,
      isPerrp
    } = this.props;

    const happenToWorker = oshaForm.happenToWorker;
    const options = [
      { value: 0, label: 'Fatality' },
      { value: 1, label: 'Admitted to a Hospital' },
      { value: 2, label: 'Amputation' },
      { value: 3, label: 'Loss of an Eye' },
      { value: 4, label: 'N/A' }
    ];

    const numberOfHours =
      happenToWorker?.some(option => option.value === 0)
        ? 8
        : 24;

    return (
      <Card
        showHeader
        title={isPerrp ? 'PERRP' : 'OSHA Compliance'}
        name={name}
        rightButton={
          <CheckboxGroup
            options={
              oshaHasAnswers(oshaForm)
                ? [{ value: 1001, label: 'Redline The Original Entry' }]
                : [{ value: 1000, label: 'Not Applicable' }]
            }
            handleChange={values =>
              answerOsha(
                oshaHasAnswers(oshaForm)
                  ? { redline: values }
                  : { isNotApplicable: values }
              )
            }
            currentValue={
              oshaHasAnswers(oshaForm)
                ? oshaForm?.redline
                : oshaForm?.isNotApplicable
            }
            disabled={disabled}
          />
        }
      >
        <div className={styles.happenToWorker}>
          <CheckboxGroup
            options={options}
            name="happenToWorker"
            handleChange={values => answerOsha?.({ happenToWorker: values })}
            currentValue={happenToWorker}
            isRequired
            fieldLabel="Did any of these happen to a worker? (Check all that apply)"
            disabled={disabled}
          />
          {happenToWorker &&
            happenToWorker.length > 0 &&
            happenToWorker.map(option => option.value).indexOf(4) === -1 && (
              <div className={styles.toggleText}>
                <p>{`Must be Reported to ${
                  isPerrp ? 'PERRP' : 'OSHA'
                } within ${numberOfHours} hours.`}</p>
                {!isPerrp && <p>1 (800) 321-6742</p>}
                {isPerrp && <p>1 (800) 671-6858</p>}
              </div>
            )}
        </div>
        <DatePicker
          fieldLabel="When did the incident occur?"
          isRequired
          name="dateTime"
          handleChange={(dateTime, timeNotDetermined) =>
            answerOsha?.({ dateTime, timeNotDetermined })
          }
          currentValue={oshaForm.dateTime}
          timeNotDetermined={oshaForm.timeNotDetermined}
          pickTime
          pickNotExact
          disabled={disabled}
        />
        <Textbox
          fieldLabel="Describe where the incident occurred. "
          handleChange={e => answerOsha?.({ description: e.target.value })}
          isRequired
          currentValue={oshaForm.description}
          type="textarea"
          placeholder="e.g. Loading Dock North End"
          disabled={disabled}
        />
        <OshaInjuredWorker
          personnel={employees}
          handleInjuredWorkerChange={values => answerOsha?.(values)}
          oshaForm={oshaForm}
          isPerrp={isPerrp}
          disabled={disabled}
        />
      </Card>
    );
  }
}
