import { SEARCH_REQUEST, SEARCH_RESPONSE } from '../constants/actionTypes';

export const searchRequest = payload => ({
  type: SEARCH_REQUEST,
  payload
});

export const searchResponse = payload => ({
  type: SEARCH_RESPONSE,
  payload
});
