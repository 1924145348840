import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getLoggedInUser } from '../../selectors/users';
import { hasAccess } from '../../utils/incidentHelper';
import { isComplete } from '../../utils/reportHelper';
import { classnames } from '../../utils/classNamesHelper';

import styles from './viewOnly.module.scss';

export class ViewOnly extends Component {
  render() {
    const {
      loggedInUser,
      report,
      canUnlock,
      incident
    } = this.props;

    const accessLevel = parseInt(loggedInUser?.accessLevel || 0, 10);
    let complete = isComplete(report);
    if (canUnlock) {
      complete = true;
    } else if (!complete && (accessLevel > 100 || hasAccess(loggedInUser, report?.stage, incident))) {
      return <></>;
    }

    const classes = [styles.viewOnly, this.props.className, complete && styles.complete];

    return (
      <div className={classnames(classes)} onClick={this.props.onClick}>
        <img
          alt="Report is locked"
          src={require('../../assets/images/reportLock.png')}
        />
        <img
          className={styles.hover}
          alt="Report is locked"
          src={require('../../assets/images/reportLockHover.png')}
        />
        <div>
          { complete ? (
            <div className={styles.completed}>
              Report Complete
            </div>
          ) : <></>
          }
          <div className={styles.vo}>VIEW ONLY ACCESS</div>
          <div className={styles.unlock}>UNLOCK?</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state)
});

export default connect(mapStateToProps)(ViewOnly);
