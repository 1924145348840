import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import { classnames } from '../../utils/classNamesHelper';
import Checkbox from './Checkbox';

import styles from './input.module.scss';

export class Input extends Component {
  render() {
    const {
      fieldLabel,
      isRequired,
      touched,
      error,
      warning,
      children,
      tooltip,
      displayTooltip,
      className,
      showNA,
      isNA,
      disabled,
      onNAChange,
      skipInputWrapper,
      inline,
      name
    } = this.props;

    let classes = [styles.input, className, inline && styles.inline];

    if (skipInputWrapper) return children;

    return (
      <div className={classnames(classes)}>
        {displayTooltip && (
          <ReactTooltip className="customTheme" id={name}>
            {tooltip || children}
          </ReactTooltip>
        )}
        {fieldLabel && (
          <span className={styles.label}>
            {isRequired ? <span className={styles.isRequired}>*</span> : ''}
            <label data-tip="customTheme" data-for={name} htmlFor={name}>
              {fieldLabel}
              {displayTooltip && (
                <img
                  className="label-info"
                  src={require(`../../assets/images/Info Icon.png`)}
                  alt="Info Icon"
                />
              )}
            </label>
          </span>
        )}
        {showNA && (
          <Checkbox
            fieldLabel="Not Applicable"
            name={`${name}-na`}
            currentValue={isNA}
            onChange={onNAChange}
            disabled={disabled}
            className={`${styles.na} legacy-stop-input-spacing`}
          />
        )}
        {!isNA && this.props.children}
        {touched &&
          ((error && <span className="form-error">{error}</span>) ||
            (warning && <span className="form-warning">{warning}</span>))}
      </div>
    );
  }
}

export class TwoColumn extends Component {
  render() {
    const { className, children, ...props } = this.props;
    return (
      <div className={classnames(className, styles.twoColumn)} {...props}>
        {children}
      </div>
    );
  }
}

export class InputRow extends Component {
  render() {
    const {
      className,
      children,
      right,
      center,
      space,
      ...props
    } = this.props;
    const classes = [
      className,
      styles.row,
      right && styles.right,
      center && styles.center,
      space && styles.space
    ];
    return (
      <div className={classnames(classes)} {...props}>
        {children}
      </div>
    );
  }
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function nameInput(Input) {
  class nameInput extends Component {
    render() {
      let { name, ...props } = this.props;
      name = name || uuidv4();
      return <Input name={name} {...props} />;
    }
  }
  nameInput.displayName = `nameInput(${getDisplayName(Input)})`;
  return nameInput;
}

export default Input;
