import moment from 'moment';
import {
  CONTRACTOR_STATUSES,
  EMPLOYEE_STATUSES,
  OTHER_STATUSES
} from '../constants/constants';
import priorityMapper from './priorityMapper';
import severityDescriptionMapper from './severityDescriptionMapper';

export const defaultFilterMethod = (filter, row) => {
  if (row[filter.id] !== null && typeof row[filter.id] === 'string') {
    return row[filter.id] !== undefined
      ? String(row[filter.id].toLowerCase()).includes(
          filter.value.toLowerCase()
        )
      : true;
  }
};

export const filterAllDropdown = (filter, row) => {
  return filter.value === 'all' ? true : row[filter.id] === filter.value;
};

export const defaultSortMethod = (a, b) => {
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
};

// Date specific filters and sorters
export const sortDateMethod = (a, b) => {
  a = new Date(a).getTime();
  b = new Date(b).getTime();
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
};

export const filterHireDate = (filter, row) => {
  if (filter.value === 'past7') {
    return moment().diff(moment(row[filter.id]), 'days') <= 7;
  } else if (filter.value === 'past30') {
    return moment().diff(moment(row[filter.id]), 'days') <= 30;
  } else if (filter.value === 'past60') {
    return moment().diff(moment(row[filter.id]), 'days') <= 60;
  } else if (filter.value === 'past90') {
    return moment().diff(moment(row[filter.id]), 'days') <= 90;
  } else if (filter.value === 'past120') {
    return moment().diff(moment(row[filter.id]), 'days') > 90;
  }
  return true;
};

export const filterExpireDate = (filter, row) => {
  if (filter.value === 'today') {
    return moment(Date.parse(row[filter.id])).diff(moment(), 'days') <= 0;
  } else if (filter.value === 'within7') {
    return (
      moment(Date.parse(row[filter.id])).diff(moment(), 'days') > 0 &&
      moment(Date.parse(row[filter.id])).diff(moment(), 'days') <= 7
    );
  } else if (filter.value === 'within30') {
    return (
      moment(Date.parse(row[filter.id])).diff(moment(), 'days') > 0 &&
      moment(Date.parse(row[filter.id])).diff(moment(), 'days') <= 30
    );
  } else if (filter.value === 'within60') {
    return (
      moment(Date.parse(row[filter.id])).diff(moment(), 'days') > 0 &&
      moment(Date.parse(row[filter.id])).diff(moment(), 'days') <= 60
    );
  } else if (filter.value === 'past90') {
    return moment(Date.parse(row[filter.id])).diff(moment(), 'days') > 60;
  }
  return true;
};

export const filterCompletedDate = (filter, row) => {
  if (filter.value === 'trainingPerformed') {
    return moment(Date.parse(row[filter.id])).diff(moment(), 'days') <= 0;
  } else if (filter.value === 'trainingInProgress') {
    return moment(Date.parse(row[filter.id])).diff(moment(), 'days') > 0;
  }

  return true;
};

export const filterPerformedDate = (filter, row) => {
  const today = new Date();

  if (filter.value === 'today') {
    return moment().diff(moment(Date.parse(row[filter.id])), 'days') === 0;
  } else if (filter.value === 'last7') {
    return moment().diff(moment(Date.parse(row[filter.id])), 'days') <= 7;
  } else if (filter.value === 'last30') {
    return (
      new Date(Date.parse(row[filter.id])).getMonth() === today.getMonth() &&
      new Date(Date.parse(row[filter.id])).getFullYear() === today.getFullYear()
    );
  } else if (filter.value === 'thisQuarter') {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    if (quarter === 1) {
      return moment(Date.parse(row[filter.id])).isBetween(
        `01/01/${today.getFullYear()}`,
        `03/31/${today.getFullYear()}`
      );
    } else if (quarter === 2) {
      return moment(Date.parse(row[filter.id])).isBetween(
        `04/01/${today.getFullYear()}`,
        `06/30/${today.getFullYear()}`
      );
    } else if (quarter === 3) {
      return moment(Date.parse(row[filter.id])).isBetween(
        `07/01/${today.getFullYear()}`,
        `09/30/${today.getFullYear()}`
      );
    } else {
      return moment(Date.parse(row[filter.id])).isBetween(
        `10/01/${today.getFullYear()}`,
        `12/31/${today.getFullYear()}`
      );
    }
  } else if (filter.value === 'thisYear') {
    return moment(Date.parse(row[filter.id])).isBetween(
      `01/01/${today.getFullYear()}`,
      `12/31/${today.getFullYear()}`
    );
  }
  return true;
};

// Priority Level filters and sorters
export const filterPriority = (filter, row) => {
  if (filter.value === 'low') {
    return row[filter.id] === 1;
  } else if (filter.value === 'medium') {
    return row[filter.id] === 2;
  } else if (filter.value === 'high') {
    return row[filter.id] === 3;
  }
  return true;
};

export const sortPriority = (a, b) => {
  a = priorityMapper(a);
  b = priorityMapper(b);

  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
};

// Attachments filters
export const filterAttachments = (filter, row) => {
  if (filter.value === 'hasAttachments') {
    return row[filter.id].length > 0;
  } else if (filter.value === 'noAttachments') {
    return row[filter.id].length === 0;
  }

  return true;
};

// Employee/Contractors filters and sorters
export const sortPersonnel = (a, b, personnelList) => {
  let aPerson = personnelList.find(personnel => personnel._id === a);

  aPerson = aPerson
    ? `${aPerson.person.firstName} ${aPerson.person.lastName}`
    : '';

  let bPerson = personnelList.find(personnel => personnel._id === b);

  bPerson = bPerson
    ? `${bPerson.person.firstName} ${bPerson.person.lastName}`
    : '';

  if (aPerson === bPerson) {
    return 0;
  }
  return aPerson > bPerson ? 1 : -1;
};

export const filterPersonnel = (filter, row, personnelList) => {
  if (!filter.value) {
    return true;
  }

  let personnel = personnelList.filter(
    person =>
      person.person.firstName
        .toLowerCase()
        .indexOf(filter.value.toLowerCase()) > -1 ||
      person.person.lastName.toLowerCase().indexOf(filter.value.toLowerCase()) >
        -1
  );

  personnel = personnel.map(person => person._id);

  return personnel.indexOf(row[filter.id]) > -1;
};

export const filterEmployeeType = (filter, row) => {
  if (filter.value === 'employee') {
    return (
      EMPLOYEE_STATUSES.findIndex(
        employee => row[filter.id] === employee.value.toString()
      ) > -1
    );
  } else if (filter.value === 'contractor') {
    return (
      CONTRACTOR_STATUSES.findIndex(
        contractor => row[filter.id] === contractor.value.toString()
      ) > -1
    );
  } else if (filter.value === 'other') {
    return (
      OTHER_STATUSES.findIndex(
        contractor => row[filter.id] === contractor.value.toString()
      ) > -1
    );
  }

  return true;
};

export const sortUser = (a, b, personnelList) => {
  let aPerson = personnelList.find(
    personnel => personnel.userAccount._id === a
  );

  aPerson = aPerson
    ? `${aPerson.person.firstName} ${aPerson.person.lastName}`
    : '';

  let bPerson = personnelList.find(
    personnel => personnel.userAccount._id === b
  );

  bPerson = bPerson
    ? `${bPerson.person.firstName} ${bPerson.person.lastName}`
    : '';

  if (aPerson === bPerson) {
    return 0;
  }
  return aPerson > bPerson ? 1 : -1;
};

export const filterUser = (filter, row, personnelList) => {
  if (!filter.value) {
    return true;
  }

  let personnel = personnelList.filter(
    person =>
      person.person.firstName
        .toLowerCase()
        .indexOf(filter.value.toLowerCase()) > -1 ||
      person.person.lastName.toLowerCase().indexOf(filter.value.toLowerCase()) >
        -1
  );

  personnel = personnel.map(
    person => person.userAccount && person.userAccount._id
  );

  return (
    personnel.indexOf(row[filter.id]) > -1 ||
    personnel.indexOf(row[filter.id][0]) > -1
  );
};

export const filterUserOrEmployee = (filter, row, personnelList, userList) => {
  if (!filter.value) {
    return true;
  }

  let personnel = personnelList.filter(
    person =>
      person.person.firstName
        .toLowerCase()
        .indexOf(filter.value.toLowerCase()) > -1 ||
      person.person.lastName.toLowerCase().indexOf(filter.value.toLowerCase()) >
        -1
  );

  let users = userList.filter(
    person =>
      person.person.firstName
        .toLowerCase()
        .indexOf(filter.value.toLowerCase()) > -1 ||
      person.person.lastName.toLowerCase().indexOf(filter.value.toLowerCase()) >
        -1
  );

  personnel = personnel.map(person => person._id);

  users = users.map(user => user.userAccount && user.userAccount._id);

  return (
    personnel.indexOf(row[filter.id]) > -1 ||
    personnel.indexOf(row[filter.id][0]) > -1 ||
    users.indexOf(row[filter.id]) > -1 ||
    users.indexOf(row[filter.id][0]) > -1
  );
};

// Location filters and sorters
export const filterLocation = (filter, row, locationList) => {
  if (filter.value === 'all') {
    return true;
  }

  const locationIndex = locationList.findIndex(
    location => location._id === filter.value
  );

  return row[filter.id].indexOf(locationList[locationIndex]._id) > -1;
};

// Risk Value Level filters and sorters
export const filterRiskValue = (filter, row) => {
  if (filter.value === 'low') {
    return row[filter.id] === 'green';
  } else if (filter.value === 'medium') {
    return row[filter.id] === 'yellow';
  } else if (filter.value === 'high') {
    return row[filter.id] === 'red';
  }
  return true;
};

export const sortRiskValue = (a, b) => {
  a = severityDescriptionMapper(a);
  b = severityDescriptionMapper(b);

  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
};

//my documents filters
export const filterMyDocStatus = (filter, row) => {
  if (filter.value === 'supReview') {
    return (
      !row._original.isApprovedBySupervisor &&
      (row._original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment' ||
        row._original.label === 'Transitional Return to Work Program')
    );
  } else if (filter.value === 'hrReview') {
    return (
      row._original.isApprovedBySupervisor &&
      !row._original.isApprovedByHr &&
      row._original.label === 'Transitional Return to Work Program'
    );
  } else if (filter.value === 'approved') {
    return (
      (row._original.isApprovedBySupervisor &&
        row._original.isApprovedByHr &&
        row._original.label === 'Transitional Return to Work Program') ||
      (row._original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment' &&
        row._original.isApprovedBySupervisor) ||
      row._original.title
    );
  }

  return true;
};

export const filterMyDocType = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }

  if (filter.value === 'safetyTraining') {
    return row._original.title;
  } else if (filter.value === 'continuousCare') {
    return (
      row._original.label === 'Transitional Return to Work Program' ||
      row._original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment'
    );
  } else {
    return (
      row._original.label !== 'Transitional Return to Work Program' &&
      row._original.label !==
        'Recurrence, Relapse During Recovery & Continuing Treatment' &&
      !row._original.title
    );
  }
};

// RA specific filters
export const filterRiskAssessments = (filter, sectionList) => {
  if (!filter.value) {
    return true;
  }

  const sections = sectionList.filter(
    section =>
      section.sectionName.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
  );

  return sections.length > 0;
};

export const filterAssignedTo = (filter, row, userList) => {
  if (!filter.value) {
    return true;
  }

  const assignedTo = row._original.assignedTo;

  const personnel = userList.filter(
    person =>
      assignedTo.includes(person?.userAccount._id) &&
      `${person.person.firstName} ${person.person.lastName}`
        .toLowerCase()
        .indexOf(filter.value.toLowerCase()) > -1
  );

  return personnel.length > 0;
};

export const filterRepeatingOn = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }

  return row._original.frequency === filter.value;
};

//Training Filters
export const filterApprovalExpires = (filter, row) => {
  if (filter.value === 'all') {
    return true;
  }

  if (filter.value === 'true') {
    return row[filter.id];
  } else {
    return !row[filter.id];
  }
};

export const sortByTimeSpent = (a, b) => {
  if (a.timeSpent === b.timeSpent) {
    return 0;
  }
  return a.timeSpent > b.timeSpent ? 1 : -1;
};
