import { createSelector } from 'reselect';

const taskSelector = state => state.tasks;

export const getUserTasksSelector = createSelector(
  taskSelector,
  tasks => tasks && tasks.list
);

export const getTaskLoadingSelector = createSelector(
  taskSelector,
  tasks => tasks && tasks.loading
);

export const getTaskPaginationInfoSelector = createSelector(
  taskSelector,
  tasks => tasks && tasks.paginationInfo
);

export const getTaskCircleDataSelector = createSelector(
  taskSelector,
  circles => circles && circles.circles
);

export const getActiveTaskSelector = createSelector(
  taskSelector,
  activeTask => activeTask && activeTask.activeTask
);

export const getNotStartedTasksSelector = createSelector(
  getUserTasksSelector,
  tasks => tasks && tasks.filter(task => task.currentWfStatus === 1)
);

export const getInProgressTasksSelector = createSelector(
  getUserTasksSelector,
  tasks =>
    tasks &&
    tasks.filter(
      task => task.currentWfStatus === 2 || task.currentWfStatus === 3
    )
);

export const getStuckTasksSelector = createSelector(
  getUserTasksSelector,
  tasks => tasks && tasks.filter(task => task.currentWfStatus === 4)
);

export const getCompleteTasksSelector = createSelector(
  getUserTasksSelector,
  tasks => tasks && tasks.filter(task => task.currentWfStatus === 5)
);
