import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../../history';
import { updateLeftNavRequest } from '../../../actions/navigation';
import { getLoggedInUser } from '../../../selectors/users';
import {
  fetchAnalyticsDataSources,
  fetchAnalyticsDataPoints,
  fetchAnalyticsDataPointValues,
  createAnalyticsChart,
  updateAnalyticsChart
} from '../../../api';
import svgMapper from '../../../utils/svgMapper';
import Widget from '../../../components/Widget';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import Textbox from '../../../components/inputs/Textbox';
import Dropdown from '../../../components/inputs/Dropdown';
import ButtonSelector from '../../../components/inputs/ButtonSelector';
import AnalyticsChart from '../../../components/AnalyticsChart';
import Loading from '../../../components/Loading';

import {
  PelletContainer,
  PelletDrop,
  SelectValuesPellet
} from '../../../components/DragPellets';

import styles from './styles.module.scss';

export class MyAnalyticsChart extends Component {
  constructor(props) {
    super(props);

    let values = {
      title: '',
      dataSource: null,
      yAxis: 'getSum',
      category: null,
      grouping: null,
      filters: [],
      type: 'bar'
    };

    this.state = {
      initial: { ...values },
      current: values,
      openUnsavedChangesModal: false,
      dataSources: [],
      dataPoints: [],
      dataPointsEmpty: 'Choose Data Source'
    };
  }

  componentDidMount() {
    this.props.updateLeftNav('My Analytics');

    fetchAnalyticsDataSources().then(data => {
      if (this.props.match?.params?.cardId)
        this.loadChart(
          this.props.match?.params?.cardId,
          this.props.location?.state?.chart,
          data
        );
      this.setState({ dataSources: data });
    });
  }

  loadChart = (chartId, chart, dataSources) => {
    this.updateDataSource(chart.datasource).then(data => {
      let datapoints = data.datapoints;
      this.setState(
        (state, props) => {
          let filters = [];
          let newState = { ...state };

          for (const filterName in chart.filterArgs) {
            let filter = datapoints.find(point => point.value === filterName);
            filters.push({
              isLoading: true,
              ...filter
            });
          }

          newState.initial = {
            title: chart.title,
            dataSource: chart.datasource,
            category: datapoints.find(point => point.value === chart.category),
            grouping: datapoints.find(point => point.value === chart.series),
            yAxis: chart.yAxis || 'getSum',
            type: chart.type,
            filters
          };

          newState.current = { ...newState.initial };
          return newState;
        },
        () => {
          for (const filterName in chart.filterArgs)
            this.loadFilter({
              dataSource: chart.datasource,
              dataPoint: filterName,
              selected: chart.filterArgs[filterName]
            });
        }
      );
    });
  };

  loadFilter = ({ dataSource, dataPoint, selected = true }) => {
    fetchAnalyticsDataPointValues({ dataSource, dataPoint }).then(data => {
      this.setState((state, props) => {
        let newState = { ...state };
        let i = newState.current.filters.findIndex(f => f.value === dataPoint);
        if (i !== -1) {
          newState.current.filters[i].isLoading = false;
          newState.current.filters[i].values = data.values.map(v => ({
            ...v,
            selected:
              selected === true || selected.findIndex(s => s === v.value) !== -1
          }));
        }
        return newState;
      });
    });
  };

  canPreview = () => {
    const current = this.state.current;
    return current.dataSource !== null && current.category !== null;
  };

  canSubmit = () => {
    return this.canPreview && !!this.state.current.title;
  };

  hasChanges = () => {
    for (let key in this.state.current)
      if (this.state.current[key] !== this.state.initial[key]) return true;
    return false;
  };

  leavePage = () => {
    if (this.hasChanges()) this.setState({ openUnsavedChangesModal: true });
    else history.goBack();
  };

  update = (field, value) => {
    this.setState((state, props) => {
      let newState = { ...state };
      newState.current[field] = value;
      return newState;
    });
  };

  updateDataSource = dataSource => {
    if (dataSource === this.state.current.dataSource) return;

    this.setState((state, props) => {
      let newState = { ...state };
      newState.dataPoints = [];
      newState.dataPointsEmpty = <Loading text />;
      newState.current.dataSource = dataSource;
      newState.current.category = null;
      newState.current.grouping = null;
      newState.current.filters = [];
      newState.current.yAxis = 'getSum';
      return newState;
    });
    let p = fetchAnalyticsDataPoints(dataSource);
    p.then(data => {
      this.setState({
        dataPoints: data.datapoints,
        yAxis: data.yAxisOptions
      });
    });
    return p;
  };

  selectValue = (f, v) => {
    this.setState((state, props) => {
      let newState = { ...state };
      newState.current.filters[f].values[v].selected = !newState.current
        .filters[f].values[v].selected;
      return newState;
    });
  };

  selectAll = f => {
    this.setState((state, props) => {
      let newState = { ...state };
      newState.current.filters[f].values.forEach((v, i, a) => {
        a[i].selected = true;
      });
      return newState;
    });
  };

  clearAll = f => {
    this.setState((state, props) => {
      let newState = { ...state };
      newState.current.filters[f].values.forEach((v, i, a) => {
        a[i].selected = false;
      });
      return newState;
    });
  };

  onDrop = (pellet, where) => {
    this.setState((state, props) => {
      let newState = { ...state };
      if (where === 'filter') {
        pellet.isLoading = true;
        newState.current.filters.push(pellet);
        this.loadFilter({
          dataSource: this.state.current.dataSource,
          dataPoint: pellet.value
        });
      } else {
        newState.current[where] = pellet;
      }
      return newState;
    });
  };

  onDelete = (i, where) => {
    this.setState((state, props) => {
      let newState = { ...state };
      if (where === 'filter') state.current.filters.splice(i, 1);
      else state.current[where] = null;
      return newState;
    });
  };

  handleSubmit = () => {
    if (!this.canSubmit()) return;

    let filters = {};
    this.state.current.filters
      .filter(f => !f.isLoading)
      .forEach(filter => {
        filters[filter.value] = filter.values
          .filter(v => v.selected)
          .map(v => v.value);
      });

    let payload = {
      datasource: this.state.current.dataSource,
      category: this.state.current.category.value,
      filterArgs: filters,
      title: this.state.current.title,
      type: this.state.current.type,
      yAxis: this.state.current.yAxis
    };
    if (payload.type === 'bar')
      payload.series = this.state.current.grouping?.value;
    let p;
    if (this.props.match?.params?.cardId)
      p = updateAnalyticsChart({
        chartId: this.props.match.params.cardId,
        payload
      });
    else
      p = createAnalyticsChart({
        dashboardId: this.props.location.state.dashboardId,
        payload
      });

    p.then(data => {
      history.goBack();
    });
  };

  getSettings = () => {
    let filters = {};
    this.state.current.filters
      .filter(f => !f.isLoading)
      .forEach(filter => {
        filters[filter.value] = filter.values
          .filter(v => v.selected)
          .map(v => v.value);
      });

    return {
      datasource: this.state.current.dataSource || null,
      category: this.state.current.category?.value || null,
      filterArgs: filters,
      series: this.state.current.grouping?.value || null,
      type: this.state.current.type,
      yAxis: this.state.current?.yAxis || 'getSum'
    };
  };

  render() {
    const { openUnsavedChangesModal } = this.state;
    const dataSource = this.state.dataSources.find(
      d => d.value === this.state.current.dataSource
    );

    let preview;
    if (!this.canPreview()) {
      preview = (
        <div className={`${styles.previewBody} ${styles.previewPending}`}>
          Choose Data Source and Category to see a preview.
        </div>
      );
    } else {
      preview = (
        <AnalyticsChart
          width={445}
          height={445}
          className={styles.previewBody}
          settings={this.getSettings()}
        />
      );
    }

    const options = [
      {
        image: svgMapper('BarChart'),
        text: 'Bar Chart',
        value: 'bar'
      },
      {
        image: svgMapper('LineChart'),
        text: 'Line Chart',
        value: 'line'
      },
      {
        image: svgMapper('PieChart'),
        text: 'Pie Chart',
        value: 'pie'
      },
      {
        visible: false,
        image: svgMapper('List'),
        text: 'List',
        value: 'list'
      },
      {
        visible: false,
        image: svgMapper('Statistic'),
        text: 'Statistic',
        value: 'statistic'
      }
    ];

    const left = (
      <PelletContainer
        pellets={this.state.dataPoints}
        empty={this.state.dataPointsEmpty}
        className={styles.pelletContainer}
      />
    );
    const header = (
      <Header
        title="My Analytics"
        section={
          this.props?.match?.params?.cardId ? 'Edit Card' : 'Create Card'
        }
        clickBack={this.leavePage}
        needsSaved={this.hasChanges()}
      />
    );
    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={this.handleSubmit}
        cancelButtonClick={this.leavePage}
        editing={this.props.match?.params?.cardId}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={left}
          className={styles.container}
        >
          <Widget className={styles.widget}>
            <div className={styles.selects}>
              <Dropdown
                fieldLabel="Data Source"
                isRequired
                placeholder="Choose One"
                currentValue={dataSource}
                handleChange={option => this.updateDataSource(option.value)}
                options={this.state.dataSources}
              />
              {this.state.yAxis?.length > 1 ? (
                <Dropdown
                  fieldLabel="Aggregate"
                  isRequired
                  currentValue={this.state.current.yAxis}
                  handleChange={option => this.onDrop(option.value, 'yAxis')}
                  options={this.state.yAxis}
                />
              ) : (
                <></>
              )}
              <PelletDrop
                fieldLabel="Category"
                oneOnly
                isRequired
                placeholder="Drag and Drop from the list"
                pellets={this.state.current.category}
                onDrop={pellet => this.onDrop(pellet, 'category')}
                onDelete={i => this.onDelete(i, 'category')}
              />
              {this.state.current.type === 'bar' ? (
                <PelletDrop
                  fieldLabel="Additional Grouping"
                  oneOnly
                  placeholder="Drag and Drop from the list"
                  pellets={this.state.current.grouping}
                  onDrop={pellet => this.onDrop(pellet, 'grouping')}
                  onDelete={i => this.onDelete(i, 'grouping')}
                />
              ) : (
                <></>
              )}
              <Textbox
                currentValue={this.state.current.title}
                fieldLabel="Card Title"
                isRequired
                placeholder="Be clear and concise"
                handleChange={e => this.update('title', e.target.value)}
              />
            </div>
            <ButtonSelector
              fieldLabel="Card Type"
              isRequired
              className={styles.type}
              value={this.state.current.type}
              onClick={v => this.update('type', v)}
              options={options}
            />
            <div className={styles.filter}>
              <PelletDrop
                fieldLabel="Filters"
                placeholder="Drag and Drop from the list"
                onDrop={pellet => this.onDrop(pellet, 'filter')}
                onDelete={i => this.onDelete(i, 'filter')}
                pellets={this.state.current.filters}
                pellet={SelectValuesPellet}
                selectAll={this.selectAll}
                clearAll={this.clearAll}
                selectValue={this.selectValue}
                className={styles.filterDrop}
              />
            </div>
            <div className={styles.preview}>
              <div className={styles.previewHeader}>Preview</div>
              {preview}
            </div>
          </Widget>
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => history.goBack()}
          savingWhat="a card"
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAnalyticsChart);
