import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import {
  deleteCompanyRequest,
  updateCompanyRequest
} from '../../actions/company';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getActiveCompany } from '../../selectors/company';
import { getMessageSelector } from '../../selectors/messages';
import { getLoggedInUser } from '../../selectors/users';
import CompanyForm from '../../forms/CompanyForm';

export class ViewEditCompany extends Component {
  state = {
    redirect: false
  };

  componentDidMount = () => {};

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  render() {
    const { redirect } = this.state;
    const {
      activeCompany,
      updateCompany,
      deleteCompany,
      addedAttachments,
      addAttachment,
      removeAttachment
    } = this.props;

    return (
      <div className="viewEdit">
        <div className="viewEditForm">
          <CompanyForm
            onSubmit={values =>
              updateCompany(values) && this.setState({ redirect: true })
            }
            initialValues={{
              ...activeCompany
            }}
            activeCompany={activeCompany}
            redirect={redirect}
            onCancel={() => this.setState({ redirect: true })}
            onDelete={() => deleteCompany(activeCompany._id)}
            isUpdate={true}
            addedAttachments={addedAttachments}
            addAttachment={addAttachment}
            removeAttachment={removeAttachment}
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  message: getMessageSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  loggedInUser: getLoggedInUser(state)
});

export const mapDispatchToProps = dispatch => ({
  updateCompany: company => dispatch(updateCompanyRequest(company)),
  deleteCompany: companyId => dispatch(deleteCompanyRequest(companyId)),
  addAttachment: values => dispatch(addUploadedAttachmentsRequest(values)),
  removeAttachment: () => dispatch(clearUploadedAttachments())
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(ViewEditCompany)
);
