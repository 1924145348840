import { call, put, select } from 'redux-saga/effects';

import { clearUser, loadUserResponse } from '../actions/user';
import { getLoadedUsers } from '../selectors/users';

export function* loadUser(api, { payload: userId }) {
  try {
    let users = yield select(getLoadedUsers);
    let loading = users?.[userId]?.loading;

    if (loading === false || loading === true) {
      return;
    }

    yield put(loadUserResponse({
      id: userId,
      loading: true
    }));

    let response = yield call(api.loadUser, userId);

    yield put(loadUserResponse({
      id: userId,
      loading: false,
      ...response
    }));
  } catch (e) {
    yield put(clearUser(userId));
    console.log(e);
  }
}
