import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { Tabbordion, TabContent, TabLabel, TabPanel } from 'react-tabbordion';
import {
  fetchUserCompaniesRequest,
  setActiveCompany
} from '../../actions/company';
import {
  updateDefaultPasswordRequest,
  updatePasswordRequest
} from '../../actions/user';
import {
  getActiveCompany,
  getUserCompaniesSelector
} from '../../selectors/company';
import { getMessageSelector } from '../../selectors/messages';
import { getLoggedInUser } from '../../selectors/users';
import AccountDetails from '../AccountDetails';
import UserProfile from '../../components/UserProfile';

import '../../components/Tabs/index.css';

export class Profile extends Component {
  componentDidMount() {
    if (!this.props.activeCompanyId) {
      this.props.setActiveCompany(this.props.userCompanies[0]);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  render() {
    const {
      activeUser,
      userCompanies,
      updatePassword,
      updateDefaultPassword
    } = this.props;

    const blockElements = {
      content: 'traditional-tabs-content',
      panel: 'traditional-tabs-panel',
      label: 'traditional-tabs-title'
    };

    return (
      <div className="settings">
        {!activeUser.isAccountOwner ? (
          <UserProfile
            activeUser={activeUser}
            userCompanies={userCompanies}
            updatePassword={updatePassword}
            updateDefaultPassword={updateDefaultPassword}
          />
        ) : (
          <Tabbordion
            blockElements={blockElements}
            className="traditional-tabs"
            name="tabs"
          >
            <TabPanel>
              <TabLabel>User Profile</TabLabel>
              <TabContent>
                <UserProfile
                  activeUser={activeUser}
                  userCompanies={userCompanies}
                  updatePassword={updatePassword}
                  updateDefaultPassword={updateDefaultPassword}
                />
              </TabContent>
            </TabPanel>
            <TabPanel>
              <TabLabel>Account Details</TabLabel>
              <TabContent>
                <AccountDetails />
              </TabContent>
            </TabPanel>
          </Tabbordion>
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    activeUser: getLoggedInUser(state),
    userCompanies: getUserCompaniesSelector(state),
    activeCompany: getActiveCompany(state),
    message: getMessageSelector(state)
  };
};

export const mapDispatchToProps = dispatch => ({
  fetchUsersCompanies: userId => dispatch(fetchUserCompaniesRequest(userId)),
  setActiveCompany: companyId => dispatch(setActiveCompany(companyId)),
  updatePassword: values => dispatch(updatePasswordRequest(values)),
  updateDefaultPassword: values =>
    dispatch(updateDefaultPasswordRequest(values))
});

export default withAlert(connect(mapStateToProps, mapDispatchToProps)(Profile));
