import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';

import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  updateIncidentBasicsRequest,
  updateIncidentRequest
} from '../../actions/incidents';
import { fetchAllActiveEmployees } from '../../actions/personnel';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getActiveIncidentSelector,
  getIncidentAttachmentsSelector,
  getIncidentBasicsSelector,
  getIncidentsLoadingSelector
} from '../../selectors/incidents';
import { getMessageSelector } from '../../selectors/messages';
import {
  getCompanyActiveEmployeesSelector,
  getCompanyUsersSelector
} from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import { isInitialReportSectionComplete } from '../../utils/incidentHelper';
import Diagram from '../../components/Diagram';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import DiagramCard from '../../components/reportCards/DiagramCard';
import PhotoEvidence from '../../components/reportCards/PhotoEvidence';
import IncidentSection from '../../components/reportCards/IncidentSection';
import SignatureCard from '../../components/SignatureCard';
import LeftNav from '../../components/LeftNav';
import Assignee from '../../components/Assignee';
import ViewOnly from '../../components/ViewOnly';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';

export class InitialReportContainer extends Component {
  state = {
    answers: {},
    leftNav: [],
    imageUrl: '',
    diagramCategory: { name: '', url: '' },
    photoEvidence: [],
    didSubmit: false,
    signature: undefined,
    openUnlockAndReopenReportModal: false,
    isEditing: false,
    hasChanges: false,
    openUnsavedChangesModal: false
  };

  componentDidMount() {
    this.props.fetchAllActiveEmployees();
    this.props.activeIncident &&
      this.setState({
        answers: { ...this.props.activeIncident.answers },
        signature: this.props.activeIncident.signature
      });
  }

  componentDidUpdate(prevProps) {
    const { message, alert } = this.props;
    if (message.message && prevProps.message.message !== message.message) {
      message.error
        ? alert.error(this.props.message.message)
        : alert.success(this.props.message.message);
    }
  }

  handleSelectedAnswer = answersObject => {
    this.setState(state => ({
      answers: { ...state.answers, ...answersObject },
      hasChanges: true
    }));
  };

  submitActions = () => {
    this.setState({ didSubmit: true });
    if (this.state.isEditing) {
      this.props.updateIncidentBasics(
        this.props.activeIncident._id,
        this.state.answers
      );
      return;
    }
    this.props.updateIncident({
      answers: this.state.answers,
      signature: this.state.signature
    });
  };

  handlePhotoUpload = async e => {
    const files = e.target.files;
    let data = new FormData();
    for (const file of Object.entries(files)) {
      data.append('attachments', file[1], file[1].name);
    }
    await this.props.addAttachment({
      data,
      ownerId: '1234',
      ownerType: 'incident',
      isPhotoEvidence: true
    });
  };

  flattenedAttachmentList = addedAttachments =>
    addedAttachments.reduce((acc, x) => acc.concat(x), []);

  updatePhotoEvidence = photo => {
    const { addedAttachments, addUploadedAttachments } = this.props;
    let flatList = this.flattenedAttachmentList(addedAttachments);
    flatList = flatList.map(currentPhoto => {
      if (currentPhoto._id === photo._id) {
        return photo;
      }
      return currentPhoto;
    });
    addUploadedAttachments(flatList);
  };

  disableSubmit = () => {
    const { incidentBasics } = this.props;
    let disableSubmit = false;
    for (const section of incidentBasics.sections) {
      if (!isInitialReportSectionComplete(section, this.state.answers)) {
        disableSubmit = true;
      }
    }
    return disableSubmit || !this.state.hasChanges || !this.state.signature;
  };

  getBodyDiagrams = addedAttachments =>
    this.flattenedAttachmentList(addedAttachments).filter(
      attachment => attachment.original_filename.indexOf('bodydiagram') > -1
    );

  getAccidentDiagrams = addedAttachments =>
    this.flattenedAttachmentList(addedAttachments).filter(
      attachment => attachment.original_filename.indexOf('roaddiagram') > -1
    );

  getVehicleDiagrams = addedAttachments =>
    this.flattenedAttachmentList(addedAttachments).filter(
      attachment => attachment.original_filename.indexOf('vehiclediagram') > -1
    );

  getAircraftDiagrams = addedAttachments =>
    this.flattenedAttachmentList(addedAttachments).filter(
      attachment => attachment.original_filename.indexOf('aircraftdiagram') > -1
    );

  handleGoBack = () => {
    const { activeIncident } = this.props;

    if (this.state.isEditing) {
      this.setState((state, props) => ({
        isEditing: false,
        answers: { ...props.activeIncident.answers }
      }));
      return;
    }

    if (!activeIncident) {
      history.push('/app/dashboard');
      return;
    }
    history.goBack();
  };

  reopenReport = () => {
    this.setState({
      openUnlockAndReopenReportModal: false,
      isEditing: true
    });
  };

  render() {
    const {
      employees,
      incidentBasics,
      activeCompany,
      activeLocation,
      activeProject,
      addAttachment,
      addedAttachments,
      deleteAttachment,
      loggedInUser,
      users,
      activeIncident
    } = this.props;

    const {
      answers,
      imageUrl,
      diagramCategory,
      didSubmit,
      isEditing
    } = this.state;
    const accessLevel = parseInt(loggedInUser?.accessLevel || 0, 10);

    const leftNav = incidentBasics.sections?.length
      ? incidentBasics.sections
          .filter(x => x)
          .map((section, i) => ({
            label: section.label,
            complete: isInitialReportSectionComplete(section, answers)
          }))
      : [];

    const isCreating = !activeIncident;

    if (leftNav && leftNav.length > 0) {
      leftNav.push({ label: 'Diagrams', id: 'diagrams' });
      leftNav.push({ label: 'Signature', id: 'signature' });
    }

    const photoEvidence = addedAttachments =>
      this.flattenedAttachmentList(addedAttachments).filter(
        attachment => attachment.isPhotoEvidence
      );

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={didSubmit || this.disableSubmit()}
        saveButtonClick={this.submitActions}
        cancelButtonClick={() =>
          this.state.hasChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
        editing={true}
      />
    );

    const reporter = (
      <Assignee
        text="Reported By"
        user={activeIncident?.createdByUserId || loggedInUser._id}
      />
    );

    const left = (
      <LeftNav
        items={leftNav}
        onAddEvidence={!isEditing && (e => this.handlePhotoUpload(e))}
      />
    );

    const headerCenter = activeIncident && (
      <ViewOnly
        canUnlock={
          !isEditing &&
          (accessLevel === 900 ||
            activeIncident.createdByUserId === loggedInUser?._id)
        }
        incident={activeIncident}
        onClick={() => this.setState({ openUnlockAndReopenReportModal: true })}
      />
    );

    const header = (
      <Header
        title="Initial Report"
        needsSaved={this.state.hasChanges}
        clickBack={() =>
          this.state.hasChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
        center={headerCenter}
        right={reporter}
      />
    );

    return !imageUrl ? (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={(isCreating || isEditing) && left}
          showFooter={isCreating || isEditing}
        >
          {incidentBasics.sections.map((basic, index) => (
            <IncidentSection
              name={index}
              reportSectionHeader={basic.label}
              fields={basic.fields}
              key={index}
              employees={employees}
              handleSelectedAnswer={answerObject =>
                this.handleSelectedAnswer(answerObject)
              }
              answers={answers}
              groupList={activeCompany.groups}
              activeLocation={activeLocation}
              isIncidentBasics={index === 0}
              activeProject={activeProject}
              activeIncident={activeIncident}
              isCreating={isCreating}
              isEditing={isEditing}
            />
          ))}
          {photoEvidence(addedAttachments).map((photo, index) => (
            <PhotoEvidence
              photo={photo}
              key={incidentBasics.sections.length + index}
              name={incidentBasics.sections.length + index}
              companyUsers={users}
              updatePhotoEvidence={values =>
                this.updatePhotoEvidence({ ...photo, ...values })
              }
              canRemoveEdit={isCreating}
            />
          ))}
          <DiagramCard
            reportSectionHeader="Diagrams"
            bodyDiagrams={this.getBodyDiagrams(addedAttachments)}
            accidentDiagrams={this.getAccidentDiagrams(addedAttachments)}
            vehicleDiagrams={this.getVehicleDiagrams(addedAttachments)}
            aircraftDiagrams={this.getAircraftDiagrams(addedAttachments)}
            deleteAttachment={deleteAttachment}
            handleDiagramSelection={value => this.setState(value)}
            name="diagrams"
            canRemoveEdit={isCreating}
            hideNA={true}
            showAircraft={activeCompany.showAircraft}
          />
          <SignatureCard
            header="Signature"
            name="signature"
            onChange={signature => this.setState({ signature })}
            disabled={!isCreating}
            currentValue={this.state.signature}
          />
        </HeaderAndFooter>
        <Modal
          title="Unlock and Re-Open Report"
          titleClassName="blueHeader"
          isOpen={this.state.openUnlockAndReopenReportModal}
          submitButtonColor="blue"
          submitButtonText="Re-open Report"
          onRequestClose={() =>
            this.setState({ openUnlockAndReopenReportModal: false })
          }
          submitActions={this.reopenReport}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Unlocking this report will allow you –and users with edit
            permission– to make changes and additions to the report.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This also means that the report will no longer be considered
              completed until it is filled out, saved, and marked as complete
              once more. Re-Opening this report will also remove the currently
              assigned user.
            </span>{' '}
            You can reassign this report to the same or a different user as a
            new task.
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={this.state.openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleGoBack()}
          savingWhat="an incident"
        />
      </>
    ) : (
      <div className="initialReportContainer">
        <div>
          {diagramCategory.name !== '' && (
            <Diagram
              imageUrl={imageUrl}
              addAttachment={addAttachment}
              resetDiagrams={() =>
                this.setState({
                  imageUrl: '',
                  diagramCategory: { name: '', url: '' },
                  diagramSubcategory: { name: '', url: '' }
                })
              }
              category={diagramCategory.name}
              loggedInUser={loggedInUser}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  message: getMessageSelector(state),
  employees: getCompanyActiveEmployeesSelector(state),
  users: getCompanyUsersSelector(state),
  incidentBasics: getIncidentBasicsSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  incidentLoading: getIncidentsLoadingSelector(state),
  addedAttachments: getIncidentAttachmentsSelector(state),
  activeProject: getActiveProject(state),
  activeIncident: getActiveIncidentSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateIncident: incident => dispatch(updateIncidentRequest(incident)),
  updateIncidentBasics: (id, answers) =>
    dispatch(updateIncidentBasicsRequest({ id, answers })),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  deleteAttachment: attachment => dispatch(deleteAttachmentRequest(attachment)),
  fetchAllActiveEmployees: () => dispatch(fetchAllActiveEmployees()),
  addUploadedAttachments: values =>
    dispatch(addUploadedAttachmentsResponse(values))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(InitialReportContainer)
);
