export const circleDataMapper = (circleData, type) => {
  return [
    {
      timeFrame:
        // eslint-disable-next-line
        circleData.daysWithout == 10000
          ? `No ${type} Recorded`
          : `Days Without ${type}`,
      // eslint-disable-next-line
      count: circleData.daysWithout == 10000 ? '' : circleData.daysWithout
    },
    {
      timeFrame: 'Past 7 Days',
      count: circleData.pastSeven
    },
    {
      timeFrame: 'Past 30 Days',
      count: circleData.pastThirty
    },
    {
      timeFrame: 'Total',
      count: circleData.total
    }
  ];
};
