import { all, call, put, select } from 'redux-saga/effects';
import history from '../history';
import { newUserRegistrationResponse } from '../actions/auth';
import {
  addCompanyLocationResponse,
  addCompanyResponse,
  deleteCompanyLocationResponse,
  deleteCompanyResponse,
  fetchUserCompaniesResponse,
  setActiveCompany,
  setActiveLocation,
  updateCompanyLocationResponse,
  updateCompanyResponse
} from '../actions/company';
import { addMessage, removeMessage } from '../actions/messages';
import { fetchUserProfile } from '../actions/user';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveCompany,
  getUserCompaniesSelector
} from '../selectors/company';
import { getLoggedInUser } from '../selectors/users';

export function* fetchUsersCompanies(api, { payload }) {
  try {
    const userCompanies = yield select(getUserCompaniesSelector);

    yield put(fetchUserCompaniesResponse(userCompanies));
  } catch (e) {
    console.log(e);
  }
}

export function* addCompany(api, { payload }) {
  const user = yield select(getLoggedInUser);
  try {
    yield put(removeMessage());

    const companyLogo = yield select(getAddedAttachmentsSelector);

    let newLogo = [];

    if (companyLogo.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = companyLogo.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: payload._id
          })
        )
      );

      newLogo = temp;
    }

    payload = {
      ...payload,
      logoUrl: newLogo.length > 0 ? newLogo[0].source_url : ''
    };

    const response = yield call(api.addCompany, payload);

    if (user.companies) {
      user.companies = [...user.companies, response];
    } else {
      user.companies = [response];
    }

    yield put(addCompanyResponse(response));
    yield put(setActiveCompany(response));
    yield put(setActiveLocation(0));
    yield put(fetchUserProfile(user));
    yield put(fetchUserCompaniesResponse(user.companies));

    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(fetchUserCompaniesResponse(user.companies));
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateCompany(api, { payload }) {
  const user = yield select(getLoggedInUser);
  try {
    yield put(removeMessage());
    const companyLogo = yield select(getAddedAttachmentsSelector);

    let newLogo = [];

    if (companyLogo.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = companyLogo.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: payload._id
          })
        )
      );

      newLogo = temp;
    }

    payload = {
      ...payload,
      logoUrl: newLogo.length > 0 ? newLogo[0].source_url : payload.logoUrl
    };

    let response = yield call(api.updateCompany, payload._id, payload);
    const findCompany = user.companies.findIndex(
      company => company._id === response._id
    );

    response = {
      ...response,
      groups: user.companies[findCompany].groups
    };

    yield put(updateCompanyResponse(response));
    yield put(setActiveCompany(response));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    const findCompany = user.companies.findIndex(
      company => company._id === payload._id
    );

    yield put(fetchUserCompaniesResponse(user.companies));
    yield put(setActiveCompany(user.companies[findCompany]));
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addCompanyLocation(api, { payload }) {
  const activeCompany = yield select(getActiveCompany);
  const userCompanies = yield select(getUserCompaniesSelector);
  const isOnboarding = payload.isOnboarding;

  try {
    yield put(removeMessage());
    yield put(fetchUserCompaniesResponse(0));

    payload = { ...payload, companyId: activeCompany._id };

    const response = yield call(api.createGroup, payload);

    activeCompany.locations = [...activeCompany.groups, response];
    activeCompany.groups = [...activeCompany.groups, response];

    const companyToUpdate = userCompanies.findIndex(
      company => company._id === payload.companyId
    );

    userCompanies[companyToUpdate] = activeCompany;

    yield put(fetchUserCompaniesResponse(userCompanies));
    yield put(updateCompanyResponse(activeCompany));
    yield put(setActiveCompany(activeCompany));

    yield put(setActiveLocation(response));
    yield put(addCompanyLocationResponse(activeCompany.groups));

    yield put(addMessage({ error: false, message: 'Saved Successfully' }));

    if (isOnboarding) {
      yield put(newUserRegistrationResponse(false));
    }
  } catch (e) {
    yield put(fetchUserCompaniesResponse(userCompanies));
    yield put(setActiveCompany(activeCompany));
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateCompanyLocation(api, { payload }) {
  let activeCompany = yield select(getActiveCompany);
  let userCompanies = yield select(getUserCompaniesSelector);

  try {
    yield put(removeMessage());
    userCompanies = [...userCompanies];
    activeCompany = { ...activeCompany };

    const updatedLocation = activeCompany.groups.findIndex(
      location => location._id === payload._id
    );

    payload = { ...payload, companyId: activeCompany._id };

    const response = yield call(api.updateGroup, payload);
    activeCompany.locations[updatedLocation] = {
      ...response,
      projects: activeCompany.locations[updatedLocation].projects
    };
    activeCompany.groups[updatedLocation] = {
      ...response,
      projects: activeCompany.locations[updatedLocation].projects
    };

    const companyToUpdate = userCompanies.findIndex(
      company => company._id === payload.companyId
    );

    userCompanies[companyToUpdate] = activeCompany;

    yield put(fetchUserCompaniesResponse(userCompanies));

    yield put(updateCompanyResponse(activeCompany));
    yield put(setActiveCompany(activeCompany));
    yield put(
      setActiveLocation({
        ...response,
        projects: activeCompany.locations[updatedLocation].projects
      })
    );
    yield put(
      updateCompanyLocationResponse({
        ...response,
        projects: activeCompany.locations[updatedLocation].projects
      })
    );
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(setActiveLocation(0));
    yield put(fetchUserCompaniesResponse(userCompanies));
    yield put(setActiveCompany(activeCompany));
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteCompanyLocation(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    yield call(api.deleteGroup, {
      ...payload,
      companyId: activeCompany._id
    });

    const deletedLocation = activeCompany.groups.findIndex(
      location => location._id === payload.id
    );

    activeCompany.groups = [
      ...activeCompany.locations.slice(0, deletedLocation),
      ...activeCompany.locations.slice(deletedLocation + 1)
    ];

    activeCompany.locations = [
      ...activeCompany.locations.slice(0, deletedLocation),
      ...activeCompany.locations.slice(deletedLocation + 1)
    ];

    activeCompany.allLocations = [
      ...activeCompany.locations.slice(0, deletedLocation),
      ...activeCompany.locations.slice(deletedLocation + 1)
    ];

    yield put(updateCompanyResponse(activeCompany));
    yield put(setActiveCompany(activeCompany));
    yield put(deleteCompanyLocationResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
    yield put(setActiveLocation(0));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteCompany(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteCompany, payload);

    yield put(deleteCompanyResponse(payload));
    history.push('/home');

    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addGroupProject(api, { payload }) {
  const userCompanies = yield select(getUserCompaniesSelector);
  const activeCompany = yield select(getActiveCompany);

  try {
    yield put(removeMessage());
    yield put(fetchUserCompaniesResponse(0));

    const response = yield call(api.addGroupProject, payload);

    const companyToUpdate = userCompanies.findIndex(
      company => company._id === payload.companyId
    );

    const groupToUpdate = userCompanies[companyToUpdate].groups.findIndex(
      group => group._id === payload.groupId
    );

    let projectList = [];

    if (
      userCompanies[companyToUpdate].groups[groupToUpdate].projects.length > 0
    ) {
      projectList = [
        ...userCompanies[companyToUpdate].groups[groupToUpdate].projects,
        response
      ];
    } else {
      projectList = [response];
    }

    userCompanies[companyToUpdate].groups[groupToUpdate].projects = projectList;

    yield put(fetchUserCompaniesResponse(userCompanies));
    yield put(addMessage({ error: false, message: 'Saved Succcessfully' }));
  } catch (e) {
    yield put(fetchUserCompaniesResponse(userCompanies));
    yield put(setActiveCompany(activeCompany));
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateGroupProject(api, { payload }) {
  const userCompanies = yield select(getUserCompaniesSelector);
  const activeCompany = yield select(getActiveCompany);
  try {
    yield put(removeMessage());

    yield put(fetchUserCompaniesResponse([]));
    yield put(setActiveCompany({}));

    const response = yield call(api.updateGroupProject, payload);

    const companyToUpdate = userCompanies.findIndex(
      company => company._id === payload.companyId
    );
    const groupToUpdate = userCompanies[companyToUpdate].groups.findIndex(
      group => group._id === payload.groupId
    );

    const projectToUpdate = userCompanies[companyToUpdate].groups[
      groupToUpdate
    ].projects.findIndex(project => project._id === response._id);

    let projectList =
      userCompanies[companyToUpdate].groups[groupToUpdate].projects;
    projectList[projectToUpdate] = response;

    userCompanies[companyToUpdate].groups[groupToUpdate].projects = projectList;

    yield put(fetchUserCompaniesResponse(userCompanies));
    yield put(setActiveCompany(userCompanies[companyToUpdate]));
    yield put(addMessage({ error: false, message: 'Saved Succcessfully' }));
  } catch (e) {
    yield put(fetchUserCompaniesResponse(userCompanies));
    yield put(setActiveCompany(activeCompany));
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteGroupProject(api, { payload }) {
  try {
    yield put(removeMessage());
    const userCompanies = yield select(getUserCompaniesSelector);
    yield put(fetchUserCompaniesResponse([]));

    yield call(api.deleteGroupProject, payload);

    const companyToUpdate = userCompanies.findIndex(
      company => company._id === payload.companyId
    );
    const groupToUpdate = userCompanies[companyToUpdate].groups.findIndex(
      group => group._id === payload.groupId
    );

    let projectList =
      userCompanies[companyToUpdate].groups[groupToUpdate].projects;
    const indexToDelete = projectList.findIndex(
      project => project._id === payload._id
    );

    projectList = [
      ...projectList.slice(0, indexToDelete),
      ...projectList.slice(indexToDelete + 1)
    ];

    userCompanies[companyToUpdate].groups[groupToUpdate].projects = projectList;

    yield put(fetchUserCompaniesResponse(userCompanies));
    yield put(addMessage({ error: false, message: 'Deleted Succcessfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}
