import moment from 'moment';

export const reportDateTimeMapper = (sections, answers) => {
  if (!answers) {
    return {};
  }

  let questionAnswers = {};

  sections.forEach(
    section =>
      section &&
      section.fields.forEach(field => {
        if (answers.hasOwnProperty(field._id)) {
          if (field.type === 'dateTimePicker' && answers[field._id]) {
            const dateTime = moment(answers[field._id])
              .format('MM/DD/YYYY hh:mm a')
              .split(' ');

            questionAnswers[`${field._id}_date`] = dateTime[0];
            questionAnswers[`${field._id}_time`] = `${
              dateTime[1]
            } ${dateTime[2].toUpperCase()}`;
          }
        }
      })
  );

  return questionAnswers;
};

export const dbDateTimeMapper = answers => {
  if (!answers) {
    return {};
  }

  let questionAnswers = answers;

  const keys = Object.keys(questionAnswers);
  keys.forEach(key => {
    if (key.indexOf('_date') > -1) {
      const actualId = key.substr(0, 24);

      const date = questionAnswers[`${actualId}_date`];
      const time = questionAnswers[`${actualId}_time`];
      questionAnswers[actualId] = new Date(`${date} ${time.trim()}`);
      delete questionAnswers[`${actualId}_date`];
      delete questionAnswers[`${actualId}_time`];
    }
  });

  return questionAnswers;
};

export const dbIncidentDateTimeMapper = (sections, answers) => {
  if (!answers) {
    return {};
  }

  const questionAnswers = {};

  sections.forEach(
    section =>
      section &&
      section.fields.forEach(field => {
        if (answers.hasOwnProperty(field._id)) {
          if (
            (field.type === 'dateTimePicker' || field.type === 'datePicker') &&
            answers[field._id]
          ) {
            questionAnswers[field._id] = new Date(answers[field._id]);
          }
          questionAnswers[field._id] = answers[field._id];
        }
      })
  );

  return questionAnswers;
};
