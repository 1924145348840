import React from 'react';
import ReactTable from 'react-table';
import history from '../../../history';
import { getUserInfo } from '../../../utils/assignedToMapper';
import { sortUser, filterUser } from '../../../utils/sortFilterHelpers';
import AuthorizeRejectButtons from '../../inputs/AuthorizeRejectButtons';
import Button from '../../inputs/Button';

import tableStyles from './styles.module.scss';

export default function QuizTable(props) {
  return (
    <ReactTable
      data={props.childQuizzes}
      columns={[
        {
          Header: 'ASSIGNEE',
          accessor: d => d.assignee,
          id: 'assignee',
          Cell: ({ original }) => {
            const userInfo = getUserInfo(original.assignee, props.users);
            return `${userInfo.firstName} ${userInfo.lastName}`;
          },
          sortMethod: (a, b) => sortUser(a, b, props.users),
          filterMethod: (filter, row) => filterUser(filter, row, props.users)
        },
        {
          Header: 'PASS/FAIL',
          accessor: d => d.quiz.passedQuiz,
          id: 'passedQuiz',
          Cell: ({ original }) =>
            original.quiz.passedQuiz && original.quiz.isCompleted
              ? 'Passed'
              : !original.quiz.passedQuiz && original.quiz.isCompleted
              ? 'Failed'
              : 'In Progress'
        },
        {
          Header: '# OF ATTEMPTS',
          accessor: d => d.quiz.attemptNumber,
          id: 'attemptNumber',
          Cell: ({ original }) => {
            return original.quiz.isCompleted
              ? original.quiz.attemptNumber
              : original.quiz.attemptNumber - 1;
          }
        },
        {
          Header: 'VIEW',
          Cell: ({ original }) => (
            <Button
              color="blue"
              onClick={() => {
                props.setActiveTraining(original);
                history.push('/app/training/TrainingQuizViewer');
              }}
              text="View"
            />
          ),
          filterable: false,
          sortable: false
        },
        {
          Header: 'AUTHORIZE',
          Cell: ({ original }) => {
            if (props.isApprover) {
              let approveReject = '';
              const index = props.assigneeApproval.findIndex(
                aa => aa.assignee === original.assignee
              );

              approveReject =
                index > -1 ? props.assigneeApproval[index].approveReject : '';
              return (
                <AuthorizeRejectButtons
                  answer={approveReject}
                  updateAnswer={values =>
                    props.updateAnswer({
                      approveReject: values,
                      assignee: original.assignee
                    })
                  }
                  className={tableStyles.authorizeRejectButtons}
                />
              );
            } else if (original.approveReject) {
              return (
                <Button
                  color={original.approveReject === 'reject' ? 'red' : 'blue'}
                  text={
                    original.approveReject === 'reject' ? 'Reject' : 'Approve'
                  }
                />
              );
            } else {
              return 'In Progress';
            }
          },
          sortable: false,
          filterable: false
        }
      ]}
      filterable
      resizable={true}
      noDataText="No results found"
      defaultPageSize={10}
      className="-striped -highlight"
    />
  );
}
