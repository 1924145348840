import React from 'react';
import moment from 'moment';
import ReactLoading from 'react-loading';
import Button from '../inputs/Button';
import PaginationList from '../PaginationList';
import UserBadge from '../UserBadge';
import Widget from '../Widget';

import './index.css';

const TaskWidget = ({
  taskList,
  setActiveTask,
  taskLoading,
  accessLevel,
  taskPagination,
  fetchUsersTasks
}) => (
  <Widget>
    <div className="taskList">
      <h2 className="taskList-header">
        My Tasks{' '}
        <div className="settings-buttonBox">
          {accessLevel !== '100' ? (
            <Button
              className="add"
              text="Add Task"
              onClick={() => setActiveTask({})}
            />
          ) : (
            ''
          )}
        </div>
      </h2>
      <ul className="taskList-list">
        {taskLoading ? (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        ) : (
          taskList.map((task, index) => (
            <li key={index}>
              <div
                onClick={() => setActiveTask(task)}
                className={
                  moment().diff(Date.parse(task.dueDate), 'days') > 0
                    ? 'taskList-listCard pastDue'
                    : 'taskList-listCard'
                }
              >
                <h3>
                  {moment().diff(Date.parse(task.dueDate), 'days') > 0
                    ? 'Past Due'
                    : 'Assigned'}
                </h3>
                <div className="taskList-card">
                  <div className="taskList-cardLeft">
                    <div>
                      <div className="taskList-cardTypes">Created By: </div>
                      <div className="incidentCard-left-profile">
                        <UserBadge
                          userId={task.createdBy}
                          showImage
                          hoverName
                        />
                      </div>
                    </div>
                    <div className="taskList-cardMargin">
                      <div className="taskList-cardTypes">Title: </div>
                      {task.label}
                    </div>
                    <div>
                      <div className="taskList-cardTypes">Due Date: </div>
                      {moment(Date.parse(task.dueDate)).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div className="taskList-cardRight">
                    <div>
                      <div className="taskList-cardTypes">Assigned To: </div>
                      <div className="taskList-cardTypes--profile">
                        {task.assignedTo.length < 4 ? (
                          task.assignedTo.map((userId, index) =>
                            <UserBadge
                              showImage
                              userId={userId}
                              key={index}
                            />
                          )
                        ) : (
                          <div>4+</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
    {taskPagination.totalPages > 1 && (
      <PaginationList
        page={taskPagination.page}
        totalPages={taskPagination.totalPages}
        fetchAction={fetchUsersTasks}
      />
    )}
  </Widget>
);

export default TaskWidget;
