import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import './index.css';

export default class Osha300List extends Component {
  isEllipsisActive = id => {
    const activeWidth = document.getElementById(id);
    if (activeWidth) {
      return activeWidth.clientWidth < activeWidth.scrollWidth;
    }

    return false;
  };

  handleRowClick = values => {
    if (!values.isRedLined) {
      this.props.goTo301({ selected: 2, selectedRow: values });
    }
  };

  render() {
    const { cases, setActiveIncident } = this.props;

    return (
      <div className="osha300List" id="osha300List">
        <table className="osha300List-table">
          <thead>
            <tr>
              <th
                colSpan="3"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--firstTopHeader"
              >
                Identify The Person
              </th>
              <th
                colSpan="3"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--secondTopHeader"
              >
                Describe The Case
              </th>
              <th
                colSpan="1"
                rowSpan="2"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--thirdTopHeader"
              >
                Classify The Case
              </th>
              <th
                colSpan="2"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--fourthTopHeader"
              >
                Days Affected
              </th>
              <th
                colSpan="1"
                rowSpan="2"
                scope="colgroup"
                className="osha300List-table--topHeader osha300List-table--fifthTopHeader"
              >
                Injury or Illness
              </th>
            </tr>
            <tr>
              <th scope="col" className="paddingBottom">
                Case #
              </th>
              <th scope="col" className="leftAlign paddingBottom">
                Worker Name
              </th>
              <th scope="col" className="leftAlign paddingBottom">
                Job Title
              </th>
              <th scope="col" className="paddingBottom">
                Date
              </th>
              <th scope="col" className="leftAlign paddingBottom">
                Location
              </th>
              <th scope="col" className="leftAlign paddingBottom">
                Description
              </th>
              <th scope="col" className="paddingBottom">
                Away
              </th>
              <th scope="col" className="paddingBottom">
                At Work
              </th>
            </tr>
          </thead>
          <tbody>
            {cases &&
              cases.map((row, index) => (
                <tr
                  key={index}
                  className={row.isIncomplete ? 'isIncomplete' : 'isComplete'}
                  onClick={() => this.handleRowClick(row)}
                >
                  <td
                    className={
                      row.isRedLined ? 'paddingTop redLined' : 'paddingTop'
                    }
                  >
                    {row.isIncomplete && (
                      <div
                        className="editicons"
                        onClick={() => setActiveIncident(row.incidentId)}
                      >
                        <ReactTooltip
                          className="oshaListErrorTheme"
                          id="divEstError"
                        >
                          <p>Missing OSHA compliant information.</p>
                          <p>Click to Go Edit Case </p>
                        </ReactTooltip>
                        <img
                          style={{ width: '32px', height: '32px' }}
                          src={require('../../assets/images/incompleteCase.png')}
                          alt="caseIncomplete"
                          data-tip="oshaListErrorTheme"
                          data-for="divEstError"
                        />
                      </div>
                    )}
                    {row.caseNumber}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-secondWidth redLined'
                        : 'osha300List-secondWidth'
                    }
                  >
                    {!row.privacyConcern ? row.workerName : 'Privacy Concern'}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-thirdWidth redLined'
                        : 'osha300List-thirdWidth'
                    }
                  >
                    {!row.privacyConcern ? row.jobTitle : '-----------------'}
                  </td>
                  <td
                    className={
                      row.isRedLined ? 'paddingTop redLined' : 'paddingTop'
                    }
                  >
                    {row.dateOfClaim !== ''
                      ? moment(row.dateOfClaim).format('MM/DD/YYYY')
                      : row.dateOfClaim}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-thirdWidth redLined'
                        : 'osha300List-thirdWidth'
                    }
                    id={`location${index}`}
                    data-tip="customTheme"
                    data-for={`tooltipLocation${index}`}
                  >
                    {/* TODO figure this out
                    {this.isEllipsisActive(`location${index}`) &&
                      renderTooltip(
                        `tooltipLocation${index}`,
                        row.locationOfClaim
                      )} */}
                    {row.locationOfClaim}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-sixWidth redLined'
                        : 'osha300List-sixWidth'
                    }
                  >
                    {row.descriptionOfClaim}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-seventhWidth redLined'
                        : 'osha300List-seventhWidth'
                    }
                  >
                    {row.caseClassification}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-eighthWidth redLined'
                        : 'osha300List-eighthWidth'
                    }
                  >
                    {row.daysAwayFromWork}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-eighthWidth redLined'
                        : 'osha300List-eighthWidth'
                    }
                  >
                    {row.daysOnJobRestrictionOrTransfer}
                  </td>
                  <td
                    className={
                      row.isRedLined
                        ? 'osha300List-tenthWidth redLined'
                        : 'osha300List-tenthhWidth'
                    }
                  >
                    {row.typeOfIllnessOrInjury}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
