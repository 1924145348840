import {
  FETCH_CUSTOM_TRAINING_TEMPLATES_RESPONSE,
  SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE,
  FETCH_TRAININGS_RESPONSE,
  SET_ACTIVE_TRAINING_RESPONSE,
  SET_ACTIVE_TRAINING_ATTACHMENT
} from '../constants/actionTypes';

export const initialState = {
  list: [],
  loading: false,
  activeTrainingTemplate: {},
  activeTraining: {},
  templates: [],
  activeTrainingAttachment: {}
};

const trainings = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOM_TRAINING_TEMPLATES_RESPONSE:
      return {
        ...state,
        templates: payload
      };

    case SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE:
      return {
        ...state,
        activeTrainingTemplate: payload
      };

    case FETCH_TRAININGS_RESPONSE:
      return {
        ...state,
        list: payload
      };

    case SET_ACTIVE_TRAINING_RESPONSE:
      return {
        ...state,
        activeTraining: payload
      };

    case SET_ACTIVE_TRAINING_ATTACHMENT:
      return {
        ...state,
        activeTrainingAttachment: payload
      };

    default:
      return {
        ...state
      };
  }
};

export default trainings;
