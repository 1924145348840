import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import history from '../history';
import {
  addJsaAttachmentsResponse,
  addJsaNotesResponse,
  createCustomTemplateResponse,
  createJsaResponse,
  deleteJsaResponse,
  fetchCompanyJsaResponse,
  fetchCustomTemplatesResponse,
  fetchJsaCirclesResponse,
  fetchJsaSectionsResponse,
  fetchLocationJsaResponse,
  setActiveJsaResponse,
  setActiveRaCustomTemplate,
  updateCustomTemplateResponse,
  updateJsaResponse,
  setActiveJsaRequest
} from '../actions/jsa';
import { addMessage, removeMessage } from '../actions/messages';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import {
  getActiveJsaSelector,
  getCustomRaTemplatesSelector
} from '../selectors/jsa';
import { getLoggedInUser } from '../selectors/users';
import { circleDataMapper } from '../utils/circleDataMapper';

export function* fetchCompanyJsas(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const user = yield select(getLoggedInUser);

    let response = [];

    if (payload.isRecurring) {
      response = yield call(
        api.fetchRecurringJsasByCompanyId,
        company._id,
        payload.page,
        payload.limit
      );

      response = { list: response.docs };
    } else {
      if (payload.isLean) {
        response = yield call(
          api.fetchJsasByCompanyIdLean,
          company._id,
          payload.page,
          payload.limit,
          payload.removeClosed
        );
      } else {
        response = yield call(
          api.fetchJsasByCompanyId,
          company._id,
          payload.page,
          payload.limit,
          payload.removeClosed
        );
      }

      if (user.accessLevel === '500') {
        let userLocations = user.companies.filter(
          userCompany => userCompany._id === company._id
        )[0].locations;

        userLocations = userLocations.map(location => {
          return location._id;
        });

        response.docs = response.docs.filter(
          jsa => userLocations.indexOf(jsa.locationId) !== -1
        );
      }

      response = {
        list: response.docs,
        paginationInfo: {
          hasNextPage: response.hasNextPage,
          hasPrevPage: response.hasPrevPage,
          limit: response.limit,
          nextPage: response.nextPage,
          page: response.page,
          prevPage: response.prevPage,
          totalDocs: response.totalDocs,
          totalPages: response.totalPages
        }
      };

      const circleResponse = yield call(api.fetchCompanyCircleData, {
        companyId: company._id,
        reportType: '8'
      });

      const circles = circleDataMapper(circleResponse, 'Risk Assessments');
      yield put(fetchJsaCirclesResponse(circles));
    }

    yield put(fetchCompanyJsaResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchLocationJsas(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const locationId = yield select(getActiveLocationId);
    let response;
    if (payload.isRecurring) {
      response = yield call(
        api.fetchRecurringJsasByLocationId,
        locationId._id,
        payload.page,
        payload.limit
      );

      response = { list: response.docs };
    } else {
      if (payload.isLean) {
        response = yield call(api.fetchJsasByLocationIdLean, locationId._id);
      } else {
        response = yield call(
          api.fetchJsasByLocationId,
          locationId._id,
          payload.page,
          payload.limit
        );
      }

      response = {
        list: response.docs,
        paginationInfo: {
          hasNextPage: response.hasNextPage,
          hasPrevPage: response.hasPrevPage,
          limit: response.limit,
          nextPage: response.nextPage,
          page: response.page,
          prevPage: response.prevPage,
          totalDocs: response.totalDocs,
          totalPages: response.totalPages
        }
      };

      const circleResponse = yield call(api.fetchLocationCircleData, {
        companyId: company._id,
        locationId: locationId._id,
        reportType: '8'
      });

      const circles = circleDataMapper(circleResponse, 'Risk Assessments');
      yield put(fetchJsaCirclesResponse(circles));
    }

    yield put(fetchLocationJsaResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchProjectJsas(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const project = yield select(getActiveProject);

    let response;

    if (payload.isRecurring) {
      response = yield call(
        api.fetchRecurringJsasByProjectId,
        project._id,
        payload.page,
        payload.limit
      );
      response = { list: response.docs };
    } else {
      if (payload.isLean) {
        response = yield call(
          api.fetchJsasByProjectIdLean,
          project._id,
          payload.page,
          payload.limit
        );
      } else {
        response = yield call(
          api.fetchJsasByProjectId,
          project._id,
          payload.page,
          payload.limit
        );
      }

      response = {
        list: response.docs,
        paginationInfo: {
          hasNextPage: response.hasNextPage,
          hasPrevPage: response.hasPrevPage,
          limit: response.limit,
          nextPage: response.nextPage,
          page: response.page,
          prevPage: response.prevPage,
          totalDocs: response.totalDocs,
          totalPages: response.totalPages
        }
      };

      const circleResponse = yield call(api.fetchProjectCircleData, {
        companyId: company._id,
        projectId: project._id,
        reportType: '8'
      });

      const circles = circleDataMapper(circleResponse, 'Risk Assessments');
      yield put(fetchJsaCirclesResponse(circles));
    }

    yield put(fetchLocationJsaResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchUserJsas(api, { payload }) {
  try {
    const user = yield select(getLoggedInUser);
    const activeCompany = yield select(getActiveCompany);

    let assignedToResponse = yield call(
      api.fetchAllUserJsas,
      activeCompany._id,
      user._id,
      payload.page,
      payload.limit,
      payload.removeClosed
    );

    assignedToResponse = {
      list: assignedToResponse.docs,
      paginationInfo: {
        hasNextPage: assignedToResponse.hasNextPage,
        hasPrevPage: assignedToResponse.hasPrevPage,
        limit: assignedToResponse.limit,
        nextPage: assignedToResponse.nextPage,
        page: assignedToResponse.page,
        prevPage: assignedToResponse.prevPage,
        totalDocs: assignedToResponse.totalDocs,
        totalPages: assignedToResponse.totalPages
      }
    };

    yield put(fetchLocationJsaResponse(assignedToResponse));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchJsaSections(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const jsaQuestions = yield call(
      api.getJsaQuestions,
      activeCompany._id,
      payload
    );

    let sections = [];
    jsaQuestions.forEach(question => {
      if (sections.indexOf(question.sections) === -1) {
        sections.push(question.sections);
      }
    });

    yield put(fetchJsaSectionsResponse(sections));
  } catch (e) {
    console.log(e);
  }
}

export function* setActiveJsa(api, { payload }) {
  try {
    const sections = payload.sections;
    const goToSummary = payload.goToSummary;

    let tasks = yield all(
      sections.reduce(
        (acc, section) =>
          acc.concat(
            section.questions.map(question =>
              call(api.fetchTasksByOwnerId, question._id, 1)
            )
          ),
        []
      )
    );

    tasks =
      tasks.length > 0
        ? tasks.filter(task => task?.docs?.length > 0).map(task => task.docs)
        : [];

    sections.map(section =>
      section.questions.forEach(question => {
        const taskIndex = tasks.findIndex(
          task => task[0].ownerId === question._id
        );
        if (taskIndex > -1) {
          question.task = tasks[taskIndex][0];
        } else {
          question.task = null;
        }
      })
    );

    if (payload.isLocked) {
      let taskList = [];

      tasks.forEach(task => {
        if (task.length > 0) {
          taskList.push(...task);
        }
      });

      let correctiveActionsCreated = 0;
      let correctiveActionsCompleted = 0;
      let correctiveActionsPastDue = 0;

      if (taskList.length) {
        correctiveActionsCreated = taskList.length;
        correctiveActionsCompleted = taskList.filter(task => task.isCompleted)
          .length;
        correctiveActionsPastDue = taskList.filter(
          task => moment(task.dueDate).isBefore() && !task.isCompleted
        ).length;
      }

      payload.summary = {
        ...payload.summary,
        correctiveActionsCreated,
        correctiveActionsCompleted,
        correctiveActionsPastDue
      };
    }

    payload.sections = sections;
    delete payload.goToSummary;
    delete payload.taskDeleted;

    yield put(setActiveJsaResponse(payload));

    goToSummary
      ? history.push('/app/raSummaryContainer')
      : history.push('/app/raContainer');
  } catch (e) {
    console.log(e);
  }
}

export function* createJsa(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);

    const sections = Array.isArray(payload.sections)
      ? payload.sections.map(section => section.label)
      : [payload.sections.label];
    const assignedTo = payload.assignedTo.map(user => user.value);

    let actualQuestions = [];
    let questions = [];

    if (payload.industry.label !== 'Custom') {
      actualQuestions = yield all(
        sections.map(section =>
          call(
            api.getJsaQuestionsBySections,
            activeCompany._id,
            section,
            payload.industry.label
          )
        )
      );
      actualQuestions.forEach(section =>
        section.length > 0
          ? questions.push({
              industry: section[0].industry,
              sectionName: section[0].sections,
              questions: section
            })
          : ''
      );
    } else {
      const templates = yield select(getCustomRaTemplatesSelector);

      actualQuestions = templates.filter(
        template => sections.indexOf(template.title) > -1
      );

      actualQuestions.map(section =>
        questions.push({
          industry: 'Custom',
          sectionName: section.title,
          questions: section.questions
        })
      );
    }

    payload = {
      locationId: activeGroup ? activeGroup._id : payload.locationId,
      projectId: activeProject ? activeProject._id : payload.projectId,
      title: 'JSA',
      dueDate: moment(payload.dueDate),
      industry: payload.industry.label,
      companyId: activeCompany._id,
      sections: questions,
      currentWfStage: 0,
      assignedTo: assignedTo,
      jobLocation: payload.jobLocation,
      isIndividual: payload.isIndividual,
      isMaster: payload.repeatingRa.value !== 0,
      schedule:
        payload.repeatingRa.value === 0
          ? 'immediate'
          : payload.repeatingRa.value === 1
          ? 'repeating'
          : 'scan',
      dueBy: payload.repeatingOption ? payload.repeatingOption.value : '',
      dueByTime: payload.recurringTime,
      repeatingOn: Array.isArray(payload.repeatingOn)
        ? payload.repeatingOn
        : [payload.repeatingOn.value],
      frequency: payload.frequency
    };

    const response = yield call(api.createJsa, payload);

    yield put(createJsaResponse(response));
    history.push('/app/raStages');
  } catch (e) {
    console.log(e);
  }
}

export function* updateJsa(api, { payload }) {
  try {
    const answers = payload.answers;
    const assignedTo = payload.assignedTo.map(user => {
      if (user.value) {
        return user.value;
      }

      return user;
    });

    if (answers) {
      payload.sections.forEach(section => {
        let questions = [];
        section.questions.forEach(question => {
          const answer = answers.filter(
            answer => answer.questionId === question._id
          )[0]
            ? answers.filter(answer => answer.questionId === question._id)[0]
                .answer
            : question.answer
            ? question.answer
            : '';
          question.answer = answer;
          questions.push(question);
        });
        section.questions = questions;
        section.sectionNotes = payload.sectionNotes;
      });
    }

    payload.assignedTo = assignedTo;
    payload.dueDate = moment(payload.dueDate);
    payload.industry = payload.industry.label
      ? payload.industry.label
      : payload.industry;

    const response = yield call(api.updateJsa, payload);

    yield put(updateJsaResponse(response));
    yield put(setActiveJsaRequest({ ...response, goToSummary: true }));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteJsa(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteJsa, payload);

    yield put(deleteJsaResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
    history.push('/app/raStages');
  } catch (e) {
    console.log(e);
  }
}

export function* addJsaNote(api, { payload }) {
  try {
    const activeJsa = yield select(getActiveJsaSelector);
    const loggedInUser = yield select(getLoggedInUser);

    let activeNotes = [];
    activeJsa.sections.forEach(section => {
      let questions = [];
      section.questions.forEach(question => {
        if (question._id === payload.questionId) {
          let notes =
            question.actionItems && question.actionItems.notes
              ? question.actionItems.notes
              : [];

          notes = [
            ...notes,
            {
              value: payload.name,
              ownerId: question._id,
              ownerType: 'jsaQuestion',
              createdByUserId: loggedInUser._id
            }
          ];

          const actionItems = question.actionItems
            ? question.actionItems
            : { attachments: [], tasks: [], notes: [], comments: [] };

          question.actionItems = { ...actionItems, notes: notes };
          activeNotes = notes;
        }

        questions.push(question);
      });
      section.questions = questions;
    });

    const response = yield call(api.updateJsa, activeJsa);

    yield put(updateJsaResponse(response));
    yield put(addJsaNotesResponse(activeNotes));
    yield put(setActiveJsaRequest({ ...response, goToSummary: true }));
  } catch (e) {
    console.log(e);
  }
}

export function* addJsaAttachment(api, { payload }) {
  try {
    const activeJsa = yield select(getActiveJsaSelector);
    const attachmentResponse = yield call(api.uploadAttachment, payload.data, {
      ownerId: payload.ownerId,
      ownerType: payload.ownerType
    });

    let activeAttachments = [];
    activeJsa.sections.forEach(section => {
      let questions = [];
      section.questions.forEach(question => {
        if (question._id === payload.ownerId) {
          let attachments =
            question.actionItems && question.actionItems.attachments
              ? question.actionItems.attachments
              : [];

          attachments = [...attachments, ...attachmentResponse];

          const actionItems = question.actionItems
            ? question.actionItems
            : { attachments: [], tasks: [], notes: [], comments: [] };

          question.actionItems = { ...actionItems, attachments: attachments };
          activeAttachments = attachments;
        }

        questions.push(question);
      });
      section.questions = questions;
    });

    const response = yield call(api.updateJsa, activeJsa);

    yield put(updateJsaResponse(response));
    yield put(addJsaAttachmentsResponse(activeAttachments));
    yield put(setActiveJsaRequest({ ...response, goToSummary: true }));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteJsaQuestionAttachment(api, { payload }) {
  try {
    const activeJsa = yield select(getActiveJsaSelector);

    yield call(api.deleteAttachment, payload._id);

    let activeAttachments = [];
    activeJsa.sections.forEach(section => {
      let questions = [];
      section.questions.forEach(question => {
        if (question._id === payload.ownerId) {
          let attachments = question.actionItems.attachments;

          const index = attachments.findIndex(
            attachment => attachment._id === payload._id
          );

          attachments = [
            ...attachments.slice(0, index),
            ...attachments.slice(index + 1)
          ];

          const actionItems = question.actionItems;

          question.actionItems = { ...actionItems, attachments: attachments };
          activeAttachments = attachments;
        }

        questions.push(question);
      });
      section.questions = questions;
    });

    const response = yield call(api.updateJsa, activeJsa);

    yield put(updateJsaResponse(response));
    yield put(addJsaAttachmentsResponse(activeAttachments));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCustomRaTemplates(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    const response = yield call(api.fetchRaCustomTemplates, activeCompany._id);

    yield put(fetchCustomTemplatesResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* createCustomRaTemplate(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload = {
      ...payload,
      questions: payload.questions.map((question, index) => {
        return {
          question,
          industry: 'Custom',
          sections: payload.title,
          sortOrder: index + 1
        };
      }),
      companyId: activeCompany._id
    };

    delete payload.openDeleteTemplateModal;
    delete payload.openUnsavedChangesModal;
    delete payload.openUnsavedTemplateModal;

    const response = yield call(api.createRaCustomTemplate, payload);
    yield put(createCustomTemplateResponse(response));
    yield put(setActiveRaCustomTemplate({}));
    history.push('/app/customRaList');
  } catch (e) {
    console.log(e);
  }
}

export function* updateCustomRaTemplate(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload = {
      ...payload,
      questions: payload.questions.map((question, index) => {
        return {
          question,
          industry: 'Custom',
          sections: payload.title,
          sortOrder: index + 1
        };
      }),
      companyId: activeCompany._id
    };

    delete payload.openDeleteTemplateModal;
    delete payload.openUnsavedChangesModal;
    delete payload.openUnsavedTemplateModal;

    const response = yield call(api.updateRaCustomTemplate, payload);
    yield put(updateCustomTemplateResponse(response));
    history.push('/app/customRaList');
  } catch (e) {
    console.log(e);
  }
}

export function* deleteCustomRaTemplate(api, { payload }) {
  try {
    yield call(api.deleteRaCustomTemplate, payload);

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* fetchJsaById(api, { payload }) {
  try {
    const response = yield call(api.fetchJsaById, payload);
    yield put(setActiveJsaResponse(api, { ...response }));
  } catch (e) {
    console.log(e);
  }
}
