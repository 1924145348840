import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import './index.css';

export default class ProfilePicture extends Component {
  state = {
    percent: 0,
    imageFiles: []
  };

  onDrop = files => {
    this.setState({
      percent: 0,
      imageFiles: [...this.state.imageFiles, ...files]
    });

    let data = new FormData();
    files.forEach(file => {
      data.append('attachments', file, file.name);
    });

    this.props.addAttachment({
      profileFormData: data
    });
  };

  render() {
    return (
      <div>
        <label className="label">{this.props.label}</label>
        <div className="dropzone">
          <Dropzone
            onDrop={this.onDrop}
            className="dropzone-box"
            accept={['image/png', 'image/jpeg', 'image/jpg']}
          >
            <div>Select an image to upload as your profile picture!</div>
          </Dropzone>
          {this.state.imageFiles.length === 1 ? (
            <div>
              <h4>Image Preview:</h4>
              <div>
                {this.state.imageFiles.map((file, index) => {
                  return <img src={file.preview} key={index} alt="images" />;
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
