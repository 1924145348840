import {
  FETCH_CUSTOM_TRAINING_TEMPLATES_REQUEST,
  FETCH_CUSTOM_TRAINING_TEMPLATES_RESPONSE,
  ADD_CUSTOM_TRAINING_TEMPLATE_REQUEST,
  UPDATE_CUSTOM_TRAINING_TEMPLATE_REQUEST,
  DELETE_CUSTOM_TRAINING_TEMPLATE_REQUEST,
  SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE,
  FETCH_TRAININGS_REQUEST,
  FETCH_TRAININGS_RESPONSE,
  ADD_TRAINING_REQUEST,
  UPDATE_TRAINING_REQUEST,
  DELETE_TRAINING_REQUEST,
  SET_ACTIVE_TRAINING_REQUEST,
  SET_ACTIVE_TRAINING_RESPONSE,
  SET_ACTIVE_TRAINING_ATTACHMENT
} from '../constants/actionTypes';

export const fetchCustomTrainingTemplatesRequest = payload => ({
  type: FETCH_CUSTOM_TRAINING_TEMPLATES_REQUEST,
  payload
});

export const fetchCustomTrainingTemplatesResponse = payload => ({
  type: FETCH_CUSTOM_TRAINING_TEMPLATES_RESPONSE,
  payload
});

export const addCustomTrainingTemplateRequest = payload => ({
  type: ADD_CUSTOM_TRAINING_TEMPLATE_REQUEST,
  payload
});

export const updateCustomTrainingTemplateRequest = payload => ({
  type: UPDATE_CUSTOM_TRAINING_TEMPLATE_REQUEST,
  payload
});

export const deleteCustomTrainingTemplateRequest = payload => ({
  type: DELETE_CUSTOM_TRAINING_TEMPLATE_REQUEST,
  payload
});

export const setActiveCustomTrainingTemplate = payload => ({
  type: SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE,
  payload
});

export const fetchTrainingsRequest = payload => ({
  type: FETCH_TRAININGS_REQUEST,
  payload
});

export const fetchTrainingsResponse = payload => ({
  type: FETCH_TRAININGS_RESPONSE,
  payload
});

export const addTrainingRequest = payload => ({
  type: ADD_TRAINING_REQUEST,
  payload
});

export const updateTrainingRequest = payload => ({
  type: UPDATE_TRAINING_REQUEST,
  payload
});

export const deleteTrainingRequest = payload => ({
  type: DELETE_TRAINING_REQUEST,
  payload
});

export const setActiveTrainingRequest = payload => ({
  type: SET_ACTIVE_TRAINING_REQUEST,
  payload
});

export const setActiveTrainingResponse = payload => ({
  type: SET_ACTIVE_TRAINING_RESPONSE,
  payload
});

export const setActiveTrainingAttachment = payload => ({
  type: SET_ACTIVE_TRAINING_ATTACHMENT,
  payload
});
