import React from 'react';

import Button from '../inputs/Button';
import Textbox from '../inputs/Textbox';
import { classnames } from '../../utils/classNamesHelper';

import styles from './styles.module.scss';

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      currentName: this.props.section
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.section !== this.props.section) {
      this.setState({ currentName: this.props.section });
    }
  }

  render() {
    let sectionJSX = <></>;
    let rightButtons = this.props.rightButtons || [];
    if (!Array.isArray(rightButtons)) rightButtons = [rightButtons];
    const buttonJSX = (
      <div className={styles.buttons}>
        {this.props.right || <></>}
        {rightButtons.map((b, i) => {
          if ('visible' in b && !b.visible) return <React.Fragment key={i}></React.Fragment>;
          const className = b.className;
          delete b.visible;
          delete b.className;
          return (
            <Button
              key={i}
              className={[className, styles.headerButton]}
              {...b}
            />
          );
        })}
      </div>
    );

    if (this.props.section) {
      if (this.state.editing) {
        sectionJSX = (
          <div className={styles.section}>
            <Textbox
              placeholder={this.props.section}
              className={styles.input}
              currentValue={this.state.currentName}
              handleChange={e => this.setState({ currentName: e.target.value })}
              onBlur={() => this.setState({ editing: false })}
              escape={() => this.setState({ editing: false })}
              enter={() => {
                this.props.editName(this.state.currentName);
                this.setState({ editing: false });
              }}
              autoFocus
            />
          </div>
        );
      } else {
        sectionJSX = (
          <div className={styles.section}>
            <div className={styles.sectionName} title={this.props.section}>
              {this.props.section}
            </div>
            {this.props.editName && (
              <div
                className={styles.edit}
                onClick={() => this.setState({ editing: true })}
              >
                <img
                  src={require('../../assets/images/editName.png')}
                  alt="Edit Name"
                />
              </div>
            )}
          </div>
        );
      }
    }
    return (
      <header
        className={classnames(
          styles.header,
          !this.props.center && styles.noCenter
        )}
      >
        <div className={styles.headerInfo}>
          {this.props.clickBack && (
            <div className={styles.backButton} onClick={this.props.clickBack}>
              <img
                alt=""
                className={this.props.needsSaved ? styles.unsaved : ''}
              />
            </div>
          )}
          <div className={styles.title}>
            <div>{this.props.title}</div>
            {sectionJSX}
          </div>
        </div>
        {this.props.center && (
          <div className={styles.center}>{this.props.center}</div>
        )}
        {buttonJSX}
      </header>
    );
  }
}
export default Header;
