import { all, call, put, select } from 'redux-saga/effects';
import history from '../history';
import {
  fetchCompanyReportsResponse,
  fetchLocationReportsResponse,
  fetchProjectReportsResponse,
  fetchReportCirclesResponse,
  setActiveCustomReportQuestions
} from '../actions/reports';
import {
  fetchIncidentAttachmentsResponse,
  fetchIncidentBasicsResponse,
  setActiveIncidentResponse
} from '../actions/incidents';
import { fetchOshaFormResponse } from '../actions/oshaLogs';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getIncidentsSelector } from '../selectors/incidents';
import { getActiveCustomReportQuestionsSelector } from '../selectors/reports';
import { circleDataMapper } from '../utils/circleDataMapper';
import { oshaQuestionMapper } from '../utils/oshaDbMapper';
import reportTemplateMapper from '../utils/reportTemplateMapper';

export function* fetchCompanyReports(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);

    let response = [];
    response.docs = [];

    if (payload.templateId === 3 || payload.templateId === '3') {
      const genLiab = [3, 4, 5];

      const reports = yield all(
        genLiab.map(templateId =>
          call(
            api.fetchReportsByTemplateIdAndCompanyId,
            company._id,
            templateId,
            payload.page,
            payload.limit
          )
        )
      );

      reports.forEach(report =>
        report.docs.map(typeOfReport => response.docs.push(typeOfReport))
      );
    } else {
      response = yield call(
        api.fetchReportsByTemplateIdAndCompanyId,
        company._id,
        payload.templateId,
        payload.page,
        payload.limit
      );
    }

    const circleResponse = yield call(api.fetchCompanyCircleData, {
      companyId: company._id,
      reportType: payload.templateId
    });

    const circles = circleDataMapper(
      circleResponse,
      reportTemplateMapper(payload.templateId).circleText
    );

    yield put(fetchCompanyReportsResponse(response.docs));
    yield put(fetchReportCirclesResponse(circles));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchLocationReports(api, { payload }) {
  try {
    const locationId = yield select(getActiveLocationId);
    const company = yield select(getActiveCompany);

    let response = [];
    response.docs = [];

    if (payload.templateId === 3 || payload.templateId === '3') {
      const genLiab = [3, 4, 5];

      const reports = yield all(
        genLiab.map(templateId =>
          call(
            api.fetchReportsByTemplateIdAndLocationId,
            company._id,
            templateId,
            locationId._id,
            payload.page,
            payload.limit
          )
        )
      );

      reports.forEach(report =>
        report.docs.map(typeOfReport => response.docs.push(typeOfReport))
      );
    } else {
      response = yield call(
        api.fetchReportsByTemplateIdAndLocationId,
        company._id,
        payload.templateId,
        locationId._id,
        payload.page,
        payload.limit
      );
    }
    const circleResponse = yield call(api.fetchLocationCircleData, {
      companyId: company._id,
      locationId: locationId._id,
      reportType: payload.templateId
    });

    const circles = circleDataMapper(
      circleResponse,
      reportTemplateMapper(payload.templateId).circleText
    );

    yield put(fetchLocationReportsResponse(response.docs));
    yield put(fetchReportCirclesResponse(circles));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchProjectReports(api, { payload }) {
  try {
    const project = yield select(getActiveProject);
    const company = yield select(getActiveCompany);

    let response = [];
    response.docs = [];

    if (payload.templateId === 3 || payload.templateId === '3') {
      const genLiab = [3, 4, 5];

      const reports = yield all(
        genLiab.map(templateId =>
          call(
            api.fetchReportsByTemplateIdAndProjectId,
            company._id,
            templateId,
            project._id,
            payload.page,
            payload.limit
          )
        )
      );

      reports.forEach(report =>
        report.docs.map(typeOfReport => response.docs.push(typeOfReport))
      );
    } else {
      response = yield call(
        api.fetchReportsByTemplateIdAndProjectId,
        company._id,
        payload.templateId,
        project._id,
        payload.page,
        payload.limit
      );
    }

    const circleResponse = yield call(api.fetchProjectCircleData, {
      companyId: company._id,
      projectId: project._id,
      reportType: payload.templateId
    });

    const circles = circleDataMapper(
      circleResponse,
      reportTemplateMapper(payload.templateId).circleText
    );

    yield put(fetchProjectReportsResponse(response.docs));
    yield put(fetchReportCirclesResponse(circles));
  } catch (e) {
    console.log(e);
  }
}

export function* setActiveReport(api, { payload }) {
  try {
    const incidents = yield select(getIncidentsSelector);

    let activeIncident = incidents.find(
      incident => incident._id === payload.incidentId
    );

    if (!activeIncident) {
      const company = yield select(getActiveCompany);
      activeIncident = yield call(
        api.fetchIncidentById,
        company._id,
        payload.incidentId
      );
    }

    const froi = activeIncident.reportTemplateIds.indexOf('2') > -1;

    if (froi) {
      const oshaFormPayload = {
        incidentId: activeIncident._id,
        companyId: activeIncident.companyId
      };

      let oshaForm = yield call(api.fetchOshaForm, oshaFormPayload);

      if (oshaForm.length > 0) {
        oshaForm[0].formAnswers = oshaQuestionMapper(oshaForm[0].questions);
      }

      yield put(fetchOshaFormResponse(oshaForm));
    }

    const response = { sections: [] };

    const incidentBasics = activeIncident.incidentBasicsFieldGroup;

    const reportBasics = activeIncident.reportBasicsFieldGroups;

    response.sections = [incidentBasics, ...reportBasics];

    yield put(setActiveIncidentResponse({ ...activeIncident }));
    yield put(fetchIncidentBasicsResponse(response));
    yield put(fetchIncidentAttachmentsResponse(activeIncident.attachments || []));
    history.push('/app/incidentContainer');
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCustomReportQuestions(api) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeReport = yield select(getActiveCustomReportQuestionsSelector);

    const response = yield call(api.fetchCustomReportQuestions, {
      companyId: activeCompany._id,
      reportTemplateNumber: activeReport.reportTemplateNumber,
      reportComponentName: activeReport.reportComponentName
    });

    if (response.length > 0) {
      yield put(setActiveCustomReportQuestions(response[0]));
    }
    history.push('/app/customReportQuestionContainer');
  } catch (error) {
    console.log(error);
  }
}

export function* createCustomReportQuestions(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload.fields.forEach((field, index) => {
      field.subSectionOrder = 1000 + index;
      field.type = field.type.value;
      field.required = field.required.length === 1;
      field.showNotApplicable = field.showNotApplicable.length === 1;
      field.options = field.options.map(option => option.option);
    });

    payload = {
      ...payload,
      label: 'Custom Questions',
      companyId: activeCompany._id
    };

    yield call(api.createCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* updateCustomReportQuestions(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload.fields.forEach((field, index) => {
      field.subSectionOrder = 1000 + index;
      field.type = field.type.value ? field.type.value : field.type;

      if (field.options.length > 0 && field.options[0].option) {
        field.options = field.options.map(option => option.option);
      }
    });

    payload = {
      ...payload,
      label: 'Custom Questions',
      companyId: activeCompany._id
    };

    yield call(api.updateCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* deleteCustomReportQuestions(api, { payload }) {
  try {
    yield call(api.deleteCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}
