import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  dropDownRequired,
  renderDropdownField,
  renderNumberField,
  required
} from '../../utils/formValidation';

import '../form.css';

const optionList = [
  { label: 'Under 20', value: 0 },
  { label: '20 - 49', value: 1 },
  { label: '50 - 99', value: 2 },
  { label: '100 - 249', value: 3 },
  { label: '250 - 499', value: 4 },
  { label: '500 - 999', value: 5 },
  { label: '1000+', value: 6 }
];

const OshaEmploymentForm = props => {
  const { handleSubmit } = props;

  return (
    <form className="oshaEmploymentForm" onSubmit={handleSubmit}>
      <div className="form-fields">
        <Field
          name="annualAverageNumberOfWorkers"
          component={renderNumberField}
          label="Annual average number of workers."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
          displayToolTip={true}
        >
          <p>
            Total number of worker the establishment paid that year (include
            all, not just full-time).
          </p>
          <p>Divided By:</p>
          <p>The number of pay periods the establishment has that year.</p>
          <p>Then round to the nearest whole number.</p>
        </Field>
        <Field
          name="totalHoursWorkedByAllEmployees"
          component={renderNumberField}
          label="Total hours worked by all workers last year."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
          displayToolTip={true}
        >
          <p>
            Total the number of hours worked by all workers at the
            establishment.
          </p>
          <p>
            {' '}
            If this isn’t available, then estimate by doing the following:{' '}
          </p>
          <p>
            {' '}
            Multiply number of full-time employees by the number of work hours
            for a full-time employee in a year.{' '}
          </p>
          <p>
            Then add any overtime hours and hours worked by other workers
            (Part-time, Temp, Seasonal).
          </p>
        </Field>
        <Field
          label="Maximum number of workers at any given time last year."
          name="maximumNumberOfWorkersEmployed"
          component={renderDropdownField}
          placeholder="Select an option"
          options={optionList}
          validate={[dropDownRequired]}
          className="form-select"
          displayToolTip={true}
          requiredLabel={true}
        >
          <p>NOT the total number of workers at the establishment that year.</p>
          <p>
            {' '}
            This is the highest number of workers working at the establishment
            at one time.
          </p>
        </Field>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'OshaEmploymentForm'
})(OshaEmploymentForm);
