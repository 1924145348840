import { connect } from 'react-redux';
import React, { Component } from 'react';

import { formValueSelector, isValid, submit } from 'redux-form';
import history from '../../history';

import {
  addCompanyLocationRequest,
  deleteCompanyLocationRequest,
  setActiveLocation,
  updateCompanyLocationRequest
} from '../../actions/company';
import { getActiveCompany } from '../../selectors/company';
import { addLocationEmployeeRequest } from '../../actions/personnel';
import { updateLeftNavRequest } from '../../actions/navigation';
import { getLoggedInUser } from '../../selectors/users';

import Modal from '../Modal';
import NewLocationForm from '../../forms/NewLocationForm';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import ViewEditList from '../ViewEditList';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';

export class LocationList extends Component {
  state = {
    modalOpen: false,
    row: undefined
  };

  handleOpenModal(row) {
    this.setState({
      modalOpen: true,
      row
    });
  }

  handleCloseModal() {
    this.setState({
      modalOpen: false,
      row: undefined
    });
  }

  componentDidMount() {
    this.props.updateLeftNav('Locations');
  }

  handleAddGroupSubmit = values => {
    this.props.addCompanyLocation(values);
    this.handleCloseModal();
  };

  handleUpdateGroupSubmit = values => {
    const {
      isSeparateEstablishment,
      operationalForYearOrLonger,
      name
    } = this.state.row;

    if (name !== values.name) {
    }

    if (
      (isSeparateEstablishment !== values.isSeparateEstablishment &&
        values.isSeparateEstablishment &&
        values.operationalForYearOrLonger) ||
      (isSeparateEstablishment === values.isSeparateEstablishment &&
        operationalForYearOrLonger !== values.operationalForYearOrLonger)
    ) {
    }
    this.props.updateCompanyLocation(values);
    this.handleCloseModal();
  };

  deleteLocation = id => {
    this.props.deleteCompanyLocation({ id });

    this.handleCloseModal();
  };

  render() {
    const {
      setActiveLocation,
      establishmentValid
    } = this.props;

    const groups = this.props.activeCompany.groups;

    const { modalOpen, row } = this.state;

    const header = (
      <Header
        title="Groups or Establishments"
        rightButtons={{
          text: 'New Group or Establishment',
          color: 'green',
          onClick: () => this.handleOpenModal(undefined)
        }}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header}>
          <ScrollToTopOnMount />
          <ViewEditList
            data={groups}
            onView={id => {
              let group = groups.find(g => g._id === id);
              setActiveLocation(group);
              history.push('/app/dashboard');
            }}
            onEdit={id => {
              let group = groups.find(g => g._id === id);
              this.handleOpenModal(group);
            }}
            onDelete={this.deleteLocation}
          />
        </HeaderAndFooter>
        <Modal
          title={`${row ? 'Edit' : 'New'} Group or Establishment`}
          titleClassName={row ? 'blueHeader' : 'greenHeader'}
          isOpen={modalOpen}
          submitButtonColor={row ? 'blue' : 'green'}
          onRequestClose={() => this.handleCloseModal()}
          submitActions={this.props.submitEstablishment}
          submitButtonText={row ? 'Update' : 'Create'}
          disableSubmit={!establishmentValid}
        >
          <NewLocationForm
            initialValues={row}
            onSubmit={values =>
              row
                ? this.handleUpdateGroupSubmit({
                    ...values,
                    id: row?._id
                  })
                : this.handleAddGroupSubmit({
                    ...values,
                    fromLocationScreen: true
                  })
            }
          />
        </Modal>
      </>
    );
  }
}

const selector = formValueSelector('NewLocationForm');

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  establishmentValid: isValid('NewLocationForm')(state),
  operationalYearOrLonger: selector(state, 'operationalForYearOrLonger'),
  loggedInUser: getLoggedInUser(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  addCompanyLocation: values => dispatch(addCompanyLocationRequest(values)),
  updateCompanyLocation: values =>
    dispatch(updateCompanyLocationRequest(values)),
  deleteCompanyLocation: values =>
    dispatch(deleteCompanyLocationRequest(values)),
  addLocationEmployee: values => dispatch(addLocationEmployeeRequest(values)),
  setActiveLocation: locationId => dispatch(setActiveLocation(locationId)),
  submitEstablishment: () => dispatch(submit('NewLocationForm'))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationList);
