import React, { Component } from 'react';
import { Tabbordion, TabContent, TabLabel, TabPanel } from 'react-tabbordion';
import moment from 'moment';
import Button from '../inputs/Button';
import ToolboxTalkForm from '../../forms/ToolboxTalkForm';
import TaskList from '../TaskList';

import '../Tabs/index.css';

class ViewEditToolboxTalk extends Component {
  handleModalOpen() {
    this.setState({ modalOpen: true });
  }

  handleModalClose() {
    this.setState({ modalOpen: false });
  }

  render() {
    const blockElements = {
      content: 'traditional-tabs-content',
      panel: 'traditional-tabs-panel',
      label: 'traditional-tabs-title'
    };

    const tabs = ['Toolbox Talk Information', 'Toolbox Talk Tasks'];

    const taskTabs = [
      'Not Started',
      'In Progress',
      'Waiting for Feedback',
      'Stuck',
      'Complete'
    ];

    const taskHeader = [
      'Title',
      'Priority',
      'Severity',
      'Updated Date',
      'Status',
      ''
    ];

    const {
      disabled,
      onCancel,
      onDelete,
      activeToolboxTalk,
      activeCompanyId,
      addAttachment,
      activeAttachments,
      deleteTTAttachment,
      loading,
      locations,
      handleSubmit,
      setActiveTask,
      deleteAttachment,
      addedAttachments,
      activeLocation,
      activeProject,
      documentFolders,
      allEmployeesList,
      activeEmployees,
      accessLevel,
      activeGroups
    } = this.props;

    return (
      <div>
        <Tabbordion
          blockElements={blockElements}
          className="traditional-tabs"
          name="tabs"
        >
          <TabPanel>
            <TabLabel>{`${tabs[0]}`}</TabLabel>
            <TabContent>
              <h2 className="viewEdit-header">
                {`View/Edit Toolbox Talk`}
                <div className="viewEdit-buttonBox">
                  {disabled ? (
                    <Button text={'Edit'} onClick={() => onCancel(false)} />
                  ) : (
                    <div />
                  )}
                </div>
              </h2>
              <ToolboxTalkForm
                onSubmit={values => handleSubmit(values)}
                allEmployeeList={allEmployeesList}
                activeEmployees={activeEmployees}
                initialValues={{
                  ...activeToolboxTalk,
                  locationId: activeToolboxTalk.groupIds,
                  projectId: activeToolboxTalk.projectIds,
                  expires: moment(activeToolboxTalk.expires).format(
                    'MM/DD/YYYY'
                  )
                }}
                disabled={disabled}
                onCancel={onCancel}
                onDelete={onDelete}
                isUpdate={true}
                activeCompanyId={activeCompanyId}
                addAttachment={addAttachment}
                activeAttachments={activeAttachments}
                deleteTTAttachment={deleteTTAttachment}
                loading={loading}
                locations={locations}
                activeLocation={activeLocation}
                activeProject={activeProject}
                documentFolders={documentFolders}
                // eslint-disable-next-line
                hasAccess={accessLevel != '100'}
                addedAttachments={addedAttachments}
                deleteAttachment={deleteAttachment}
              />
            </TabContent>
          </TabPanel>
          <TabPanel>
            <TabLabel>{`${tabs[1]}`}</TabLabel>
            <TabContent className="viewEdit-tasks">
              <h2 className="viewEdit-header">
                Toolbox Talk Tasks
                <div className="viewEdit-buttonBox">
                  <Button
                    text="Add Task"
                    className="add"
                    onClick={() =>
                      setActiveTask({
                        ownerId: activeToolboxTalk._id,
                        ownerType: 'ToolboxTalk'
                      })
                    }
                  />
                </div>
              </h2>
              <TaskList
                taskHeader={taskHeader}
                content={activeToolboxTalk.tasks ? activeToolboxTalk.tasks : []}
                tabs={taskTabs}
                onClick={setActiveTask}
                activeGroups={activeGroups}
              />
            </TabContent>
          </TabPanel>
        </Tabbordion>
      </div>
    );
  }
}

export default ViewEditToolboxTalk;
