import React, { Component } from 'react';
import moment from 'moment';

import { CENSORED_TAGS, DEFAULT_TAGS } from '../../../constants/constants';
import { InputRow } from '../../inputs';
import Button from '../../inputs/Button';
import CheckboxGroup from '../../inputs/CheckboxGroup';
import UserBadge from '../../UserBadge';
import Textbox from '../../inputs/Textbox';
import HoverImage from '../../HoverImage';

import './index.css';

export default class PhotoEvidence extends Component {
  state = {
    shouldExpandPhoto: false,
    shouldExpandTags: false,
    photoName: '',
    isNoOneInPhoto: '',
    peopleInPhoto: '',
    description: '',
    tags: [],
    shouldNotRevealImage: true,
    isRemoved: false,
    isRemovedByOwner: false
  };

  componentDidMount() {
    const { photo } = this.props;

    const peopleInPhoto = photo.namesOfPeopleInPhoto
      .map(person => `${person.firstName} ${person.lastName}`)
      .join(', ');
    this.setState({ ...photo, peopleInPhoto });

    if (!this.isCensoredData(photo.tags))
      this.setState({ shouldNotRevealImage: false });

    if (photo.description === 'Unknown') this.setState({ description: '' });
  }

  componentDidUpdate(prevProps) {
    const { photo } = this.props;

    if (photo && JSON.stringify(photo) !== JSON.stringify(prevProps.photo)) {
      const peopleInPhoto = photo.namesOfPeopleInPhoto
        .map(person =>
          person.lastName
            ? `${person.firstName} ${person.lastName}`
            : `${person.firstName}`
        )
        .join(', ');
      this.setState({ ...photo, peopleInPhoto });

      if (!this.isCensoredData(photo.tags))
        this.setState({ shouldNotRevealImage: false });
    }
  }

  handlePhotoEvidenceUpdate = async updatedObject => {
    if (updatedObject.hasOwnProperty('peopleInPhoto')) {
      let ppl = updatedObject.peopleInPhoto;

      if (!ppl) {
        await this.setState({ updatedObject });
        this.props.updatePhotoEvidence({
          ...this.setState,
          namesOfPeopleInPhoto: []
        });
        return;
      }

      ppl = ppl.split(', ');
      const namesOfPeopleInPhoto = [];
      ppl.forEach(person => {
        const name = person.split(' ');
        namesOfPeopleInPhoto.push({ firstName: name[0], lastName: name[1] });
      });

      await this.setState(updatedObject);
      this.props.updatePhotoEvidence({ ...this.state, namesOfPeopleInPhoto });
    } else {
      await this.setState(updatedObject);
      this.props.updatePhotoEvidence({ ...this.state, updatedObject });
    }
  };

  handlePhotoTagUpdate = async tag => {
    let tagList = this.state.tags;
    const tagIndex = tagList.map(photoTag => photoTag.label).indexOf(tag.label);

    if (tagIndex > -1) {
      tagList = [...tagList.slice(0, tagIndex), ...tagList.slice(tagIndex + 1)];
      this.setState({
        tags: tagList
      });
      this.props.updatePhotoEvidence({ ...this.state });
    } else {
      tagList = [...tagList, tag];
      this.setState({ tags: tagList });
      this.props.updatePhotoEvidence({
        ...this.state,
        tags: tagList
      });
    }
  };

  isCensoredData = tags => {
    const censoredTags = tags.filter(tag => tag.type === 'Censored');

    return censoredTags.length > 0;
  };

  render() {
    const {
      name,
      isIncidentOwner,
      canRemoveEdit,
      photo
    } = this.props;

    const {
      shouldExpandPhoto,
      shouldExpandTags,
      photoName,
      isNoOneInPhoto,
      peopleInPhoto,
      description,
      tags,
      source_url,
      createdAt,
      shouldNotRevealImage,
      isRemoved
    } = this.state;

    return (
      <div className="photoEvidenceCard" id={name}>
        <div
          className={
            shouldExpandPhoto
              ? 'photoEvidenceCard-photoBig'
              : 'photoEvidenceCard-photoSmall'
          }
        >
          <div className="photoEvidenceCard-left">
            <UserBadge
              className="photoEvidenceCard-uploadedAt"
              showImage
              userId={photo.createdByUserId}
              showName
              byline="Uploaded By"
            />
            {this.isCensoredData(tags) && (
              <div className="photoEvidenceCard-censored">
                Censored due to red tag(s)
              </div>
            )}
          </div>
          <div className="photoEvidenceCard-middle">
            <img
              className={
                shouldNotRevealImage && this.isCensoredData(tags)
                  ? 'photoImageCensored'
                  : 'photoImage'
              }
              src={source_url}
              alt={photoName}
            />
            <div
              className={
                shouldNotRevealImage ? 'allButtonsCensored' : 'allButtons'
              }
            >
              <InputRow center>
                <Button
                  type="button"
                  text="Download"
                  color="white"
                  onClick={() => window.open(source_url, '_blank')}
                />
                {canRemoveEdit && !isRemoved && (
                  <Button
                    type="button"
                    text="Remove"
                    color="red"
                    onClick={() =>
                      this.handlePhotoEvidenceUpdate({
                        isRemoved: true,
                        isRemovedByOwner: isIncidentOwner
                      })
                    }
                  />
                )}
                {canRemoveEdit && isRemoved && (
                  <Button
                    type="button"
                    text="Re-Add?"
                    color="blue"
                    onClick={() =>
                      this.handlePhotoEvidenceUpdate({
                        isRemoved: false,
                        isRemovedByOwner: false
                      })
                    }
                  />
                )}
              </InputRow>
              {shouldNotRevealImage && (
                <Button
                  type="button"
                  text="Reveal Image"
                  color="blue"
                  onClick={() =>
                    this.setState({ shouldNotRevealImage: false })
                  }
                />
              )}
            </div>
          </div>
          <div className="photoEvidenceCard-right">
            <HoverImage
              className="photoEvidenceCard-expandDespanButton"
              onClick={() =>
                this.setState({ shouldExpandPhoto: !shouldExpandPhoto })
              }
              src={require('../../../assets/images/collapseButton.png')}
              srcHover={require('../../../assets/images/hoverCollapseButton.png')}
              srcSelected={require('../../../assets/images/expandButton.png')}
              srcHoverSelected={require('../../../assets/images/hoverExpandButton.png')}
              alt="expand"
              selected={!shouldExpandPhoto}
            />
            <div className="photoEvidenceCard-uploadedAt photoEvidenceCard-time">
              <span className="uploadedAt-time">
                Uploaded At {moment(Date.parse(createdAt)).format('hh:mm a')}
              </span>
              <span className="uploadedAt-date">
                {moment(Date.parse(createdAt)).format('LL')}
              </span>
            </div>
          </div>
        </div>
        <div className="photoEvidenceCard-photoInfo">
          {shouldExpandTags ? (
            <div className="photoEvidenceCard-tagsExpanded">
              <HoverImage
                className="closeButton"
                onClick={() => this.setState({ shouldExpandTags: false })}
                src={require('../../../assets/images/closeButton.png')}
                srcHover={require('../../../assets/images/closeButtonHover.png')}
                alt="close tags"
              />
              <div className="photoEvidence-tagsExpanded--tags">
                <div className="photoEvidence-tagsExpanded--censoredTags">
                  {CENSORED_TAGS.map((tag, index) => (
                    <div
                      onClick={() =>
                        this.handlePhotoTagUpdate({
                          type: 'Censored',
                          label: tag
                        })
                      }
                      className={
                        tags.map(photoTag => photoTag.label).indexOf(tag) > -1
                          ? 'photoEvidence-tags censoredSelected'
                          : 'photoEvidence-tags'
                      }
                      key={index}
                    >
                      <div>{tag}</div>
                    </div>
                  ))}
                </div>
                <div className="photoEvidence-tagsExpanded--defaultTags">
                  {DEFAULT_TAGS.map((tag, index) => (
                    <div
                      className={
                        tags.map(photoTag => photoTag.label).indexOf(tag) > -1
                          ? 'photoEvidence-tags defaultSelected'
                          : 'photoEvidence-tags'
                      }
                      key={index}
                      onClick={() =>
                        this.handlePhotoTagUpdate({
                          type: 'Default',
                          label: tag
                        })
                      }
                    >
                      <div>{tag}</div>
                    </div>
                  ))}
                </div>
                {/* <div className="photoEvidence-tagsExpanded--customTags">
                  <div className="customTags-header">Custom:</div>
                  <div className="photoEvidence-tags customTag-addTag">
                    <div>
                      <img
                        src={require('../../../assets/images/addTag.png')}
                        alt="add custom tag"
                      />
                      Add Tag
                    </div>
                  </div>
                </div>
                <div className="photoEvidence-customTags--disclaimer">
                  *Editing or Deleting Custom Tags affects all Photo Evidence.
                </div> */}
              </div>
            </div>
          ) : (
            <div className="photoEvidenceCard-tagsCollapsed">
              {canRemoveEdit && (
                <HoverImage
                  className="gear"
                  onClick={() => this.setState({ shouldExpandTags: true })}
                  src={require('../../../assets/images/tagGear.png')}
                  srcHover={require('../../../assets/images/tagGearHover.png')}
                  alt="open tags"
                />
              )}
              <div className="photoEvidence-tagsCollapsed--tags">
                <div className="photoEvidence-tagsCollapsed--noTags">
                  {tags.length > 0 ? (
                    tags.map((tag, index) => (
                      <div
                        className={
                          tag.type === 'Default'
                            ? 'photoEvidence-tags defaultSelected'
                            : 'photoEvidence-tags censoredSelected'
                        }
                        key={index}
                      >
                        <div>{tag.label}</div>
                      </div>
                    ))
                  ) : (
                    <div className="photoEvidence-tagsCollapsed--tags">
                      <div className="photoEvidence-tagsCollapsed--noTagsWord">
                        No Tags
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <Textbox
            fieldLabel="Photo Name:"
            handleChange={e =>
              this.handlePhotoEvidenceUpdate({ photoName: e.target.value })
            }
            placeholder="Use a descriptive name."
            currentValue={photoName}
            disabled={!canRemoveEdit}
          />
          <CheckboxGroup
            fieldLabel="Who is in this photo?"
            options={[{ value: 1, label: 'No one is in this photo.' }]}
            handleChange={values =>
              this.handlePhotoEvidenceUpdate({ isNoOneInPhoto: values })
            }
            currentValue={isNoOneInPhoto}
            disabled={!canRemoveEdit}
          />
          <Textbox
            handleChange={e =>
              this.handlePhotoEvidenceUpdate({ peopleInPhoto: e.target.value })
            }
            placeholder="Try to include everyone who appears (eg John Doe), comma seperated"
            currentValue={peopleInPhoto}
            disabled={!canRemoveEdit}
          />
          <Textbox
            fieldLabel="Description:"
            handleChange={e =>
              this.handlePhotoEvidenceUpdate({ description: e.target.value })
            }
            type="textarea"
            rows={5}
            placeholder="Be sure to note important details and context."
            currentValue={description}
            disabled={!canRemoveEdit}
          />
        </div>
      </div>
    );
  }
}
