import { createSelector } from 'reselect';

const paymentSelector = state => state.payments;

export const getAccountInfo = createSelector(
  paymentSelector,
  customer => customer && customer.customerInfo
);

export const getPaymentLoadingSelector = createSelector(
  paymentSelector,
  payment => payment && payment.loading
);
