import { createSelector } from 'reselect';

const attachmentSelector = state => state.attachments;

export const getAddedAttachmentsSelector = createSelector(
  attachmentSelector,
  attachments => attachments && attachments.list
);

export const getAttachmentLoadingSelector = createSelector(
  attachmentSelector,
  attachments => attachments && attachments.loading
);
