import { createSelector } from 'reselect';

const jsaSelector = state => state.jsa;

export const getJsasSelector = createSelector(
  jsaSelector,
  list => list && list.list
);

export const getJsaPaginationSelector = createSelector(
  jsaSelector,
  jsa => jsa && jsa.paginationInfo
);

export const getNotClosedJsasSelector = createSelector(
  getJsasSelector,
  list => list && list.filter(jsa => jsa.currentWfStage < 3)
);

export const getJsaSectionsSelector = createSelector(
  jsaSelector,
  jsaSections => jsaSections && jsaSections.sections
);

export const getActiveJsaSelector = createSelector(
  jsaSelector,
  activeJsa => activeJsa && activeJsa.activeJsa
);

export const getActiveJsaNotesSelector = createSelector(
  jsaSelector,
  activeJsa => activeJsa && activeJsa.notes
);

export const getActiveJsaAttachmentsSelector = createSelector(
  jsaSelector,
  activeJsa => activeJsa && activeJsa.attachments
);

export const getJsaCirclesSelector = createSelector(
  jsaSelector,
  jsas => jsas && jsas.circles
);

export const getJsaLoadingSelector = createSelector(
  jsaSelector,
  jsa => jsa && jsa.loading
);

export const getCustomRaTemplatesSelector = createSelector(
  jsaSelector,
  jsa => jsa && jsa.customTemplates
);

export const getActiveCustomRaTemplateSelector = createSelector(
  jsaSelector,
  jsa => jsa && jsa.activeCustomTemplate
);
