import React, { Component } from 'react';

import { InputRow } from '../inputs';
import Button from '../inputs/Button';

import styles from './styles.module.scss';

class Carousel extends Component {
  state = {
    selectedItem: 0
  };

  addDefaultSrc(ev) {
    ev.target.src = require('../../assets/images/cloud-upload.gif');
  }

  handleDelete() {
    if (this.state.selectedItem === this.props.items.length - 1) {
      this.setState({ selectedItem: this.state.selectedItem - 1 });
    }
    this.props.deleteAction(this.props.items[this.state.selectedItem]);
  }

  render() {
    const { items, title, canDelete } = this.props;
    const { selectedItem } = this.state;

    const classes =
      items.length === 1
        ? [styles.carousel, styles.oneItem]
        : [styles.carousel];

    return (
      <>
        {title && <h2 className={styles.carouselHeader}>{title}</h2>}
        <div className={classes.join(' ')}>
          {items?.length > 1 && (
            <img
              src={require('../../assets/images/leftChevron.png')}
              alt="Go back hover"
              onClick={() =>
                this.setState({
                  selectedItem: selectedItem > 0 ? selectedItem - 1 : 0
                })
              }
            />
          )}
          {items[selectedItem] ? (
            <div className={styles.imageContainer}>
              <img
                src={items[selectedItem].source_url}
                onError={this.addDefaultSrc}
                alt={items[selectedItem].original_fileName}
              />
              {canDelete && (
                <InputRow center className={styles.actionItems}>
                  <Button
                    color="blue"
                    text="Download"
                    onClick={() =>
                      window.open(items[selectedItem].source_url, '_blank')
                    }
                  />
                  <Button
                    color="red"
                    text="Delete"
                    onClick={() => this.handleDelete()}
                  />
                </InputRow>
              )}
            </div>
          ) : (
            ''
          )}
          {items.length > 1 && (
            <img
              src={require('../../assets/images/rightChevron.png')}
              alt="Go back hover"
              onClick={() =>
                this.setState({
                  selectedItem:
                    selectedItem === items?.length - 1
                      ? selectedItem
                      : selectedItem + 1
                })
              }
            />
          )}
        </div>
      </>
    );
  }
}

export default Carousel;
