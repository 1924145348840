import React from 'react';
import { BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

class CustomizedAxisTick extends React.Component {
  render() {
    const { x, y, payload, fontSize } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          fontSize={fontSize}
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

CustomizedAxisTick.defaultProps = {
  width: 50,
  maxChars: 10,
  fontSize: 12,
  lineHeight: 14,
  fill: '#333'
};

const CustomTooltip = props => {
  const { active } = props;

  if (active) {
    const { payload, label, isNearMiss } = props;

    return (
      <div className="custom-tooltip">
        <p>
          {!isNearMiss || label === 'Not Specified'
            ? `${label} : ${payload[0].value}`
            : `Unsafe ${label} : ${payload[0].value}`}
        </p>
      </div>
    );
  }

  return null;
};

const BarChartComponent = ({
  data,
  height,
  width,
  children,
  customToolTip,
  customXAxisLabel,
  isNearMiss
}) => (
  <BarChart width={width} height={height} data={data}>
    <CartesianGrid strokeDasharray="3 3" />
    {customXAxisLabel ? (
      <XAxis
        dataKey="name"
        interval={0}
        height={60}
        tick={<CustomizedAxisTick />}
      />
    ) : (
      <XAxis dataKey="name" interval={0} />
    )}
    <YAxis />
    {customToolTip ? (
      <Tooltip
        content={<CustomTooltip isNearMiss={isNearMiss} />}
        wrapperStyle={{
          width: 200,
          backgroundColor: '#fff',
          border: '1px solid #ccc'
        }}
      />
    ) : (
      <Tooltip />
    )}
    {children}
  </BarChart>
);

export default BarChartComponent;
