import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    width="30px"
    height="25px"
  >
    <path d="M96.599,41.802L82.537,30.307V10.913c0-0.868-0.71-1.579-1.578-1.579H68.453  c-0.868,0-1.578,0.71-1.578,1.579v6.589L55.383,8.107c-3.164-2.587-7.601-2.587-10.765,0l-11.275,9.218l-0.292,6.832l17.259,7.272  L39.945,54.337l13.281,20.117l-5.914,3.904l-15.436-23.38l8.978-19.844l-15.094-6.36l0.232-5.441l-2.368,1.936L3.402,41.802  c-0.945,0.773-1.286,1.998-0.875,3.146c0.41,1.15,1.449,1.883,2.669,1.883H13.5v40.578c0,3.533,2.891,6.424,6.424,6.424h60.152  c3.533,0,6.425-2.891,6.425-6.424V46.832h8.304c1.221,0,2.26-0.732,2.669-1.883C97.884,43.8,97.544,42.575,96.599,41.802z" />
  </svg>
);
