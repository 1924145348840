import { oshaText } from '../constants/oshaComplianceQuestionText';

export const oshaDbMapper = oshaQuestions => {
  let newOshaQuestionAnswers = [];

  Object.keys(oshaQuestions).map(key =>
    newOshaQuestionAnswers.push({
      key: key,
      value: oshaQuestions[key],
      questionText: oshaText[key]
    })
  );

  return newOshaQuestionAnswers;
};

export const oshaQuestionMapper = dbQuestions => {
  let oshaQuestions = {};
  dbQuestions.forEach(question => {
    if (question) {
      const key = question.key;
      const value = question.value;
      oshaQuestions[key] = value;
    }
  });

  return oshaQuestions;
};

export const canSubmit = oshaForm => {
  if (!oshaForm) return false;
  if (oshaForm.isNotApplicable) return true;
  if (oshaForm.redline) return true;

  const validDate = !!oshaForm.dateTime;

  const description = oshaForm.description && oshaForm.description !== '';
  const employeeName = oshaForm.employeeName && oshaForm.employeeName !== '';
  const jobTitle = oshaForm.jobTitle && oshaForm.jobTitle !== '';
  const injuryIllness = oshaForm.injuryIllness && oshaForm.injuryIllness !== '';

  const firstSubmit =
    validDate && description && employeeName && jobTitle && injuryIllness;

  if (!firstSubmit) return false;

  if (!oshaForm.happenToThisWorker || oshaForm.happenToThisWorker.length === 0) {
    return true;
  }

  const timeOfDayWorkStarted =
    oshaForm.timeOfDayWorkStarted && oshaForm.timeOfDayWorkStarted !== '';
  const workerDoing = oshaForm.workerDoing && oshaForm.workerDoing !== '';
  const howInjuryOccurred =
    oshaForm.howInjuryOccurred && oshaForm.howInjuryOccurred !== '';
  const incidentInjuryIllness =
    oshaForm.incidentInjuryIllness && oshaForm.incidentInjuryIllness !== '';
  const bodyParts = oshaForm.bodyParts && oshaForm.bodyParts !== '';

  const canSubmitIncident =
    timeOfDayWorkStarted &&
    workerDoing &&
    howInjuryOccurred &&
    incidentInjuryIllness &&
    bodyParts;

  const physicianName = oshaForm.physicianName && oshaForm.physicianName !== '';
  const facility =
    (oshaForm.facilityName &&
      oshaForm.facilityName !== '' &&
      oshaForm.facilityAddress &&
      oshaForm.facilityAddress !== '' &&
      oshaForm.facilityCity &&
      oshaForm.facilityCity !== '' &&
      oshaForm.facilityState &&
      oshaForm.facilityState !== '' &&
      oshaForm.facilityZip &&
      oshaForm.facilityZip !== '') ||
    (oshaForm.facilityTreatedWorksite &&
      oshaForm.facilityTreatedWorksite.length === 1);
  const treatedInEmergency =
    oshaForm.treatedInEmergency && oshaForm.treatedInEmergency !== '';
  const hospitalizedInpatient =
    oshaForm.hospitalizedInpatient && oshaForm.hospitalizedInpatient !== '';

  const canSubmitTreatment =
    physicianName &&
    facility &&
    treatedInEmergency &&
    hospitalizedInpatient &&
    oshaForm.happenToThisWorker.length > 0;

  if (!canSubmitTreatment || !canSubmitIncident) return false;

  const deathHappened =
    oshaForm.happenToThisWorker &&
    oshaForm.happenToThisWorker.some(option => option.value === 0);
  const daysAwayHappened =
    oshaForm.happenToThisWorker &&
    oshaForm.happenToThisWorker.some(option => option.value === 1);

  const canSubmitDeath = oshaForm.dateOfDeath && oshaForm.dateOfDeath !== '';
  if (deathHappened && !canSubmitDeath) return false;

  const canSubmitDaysAway =
    oshaForm.daysAwayFromWork &&
    oshaForm.daysAwayFromWork !== '' &&
    oshaForm.daysJobRestriction &&
    oshaForm.daysJobRestriction !== '';
  const isOngoing = oshaForm.ongoing?.length >= 1;
  if (daysAwayHappened && (!canSubmitDaysAway || isOngoing)) return false;

  return true;
};

export const oshaHasAnswers = oshaForm => {
  const isNotApplicable = oshaForm.isNotApplicable;
  const hasAnswers = oshaForm && Object.keys(oshaForm).length > 0;

  return hasAnswers && !isNotApplicable;
};
