import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import * as api from '../../api';
import {
  addUploadedAttachmentsRequest,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  createCustomDocumentRequest,
  deleteCustomDocumentAttachmentRequest,
  deleteCustomDocumentRequest,
  setActiveCustomDocument,
  updateCustomDocumentRequest
} from '../../actions/documents';
import { updateLeftNavRequest } from '../../actions/navigation';
import { fetchAllActiveEmployees } from '../../actions/personnel';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCustomDocSelector,
  getActiveCustomDocTemplateSelector
} from '../../selectors/documents';
import { getCompanyActiveEmployeesSelector } from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';

import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { SaveCancelFooter } from '../../components/Footer';
import CustomDocumentGenerator from '../../components/documentCards/CustomDocumentGenerator';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';

export class CustomDocumentContainer extends Component {
  state = {
    openUnsavedTemplateModal: false,
    openUnsavedChangesModal: false,
    openDeleteTemplateModal: false,
    fields: [],
    isCreating: false,
    isEditing: false,
    hasUnsavedChanges: false,
    expires: '',
    downloadCustomDoc: false
  };

  componentDidMount() {
    this.props.updateLeftNav('Custom Documents');
    this.props.fetchAllActiveEmployees();

    if (this.props.activeCustomDoc._id) {
      this.setState({
        ...this.props.activeCustomDoc,
        fields: this.props.activeCustomDoc.documentData.fields
      });
    } else {
      this.setState({
        isCreating: true,
        fields: this.props.activeCustomDocTemplate.fields
      });
    }
  }

  handleCustomSubmit = () => {
    if (this.state._id) {
      this.props.updateCustomTemplate(this.state);
    } else {
      this.props.createCustomTemplate({
        ...this.state,
        label: this.props.activeCustomDocTemplate.label
      });
    }
  };

  handleCustomDelete = () => {
    this.props.deleteCustomTemplate(this.state);
  };

  canSubmit = () => {
    const requiredNotAnswered = this.state.fields.filter(
      question =>
        question.required &&
        question.type !== 'blockOfText' &&
        (Array.isArray(question.answer)
          ? question.answer.length === 0
          : !question.answer)
    );

    if (!this.state.hasUnsavedChanges) {
      return false;
    }

    return this.state.fields.length > 0 && requiredNotAnswered.length === 0;
  };

  downloadDocument = async () => {
    this.setState({ downloadCustomDoc: true });

    const url = await api.printCustomDoc(this.props.activeCustomDoc);

    window.open(url, '_blank');

    this.setState({ downloadCustomDoc: false });
  };

  render() {
    const {
      openUnsavedTemplateModal,
      openUnsavedChangesModal,
      openDeleteTemplateModal,
      isCreating,
      isEditing,
      hasUnsavedChanges,
      fields,
      expires,
      downloadCustomDoc
    } = this.state;

    const {
      setActiveCustomDocument,
      employees,
      activeCustomDoc,
      addedAttachments,
      addAttachment,
      deleteAttachment,
      loading,
      activeAttachments,
      activeCompanyId,
      deleteCDAttachment
    } = this.props;

    const header = (
      <Header
        title="Custom Document"
        section={
          !isEditing && !isCreating
            ? 'View Document'
            : isCreating
            ? 'Create New Document'
            : 'Edit Document'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges && isEditing
            ? this.setState({ openUnsavedChangesModal: true })
            : hasUnsavedChanges && isCreating
            ? this.setState({ openUnsavedTemplateModal: true })
            : setActiveCustomDocument({}) && history.goBack()
        }
        rightButtons={{
          text: isEditing ? 'Delete Document' : 'Edit Document',
          color: isEditing ? 'red' : 'blue',
          visible: !isCreating,
          onClick: () =>
            isEditing
              ? this.setState({ openDeleteTemplateModal: true })
              : this.setState({ isEditing: true })
        }}
      />
    );

    const footer =
      isEditing || isCreating ? (
        <SaveCancelFooter
          saveButtonDisabled={!this.canSubmit()}
          saveButtonClick={() => this.handleCustomSubmit()}
          cancelButtonClick={() =>
            isEditing && hasUnsavedChanges
              ? this.setState({ openUnsavedChangesModal: true })
              : isEditing && !hasUnsavedChanges
              ? this.setState({ isEditing: false })
              : this.setState({ openUnsavedChangesModal: true })
          }
          editing={isEditing}
        />
      ) : (
        <></>
      );

    return (
      <>
        <HeaderAndFooter Header={header} Footer={footer}>
          <CustomDocumentGenerator
            answerQuestion={fields =>
              this.setState({ fields: [...fields], hasUnsavedChanges: true })
            }
            fields={fields}
            disabled={!isEditing && !isCreating}
            employees={employees}
            activeCustomDoc={activeCustomDoc}
            handleExpires={values => this.setState({ expires: values })}
            expires={expires}
            printDocument={() => this.downloadDocument()}
            downloadCustomDoc={downloadCustomDoc}
            addedAttachments={addedAttachments}
            addAttachment={addAttachment}
            deleteAttachment={deleteAttachment}
            activeAttachments={activeAttachments}
            activeCompanyId={activeCompanyId}
            loading={loading}
            deleteCDAttachment={deleteCDAttachment}
            hasAccess={this.props.loggedInUser.accessLevel !== '100'}
          />
        </HeaderAndFooter>
        <Modal
          title="Unsaved Document"
          titleClassName="redHeader"
          isOpen={openUnsavedTemplateModal}
          submitButtonColor="red"
          submitButtonText="Discard Document"
          onRequestClose={() =>
            this.setState({ openUnsavedTemplateModal: false })
          }
          submitActions={() => setActiveCustomDocument({}) && history.goBack()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Leaving without saving will result in this document not being
              created.{' '}
            </span>
            If you wish to keep your template, click “Cancel”, and then hit
            “Save Changes" at the bottom of the create template screen.
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => setActiveCustomDocument({}) && history.goBack()}
          savingWhat="a document"
        />
        <Modal
          title="Delete Document"
          titleClassName="redHeader"
          isOpen={openDeleteTemplateModal}
          submitButtonColor="red"
          submitButtonText="Delete Document"
          onRequestClose={() =>
            this.setState({ openDeleteTemplateModal: false })
          }
          submitActions={() => this.handleCustomDelete()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting a Document will permanently remove it for all users.
            </span>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeCustomDoc: getActiveCustomDocSelector(state),
  activeCustomDocTemplate: getActiveCustomDocTemplateSelector(state),
  loggedInUser: getLoggedInUser(state),
  employees: getCompanyActiveEmployeesSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  loading: getAttachmentLoadingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation)),
  createCustomTemplate: values => dispatch(createCustomDocumentRequest(values)),
  updateCustomTemplate: values => dispatch(updateCustomDocumentRequest(values)),
  deleteCustomTemplate: values => dispatch(deleteCustomDocumentRequest(values)),
  setActiveCustomDocument: values => dispatch(setActiveCustomDocument(values)),
  fetchAllActiveEmployees: values => dispatch(fetchAllActiveEmployees(values)),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  deleteCDAttachment: attachment =>
    dispatch(deleteCustomDocumentAttachmentRequest(attachment))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDocumentContainer);
