import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import CheckboxGroup from '../../inputs/CheckboxGroup';
import RadioButtons from '../../inputs/RadioButtons';

import quizCardStyles from './styles.module.scss';

export default function QuizCard(props) {
  const [quiz, setQuiz] = useState([]);
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    setQuestions(props?.quiz?.questions);
    setQuiz(props.quiz);
  }, [props, props.quiz]);

  const handleQuizAnswers = (questionIndex, answers) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].assigneeSelectedAnswers = answers;

    setQuestions(newQuestions);
    props.handleQuizAnswers(newQuestions);
  };

  return (
    <>
      {questions?.map((question, index) => {
        const answerOptions = question?.answerOptions;
        const assigneeSelectedAnswers = question?.assigneeSelectedAnswers;
        const answers = answerOptions?.map((answerOption, answerIndex) => {
          return {
            value: answerOption._id,
            label: answerOption.answer
          };
        });
        const correctAnswers = answerOptions
          ?.filter(answer => answer.isCorrectAnswer)
          .map(answerOption => answerOption.answer);
        const hasCorrectAnswers = isEqual(
          correctAnswers,
          assigneeSelectedAnswers.map(selected => selected.label)
        );

        const getImage = hasCorrectAnswers
          ? require('../../../assets/images/Check.png')
          : require('../../../assets/images/Remove Icon.png');

        return (
          <div className={quizCardStyles.question} key={index}>
            <div>
              {quiz.isCompleted && (
                <div className={quizCardStyles.imageDiv}>
                  <img
                    src={getImage}
                    alt={
                      hasCorrectAnswers
                        ? 'you got it right'
                        : 'you got it wrong'
                    }
                  />
                </div>
              )}
              {correctAnswers?.length === 1 ? (
                <RadioButtons
                  fieldLabel={question.label}
                  options={answers}
                  isRequired
                  handleChange={values => handleQuizAnswers(index, [values])}
                  currentValue={assigneeSelectedAnswers[0]}
                  disabled={quiz.isCompleted}
                />
              ) : (
                <CheckboxGroup
                  fieldLabel={`${question.label} (Mark All Potential Answers)`}
                  options={answers}
                  isRequired
                  handleChange={values => handleQuizAnswers(index, values)}
                  currentValue={assigneeSelectedAnswers}
                  disabled={quiz.isCompleted}
                />
              )}
            </div>
            {quiz.isCompleted && !hasCorrectAnswers && (
              <div className={quizCardStyles.correctAnswers}>
                <h4>Correct Answers</h4>
                {correctAnswers.join(', ')}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}
