import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { Tabbordion, TabContent, TabLabel, TabPanel } from 'react-tabbordion';
import { setActiveIncidentResponse } from '../../actions/incidents';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  fetchUsersTasksRequest,
  setActiveTaskRequest
} from '../../actions/tasks';
import { getActiveCompany } from '../../selectors/company';
import {
  getCompleteTasksSelector,
  getInProgressTasksSelector,
  getNotStartedTasksSelector,
  getStuckTasksSelector,
  getTaskCircleDataSelector,
  getUserTasksSelector
} from '../../selectors/tasks';
import { getLoggedInUser } from '../../selectors/users';
import { getMessageSelector } from '../../selectors/messages';
import { AnalyticCircleBox } from '../../components/AnalyticCircle';
import TaskList from '../../components/TaskList';
import Header from '../../components/Header';

import './index.css';
import '../../components/ObjectList/index.css';
import '../../components/Tabs/index.css';

export class TaskStages extends Component {
  state = {
    isRepeating: false
  };

  componentDidMount() {
    this.props.updateLeftNav('Tasks');
    this.props.fetchUsersTasks(false);
    this.props.activeIncidentResponse();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  handleTypeSelection = stateObject => {
    this.props.fetchUsersTasks(stateObject.isRepeating);

    this.setState(stateObject);
  };

  render() {
    const {
      circles,
      notStartedTasks,
      inProgressTasks,
      stuckTasks,
      completeTasks,
      setActiveTask,
      loggedInUser,
      activeGroups,
      allTasks
    } = this.props;

    const { isRepeating } = this.state;

    const tabs = ['Assigned', 'In Progress', 'Stuck', 'Complete'];

    const taskHeader = [
      'Group/Est.',
      'Title',
      'Priority',
      'Due Date',
      'View/Edit'
    ];

    const repeatedHeaders = [
      'Group/Est.',
      'Title',
      'Priority',
      'Repeat On',
      'View/Edit'
    ];

    const taskContent = [
      notStartedTasks,
      inProgressTasks,
      stuckTasks,
      completeTasks
    ];

    const blockElements = {
      content: 'traditional-tabs-content',
      panel: 'traditional-tabs-panel',
      label: 'traditional-tabs-title'
    };

    return (
      <div className="tasks">
        <Header
          title="Tasks"
          rightButtons={
            loggedInUser.accessLevel !== '100' && {
              text: 'Create Task',
              className: 'blueButton',
              onClick: () => setActiveTask({})
            }
          }
        />
        <div className="tasks-typeSelection">
          <div
            className={
              isRepeating
                ? 'tasks-toggleSwitch toggle-active'
                : 'tasks-toggleSwitch toggle-active tasks-toggleSelected'
            }
            onClick={() => this.handleTypeSelection({ isRepeating: false })}
          >
            <div>Active</div>
          </div>
          <div
            className={
              isRepeating
                ? 'tasks-toggleSwitch tasks-toggleSelected'
                : 'tasks-toggleSwitch'
            }
            onClick={() => this.handleTypeSelection({ isRepeating: true })}
          >
            <div>Repeating</div>
          </div>
        </div>
        {!isRepeating && <AnalyticCircleBox circles={circles} />}
        <div className="tasks-content">
          {isRepeating ? (
            <TaskList
              taskHeader={repeatedHeaders}
              content={allTasks}
              onClick={setActiveTask}
              activeGroups={activeGroups}
              isRepeating={isRepeating}
              hasBorder={true}
            />
          ) : (
            <Tabbordion
              blockElements={blockElements}
              className="traditional-tabs"
              name="tabs"
            >
              {tabs.map((tab, index) => (
                <TabPanel key={index}>
                  <TabLabel>{`${tab} (${taskContent[index].length})`}</TabLabel>
                  <TabContent>
                    <TaskList
                      taskHeader={taskHeader}
                      content={taskContent[index]}
                      onClick={setActiveTask}
                      activeGroups={activeGroups}
                      isRepeating={isRepeating}
                    />
                  </TabContent>
                </TabPanel>
              ))}
            </Tabbordion>
          )}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  circles: getTaskCircleDataSelector(state),
  notStartedTasks: getNotStartedTasksSelector(state),
  inProgressTasks: getInProgressTasksSelector(state),
  stuckTasks: getStuckTasksSelector(state),
  completeTasks: getCompleteTasksSelector(state),
  message: getMessageSelector(state),
  activeGroups: getActiveCompany(state).groups,
  allTasks: getUserTasksSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  fetchUsersTasks: isRecurring =>
    dispatch(
      fetchUsersTasksRequest({
        isFromStages: true,
        page: 1,
        limit: 10000,
        removeClosed: false,
        isRecurring: isRecurring
      })
    ),
  setActiveTask: task => dispatch(setActiveTaskRequest(task)),
  activeIncidentResponse: () => dispatch(setActiveIncidentResponse({}))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(TaskStages)
);
