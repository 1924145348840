import {
  ADD_EMPLOYEE_DOCUMENT_REQUEST,
  ADD_EMPLOYEE_DOCUMENT_RESPONSE,
  ADD_LOCATION_EMPLOYEE_REQUEST,
  ADD_LOCATION_EMPLOYEE_RESPONSE,
  DELETE_EMPLOYEE_DOCUMENT_REQUEST,
  DELETE_EMPLOYEE_DOCUMENT_RESPONSE,
  DELETE_LOCATION_EMPLOYEE_REQUEST,
  DELETE_LOCATION_EMPLOYEE_RESPONSE,
  DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
  DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE,
  FETCH_ALL_ACTIVE_EMPLOYEES,
  FETCH_ALL_ACTIVE_EMPLOYEES_ACTIVE_USERS,
  FETCH_COMPANY_EMPLOYEES_RESPONSE,
  FETCH_COMPANY_USERS_RESPONSE,
  IMPORT_EMPLOYEE_REQUEST,
  RESET_EMPLOYEES_PASSWORD_REQUEST,
  RESEND_WELCOME_NOTIFICATION_REQUEST,
  SET_ACTIVE_EMPLOYEE_REQUEST,
  SET_ACTIVE_EMPLOYEE_RESPONSE,
  UPDATE_EMPLOYEE_DOCUMENT_REQUEST,
  UPDATE_EMPLOYEE_DOCUMENT_RESPONSE,
  UPDATE_LOCATION_EMPLOYEE_REQUEST,
  UPDATE_LOCATION_EMPLOYEE_RESPONSE,
  UPLOAD_ERROR_RESPONSE
} from '../constants/actionTypes';

export const fetchCompanyEmployeesResponse = payload => ({
  type: FETCH_COMPANY_EMPLOYEES_RESPONSE,
  payload
});

export const setActiveEmployeeRequest = payload => ({
  type: SET_ACTIVE_EMPLOYEE_REQUEST,
  payload
});

export const addLocationEmployeeRequest = payload => ({
  type: ADD_LOCATION_EMPLOYEE_REQUEST,
  payload
});

export const addLocationEmployeeResponse = payload => ({
  type: ADD_LOCATION_EMPLOYEE_RESPONSE,
  payload
});

export const updateLocationEmployeeRequest = payload => ({
  type: UPDATE_LOCATION_EMPLOYEE_REQUEST,
  payload
});

export const updateLocationEmployeeResponse = payload => ({
  type: UPDATE_LOCATION_EMPLOYEE_RESPONSE,
  payload
});

export const deleteLocationEmployeeRequest = payload => ({
  type: DELETE_LOCATION_EMPLOYEE_REQUEST,
  payload
});

export const deleteLocationEmployeeResponse = payload => ({
  type: DELETE_LOCATION_EMPLOYEE_RESPONSE,
  payload
});

export const importEmployeeRequest = payload => ({
  type: IMPORT_EMPLOYEE_REQUEST,
  payload
});

export const setActiveEmployeeResponse = payload => ({
  type: SET_ACTIVE_EMPLOYEE_RESPONSE,
  payload
});

export const downloadEmployeeQRcodeRequest = payload => ({
  type: DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
  payload
});

export const downloadEmployeeQRcodeResponse = payload => ({
  type: DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE,
  payload
});

export const addEmployeeDocumentRequest = payload => ({
  type: ADD_EMPLOYEE_DOCUMENT_REQUEST,
  payload
});

export const addEmployeeDocumentResponse = payload => ({
  type: ADD_EMPLOYEE_DOCUMENT_RESPONSE,
  payload
});

export const updateEmployeeDocumentRequest = payload => ({
  type: UPDATE_EMPLOYEE_DOCUMENT_REQUEST,
  payload
});

export const updateEmployeeDocumentResponse = payload => ({
  type: UPDATE_EMPLOYEE_DOCUMENT_RESPONSE,
  payload
});

export const deleteEmployeeDocumentRequest = payload => ({
  type: DELETE_EMPLOYEE_DOCUMENT_REQUEST,
  payload
});

export const deleteEmployeeDocumentResponse = payload => ({
  type: DELETE_EMPLOYEE_DOCUMENT_RESPONSE,
  payload
});

export const fetchAllActiveEmployees = payload => ({
  type: FETCH_ALL_ACTIVE_EMPLOYEES,
  payload
});

export const fetchAllActiveEmployeesActiveUsers = payload => ({
  type: FETCH_ALL_ACTIVE_EMPLOYEES_ACTIVE_USERS,
  payload
});

export const fetchCompanyUsersResponse = payload => ({
  type: FETCH_COMPANY_USERS_RESPONSE,
  payload
});

export const uploadErrorResponse = payload => ({
  type: UPLOAD_ERROR_RESPONSE,
  payload
});

export const resetEmployeesPasswordRequest = payload => ({
  type: RESET_EMPLOYEES_PASSWORD_REQUEST,
  payload
});

export const resendWelcomeNotificationRequest = payload => ({
  type: RESEND_WELCOME_NOTIFICATION_REQUEST,
  payload
});
