import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import {
  createMyDocumentRequest,
  deleteMyDocumentRequest,
  setActiveMyDocument,
  updateMyDocumentRequest
} from '../../actions/documents';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getActiveMyDocumentSelector } from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import Button from '../../components/inputs/Button';
import FileDownloader from '../../components/FileDownloader';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import Textbox from '../../components/inputs/Textbox';

import './index.css';

class MyDocumentContainer extends Component {
  state = {
    isCreating: false,
    hasUnsavedChanges: false,
    isEditing: false,
    selectedAttachment: 0,
    label: '',
    description: '',
    openDeleteDocumentModal: false,
    openDiscardDocumentModal: false,
    openUnsavedChangesModal: false,
    images: []
  };

  componentDidMount() {
    if (!this.props.activeMyDoc._id) {
      this.setState({ isCreating: true });
    } else {
      this.setState({
        label: this.props.activeMyDoc.label,
        description:
          this.props.activeMyDoc.documentData &&
          this.props.activeMyDoc.documentData.description,
        images: this.props.activeMyDoc.attachments
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addedAttachments &&
      this.props.addedAttachments !== prevProps.addedAttachments
    ) {
      this.setState({
        images: [...this.state.images, ...this.props.addedAttachments]
      });
    }
  }

  handlePhotoUpload = async e => {
    const files = e.target.files;

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    await this.props.addAttachment({
      data,
      ownerId: this.props.loggedInUser?._id,
      ownerType: 'EmployeeDocument'
    });

    this.setState({ hasUnsavedChanges: true });
  };

  canSubmit = () => {
    const { label, description, images } = this.state;

    if (label && description && (images.length > 0 || images.length > 0)) {
      return true;
    }

    return false;
  };

  handleSubmit = () => {
    const { label, description } = this.state;
    const { activeMyDoc } = this.props;

    if (activeMyDoc._id) {
      this.props.updateMyDocument({ ...activeMyDoc, label, description });
    } else {
      this.props.createMyDocument({ label, description });
    }
  };

  handleDeleteDocument = () => {
    this.props.deleteMyDocument(this.props.activeMyDoc);
    this.props.clearUploadedAttachments();
  };

  handleNavigateAway = () => {
    this.props.setActiveMyDocument({ attachments: [] });
    this.props.clearUploadedAttachments();
    history.goBack();
  };

  render() {
    const {
      isCreating,
      hasUnsavedChanges,
      isEditing,
      selectedAttachment,
      label,
      description,
      openDeleteDocumentModal,
      openDiscardDocumentModal,
      openUnsavedChangesModal,
      images
    } = this.state;

    const header = (
      <Header
        title="My Documents"
        section={
          !isEditing && !isCreating
            ? 'View Document'
            : isCreating
            ? 'Create Document'
            : 'Edit Document'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
        rightButtons={{
          text: isEditing ? 'Delete Document' : 'Edit Document',
          color: isEditing ? 'red' : 'blue',
          onClick: () =>
            isEditing
              ? this.setState({ openDeleteDocumentModal: true })
              : this.setState({ isEditing: true })
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={this.handleSubmit}
        cancelButtonText={isCreating ? 'Discard' : 'Cancel'}
        cancelButtonColor={isCreating ? 'red' : 'white'}
        cancelButtonClick={() =>
          isCreating
            ? this.setState({ openDiscardDocumentModal: true })
            : isEditing && hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isEditing || isCreating}
        >
          <div className="myDocumentContainer-card">
            {images.length > 0 && (
              <div
                className={
                  images.length === 1
                    ? 'myDocumentContainer-card-pictureContainer--onePicture'
                    : 'myDocumentContainer-card-pictureContainer'
                }
              >
                {images.length > 1 && (
                  <img
                    src={require('../../assets/images/leftChevron.png')}
                    alt="Go back hover"
                    className="arrows"
                    onClick={() =>
                      this.setState({
                        selectedAttachment:
                          selectedAttachment > 0 ? selectedAttachment - 1 : 0
                      })
                    }
                  />
                )}
                <FileDownloader
                  attachment={images[selectedAttachment]}
                  attachmentIndex={selectedAttachment}
                  disabled={true}
                />
                {images.length > 1 && (
                  <img
                    src={require('../../assets/images/rightChevron.png')}
                    alt="Go back hover"
                    className="arrows"
                    onClick={() =>
                      this.setState({
                        selectedAttachment:
                          selectedAttachment === images.length - 1
                            ? selectedAttachment
                            : selectedAttachment + 1
                      })
                    }
                  />
                )}
                {/* <div className="photoButtons">
                  <Button
                    type="button"
                    text="Download"
                    color="white"
                    onClick={() =>
                      window.open(
                        images[selectedAttachment].source_url,
                        '_blank'
                      )
                    }
                  />
                  <Button
                    type="button"
                    text="Remove"
                    color="red"
                    // onClick={() =>
                    //   this.handlePhotoEvidenceUpdate({
                    //     isRemoved: true,
                    //     isRemovedByOwner: isIncidentOwner
                    //   })
                    // }
                  />
                </div> */}
              </div>
            )}
            <div
              className={images.length > 0 ? 'padding noTopBorder' : 'padding'}
            >
              {(isEditing || isCreating) && (
                <div className="addPictures">
                  <Button
                    text="Add Attachments"
                    color="blue"
                    onClick={() =>
                      document.getElementById('myPictureFile').click()
                    }
                  />
                  {images.length === 0 && (
                    <div className="requiredPictures">
                      *At least 1 attachment is required
                    </div>
                  )}
                </div>
              )}
              <input
                type="file"
                id="myPictureFile"
                onChange={e => this.handlePhotoUpload(e)}
                multiple
              />
              <Textbox
                fieldLabel="Document Title"
                placeholder="Type a clear and consise title."
                isRequired={true}
                handleChange={e =>
                  this.setState({
                    label: e.target.value,
                    hasUnsavedChanges: true
                  })
                }
                currentValue={label}
                disabled={!isEditing && !isCreating}
              />
              <Textbox
                type="textarea"
                rows={5}
                fieldLabel="Description"
                placeholder="Describe this document. Be sure to include any important details."
                handleChange={e =>
                  this.setState({
                    description: e.target.value,
                    hasUnsavedChanges: true
                  })
                }
                currentValue={description}
                disabled={!isEditing && !isCreating}
                isRequired={true}
              />
            </div>
          </div>
        </HeaderAndFooter>
        <Modal
          title="Delete Document"
          titleClassName="redHeader"
          isOpen={openDeleteDocumentModal}
          submitButtonColor="red"
          submitButtonText="Delete Document"
          onRequestClose={() =>
            this.setState({ openDeleteDocumentModal: false })
          }
          submitActions={() => this.handleDeleteDocument()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting the Document will delete it for you as well as anyone who
              may be associated with it.
            </span>
          </div>
        </Modal>
        <Modal
          title="Discard Document"
          titleClassName="redHeader"
          isOpen={openDiscardDocumentModal}
          submitButtonColor="red"
          submitButtonText="Discard Document"
          onRequestClose={() =>
            this.setState({ openDiscardDocumentModal: false })
          }
          submitActions={() => this.handleNavigateAway()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Discarding the Document will delete all information that has been
              gathered and pictures taken.
            </span>
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleNavigateAway()}
          savingWhat="a page"
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeMyDoc: getActiveMyDocumentSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveMyDocument: payload => dispatch(setActiveMyDocument(payload)),
  addAttachment: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  createMyDocument: payload => dispatch(createMyDocumentRequest(payload)),
  updateMyDocument: payload => dispatch(updateMyDocumentRequest(payload)),
  deleteMyDocument: payload => dispatch(deleteMyDocumentRequest(payload)),
  clearUploadedAttachments: () => dispatch(clearUploadedAttachments())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDocumentContainer);
