import React from 'react';
import moment from 'moment';

import * as api from '../../../api';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';
import Card from '../../Card';

import './index.css';

const printSafetyWalk = async (safetyWalk, loggedInUser) => {
  const url = await api.printSafetyWalk(safetyWalk);
  window.open(url.pdfUrl, '_blank');
};

const hazards = summary => {
  const hazards = {
    text: `${summary.hazardsFound} Hazards Found`,
    color: 'black'
  };
  const immediatelyFixed = {
    text: `${summary.immediatelyFixed} Immediately Fixed`,
    color: summary.immediatelyFixed ? 'green' : 'gray'
  };
  const correctiveActionsCreated = {
    text: `${summary.correctiveActionsCreated} Corrective Actions Assigned`,
    color: summary.correctiveActionsCreated ? 'blue' : 'gray'
  };
  const correctiveActionsCompleted = {
    text: `${summary.correctiveActionsCompleted} Corrective Actions Completed`,
    color: summary.correctiveActionsCompleted ? 'green' : 'gray'
  };
  const correctiveActionsPastDue = {
    text: `${summary.correctiveActionsPastDue} Corrective Actions Past Due`,
    color: summary.correctiveActionsPastDue ? 'red' : 'gray'
  };

  return [
    hazards,
    immediatelyFixed,
    correctiveActionsCreated,
    correctiveActionsCompleted,
    correctiveActionsPastDue
  ];
};

const SummaryCard = ({ safetyWalk, loggedInUser, name, isDash }) => {
  return (
    <Card id={name} title="Summary" showHeader>
      <div className="safetyWalkSummaryCard-createdBy">
        <UserBadge
          size="large"
          showImage
          showName
          byline="Created By"
          userId={safetyWalk.createdBy?._id ?? safetyWalk.createdBy}
          className="safetyWalkSummaryCard-createdBy--created"
        />
        <div className="safetyWalkSummaryCard-createdBy--date">
          <div className="safetyWalksSummaryCard-topHeader">
            Created at{' '}
            {safetyWalk &&
              moment(Date.parse(safetyWalk.createdAt)).format('hh:mm A')}
          </div>
          <div className="safetyWalksSummaryCard-bottomHeader">
            {safetyWalk &&
              moment(Date.parse(safetyWalk.createdAt)).format('LL')}
          </div>
        </div>
      </div>
      <ul className="hazardList">
        {safetyWalk.summary &&
          hazards(safetyWalk.summary).map((hazard, index) => (
            <li key={index}>
              <div className={`${hazard.color} listPadding`}>{hazard.text}</div>
            </li>
          ))}
      </ul>
      <div className="summaryCard-footer">
        <Button
          className="blueButton safetyWalksSummaryCard-button"
          text={isDash ? 'Download DASH Report' : 'Download Safety Walk'}
          onClick={() => printSafetyWalk(safetyWalk, loggedInUser)}
        />
      </div>
    </Card>
  );
};

export default SummaryCard;
