import React, { Component } from 'react';

import { DatePicker } from '../../inputs/DateTimePicker';
import Textbox from '../../inputs/Textbox';

import './index.css';

export default class TransitionalInfoCard extends Component {
  render() {
    const {
      employeeName,
      workRestrictions,
      dateOfTransition,
      handleChange,
      disabled,
      name
    } = this.props;
    return (
      <div className="transitionalCard" id={name}>
        <h2 className="transitionalCard-header">
          Transitional Return to Work Program
        </h2>
        <Textbox
          fieldLabel="Employee Name"
          placeholder="ex. Jane Doe"
          isRequired={true}
          handleChange={e =>
            handleChange({
              employeeName: e.target.value
            })
          }
          currentValue={employeeName}
          disabled={disabled}
        />
        <div className="transitionalCard-disabledText">
          The Transitional Return to Work Program (TRWP) is designed to provide
          you with a suitable temporary work assignment while you complete your
          recovery. All parties agree that during your participation in the
          program, you will not be required to perform any task or duties that
          are not compatible with the temporary restrictions that your physician
          has provided.
        </div>
        <Textbox
          fieldLabel="List your Work Restrictions (according to your physician)"
          type="textarea"
          rows={5}
          placeholder="Type here"
          handleChange={e =>
            handleChange({
              workRestrictions: e.target.value
            })
          }
          currentValue={workRestrictions}
          disabled={disabled}
        />
        <DatePicker
          pickTime
          fieldLabel="Date Transitional Return to Work Program starts"
          isRequired
          disabled={disabled}
          handleChange={value => handleChange({ dateOfTransition: value })}
          currentValue={dateOfTransition}
        />
        <div className="transitionalCard-disabledText">
          The program may continue as long as there is medical documentation of
          need and the division is able to accommodate the temporary
          restrictions. You will be paid your regular rate of pay for hours
          worked while participating in the program. You will be expected to
          follow all established personnel policies and procedures. If you are
          required to attend physical therapy or doctor appointments, you are
          expected to use appropriate injury leave or sick leave as specified in
          your collective bargaining agreement and are encouraged to schedule
          these appointments during non-working hours whenever possible. Your
          signature on this form indicates that you understand the requirements
          for participation in the Transitional Return to Work Program and that
          you will abide by the restrictions placed upon you by your physician.
        </div>
      </div>
    );
  }
}
