import React, { Component } from 'react';

import '../input.css';
import './index.css';

export default class RadioButtons extends Component {
  render() {
    const {
      name,
      fieldLabel,
      options,
      handleChange,
      isRequired,
      disabled,
      className,
      currentValue
    } = this.props;

    let values = currentValue;
    if (!values) {
      values = {};
    }

    return (
      <div className={`input ${className}`}>
        {fieldLabel && (
          <label className="label" htmlFor={name}>
            {isRequired ? <span className="isRequired">*</span> : ''}
            {fieldLabel}
          </label>
        )}
        <div className="radioButtons-row">
          {options.map((option, index) => (
            <div className="radioButtoninput" key={index}>
              <div className="radioButtonDiv">
                <label className="radioButton-label">
                  {option.label}
                  <input
                    type="checkbox"
                    name={`${name}[${index}]`}
                    value={option.value}
                    checked={
                      values === option.value || values.value === option.value
                    }
                    onChange={() => {
                      handleChange(option);
                    }}
                    disabled={disabled}
                  />
                  <span
                    className={disabled ? 'radioButtonDisabled' : 'radioButton'}
                  />
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
