import React from 'react';
import './svg.css';

export default () => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x="0px"
    y="0px"
    viewBox="0 0 95 95"
  >
    <g>
      <path d="M72.4,48c-0.8,0-1.5,0.7-1.5,1.5v37.1H23.6v-57v-3.8v-1.4c0-1.3-0.4-2.5-1-3.5h27.2c0.8,0,1.5-0.7,1.5-1.5   s-0.7-1.5-1.5-1.5H17.3h-0.1h-0.1c-0.1,0-0.2,0-0.2,0c-3.4,0.2-6.1,3-6.1,6.5v2.9h9.8v2.3v59.9h53.3v-40C73.9,48.7,73.3,48,72.4,48   z M17.2,20.9c1.8,0.1,3.3,1.6,3.4,3.4h-6.8C13.9,22.5,15.4,21,17.2,20.9z" />
      <path d="M53.2,76.4c-0.1-0.8-0.9-1.4-1.7-1.3s-1.4,0.9-1.3,1.7c0.2,1.3,1.4,3.8,4.7,3.8c1.4,0,2.8-0.6,4-1.6   c1.1,0.9,2.5,1.6,3.7,1.6c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5c-0.5,0-1.1-0.3-1.7-0.8c0.7-1,1.1-2.1,1.1-3.1s-0.4-1.7-0.8-2   c-0.4-0.4-1-0.8-2.2-0.8c-0.9,0-1.7,0.3-2.3,0.8c-0.4,0.4-0.9,1-0.9,2.1c0,0.9,0.4,2,1.1,3c-0.7,0.5-1.4,0.8-2,0.8   C53.6,77.6,53.3,76.7,53.2,76.4z M58.9,73.9L58.9,73.9L58.9,73.9L58.9,73.9z" />
      <path d="M32.5,34h17.3c0.8,0,1.5-0.7,1.5-1.5S50.6,31,49.8,31H32.5c-0.8,0-1.5,0.7-1.5,1.5S31.7,34,32.5,34z" />
      <path d="M31,41c0,0.8,0.7,1.5,1.5,1.5h22c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5h-22C31.7,39.5,31,40.2,31,41z" />
      <path d="M62.6,48H32.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h30.1c0.8,0,1.5-0.7,1.5-1.5S63.5,48,62.6,48z" />
      <path d="M63.4,56.5H32.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h30.9c0.8,0,1.5-0.7,1.5-1.5S64.3,56.5,63.4,56.5z" />
      <path d="M64.9,66.4c0-0.8-0.7-1.5-1.5-1.5H32.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h30.9C64.3,67.9,64.9,67.2,64.9,66.4z" />
      <path d="M32.5,73.3c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h12.3c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H32.5z" />
      <path d="M88.2,9.9C87.4,9,86.3,8.4,85,8.4H59.8c-1.2,0-2.4,0.5-3.2,1.5c-0.8,0.9-1.1,2.2-0.9,3.4l2.8,15.8   c1.3,7,6.6,12.4,13.5,13.8h0.4l0.3-0.1C79.5,41.4,84.8,36,86,29.1l2.9-15.9C89.3,12.1,89,10.8,88.2,9.9z M85.9,11.8   c0.1,0.1,0.3,0.5,0.2,0.9l-1.7,9.4l-5.3,0.6c-1.7,0.2-3.5,0.3-5.2,0.3V11.4H85C85.5,11.4,85.8,11.7,85.9,11.8z M59,11.8   c0.1-0.1,0.4-0.4,0.9-0.4H71v11.7c-2.1,0-4.2-0.2-6.3-0.4l-4.2-0.5l-1.7-9.5C58.7,12.3,58.9,12,59,11.8z M61.6,28.5L61,25.3   l3.3,0.4c2.2,0.3,4.4,0.4,6.6,0.4v13.3C66.1,37.8,62.5,33.7,61.6,28.5z M73.9,39.4V26.1c1.9-0.1,3.7-0.2,5.6-0.4l4.4-0.5l-0.6,3.4   C82.3,33.7,78.7,37.8,73.9,39.4z" />
    </g>
  </svg>
);
