import React from 'react';
import Dashboard from '../svgs/dashboard';
import Tasks from '../svgs/tasks';
import FROI from '../svgs/froi';
import AutoIncident from '../svgs/autoIncident';
import GeneralLiability from '../svgs/generalLiability';
import CorrectiveAction from '../svgs/correctiveAction';
import NearMiss from '../svgs/nearMiss';
import JSA from '../svgs/jsa';
import SafetySuggestion from '../svgs/safetySuggestion';
import SiteInspection from '../svgs/siteInspection';
import CriticalBehavior from '../svgs/criticalBehavior';
import SafetyInstructions from '../svgs/safetyInstructions';
import SDS from '../svgs/sds';
import SafetyTraining from '../svgs/safetyTraining';
import EmployeesUsers from '../svgs/employeesUsers';
import Locations from '../svgs/locations';
import DOT from '../svgs/dot';
import LockoutTagout from '../svgs/lockoutTagout';
import Incidents from '../svgs/incidents';
import DailyReports from '../svgs/dailyReports';
import Theft from '../svgs/theft';
import PropertyDamage from '../svgs/propertyDamage';
import Gear from '../svgs/gear';
import PlusSign from '../svgs/plusSign';
import Home from '../svgs/home';
import Company from '../svgs/company';
import Search from '../svgs/search';
import Reporter from '../svgs/reporter';
import Collaborator from '../svgs/collaborator';
import Admin from '../svgs/admin';
import Delete from '../svgs/delete';
import Toolbox from '../svgs/toolbox';
import Supervisor from '../svgs/supervisor';
import LRR from '../svgs/lrr';
import OshaLogo from '../svgs/oshaLogo';
import Analytics from '../svgs/analytics';
import Qualifications from '../svgs/qualifications';
import Projects from '../svgs/project';
import S4 from '../svgs/s4';
import InfoIcon from '../svgs/infoIcon';
import CustomRa from '../svgs/customRa';
import CustomQuestions from '../svgs/customQuestions';
import CustomDocument from '../svgs/customDocument';
import Aircraft from '../svgs/aircraft';
import FuelReport from '../svgs/fuelReports';
import BarChart from '../svgs/barChart';
import LineChart from '../svgs/lineChart';
import PieChart from '../svgs/pieChart';
import List from '../svgs/list';
import Statistic from '../svgs/statistic';
import Quiz from '../svgs/quiz';

const svgMapper = imageSvg => {
  switch (imageSvg) {
    case 'Dashboard':
      return <Dashboard />;
    case 'Tasks':
      return <Tasks />;
    case 'first-report-of-injury':
    case 2:
    case '2':
      return <FROI />;
    case 'auto-accident':
    case 1:
    case '1':
      return <AutoIncident />;
    case 'general-liability':
    case 5:
    case '5':
      return <GeneralLiability />;
    case 'corrective-action':
      return <CorrectiveAction />;
    case 'near-miss-report':
    case 7:
    case '7':
      return <NearMiss />;
    case 'jsa':
    case 8:
    case '8':
      return <JSA />;
    case 'safety-suggestion':
    case 9:
    case '9':
      return <SafetySuggestion />;
    case 'site-inspection':
    case 10:
    case '10':
      return <SiteInspection />;
    case 'critical-behavior':
    case 12:
    case '12':
      return <CriticalBehavior />;
    case 11:
    case '11':
      return <S4 />;
    case 'safety-instruction':
      return <SafetyInstructions />;
    case 'sds':
      return <SDS />;
    case 'safety-training':
      return <SafetyTraining />;
    case 'Employees':
    case 'Users':
    case 'Contractors':
      return <EmployeesUsers />;
    case 'Locations':
      return <Locations />;
    case 'dot':
    case 15:
    case '15':
      return <DOT />;
    case 'lockout-tagout':
    case 14:
    case '14':
      return <LockoutTagout />;
    case 'Incidents':
      return <Incidents />;
    case 'daily-reports':
      return <DailyReports />;
    case 4:
    case '4':
      return <Theft />;
    case 3:
    case '3':
      return <PropertyDamage />;
    case 'gear':
      return <Gear />;
    case 'plusSign':
      return <PlusSign />;
    case 'home':
      return <Home />;
    case 'company':
      return <Company />;
    case 'search':
      return <Search />;
    case 'reporter':
      return <Reporter />;
    case 'collaborator':
      return <Collaborator />;
    case 'admin':
      return <Admin />;
    case 'delete':
      return <Delete />;
    case 'toolbox':
      return <Toolbox />;
    case 'supervisor':
      return <Supervisor />;
    case 'lossRun':
      return <LRR />;
    case 'oshaLogo':
      return <OshaLogo />;
    case 'Analytics':
      return <Analytics />;
    case 'qualifications':
      return <Qualifications />;
    case 'Projects':
      return <Projects />;
    case 'InfoIcon':
      return <InfoIcon />;
    case 'CustomRa':
      return <CustomRa />;
    case 'CustomQuestions':
      return <CustomQuestions />;
    case 'CustomDocument':
      return <CustomDocument />;
    case 'BarChart':
      return <BarChart />;
    case 'LineChart':
      return <LineChart />;
    case 'PieChart':
      return <PieChart />;
    case 'List':
      return <List />;
    case 'Statistic':
      return <Statistic />;
    case 6:
    case '6':
      return <Aircraft />;
    case 16:
    case '16':
      return <FuelReport />;
    case 'quiz':
      return <Quiz />;
    default:
      return '';
  }
};

export default svgMapper;
