import React, { Component } from 'react';
import history from '../../history';
import ReactTable from 'react-table';
import moment from 'moment';
import ReactLoading from 'react-loading';
import expirationMapper from '../../utils/expirationMapper';

import {
  defaultFilterMethod,
  defaultSortMethod,
  filterAttachments,
  filterExpireDate,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import AddToFolderModal from '../Modal/addToFolderModal';
import Button from '../inputs/Button';
import Checkbox from '../inputs/Checkbox';
import FileDownloader from '../FileDownloader';
import DocumentFolder from '../DocumentFolder';
import Modal from '../Modal';
import SdsForm from '../../forms/SdsForm';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

class SdsList extends Component {
  state = {
    modalOpen: false,
    sds: {
      label: '',
      expires: '',
      ghsLabels: [],
      locationId: [],
      projectId: [],
      documentFolderId: ''
    },
    addToFolder: [],
    addToFolderModal: false,
    documentFolderId: '',
    filters: []
  };

  componentDidMount = () => {
    if (localStorage.getItem('sdsFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('sdsFilters'))
      });
    }

    this.props.fetchDocumentFolders({
      documentType: 'SDS',
      companyId: this.props.activeCompanyId
    });
    this.props.updateLeftNav('SDS');
  };

  handleOpenModal() {
    this.setState({
      modalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }

  updateSds = values => {
    let sds = this.state.sds;
    sds = { ...sds, ...values };

    this.setState({ sds: sds });
  };

  handleSubmit = () => {
    this.props.addSds(this.state.sds);
    this.props.clearAttachment();
    this.handleCloseModal();
  };

  handleSort = async (sortFunction, sortBy) => {
    sortFunction();
  };

  handleAddToFolder = id => {
    this.setState(state => {
      let { addToFolder } = state;

      const qrIndex = addToFolder.findIndex(i => i === id);

      if (qrIndex > -1) {
        addToFolder = addToFolder.filter(i => i !== id);
      } else {
        addToFolder = [...addToFolder, id];
      }

      return { addToFolder };
    });
  };

  handleAddMultipleToFolders = () => {
    const { content } = this.props;
    let addDocsToFolder = [];

    const addToFolder = this.state.addToFolder;

    content.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({
      addToFolderModal: false,
      addToFolder: [],
      documentFolderId: ''
    });
  };

  handleFilterChange = filters => {
    localStorage.setItem('sdsFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  render() {
    const {
      content,
      setSds,
      activeCompanyId,
      addAttachment,
      clearAttachment,
      loading,
      accessLevel,
      locationList,
      deleteAttachment,
      addedAttachments,
      activeProject,
      activeLocation,
      activeFolder,
      documentFolders,
      isAddingToFolder
    } = this.props;

    const {
      modalOpen,
      sds,
      addToFolder,
      addToFolderModal,
      documentFolderId,
      filters
    } = this.state;

    const folderList = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    return (
      <div>
        <ScrollToTopOnMount />
        <h2 className="documents-header">
          SDS
          <div className="documents-buttonBox">
            {accessLevel !== '100' && (
              <Button
                className="add"
                text="Add SDS"
                onClick={() => this.handleOpenModal()}
              />
            )}
          </div>
        </h2>
        {activeFolder.name && (
          <DocumentFolder
            activeFolder={activeFolder}
            accessLevel={accessLevel}
            updateDocumentFolder={this.props.updateDocumentFolder}
            deleteDocumentFolder={this.props.deleteDocumentFolder}
            username={this.props.user.username}
            content={content}
            docType="Safety Data Sheet"
          />
        )}
        <div className="documents-list">
          <ReactTable
            data={content}
            filterable
            resizable={false}
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            onFilteredChange={filtered => this.handleFilterChange(filtered)}
            filtered={filters}
            columns={[
              {
                Header: () =>
                  isAddingToFolder ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#58a15b"
                      className="loading"
                    />
                  ) : (
                    <Button
                      className="blueButton"
                      text="Add to Folder"
                      onClick={() => this.setState({ addToFolderModal: true })}
                    />
                  ),
                show: accessLevel !== '100',
                Cell: row => (
                  <Checkbox
                    name={row.original._id}
                    onChange={() => this.handleAddToFolder(row.original._id)}
                    currentValue={addToFolder.indexOf(row.original._id) !== -1}
                  />
                ),
                filterable: false,
                sortable: false
              },
              {
                Header: 'TITLE',
                accessor: 'label'
              },
              {
                Header: 'ATTACHMENTS(S)',
                accessor: 'attachments',
                Cell: ({ original }) => (
                  <div>
                    {original.attachments.map((attachment, attachmentIndex) => (
                      <FileDownloader
                        attachment={attachment}
                        key={attachmentIndex}
                        attachmentIndex={attachmentIndex}
                        disabled
                        isInList
                      />
                    ))}
                  </div>
                ),
                filterMethod: (filter, row) => filterAttachments(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="hasAttachments">Has Data Sheets</option>
                    <option value="noAttachments">No Data Sheets</option>
                  </select>
                ),
                sortable: false
              },
              {
                Header: 'EXPIRATION DATE',
                accessor: 'expires',
                Cell: ({ original }) =>
                  original.expires ? (
                    <div className="documents-list--severity">
                      <img
                        src={expirationMapper(original.expires)}
                        alt="severity"
                      />
                      <span>
                        {moment(original.expires).format('MM/DD/YYYY')}
                      </span>
                    </div>
                  ) : (
                    ''
                  ),
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterExpireDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Expired</option>
                    <option value="within30">Expiring within 30 Days</option>
                    <option value="within60">Expiring within 60 Days</option>
                    <option value="past90">Expiring after 60 Days</option>
                  </select>
                )
              },
              {
                Header: 'VIEW/EDIT',
                Cell: ({ original }) => (
                  <Button
                    text="View/Edit"
                    color="blue"
                    onClick={() => {
                      setSds(original);
                      history.push('/app/viewEdit/SDS');
                    }}
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
            noDataText="No results found"
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
        {modalOpen && (
          <Modal
            title="Add SDS"
            titleClassName="greenHeader"
            wide
            submitButtonColor="green"
            isOpen={modalOpen}
            onRequestClose={() => clearAttachment() && this.handleCloseModal()}
            submitActions={() => this.handleSubmit()}
            disableSubmit={sds?.label?.length < 3}
          >
            <SdsForm
              activeCompanyId={activeCompanyId}
              addAttachment={addAttachment}
              loading={loading}
              sds={{}}
              handleSdsSelection={values => this.updateSds(values)}
              locationList={locationList}
              deleteAttachment={deleteAttachment}
              addedAttachments={addedAttachments}
              activeLocation={activeLocation}
              activeProject={activeProject}
              documentFolders={documentFolders}
            />
          </Modal>
        )}
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={() => this.handleAddMultipleToFolders()}
          folderOptions={folderList}
          handleChange={values =>
            this.setState({ documentFolderId: values.value })
          }
        />
      </div>
    );
  }
}

export default SdsList;
