import React from 'react';
import styles from './styles.module.scss';

export default function TypeSelection({ selected, selectedArray, onClick }) {
  return (
    <div className={styles.typeSelection}>
      {selectedArray.map((item, index) => (
        <div
          className={item === selected ? styles.toggleSelected : undefined}
          onClick={() => onClick(item)}
          key={index}
        >
          <div>{item}</div>
        </div>
      ))}
    </div>
  );
}
