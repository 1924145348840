import {
  CHARGE_CUSTOMER_REQUEST,
  CHARGE_CUSTOMER_RESPONSE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_RESPONSE,
  FETCH_ACCOUNT_INFORMATION_REQUEST,
  FETCH_ACCOUNT_INFORMATION_RESPONSE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_RESPONSE
} from '../constants/actionTypes';

export const chargeCustomerRequest = payload => ({
  type: CHARGE_CUSTOMER_REQUEST,
  payload
});

export const chargeCustomerResponse = payload => ({
  type: CHARGE_CUSTOMER_RESPONSE,
  payload
});

export const createCustomerRequest = payload => ({
  type: CREATE_CUSTOMER_REQUEST,
  payload
});

export const createCustomerResponse = payload => ({
  type: CREATE_CUSTOMER_RESPONSE,
  payload
});

export const updateCustomerRequest = payload => ({
  type: UPDATE_CUSTOMER_REQUEST,
  payload
});

export const updateCustomerResponse = payload => ({
  type: UPDATE_CUSTOMER_RESPONSE,
  payload
});

export const fetchAccountInformationRequest = payload => ({
  type: FETCH_ACCOUNT_INFORMATION_REQUEST,
  payload
});

export const fetchAccountInformationResponse = payload => ({
  type: FETCH_ACCOUNT_INFORMATION_RESPONSE,
  payload
});
