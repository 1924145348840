import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import moment from 'moment';

import { getPercentages } from '../../utils/incidentHelper';
import {
  setActiveQualification,
  setActiveSafetyInstruction,
  setActiveSds
} from '../../actions/documents';
import { setActiveIncidentRequest } from '../../actions/incidents';
import { setActiveJsaResponse } from '../../actions/jsa';
import {
  downloadEmployeeQRcodeRequest,
  setActiveEmployeeRequest
} from '../../actions/personnel';
import { searchRequest } from '../../actions/search';
import { setActiveTaskRequest } from '../../actions/tasks';
import { getCompanyLocationsSelector } from '../../selectors/company';
import {
  getSearchListSelector,
  getSearchLoadingSelector
} from '../../selectors/search';
import { getLoggedInUser } from '../../selectors/users';
import Button from '../../components/inputs/Button';
import EmployeeCard from '../../components/EmployeeCard';
import { IncidentCard } from '../../components/DashboardCard';
import JsaCard from '../../components/JsaCard';
import QualificationCard from '../../components/QualificationCard';
import SafetyInstructionCard from '../../components/SafetyInstructionCard';
import SdsCard from '../../components/SdsCard';
import TaskCard from '../../components/TaskCard';

import './index.css';

const getStatus = (i, stage) => {
  const percentages = getPercentages(i.reportComponents, stage, i.reportTemplateIds);
  return percentages.completed === percentages.total
    ? 'complete'
    : percentages.pastDue > 0
    ? 'pastDue'
    : (percentages.started > 0 || percentages.completed > 0)
    ? 'started'
    : 'notStarted';
};

export class SearchPage extends Component {
  render() {
    const {
      searchSections,
      setActiveIncident,
      setActiveTask,
      setSds,
      setSafetyInstruction,
      setActiveEmployee,
      setActiveJsa,
      setQualification,
      companyLocations,
      searchByTerms,
      downloadQRCodes,
      searchLoading
    } = this.props;

    return (
      <div className="searchPage">
        {searchLoading && (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        )}
        {!searchLoading && searchSections.length === 0 ? (
          <div>
            <h2>No Search Results</h2>
            <h3>Please expand your search</h3>
          </div>
        ) : (
          ''
        )}
        {!searchLoading &&
          searchSections.map((search, index) => (
            <div className="searchPage-searchDiv" key={index}>
              <h3 className="searchPage-searchDiv--header">
                <span>{`${search.searchType}`}</span>
                <div className="searchPage-searchDiv--buttonBox">
                  {searchSections.searchType === 'All' ? (
                    <Button
                      className={'add'}
                      text={`See All ${search.searchType}`}
                      onClick={() =>
                        searchByTerms({
                          searchTerm: searchSections.searchTerm,
                          searchType: search.searchType
                        })
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
              </h3>
              <hr />
              {search.searchItems
                .slice(0, searchSections.maxLength)
                .map((item, index) => {
                  if (search.searchType === 'Incidents') {
                    let auditLog = item.auditLog;
                    item.updatedAt = moment(auditLog[auditLog.length - 1].when);
                    item.description =
                      item.answers[item.incidentBasicsFieldGroup.fields[1]._id];
                    item.status = {
                      investigation: getStatus(item, 0),
                      action: getStatus(item, 1),
                      closure: getStatus(item, 2),
                    };

                    return (
                      <IncidentCard
                        incident={item}
                        key={index}
                        onClick={() => setActiveIncident(item)}
                      />
                    );
                  } else if (search.searchType === 'Tasks') {
                    return (
                      <TaskCard
                        task={item}
                        key={index}
                        onClick={() => setActiveTask(item)}
                      />
                    );
                  } else if (search.searchType === 'SDS') {
                    return (
                      <SdsCard
                        sds={item}
                        key={index}
                        onClick={() => setSds(item)}
                      />
                    );
                  } else if (search.searchType === 'Safety Instructions') {
                    return (
                      <SafetyInstructionCard
                        safetyInstruction={item}
                        key={index}
                        onClick={() => setSafetyInstruction(item)}
                      />
                    );
                  } else if (
                    search.searchType === 'Employees' ||
                    search.searchType === 'Contractors'
                  ) {
                    return (
                      <EmployeeCard
                        employee={item}
                        key={index}
                        onClick={() =>
                          setActiveEmployee({
                            ...item,
                            setEmployeeType: search.searchType
                          })
                        }
                        setEmployeeType={search.searchType}
                        downloadQRCodes={downloadQRCodes}
                      />
                    );
                  } else if (search.searchType === 'JSA') {
                    return (
                      <JsaCard
                        jsa={item}
                        key={index}
                        onClick={() => setActiveJsa(item)}
                        companyLocations={companyLocations}
                      />
                    );
                  } else if (search.searchType === 'Qualifications') {
                    return (
                      <QualificationCard
                        qualification={item}
                        key={index}
                        onClick={() => setQualification(item)}
                      />
                    );
                  }
                  return '';
                })}
            </div>
          ))}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  searchSections: getSearchListSelector(state),
  companyLocations: getCompanyLocationsSelector(state),
  loggedInUser: getLoggedInUser(state),
  searchLoading: getSearchLoadingSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  setActiveIncident: incident => dispatch(setActiveIncidentRequest(incident)),
  setActiveTask: task => dispatch(setActiveTaskRequest(task)),
  setSds: sds => dispatch(setActiveSds(sds)),
  setSafetyInstruction: safetyInstruction =>
    dispatch(setActiveSafetyInstruction(safetyInstruction)),
  setActiveEmployee: activeEmployee =>
    dispatch(setActiveEmployeeRequest(activeEmployee)),
  setActiveJsa: jsa => dispatch(setActiveJsaResponse(jsa)),
  searchByTerms: values => dispatch(searchRequest(values)),
  setQualification: qualification =>
    dispatch(setActiveQualification(qualification)),
  downloadQRCodes: values => dispatch(downloadEmployeeQRcodeRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
