import React, { Component } from 'react';
import moment from 'moment';

import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  PRIORITY_DROPDOWN,
  TASK_CATEGORY_DROPDOWN,
  TASK_REASON_DROPDOWN,
  YEARLY_FREQUENCY_OPTIONS
} from '../../../constants/constants';
import { getPermissionUserList } from '../../../utils/incidentHelper';
import { TwoColumn } from '../../inputs';
import Card from '../../Card';
import Checkbox from '../../inputs/Checkbox';
import { DatePicker } from '../../inputs/DateTimePicker';
import DayOfWeekButtons from '../../inputs/DayOfWeekButtons';
import Dropdown from '../../inputs/Dropdown';
import Multiselect from '../../inputs/Multiselect';
import UserBadge from '../../UserBadge';
import RadioButtons from '../../inputs/RadioButtons';
import Textbox from '../../inputs/Textbox';
import DueBy from '../../inputs/DueBy';

import './index.css';

export default class AssignTaskCard extends Component {
  render() {
    const {
      employees,
      groupList,
      disabled,
      activeLocation,
      activeProject,
      locationId,
      label,
      description,
      dueDate,
      assignedTo,
      currentCategory,
      currentPriority,
      currentReason,
      handleAnswer,
      projectId,
      notes,
      repeatingTask,
      repeatingOption,
      recurringTime,
      repeatingOn,
      task,
      isFromRa,
      isFromReport,
      isForIncident,
      activeIncident,
      isIndividual,
      isEditing,
      isCreatedBy,
      frequency,
      match
    } = this.props;

    const employeeDropdown = isForIncident
      ? getPermissionUserList(activeIncident, employees, match.params.stage)
      : employees.map(employee => {
          return {
            value: employee.userAccount._id,
            label: `${employee.person.firstName} ${employee.person.lastName}`
          };
        });

    const groupDropdown = groupList.map(group => {
      return {
        value: group._id,
        label: group.name
      };
    });

    const getProjectDropdown = () => {
      const selectedGroup = groupList.filter(
        group => group._id === activeLocation._id || group._id === locationId
      );

      let temp = [];

      if (selectedGroup.length > 0) {
        temp = selectedGroup[0].projects.map(project => {
          return {
            value: project._id,
            label: project.name
          };
        });
        return [{ value: '', label: '      ' }, ...temp];
      }

      return [];
    };

    return (
      <Card>
        {task?._id && (
          <div className="assignTaskCard-header">
            <UserBadge
              userId={task.createdBy}
              showImage
              showName
              size="large"
              byline="Assigned By"
              className="assignTaskCard-assignBy"
            />
            <div className="assignTaskCard-headerCombination">
              <div>
                Assigned at{' '}
                {moment(Date.parse(task.createdAt)).format('hh:mm A')}
              </div>
              <div style={{ fontWeight: 'bold' }}>
                {moment(Date.parse(task.createdAt)).format('LL')}
              </div>
            </div>
          </div>
        )}
        <TwoColumn>
          {!activeLocation && (
            <Dropdown
              searchable={true}
              options={groupDropdown}
              fieldLabel="Group or Establishment:"
              currentValue={locationId}
              handleChange={values =>
                handleAnswer({ locationId: values.value })
              }
              isRequired={true}
              disabled={disabled || isForIncident || isEditing || isFromRa}
            />
          )}
          {!activeProject && (
            <Dropdown
              searchable={true}
              options={getProjectDropdown()}
              fieldLabel="Project:"
              currentValue={projectId}
              handleChange={values => handleAnswer({ projectId: values.value })}
              disabled={disabled || isEditing || isFromRa}
            />
          )}
        </TwoColumn>
        <Textbox
          currentValue={label}
          fieldLabel="Title:"
          handleChange={e => handleAnswer({ label: e.target.value })}
          type="textarea"
          placeholder="Type a clear and concise title."
          isRequired={true}
          disabled={disabled || isFromRa || isFromReport || isEditing}
        />
        <Textbox
          currentValue={description}
          fieldLabel="Description:"
          handleChange={e => handleAnswer({ description: e.target.value })}
          type="textarea"
          placeholder="Describe what needs to be done to complete this Task. Be sure to include any important details."
          isRequired={true}
          disabled={disabled || isEditing}
        />
        <Textbox
          currentValue={notes}
          fieldLabel="Notes:"
          handleChange={e => handleAnswer({ notes: e.target.value })}
          type="textarea"
          placeholder="Type any notes that relate to this task. This field may be edited by the assignees as well."
          disabled={disabled}
        />
        <TwoColumn>
          <Dropdown
            options={PRIORITY_DROPDOWN}
            fieldLabel="Priority:"
            currentValue={currentPriority}
            handleChange={values =>
              handleAnswer({ currentPriority: values.value })
            }
            isRequired={true}
            disabled={disabled || isEditing}
          />
          <Dropdown
            options={TASK_CATEGORY_DROPDOWN}
            fieldLabel="Category:"
            currentValue={currentCategory}
            handleChange={values =>
              handleAnswer({ currentCategory: values.value })
            }
            disabled={disabled || isFromReport || isEditing}
            isRequired={true}
          />
        </TwoColumn>
        <Dropdown
          options={TASK_REASON_DROPDOWN}
          fieldLabel="Reason:"
          currentValue={currentReason}
          handleChange={values => handleAnswer({ currentReason: values.value })}
          disabled={disabled || isFromRa || isFromReport || isEditing}
          isRequired={true}
        />
        {!isFromRa && !isFromReport && !isEditing && !isForIncident && (
          <RadioButtons
            fieldLabel="Schedule:"
            options={[
              { value: 0, label: 'Immediately' },
              { value: 1, label: 'Repeating' }
            ]}
            isRequired={true}
            handleChange={values => handleAnswer({ repeatingTask: values })}
            currentValue={repeatingTask}
            disabled={disabled || isEditing}
          />
        )}
        {repeatingTask.value === 1 && (
          <Dropdown
            options={FREQUENCY_OPTIONS}
            fieldLabel="Frequency:"
            currentValue={frequency}
            handleChange={values => handleAnswer({ frequency: values.value })}
            isRequired={true}
            disabled={disabled || isEditing}
          />
        )}
        {!isFromRa &&
          !isFromReport &&
          !isForIncident &&
          repeatingTask.value === 1 &&
          frequency === 'weekly' && (
            <DayOfWeekButtons
              fieldLabel="Assign Every:"
              inputClassName="dayOfWeekInput"
              disabled={repeatingTask.value !== 1 || disabled}
              handleChange={values => handleAnswer({ repeatingOn: values })}
              currentValue={repeatingOn}
            />
          )}
        {!isFromRa &&
          !isFromReport &&
          !isForIncident &&
          repeatingTask.value === 1 &&
          frequency === 'monthly' && (
            <Dropdown
              options={MONTHLY_FREQUENCY_OPTIONS}
              fieldLabel="Assign Every:"
              currentValue={repeatingOn}
              handleChange={values => handleAnswer({ repeatingOn: values })}
              isRequired={true}
              disabled={disabled || isEditing}
            />
          )}
        {!isFromRa &&
          !isFromReport &&
          !isForIncident &&
          repeatingTask.value === 1 &&
          frequency === 'yearly' && (
            <Dropdown
              options={YEARLY_FREQUENCY_OPTIONS}
              fieldLabel="Assign Every:"
              currentValue={repeatingOn}
              handleChange={values => handleAnswer({ repeatingOn: values })}
              isRequired={true}
              disabled={disabled || isEditing}
            />
          )}
        {repeatingTask.value === 1 &&
        (!isFromRa || !isFromReport || !isForIncident) ? (
          <DueBy
            fieldLabel="Due By:"
            isRequired
            disabled={disabled || (isEditing && !isCreatedBy)}
            name="dueBy"
            dueBy={repeatingOption}
            dueTime={recurringTime}
            onDueByChange={v => handleAnswer({ repeatingOption: v })}
            onDueTimeChange={v => handleAnswer({ recurringTime: v })}
          />
        ) : (
          <DatePicker
            fieldLabel="Due By:"
            isRequired
            handleChange={value => handleAnswer({ dueDate: value })}
            pickTime
            currentValue={dueDate}
            disabled={disabled || (isEditing && !isCreatedBy)}
          />
        )}
        {isForIncident ? (
          <Dropdown
            options={employeeDropdown}
            fieldLabel="Assignee:"
            currentValue={assignedTo}
            handleChange={values => handleAnswer({ assignedTo: values.value })}
            isRequired={true}
            disabled={disabled || (isEditing && !isCreatedBy)}
            searchable={true}
            name="singleEmployees"
          />
        ) : (
          <Multiselect
            options={employeeDropdown}
            fieldLabel="Assignee(s):"
            currentValue={assignedTo}
            isRequired={true}
            handleChange={values => handleAnswer({ assignedTo: values })}
            disabled={disabled || (isEditing && !isCreatedBy)}
            searchable={true}
            selectButtons
            name="multiEmployees"
          />
        )}
        {!isFromRa && !isFromReport && (
          <Checkbox
            fieldLabel="Assign Individually"
            name="isIndividual"
            onChange={value => handleAnswer({ isIndividual: value })}
            currentValue={isIndividual}
            disabled={disabled || (isEditing && !isCreatedBy)}
          />
        )}
      </Card>
    );
  }
}
