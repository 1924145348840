import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabbordion, TabContent, TabLabel, TabPanel } from 'react-tabbordion';
import { clearUploadedAttachments } from '../../actions/attachments';
import {
  fetchCompanyIncidentsRequest,
  fetchIncidentBasicsRequest,
  setActiveIncidentRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import {
  fetchJsaSectionsRequest,
  setActiveJsaResponse
} from '../../actions/jsa';
import { updateLeftNavRequest } from '../../actions/navigation';
import { fetchAllActiveEmployeesActiveUsers } from '../../actions/personnel';
import { INCIDENT_STAGES } from '../../constants/constants';
import { getActiveCompany, getActiveLocationId } from '../../selectors/company';
import {
  getIncidentCircleSelector,
  getIncidentsSelector
} from '../../selectors/incidents';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import { getIncidentStagesList } from '../../utils/incidentHelper';
import { AnalyticCircleBox } from '../../components/AnalyticCircle';
import IncidentsList from '../../components/IncidentsList';
import Header from '../../components/Header';

import './index.css';
import '../../components/ObjectList/index.css';
import '../../components/Tabs/index.css';

export class IncidentStages extends Component {
  componentDidMount() {
    this.props.updateLeftNav('Reports');
    this.props.fetchCompanyIncidents(1);
    this.props.clearAttachments();
    this.props.fetchAllActiveEmployeesActiveUsers();
  }

  render() {
    const { circles, setActiveIncident, incidents, users } = this.props;

    const incidentContent = getIncidentStagesList(incidents);

    const tabs = INCIDENT_STAGES;

    const blockElements = {
      content: 'traditional-tabs-content',
      panel: 'traditional-tabs-panel',
      label: 'traditional-tabs-title'
    };

    return (
      <div className="incidents">
        <Header title="Reports" />
        <AnalyticCircleBox circles={circles} />
        <div className="incidents-content">
          <Tabbordion
            blockElements={blockElements}
            className="traditional-tabs"
            name="tabs"
          >
            {tabs.map((tab, index) => (
              <TabPanel key={index}>
                <TabLabel>{`${tab} (${incidentContent[index].length})`}</TabLabel>
                <TabContent>
                  <IncidentsList
                    content={incidentContent[index]}
                    setActiveIncident={setActiveIncident}
                    activeEmployeeList={users}
                  />
                </TabContent>
              </TabPanel>
            ))}
          </Tabbordion>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  circles: getIncidentCircleSelector(state),
  incidents: getIncidentsSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocationId: getActiveLocationId(state),
  loggedInUser: getLoggedInUser(state),
  users: getCompanyUsersSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  fetchIncidentBasics: reportTypes =>
    dispatch(fetchIncidentBasicsRequest(reportTypes)),
  setActiveIncident: incident => dispatch(setActiveIncidentRequest(incident)),
  fetchCompanyIncidents: page =>
    dispatch(
      fetchCompanyIncidentsRequest({
        page: page,
        limit: 10000,
        removeClosed: false
      })
    ),
  clearAttachments: () => dispatch(clearUploadedAttachments()),
  setIncident: () => dispatch(setActiveIncidentResponse({})),
  setJsa: () => dispatch(setActiveJsaResponse({})),
  fetchJsaSections: () => dispatch(fetchJsaSectionsRequest()),
  fetchAllActiveEmployeesActiveUsers: () =>
    dispatch(fetchAllActiveEmployeesActiveUsers())
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentStages);
