import React, { Component } from 'react';
import ReactLoading from 'react-loading';

import styles from './loading.module.scss';

export class Loading extends Component {
  render() {
    let style = {};
    let classes = [styles.loading]
    let size = 128;
    if (this.props.className) classes.push(this.props.className);

    if (this.props.mode === 'fixedSize') {
      style.width = this.props.width;
      style.height = this.props.height;
    } else if (this.props.mode === 'auto') {
      size = '100%';
    }

    return (
      <div
        className={classes.join(' ')}
        style={style}
      >
        {this.props.text && 'Loading'}
        <ReactLoading
          width={size}
          height={size}
          type="bubbles"
          className="reactLoadingBlue"
        />
      </div>
    );
  }
}

export default Loading;
