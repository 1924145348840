import React, { Component } from 'react';

import Dropdown from '../Dropdown';

export default class FrequencyPicker extends Component {
  render() {
    return (
      <Dropdown
        {...this.props}
        options={[
          {
            label: 'Daily',
            value: 'daily'
          },
          {
            label: 'Weekly',
            value: 'weekly'
          },
          {
            label: 'Monthly',
            value: 'monthly'
          },
          /*{
            label: 'Quarterly',
            value: 'quarterly'
          },*/
          {
            label: 'Yearly',
            value: 'yearly'
          },
          /*{
            label: 'Week to Date',
            value: 'wtd'
          },
          {
            label: 'Month to Date',
            value: 'mtd'
          },
          {
            label: 'Quarter to Date',
            value: 'qtd'
          },
          {
            label: 'Year to Date',
            value: 'ytd'
          }*/
        ]}
      />
    );
  }
}
