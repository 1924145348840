import React, { Component } from 'react';

import { classnames } from '../../utils/classNamesHelper';

import styles from './progress.module.scss';

export class ProgressBar extends Component {
  render() {
    const bars = this.props.bars || [];
    const scale = this.props.scale || 1;

    return (
      <div className={classnames(this.props.className, styles.container)}>
        <div className={styles.bar}>
          {bars.map((bar, i) => {
            if (!bar.width) return <></>;

            return (
              <div
                key={i}
                className={classnames(styles.barPiece, bar.color)}
                style={{ width: `${(bar.width / scale) * 100}%` }}
              />
            );
          })}
        </div>
        {typeof this.props.complete === 'boolean' ? (
          this.props.complete ? (
            <img
              src={require('../../assets/images/CompleteStar.png')}
              alt="complete"
            />
          ) : (
            <img
              src={require('../../assets/images/IncompleteStar.png')}
              alt="incomplete"
            />
          )
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default ProgressBar;
