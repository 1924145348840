import {
  CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  CREATE_REPORT_RESPONSE,
  DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  FETCH_COMPANY_REPORTS_REQUEST,
  FETCH_COMPANY_REPORTS_RESPONSE,
  FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST,
  FETCH_INCIDENT_REPORT_RESPONSE,
  FETCH_LOCATION_REPORTS_REQUEST,
  FETCH_LOCATION_REPORTS_RESPONSE,
  FETCH_PROJECT_REPORTS_REQUEST,
  FETCH_PROJECT_REPORTS_RESPONSE,
  FETCH_REPORT_CIRCLES_RESPONSE,
  SET_ACTIVE_CUSTOM_REPORT_QUESTIONS,
  SET_ACTIVE_REPORT_REQUEST,
  SET_REPORT_TEMPLATES,
  UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  UPDATE_REPORT_RESPONSE
} from '../constants/actionTypes';

export const fetchCompanyReportsRequest = payload => ({
  type: FETCH_COMPANY_REPORTS_REQUEST,
  payload
});

export const fetchCompanyReportsResponse = payload => ({
  type: FETCH_COMPANY_REPORTS_RESPONSE,
  payload
});

export const fetchLocationReportsRequest = payload => ({
  type: FETCH_LOCATION_REPORTS_REQUEST,
  payload
});

export const fetchLocationReportsResponse = payload => ({
  type: FETCH_LOCATION_REPORTS_RESPONSE,
  payload
});

export const fetchProjectReportsRequest = payload => ({
  type: FETCH_PROJECT_REPORTS_REQUEST,
  payload
});

export const fetchProjectReportsResponse = payload => ({
  type: FETCH_PROJECT_REPORTS_RESPONSE,
  payload
});

export const fetchReportCirclesResponse = payload => ({
  type: FETCH_REPORT_CIRCLES_RESPONSE,
  payload
});

export const createReportResponse = payload => ({
  type: CREATE_REPORT_RESPONSE,
  payload
});

export const updateReportResponse = payload => ({
  type: UPDATE_REPORT_RESPONSE,
  payload
});

export const setActiveReportRequest = payload => ({
  type: SET_ACTIVE_REPORT_REQUEST,
  payload
});

export const setReportTemplate = payload => ({
  type: SET_REPORT_TEMPLATES,
  payload
});

export const fetchIncidentReportResponse = payload => ({
  type: FETCH_INCIDENT_REPORT_RESPONSE,
  payload
});

export const fetchCustomReportQuestionsRequest = payload => ({
  type: FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST,
  payload
});

export const setActiveCustomReportQuestions = payload => ({
  type: SET_ACTIVE_CUSTOM_REPORT_QUESTIONS,
  payload
});

export const createCustomReportQuestionsRequest = payload => ({
  type: CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  payload
});

export const updateCustomReportQuestionsRequest = payload => ({
  type: UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  payload
});

export const deleteCustomReportQuestionsRequest = payload => ({
  type: DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  payload
});
