import React, { Component } from 'react';
import {
  PROACTIVE_KEYS,
  PROACTIVE_REPORT_TYPES,
  REACTIVE_KEYS,
  REACTIVE_REPORT_TYPES
} from '../../../constants/constants';
import svgMapper from '../../../utils/svgMapper';

import './index.css';

class ReportSelector extends Component {
  handleSelectReport = selectedReport => {
    const { currentValue, onChange } = this.props;

    let newValue = [...currentValue];
    const where = currentValue.indexOf(selectedReport);

    if (where > -1) {
      newValue.splice(where, 1);
    } else {
      newValue.push(selectedReport);
    }

    onChange && onChange(newValue);
  };

  render() {
    let { accessLevel, currentValue, activeCompany } = this.props;

    accessLevel = parseInt(accessLevel, 10);
    if (!Array.isArray(currentValue)) {
      currentValue = currentValue ? [currentValue] : [];
    }

    const reportsToShow =
      activeCompany && activeCompany.showAircraft
        ? REACTIVE_REPORT_TYPES
        : REACTIVE_REPORT_TYPES.filter(
            reportType => reportType.value !== 6 && reportType.value !== 16
          );

    return (
      <div className="reportSelector">
        <h2 className="reportSelector-headers">Reports</h2>
        <div className="reportSelector-buttonArray">
          {reportsToShow.map((button, index) => (
            <button
              className={
                currentValue.indexOf(button.value) > -1
                  ? 'reportSelector-button activeButton'
                  : currentValue.filter(
                      chosen => PROACTIVE_KEYS.indexOf(chosen) > -1
                    ).length > 0
                  ? 'reportSelector-button disabledButton'
                  : (currentValue.length > 0 &&
                      (button.value === 8 || button.value === 10)) ||
                    (accessLevel === 100 &&
                      (button.value === 8 || button.value === 10))
                  ? 'reportSelector-button disabledButton'
                  : 'reportSelector-button'
              }
              key={index}
              onClick={() => this.handleSelectReport(button.value)}
            >
              <div
                className="reportSelector-button--buttonStuff"
                data-test={button.label}
              >
                {svgMapper(button.value)}
                <span className="reportSelector-button--text">
                  {button.label}
                </span>
              </div>
            </button>
          ))}
        </div>
        <h2 className="reportSelector-headers">Observations</h2>
        <div className="reportSelector-buttonArray">
          {PROACTIVE_REPORT_TYPES.map((button, index) => (
            <button
              className={
                currentValue.indexOf(button.value) > -1
                  ? 'reportSelector-button activeButton'
                  : currentValue.filter(
                      chosen => REACTIVE_KEYS.indexOf(chosen) > -1
                    ).length > 0
                  ? 'reportSelector-button disabledButton'
                  : (currentValue.length > 0 &&
                      (button.value === 8 || button.value === 10)) ||
                    (accessLevel === 100 && button.value === 8) ||
                    currentValue.indexOf(8) > -1 ||
                    currentValue.indexOf(10) > -1
                  ? 'reportSelector-button disabledButton'
                  : 'reportSelector-button'
              }
              key={index}
              onClick={() => this.handleSelectReport(button.value)}
            >
              <div
                className="reportSelector-button--buttonStuff"
                data-test={button.label}
              >
                {svgMapper(button.value)}
                <span className="reportSelector-button--text">
                  {activeCompany &&
                  activeCompany.isDash &&
                  button.label === 'Safety Walk'
                    ? 'DASH Report'
                    : button.label}
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default ReportSelector;
