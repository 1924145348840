import React, { Component, isValidElement } from 'react';

import { classnames } from '../../../utils/classNamesHelper';
import Input from '../';

import styles from './buttonSelector.module.scss';

export default class ButtonSelector extends Component {
  state = { selected: null };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ selected: this.props.value });
    }
  }

  render() {
    const {
      options,
      className,
      noSpacing,
      value,
      onClick,
      ...props
    } = this.props;
    const selected = options.find(o => o.value === value);
    let classes = [styles.container, className, noSpacing && styles.noSpacing];

    return (
      <Input className={classnames(classes)} {...props}>
        <div>
          {options.map(o => {
            if ('visible' in o && !o.visible) return <></>;
            let image = isValidElement(o.image) ? (
              o.image
            ) : (
              <img src={o.image} alt={o.text} />
            );
            let classes = [
              o.color || 'blue',
              o.value === value && styles.active
            ];
            return (
              <button
                key={o.value}
                className={classnames(classes)}
                onClick={e => {
                  e.stopPropagation();
                  this.setState({ selected: o.value });
                  if (onClick) onClick(o.value);
                }}
                disabled={o.disabled}
                type="button"
              >
                <div>
                  {image}
                  <span>{o.text}</span>
                </div>
              </button>
            );
          })}
          {selected?.description && (
            <span className={styles.bold}>{selected.description}</span>
          )}
        </div>
      </Input>
    );
  }
}
