import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as api from '../../../api';
import history from '../../../history';
import {
  setActiveTrainingResponse,
  deleteTrainingRequest
} from '../../../actions/training';
import { getCompanyUsersSelector } from '../../../selectors/personnel';
import { getActiveTrainingSelector } from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import AssigneeTable from '../../../components/training/AssigneeTable';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import FileDownloader from '../../../components/FileDownloader';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import QuizTable from '../../../components/training/QuizTable';
import SignatureCard from '../../../components/SignatureCard';
import TrainingTemplate from '../../../components/training/TrainingTemplate';

import styles from './styles.module.scss';

class TrainingSummary extends Component {
  state = {
    assigneeApproval: [],
    openDeleteConfirmationModal: false
  };

  componentDidMount() {
    const childAttachments = this.props.activeTraining.childAttachments;

    let assigneeApproval = [];

    childAttachments.forEach(attachment => {
      if (!assigneeApproval.some(aa => aa.assignee === attachment.assignedTo)) {
        assigneeApproval.push({
          assignee: attachment.assignedTo,
          approveReject: attachment.approveReject
        });
      }
    });

    this.setState({ assigneeApproval });
  }

  printTrainingSummary = async () => {
    const { activeTraining } = this.props;

    const url = await api.printTrainingSummary(activeTraining);

    window.open(url, '_blank');
  };

  handleBack = () => {
    this.props.setActiveTraining({});
    history.goBack();
  };

  handleDelete = () => {
    const { deleteTraining } = this.props;

    deleteTraining(this.props.activeTraining);
  };

  getQuickNav = () => {
    const { activeTraining } = this.props;

    let leftNav = [{ label: activeTraining.title, id: '0' }];

    if (activeTraining.childAttachments?.length) {
      leftNav = [...leftNav, { label: 'Material List', id: '1' }];
    }

    if (activeTraining.childQuizzes?.length) {
      leftNav = [...leftNav, { label: 'Quiz List', id: '2' }];
    }

    if (activeTraining.needsApproval && activeTraining.approverSignature) {
      leftNav = [...leftNav, { label: 'Signed and Approved', id: '3' }];
    }

    return leftNav;
  };

  render() {
    const { activeTraining, users } = this.props;
    const { assigneeApproval } = this.state;

    const header = (
      <Header
        title="Safety Training"
        section={activeTraining.title}
        clickBack={() => this.handleBack()}
        rightButtons={{
          text: 'Delete Training',
          onClick: () => this.setState({ openDeleteConfirmationModal: true }),
          color: 'red'
        }}
      />
    );

    const userDD = users.map(user => {
      return {
        value: user.userAccount._id,
        label: `${user.person.firstName} ${user.person.lastName}`
      };
    });

    return (
      <>
        <HeaderAndFooter
          Header={header}
          showFooter={false}
          Left={<LeftNav items={this.getQuickNav()} />}
        >
          <Card
            title={activeTraining.title}
            showHeader
            name="0"
            rightButton={{
              text: 'Print',
              onClick: () => this.printTrainingSummary(),
              color: 'blue'
            }}
          >
            <TrainingTemplate {...activeTraining} employeeDropdown={userDD} />
            {activeTraining.legacy && (
              <DatePicker
                fieldLabel="Expiration Date"
                pickTime
                currentValue={activeTraining.expirationDate}
                disabled
              />
            )}
            {!activeTraining.legacy &&
              !activeTraining.isCompleted &&
              activeTraining.dueDate &&
              moment().isBefore(activeTraining.dueDate) && (
                <DatePicker
                  fieldLabel="Due Date"
                  pickTime
                  currentValue={activeTraining.dueDate}
                  disabled
                />
              )}
            {!activeTraining.legacy && activeTraining.isCompleted && (
              <DatePicker
                fieldLabel="Completion Date"
                pickTime
                currentValue={activeTraining.completionDate}
                disabled
              />
            )}
            <div className={styles.attachmentContainer}>
              {activeTraining?.attachments?.map((attachment, index) => (
                <FileDownloader
                  attachment={attachment}
                  attachmentIndex={index}
                  disabled
                  key={index}
                  isInTraining
                />
              ))}
            </div>
          </Card>
          {activeTraining?.attachments?.length && !activeTraining.legacy ? (
            <Card title="Assignee(s) Material List" showHeader name="1">
              <AssigneeTable
                assignees={activeTraining.assignedTo}
                attachments={activeTraining.attachments}
                childAttachments={activeTraining.childAttachments}
                users={users}
                needsApproval={
                  activeTraining.needsApproval && !activeTraining?.quiz
                }
                assigneeApproval={assigneeApproval}
              />
            </Card>
          ) : null}
          {activeTraining?.quiz && (
            <Card title="Assignee(s) Quiz List" showHeader name="2">
              <QuizTable
                childQuizzes={activeTraining?.childQuizzes}
                setActiveTraining={values =>
                  this.props.setActiveTraining({
                    ...values,
                    isEditing: false,
                    isFromSummary: true,
                    masterTraining: activeTraining
                  })
                }
                users={users}
                assigneeApproval={assigneeApproval}
                needsApproval={activeTraining.needsApproval}
              />
            </Card>
          )}
          {activeTraining.needsApproval && activeTraining.approverSignature && (
            <SignatureCard
              header="Signed and Approved"
              dropdownName="Approver Name"
              users={userDD}
              userId={activeTraining.approver}
              name="3"
              currentValue={activeTraining?.approverSignature}
              userDisabled
              disabled
            />
          )}
        </HeaderAndFooter>
        <DeleteItemModal
          isOpen={this.state.openDeleteConfirmationModal}
          deletingWhat="Training"
          onRequestClose={() =>
            this.setState({ openDeleteConfirmationModal: false })
          }
          submitActions={() => this.handleDelete()}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTraining: getActiveTrainingSelector(state),
  users: getCompanyUsersSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  deleteTraining: payload => dispatch(deleteTrainingRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingSummary);
