import React from 'react';
import { REACTIVE_KEYS } from './constants';
import Analytics from '../containers/Analytics';
import ApproveTraining from '../containers/Training/ApproveTraining';
import CustomDocumentContainer from '../containers/CustomDocumentContainer';
import CustomDocumentList from '../containers/CustomDocumentList';
import CustomDocumentTemplateContainer from '../containers/CustomDocumentTemplateContainer';
import CustomDocumentTemplateList from '../containers/CustomDocumentTemplateList';
import CustomRaContainer from '../containers/CustomRaContainer';
import CustomRaList from '../containers/CustomRaList';
import CustomReportQuestionContainer from '../containers/CustomReportQuestionContainer';
import CustomReportQuestionsList from '../containers/CustomReportQuestionsList';
import CustomTrainingTemplates from '../containers/Training/CustomTemplates';
import Dashboard from '../containers/Dashboard';
import Documents from '../containers/Documents';
import HazardCard from '../containers/SafetyWalkContainer/HazardCard';
import IncidentContainer from '../containers/IncidentContainer';
import IncidentStages from '../containers/IncidentStages';
import InitalReportContainer from '../containers/InitialReportContainer';
import LeftNav from '../containers/LeftNav';
import MyAnalytics from '../containers/MyAnalytics';
import MyAnalyticsChart from '../containers/MyAnalytics/Chart';
import MyAnalyticsDashboard from '../containers/MyAnalytics/Dashboard';
import MyAnalyticsDetail from '../containers/MyAnalytics/Detail';
import MyDocumentContainer from '../containers/MyDocumentContainer';
import MyDocumentsStages from '../containers/MyDocumentsStages';
import OshaLog from '../containers/OshaLog';
import PhotoEvidenceContainer from '../containers/PhotoEvidenceContainer';
import PerformTraining from '../containers/Training/PerformTraining';
import Profile from '../containers/Profile';
import QuizList from '../containers/Quiz/QuizList';
import QuizTemplate from '../containers/Quiz/QuizTemplate';
import RaContainer from '../containers/RaContainer';
import RaStages from '../containers/RaStages';
import RaSummaryContainer from '../containers/RaSummaryContainer';
import RecurrenceContainer from '../containers/RecurrenceContainer';
import ReportSectionContainer from '../containers/ReportSectionContainer';
import ReportStages from '../containers/ReportStages';
import RequireAuthorizationList from '../containers/RequireAuthorizationList';
import SafetyWalkContainer from '../containers/SafetyWalkContainer';
import SafetyWalkStages from '../containers/SafetyWalkStages';
import SearchPage from '../containers/SearchPage';
import Settings from '../containers/Settings';
import TaskContainer from '../containers/TaskContainer';
import TaskStages from '../containers/TaskStages';
import TemplateList from '../containers/Training/TemplateList';
import TrainingAttachmentViewer from '../containers/Training/TrainingAttachmentViewer';
import TrainingContainer from '../containers/Training/TrainingContainer';
import TrainingList from '../containers/Training/TrainingList';
import TrainingQuizViewer from '../containers/Training/TrainingQuizViewer';
import TrainingSummary from '../containers/Training/TrainingSummary';
import TransitionContainer from '../containers/TransitionContainer';
import ViewEdit from '../containers/ViewEdit';
import ViewEditCompany from '../containers/ViewEditCompany';
import ViewEditEmployee from '../containers/ViewEditEmployee';
import ViewEditProfile from '../containers/ViewEditProfile';

const routes = [
  {
    path: '/app/analytics',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <Analytics match={match} />
  },
  {
    path: '/app/training/approveTraining',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <ApproveTraining match={match} />
  },
  {
    path: '/app/myAnalytics/card/:cardId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="0" />,
    main: (props, { match }) => <MyAnalyticsChart match={match} {...props} />
  },
  {
    path: '/app/myAnalytics/dashboard/:dashboardId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="0" />,
    main: (props, { match }) => (
      <MyAnalyticsDashboard match={match} {...props} />
    )
  },
  {
    path: '/app/myAnalytics/detail/:chartId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="0" />,
    main: (props, { match }) => <MyAnalyticsDetail match={match} {...props} />
  },
  {
    path: '/app/myAnalytics',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <MyAnalytics match={match} />
  },
  {
    path: '/app/CustomDocumentContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <CustomDocumentContainer match={match} />
  },
  {
    path: '/app/CustomDocumentList',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="4" />,
    main: ({ match }) => <CustomDocumentList match={match} />
  },
  {
    path: '/app/CustomDocumentTemplateContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="3" />,
    main: ({ match }) => <CustomDocumentTemplateContainer match={match} />
  },
  {
    path: '/app/CustomDocumentTemplateList',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="3" />,
    main: ({ match }) => <CustomDocumentTemplateList match={match} />
  },
  {
    path: '/app/customRaContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="3" />,
    main: ({ match }) => <CustomRaContainer match={match} />
  },
  {
    path: '/app/customRaList',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="3" />,
    main: ({ match }) => <CustomRaList match={match} />
  },
  {
    path: '/app/customReportQuestionsList',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="3" />,
    main: ({ match }) => <CustomReportQuestionsList match={match} />
  },
  {
    path: '/app/customReportQuestionContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="3" />,
    main: ({ match }) => <CustomReportQuestionContainer match={match} />
  },
  {
    path: '/app/training/customTrainingTemplate',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="3" />,
    main: ({ match }) => <CustomTrainingTemplates match={match} />
  },
  {
    path: '/app/dashboard',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <Dashboard match={match} />
  },
  {
    path: '/app/documents/:documentType/:qualificationType?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="4" />,
    main: ({ match }) => <Documents match={match} />
  },
  {
    path: '/app/safetyWalkContainer/hazardCard/:hazardIndex?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="2" />,
    main: ({ match }) => <HazardCard match={match} />
  },
  {
    path: '/app/incidentContainer/:stage?/:incidentId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="0" />,
    main: ({ match }) => <IncidentContainer match={match} />
  },
  {
    path: '/app/incidents',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <IncidentStages match={match} />
  },
  {
    path: '/app/initalReportContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="0" />,
    main: ({ match }) => <InitalReportContainer match={match} />
  },
  {
    path: '/app/myDocumentContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <MyDocumentContainer match={match} />
  },
  {
    path: '/app/myDocumentsStages',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="4" />,
    main: ({ match }) => <MyDocumentsStages match={match} />
  },
  {
    path: '/app/oshaLog/:year?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <OshaLog match={match} />
  },
  {
    path: '/app/photoEvidenceContainer/:isPhotoEvidence?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="0" />,
    main: ({ match }) => <PhotoEvidenceContainer match={match} />
  },
  {
    path: '/app/training/performTraining',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <PerformTraining match={match} />
  },
  {
    path: '/app/profile',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <Profile match={match} />
  },
  {
    path: '/app/quiz/quizList',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="3" />,
    main: ({ match }) => <QuizList match={match} />
  },
  {
    path: '/app/quiz/quizTemplate/:quizTemplateId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="3" />,
    main: (props, { match }) => <QuizTemplate match={match} {...props} />
  },
  {
    path: '/app/raContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="2" />,
    main: ({ match }) => <RaContainer match={match} />
  },
  {
    path: '/app/raStages',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="2" />,
    main: ({ match }) => <RaStages match={match} />
  },
  {
    path: '/app/raSummaryContainer/:raId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="2" />,
    main: ({ match }) => <RaSummaryContainer match={match} />
  },
  {
    path: '/app/recurrenceContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <RecurrenceContainer match={match} />
  },
  {
    path: '/app/reportSectionContainer/:stage?/:taskId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="0" />,
    main: ({ match }) => <ReportSectionContainer match={match} />
  },
  {
    path: '/app/reportStages/:reportType/:templateId',
    exact: true,
    sidebar: ({ match }) => (
      <LeftNav
        showLeftNav={true}
        sectionOpen={
          REACTIVE_KEYS.indexOf(parseInt(match.params.templateId, 10)) > -1
            ? '1'
            : '2'
        }
      />
    ),
    main: ({ match }) => <ReportStages match={match} />
  },
  {
    path: '/app/requireAuthList',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="4" />,
    main: ({ match }) => <RequireAuthorizationList match={match} />
  },
  {
    path: '/app/safetyWalkContainer/:safetyWalkId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="2" />,
    main: ({ match }) => <SafetyWalkContainer match={match} />
  },
  {
    path: '/app/safetyWalkStages/',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="2" />,
    main: ({ match }) => <SafetyWalkStages match={match} />
  },
  {
    path: '/app/searchPage',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <SearchPage match={match} />
  },
  {
    path: '/app/settings/:settingType/:openEditUser?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="5" />,
    main: ({ match }) => <Settings match={match} />
  },
  {
    path: '/app/taskContainer/:stage?/:taskId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="0" />,
    main: (props, { match }) => <TaskContainer match={match} {...props} />
  },
  {
    path: '/app/tasks',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <TaskStages match={match} />
  },
  {
    path: '/app/training/templateList',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="3" />,
    main: ({ match }) => <TemplateList match={match} />
  },
  {
    path: '/app/training/trainingAttachmentViewer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <TrainingAttachmentViewer match={match} />
  },
  {
    path: '/app/training/trainingContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <TrainingContainer match={match} />
  },
  {
    path: '/app/training/trainingList',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="4" />,
    main: ({ match }) => <TrainingList match={match} />
  },
  {
    path: '/app/training/trainingQuizViewer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <TrainingQuizViewer match={match} />
  },
  {
    path: '/app/training/trainingSummary',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <TrainingSummary match={match} />
  },
  {
    path: '/app/transitionContainer',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="4" />,
    main: ({ match }) => <TransitionContainer match={match} />
  },
  {
    path: '/app/ViewEdit/:editType/:docId?',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="4" />,
    main: ({ match }) => <ViewEdit match={match} />
  },
  {
    path: '/app/viewEditCompany',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="5" />,
    main: ({ match }) => <ViewEditCompany match={match} />
  },
  {
    path: '/app/viewEditPersonnel/:editType',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={false} sectionOpen="5" />,
    main: ({ match }) => <ViewEditEmployee match={match} />
  },
  {
    path: '/app/ViewEditProfile/:editType',
    exact: true,
    sidebar: () => <LeftNav showLeftNav={true} sectionOpen="0" />,
    main: ({ match }) => <ViewEditProfile match={match} />
  }
];

export default routes;
