import { createSelector } from 'reselect';

const documentsSelector = state => state.documents;

export const getCompanySafetyInstructionsSelector = createSelector(
  documentsSelector,
  documents => documents && documents.safetyInstructions
);

export const getCompanySdsSelector = createSelector(
  documentsSelector,
  documents => documents && documents.sds
);

export const getCompanyDailyReportSelector = createSelector(
  documentsSelector,
  documents => documents && documents.dailyReports
);

export const getCompanyLrrSelector = createSelector(
  documentsSelector,
  documents => documents && documents.lossRun
);

export const getCompanyToolboxTalksSelector = createSelector(
  documentsSelector,
  documents => documents && documents.toolboxTalks
);

export const getCompanyQualificationsSelector = createSelector(
  documentsSelector,
  documents => documents && documents.qualifications
);

export const getDocumentLoadingSelector = createSelector(
  documentsSelector,
  document => document && document.loading
);

export const getActiveSdsSelector = createSelector(
  documentsSelector,
  document => document && document.activeSds
);

export const getActiveSafetyInstructionSelector = createSelector(
  documentsSelector,
  document => document && document.activeSafetyInstruction
);

export const getActiveDailyReportSelector = createSelector(
  documentsSelector,
  document => document && document.activeDailyReport
);

export const getActiveToolboxTalkSelector = createSelector(
  documentsSelector,
  document => document && document.activeToolboxTalk
);

export const getActiveQualificationSelector = createSelector(
  documentsSelector,
  document => document && document.activeQualification
);

export const getQualificationTypesSelector = createSelector(
  documentsSelector,
  document => document && document.qualificationTypes
);

export const getDocumentFoldersSelector = createSelector(
  documentsSelector,
  document => document && document.documentFolders
);

export const getActiveFolderSelector = createSelector(
  documentsSelector,
  document => document && document.activeDocumentFolder
);

export const getMyDocumentsSelector = createSelector(
  documentsSelector,
  documents => documents && documents.myDocsList
);

export const getActiveMyDocumentSelector = createSelector(
  documentsSelector,
  documents => documents && documents.activeMyDocument
);

export const getRequireAuthDocsSelector = createSelector(
  documentsSelector,
  documents => documents && documents.requireAuthDocs
);

export const getCustomDocumentsSelector = createSelector(
  documentsSelector,
  documents => documents && documents.customDocuments
);

export const getActiveCustomDocSelector = createSelector(
  documentsSelector,
  documents => documents && documents.activeCustomDocument
);

export const getCustomDocumentTemplatesSelector = createSelector(
  documentsSelector,
  documents => documents && documents.customDocumentTemplates
);

export const getActiveCustomDocTemplateSelector = createSelector(
  documentsSelector,
  documents => documents && documents.activeCustomDocumentTemplate
);
