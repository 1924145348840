import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Button from '../inputs/Button';

import './index.css';

export default class FileCropper extends Component {
  state = {
    percent: 0,
    imageFiles: []
  };

  onDrop = files => {
    if (
      files[0].name.indexOf('.png') === -1 &&
      files[0].name.indexOf('.jpg') === -1 &&
      files[0].name.indexOf('.jpeg') === -1
    ) {
      alert('File must be a .png or .jpg file');

      return;
    }

    let data = new FormData();
    files.forEach(file => {
      data.append('attachments', file, file.name);
    });

    this.props.addAttachment({
      data,
      ownerId: '1234',
      ownerType: 'companyLogo'
    });
  };

  render() {
    return (
      <div>
        <label className="label">{this.props.label}</label>
        <div className="dropzone">
          <Dropzone
            onDrop={this.onDrop}
            className="dropzone-box"
            accept={['image/png', 'image/jpeg', 'image/jpg']}
          >
            <div className="dropzone-middle">
              <Button
                text="Upload Logo"
                type="button"
                className="update"
              />
              <div className="dropzone-text"> PNG or JPG</div>
            </div>
          </Dropzone>
        </div>
      </div>
    );
  }
}
