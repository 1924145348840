import {
  ADD_DAILY_REPORTS_REQUEST,
  ADD_DAILY_REPORTS_RESPONSE,
  ADD_DOCUMENT_FOLDER_REQUEST,
  ADD_DOCUMENT_FOLDER_RESPONSE,
  ADD_DOCUMENTS_TO_FOLDER_REQUEST,
  ADD_DOCUMENTS_TO_FOLDER_RESPONSE,
  ADD_QUALIFICATION_REQUEST,
  ADD_QUALIFICATION_RESPONSE,
  ADD_SAFETY_INSTRUCTION_REQUEST,
  ADD_SAFETY_INSTRUCTION_RESPONSE,
  ADD_SDS_REQUEST,
  ADD_SDS_RESPONSE,
  ADD_TOOLBOX_TALKS_REQUEST,
  ADD_TOOLBOX_TALKS_RESPONSE,
  CREATE_CONTINUOUS_CARE_REQUEST,
  CREATE_CUSTOM_DOCUMENT_REQUEST,
  CREATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
  CREATE_MY_DOCUMENT_REQUEST,
  DELETE_CONTINUOUS_CARE_REQUEST,
  DELETE_CUSTOM_DOCUMENT_ATTACHMENT_REQUEST,
  DELETE_CUSTOM_DOCUMENT_REQUEST,
  DELETE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
  DELETE_DAILY_REPORT_ATTACHMENT,
  DELETE_DAILY_REPORTS_REQUEST,
  DELETE_DAILY_REPORTS_RESPONSE,
  DELETE_DOCUMENT_FOLDER_REQUEST,
  DELETE_DOCUMENT_FOLDER_RESPONSE,
  DELETE_MY_DOCUMENT_REQUEST,
  DELETE_QUALIFICATION_ATTACHMENT,
  DELETE_QUALIFICATION_REQUEST,
  DELETE_QUALIFICATION_RESPONSE,
  DELETE_SAFETY_INSTRUCTION_ATTACHMENT,
  DELETE_SAFETY_INSTRUCTION_REQUEST,
  DELETE_SAFETY_INSTRUCTION_RESPONSE,
  DELETE_SDS_ATTACHMENT,
  DELETE_SDS_REQUEST,
  DELETE_SDS_RESPONSE,
  DELETE_TOOLBOX_TALK_ATTACHMENT,
  DELETE_TOOLBOX_TALKS_REQUEST,
  DELETE_TOOLBOX_TALKS_RESPONSE,
  FETCH_CUSTOM_DOCUMENTS_REQUEST,
  FETCH_CUSTOM_DOCUMENTS_RESPONSE,
  FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST,
  FETCH_CUSTOM_DOCUMENTS_TEMPLATE_RESPONSE,
  FETCH_DAILY_REPORTS_REQUEST,
  FETCH_DAILY_REPORTS_RESPONSE,
  FETCH_DOCUMENT_FOLDERS_REQUEST,
  FETCH_DOCUMENT_FOLDERS_RESPONSE,
  FETCH_MY_DOCUMENTS_REQUEST,
  FETCH_MY_DOCUMENTS_RESPONSE,
  FETCH_QUALIFICATION_REQUEST,
  FETCH_QUALIFICATION_RESPONSE,
  FETCH_QUALIFICATION_TYPES_REQUEST,
  FETCH_QUALIFICATION_TYPES_RESPONSE,
  FETCH_REQUIRE_AUTHORIZATION_REQUEST,
  FETCH_REQUIRE_AUTHORIZATION_RESPONSE,
  FETCH_SAFETY_INSTRUCTION_REQUEST,
  FETCH_SAFETY_INSTRUCTION_RESPONSE,
  FETCH_SDS_REQUEST,
  FETCH_SDS_RESPONSE,
  FETCH_TOOLBOX_TALKS_REQUEST,
  FETCH_TOOLBOX_TALKS_RESPONSE,
  SET_ACTIVE_CUSTOM_DOCUMENT,
  SET_ACTIVE_CUSTOM_DOCUMENT_TEMPLATE,
  SET_ACTIVE_DAILY_REPORT,
  SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
  SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE,
  SET_ACTIVE_MY_DOCUMENT,
  SET_ACTIVE_QUALIFICATION,
  SET_ACTIVE_SAFETY_INSTRUCTION,
  SET_ACTIVE_SDS,
  SET_ACTIVE_TOOLBOX_TALK,
  UPDATE_CONTINUOUS_CARE_REQUEST,
  UPDATE_CUSTOM_DOCUMENT_REQUEST,
  UPDATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
  UPDATE_DAILY_REPORTS_REQUEST,
  UPDATE_DAILY_REPORTS_RESPONSE,
  UPDATE_DOCUMENT_FOLDER_REQUEST,
  UPDATE_DOCUMENT_FOLDER_RESPONSE,
  UPDATE_MY_DOCUMENT_REQUEST,
  UPDATE_QUALIFICATION_REQUEST,
  UPDATE_QUALIFICATION_RESPONSE,
  UPDATE_SAFETY_INSTRUCTION_REQUEST,
  UPDATE_SAFETY_INSTRUCTION_RESPONSE,
  UPDATE_SDS_REQUEST,
  UPDATE_SDS_RESPONSE,
  UPDATE_TOOLBOX_TALKS_REQUEST,
  UPDATE_TOOLBOX_TALKS_RESPONSE
} from '../constants/actionTypes';

export const fetchSafetyInstructionRequest = payload => ({
  type: FETCH_SAFETY_INSTRUCTION_REQUEST,
  payload
});

export const fetchSafetyInstructionResponse = payload => ({
  type: FETCH_SAFETY_INSTRUCTION_RESPONSE,
  payload
});

export const fetchSdsRequest = payload => ({
  type: FETCH_SDS_REQUEST,
  payload
});

export const fetchSdsResponse = payload => ({
  type: FETCH_SDS_RESPONSE,
  payload
});

export const setActiveSds = payload => ({
  type: SET_ACTIVE_SDS,
  payload
});

export const addSdsRequest = payload => ({
  type: ADD_SDS_REQUEST,
  payload
});

export const addSdsResponse = payload => ({
  type: ADD_SDS_RESPONSE,
  payload
});

export const updateSdsRequest = payload => ({
  type: UPDATE_SDS_REQUEST,
  payload
});

export const updateSdsResponse = payload => ({
  type: UPDATE_SDS_RESPONSE,
  payload
});

export const deleteSdsRequest = payload => ({
  type: DELETE_SDS_REQUEST,
  payload
});

export const deleteSdsResponse = payload => ({
  type: DELETE_SDS_RESPONSE,
  payload
});

export const setActiveSafetyInstruction = payload => ({
  type: SET_ACTIVE_SAFETY_INSTRUCTION,
  payload
});

export const addSafetyInstructionRequest = payload => ({
  type: ADD_SAFETY_INSTRUCTION_REQUEST,
  payload
});

export const addSafetyInstructionResponse = payload => ({
  type: ADD_SAFETY_INSTRUCTION_RESPONSE,
  payload
});

export const updateSafetyInstructionRequest = payload => ({
  type: UPDATE_SAFETY_INSTRUCTION_REQUEST,
  payload
});

export const updateSafetyInstructionResponse = payload => ({
  type: UPDATE_SAFETY_INSTRUCTION_RESPONSE,
  payload
});

export const deleteSafetyInstructionRequest = payload => ({
  type: DELETE_SAFETY_INSTRUCTION_REQUEST,
  payload
});

export const deleteSafetyInstructionResponse = payload => ({
  type: DELETE_SAFETY_INSTRUCTION_RESPONSE,
  payload
});

export const deleteSafetyInstructionAttachment = payload => ({
  type: DELETE_SAFETY_INSTRUCTION_ATTACHMENT,
  payload
});

export const deleteSdsAttachment = payload => ({
  type: DELETE_SDS_ATTACHMENT,
  payload
});

export const fetchDailyReportsRequest = payload => ({
  type: FETCH_DAILY_REPORTS_REQUEST,
  payload
});

export const fetchDailyReportsResponse = payload => ({
  type: FETCH_DAILY_REPORTS_RESPONSE,
  payload
});

export const addDailyReportsRequest = payload => ({
  type: ADD_DAILY_REPORTS_REQUEST,
  payload
});

export const addDailyReportsResponse = payload => ({
  type: ADD_DAILY_REPORTS_RESPONSE,
  payload
});

export const updateDailyReportsRequest = payload => ({
  type: UPDATE_DAILY_REPORTS_REQUEST,
  payload
});

export const updateDailyReportsResponse = payload => ({
  type: UPDATE_DAILY_REPORTS_RESPONSE,
  payload
});

export const deleteDailyReportsRequest = payload => ({
  type: DELETE_DAILY_REPORTS_REQUEST,
  payload
});

export const deleteDailyReportsResponse = payload => ({
  type: DELETE_DAILY_REPORTS_RESPONSE,
  payload
});

export const setActiveDailyReport = payload => ({
  type: SET_ACTIVE_DAILY_REPORT,
  payload
});

export const fetchToolboxTalksRequest = payload => ({
  type: FETCH_TOOLBOX_TALKS_REQUEST,
  payload
});

export const fetchToolboxTalksResponse = payload => ({
  type: FETCH_TOOLBOX_TALKS_RESPONSE,
  payload
});

export const addToolboxTalksRequest = payload => ({
  type: ADD_TOOLBOX_TALKS_REQUEST,
  payload
});

export const addToolboxTalksResponse = payload => ({
  type: ADD_TOOLBOX_TALKS_RESPONSE,
  payload
});

export const updateToolboxTalksRequest = payload => ({
  type: UPDATE_TOOLBOX_TALKS_REQUEST,
  payload
});

export const updateToolboxTalksResponse = payload => ({
  type: UPDATE_TOOLBOX_TALKS_RESPONSE,
  payload
});

export const deleteToolboxTalksRequest = payload => ({
  type: DELETE_TOOLBOX_TALKS_REQUEST,
  payload
});

export const deleteToolboxTalksResponse = payload => ({
  type: DELETE_TOOLBOX_TALKS_RESPONSE,
  payload
});

export const setActiveToolboxTalks = payload => ({
  type: SET_ACTIVE_TOOLBOX_TALK,
  payload
});

export const deleteDailyReportAttachment = payload => ({
  type: DELETE_DAILY_REPORT_ATTACHMENT,
  payload
});

export const deleteToolboxTalkAttachment = payload => ({
  type: DELETE_TOOLBOX_TALK_ATTACHMENT,
  payload
});

export const deleteQualificationAttachment = payload => ({
  type: DELETE_QUALIFICATION_ATTACHMENT,
  payload
});

export const fetchQualificationRequest = payload => ({
  type: FETCH_QUALIFICATION_REQUEST,
  payload
});

export const fetchQualificationResponse = payload => ({
  type: FETCH_QUALIFICATION_RESPONSE,
  payload
});

export const addQualificationRequest = payload => ({
  type: ADD_QUALIFICATION_REQUEST,
  payload
});

export const addQualificationResponse = payload => ({
  type: ADD_QUALIFICATION_RESPONSE,
  payload
});

export const updateQualificationRequest = payload => ({
  type: UPDATE_QUALIFICATION_REQUEST,
  payload
});

export const updateQualificationResponse = payload => ({
  type: UPDATE_QUALIFICATION_RESPONSE,
  payload
});
export const deleteQualificationRequest = payload => ({
  type: DELETE_QUALIFICATION_REQUEST,
  payload
});

export const deleteQualificationResponse = payload => ({
  type: DELETE_QUALIFICATION_RESPONSE,
  payload
});

export const setActiveQualification = payload => ({
  type: SET_ACTIVE_QUALIFICATION,
  payload
});

export const fetchQualificationTypeRequest = payload => ({
  type: FETCH_QUALIFICATION_TYPES_REQUEST,
  payload
});

export const fetchQualificationTypeResponse = payload => ({
  type: FETCH_QUALIFICATION_TYPES_RESPONSE,
  payload
});

export const fetchDocumentFoldersRequest = payload => ({
  type: FETCH_DOCUMENT_FOLDERS_REQUEST,
  payload
});

export const fetchDocumentFoldersResponse = payload => ({
  type: FETCH_DOCUMENT_FOLDERS_RESPONSE,
  payload
});

export const addDocumentFolderRequest = payload => ({
  type: ADD_DOCUMENT_FOLDER_REQUEST,
  payload
});

export const addDocumentFolderResponse = payload => ({
  type: ADD_DOCUMENT_FOLDER_RESPONSE,
  payload
});

export const updateDocumentFolderRequest = payload => ({
  type: UPDATE_DOCUMENT_FOLDER_REQUEST,
  payload
});

export const updateDocumentFolderResponse = payload => ({
  type: UPDATE_DOCUMENT_FOLDER_RESPONSE,
  payload
});

export const deleteDocumentFolderRequest = payload => ({
  type: DELETE_DOCUMENT_FOLDER_REQUEST,
  payload
});

export const deleteDocumentFolderResponse = payload => ({
  type: DELETE_DOCUMENT_FOLDER_RESPONSE,
  payload
});

export const setActiveDocumentFolderRequest = payload => ({
  type: SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
  payload
});

export const setActiveDocumentFolderResponse = payload => ({
  type: SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE,
  payload
});

export const addDocumentsToFolderRequest = payload => ({
  type: ADD_DOCUMENTS_TO_FOLDER_REQUEST,
  payload
});

export const addDocumentsToFolderResponse = payload => ({
  type: ADD_DOCUMENTS_TO_FOLDER_RESPONSE,
  payload
});

export const fetchMyDocumentsRequest = payload => ({
  type: FETCH_MY_DOCUMENTS_REQUEST,
  payload
});

export const fetchMyDocumentsResponse = payload => ({
  type: FETCH_MY_DOCUMENTS_RESPONSE,
  payload
});

export const setActiveMyDocument = payload => ({
  type: SET_ACTIVE_MY_DOCUMENT,
  payload
});

export const createMyDocumentRequest = payload => ({
  type: CREATE_MY_DOCUMENT_REQUEST,
  payload
});

export const updateMyDocumentRequest = payload => ({
  type: UPDATE_MY_DOCUMENT_REQUEST,
  payload
});

export const deleteMyDocumentRequest = payload => ({
  type: DELETE_MY_DOCUMENT_REQUEST,
  payload
});

export const createContinuousRequest = payload => ({
  type: CREATE_CONTINUOUS_CARE_REQUEST,
  payload
});

export const updateContinuousRequest = payload => ({
  type: UPDATE_CONTINUOUS_CARE_REQUEST,
  payload
});

export const deleteContinuousRequest = payload => ({
  type: DELETE_CONTINUOUS_CARE_REQUEST,
  payload
});

export const fetchRequireAuthRequest = payload => ({
  type: FETCH_REQUIRE_AUTHORIZATION_REQUEST,
  payload
});

export const fetchRequireAuthResponse = payload => ({
  type: FETCH_REQUIRE_AUTHORIZATION_RESPONSE,
  payload
});

export const fetchCustomDocumentsRequest = payload => ({
  type: FETCH_CUSTOM_DOCUMENTS_REQUEST,
  payload
});

export const fetchCustomDocumentsResponse = payload => ({
  type: FETCH_CUSTOM_DOCUMENTS_RESPONSE,
  payload
});

export const setActiveCustomDocument = payload => ({
  type: SET_ACTIVE_CUSTOM_DOCUMENT,
  payload
});

export const createCustomDocumentRequest = payload => ({
  type: CREATE_CUSTOM_DOCUMENT_REQUEST,
  payload
});

export const updateCustomDocumentRequest = payload => ({
  type: UPDATE_CUSTOM_DOCUMENT_REQUEST,
  payload
});

export const deleteCustomDocumentRequest = payload => ({
  type: DELETE_CUSTOM_DOCUMENT_REQUEST,
  payload
});

export const deleteCustomDocumentAttachmentRequest = payload => ({
  type: DELETE_CUSTOM_DOCUMENT_ATTACHMENT_REQUEST,
  payload
});

export const fetchCustomDocumentTemplatesRequest = payload => ({
  type: FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST,
  payload
});

export const fetchCustomDocumentTemplatesResponse = payload => ({
  type: FETCH_CUSTOM_DOCUMENTS_TEMPLATE_RESPONSE,
  payload
});

export const setActiveCustomDocumentTemplate = payload => ({
  type: SET_ACTIVE_CUSTOM_DOCUMENT_TEMPLATE,
  payload
});

export const createCustomDocumentTemplateRequest = payload => ({
  type: CREATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
  payload
});

export const updateCustomDocumentTemplateRequest = payload => ({
  type: UPDATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
  payload
});

export const deleteCustomDocumentTemplateRequest = payload => ({
  type: DELETE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
  payload
});
