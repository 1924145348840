import {
  ADD_INCIDENT_ATTACHMENTS_REQUEST,
  ADD_INCIDENT_ATTACHMENTS_RESPONSE,
  ADD_INCIDENT_COMMENTS_REQUEST,
  ADD_INCIDENT_NOTES_REQUEST,
  DELETE_INCIDENT_REQUEST,
  EMAIL_INCIDENT_ASSIGNEE_REQUEST,
  EMAIL_INCIDENT_ASSIGNEE_RESPONSE,
  FETCH_COMPANY_CIRCLES_RESPONSE,
  FETCH_COMPANY_INCIDENTS_REQUEST,
  FETCH_COMPANY_INCIDENTS_RESPONSE,
  FETCH_INCIDENT_ATTACHMENTS_REQUEST,
  FETCH_INCIDENT_ATTACHMENTS_RESPONSE,
  FETCH_INCIDENT_BASICS_REQUEST,
  FETCH_INCIDENT_BASICS_RESPONSE,
  FETCH_INCIDENT_BY_ID_REQUEST,
  RECATAGORIZE_REPORTS_REQUEST,
  SET_ACTIVE_INCIDENT_REQUEST,
  SET_ACTIVE_INCIDENT_RESPONSE,
  UPDATE_INCIDENT_COMMENTS_REQUEST,
  UPDATE_INCIDENT_NOTES_REQUEST,
  UPDATE_INCIDENT_BASICS_REQUEST,
  UPDATE_INCIDENT_BASICS_RESPONSE,
  UPDATE_INCIDENT_REQUEST,
  UPDATE_INCIDENT_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  list: [],
  circles: [],
  incidentBasics: [],
  activeIncident: {},
  attachments: []
};

const incidents = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMPANY_INCIDENTS_REQUEST:
    case FETCH_INCIDENT_BASICS_REQUEST:
    case UPDATE_INCIDENT_BASICS_REQUEST:
    case UPDATE_INCIDENT_REQUEST:
    case SET_ACTIVE_INCIDENT_REQUEST:
    case ADD_INCIDENT_COMMENTS_REQUEST:
    case ADD_INCIDENT_NOTES_REQUEST:
    case FETCH_INCIDENT_ATTACHMENTS_REQUEST:
    case ADD_INCIDENT_ATTACHMENTS_REQUEST:
    case DELETE_INCIDENT_REQUEST:
    case EMAIL_INCIDENT_ASSIGNEE_REQUEST:
    case UPDATE_INCIDENT_COMMENTS_REQUEST:
    case UPDATE_INCIDENT_NOTES_REQUEST:
    case FETCH_INCIDENT_BY_ID_REQUEST:
    case RECATAGORIZE_REPORTS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FETCH_COMPANY_INCIDENTS_RESPONSE:
      return {
        ...state,
        ...payload
      };

    case FETCH_COMPANY_CIRCLES_RESPONSE:
      return {
        ...state,
        loading: false,
        circles: payload
      };

    case FETCH_INCIDENT_BASICS_RESPONSE:
      return {
        ...state,
        loading: false,
        incidentBasics: payload
      };

    case UPDATE_INCIDENT_BASICS_RESPONSE:
      let id = payload.id;
      delete payload.id;

      let activeIncident = {
        ...state.activeIncident,
        ...payload
      };

      const list = state.list.map(incident => {
        if (id === incident._id) return {...activeIncident};
        return incident;
      });

      return {
        ...state,
        loading: false,
        activeIncident,
        list
      };

    case UPDATE_INCIDENT_RESPONSE:
      const incidentList = state.list.map(incident => {
        if (payload._id === incident._id) {
          return {
            ...payload
          };
        }

        return incident;
      });

      return {
        ...state,
        loading: false,
        activeIncident: payload,
        list: incidentList
      };

    case SET_ACTIVE_INCIDENT_RESPONSE:
      return {
        ...state,
        loading: false,
        activeIncident: payload
      };

    case FETCH_INCIDENT_ATTACHMENTS_RESPONSE:
      return {
        ...state,
        attachments: payload
      };

    case ADD_INCIDENT_ATTACHMENTS_RESPONSE: {
      const attachmentList = [...state.attachments];

      if (payload && payload[0]._id) {
        return {
          ...state,
          attachments: [...state.attachments, ...payload]
        };
      }

      return {
        ...state,
        attachments: [...attachmentList]
      };
    }

    case EMAIL_INCIDENT_ASSIGNEE_RESPONSE:
      return {
        ...state,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};

export default incidents;
