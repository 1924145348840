import React, { Component } from 'react';
import Button from '../inputs/Button';
import DeleteFolderModal from '../Modal/deleteFolderModal';
import Textbox from '../inputs/Textbox';

import documentFolderStyles from './styles.module.scss';

export default class DocumentFolder extends Component {
  state = {
    editFolderName: false,
    openDeleteModal: false,
    folderName: ''
  };

  handleFolderUpdate = () => {
    this.props.updateDocumentFolder({
      ...this.props.activeFolder,
      name: this.state.folderName
    });

    this.setState({ editFolderName: '', folderName: '' });
  };

  handleFolderDelete = deleteAll => {
    this.props.deleteDocumentFolder({
      ...this.props.activeFolder,
      deleteAll,
      documents: this.props.content
    });

    this.setState({ openDeleteModal: false });
  };

  render() {
    const { activeFolder, accessLevel } = this.props;

    const { editFolderName, openDeleteModal, folderName } = this.state;

    return (
      <div className={documentFolderStyles.folder}>
        {editFolderName ? (
          <div className={documentFolderStyles.editFolderWrapper}>
            <span>Old Folder Name: {activeFolder.name}</span>{' '}
            <div className={documentFolderStyles.editFolder}>
              <Textbox
                handleChange={e =>
                  this.setState({ folderName: e.target.value })
                }
                fieldLabel="New Folder Name"
                currentValue={folderName}
              />
              <Button
                color="blue"
                text="Submit Folder Name"
                onClick={() => this.handleFolderUpdate()}
                disabled={folderName.length < 3}
                inputClassName={documentFolderStyles.editFolderButton}
              />
              <Button
                color="white"
                text="Cancel"
                onClick={() => this.setState({ editFolderName: false })}
                inputClassName={documentFolderStyles.editFolderButton}
              />
            </div>
          </div>
        ) : (
          <div className={documentFolderStyles.folderHeader}>
            <span>{activeFolder.name}</span>{' '}
            {this.props.updateDocumentFolder && accessLevel !== '100' && (
              <img
                src={require('../../assets/images/Pen.png')}
                alt="edit folder name"
                onClick={() => this.setState({ editFolderName: true })}
                className={documentFolderStyles.editIcon}
              />
            )}
            {this.props.deleteDocumentFolder && accessLevel !== '100' && (
              <img
                src={require('../../assets/images/actionClose.png')}
                alt="delete folder name"
                onClick={() => this.setState({ openDeleteModal: true })}
                className={documentFolderStyles.deleteIcon}
              />
            )}
          </div>
        )}
        <DeleteFolderModal
          isOpen={openDeleteModal}
          folderName={folderName}
          onRequestClose={() => this.setState({ openDeleteModal: false })}
          submitActions={() => this.handleFolderDelete(false)}
          deleteActions={() => this.handleFolderDelete(true)}
        />
      </div>
    );
  }
}
