import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import moment from 'moment';

import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  deleteDailyReportAttachment,
  deleteDailyReportsRequest,
  deleteSafetyInstructionAttachment,
  deleteSafetyInstructionRequest,
  deleteSdsAttachment,
  deleteSdsRequest,
  deleteToolboxTalkAttachment,
  deleteToolboxTalksRequest,
  updateDailyReportsRequest,
  updateSafetyInstructionRequest,
  updateSdsRequest,
  updateToolboxTalksRequest
} from '../../actions/documents';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  fetchAllActiveEmployees,
  fetchAllActiveEmployeesActiveUsers
} from '../../actions/personnel';
import { setActiveTaskRequest } from '../../actions/tasks';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getCompanyLocationsSelector
} from '../../selectors/company';
import {
  getActiveDailyReportSelector,
  getActiveSafetyInstructionSelector,
  getActiveSdsSelector,
  getActiveToolboxTalkSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';
import { getMessageSelector } from '../../selectors/messages';
import {
  getCompanyActiveEmployeesSelector,
  getCompanyUsersSelector
} from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import Button from '../../components/inputs/Button';
import DailyReportForm from '../../forms/DailyReportForm';
import SafetyInstructionForm from '../../forms/SafetyInstructionForm';
import SdsForm from '../../forms/SdsForm';
import ViewEditToolboxTalk from '../../components/ViewEditToolboxTalk';
import history from '../../history';

import './index.css';

export class ViewEdit extends Component {
  state = {
    viewing: true,
    sds: {
      label: '',
      expires: '',
      ghsLabels: [],
      locationId: '',
      projectId: ''
    }
  };

  componentDidMount = () => {
    this.props.fetchAllActiveEmployees();
    this.props.fetchAllActiveEmployeesActiveUsers();

    this.setState({
      sds: {
        ...this.props.activeSds,
        ghsLabels:
          this.props.activeSds.documentData &&
          this.props.activeSds.documentData.ghsLabels
            ? this.props.activeSds.documentData.ghsLabels
            : []
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  updateSds = values => {
    this.setState(state => ({
      sds: { ...state.sds, ...values }
    }));
  };

  handleSPSubmit = values => {
    this.props.updateSafetyInstruction(values);
    this.setState({ viewing: true });

    this.props.clearAttachment();
  };

  handleSubmit = () => {
    this.props.updateSds(this.state.sds);

    this.props.clearAttachment();
  };

  handleToolboxTalkSubmit = values => {
    this.props.updateToolboxTalk(values);
    this.setState({ viewing: true });

    this.props.clearAttachment();
  };

  handleDailyReportSubmit = values => {
    this.props.updateDailyReport(values);
    this.setState({ viewing: true });

    this.props.clearAttachment();
  };

  render() {
    const {
      match,
      companyLocations,
      activeSds,
      deleteSds,
      activeSafetyInstruction,
      deleteSafetyInstruction,
      activeCompany,
      addAttachment,
      clearAttachment,
      deleteSIAttachment,
      deleteSDSAttachment,
      attachmentLoading,
      activeDailyReport,
      deleteDailyReport,
      deleteToolboxTalk,
      activeToolboxTalk,
      deleteDRAttachment,
      deleteTTAttachment,
      setActiveTask,
      companyUsers,
      activeEmployees,
      loggedInUser,
      deleteAttachment,
      addedAttachments,
      activeLocation,
      activeProject,
      documentFolders
    } = this.props;

    const { viewing } = this.state;

    return (
      <div className="viewEdit">
        {match.params.editType === 'SDS' ||
        match.params.editType === 'Toolbox Talk' ? (
          ''
        ) : (
          <h2 className="viewEdit-header">
            {`View/Edit ${match.params.editType}`}
            <div className="viewEdit-buttonBox">
              {viewing && loggedInUser.accessLevel !== '100' ? (
                <Button
                  text="Edit"
                  onClick={() =>
                    this.setState({
                      viewing: false
                    })
                  }
                  className={'whiteButton'}
                />
              ) : (
                <div />
              )}
            </div>
          </h2>
        )}
        <div className="viewEditForm">
          {match.params.editType === 'SDS' ? (
            <SdsForm
              submitForm={() =>
                this.handleSubmit() && this.setState({ viewing: true })
              }
              sds={{
                ...activeSds,
                expires: moment(activeSds.expires).format('MM/DD/YYYY')
              }}
              disabled={viewing}
              onCancel={() =>
                clearAttachment() && this.setState({ viewing: true })
              }
              onDelete={() => deleteSds(activeSds)}
              activeCompanyId={activeCompany._id}
              addAttachment={addAttachment}
              activeAttachments={activeSds.attachments}
              deleteSDSAttachment={deleteSDSAttachment}
              loading={attachmentLoading}
              isUpdate={true}
              accessLevel={loggedInUser.accessLevel}
              addedAttachments={addedAttachments}
              deleteAttachment={deleteAttachment}
              activeLocation={activeLocation}
              activeProject={activeProject}
              locationList={companyLocations}
              handleSdsSelection={values => this.updateSds(values)}
              documentFolders={documentFolders}
            />
          ) : match.params.editType === 'Safety Procedure' ? (
            <SafetyInstructionForm
              onSubmit={values => this.handleSPSubmit(values)}
              initialValues={{
                ...activeSafetyInstruction,
                locationId: activeSafetyInstruction.groupIds,
                projectId: activeSafetyInstruction.projectIds
              }}
              disabled={viewing}
              onCancel={() =>
                clearAttachment() && this.setState({ viewing: true })
              }
              onDelete={() => deleteSafetyInstruction(activeSafetyInstruction)}
              activeCompanyId={activeCompany._id}
              addAttachment={addAttachment}
              activeAttachments={activeSafetyInstruction.attachments}
              deleteSIAttachment={deleteSIAttachment}
              loading={attachmentLoading}
              isUpdate={true}
              accessLevel={loggedInUser.accessLevel}
              addedAttachments={addedAttachments}
              deleteAttachment={deleteAttachment}
              activeLocation={activeLocation}
              activeProject={activeProject}
              locationList={companyLocations}
              documentFolders={documentFolders}
            />
          ) : match.params.editType === 'Daily Report' ? (
            <DailyReportForm
              onSubmit={values => this.handleDailyReportSubmit(values)}
              activeEmployees={activeEmployees}
              initialValues={{
                ...activeDailyReport,
                locationId: activeDailyReport.groupIds,
                projectId: activeDailyReport.projectIds
              }}
              disabled={viewing}
              onCancel={() =>
                clearAttachment() && this.setState({ viewing: true })
              }
              onDelete={() => deleteDailyReport(activeDailyReport)}
              isUpdate={true}
              activeCompanyId={activeCompany._id}
              addAttachment={addAttachment}
              activeAttachments={activeDailyReport.attachments}
              deleteDRAttachment={deleteDRAttachment}
              loading={attachmentLoading}
              addedAttachments={addedAttachments}
              deleteAttachment={deleteAttachment}
              activeLocation={activeLocation}
              activeProject={activeProject}
              locationList={companyLocations}
              documentFolders={documentFolders}
              accessLevel={loggedInUser.accessLevel}
            />
          ) : (
            <ViewEditToolboxTalk
              handleSubmit={values => this.handleToolboxTalkSubmit(values)}
              activeToolboxTalk={activeToolboxTalk}
              disabled={viewing}
              onCancel={value =>
                clearAttachment() && this.setState({ viewing: value })
              }
              onDelete={() => {
                deleteToolboxTalk(activeToolboxTalk);
                history.goBack();
              }}
              isUpdate={true}
              activeCompanyId={activeCompany._id}
              addAttachment={addAttachment}
              activeAttachments={activeToolboxTalk.attachments}
              deleteTTAttachment={deleteTTAttachment}
              loading={attachmentLoading}
              locations={companyLocations}
              setActiveTask={setActiveTask}
              activeEmployees={activeEmployees}
              loggedInUser={loggedInUser}
              addedAttachments={addedAttachments}
              deleteAttachment={deleteAttachment}
              activeLocation={activeLocation}
              activeProject={activeProject}
              documentFolders={documentFolders}
              companyUsers={companyUsers}
              accessLevel={loggedInUser.accessLevel}
              activeGroups={activeCompany.groups}
            />
          )}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  companyLocations: getCompanyLocationsSelector(state),
  activeSds: getActiveSdsSelector(state),
  activeSafetyInstruction: getActiveSafetyInstructionSelector(state),
  activeCompany: getActiveCompany(state),
  message: getMessageSelector(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  activeDailyReport: getActiveDailyReportSelector(state),
  activeToolboxTalk: getActiveToolboxTalkSelector(state),
  loggedInUser: getLoggedInUser(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  documentFolders: getDocumentFoldersSelector(state),
  activeEmployees: getCompanyActiveEmployeesSelector(state),
  companyUsers: getCompanyUsersSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateSds: sds => dispatch(updateSdsRequest(sds)),
  deleteSds: sds => dispatch(deleteSdsRequest(sds)),
  updateSafetyInstruction: safetyInstruction =>
    dispatch(updateSafetyInstructionRequest(safetyInstruction)),
  deleteSafetyInstruction: safetyInstruction =>
    dispatch(deleteSafetyInstructionRequest(safetyInstruction)),
  setActiveIncident: incident => dispatch(setActiveIncidentRequest(incident)),
  deleteSIAttachment: attachment =>
    dispatch(deleteSafetyInstructionAttachment(attachment)),
  deleteSDSAttachment: attachment => dispatch(deleteSdsAttachment(attachment)),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  updateDailyReport: values => dispatch(updateDailyReportsRequest(values)),
  deleteDailyReport: values => dispatch(deleteDailyReportsRequest(values)),
  updateToolboxTalk: values => dispatch(updateToolboxTalksRequest(values)),
  deleteToolboxTalk: values => dispatch(deleteToolboxTalksRequest(values)),
  deleteDRAttachment: attachment =>
    dispatch(deleteDailyReportAttachment(attachment)),
  deleteTTAttachment: attachment =>
    dispatch(deleteToolboxTalkAttachment(attachment)),
  setActiveTask: task => dispatch(setActiveTaskRequest(task)),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  fetchAllActiveEmployeesActiveUsers: () =>
    dispatch(fetchAllActiveEmployeesActiveUsers()),
  fetchAllActiveEmployees: () => dispatch(fetchAllActiveEmployees())
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(ViewEdit)
);
