import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import history from '../../history';
import {
  fetchMyDocumentsRequest,
  setActiveMyDocument
} from '../../actions/documents';
import { setActiveTrainingResponse } from '../../actions/training';
import { updateLeftNavRequest } from '../../actions/navigation';
import { getMyDocumentsSelector } from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterMyDocStatus,
  filterPerformedDate,
  filterMyDocType,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import Button from '../../components/inputs/Button';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import RadioButtons from '../../components/inputs/RadioButtons';

import './index.css';
import 'react-table/react-table.css';

class MyDocumentStages extends Component {
  state = {
    filters: [],
    openCreateModal: false,
    openDocumentTypeModal: false,
    docType: {}
  };

  componentDidMount() {
    if (localStorage.getItem('myDocsFilters')) {
      this.setState({
        filters: JSON.parse(localStorage.getItem('myDocsFilters'))
      });
    }

    this.props.updateLeftNav('My Documents');
    this.props.fetchMyDocuments();
  }

  handleFilterChange = filters => {
    localStorage.setItem('myDocsFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  handleSelectDocument = () => {
    this.props.setActiveMyDocument({});
    if (
      this.state.docType.label ===
      'Recurrence, Relapse During Recovery & Continuing Treatment'
    ) {
      history.push('/app/recurrenceContainer');
    } else {
      history.push('/app/transitionContainer');
    }
  };

  handleView = original => {
    if (original.title) {
      this.props.setActiveTraining({ ...original, isEditing: false });
      history.push('/app/training/performTraining');
    } else {
      this.props.setActiveMyDocument({ ...original });

      if (
        original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment'
      ) {
        history.push('/app/recurrenceContainer');
      } else if (original.label === 'Transitional Return to Work Program') {
        history.push('/app/transitionContainer');
      } else {
        history.push('/app/myDocumentContainer');
      }
    }
  };

  render() {
    const { myDocs, setActiveMyDocument } = this.props;
    const {
      filters,
      openCreateModal,
      openDocumentTypeModal,
      docType
    } = this.state;

    return (
      <div className="myDocumentList">
        <Header
          title="My Documents"
          rightButtons={{
            text: 'Create Document',
            className: 'blueButton',
            onClick: () => this.setState({ openCreateModal: true })
          }}
        />
        <div className="tableCard">
          <ReactTable
            data={myDocs}
            onFilteredChange={filtered => this.handleFilterChange(filtered)}
            filtered={filters}
            resizable={false}
            filterable={true}
            defaultFilterMethod={(filter, row) =>
              defaultFilterMethod(filter, row)
            }
            defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
            noDataText="No results found"
            defaultPageSize={10}
            className="table -striped -highlight"
            columns={[
              {
                Header: 'Title',
                accessor: d => d.label,
                id: 'label'
              },
              {
                Header: 'CREATED DATE',
                accessor: d => d.createdAt,
                id: 'createdAt',
                Cell: ({ original }) =>
                  moment(Date.parse(original.createdAt)).format(
                    'MM/DD/YYYY hh:mm a'
                  ),
                sortMethod: (a, b) => sortDateMethod(a, b),
                filterMethod: (filter, row) => filterPerformedDate(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="today">Created Today</option>
                    <option value="last7">Created within Last Week</option>
                    <option value="last30">Created This Month</option>
                    <option value="thisQuarter">Created This Quarter</option>
                    <option value="thisYear">Created This Year</option>
                  </select>
                )
              },
              {
                Header: 'STATUS',
                id: 'status',
                Cell: ({ original }) => {
                  if (
                    original.label ===
                    'Recurrence, Relapse During Recovery & Continuing Treatment'
                  ) {
                    if (original.isApprovedBySupervisor) {
                      return 'Approved';
                    } else {
                      return 'Supervisor Review';
                    }
                  } else if (
                    original.label === 'Transitional Return to Work Program'
                  ) {
                    if (
                      original.isApprovedBySupervisor &&
                      original.isApprovedByHr
                    ) {
                      return 'Approved';
                    }
                    if (
                      original.isApprovedBySupervisor &&
                      !original.isApprovedByHr
                    ) {
                      return 'HR Review';
                    } else {
                      return 'Supervisor Review';
                    }
                  } else if (original.title) {
                    return 'Approved';
                  } else {
                    return '--';
                  }
                },
                filterMethod: (filter, row) => filterMyDocStatus(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="supReview">Supervisor Review</option>
                    <option value="hrReview">HR Review</option>
                    <option value="approved">Approved</option>
                  </select>
                ),
                sortable: false
              },
              {
                Header: 'Document Type',
                id: 'docType',
                Cell: ({ original }) => {
                  if (original.title) {
                    return 'Safety Training';
                  } else if (
                    original.label === 'Transitional Return to Work Program' ||
                    original.label ===
                      'Recurrence, Relapse During Recovery & Continuing Treatment'
                  ) {
                    return 'Premade Documents';
                  } else {
                    return 'Uploaded Documents';
                  }
                },
                filterMethod: (filter, row) => filterMyDocType(filter, row),
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'All Folders'}
                  >
                    <option value="all">Show All</option>
                    <option value="safetyTraining">Safety Training</option>
                    <option value="continuousCare">Premade Dcouments</option>
                    <option value="myDocs">Uploaded Documents</option>
                  </select>
                ),
                sortable: false
              },
              {
                Header: 'VIEW/EDIT',
                Cell: ({ original }) => (
                  <Button
                    className="blueButton"
                    onClick={() => this.handleView(original)}
                    text=" View/Edit"
                  />
                ),
                filterable: false,
                sortable: false
              }
            ]}
          />
        </div>
        <Modal
          title="Choose Document Type"
          titleClassName="blueHeader"
          isOpen={openCreateModal}
          onRequestClose={() => this.setState({ openCreateModal: false })}
          hideButtons={true}
        >
          <div className="myDocumentList-reportSelection">
            <div
              className="myDocumentList-button"
              onClick={() => this.setState({ openDocumentTypeModal: true })}
            >
              <img
                src={require('../../assets/images/PremadeForms.png')}
                className="myDocumentList-premadeForms"
                alt="Select For Premande forms"
              />
              <span>Premade Documents</span>
            </div>
            <div
              className="myDocumentList-button"
              onClick={() =>
                setActiveMyDocument({ attachments: [] }) &&
                history.push('/app/myDocumentContainer')
              }
            >
              <img
                src={require('../../assets/images/Upload Image.png')}
                className="myDocumentList-uploadForms"
                alt="Select For Employee Document Upload"
              />
              <span>Upload Document</span>
            </div>
          </div>
        </Modal>
        <Modal
          title="Choose Document Type"
          titleClassName="blueHeader"
          isOpen={openDocumentTypeModal}
          onRequestClose={() => this.setState({ openDocumentTypeModal: false })}
          submitButtonColor="blue"
          submitButtonText="Continue"
          submitActions={() => this.handleSelectDocument()}
        >
          <div className="myDocumentList-premadeFormsHeader">Premade Forms</div>
          <div className="myDocumentList-docTypeSelection">
            <RadioButtons
              options={[
                {
                  label:
                    'Recurrence, Relapse During Recovery & Continuing Treatment',
                  value: 0
                },
                { value: 1, label: 'Transitional Return to Work Program' }
              ]}
              handleChange={values => this.setState({ docType: values })}
              isRequired={true}
              currentValue={docType}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  myDocs: getMyDocumentsSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  fetchMyDocuments: () => dispatch(fetchMyDocumentsRequest()),
  setActiveMyDocument: paylaod => dispatch(setActiveMyDocument(paylaod)),
  updateLeftNav: payload => dispatch(updateLeftNavRequest(payload)),
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDocumentStages);
