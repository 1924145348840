import {
  CLEAR_USER,
  FETCH_USER_PROFILE,
  LOAD_USER_RESPONSE,
  UPDATE_DEFAULT_PASSWORD_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST
} from '../constants/actionTypes';

export const initialState = {
  activeUser: {},
  users: {}
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
    case UPDATE_DEFAULT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USER_PROFILE:
      return {
        ...state,
        loading: false,
        activeUser: payload
      };
    case LOAD_USER_RESPONSE:
      let users = { ...state.users };
      let { id, ...data } = payload;
      users[id] = data;
      return {
        ...state,
        users
      };
    case CLEAR_USER:
      let u = { ...state.users };
      delete u[payload];
      return {
        ...state,
        users: u
      };

    default:
      return state;
  }
};

export default user;
