import React from 'react';

import { InputRow } from '../../inputs';
import Button from '../../inputs/Button';

import styles from './styles.module.scss';

export default ({ answer, updateAnswer, isLocked }) => {
  answer = parseInt(answer, 10);
  return (
    <InputRow space className={styles.safeUnsafeButtons}>
      {answer === 1 ? (
        <Button
          text="Safe"
          color="green"
          image={require('../../../assets/images/checkmark.png')}
          className={styles.checkmarkButton}
        />
      ) : !isLocked ? (
        <Button
          text="Safe"
          onClick={() => updateAnswer(1)}
          color={answer ? 'greenOutline' : 'green'}
        />
      ) : (
        ''
      )}
      {answer === 2 ? (
        <Button
          text="Unsafe"
          color="red"
          image={require('../../../assets/images/checkmark.png')}
          className={styles.checkmarkButton}
        />
      ) : !isLocked ? (
        <Button
          text="Unsafe"
          onClick={() => updateAnswer(2)}
          color={answer ? 'redOutline' : 'red'}
        />
      ) : (
        ''
      )}
      {answer === 3 ? (
        <Button
          text="Not Applicable"
          color="white"
          image={require('../../../assets/images/blackCheckmark.png')}
          className={styles.checkmarkButton}
        />
      ) : !isLocked ? (
        <Button
          text="Not Applicable?"
          onClick={() => updateAnswer(3)}
          color="white"
        />
      ) : (
        ''
      )}
    </InputRow>
  );
};
