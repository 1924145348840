import React, { Component } from 'react';
import '../../components/inputs/input.css';
import Textbox from '../../components/inputs/Textbox';
import '../../components/inputs/Textbox/index.css';
import Modal from '../../components/Modal';
import '../form.css';

export class ResetUserPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      securityQuestionsModalOpen: true,
      securityAnswer1: '',
      securityAnswer2: ''
    };
  }

  handleSecurityQuestionsCloseModal = () => {
    this.setState({ securityQuestionsModalOpen: false });
  };

  handleSecurityQuestionsOpenModal = () => {
    this.setState({
      securityQuestionsModalOpen: true
    });
  };

  render() {
    const { securityQuestions, submitQuestions } = this.props;
    return (
      <Modal
        title="Answer Security Questions"
        titleClassName="blueHeader"
        isOpen={this.state.securityQuestionsModalOpen}
        submitButtonColor="blue"
        submitButtonText="Save Changes"
        onRequestClose={this.handleSecurityQuestionsCloseModal}
        submitActions={() => {
          const questionsAnswers = {
            answers: [
              {
                question: securityQuestions[0],
                answer: this.state.securityAnswer1
              },
              {
                question: securityQuestions[1],
                answer: this.state.securityAnswer2
              },
              {
                username: securityQuestions[2]
              }
            ]
          };

          submitQuestions(questionsAnswers);

          this.setState({
            securityQuestionsModalOpen: false
          });
        }}
        disableSubmit={
          !(this.state.securityAnswer1 && this.state.securityAnswer2)
        }
      >
        <Textbox
          fieldLabel={`${this.props.securityQuestions[0]}`}
          handleChange={e => this.setState({ securityAnswer1: e.target.value })}
          isRequired={true}
          placeholder="Fill in with answer to Security Question 1"
        />
        <Textbox
          fieldLabel={`${this.props.securityQuestions[1]}`}
          handleChange={e => this.setState({ securityAnswer2: e.target.value })}
          isRequired={true}
          placeholder="Fill in with answer to Security Question 2"
        />
      </Modal>
    );
  }
}
