import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';
import { PersistGate } from 'redux-persist/lib/integration/react';

import history from '../../history';
import { isLoading } from '../../selectors/loading';
import { persistor } from '../../store';
import { unregister } from '../../registerServiceWorker';

import App from '../App';
import AuthenticatedRoute from '../AuthenticatedRoute';
import ErrorPage from '../../components/ErrorPage';
import Home from '../Home';
import Loading from '../../components/Loading';
import Login from '../Login';
import Register from '../Register';
import TopHeader from '../TopHeader';

import loadingGif from '../../assets/images/iReportSourceLoading.gif';
import styles from './styles.module.scss';

unregister();

const options = {
  position: 'top center',
  timeout: 4000,
  offset: '30px',
  transition: 'scale',
  width: '100%'
};

const AlertTemplate = ({ options, message, close }) => (
  <div
    className={[
      styles.alertStyle,
      options.type === 'error'
        ? styles.alertStyleError
        : '' + options.type === 'success'
        ? styles.alertStyleSuccess
        : ''
    ].join(' ')}
  >
    <div className={styles.alertMessage}>{message}</div>
    <div onClick={close} className="modalBody-header--X">
      <img
        src={require('../../assets/images/modalX.png')}
        alt="close the alert"
      />
    </div>
  </div>
);

const Root = ({ store, isLoading }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router history={history}>
          <div id="app" className="root">
            <AlertProvider template={AlertTemplate} {...options}>
              <TopHeader />
              <Switch>
                <AuthenticatedRoute exact path="/home" component={Home} />
                <AuthenticatedRoute path="/app" component={App} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/error" component={ErrorPage} />
                <Route
                  exact
                  path="/useraccounts/microsoftlogin"
                  component={Home}
                />
                <Redirect from="/" to="/login" />
              </Switch>
            </AlertProvider>
            <div
              className={`${styles.loading} ${isLoading ? '' : styles.hidden}`}
            >
              <div>
                <img src={loadingGif} alt="Loading..." />
              </div>
            </div>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired
};
export default connect(state => ({
  isLoading: isLoading(state)
}))(Root);
