import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import {
  fetchCompanyIncidentAnalyticsRequest,
  fetchCompanyManagementAnalyticsRequest
} from '../../actions/analytics';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  getAccountabilityDocumentsSelector,
  getAllCasesAverageLossTimeSelector,
  getAnalyticsLoadingSelector,
  getAverageIncidentLagTimeSelector,
  getCorrectiveActionsByTypeSelector,
  getDartSelector,
  getJsaClosedOneWeekSelector,
  getLtirSelector,
  getNearMissByTypeSelector,
  getNumberOfFROIsSelector,
  getNumberOfProactiveReportsSelector,
  getNumberOfSafetyTrainingsPerformedSelector,
  getObservationCostsStatsSelector,
  getOshaCasesAverageLossTimeSelector,
  getOshaDataForIncidentsSelector,
  getReportCostsStatsSelector,
  getTaskDataSelector,
  getTcirSelector,
  getTopFactorsFROIAutoSelector,
  getTotalOshaInjurySelector,
  getTotalSafetyCostsSelector
} from '../../selectors/analytics';
import { getActiveCompany, getActiveLocationId } from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import yearToDateDropdown from '../../utils/yeartoDateDropdown';
import AnalyticCards from '../../components/AnalyticCards';
import AnalyticCharts from '../../components/AnalyticCharts';
import Dropdown from '../../components/inputs/Dropdown';
import Header from '../../components/Header';

import './index.css';

export class Analytics extends Component {
  state = {
    analyticType: 0,
    timeFilterSelection: { value: 'lastMonth', label: 'Last 30 Days' },
    divisionEstInfo: {}
  };

  componentDidMount = () => {
    this.props.updateLeftNav('Analytics');
    this.props.fetchCompanyManagementAnalytics('lastMonth');
    this.props.fetchCompanyIncidentAnalytics('lastMonth');

    if (!this.props.activeLocationId) {
      this.setState({ divisionEstInfo: this.props.activeCompany });
    } else {
      const {
        operationalForYearOrLonger,
        isSeparateEstablishment
      } = this.props.activeLocationId;

      if (isSeparateEstablishment && operationalForYearOrLonger) {
        this.setState({ divisionEstInfo: this.props.activeLocationId });
      } else {
        this.setState({ divisionEstInfo: this.props.activeCompany });
      }
    }
  };

  handleStateUpdate = updatedState => {
    this.setState(updatedState);

    this.getGAType(updatedState.analyticType);
  };

  handleTimeFilterChange = updatedState => {
    this.setState(updatedState);
    this.props.fetchCompanyManagementAnalytics(
      updatedState.timeFilterSelection.value.toString()
    );
    this.props.fetchCompanyIncidentAnalytics(
      updatedState.timeFilterSelection.value.toString()
    );

    this.getGAType(updatedState.analyticType);
  };

  render() {
    const { analyticType, timeFilterSelection, divisionEstInfo } = this.state;

    const {
      analyticsLoading,
      nearMissByType,
      jsaClosedOneWeek,
      accountabilityDocuments,
      numberOfProactiveReports,
      numberOfSafetyTrainingsPerformed,
      averageIncidentLagTime,
      reportCostStats,
      correctiveActionsByType,
      dart,
      tcir,
      ltir,
      numberOfFROIs,
      oshaCasesAverageLossTime,
      allCasesAverageLossTime,
      totalSafetyCosts,
      activeLocation,
      observationCostsStats,
      taskData,
      oshaDataForIncidents,
      totalOshaInjury,
      topFactorsFROIAuto
    } = this.props;

    const timeFilterOptions = [
      { value: 'lastMonth', label: 'Last 30 Days' },
      { value: 'last3Months', label: 'Last 3 Months' },
      { value: 'last6Months', label: 'Last 6 Months' },
      { value: 'last12Months', label: 'Last 12 Months' },
      ...yearToDateDropdown()
    ];

    return (
      <div>
        <Header title="Analytics" />
        <div className="analytics-selectionBar">
          <div className="analytics-selectionBar--left">
            <div
              className={
                analyticType === 0
                  ? 'analytics-managementSelection analytics-selection--selected'
                  : 'analytics-managementSelection analytics-selection--notSelected'
              }
              onClick={() => this.handleStateUpdate({ analyticType: 0 })}
            >
              <span>Management</span>
            </div>
            <div
              className={
                analyticType === 1
                  ? 'analytics-incidentSelection analytics-selection--selected'
                  : 'analytics-incidentSelection analytics-selection--notSelected'
              }
              onClick={() => this.handleStateUpdate({ analyticType: 1 })}
            >
              <span>Incidents</span>
            </div>
          </div>
          <div className="analytics-selectionBar--right">
            <Dropdown
              options={timeFilterOptions}
              placeholder="Select an option..."
              currentValue={timeFilterSelection}
              handleChange={value =>
                this.handleTimeFilterChange({
                  timeFilterSelection: value
                })
              }
              searchable={false}
            />
          </div>
        </div>
        {analyticsLoading ? (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        ) : (
          <div>
            <AnalyticCards
              analyticType={analyticType}
              accountabilityDocuments={accountabilityDocuments}
              numberOfProactiveReports={numberOfProactiveReports}
              numberOfSafetyTrainingsPerformed={
                numberOfSafetyTrainingsPerformed
              }
              averageIncidentLagTime={averageIncidentLagTime}
              dart={dart}
              tcir={tcir}
              ltir={ltir}
              numberOfFROIs={numberOfFROIs}
              oshaCasesAverageLossTime={oshaCasesAverageLossTime}
              allCasesAverageLossTime={allCasesAverageLossTime}
              isTimeFrameMonth={
                timeFilterSelection.value.toString().indexOf('last') > -1
              }
              totalSafetyCosts={totalSafetyCosts}
              canViewTDL={
                activeLocation === 0 ||
                activeLocation.operationalForYearOrLonger
              }
              year={timeFilterSelection.value.toString()}
              activeLocation={activeLocation}
              divisionEstInfo={divisionEstInfo}
            />
            <AnalyticCharts
              analyticType={analyticType}
              nearMissByType={nearMissByType}
              jsaClosedOneWeek={jsaClosedOneWeek}
              activeLocation={activeLocation}
              reportCostStats={reportCostStats}
              observationCostsStats={observationCostsStats}
              taskData={taskData}
              correctiveActionsByType={correctiveActionsByType}
              oshaDataForIncidents={oshaDataForIncidents}
              totalOshaInjury={totalOshaInjury}
              topFactorsFROIAuto={topFactorsFROIAuto}
              divisionEstInfo={divisionEstInfo}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  analyticsLoading: getAnalyticsLoadingSelector(state),
  nearMissByType: getNearMissByTypeSelector(state),
  jsaClosedOneWeek: getJsaClosedOneWeekSelector(state),
  accountabilityDocuments: getAccountabilityDocumentsSelector(state),
  numberOfProactiveReports: getNumberOfProactiveReportsSelector(state),
  numberOfSafetyTrainingsPerformed: getNumberOfSafetyTrainingsPerformedSelector(
    state
  ),
  averageIncidentLagTime: getAverageIncidentLagTimeSelector(state),
  reportCostStats: getReportCostsStatsSelector(state),
  correctiveActionsByType: getCorrectiveActionsByTypeSelector(state),
  dart: getDartSelector(state),
  tcir: getTcirSelector(state),
  numberOfFROIs: getNumberOfFROIsSelector(state),
  oshaCasesAverageLossTime: getOshaCasesAverageLossTimeSelector(state),
  allCasesAverageLossTime: getAllCasesAverageLossTimeSelector(state),
  totalSafetyCosts: getTotalSafetyCostsSelector(state),
  activeLocation: getActiveLocationId(state),
  observationCostsStats: getObservationCostsStatsSelector(state),
  taskData: getTaskDataSelector(state),
  oshaDataForIncidents: getOshaDataForIncidentsSelector(state),
  totalOshaInjury: getTotalOshaInjurySelector(state),
  topFactorsFROIAuto: getTopFactorsFROIAutoSelector(state),
  ltir: getLtirSelector(state),
  loggedInUser: getLoggedInUser(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  updateLeftNav: navigation => dispatch(updateLeftNavRequest(navigation)),
  fetchCompanyManagementAnalytics: timeFrame =>
    dispatch(fetchCompanyManagementAnalyticsRequest(timeFrame)),
  fetchCompanyIncidentAnalytics: timeFrame =>
    dispatch(fetchCompanyIncidentAnalyticsRequest(timeFrame))
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
