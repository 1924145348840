import React, { Component } from 'react';

import { classnames } from '../../../utils/classNamesHelper';

import styles from './dropdown.module.scss';

export default class PermissionsDropdown extends Component {
  state = { showOptions: false };

  static getPicture(value) {
    switch (value) {
      case 'Edit':
        return require('../../../assets/images/Pen Icon.png');
      case 'View':
        return require('../../../assets/images/Eye Icon.png');
      case 'None':
      default:
        return require('../../../assets/images/Remove Icon.png');
    }
  }

  handleSelectionChange = selection => {
    this.props.onChange(selection);
  };

  _onBlur = () => {
    this.setState({ showOptions: false });
  };

  render() {
    let {
      className,
      placeholder,
      disabled,
      accessLevel,
      stage,
      currentValue,
    } = this.props;

    accessLevel = parseInt(accessLevel, 10);

    const currentPicture = PermissionsDropdown.getPicture(currentValue);

    const { showOptions } = this.state;
    const classes = [
      className,
      styles.permDropdown,
      styles.multiSelect,
      disabled && styles.disabled,
      showOptions && styles.showOptions
    ];

    return (
      <ul
        className={classnames(classes)}
        onClick={() =>
          this.setState(state => ({ showOptions: disabled ? false : !state.showOptions }))
        }
        onBlur={this._onBlur}
        tabIndex={0}
      >
        <li className={styles.select}>
          <span className={currentPicture ? undefined : styles.placeholder}>
            {currentPicture ? (
              <img
                src={currentPicture}
                alt={currentValue}
                className={styles.picture}
              />
            ) : (
              placeholder || 'Select'
            )}
          </span>
          <div className={styles.right}>
            <img
              src={
                showOptions
                  ? require('../../../assets/images/Chevron.png')
                  : require('../../../assets/images/downChevron.png')
              }
              alt={showOptions ? 'dropdown open' : 'dropdown closed'}
            />
          </div>
        </li>
        <li className={styles.wrapper}>
          <ul>
            <li
              onClick={() => this.handleSelectionChange('View')}
              className={styles.options}
            >
              <img
                src={require('../../../assets/images/Eye Icon.png')}
                alt="View stage"
                className={styles.picture}
              />
              View
            </li>
            <li
              onClick={() => this.handleSelectionChange('Edit')}
              className={styles.options}
            >
              <img
                src={require('../../../assets/images/Pen Icon.png')}
                alt="Edit stage"
                className={styles.picture}
              />
              Edit
            </li>
            {(accessLevel !== 900 ||
              (accessLevel !== 900 && stage !== 0)) && (
              <li
                onClick={() => this.handleSelectionChange('None')}
                className={styles.options}
              >
                <img
                  src={require('../../../assets/images/Remove Icon.png')}
                  alt="Remove from stage"
                  className={styles.picture}
                />
                None
              </li>
            )}
          </ul>
        </li>
      </ul>
    );
  }
}
