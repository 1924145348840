import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { newUserRegistrationRequest } from '../../actions/auth';
import { getMessageSelector } from '../../selectors/messages';
import NewUserRegistrationForm from '../../forms/NewUserRegistrationForm';

export class Register extends Component {
  render() {
    const { newUserRegistration, newGoogleLogin } = this.props;
    return (
      <div className="login-registration">
        <h2>Join some of the world’s safest companies!</h2>
        <NewUserRegistrationForm
          onSubmit={values => newUserRegistration(values)}
          setLoginPage={value => this.setState({ loginPage: value })}
          onGoogleSuccess={values => newGoogleLogin(values)}
        />
        <h3>Free 30 Day Trial</h3>
        <h3>No Credit Card Required</h3>
        <footer>
          <img
            src={require('../../assets/images/partner-logos-4.png')}
            alt="partnerImage"
          />
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  message: getMessageSelector(state)
});

const mapDispatchToProps = dispatch => ({
  newUserRegistration: payload => dispatch(newUserRegistrationRequest(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
