import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import { Input, nameInput } from '../';
import { classnames } from '../../../utils/classNamesHelper';

import styles from './googleMaps.module.scss';

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      gmapsLoaded: false
    };
  }

  handleChange = address => {
    this.props.handleChange(address);
    this.setState({ errorMessage: '' });
  };

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  initMap = () => {
    this.setState({
      gmapsLoaded: true
    });
  };

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCqpOeA-wTWDFISjWvIBjTtQvz9Z3ApBPo&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }

  render() {
    const {
      name,
      disabled,
      currentValue,
      placeholder,
      ...props
    } = this.props;
    const { errorMessage } = this.state;

    return (
      <Input
        name={name}
        disabled={disabled}
        {...props}
      >
        {this.state.gmapsLoaded && (
          <>
            <PlacesAutocomplete
              onChange={this.handleChange}
              value={currentValue}
              onError={this.handleError}
              shouldFetchSuggestions={currentValue.length > 2}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                return (
                  <div className={styles.container}>
                    <input
                      {...getInputProps({
                        placeholder: placeholder || 'Start typing for search...',
                        className: classnames('textbox', suggestions.length > 0 && styles.hasSuggestions),
                        disabled
                      })}
                    />
                    {suggestions.length > 0 && (
                      <div className={styles.suggestions}>
                        {suggestions.map(suggestion => {
                          const className = classnames(
                            styles.item,
                            suggestion.active && styles.active
                          );

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className
                              })}
                            >
                              <strong>
                                {suggestion.formattedSuggestion.mainText}
                              </strong>{' '}
                              <small>
                                {suggestion.formattedSuggestion.secondaryText}
                              </small>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              }}
            </PlacesAutocomplete>
            {errorMessage.length > 0 && (
              <div className={styles.error}>
                {this.state.errorMessage}
              </div>
            )}
          </>
        )}
      </Input>
    );
  }
}

export default nameInput(GoogleMap);
