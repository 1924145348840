import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';

import { getLoggedInUser } from '../../selectors/users';
import { getMessageSelector } from '../../selectors/messages';
import { setActiveIncidentResponse } from '../../actions/incidents';
import { clearUploadedAttachments } from '../../actions/attachments';
import { fetchAllActiveEmployees } from '../../actions/personnel';
import CompanyDetails from '../../components/CompanyDetails';
import EmployeeList from '../../components/EmployeeList';
import LocationList from '../../components/LocationList';
import ProjectList from '../../components/ProjectList';

export class Settings extends Component {
  componentDidMount() {
    this.props.fetchAllActiveEmployees();
    this.props.activeIncidentResponse();
    this.props.clearAttachment();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }
  }

  render() {
    const { match } = this.props;

    if (match.params.settingType === 'Projects') return <ProjectList />;
    if (match.params.settingType === 'Locations') return <LocationList />;
    if (match.params.settingType === 'CompanyDetails') return <CompanyDetails />;
    if (match.params.settingType === 'Employees') return <EmployeeList />;

    return <></>;
  }
}

export const mapStateToProps = state => ({
  message: getMessageSelector(state),
  loggedInUser: getLoggedInUser(state)
});

export const mapDispatchToProps = dispatch => ({
  fetchAllActiveEmployees: () => dispatch(fetchAllActiveEmployees()),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  activeIncidentResponse: () => dispatch(setActiveIncidentResponse({}))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
