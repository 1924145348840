import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import moment from 'moment';
import ReactLoading from 'react-loading';
import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  addQualificationRequest,
  deleteQualificationAttachment,
  deleteQualificationRequest,
  fetchDocumentFoldersRequest,
  fetchMyDocumentsRequest,
  fetchQualificationTypeRequest,
  setActiveMyDocument,
  setActiveQualification,
  updateQualificationRequest
} from '../../actions/documents';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  deleteLocationEmployeeRequest,
  resetEmployeesPasswordRequest,
  resendWelcomeNotificationRequest,
  setActiveEmployeeResponse,
  updateLocationEmployeeRequest
} from '../../actions/personnel';
import {
  setActiveTrainingRequest,
  setActiveTrainingResponse
} from '../../actions/training';
import { updateUserRequest, createUserRequest } from '../../actions/user';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getUserCompaniesSelector
} from '../../selectors/company';
import {
  getActiveFolderSelector,
  getActiveQualificationSelector,
  getDocumentFoldersSelector,
  getMyDocumentsSelector,
  getQualificationTypesSelector
} from '../../selectors/documents';
import { getMessageSelector } from '../../selectors/messages';
import { getAccountInfo } from '../../selectors/payment';
import {
  getActiveEmployeeSelector,
  getCompanyActiveEmployeesSelector,
  getCompanyUsersSelector,
  getPersonnelLoadingSelector
} from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import Button from '../../components/inputs/Button';
import EmployeeDocumentRepo from '../../components/EmployeeDocumentRepo';
import EmployeeForm from '../../forms/EmployeeForm';
import EmployeeSnapshot from '../../components/EmployeeSnapshot';
import IncidentsList from '../../components/IncidentsList';
import Modal from '../../components/Modal';
import QualificationList from '../../components/QualificationList';
import SafetyTrainingList from '../../components/training/SafetyTrainingList';
import UserForm from '../../forms/UserForm';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';

import './index.css';

export class ViewEditEmployee extends Component {
  state = {
    modalOpen: false,
    selected: 0
  };

  componentDidMount = () => {
    this.props.fetchQualificationTypes();
    this.props.fetchMyDocuments();
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.message.message &&
      prevProps.message.message !== this.props.message.message
    ) {
      this.props.message.error
        ? this.props.alert.error(this.props.message.message)
        : this.props.alert.success(this.props.message.message);
    }

    if (
      this.props.activeEmployee._id &&
      this.props.activeEmployee._id !== prevProps.activeEmployee._id
    ) {
      this.props.fetchMyDocuments();
    }
  }

  handleUpdateEmployee = values => {
    this.props.updateLocationEmployee(values);
  };

  handleDeleteEmployee = () => {
    this.props.deleteLocationEmployee({
      ...this.props.activeEmployee
    });

    history.push('/app/settings/Employees');
  };

  render() {
    const { selected, modalOpen } = this.state;

    const {
      activeEmployee,
      addAttachment,
      clearAttachment,
      setActiveIncident,
      setActiveQualifications,
      addQualification,
      activeQualification,
      updateQualification,
      qualificationTypes,
      activeCompany,
      addedAttachments,
      loggedInUser,
      deleteAttachment,
      fetchDocumentFolders,
      documentFolders,
      activeFolder,
      activeEmployeeDocs,
      userCompanies,
      accountInfo,
      companyUsers,
      activeEmployees,
      personnelLoading,
      updateUser,
      createUser,
      deleteQualification,
      deleteQualificationAttachment,
      setActiveMyDocument,
      setActiveEmployee,
      resetEmployeesPassword,
      resendWelcomeNotification,
      setActiveTemplate,
      setActiveTraining
    } = this.props;

    const incidentTabs = [
      'Open',
      'Awaiting Info',
      'Investigation',
      'Remediation',
      'Closed'
    ];

    const incidentHeaders = ['Id', 'Severity', 'Updated Date', 'Status', ''];
    const header = (
      <Header
        title="Employee Profile"
        clickBack={() => {
          setActiveEmployee();
          history.goBack();
        }}
      />
    );

    return (
      <HeaderAndFooter Header={header} className="viewEditEmployee">
        {personnelLoading ? (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        ) : (
          <>
            <div className="viewEditEmployeeMainPage-tabs">
              <div
                className={
                  selected === 0
                    ? 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabStart selected'
                    : 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabStart viewEditEmployeeMainPage-tab--notSelected'
                }
                onClick={() => this.setState({ selected: 0 })}
              >
                <div className="viewEditEmployeeMainPage-tabs--headerTopLine">
                  Snapshot
                </div>
                {selected === 0 && (
                  <div className="viewEditEmployeeMainPage-tab--selected" />
                )}
              </div>
              <div
                className={
                  selected === 1
                    ? 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle selected'
                    : 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--notSelected'
                }
                onClick={() => this.setState({ selected: 1 })}
              >
                <div className="viewEditEmployeeMainPage-tabs--headerTopLine">
                  Personal Info
                </div>
                {selected === 1 && (
                  <div className="viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--selected" />
                )}
              </div>
              <div
                className={
                  selected === 2
                    ? 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle selected'
                    : 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--notSelected'
                }
                onClick={() => this.setState({ selected: 2 })}
              >
                <div className="viewEditEmployeeMainPage-tabs--headerTopLine">
                  Trainings
                </div>
                {selected === 2 && (
                  <div className="viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--selected" />
                )}
              </div>
              <div
                className={
                  selected === 3
                    ? 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle selected'
                    : 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--notSelected'
                }
                onClick={() => this.setState({ selected: 3 })}
              >
                <div className="viewEditEmployeeMainPage-tabs--headerTopLine">
                  Reports
                </div>
                {selected === 3 && (
                  <div className="viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--selected" />
                )}
              </div>
              <div
                className={
                  selected === 4
                    ? 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle selected'
                    : 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--notSelected'
                }
                onClick={() => this.setState({ selected: 4 })}
              >
                <div className="viewEditEmployeeMainPage-tabs--headerTopLine">
                  Qualifications
                </div>
                {selected === 4 && (
                  <div className="viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--selected" />
                )}
              </div>
              {loggedInUser.accessLevel === '900' && (
                <div
                  className={
                    selected === 5
                      ? 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle selected'
                      : 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--notSelected'
                  }
                  onClick={() => this.setState({ selected: 5 })}
                >
                  <div className="viewEditEmployeeMainPage-tabs--headerTopLine">
                    User Info
                  </div>
                  {selected === 5 && (
                    <div className="viewEditEmployeeMainPage-tabMiddle viewEditEmployeeMainPage-tab--selected" />
                  )}
                </div>
              )}
              <div
                className={
                  selected === 6
                    ? 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabEnd selected'
                    : 'viewEditEmployeeMainPage-tab viewEditEmployeeMainPage-tabEnd viewEditEmployeeMainPage-tab--notSelected'
                }
                onClick={() => this.setState({ selected: 6 })}
              >
                <div className="viewEditEmployeeMainPage-tabs--headerTopLine">
                  Doc Repo
                </div>
                {selected === 6 && (
                  <div className="viewEditEmployeeMainPage-tabEnd viewEditEmployeeMainPage-tab--selected" />
                )}
              </div>
            </div>

            <div className="viewEditEmployeeMainPage-tabContent">
              {selected === 0 && (
                <EmployeeSnapshot
                  activeEmployee={activeEmployee}
                  userCompanies={userCompanies}
                  accessLevel={loggedInUser.accessLevel}
                />
              )}
              {selected === 1 && (
                <EmployeeForm
                  onSubmit={values =>
                    this.handleUpdateEmployee({
                      ...values
                    })
                  }
                  onDelete={() => this.setState({ modalOpen: true })}
                  initialValues={{
                    ...activeEmployee,
                    companyId: activeEmployee.companyIds,
                    locationId: activeEmployee.groupIds,
                    projectId: activeEmployee.projectIds,
                    email:
                      activeEmployee.person &&
                      activeEmployee.person.emails &&
                      activeEmployee.person.emails.length > 0 &&
                      activeEmployee.person.emails[0].emailAddress,
                    phone:
                      activeEmployee.person &&
                      activeEmployee.person.phoneNumbers &&
                      activeEmployee.person.phoneNumbers.length > 0 &&
                      activeEmployee.person.phoneNumbers[0].fullPhoneNumber,
                    homeAddress:
                      activeEmployee.person &&
                      activeEmployee.person.addresses &&
                      activeEmployee.person.addresses.length > 0 &&
                      activeEmployee.person.addresses[0].streetAddress,
                    city:
                      activeEmployee.person &&
                      activeEmployee.person.addresses &&
                      activeEmployee.person.addresses.length > 0 &&
                      activeEmployee.person.addresses[0].city,
                    state:
                      activeEmployee.person &&
                      activeEmployee.person.addresses &&
                      activeEmployee.person.addresses.length > 0 &&
                      activeEmployee.person.addresses[0].stateName,
                    zip:
                      activeEmployee.person &&
                      activeEmployee.person.addresses &&
                      activeEmployee.person.addresses.length > 0 &&
                      activeEmployee.person.addresses[0].zip,
                    dateOfBirth:
                      activeEmployee.person &&
                      activeEmployee.person.dateOfBirth &&
                      activeEmployee.person.dateOfBirth !== ''
                        ? moment(
                            Date.parse(activeEmployee.person.dateOfBirth)
                          ).format('MM/DD/YYYY')
                        : '',
                    dateOfHire: moment(
                      Date.parse(activeEmployee.dateOfHire)
                    ).format('MM/DD/YYYY'),
                    dateOfTermination:
                      activeEmployee.dateOfTermination !== ''
                        ? moment(
                            Date.parse(activeEmployee.dateOfTermination)
                          ).format('MM/DD/YYYY')
                        : ''
                  }}
                  isUpdate={true}
                  userCompanies={userCompanies}
                  accountInfo={accountInfo}
                  isAccountOwner={
                    activeEmployee.userAccount &&
                    activeEmployee.userAccount.isAccountOwner
                  }
                />
              )}
              {selected === 2 && (
                <div className="safetyListContainer">
                  <Button
                    className="createTrainingButton"
                    color="blue"
                    text="Create Training"
                    onClick={() => {
                      setActiveTraining({ isFromEmployee: true });
                      history.push('/app/training/trainingContainer');
                    }}
                  />
                  <SafetyTrainingList
                    trainings={activeEmployee.safetyTrainings.documents}
                    users={companyUsers}
                    setActiveTemplate={setActiveTemplate}
                    isFromList={false}
                    activeEmployees={activeEmployees}
                    setActiveTraining={setActiveTraining}
                  />
                </div>
              )}
              {selected === 3 && (
                <IncidentsList
                  incidentHeaders={incidentHeaders}
                  content={
                    activeEmployee.incidents ? activeEmployee.incidents : []
                  }
                  tabs={incidentTabs}
                  setActiveIncident={setActiveIncident}
                  activeEmployeeList={companyUsers}
                />
              )}
              {selected === 4 && (
                <QualificationList
                  content={activeEmployee.qualifications.documents}
                  updateLeftNav={() => console.log()}
                  locationList={activeCompany.groups}
                  activeEmployees={activeEmployees}
                  companyUsers={companyUsers}
                  hideGradeButton={true}
                  setActiveQualification={setActiveQualifications}
                  addQualification={addQualification}
                  activeQualification={activeQualification}
                  updateQualification={updateQualification}
                  qualificationTypes={qualificationTypes}
                  activeCompany={activeCompany}
                  addAttachment={addAttachment}
                  clearAttachment={clearAttachment}
                  fetchDocumentFolders={fetchDocumentFolders}
                  documentFolders={documentFolders}
                  activeFolder={activeFolder}
                  isOnEmployee={true}
                  deleteAttachment={deleteAttachment}
                  addedAttachments={addedAttachments}
                  user={loggedInUser}
                  isFromEmployee={true}
                  deleteQualificationAttachment={deleteQualificationAttachment}
                  deleteQualification={deleteQualification}
                />
              )}
              {selected === 5 && loggedInUser.accessLevel === '900' && (
                <UserForm
                  user={{
                    ...activeEmployee.userAccount
                  }}
                  updateActiveUser={value =>
                    value._id !== 0
                      ? updateUser({
                          ...activeEmployee.userAccount,
                          ...value,
                          isFromEmployee: true
                        })
                      : createUser({
                          ...value
                        })
                  }
                  accountInfo={accountInfo}
                  resetEmployeesPassword={() =>
                    resetEmployeesPassword(activeEmployee)
                  }
                  resendWelcomeNotification={() =>
                    resendWelcomeNotification(activeEmployee)
                  }
                />
              )}
              {selected === 6 && (
                <EmployeeDocumentRepo
                  content={activeEmployeeDocs}
                  setActiveMyDocument={setActiveMyDocument}
                  setActiveTraining={setActiveTraining}
                />
              )}

              <Modal
                title={
                  activeEmployee.person &&
                  `Are you sure you want to delete ${activeEmployee.person.firstName} ${activeEmployee.person.lastName}?`
                }
                titleClassName="redHeader"
                isOpen={modalOpen}
                onRequestClose={() => this.setState({ modalOpen: false })}
                submitActions={() => this.handleDeleteEmployee()}
                submitButtonColor="red"
              />
            </div>
          </>
        )}
      </HeaderAndFooter>
    );
  }
}

export const mapStateToProps = state => ({
  activeEmployee: getActiveEmployeeSelector(state),
  message: getMessageSelector(state),
  activeQualification: getActiveQualificationSelector(state),
  activeProject: getActiveProject(state),
  activeLocationId: getActiveLocationId(state),
  qualificationTypes: getQualificationTypesSelector(state),
  activeCompany: getActiveCompany(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  loggedInUser: getLoggedInUser(state),
  documentFolders: getDocumentFoldersSelector(state),
  activeFolder: getActiveFolderSelector(state),
  activeEmployeeDocs: getMyDocumentsSelector(state),
  userCompanies: getUserCompaniesSelector(state),
  accountInfo: getAccountInfo(state),
  activeEmployees: getCompanyActiveEmployeesSelector(state),
  companyUsers: getCompanyUsersSelector(state),
  personnelLoading: getPersonnelLoadingSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  updateLocationEmployee: employee =>
    dispatch(updateLocationEmployeeRequest(employee)),
  deleteLocationEmployee: employee =>
    dispatch(deleteLocationEmployeeRequest(employee)),
  setActiveIncident: incident => dispatch(setActiveIncidentRequest(incident)),
  setActiveQualifications: values => dispatch(setActiveQualification(values)),
  addQualification: values => dispatch(addQualificationRequest(values)),
  updateQualification: value => dispatch(updateQualificationRequest(value)),
  fetchQualificationTypes: () => dispatch(fetchQualificationTypeRequest()),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  createUser: values => dispatch(createUserRequest(values)),
  updateUser: values => dispatch(updateUserRequest(values)),
  deleteQualificationAttachment: values =>
    dispatch(deleteQualificationAttachment(values)),
  deleteQualification: values => dispatch(deleteQualificationRequest(values)),
  setActiveMyDocument: values => dispatch(setActiveMyDocument(values)),
  fetchMyDocuments: values => dispatch(fetchMyDocumentsRequest(values)),
  setActiveEmployee: () =>
    dispatch(
      setActiveEmployeeResponse({
        safetyTrainings: { expiring3Months: [] },
        incidents: [],
        qualifications: { expiring3Months: [], upcomingQuals: [] }
      })
    ),
  resendWelcomeNotification: values =>
    dispatch(resendWelcomeNotificationRequest(values)),
  resetEmployeesPassword: values =>
    dispatch(resetEmployeesPasswordRequest(values)),
  setActiveTemplate: payload => dispatch(setActiveTrainingRequest(payload)),
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload))
});

export default withAlert(
  connect(mapStateToProps, mapDispatchToProps)(ViewEditEmployee)
);
