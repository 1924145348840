import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tabbordion, TabPanel, TabLabel, TabContent } from 'react-tabbordion';
import ReactTable from 'react-table';

import {
  defaultFilterMethod,
  defaultSortMethod,
  filterLocation,
  sortDateMethod,
  filterPerformedDate,
  filterRiskAssessments,
  filterAssignedTo
} from '../../../utils/sortFilterHelpers';
import UserBadge from '../../UserBadge';
import Button from '../../inputs/Button';
import { getActiveJsaSelector } from '../../../selectors/jsa';
import { fetchJsaById } from '../../../api';

import 'react-table/react-table.css';

export class RaStagesCard extends Component {
  state = {
    schedule: '',
    filters: []
  };

  handleFilterChange = filters => {
    localStorage.setItem('raFilters', JSON.stringify(filters));
    this.setState({ filters });
  };

  render() {
    const { jsas, activeCompany, setActiveJsa, users } = this.props;
    const { filters } = this.state;

    const blockElements = {
      content: 'traditional-tabs-content',
      panel: 'traditional-tabs-panel',
      label: 'traditional-tabs-title'
    };

    const statuses = ['New', 'In Progress', 'Severity Review', 'Closed'];

    const reportContent = statuses.map((status, index) => {
      return jsas.filter(jsa => jsa.currentWfStage === `${index}`);
    });

    return (
      <>
        <Tabbordion
          blockElements={blockElements}
          className="traditional-tabs"
          name="tabs"
        >
          {statuses.map((tab, index) => (
            <TabPanel key={index}>
              <TabLabel>{`${tab} (${reportContent[index].length})`}</TabLabel>
              <TabContent>
                <ReactTable
                  data={reportContent[index]}
                  onFilteredChange={filtered =>
                    this.handleFilterChange(filtered)
                  }
                  filtered={filters}
                  resizable={false}
                  filterable={true}
                  defaultFilterMethod={(filter, row) =>
                    defaultFilterMethod(filter, row)
                  }
                  defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
                  noDataText="No results found"
                  defaultPageSize={10}
                  className="-striped -highlight"
                  columns={[
                    {
                      Header: 'GROUP/EST.',
                      accessor: 'locationId',
                      Cell: ({ original }) => (
                        <div>
                          {activeCompany &&
                            activeCompany.locations &&
                            activeCompany.locations.find(
                              location => location._id === original.locationId
                            ) &&
                            activeCompany.locations.find(
                              location => location._id === original.locationId
                            ).name}
                        </div>
                      ),
                      filterMethod: (filter, row) =>
                        filterLocation(filter, row, activeCompany.allLocations),
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: '100%' }}
                          value={filter ? filter.value : 'All Folders'}
                        >
                          <option value="all">Show All</option>
                          {activeCompany &&
                            activeCompany.allLocations &&
                            activeCompany.allLocations.map(
                              (location, index) => (
                                <option key={index} value={location._id}>
                                  {location.name}
                                </option>
                              )
                            )}
                        </select>
                      )
                    },
                    {
                      Header: 'RISK ASSESSMENT(S)',
                      id: 'sections',
                      Cell: ({ original }) =>
                        original.sections
                          .map(section => section.sectionName)
                          .join(', '),
                      filterMethod: (filter, row) =>
                        filterRiskAssessments(filter, row._original.sections)
                    },
                    {
                      Header: 'ASSIGNED TO',
                      id: 'assignedTo',
                      accessor: d => d.assignedTo,
                      Cell: ({ value }) =>
                        value.map(v => (
                          <UserBadge key={v} showName plain userId={v} />
                        )),
                      filterMethod: (filter, row) =>
                        filterAssignedTo(filter, row, users)
                    },
                    {
                      Header: 'DUE BY',
                      accessor: d => d.createdAt,
                      id: 'dueDate',
                      Cell: ({ original }) =>
                        moment(Date.parse(original.dueDate)).format(
                          'MM/DD/YYYY hh:mm a'
                        ),
                      sortMethod: (a, b) => sortDateMethod(a, b),
                      filterMethod: (filter, row) =>
                        filterPerformedDate(filter, row),
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: '100%' }}
                          value={filter ? filter.value : 'All Folders'}
                        >
                          <option value="all">Show All</option>
                          <option value="today">Created Today</option>
                          <option value="last7">
                            Created within Last Week
                          </option>
                          <option value="last30">Created This Month</option>
                          <option value="thisQuarter">
                            Created This Quarter
                          </option>
                          <option value="thisYear">Created This Year</option>
                        </select>
                      )
                    },
                    {
                      Header: 'LAST UPDATED',
                      accessor: d => d.updatedAt,
                      id: 'updatedAt',
                      Cell: ({ original }) =>
                        moment(Date.parse(original.updatedAt)).format(
                          'MM/DD/YYYY hh:mm a'
                        ),
                      sortMethod: (a, b) => sortDateMethod(a, b),
                      filterMethod: (filter, row) =>
                        filterPerformedDate(filter, row),
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={event => onChange(event.target.value)}
                          style={{ width: '100%' }}
                          value={filter ? filter.value : 'All Folders'}
                        >
                          <option value="all">Show All</option>
                          <option value="today">Updated Today</option>
                          <option value="last7">
                            Updated within Last Week
                          </option>
                          <option value="last30">Updated This Month</option>
                          <option value="thisQuarter">
                            Updated This Quarter
                          </option>
                          <option value="thisYear">Updated This Year</option>
                        </select>
                      )
                    },
                    {
                      Header: 'VIEW/EDIT',
                      Cell: ({ original }) => (
                        <Button
                          className="blueButton"
                          onClick={async () => {
                            const payload = {
                              companyId: original.companyId,
                              jsaId: original._id
                            };
                            const jsa = await fetchJsaById(
                              payload.companyId,
                              payload.jsaId
                            );
                            return await setActiveJsa({
                              ...jsa,
                              goToSummary: true
                            });
                          }}
                          text=" View/Edit"
                        />
                      ),
                      filterable: false,
                      sortable: false
                    }
                  ]}
                />
              </TabContent>
            </TabPanel>
          ))}
        </Tabbordion>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  activeRa: getActiveJsaSelector(state)
});

export const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RaStagesCard);
