import React, { Component } from 'react';

import '../input.css';
import './index.css';
import Input from '../';

export default class Textbox extends Component {
  keyDown = e => {
    if (e.keyCode === 27 && this.props.escape) {
      this.props.escape();
    } else if ((e.keyCode === 13 || e.keyCode === 9) && this.props.enter) {
      this.props.enter(this.props.currentValue);
    } else if (this.props.onKeyDown) {
      return this.props.onKeyDown(e);
    }
  };

  render() {
    const {
      name,
      placeholder,
      handleChange,
      onBlur,
      currentValue,
      disabled,
      type,
      className,
      rows,
      onMouseDown,
      autoFocus,
      inputRef
    } = this.props;

    return (
      <Input {...this.props}>
        {type === 'textarea' ? (
          <textarea
            value={currentValue}
            rows={rows}
            placeholder={placeholder}
            className={`textbox ${className}`}
            onChange={handleChange}
            onKeyDown={this.keyDown}
            onBlur={onBlur}
            disabled={disabled}
            min="0"
            id={name}
            onDragStart={e => e.preventDefault()}
            onDrop={e => e.preventDefault()}
            autoFocus={autoFocus}
            ref={inputRef}
          />
        ) : (
          <input
            value={currentValue}
            type={type}
            placeholder={placeholder}
            className={`textbox ${className}`}
            onChange={handleChange}
            onKeyDown={this.keyDown}
            onBlur={onBlur}
            onMouseDown={onMouseDown}
            disabled={disabled}
            min="0"
            step="1"
            id={name}
            autoFocus={autoFocus}
            ref={inputRef}
          />
        )}
      </Input>
    );
  }
}
