import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import history from '../../history';
import moment from 'moment';
import { clearUploadedAttachments } from '../../actions/attachments';
import { setActiveIncidentResponse } from '../../actions/incidents';
import {
  fetchCompanyJsaRequest,
  fetchLocationJsaRequest,
  fetchProjectJsaRequest,
  setActiveJsaRequest,
  setActiveJsaResponse
} from '../../actions/jsa';
import { updateLeftNavRequest } from '../../actions/navigation';
import {
  MONTHLY_FREQUENCY_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS
} from '../../constants/constants';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getActiveJsaSelector,
  getJsaCirclesSelector,
  getJsaLoadingSelector,
  getJsasSelector
} from '../../selectors/jsa';
import { getCompanyUsersSelector } from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import {
  defaultFilterMethod,
  defaultSortMethod,
  filterLocation,
  filterPerformedDate,
  filterRepeatingOn,
  filterRiskAssessments,
  sortDateMethod
} from '../../utils/sortFilterHelpers';
import { AnalyticCircleBox } from '../../components/AnalyticCircle';
import Button from '../../components/inputs/Button';
import Header from '../../components/Header';
import RaStagesCard from '../../components/raCards/RaStagesCard';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import TypeSelection from '../../components/TypeSelection';

import './index.css';
import 'react-table/react-table.css';

export class RaStages extends Component {
  state = {
    schedule: '',
    repeatingFilters: [],
    scanFilters: []
  };

  componentDidMount() {
    if (localStorage.getItem('scanFilters')) {
      this.setState({
        scanFilters: JSON.parse(localStorage.getItem('scanFilters'))
      });
    }

    if (localStorage.getItem('repeatingFilters')) {
      this.setState({
        repeatingFilters: JSON.parse(localStorage.getItem('repeatingFilters'))
      });
    }

    this.props.updateLeftNav('Risk Assessment');
    this.props.setActiveIncident();
    this.props.clearAttachments();
    this.setState({
      schedule: 'Active'
    });

    this.getJsas('Active');
  }

  getJsas = schedule => {
    const group = this.props.activeLocationId;
    const project = this.props.activeProject;

    const isRepeating =
      ['repeating', 'scan'].findIndex(option => option === schedule) > -1;

    if (project && project._id) {
      this.props.fetchProjectJsas(isRepeating, true);
    } else if (group && group._id) {
      this.props.fetchLocationJsas(isRepeating, true);
    } else {
      this.props.fetchCompanyJsas(isRepeating, true);
    }
  };

  handleTypeSelection = async stateObject => {
    await this.setState(stateObject);
    const schedule =
      stateObject.schedule === 'Active'
        ? 'immediate'
        : stateObject.schedule === 'Repeating'
        ? 'repeating'
        : 'scan';
    this.getJsas(schedule);
  };

  handleFilterChange = (filters, filterType) => {
    localStorage.setItem(`${filterType}`, JSON.stringify(filters));
    if (filterType === 'repeatingFilters') {
      this.setState({ repeatingFilters: filters });
    } else {
      this.setState({ scanFilters: filters });
    }
  };

  render() {
    const { schedule, scanFilters, repeatingFilters } = this.state;

    const {
      circles,
      jsas,
      activeCompany,
      loggedInUser,
      setActiveJsa,
      setActiveJsaResponse,
      users
    } = this.props;

    const getMostRecentUpdate = auditLog => {
      return moment(Date.parse(auditLog[auditLog.length - 1]?.when)).format(
        'MM/DD/YYYY hh:mm a'
      );
    };

    return (
      <div className="reports">
        <ScrollToTopOnMount />
        <Header
          title="Risk Assessments"
          rightButtons={
            loggedInUser.accessLevel !== '100' && {
              text: 'Assign Risk Assessment',
              className: 'blueButton',
              onClick: () =>
                setActiveJsaResponse() && history.push('/app/raContainer')
            }
          }
        />
        <TypeSelection
          selected={schedule}
          selectedArray={['Active', 'Repeating', 'On Demand']}
          onClick={value => this.handleTypeSelection({ schedule: value })}
        />
        {!(schedule === 'repeating' || schedule === 'scan') && (
          <AnalyticCircleBox circles={circles} />
        )}
        <div className="reports-content">
          {schedule === 'Repeating' ? (
            <ReactTable
              style={{ flex: 1, backgroundColor: 'white' }}
              data={jsas.filter(jsa => jsa.schedule === 'repeating')}
              onFilteredChange={filtered =>
                this.handleFilterChange(filtered, 'repeatingFilters')
              }
              filtered={repeatingFilters}
              resizable={false}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                defaultFilterMethod(filter, row)
              }
              defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
              noDataText="No results found"
              defaultPageSize={10}
              className="-striped -highlight"
              columns={[
                {
                  Header: 'GROUP/EST.',
                  accessor: 'locationId',
                  Cell: ({ original }) => (
                    <div>
                      {activeCompany &&
                        activeCompany.locations &&
                        activeCompany.locations.find(
                          location => location._id === original.locationId
                        ) &&
                        activeCompany.locations.find(
                          location => location._id === original.locationId
                        ).name}
                    </div>
                  ),
                  filterMethod: (filter, row) =>
                    filterLocation(filter, row, activeCompany.allLocations),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      {activeCompany &&
                        activeCompany.allLocations &&
                        activeCompany.allLocations.map((location, index) => (
                          <option key={index} value={location._id}>
                            {location.name}
                          </option>
                        ))}
                    </select>
                  )
                },
                {
                  Header: 'RISK ASSESSMENT(S)',
                  id: 'sections',
                  Cell: ({ original }) =>
                    original.sections
                      .map(section => section.sectionName)
                      .join(', '),
                  filterMethod: (filter, row) =>
                    filterRiskAssessments(filter, row._original.sections)
                },
                {
                  Header: 'LAST UPDATED',
                  accessor: d => d.lastUpdatedAt,
                  id: 'lastUpdatedAt',
                  Cell: ({ original }) =>
                    getMostRecentUpdate(original.auditLog),
                  sortMethod: (a, b) => sortDateMethod(a, b),
                  filterMethod: (filter, row) =>
                    filterPerformedDate(filter, row),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="today">Updated Today</option>
                      <option value="last7">Updated within Last Week</option>
                      <option value="last30">Updated This Month</option>
                      <option value="thisQuarter">Updated This Quarter</option>
                      <option value="thisYear">Updated This Year</option>
                    </select>
                  )
                },
                {
                  Header: 'REPEAT ON',
                  id: 'repeatOn',
                  Cell: ({ original }) => (
                    <div>
                      {original.frequency === 'monthly' ? (
                        <div>
                          {
                            MONTHLY_FREQUENCY_OPTIONS.filter(
                              month => month.value === original.repeatingOn[0]
                            )[0].label
                          }
                        </div>
                      ) : original.frequency === 'yearly' ? (
                        <div>
                          {
                            YEARLY_FREQUENCY_OPTIONS.filter(
                              month => month.value === original.repeatingOn[0]
                            )[0].label
                          }
                        </div>
                      ) : (
                        original.repeatingOn
                          .map(repeat => repeat.toUpperCase())
                          .join(', ')
                      )}
                    </div>
                  ),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="weekly">Repeating Weekly</option>
                      <option value="monthly">Repeating Monthly</option>
                      <option value="yearly">Repeating Yearly</option>
                    </select>
                  ),
                  filterMethod: (filter, row) => filterRepeatingOn(filter, row),
                  sortable: false
                },
                {
                  Header: 'VIEW/EDIT',
                  Cell: ({ original }) => (
                    <Button
                      color="blue"
                      onClick={() => setActiveJsa({ ...original })}
                      text=" View/Edit"
                    />
                  ),
                  filterable: false,
                  sortable: false
                }
              ]}
            />
          ) : schedule === 'On Demand' ? (
            <ReactTable
              style={{ flex: 1, backgroundColor: 'white' }}
              data={jsas.filter(jsa => jsa.schedule === 'scan')}
              onFilteredChange={filtered =>
                this.handleFilterChange(filtered, 'scanFilters')
              }
              filtered={scanFilters}
              resizable={false}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                defaultFilterMethod(filter, row)
              }
              defaultSortMethod={(a, b) => defaultSortMethod(a, b)}
              noDataText="No results found"
              defaultPageSize={10}
              className="-striped -highlight"
              columns={[
                {
                  Header: 'GROUP/EST.',
                  accessor: 'locationId',
                  Cell: ({ original }) => (
                    <div>
                      {activeCompany &&
                        activeCompany.locations &&
                        activeCompany.locations.find(
                          location => location._id === original.locationId
                        ) &&
                        activeCompany.locations.find(
                          location => location._id === original.locationId
                        ).name}
                    </div>
                  ),
                  filterMethod: (filter, row) =>
                    filterLocation(filter, row, activeCompany.allLocations),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      {activeCompany &&
                        activeCompany.allLocations &&
                        activeCompany.allLocations.map((location, index) => (
                          <option key={index} value={location._id}>
                            {location.name}
                          </option>
                        ))}
                    </select>
                  )
                },
                {
                  Header: 'RISK ASSESSMENT',
                  id: 'sections',
                  Cell: ({ original }) =>
                    original.sections
                      .map(section => section.sectionName)
                      .join(', '),
                  filterMethod: (filter, row) =>
                    filterRiskAssessments(filter, row._original.sections)
                },
                {
                  Header: 'LAST UPDATED',
                  accessor: d => d.updatedAt,
                  id: 'updatedAt',
                  Cell: ({ original }) =>
                    getMostRecentUpdate(original.auditLog),
                  sortMethod: (a, b) => sortDateMethod(a, b),
                  filterMethod: (filter, row) =>
                    filterPerformedDate(filter, row),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="today">Updated Today</option>
                      <option value="last7">Updated within Last Week</option>
                      <option value="last30">Updated This Month</option>
                      <option value="thisQuarter">Updated This Quarter</option>
                      <option value="thisYear">Updated This Year</option>
                    </select>
                  )
                },
                {
                  Header: 'QR CODE',
                  Cell: ({ original }) => (
                    <Button
                      onClick={() =>
                        window.open(`https://${original.qrCodeUrl}`, '_blank')
                      }
                      text="Download QR Code"
                      color="green"
                    />
                  ),
                  filterable: false,
                  sortable: false
                },
                {
                  Header: 'VIEW/EDIT',
                  Cell: ({ original }) => (
                    <Button
                      color="blue"
                      onClick={() => setActiveJsa({ ...original })}
                      text=" View/Edit"
                    />
                  ),
                  filterable: false,
                  sortable: false
                }
              ]}
            />
          ) : (
            <RaStagesCard
              activeCompany={activeCompany}
              jsas={jsas}
              setActiveJsa={setActiveJsa}
              users={users}
            />
          )}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  circles: getJsaCirclesSelector(state),
  jsas: getJsasSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocationId: getActiveLocationId(state),
  jsaLoading: getJsaLoadingSelector(state),
  loggedInUser: getLoggedInUser(state),
  activeProject: getActiveProject(state),
  activeRa: getActiveJsaSelector(state),
  users: getCompanyUsersSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateLeftNav: section => dispatch(updateLeftNavRequest(section)),
  setActiveIncident: () => dispatch(setActiveIncidentResponse()),
  fetchCompanyJsas: (isRecurring, isLean) =>
    dispatch(
      fetchCompanyJsaRequest({
        page: 1,
        limit: 10000,
        removeClosed: false,
        isRecurring,
        isLean
      })
    ),
  fetchLocationJsas: (isRecurring, isLean) =>
    dispatch(
      fetchLocationJsaRequest({
        page: 1,
        limit: 10000,
        removeClosed: false,
        isRecurring,
        isLean
      })
    ),
  fetchProjectJsas: (isRecurring, isLean) =>
    dispatch(
      fetchProjectJsaRequest({
        page: 1,
        limit: 10000,
        removeClosed: false,
        isRecurring,
        isLean
      })
    ),
  setActiveJsa: jsa => dispatch(setActiveJsaRequest(jsa)),
  setActiveJsaResponse: () => dispatch(setActiveJsaResponse({})),
  clearAttachments: () => dispatch(clearUploadedAttachments())
});

export default connect(mapStateToProps, mapDispatchToProps)(RaStages);
