import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, required } from '../../utils/formValidation';

import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';

const ChangePasswordForm = props => {
  const { handleSubmit } = props;
  return (
    <form className="newCompanyForm" onSubmit={handleSubmit}>
      <div className="form-fields">
        <Field
          name="new_password"
          type="password"
          component={renderField}
          label="New Password"
          validate={[required]}
          placeholder={''}
          requiredLabel={true}
        />
        <Field
          name="confirm_password"
          type="password"
          component={renderField}
          label="Confirm New Password"
          validate={[required]}
          placeholder={''}
          requiredLabel={true}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'ChangePasswordForm'
})(ChangePasswordForm);
