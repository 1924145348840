import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import history from '../history';
import { isEmpty } from 'lodash';
import { clearUploadedAttachments } from '../actions/attachments';
import {
  addDailyReportsResponse,
  addDocumentFolderResponse,
  addDocumentsToFolderResponse,
  addQualificationResponse,
  addSafetyInstructionResponse,
  addSdsResponse,
  addToolboxTalksResponse,
  deleteDailyReportsResponse,
  deleteDocumentFolderResponse,
  deleteQualificationResponse,
  deleteSafetyInstructionResponse,
  deleteSdsResponse,
  deleteToolboxTalksResponse,
  fetchCustomDocumentsResponse,
  fetchCustomDocumentTemplatesResponse,
  fetchDailyReportsResponse,
  fetchDocumentFoldersResponse,
  fetchMyDocumentsResponse,
  fetchQualificationResponse,
  fetchQualificationTypeResponse,
  fetchRequireAuthResponse,
  fetchSafetyInstructionResponse,
  fetchSdsResponse,
  fetchToolboxTalksResponse,
  setActiveCustomDocument,
  setActiveCustomDocumentTemplate,
  setActiveDailyReport,
  setActiveDocumentFolderResponse,
  setActiveQualification,
  setActiveSafetyInstruction,
  setActiveSds,
  setActiveToolboxTalks,
  updateDailyReportsResponse,
  updateDocumentFolderResponse,
  updateQualificationResponse,
  updateSafetyInstructionResponse,
  updateSdsResponse,
  updateToolboxTalksResponse
} from '../actions/documents';
import { addMessage, removeMessage } from '../actions/messages';
import { setActiveEmployeeResponse } from '../actions/personnel';
import { fetchTrainingsResponse } from '../actions/training';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import {
  getActiveCustomDocSelector,
  getActiveDailyReportSelector,
  getActiveQualificationSelector,
  getActiveSafetyInstructionSelector,
  getActiveSdsSelector,
  getActiveToolboxTalkSelector
} from '../selectors/documents';
import {
  getActiveEmployeeSelector,
  getCompanyUsersSelector
} from '../selectors/personnel';
import { getLoggedInUser } from '../selectors/users';

export function* fetchCompanySafetyInstructions(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let response = [];

    if (project) {
      response = yield call(
        api.fetchDocumentsByTypeAndProject,
        company._id,
        'SafetyInstruction',
        payload.page,
        payload.limit,
        project._id
      );
    } else if (group) {
      response = yield call(
        api.fetchDocumentsByTypeAndGroup,
        company._id,
        'SafetyInstruction',
        payload.page,
        payload.limit,
        group._id
      );
    } else {
      response = yield call(
        api.fetchDocumentsByType,
        company._id,
        'SafetyInstruction',
        payload.page,
        payload.limit
      );
    }

    if (response.docs.every(doc => doc.attachments)) {
      // do nothing
    } else {
      const attachments = yield all(
        response.docs.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document._id,
            'documents'
          )
        )
      );

      response.docs.map((res, index) => (res.attachments = attachments[index]));
    }

    yield put(fetchSafetyInstructionResponse(response.docs));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCompanySds(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let response = [];

    if (project) {
      response = yield call(
        api.fetchDocumentsByTypeAndProject,
        company._id,
        'SDS',
        payload.page,
        payload.limit,
        project._id
      );
    } else if (group) {
      response = yield call(
        api.fetchDocumentsByTypeAndGroup,
        company._id,
        'SDS',
        payload.page,
        payload.limit,
        group._id
      );
    } else {
      response = yield call(
        api.fetchDocumentsByType,
        company._id,
        'SDS',
        payload.page,
        payload.limit
      );
    }
    if (response.docs.every(doc => doc.attachments)) {
      // do nothing
    } else {
      const attachments = yield all(
        response.docs.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document._id,
            'documents'
          )
        )
      );

      response.docs.map((res, index) => (res.attachments = attachments[index]));
    }
    yield put(fetchSdsResponse(response.docs));
  } catch (e) {
    console.log(e);
  }
}

export function* addSds(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const activeCompany = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const groupIds = payload.groupIds.map(group => group.value);
    const projectIds = payload.projectIds.map(project => project.value);

    payload = {
      label: payload.label,
      expires: moment(payload.expires),
      documentData: {
        ghsLabels: payload.ghsLabels
      },
      documentType: 'SDS',
      companyId: activeCompany._id,
      groupIds: group ? [group._id] : groupIds,
      projectIds: project ? [project._id] : projectIds,
      documentFolderId: payload.documentFolderId
    };

    const response = yield call(api.addDocument, activeCompany._id, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    yield put(addSdsResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateSds(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);

    const groupId = payload.groupIds.map(group => {
      if (group.value) {
        return group.value;
      }

      return group;
    });

    const projectId = payload.projectIds.map(project => {
      if (project.value) {
        return project.value;
      }

      return project;
    });

    payload = {
      ...payload,
      documentData: {
        ghsLabels: payload.ghsLabels
      },
      expires: moment(payload.expires),
      groupIds: groupId,
      projectIds: projectId
    };

    const response = yield call(api.updateDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = [...payload.attachments, ...newAttachments];

    yield put(updateSdsResponse(response));
    yield put(setActiveSds(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteSds(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteDocument, payload);

    yield put(deleteSdsResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addSafetyInstruction(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const groupIds =
      payload.locationId && payload.locationId.map(group => group.value);
    const projectIds =
      payload.projectId && payload.projectId.map(project => project.value);

    payload = {
      ...payload,
      expires: payload.expires
        ? moment(payload.expires).format('MM/DD/YYYY')
        : '',
      documentData: {
        ...payload.documentData
      },
      documentType: 'SafetyInstruction',
      companyId: company._id,
      groupIds: group ? [group._id] : groupIds,
      projectIds: project ? [project._id] : projectIds
    };

    delete payload.locationId;
    delete payload.projectId;

    const response = yield call(api.addDocument, company._id, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    yield put(addSafetyInstructionResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateSafetyInstruction(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);

    const groupIds =
      payload.locationId &&
      payload.locationId.map(group => {
        if (group.value) {
          return group.value;
        }

        return group;
      });

    const projectIds =
      payload.projectId &&
      payload.projectId.map(project => {
        if (project.value) {
          return project.value;
        }

        return project;
      });

    payload = {
      ...payload,
      expires: moment(payload.expires),
      groupIds,
      projectIds
    };

    const response = yield call(api.updateDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = [...payload.attachments, ...newAttachments];

    yield put(updateSafetyInstructionResponse(response));
    yield put(setActiveSafetyInstruction(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteSafetyInstruction(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteDocument, payload);

    yield put(deleteSafetyInstructionResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteSafetyInstructionAttachment(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeSI = yield select(getActiveSafetyInstructionSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeSI.companyId,
      activeSI._id,
      'documents'
    );

    activeSI.attachments = attachments;

    yield put(updateSafetyInstructionResponse(activeSI));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteSdsAttachment(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeSds = yield select(getActiveSdsSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeSds.companyId,
      activeSds._id,
      'documents'
    );

    activeSds.attachments = attachments;

    yield put(updateSdsResponse(activeSds));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteDailyReportAttachment(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeDailyReport = yield select(getActiveDailyReportSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeDailyReport.companyId,
      activeDailyReport._id,
      'documents'
    );

    activeDailyReport.attachments = attachments;

    yield put(updateDailyReportsResponse(activeDailyReport));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteToolboxTalkAttachment(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeToolbox = yield select(getActiveToolboxTalkSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeToolbox.companyId,
      activeToolbox._id,
      'documents'
    );

    activeToolbox.attachments = attachments;

    yield put(updateToolboxTalksResponse(activeToolbox));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteQualificationAttachment(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeQual = yield select(getActiveQualificationSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeQual.companyId,
      activeQual._id,
      'documents'
    );

    activeQual.attachments = attachments;

    yield put(updateQualificationResponse(activeQual));
    yield put(setActiveQualification(activeQual));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchDailyReports(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let response = [];

    if (project) {
      response = yield call(
        api.fetchDocumentsByTypeAndProject,
        company._id,
        'DailyReports',
        payload.page,
        payload.limit,
        project._id
      );
    } else if (group) {
      response = yield call(
        api.fetchDocumentsByTypeAndGroup,
        company._id,
        'DailyReports',
        payload.page,
        payload.limit,
        group._id
      );
    } else {
      response = yield call(
        api.fetchDocumentsByType,
        company._id,
        'DailyReports',
        payload.page,
        payload.limit
      );
    }

    if (response.docs.every(doc => doc.attachments)) {
      // do nothing
    } else {
      const attachments = yield all(
        response.docs.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document._id,
            'documents'
          )
        )
      );

      response.docs.map((res, index) => (res.attachments = attachments[index]));
    }
    yield put(fetchDailyReportsResponse(response.docs));
  } catch (e) {
    console.log(e);
  }
}

export function* addDailyReport(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const activeCompany = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const groupIds =
      payload.locationId && payload.locationId.map(group => group.value);
    const projectIds =
      payload.projectId && payload.projectId.map(project => project.value);

    payload = {
      ...payload,
      expires: moment(),
      documentData: {
        ...payload.documentData,
        ...(payload.documentData.employees && {
          employees: payload.documentData.employees.map(
            employee => employee.value
          )
        }),
        ...(payload.documentData.contractors && {
          contractors: payload.documentData.contractors.map(
            contractor => contractor.value
          )
        })
      },
      documentType: 'DailyReports',
      companyId: activeCompany._id,
      groupIds: group ? [group._id] : groupIds,
      projectIds: project ? [project._id] : projectIds
    };

    const response = yield call(api.addDocument, activeCompany._id, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    yield put(addDailyReportsResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateDailyReport(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const groupIds = payload.locationId.map(group => {
      if (group.value) {
        return group.value;
      }

      return group;
    });

    const projectIds = payload.projectId.map(project => {
      if (project.value) {
        return project.value;
      }

      return project;
    });

    payload.documentData.employees =
      payload.documentData.employees &&
      payload.documentData.employees.map(day => {
        if (day.value) {
          return day.value;
        }

        return day;
      });

    payload.documentData.contractors =
      payload.documentData.contractors &&
      payload.documentData.contractors.map(day => {
        if (day.value) {
          return day.value;
        }

        return day;
      });

    const response = yield call(api.updateDocument, {
      ...payload,
      groupIds,
      projectIds
    });

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = [...payload.attachments, ...newAttachments];

    yield put(updateDailyReportsResponse(response));
    yield put(setActiveDailyReport(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteDailyReport(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteDocument, payload);

    yield put(deleteDailyReportsResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchToolboxTalks(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let response = [];

    if (project) {
      response = yield call(
        api.fetchDocumentsByTypeAndProject,
        company._id,
        'ToolboxTalks',
        payload.page,
        payload.limit,
        project._id
      );
    } else if (group) {
      response = yield call(
        api.fetchDocumentsByTypeAndGroup,
        company._id,
        'ToolboxTalks',
        payload.page,
        payload.limit,
        group._id
      );
    } else {
      response = yield call(
        api.fetchDocumentsByType,
        company._id,
        'ToolboxTalks',
        payload.page,
        payload.limit
      );
    }

    if (response.docs.every(doc => doc.attachments)) {
      // do nothing
    } else {
      const attachments = yield all(
        response.docs.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document._id,
            'documents'
          )
        )
      );
      response.docs.map((res, index) => (res.attachments = attachments[index]));
    }

    const tasks = yield all(
      response.docs.map(document =>
        call(api.fetchTasksByOwnerId, document._id)
      )
    );

    response.docs.map((res, index) => (res.tasks = tasks[index].docs));

    yield put(fetchToolboxTalksResponse(response.docs));
  } catch (e) {
    console.log(e);
  }
}

export function* addToolboxTalks(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const groupIds =
      payload.locationId && payload.locationId.map(group => group.value);
    const projectIds =
      payload.projectId && payload.projectId.map(project => project.value);

    payload = {
      ...payload,
      documentData: {
        ...payload?.documentData,
        ...(payload.documentData?.employees && {
          employees: payload.documentData.employees.map(
            employee => employee.value
          )
        })
      },
      documentType: 'ToolboxTalks',
      companyId: activeCompany._id,
      groupIds: group ? [group._id] : groupIds,
      projectIds: project ? [project._id] : projectIds
    };

    const response = yield call(api.addDocument, activeCompany._id, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;
    response.tasks = [];

    yield put(addToolboxTalksResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateToolboxTalks(api, { payload }) {
  try {
    yield put(removeMessage());
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);

    payload.documentData.employees =
      payload.documentData.employees &&
      payload.documentData.employees.map(day => {
        if (day.value) {
          return day.value;
        }

        return day;
      });

    const groupIds = payload.locationId.map(group => {
      if (group.value) {
        return group.value;
      }

      return group;
    });

    const projectIds = payload.projectId.map(project => {
      if (project.value) {
        return project.value;
      }

      return project;
    });

    const response = yield call(api.updateDocument, {
      ...payload,
      groupIds,
      projectIds
    });
    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    const tasks = yield call(
      api.fetchTasksByOwnerId,
      response._id
    );

    response.attachments = [...payload.attachments, ...newAttachments];
    response.tasks = [...payload.tasks, ...tasks.docs];

    yield put(updateToolboxTalksResponse(response));
    yield put(setActiveToolboxTalks(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteToolboxTalks(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteDocument, payload);

    yield put(deleteToolboxTalksResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchQualifications(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let response = [];

    if (project) {
      response = yield call(
        api.fetchDocumentsByTypeAndProject,
        company._id,
        'Qualification',
        payload.page,
        payload.limit,
        project._id
      );
    } else if (group) {
      response = yield call(
        api.fetchDocumentsByTypeAndGroup,
        company._id,
        'Qualification',
        payload.page,
        payload.limit,
        group._id
      );
    } else {
      response = yield call(
        api.fetchDocumentsByType,
        company._id,
        'Qualification',
        payload.page,
        payload.limit
      );
    }

    if (response.docs.every(doc => doc.attachments)) {
      // do nothing
    } else {
      const attachments = yield all(
        response.docs.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document._id,
            'documents'
          )
        )
      );

      response.docs.map((res, index) => (res.attachments = attachments[index]));
    }

    yield put(fetchQualificationResponse(response.docs));
  } catch (e) {
    console.log(e);
  }
}

export function* addQualification(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const projectIds =
      payload.projectIds && payload.projectIds.map(project => project.value);

    const employee = payload.employeeId.map(employee => employee.value);

    const groupIds =
      payload.groupIds && payload.groupIds.map(group => group.value);

    const isFromEmployee = payload.isFromEmployee;

    payload = {
      expires: moment(new Date(payload.expires)),
      documentData: {
        ...payload,
        scheduledDate: moment(new Date(payload.scheduledDate))
      },
      documentType: 'Qualification',
      companyId: activeCompany._id,
      groupIds: group ? [group] : groupIds,
      projectIds: project ? [project] : projectIds,
      employees: employee,
      documentFolderId: payload.documentFolderId
    };

    delete payload.documentData.expires;
    delete payload.documentData.modalOpen;

    const response = yield call(api.addDocument, activeCompany._id, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    if (isFromEmployee) {
      const activeEmployee = yield select(getActiveEmployeeSelector);

      activeEmployee.qualifications.documents = [
        ...activeEmployee.qualifications.documents,
        response
      ];

      yield put(setActiveEmployeeResponse(activeEmployee));
    }

    yield put(setActiveQualification(response));
    yield put(addQualificationResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateQualification(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const isFromEmployee = payload.isFromEmployee;

    payload.employees =
      payload.employees &&
      payload.employees.map(day => {
        if (day.value) {
          return day.value;
        }

        return day;
      });

    const projectIds =
      payload.projectIds &&
      payload.projectIds.map(project => {
        if (project.value) {
          return project.value;
        }

        return project;
      });

    const groupIds =
      payload.groupIds &&
      payload.groupIds.map(group => {
        if (group.value) {
          return group.value;
        }

        return group;
      });

    payload = {
      expires: moment(new Date(payload.expires)),
      documentData: {
        ...payload,
        scheduledDate: moment(new Date(payload.scheduledDate))
      },
      groupIds: groupIds,
      projectIds: projectIds,
      documentType: 'Qualification',
      companyId: activeCompany._id,
      _id: payload._id,
      documentFolderId: payload.documentFolderId
    };

    const response = yield call(api.updateDocument, payload);

    if (uploadedAttachments.length > 0) {
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );
    }

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeCompany._id,
      response._id,
      'documents'
    );

    response.attachments = attachments;

    if (isFromEmployee) {
      const activeEmployee = yield select(getActiveEmployeeSelector);

      activeEmployee.qualifications.documents = attachments;

      yield put(setActiveEmployeeResponse(activeEmployee));
    }

    yield put(setActiveQualification(response));
    yield put(updateQualificationResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchQualificationTypes(api) {
  try {
    const company = yield select(getActiveCompany);

    const response = yield call(api.fetchQualificationType, company._id);

    yield put(fetchQualificationTypeResponse(response));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteQualification(api, { payload }) {
  try {
    yield put(removeMessage());
    yield call(api.deleteDocument, payload);

    if (payload.isFromEmployee) {
      const activeEmployee = yield select(getActiveEmployeeSelector);

      const docIndex = activeEmployee.qualifications.documents.findIndex(
        doc => doc._id === payload._id
      );

      activeEmployee.qualifications.documents = [
        ...activeEmployee.qualifications.documents.slice(0, docIndex),
        ...activeEmployee.qualifications.documents.slice(docIndex + 1)
      ];

      yield put(setActiveEmployeeResponse(activeEmployee));
    }

    yield put(deleteQualificationResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchDocumentsByFolder(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let response = [];

    if (payload.documentType === 'SafetyTraining') {
      if (project) {
        response = yield call(api.fetchTrainingsByProject, {
          projectId: project._id,
          documentFolderId: payload._id
        });
      } else if (group) {
        response = yield call(api.fetchTrainingsByGroup, {
          groupId: group._id,
          documentFolderId: payload._id
        });
      } else {
        response = yield call(api.fetchTrainings, {
          companyId: company._id,
          documentFolderId: payload._id
        });
      }

      const training = response.filter(training => !training.legacy);
      const legacyTrainings = response.filter(training => training.legacy);

      const attachments = yield all(
        legacyTrainings.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document.masterTemplateId,
            'documents'
          )
        )
      );

      legacyTrainings.map(
        (res, index) => (res.attachments = attachments[index])
      );

      yield put(fetchTrainingsResponse([...training, ...legacyTrainings]));
      yield put(setActiveDocumentFolderResponse(payload));
    } else {
      if (project) {
        response = yield call(
          api.fetchDocumentsByTypeAndProject,
          company._id,
          payload.documentType,
          payload.page,
          payload.limit,
          project._id,
          payload._id
        );
      } else if (group) {
        response = yield call(
          api.fetchDocumentsByTypeAndGroup,
          company._id,
          payload.documentType,
          payload.page,
          payload.limit,
          group._id,
          payload._id
        );
      } else {
        response = yield call(
          api.fetchDocumentsByType,
          company._id,
          payload.documentType,
          payload.page,
          payload.limit,
          payload._id
        );
      }

      delete payload.page;
      delete payload.limit;
      if (response.docs.every(doc => doc.attachments)) {
        // do nothing
      } else {
        const attachments = yield all(
          response.docs.map(document =>
            call(
              api.fetchAttachmentByOwnerId,
              company._id,
              document._id,
              'documents'
            )
          )
        );
        response.docs.map(
          (res, index) => (res.attachments = attachments[index])
        );
      }

      yield put(setActiveDocumentFolderResponse(payload));

      if (payload.documentType === 'SDS') {
        yield put(fetchSdsResponse(response.docs));
      } else if (payload.documentType === 'SafetyInstruction') {
        yield put(fetchSafetyInstructionResponse(response.docs));
      } else if (payload.documentType === 'Qualification') {
        yield put(fetchQualificationResponse(response.docs));
      } else if (payload.documentType === 'DailyReports') {
        yield put(fetchDailyReportsResponse(response.docs));
      } else if (payload.documentType === 'ToolboxTalks') {
        yield put(fetchToolboxTalksResponse(response.docs));
      }
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchDocumentFolders(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);
    const response = yield call(
      api.fetchDocumentFoldersByType,
      activeCompany._id,
      payload.documentType
    );

    yield put(fetchDocumentFoldersResponse(response));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addDocumentFolder(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    payload = {
      ...payload,
      companyId: activeCompany._id
    };

    const response = yield call(
      api.addDocumentFolder,
      activeCompany._id,
      payload
    );

    yield put(addDocumentFolderResponse(response));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateDocumentFolder(api, { payload }) {
  try {
    yield put(removeMessage());
    const response = yield call(api.updateDocumentFolder, payload);

    yield put(updateDocumentFolderResponse(response));
    yield put(setActiveDocumentFolderResponse(response));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteDocumentFolder(api, { payload }) {
  try {
    yield put(removeMessage());
    const documents = payload.documents;
    delete payload.documents;

    let response = {};

    if (payload.deleteAll) {
      delete payload.deleteAll;

      if (payload.documentType === 'SafetyTraining') {
        yield all(
          documents.map(document => call(api.deleteTraining, document))
        );
      } else {
        yield all(
          documents.map(document => call(api.deleteDocument, document))
        );
      }

      response = yield call(api.deleteDocumentFolder, payload);
    } else {
      delete payload.deleteAll;

      if (payload.documentType === 'SafetyTraining') {
        yield all(
          documents.map(document =>
            call(api.updateTraining, { ...document, documentFolderId: '' })
          )
        );
      } else {
        yield all(
          documents.map(document =>
            call(api.updateDocument, { ...document, documentFolderId: '' })
          )
        );
      }

      response = yield call(api.deleteDocumentFolder, payload);
    }

    yield put(deleteDocumentFolderResponse(response));
    yield put(setActiveDocumentFolderResponse({}));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));

    if (payload.documentType === 'Safety Training') {
      response = yield call(api.fetchTrainings, {
        companyId: payload.companyId
      });

      const training = response.filter(training => !training.legacy);
      const legacyTrainings = response.filter(training => training.legacy);

      const attachments = yield all(
        legacyTrainings.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            payload.companyId,
            document.masterTemplateId,
            'documents'
          )
        )
      );

      legacyTrainings.map(
        (res, index) => (res.attachments = attachments[index])
      );

      yield put(fetchTrainingsResponse([...training, ...legacyTrainings]));
    } else if (payload.documentType === 'SDS') {
      yield call(fetchCompanySds, api, {
        payload: {
          page: 1,
          limit: 10000
        }
      });
    } else if (payload.documentType === 'SafetyInstruction') {
      yield call(fetchCompanySafetyInstructions, api, {
        payload: {
          page: 1,
          limit: 10000
        }
      });
    } else if (payload.documentType === 'Qualification') {
      yield call(fetchQualifications, api, {
        payload: {
          page: 1,
          limit: 10000
        }
      });
    } else if (payload.documentType === 'DailyReports') {
      yield call(fetchDailyReports, api, {
        payload: {
          page: 1,
          limit: 10000
        }
      });
    } else if (payload.documentType === 'ToolboxTalks') {
      yield call(fetchToolboxTalks, api, {
        payload: {
          page: 1,
          limit: 10000
        }
      });
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addDocumentsToFolder(api, { payload }) {
  try {
    yield put(removeMessage());

    yield all(
      payload.documents.map(document =>
        payload.documentType === 'SafetyTraining'
          ? call(api.updateTraining, {
              ...document,
              documentFolderId: payload.documentFolderId
            })
          : call(api.updateDocument, {
              ...document,
              documentFolderId: payload.documentFolderId
            })
      )
    );
    yield put(addDocumentsToFolderResponse());
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchMyDocuments(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const allEmployees = yield select(getCompanyUsersSelector);
    const activeUser = yield select(getLoggedInUser);
    const activeEmployee = yield select(getActiveEmployeeSelector);

    const activeEmployeeIndex = allEmployees.findIndex(
      emp => emp.userAccount._id === activeUser._id
    );

    const response = yield call(
      api.fetchDocumentsByType,
      company._id,
      'EmployeeDocument',
      1,
      10000,
      null,
      activeEmployee._id
        ? activeEmployee._id
        : allEmployees[activeEmployeeIndex]._id
    );
    if (response.docs.every(doc => doc.attachments)) {
      // do nothing
    } else {
      const attachments = yield all(
        response.docs.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document._id,
            'EmployeeDocument'
          )
        )
      );

      response.docs.map((res, index) => (res.attachments = attachments[index]));
    }

    const continuousCareResponse = yield call(
      api.fetchContinuousCareByEmployeeId,
      {
        companyId: company._id,
        employeeId: activeEmployee._id
          ? activeEmployee._id
          : allEmployees[activeEmployeeIndex]._id
      }
    );

    const trainings = yield call(api.fetchTrainingsByUser, {
      companyId: company._id,
      userId: activeEmployee._id
        ? activeEmployee?.userAccount?._id
        : activeUser._id,
      isCompleted: true,
      isApproved: true
    });

    const completedAndApprovedTraining = trainings.map(training => {
      return {
        ...training,
        label: training.title,
        formData: {
          employeeName: `${activeUser.firstName} ${activeUser.lastName}`
        },
        isPerform: true
      };
    });

    yield put(
      fetchMyDocumentsResponse([
        ...response.docs,
        ...continuousCareResponse,
        ...completedAndApprovedTraining
      ])
    );
  } catch (e) {
    console.log(e);
  }
}

export function* createMyDocument(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const addedAttachments = yield select(getAddedAttachmentsSelector);
    const allEmployees = yield select(getCompanyUsersSelector);
    const activeUser = yield select(getLoggedInUser);
    let activeEmployee = yield select(getActiveEmployeeSelector);

    if (!activeEmployee || isEmpty(activeEmployee)) {
      activeEmployee = allEmployees.find(
        emp => emp.userAccount._id === activeUser._id
      );
    }

    payload = {
      label: payload.label,
      documentType: 'EmployeeDocument',
      companyId: company._id,
      employees: activeEmployee._id,
      documentData: {
        description: payload.description
      }
    };

    const response = yield call(api.addDocument, company._id, payload);

    yield all(
      addedAttachments.map(attachment =>
        call(api.updateAttachment, {
          ...attachment,
          ownerId: response._id
        })
      )
    );

    yield put(clearUploadedAttachments());

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* updateMyDocument(api, { payload }) {
  try {
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    payload = {
      ...payload,
      documentData: {
        description: payload.description
      }
    };

    const response = yield call(api.updateDocument, payload);

    yield all(
      addedAttachments.map(attachment =>
        call(api.updateAttachment, {
          ...attachment,
          ownerId: response._id
        })
      )
    );

    yield put(clearUploadedAttachments());

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteMyDocument(api, { payload }) {
  try {
    yield call(api.deleteDocument, payload);

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* fetchAuthorizationDocuments(api) {
  try {
    const company = yield select(getActiveCompany);
    const activeUser = yield select(getLoggedInUser);

    const supervisorAuth = yield call(api.fetchContinuousCareBySupervisorId, {
      companyId: company._id,
      supervisorId: activeUser._id
    });

    const hrAuth = yield call(api.fetchContinuousCareByHrRepId, {
      companyId: company._id,
      hrRepId: activeUser._id
    });

    const trainings = yield call(api.fetchTrainingsByUser, {
      companyId: company._id,
      userId: activeUser._id,
      isCompleted: false
    });

    const newTrainings = trainings
      .filter(training => moment(training.dueDate).isAfter())
      .map(training => {
        return {
          ...training,
          label: training.title,
          formData: {
            employeeName: `${activeUser.firstName} ${activeUser.lastName}`
          },
          isPerform: true
        };
      });

    const trainingsForApproval = yield call(api.fetchTrainingForApprover, {
      companyId: company._id,
      approverId: activeUser._id
    });

    const newApprovals = trainingsForApproval.map(training => {
      return {
        ...training,
        label: training.title,
        formData: {
          employeeName: `${activeUser.firstName} ${activeUser.lastName}`
        },
        isApprove: true
      };
    });

    yield put(
      fetchRequireAuthResponse([
        ...supervisorAuth,
        ...hrAuth,
        ...newTrainings,
        ...newApprovals
      ])
    );
  } catch (e) {
    console.log(e);
  }
}

export function* createContinuousCare(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const allEmployees = yield select(getCompanyUsersSelector);
    const activeUser = yield select(getLoggedInUser);

    const activeEmployeeIndex = allEmployees.findIndex(
      emp => emp.userAccount._id === activeUser._id
    );

    const sig = payload.employeeSignature;

    payload = {
      label: payload.label,
      companyId: company._id,
      employeeId: allEmployees[activeEmployeeIndex]._id,
      supervisorId: payload.supervisorId.value,
      formData: {
        employeeName: payload.employeeName,
        dateOfRecurrence: moment(Date.parse(payload.dateOfRecurrence)),
        dateReported: moment(Date.parse(payload.dateReported)),
        datePhysicianAppt: moment(Date.parse(payload.datePhysicianAppt)),
        workRestrictions: payload.workRestrictions,
        dateOfTransition: moment(Date.parse(payload.dateOfTransition)),
        incidentDesc: payload.incidentDesc,
        reinjuryOccurred: payload.reinjuryOccurred,
        injuryHistory: payload.injuryHistory,
        employeeSignature: sig?.source_url || sig,
        physicianInfo: payload.physicianInfo
      }
    };

    let cc = yield call(api.createContinuousCare, payload);

    if (sig?._id) {
      yield call(api.updateAttachment, {
        _id: sig._id,
        ownerId: cc._id,
        ownerType: 'continuousCare'
      });
    }

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* updateContinuousCare(api, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const sig = payload.employeeSignature;

    let update = {
      _id: payload._id,
      companyId: company._id,
      supervisorId: payload.supervisorId.value
        ? payload.supervisorId.value
        : payload.supervisorId,
      hrRepId:
        payload.hrRepId && payload.hrRepId.value
          ? payload.hrRepId.value
          : payload.hrRepId,
      formData: {
        employeeName: payload.employeeName,
        dateOfRecurrence: moment(Date.parse(payload.dateOfRecurrence)),
        dateReported: moment(Date.parse(payload.dateReported)),
        datePhysicianAppt: moment(Date.parse(payload.datePhysicianAppt)),
        incidentDesc: payload.incidentDesc,
        reinjuryOccurred: payload.reinjuryOccurred,
        injuryHistory: payload.injuryHistory,
        workRestrictions: payload.workRestrictions,
        dateOfTransition: moment(Date.parse(payload.dateOfTransition)),
        employeeSignature: sig?.source_url || sig,
        physicianInfo: payload.physicianInfo
      }
    };

    if (payload.supervisorSignature) {
      let supSig = payload.supervisorSignature;
      update.isApprovedBySupervisor = supSig !== undefined;
      update.formData.supervisorSignature = supSig?.source_url || supSig;
    }
    if (payload.hrSignature) {
      let hrSig = payload.hrSignature;
      update.isApprovedByHr = hrSig !== undefined;
      update.formData.hrSignature = hrSig?.source_url || hrSig;
    }

    yield call(api.updateContinuousCare, update);

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteContinuousCare(api, { payload }) {
  try {
    yield call(api.deleteContinuousCare, payload);

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCustomDocumentTemplates(api) {
  try {
    const company = yield select(getActiveCompany);

    const response = yield call(api.fetchCustomDocumentTemplates, {
      companyId: company._id
    });

    yield put(fetchCustomDocumentTemplatesResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* createCustomDocumentTemplate(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCompany = yield select(getActiveCompany);

    payload.fields.forEach(field => {
      field.type = field.type.value;
      field.options = field.options.map(option => option.option);
    });

    payload = {
      ...payload,
      shouldHaveExpiredDate: payload.shouldHaveExpiredDate.length === 1,
      companyId: activeCompany._id
    };

    yield call(api.createCustomDocumentTemplates, payload);
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateCustomDocumentTemplate(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload.fields.forEach(field => {
      field.type = field.type.value ? field.type.value : field.type;

      if (field.options.length > 0 && field.options[0].option) {
        field.options = field.options.map(option => option.option);
      }
    });

    payload = {
      ...payload,
      companyId: activeCompany._id
    };

    yield call(api.updateCustomDocumentTemplates, payload);
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteCustomDocumentTemplate(api, { payload }) {
  try {
    yield call(api.deleteCustomDocumentTemplates, payload);
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCustomDocuments(api) {
  try {
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let response = [];

    if (project) {
      response = yield call(
        api.fetchDocumentsByTypeAndProject,
        company._id,
        'CustomDocument',
        1,
        10000,
        project._id
      );
    } else if (group) {
      response = yield call(
        api.fetchDocumentsByTypeAndGroup,
        company._id,
        'CustomDocument',
        1,
        10000,
        group._id
      );
    } else {
      response = yield call(
        api.fetchDocumentsByType,
        company._id,
        'CustomDocument',
        1,
        10000
      );
    }

    const templates = yield call(api.fetchCustomDocumentTemplates, {
      companyId: company._id
    });
    if (response.docs.every(doc => doc.attachments)) {
      // do nothing
    } else {
      const attachments = yield all(
        response.docs.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document._id,
            'documents'
          )
        )
      );

      response.docs.map((res, index) => (res.attachments = attachments[index]));
    }

    yield put(fetchCustomDocumentTemplatesResponse(templates));
    yield put(fetchCustomDocumentsResponse(response.docs));
  } catch (e) {
    console.log(e);
  }
}

export function* createCustomDocument(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    let sigs = [];

    payload.fields.forEach(f => {
      if (f.type === 'signature' && f.answer?._id) {
        sigs.push(f.answer);
        f.answer = f.answer.source_url;
      }
    });

    payload = {
      documentType: 'CustomDocument',
      label: payload.label,
      expires: payload.expires,
      documentData: {
        fields: payload.fields
      },
      companyId: activeCompany._id,
      groupIds: activeGroup ? [activeGroup._id] : [],
      projectIds: activeProject ? [activeProject._id] : []
    };

    const response = yield call(api.addDocument, activeCompany._id, payload);

    yield all(
      sigs.map(s =>
        call(api.updateAttachment, {
          _id: s._id,
          ownerId: response._id,
          ownerType: 'customDocument'
        })
      )
    );

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    yield put(setActiveCustomDocument(response));
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* updateCustomDocument(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const sigs = [];

    payload.fields.forEach(f => {
      if (f.type === 'signature' && f?.answer?._id) {
        sigs.push(f.answer);
        f.answer = f.answer.source_url;
      }
    });

    yield all(
      sigs.map(s =>
        call(api.updateAttachment, {
          _id: s._id,
          ownerId: payload._id,
          ownerType: 'customDocument'
        })
      )
    );

    payload = {
      documentType: 'CustomDocument',
      label: payload.label,
      expires: payload.expires,
      documentData: {
        fields: payload.fields
      },
      companyId: activeCompany._id,
      _id: payload._id
    };

    const response = yield call(api.updateDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = [...(response.attachments || []), ...newAttachments];

    yield put(setActiveCustomDocument(response));
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteCustomDocument(api, { payload }) {
  try {
    yield call(api.deleteDocument, payload);
    yield put(setActiveCustomDocument({}));
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteCustomDocumentAttachment(api, { payload }) {
  try {
    yield put(removeMessage());
    const activeCD = yield select(getActiveCustomDocSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeCD.companyId,
      activeCD._id,
      'documents'
    );

    activeCD.attachments = attachments;

    yield put(setActiveCustomDocument(activeCD));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}
