import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { Link } from 'react-router-dom';
import { signOut } from '../../actions/auth';
import {
  addDocumentFolderRequest,
  fetchDailyReportsRequest,
  fetchQualificationRequest,
  fetchSafetyInstructionRequest,
  fetchSdsRequest,
  fetchToolboxTalksRequest,
  setActiveDocumentFolderRequest
} from '../../actions/documents';
import {
  fetchIncidentBasicsRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import {
  fetchJsaSectionsRequest,
  setActiveJsaResponse
} from '../../actions/jsa';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import { getDocumentFoldersSelector } from '../../selectors/documents';
import { getLeftNavigationSelector } from '../../selectors/navigation';
import { getLoggedInUser } from '../../selectors/users';

import svgMapper from '../../utils/svgMapper';
import Button from '../../components/inputs/Button';
import LeftNavSection from '../../components/LeftNavSection/';
import Modal from '../../components/Modal';
import UserBadge from '../../components/UserBadge';
import ReportSelector from '../../components/inputs/ReportSelector';

import './index.css';

export class MobileHeader extends Component {
  state = {
    menuCollapsed: true,
    sectionOpen: 0,
    reportTemplates: [],
    modalOpen: false
  };

  toggleMenuCollapsed = () => {
    this.setState({ menuCollapsed: !this.state.menuCollapsed });
  };

  handleSectionOpen = section => {
    this.setState(section);
  };

  handleOpenModal = () => {
    this.setState({
      modalOpen: true,
      menuCollapsed: true
    });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  trackNewReportCreation = async () => {};

  handleSubmit = () => {
    this.props.setJsa();
    this.props.setIncident();
    this.handleCloseModal();

    this.trackNewReportCreation();

    if (this.state.reportTemplates.indexOf(8) > -1) {
      history.push('/app/jsaBasics');
    } else {
      this.props.fetchIncidentBasics(this.state.reportTemplates);
      this.props.fetchReportTemplates(this.state.reportTemplates);
    }
  };

  render() {
    const { menuCollapsed, sectionOpen, modalOpen } = this.state;
    const {
      leftNavigation,
      user,
      activeCompany,
      activeLocation,
      activeProject,
      documentFolders,
      addDocumentFolder,
      setActiveFolder,
      fetchSds,
      fetchDailyReports,
      fetchQualifications,
      fetchSafetyInstructions,
      fetchToolboxTalks,
      logOut
    } = this.props;

    return (
      <div className="mobileHeader">
        <div className="mobileHeader-header">
          <div className="container" onClick={() => this.toggleMenuCollapsed()}>
            <div className={menuCollapsed ? 'bar1' : 'change bar1'} />
            <div className={menuCollapsed ? 'bar2' : 'change bar2'} />
            <div className={menuCollapsed ? 'bar3' : 'change bar3'} />
          </div>
        </div>
        <div
          className={
            menuCollapsed
              ? 'mobileHeader-sideMenu hidden'
              : 'mobileHeader-sideMenu'
          }
        >
          <aside className="sidebar-sections">
            <Button
              text="New Report"
              className="greenButton"
              onClick={() => this.handleOpenModal()}
            />
            <div className="sidebar-section">
              <p className="sidebar-label activeSection">Navigation</p>
              <ul className="sidebar-menu activeSection">
                <li>
                  <Link
                    to={{
                      pathname: '/home'
                    }}
                    onClick={() => this.toggleMenuCollapsed()}
                  >
                    {svgMapper(`home`)} <span>Home</span>
                  </Link>
                </li>
              </ul>
            </div>
            {leftNavigation.sections.map((navSection, index) => {
              return navSection.accessLevel.indexOf(user.accessLevel) !== -1 ? (
                <LeftNavSection
                  title={navSection.title}
                  section={navSection.section}
                  key={index}
                  accessLevel={user.accessLevel}
                  sectionOpen={sectionOpen === index}
                  openNewSection={() =>
                    this.handleSectionOpen({ sectionOpen: index })
                  }
                  closeModalMenu={() => this.toggleMenuCollapsed()}
                  activeCompany={activeCompany}
                  activeLocation={activeLocation}
                  activeProject={activeProject}
                  documentFolders={documentFolders}
                  addDocumentFolder={addDocumentFolder}
                  setActiveFolder={setActiveFolder}
                  fetchSds={fetchSds}
                  fetchDailyReports={fetchDailyReports}
                  fetchQualifications={fetchQualifications}
                  fetchSafetyInstructions={fetchSafetyInstructions}
                  fetchToolboxTalks={fetchToolboxTalks}
                  loggedInUser={user}
                />
              ) : (
                ''
              );
            })}
          </aside>
          <div className="bottomNav">
            <UserBadge
              userId={user._id}
              showName
              showImage
              nameUnder
            />
            <Button
              text="Profile"
              className="blueButton"
              onClick={() => history.push('/app/profile')}
            />
            <Button
              text="Logout"
              className="whiteButton"
              onClick={() => logOut()}
            />
          </div>
        </div>
        <Modal
          title="Select what happened (one or more)"
          titleClassName="blueHeader"
          isOpen={modalOpen}
          submitButtonColor="blue"
          onRequestClose={() => this.handleCloseModal()}
          submitActions={() => this.handleSubmit()}
        >
          <ReportSelector
            accessLevel={user.accessLevel}
            handleReportSelection={values =>
              this.setState({ reportTemplates: values })
            }
          />
        </Modal>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  leftNavigation: getLeftNavigationSelector(state),
  user: getLoggedInUser(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  documentFolders: getDocumentFoldersSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  fetchIncidentBasics: reportTypes =>
    dispatch(fetchIncidentBasicsRequest(reportTypes)),
  fetchJsaSections: () => dispatch(fetchJsaSectionsRequest()),
  setIncident: () => dispatch(setActiveIncidentResponse({})),
  setJsa: () => dispatch(setActiveJsaResponse({})),
  logOut: () => dispatch(signOut()),
  addDocumentFolder: values => dispatch(addDocumentFolderRequest(values)),
  setActiveFolder: values => dispatch(setActiveDocumentFolderRequest(values)),
  fetchSds: () => dispatch(fetchSdsRequest({ page: 1, limit: 10000 })),
  fetchDailyReports: () =>
    dispatch(fetchDailyReportsRequest({ page: 1, limit: 10000 })),
  fetchQualifications: () =>
    dispatch(fetchQualificationRequest({ page: 1, limit: 10000 })),
  fetchSafetyInstructions: () =>
    dispatch(fetchSafetyInstructionRequest({ page: 1, limit: 10000 })),
  fetchToolboxTalks: () =>
    dispatch(fetchToolboxTalksRequest({ page: 1, limit: 10000 }))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
