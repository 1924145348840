import React, { useState, useEffect } from 'react';
import history from '../../../history';
import store from '../../../store';
import * as api from '../../../api';
import { positiveIntsOnly } from '../../../utils/formValidation';
import Card from '../../../components/Card';
import QuizQuestionList from '../../../components/quiz/QuizQuestionList';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import RadioButtons from '../../../components/inputs/RadioButtons';
import Textbox from '../../../components/inputs/Textbox';

export default function QuizTemplate(props) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [quiz, setQuiz] = useState({
    title: '',
    numberOfQuestionsCorrectNeededToPass: '',
    canBeRetaken: false,
    numberOfRetriesAllowed: ''
  });
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (props.match.params.quizTemplateId) {
      api.fetchQuizById(props.match.params.quizTemplateId).then(response => {
        setQuiz({
          ...response,
          canBeRetaken: response.canBeRetaken
            ? { value: 1, label: 'Yes' }
            : { value: 2, label: 'No' }
        });
        setQuestions(response.questions);
      });
    } else {
      setIsCreating(true);
      setQuestions([
        {
          label: '',
          answerOptions: []
        }
      ]);
    }
  }, [props.match.params.quizTemplateId]);

  const updateQuestions = newQuestions => {
    setQuestions(newQuestions);
    setHasUnsavedChanges(true);
  };

  const canSubmit = () => {
    const questionsMissingInfo = questions.filter(question => {
      const hasCorrectAnswers = question.answerOptions.some(
        answer => answer.isCorrectAnswer
      );
      const hasAnswerLabels = question.answerOptions.every(
        answer => answer.answer
      );

      return !question.label || !hasCorrectAnswers || !hasAnswerLabels;
    });

    return (
      quiz.title.length > 2 &&
      quiz.numberOfQuestionsCorrectNeededToPass &&
      questions.length &&
      quiz.numberOfQuestionsCorrectNeededToPass <= questions.length &&
      !questionsMissingInfo.length &&
      questions.length >= quiz.numberOfQuestionsCorrectNeededToPass
    );
  };

  const handleSubmit = () => {
    let state = store.getState();
    let companyId = state.company?.activeCompany?._id;
    const newQuiz = {
      ...quiz,
      questions,
      companyId,
      canBeRetaken: quiz?.canBeRetaken?.value === 1
    };

    if (newQuiz._id) {
      api
        .updateQuiz(newQuiz)
        .then(() => history.goBack())
        .catch(e => alert(e));
    } else {
      api
        .createQuiz(newQuiz)
        .then(() => history.goBack())
        .catch(e => alert(e));
    }
  };

  const handleDelete = () => {
    api.deleteQuiz(quiz).then(() => history.goBack());
  };

  const checkTitleAvailability = e => {
    api.doesQuizHaveTitle(e.target.value).then(response => {
      const titleField = window.document.getElementById('quizTitle');

      if (response && response.titleExists) {
        titleField.style.background = 'lightcoral';
      } else {
        titleField.style.background = 'lightgreen';
      }
    });
  };

  const header = (
    <Header
      title="Quiz Template"
      section="Create Template"
      rightButtons={
        isEditing
          ? {
              text: 'Delete Quiz',
              onClick: () => {
                handleDelete();
              },
              color: 'red',
              visible: !isCreating
            }
          : {
              text: 'Edit Quiz',
              onClick: () => {
                setIsEditing(true);
              },
              color: 'blue',
              visible: !isCreating
            }
      }
      clickBack={() => history.goBack()}
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonDisabled={!canSubmit()}
      saveButtonClick={() => handleSubmit()}
      cancelButtonClick={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : history.goBack()
      }
      editing={isEditing}
    />
  );

  const disabled = !isCreating && !isEditing;

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer} showFooter={!disabled}>
        <Card showHeader title="Quiz Template">
          <Textbox
            currentValue={quiz.title}
            handleChange={e => {
              checkTitleAvailability(e);
              setQuiz({ ...quiz, title: e.target.value });
              setHasUnsavedChanges(true);
            }}
            fieldLabel="Title (if textbox turns red, title is not available)"
            placeholder="Title of Quiz (e.g. Forklift Quiz)"
            isRequired
            disabled={disabled}
            name="quizTitle"
          />
          <Textbox
            type="number"
            currentValue={quiz.numberOfQuestionsCorrectNeededToPass}
            handleChange={e => {
              const onlyNums = positiveIntsOnly(e.target.value);
              setQuiz({
                ...quiz,
                numberOfQuestionsCorrectNeededToPass: onlyNums
              });
              setHasUnsavedChanges(true);
            }}
            fieldLabel="How many questions must be correct to pass?  (If textbook turns red, it means the # of total questions on the quiz is less than the # needed to be correct)."
            placeholder="0"
            isRequired
            disabled={disabled}
            name="numberNeededRight"
            style={{
              background:
                quiz.numberOfQuestionsCorrectNeededToPass > questions.length
                  ? 'lightcoral'
                  : 'lightgreen'
            }}
          />
          <RadioButtons
            fieldLabel="Can be retaken?"
            options={[
              { value: 1, label: 'Yes' },
              { value: 2, label: 'No' }
            ]}
            isRequired
            handleChange={values => {
              setQuiz({
                ...quiz,
                canBeRetaken: values
              });
              setHasUnsavedChanges(true);
            }}
            currentValue={quiz.canBeRetaken}
            disabled={disabled}
          />
          {quiz.canBeRetaken.value === 1 && (
            <Textbox
              type="number"
              currentValue={quiz.numberOfRetriesAllowed}
              handleChange={e => {
                const onlyNums = positiveIntsOnly(e.target.value);
                setQuiz({
                  ...quiz,
                  numberOfRetriesAllowed: onlyNums
                });
                setHasUnsavedChanges(true);
              }}
              fieldLabel="How many retries allowed?"
              placeholder="0"
              isRequired
              disabled={disabled}
            />
          )}
          <QuizQuestionList
            questions={questions}
            quiz={quiz}
            disabled={disabled}
            updateQuestions={values => updateQuestions(values)}
          />
        </Card>
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        savingWhat="Quiz Template"
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={() => history.goBack()}
      />
    </>
  );
}
