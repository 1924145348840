import React, { Component } from 'react';

import { DatePicker } from '../../inputs/DateTimePicker';
import Textbox from '../../inputs/Textbox';
import Card from '../../Card';
import { TwoColumn } from '../../inputs';

export default class RecurrenceCard extends Component {
  render() {
    const {
      handleChange,
      employeeName,
      dateOfRecurrence,
      dateReported,
      datePhysicianAppt,
      physicianInfo,
      incidentDesc,
      reinjuryOccurred,
      injuryHistory,
      disabled,
      name
    } = this.props;

    return (
      <Card
        name={name}
        showHeader
        title="Recurrence, Relapse During Recovery & Continuing Treatment"
      >
        <TwoColumn>
          <Textbox
            fieldLabel="Employee Name"
            placeholder="ex. Jane Doe"
            isRequired={true}
            handleChange={e =>
              handleChange({
                employeeName: e.target.value
              })
            }
            currentValue={employeeName}
            disabled={disabled}
          />
          <DatePicker
            pickTime
            fieldLabel="Date of Recurrence"
            isRequired
            disabled={disabled}
            handleChange={values => handleChange({ dateOfRecurrence: values })}
            currentValue={dateOfRecurrence}
          />
          <DatePicker
            pickTime
            fieldLabel="Date Reported"
            isRequired
            disabled={disabled}
            handleChange={value => handleChange({ dateReported: value })}
            currentValue={dateReported}
          />
          <DatePicker
            pickTime
            fieldLabel="Date of Physician Appointment"
            isRequired
            disabled={disabled}
            handleChange={value => handleChange({ datePhysicianAppt: value })}
            currentValue={datePhysicianAppt}
          />
        </TwoColumn>
        <Textbox
          fieldLabel="Physician Name and Information"
          type="textarea"
          rows={5}
          placeholder="Name, Specialization, Office Address, Contact Info"
          handleChange={e =>
            handleChange({
              physicianInfo: e.target.value
            })
          }
          currentValue={physicianInfo}
          disabled={disabled}
        />
        <Textbox
          fieldLabel="Describe the incident that caused this re-injury (include body parts affected)"
          placeholder="Type here"
          type="textarea"
          rows={5}
          isRequired={true}
          handleChange={e =>
            handleChange({
              incidentDesc: e.target.value
            })
          }
          currentValue={incidentDesc}
          disabled={disabled}
        />
        <Textbox
          fieldLabel="Where the re-injury occurred"
          placeholder="(Work, Home, Grocery Store, etc.)"
          isRequired={true}
          handleChange={e =>
            handleChange({
              reinjuryOccurred: e.target.value
            })
          }
          currentValue={reinjuryOccurred}
          disabled={disabled}
        />
        <Textbox
          fieldLabel="Describe, in detail, the history of problems related to the injury"
          placeholder="Type here"
          type="textarea"
          rows={5}
          isRequired={true}
          handleChange={e =>
            handleChange({
              injuryHistory: e.target.value
            })
          }
          currentValue={injuryHistory}
          disabled={disabled}
        />
      </Card>
    );
  }
}
